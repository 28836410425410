import React from "react"
import PropTypes from "prop-types"

import { Versions } from "../../routes/Dashboards/Support/components/Releases"

const FooterText = props => (
	<div>
		Version: {Object.keys(Versions)[0]}
		<br />
		<i className="fa fa-fw fa-copyright" />
		{props.year} All Rights Reserved
	</div>
)
FooterText.propTypes = {
	year: PropTypes.node,
	name: PropTypes.node,
	desc: PropTypes.node,
	author: PropTypes.node,
}
FooterText.defaultProps = {
	year: "2020",
	name: "Clearview Service Charge Tracker",
	desc: "Clearview Service Charge Tracker",
	author: "Clearview Software Ltd",
}

export { FooterText }
