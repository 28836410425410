import React from "react"
import { connect } from "react-redux"

import { Auth } from "aws-amplify"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"
import config from "../../../config"

import { Link } from "react-router-dom"

import { Avatar, NavLink } from "./../../../components"

import Dropdown from "react-bootstrap/Dropdown"
import { Sidebar, SidebarTrigger } from "../../../layout"

import { PeriodEndsUnreadBadge } from "./PeriodEndsUnreadBadge"

import { Consumer } from "../../../layout/context"
import Impersonate_Form from "../../Dashboards/components/Impersonate_Form"
import { removeImpersonate } from "../../../api/CustomerApi"

const onHomeClick = context => {
	context.setPreFilter([], { periodEndStatus: "Open" }, true)
}

function signOut() {
	Auth.signOut()
		.then(data => (window.location.href = "/"))
		.catch(err => console.error(err))
}

function unimpersonate() {
	removeImpersonate().then(_ =>
		Auth.signOut()
			.then(data => (window.location.href = "/"))
			.catch(err => console.error(err))
	)
}

let SidebarTopA = ({ username, preferredUsername, actualUsername, name, initials, isInHouse, groups, teams, doRecalculate, doRenew, doRebuild }) => (
	<Consumer>
		{context => {
			const userRole = teams?.find(it => it.type === "Users")?.role
			const canImpersonate = (isInHouse && ["Admin", "System"].includes(userRole)) || !!preferredUsername

			return (
				<React.Fragment>
					<Sidebar.HideSlim>
						<Sidebar.Section className="pt-0">
							<Sidebar.HideSlim>
								<div className="d-flex-row">
									<Link to="/" className="d-block flex-1" onClick={() => onHomeClick(context)}>
										<Avatar.Font
											size="lg"
											bgColorCustom={config.brand(window.location).avatarColor}
											className="mr-2"
											addOns={[<PeriodEndsUnreadBadge></PeriodEndsUnreadBadge>]}
										>
											{initials}
										</Avatar.Font>
									</Link>

									<SidebarTrigger title="Show/hide sidebar" className="text-primary nav-link-with-avatar" />
									<NavLink title="Log out" tag={Link} onClick={signOut} to="/" className="text-primary nav-link-with-avatar">
										<i className="fa fa-power-off"></i>
									</NavLink>
								</div>
							</Sidebar.HideSlim>
							<Dropdown>
								{!!preferredUsername && (
									<span
										className="hover mr-2"
										title={`"${actualUsername}" ${
											username === preferredUsername ? "is impersonating" : "has failed to impersonate"
										} "${preferredUsername}". Click to stop impersonating.`}
										onClick={e => unimpersonate()}
									>
										{clearview.Icon.IsImpersonating[true]}
									</span>
								)}
								<Dropdown.Toggle variant="none" className="pl-0 pb-0 pt-0 btn-profile sidebar__link">
									{username}
								</Dropdown.Toggle>
								<Dropdown.Menu persist>
									<Dropdown.Item header>{name}</Dropdown.Item>
									<Dropdown.Item divider />
									<Dropdown.Item>{isInHouse ? "In-House user" : "Client User"}</Dropdown.Item>

									{!!preferredUsername && (
										<Dropdown.Item>
											<span
												className="hover mr-2"
												title={`"${actualUsername}" is ${
													username === preferredUsername ? "is impersonating" : "has failed to impersonate"
												} "${preferredUsername}". Click to stop impersonating.`}
												onClick={e => unimpersonate()}
											>
												{clearview.Icon.IsImpersonating[true]} Stop Impersonating
											</span>
										</Dropdown.Item>
									)}

									{canImpersonate && (
										<Dropdown.Item>
											<a href="#" id="Impersonate_Form">
												{clearview.Icon.IsImpersonating[false]}
												Impersonate...
											</a>
											<Impersonate_Form actualUsername={actualUsername} username={username} preferredUsername={preferredUsername} />
										</Dropdown.Item>
									)}

									{teams && (
										<div style={{ maxHeight: 300, overflow: "hidden", overflowY: "scroll" }}>
											<Dropdown.Item divider />
											{teams.map((it, idx) => (
												<Dropdown.Item key={idx}>
													{clearview.Icon.Business[it.type]} {it.business.name}:<br />
													<span title="Team" className="ml-1">
														{it.name}
													</span>{" "}
													(
													<span title="Role" className="ml-0">
														{it.role}
													</span>
													)
												</Dropdown.Item>
											))}
										</div>
									)}
									{/* {groups && (
										<React.Fragment>
											<DropdownItem divider />
											{groups.map((it, idx) => (
												<DropdownItem key={idx}>Group: {it}</DropdownItem>
											))}
										</React.Fragment>
									)} */}
									{groups && groups.includes("sysadmin") && (
										<React.Fragment>
											<Dropdown.Item divider />
											<Dropdown.Item onClick={() => doRecalculate()} title="Recalculate Period End statuses.">
												Recalculate!
											</Dropdown.Item>
											<Dropdown.Item onClick={() => doRenew()} title="Renew Period Ends.">
												Renew!
											</Dropdown.Item>
											<Dropdown.Item onClick={() => doRebuild()} title="Rebuild Hierarchy.">
												Rebuild!
											</Dropdown.Item>
										</React.Fragment>
									)}
									<Dropdown.Item divider />
									<Dropdown.Item tag={Link} to="/" onClick={signOut}>
										<i className="fa fa-fw fa-sign-out mr-2"></i>
										Sign out
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Sidebar.Section>
					</Sidebar.HideSlim>
					<Sidebar.ShowSlim>
						<Sidebar.Section>
							<Link to="/" className="d-block flex-1" onClick={() => onHomeClick(context)}>
								<Avatar.Font
									size="sm"
									bgColor="primary"
									// className="mr-2"
								>
									{initials}
								</Avatar.Font>
							</Link>

							<SidebarTrigger title="Show/hide sidebar" className="text-primary nav-link-with-avatar" />
							<NavLink title="Log out" tag={Link} onClick={signOut} to="/" className="text-primary nav-link-with-avatar">
								<i className="fa fa-power-off"></i>
							</NavLink>
						</Sidebar.Section>
					</Sidebar.ShowSlim>
				</React.Fragment>
			)
		}}
	</Consumer>
)

const mapStateToProps = state => {
	const { userReducer } = state
	return userReducer.user || clearview.User.UnauthenticatedUser
}

const mapDispatchToProps = dispatch => ({
	doRecalculate: () => {
		dispatch({ type: ACTIONS.PERIODENDS_RECALCULATE, payload: {} })
	},
	doRenew: () => {
		dispatch({ type: ACTIONS.PERIODENDS_RENEW, payload: {} })
	},
	doRebuild: () => {
		dispatch({ type: ACTIONS.CLIENTS_REBUILD, payload: {} })
	},
})

SidebarTopA = connect(mapStateToProps, mapDispatchToProps)(SidebarTopA)
export { SidebarTopA }
