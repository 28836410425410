import React from "react"
import { useLoaderData } from "react-router-dom"
import PropTypes from "prop-types"
import { Prompt } from "../../../../components"

import _ from "lodash"
import { v4 as uuid } from "uuid"

import { PeriodEndUnread } from "../../PeriodEnds/components/PeriodEndUnread"
import { PeriodEndFavourite } from "../../PeriodEnds/components/PeriodEndFavourite"

import { DragDropContext } from "react-beautiful-dnd"

import * as clearview from "../../../../components/@Clearview"
import * as Api from "../../../../api/CustomerApi"
import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"
import AreYouSureModal from "../../../components/AreYouSureModal"
import { AnimatedProgressBar } from "../../../components/AnimatedProgressBar"
import { Spinner } from "../../../components/Spinner"

import { Avatar, AvatarAddOn, Container, Row, Col, Card, CardHeader, CardBody, Button } from "../../../../components"

import { HeaderMain } from "../../../components/HeaderMain"
import VouchingDetailsContext from "./VouchingDetailsContext"
import VouchingFilters from "./VouchingFilters"
import VouchingItemsSearch from "./VouchingItemsSearch"
import VouchingItemsSort from "./VouchingItemsSort"
import VouchingActions from "./VouchingActions"
import AutoMatch, { vendorNamesMatch, addVendorNameAlias } from "./AutoMatch"
import {
	isLedgerItemOrExpense,
	validateLedgerItem,
	validateExpense,
	parseLedgerItem,
	processExpenseAnalysis,
	processTextDetection,
	reapplyImportRulesToExistingExpenses,
} from "./Parsers"
import { QueueManager } from "./QueueManager"

import { ImportLedger_Form } from "./ImportLedger_Form"
import LedgerItemEdit from "./LedgerItemEdit"
import ExpenseEdit from "./ExpenseEdit"

import { Column } from "./Column"
import { LedgerList } from "./LedgerList"
import { ExpenseList } from "./ExpenseList"

import Vouching_NewQueryForm from "./Vouching_NewQueryForm"
import PropertyNames_Form from "./PropertyNames_Form"
import { STATUS_COLOR } from "./VouchingTheme"
import { STATUS_ICON } from "./VouchingTheme"

import { stringsMatch } from "clearview-aws-common"

const DEFAULT_FILTER = {
	notMatched: true,
	incomplete: true,
	excluded: false,
	autoMatched: false,
	manualMatched: false,
	unavailable: false,
}

const DEFAULT_SORT = {
	ledger: [],
	expenses: [],
}

export const PROPERTY_FILTER_REGEX = /^(id|expenseId|vendorName|ref|date|total|narrative):\s*((\d|\.)+)$/

class VouchingDetailsComponent extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.setFilterItem = this.setFilterItem.bind(this)
		this.itemFilter = this.itemFilter.bind(this)

		this.state = {
			newExpenseJsonFilesCount: undefined,
			importedCount: undefined,
			ledgerSearch: "",
			expensesSearch: "",
			isChanged: false,

			vouching: {},

			filter: clearview.LoadLocal("vouchingMeta.filter", DEFAULT_FILTER),
			sort: clearview.LoadLocal("vouchingMeta.sort", DEFAULT_SORT),

			setFilterItem: this.setFilterItem,
			itemFilter: this.itemFilter,
		}

		this.loadVouching = this.loadVouching.bind(this)
		this.onDragStart = this.onDragStart.bind(this)
		this.onDragEnd = this.onDragEnd.bind(this)

		this.onSave = this.onSave.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.updateVendorAliases = this.updateVendorAliases.bind(this)

		this.onImportLedger = this.onImportLedger.bind(this)
		this.onImportLedgerCancel = this.onImportLedgerCancel.bind(this)
		this.onImportLedgerSave = this.onImportLedgerSave.bind(this)

		this.onImportExpenseFiles = this.onImportExpenseFiles.bind(this)

		this.onEditExpense = this.onEditExpense.bind(this)
		this.onEditExpenseCancel = this.onEditExpenseCancel.bind(this)
		this.onEditExpenseSave = this.onEditExpenseSave.bind(this)

		this.onEditLedgerItem = this.onEditLedgerItem.bind(this)
		this.onEditLedgerItemCancel = this.onEditLedgerItemCancel.bind(this)
		this.onEditLedgerItemSave = this.onEditLedgerItemSave.bind(this)

		this.onLedgerItemSelected = this.onLedgerItemSelected.bind(this)
		this.onExpenseSelected = this.onExpenseSelected.bind(this)

		this.onShowExpense = this.onShowExpense.bind(this)
		this.onShowLedgerItems = this.onShowLedgerItems.bind(this)

		this.selectAllLedger = this.selectAllLedger.bind(this)
		this.selectAllExpenses = this.selectAllExpenses.bind(this)

		this.onDeselectAllLedgerItems = this.onDeselectAllLedgerItems.bind(this)
		this.onDeselectAllExpenses = this.onDeselectAllExpenses.bind(this)

		this.onQuery = this.onQuery.bind(this)
		this.onLinkItems = this.onLinkItems.bind(this)
		this.onUnlinkExpense = this.onUnlinkExpense.bind(this)
		this.onConfirmLinked = this.onConfirmLinked.bind(this)
		this.onSetOtherStatus = this.onSetOtherStatus.bind(this)
		this.onDismissWarning = this.onDismissWarning.bind(this)

		this.onQueryMulti = this.onQueryMulti.bind(this)
		this.onUnlinkExpenseMulti = this.onUnlinkExpenseMulti.bind(this)
		this.onConfirmLinkedMulti = this.onConfirmLinkedMulti.bind(this)
		this.onSetOtherStatusMulti = this.onSetOtherStatusMulti.bind(this)
		this.onDismissWarningMulti = this.onDismissWarningMulti.bind(this)
		this.onMergeAdjacentInvoices = this.onMergeAdjacentInvoices.bind(this)

		this.onVouchingChanged = this.onVouchingChanged.bind(this)

		this.checkForNewExpenseJsonFiles = this.checkForNewExpenseJsonFiles.bind(this)
		this.doAutoMatch = this.doAutoMatch.bind(this)

		this.sortBy = this.sortBy.bind(this)
		this.setLedgerSort = this.setLedgerSort.bind(this)
		this.setExpensesSort = this.setExpensesSort.bind(this)
		this.setLedgerSearch = this.setLedgerSearch.bind(this)
		this.setExpensesSearch = this.setExpensesSearch.bind(this)

		this.expenseListActions = this.expenseListActions.bind(this)
		this.ledgerListActions = this.ledgerListActions.bind(this)

		this.onClearAllLedger = this.onClearAllLedger.bind(this)
		this.onClearAllExpenses = this.onClearAllExpenses.bind(this)

		this.onChangePropertyNames = this.onChangePropertyNames.bind(this)
		this.clearTextDetectionFiles = this.clearTextDetectionFiles.bind(this)

		this.getPropertyNotVouchedCount = this.getPropertyNotVouchedCount.bind(this)
	}

	ledgerListActions = item => {
		if (item.isSelected && this.state.selectedAggregate.items.length > 1) {
			const items = {
				unlink: this.state.selectedAggregate.items.filter(it => ["A", "M"].includes(it.status)),
				matched: this.state.selectedAggregate.items.filter(it => ["A"].includes(it.status)),
				missing: this.state.selectedAggregate.items.filter(it => [undefined, null, "?", "X"].includes(it.status)),
				exclude: this.state.selectedAggregate.items.filter(it => [undefined, null, "?", "U"].includes(it.status)),
				include: this.state.selectedAggregate.items.filter(it => ["U", "X"].includes(it.status)),
			}

			return [
				{
					label: (
						<span key="l-a1x">
							{clearview.Icon.query} Query these {this.state.selectedAggregate.items.length} ...
						</span>
					),
					action: e => this.onQueryMulti(this.state.selectedAggregate.items),
					enabled: !!this.props.periodEnd.currentStage,
				},
				{
					label: (
						<span key="l-a2x">
							{clearview.Icon.unlink} Unlink {items.unlink.length ? items.unlink.length : ""}
						</span>
					),
					action: e => this.onUnlinkExpenseMulti(items.unlink.map(it => it.expenseId)),
					enabled: !!items.unlink.length,
				},
				{
					label: (
						<span key="l-a3x">
							{STATUS_ICON.M} Matched {items.matched.length ? items.matched.length : ""}
						</span>
					),
					action: e => this.onConfirmLinkedMulti(items.matched),
					enabled: !!items.matched.length,
				},
				{
					label: (
						<span key="l-a4bx">
							{STATUS_ICON.U} Missing {items.missing.length ? items.missing.length : ""}
						</span>
					),
					action: e => this.onSetOtherStatusMulti(items.missing, "U"),
					enabled: !!items.missing.length,
				},
				{
					label: (
						<span key="l-a5x">
							{STATUS_ICON.X} Exclude {items.exclude.length ? items.exclude.length : ""}
						</span>
					),
					action: e => this.onSetOtherStatusMulti(items.exclude, "X"),
					enabled: !!items.exclude.length,
				},
				{
					label: (
						<span key="l-a5x">
							{STATUS_ICON.null} Include {items.include.length ? items.include.length : ""}
						</span>
					),
					action: e => this.onSetOtherStatusMulti(items.include, null),
					enabled: !!items.include.length,
				},
			]
		} else {
			return [
				{
					id: "edit",
					label: <span key="e-a3">{clearview.Icon.edit} Edit...</span>,
					action: e => this.onEditLedgerItem(e, item),
					enabled: [undefined, null, "?"].includes(item.status) && !this.state.selectedAggregate?.items.length,
				},
				{
					label: <span key="l-a1">{clearview.Icon.query} Query this...</span>,
					action: e => this.onQuery(item),
					enabled: !!this.props.periodEnd.currentStage,
				},
				{
					label: <span key="l-a2">{clearview.Icon.unlink} Unlink</span>,
					action: e => this.onUnlinkExpense(item.expenseId),
					enabled: ["A", "M"].includes(item.status),
				},
				{
					label: <span key="l-a3">{STATUS_ICON.M} Matched</span>,
					action: e => this.onConfirmLinked(item),
					enabled: ["A"].includes(item.status),
				},
				{
					label: (
						<span key="l-a4">
							{STATUS_ICON.U} {item.status === "U" ? "Include" : "Missing"}
						</span>
					),
					action: e => this.onSetOtherStatus(item, "U"),
					enabled: !["A", "M"].includes(item.status),
				},
				{
					label: (
						<span key="l-a5">
							{STATUS_ICON.X} {item.status === "X" ? "Include" : "Exclude"}
						</span>
					),
					action: e => this.onSetOtherStatus(item, "X"),
					enabled: !["A", "M"].includes(item.status),
				},
			]
		}
	}

	expenseListActions = item => {
		if (item.isSelected && this.state.expensesAggregate.items.length > 1) {
			const items = {
				unlink: this.state.expensesAggregate.items.filter(it => ["A", "M"].includes(it.status)),
				matched: this.state.expensesAggregate.items.filter(it => ["A"].includes(it.status)),
				exclude: this.state.expensesAggregate.items.filter(it => [undefined, null, "?", "U"].includes(it.status)),
				include: this.state.expensesAggregate.items.filter(it => ["U", "X"].includes(it.status)),
				warning: this.state.expensesAggregate.items.filter(it => it.warning?.severity === "danger"),
			}
			return [
				{
					label: (
						<span key="l-a1x">
							{clearview.Icon.query} Query these {this.state.expensesAggregate.items.length} ...
						</span>
					),
					action: e => this.onQueryMulti(this.state.expensesAggregate.items),
					enabled: !!this.props.periodEnd.currentStage,
				},
				{
					label: (
						<span key="l-a2x">
							{clearview.Icon.unlink} Unlink {items.unlink.length ? items.unlink.length : ""}
						</span>
					),
					action: e => this.onUnlinkExpenseMulti(items.unlink.map(it => it.id)),
					enabled: !!items.unlink.length,
				},
				{
					label: (
						<span key="l-a3x">
							{STATUS_ICON.M} Matched {items.matched.length ? items.matched.length : ""}
						</span>
					),
					action: e => this.onConfirmLinkedMulti(items.matched),
					enabled: !!items.matched.length,
				},
				{
					label: (
						<span key="l-a5x">
							{STATUS_ICON.X} Exclude {items.exclude.length ? items.exclude.length : ""}
						</span>
					),
					action: e => this.onSetOtherStatusMulti(items.exclude, "X"),
					enabled: !!items.exclude.length,
				},
				{
					label: (
						<span key="l-a5x">
							{STATUS_ICON.null} Include {items.include.length ? items.include.length : ""}
						</span>
					),
					action: e => this.onSetOtherStatusMulti(items.include, null),
					enabled: !!items.include.length,
				},
				{
					label: (
						<span key="l-a6x">
							{STATUS_ICON["?"]} Dismiss Warnings {items.warning.length ? items.warning.length : ""}
						</span>
					),
					action: e => this.onDismissWarningMulti(items.warning),
					enabled: !!items.warning.length,
				},
				{
					label: (
						<span key="l-a7x">
							{clearview.Icon.merge} Merge Invoices
							{this.state.expensesAggregate.page ? ` (pages ${this.state.expensesAggregate.page} to ${this.state.expensesAggregate.toPage})` : ""}
						</span>
					),
					action: e => this.onMergeAdjacentInvoices(this.state.expensesAggregate.items),
					enabled: this.state.expensesAggregate.toPage > this.state.expensesAggregate.page,
				},
			]
		} else
			return [
				{
					id: "edit",
					label: <span key="e-a3">{clearview.Icon.edit} Edit...</span>,
					action: e => this.onEditExpense(e, item),
					enabled: [undefined, null, "?"].includes(item.status) && !this.state.expensesAggregate?.items.length,
				},
				{
					label: <span key="e-a1">{clearview.Icon.query} Query this...</span>,
					action: e => this.onQuery(item),
					enabled: !!this.props.periodEnd.currentStage,
				},
				{
					label: <span key="e-a2">{clearview.Icon.unlink} Unlink</span>,
					action: e => this.onUnlinkExpense(item.id),
					enabled: ["A", "M"].includes(item.status),
				},
				{
					label: <span key="e-a4">{STATUS_ICON.M} Matched</span>,
					action: e => this.onConfirmLinked(item),
					enabled: ["A"].includes(item.status),
				},
				{
					label: (
						<span key="e-a5">
							{STATUS_ICON.X} {item.status === "X" ? "Include" : "Exclude"}
						</span>
					),
					action: e => this.onSetOtherStatus(item, "X"),
					enabled: !["A", "M"].includes(item.status),
				},
				{
					label: <span key="l-a6">{STATUS_ICON["?"]} Dismiss Warning</span>,
					action: e => this.onDismissWarning(item),
					enabled: item.warning?.severity === "danger",
				},
			]
	}

	componentWillUnmount() {
		if (this.checkForNewExpenseJsonFiles_Timer != null) {
			// Stop timer
			clearInterval(this.checkForNewExpenseJsonFiles_Timer)
		}
	}

	vouchingRootUrl() {
		return `periodEnd/${this.props.periodEnd.id}`
	}

	vouchingDbUrl() {
		return `${this.vouchingRootUrl()}/vouching/vouching.${this.props.periodEnd.id}.json`
	}

	setFilterItem(e) {
		const newFilter = { ...this.state.filter, [e.target.name]: e.target.checked }
		clearview.SaveLocal("vouchingMeta.filter", newFilter)
		this.setState({ filter: newFilter })
	}

	itemFilter(item) {
		return (
			(!item.status && this.state.filter?.notMatched) ||
			(item.status === "?" && this.state.filter?.incomplete) ||
			(item.status === "X" && this.state.filter?.excluded) ||
			(item.status === "A" && this.state.filter?.autoMatched) ||
			(item.status === "M" && this.state.filter?.manualMatched) ||
			(item.status === "U" && this.state.filter?.unavailable)
		)
	}

	sortBy(collection, sortSpec) {
		if (!sortSpec) return collection
		return _.orderBy(collection, [...sortSpec.map(it => it.propName), "id"], [...sortSpec.map(it => it.order), "asc"])
	}

	selectAllLedger() {
		const visibleItems = (this.state.vouching.ledger?.filter(this.itemFilter) || []).filter(it => this.searchPredicate(it, this.state.ledgerSearch))
		if (!visibleItems.length) return

		const setTo = !visibleItems[0].isSelected
		_.each(visibleItems, it => {
			it.isSelected = setTo
		})

		this.setState(prevState => ({
			selectedAggregate: calculateSelectedAggregate(prevState.vouching),
		}))
	}

	selectAllExpenses() {
		const visibleItems = (this.state.vouching.expenses?.filter(this.itemFilter) || []).filter(it => this.searchPredicate(it, this.state.expensesSearch))
		if (!visibleItems.length) return

		const setTo = !visibleItems[0].isSelected
		_.each(visibleItems, it => {
			it.isSelected = setTo
		})

		this.setState(prevState => ({
			expensesAggregate: calculateExpensesAggregate(prevState.vouching),
		}))
	}

	setLedgerSort(sortSpec) {
		const newSort = { ...this.state.sort, ledger: sortSpec }
		clearview.SaveLocal("vouchingMeta.sort", newSort)
		this.setState({ sort: newSort })
	}

	setLedgerSearch(e) {
		this.setState({
			ledgerSearch: e.target.value,
		})
	}

	setExpensesSearch(e) {
		this.setState({
			expensesSearch: e.target.value,
		})
	}

	setExpensesSort(sortSpec) {
		const newSort = { ...this.state.sort, expenses: sortSpec }
		clearview.SaveLocal("vouchingMeta.sort", newSort)
		this.setState({ sort: newSort })
	}

	onClearAllLedger() {
		if (window.confirm(`Are you sure you wish to delete all the Expenditure data?`)) {
			this.state.vouching.ledger = []
			this.state.vouching.accounts = []
			this.state.vouching.ledgerFiles = {}
			this.state.selectedAggregate = undefined

			for (let expense of this.state.vouching.expenses.filter(it => ["A", "M"].includes(it.status))) {
				expense.status = null
			}

			this.onVouchingChanged()
		}
	}

	onClearAllExpenses() {
		if (window.confirm(`Are you sure you wish to delete all the Invoice data?`)) {
			this.state.vouching.expenses = []
			this.state.vouching.expenseFiles = {}
			this.state.expensesAggregate = undefined
			this.state.importedCount = undefined
			this.state.autoMatchCount = undefined

			for (let ledgerItem of this.state.vouching.ledger.filter(it => ["A", "M"].includes(it.status))) {
				ledgerItem.status = null
			}

			this.onVouchingChanged()
			this.checkForNewExpenseJsonFiles()
		}
	}

	onChangePropertyNames(newPropertyNames) {
		if (
			newPropertyNames.length !== this.state.propertyNames.length ||
			_.intersection(this.state.propertyNames, newPropertyNames).length !== newPropertyNames.length
		) {
			Api.savePreferences(this.props.periodEnd.property, "property-names", newPropertyNames).then(() => {
				this.clearTextDetectionFiles({ propertyNames: newPropertyNames, propertyNotVouchedCount: undefined })
			})
		} else {
			this.setState({ propertyNotVouchedCount: undefined })
		}
	}

	clearTextDetectionFiles(newState) {
		for (let textFile of _.keys(this.state.vouching.expenseFiles).filter(it => it.endsWith(".text.json"))) {
			this.state.vouching.expenseFiles[`${textFile}.${Date.now()}`] = _.cloneDeep(this.state.vouching.expenseFiles[textFile])
			delete this.state.vouching.expenseFiles[textFile]
		}
		this.setState(newState)
		this.checkForNewExpenseJsonFiles()
	}

	getPropertyNotVouchedCount(vouching) {
		return vouching.expenses.filter(it => it.warning?.severity === "danger").length
	}

	loadVouching() {
		Promise.all([
			Api.loadPeriodEndDataFile(this.props.periodEnd, "vouching/vouching", { ...materialiseVouching({}), id: this.props.periodEnd.id }),
			Api.loadPreferences(clearview.TopClientFor(this.props.periodEnd.property), "vendor-aliases", {}),
			Api.loadPreferences(this.props.periodEnd.property, "property-names", [this.props.periodEnd.property.name]),
		])
			.then(responses =>
				this.setState({
					isBusy: false,
					isImporting: false,
					isChanged: false,
					newExpenseJsonFilesCount: undefined,
					importedCount: undefined,
					autoMatchCount: undefined,
					ledgerSearch: "",
					expensesSearch: "",
					selectedAggregate: undefined,
					expensesAggregate: undefined,
					vouching: materialiseVouching(responses[0], this.props.periodEnd),
					vendorAliases: responses[1],
					vendorAliasesChanged: false,
					propertyNames: responses[2],
				})
			)
			.then(() => {
				this.checkForNewExpenseJsonFiles(true)
			})
	}

	onImportLedger() {
		this.setState({ importLedgerIsOpen: true })
	}

	onImportLedgerCancel() {
		this.setState({ importLedgerIsOpen: false })
	}

	onImportLedgerSave(file, data) {
		const vouching = this.state.vouching
		if (vouching.ledgerFiles[file.name]) {
			clearview.ShowToast(
				"error",
				<p>
					Expenditure file <b>{file.name}</b> has already been imported.
				</p>
			)
			return
		}

		const toastId = clearview.ShowToast("info", <p>Loading data from Expenditure file...</p>)
		vouching.ledgerFiles[file.name] = { lastModifiedDate: file.lastModifiedDate }

		// BuildAccounts
		let highestAccountId = _.maxBy(vouching.accounts, it => it.id)?.id || 0
		_.uniq(data.map(it => it.accountName).filter(it => it)).forEach(accountName => {
			let account = vouching.accounts.find(it => it.name === accountName)
			if (!account) {
				highestAccountId++
				vouching.accounts.push({ id: highestAccountId, name: accountName })
			}
		})
		// Join items to accounts
		const newLedgerItems = data.map(it => parseLedgerItem(it, vouching.accounts))

		vouching.ledger = [...vouching.ledger, ...newLedgerItems]

		const filter = {
			...this.state.filter,
			incomplete: true,
			notMatched: true,
		}
		clearview.ShowToast(newLedgerItems.length ? "success" : "warning", <p>{newLedgerItems.length} Expenditure items imported.</p>, null, null, toastId)

		this.onVouchingChanged({ importLedgerIsOpen: false, ledgerSearch: "", filter: filter, vouching: materialiseVouching(vouching) })
	}

	onEditLedgerItem(e, item) {
		if (this.state.selectedAggregate?.items?.length) return
		this.setState({
			ledgerItemEditItem: item,
		})
	}

	onEditLedgerItemCancel() {
		this.setState({ ledgerItemEditItem: undefined })
	}

	onEditLedgerItemSave(ledgerItem) {
		const ledgerItemIndex = _.findIndex(this.state.vouching.ledger, it => it.id === ledgerItem.id)
		if (ledgerItemIndex < 0) return

		if (!ledgerItem.original) {
			ledgerItem.original = _.cloneDeep(this.state.vouching.ledger[ledgerItemIndex])
		}

		this.state.vouching.ledger.splice(ledgerItemIndex, 1, ledgerItem)

		this.onVouchingChanged({ vouching: this.state.vouching, ledgerItemEditItem: undefined })
	}

	onEditExpense(e, item) {
		if (this.state.expensesAggregate?.items?.length) return
		this.setState({
			expenseEditItem: item,
		})
	}

	onEditExpenseCancel() {
		this.setState({ expenseEditItem: undefined })
	}

	onEditExpenseSave(expense, splits) {
		const expensesIndex = _.findIndex(this.state.vouching.expenses, it => it.id === expense.id)
		if (expensesIndex < 0) return

		const oldExpenses = this.state.vouching.expenses[expensesIndex]
		if (
			oldExpenses.vendorName !== expense.vendorName &&
			window.confirm(`Do you want to change all unmatched instances of '${oldExpenses.vendorName}' to '${expense.vendorName}'?`)
		) {
			let vendorNameChangeCount = 0
			for (let otherExpense of this.state.vouching.expenses.filter(
				it => !["A", "M"].includes(it.status) && stringsMatch(it.vendorName, oldExpenses.vendorName)
			)) {
				vendorNameChangeCount++
				otherExpense.vendorName = expense.vendorName
			}
			clearview.ShowToast(
				"info",
				<p>
					{vendorNameChangeCount} invoices have been changed to <b>{expense.vendorName}</b>.
				</p>
			)
		}

		this.state.vouching.expenses.splice(expensesIndex, 1, expense)

		if (splits) {
			const maxSplit = _.maxBy(this.state.vouching.expenses, it => it.split || 0).split || 0

			const splitExpenses = splits.map((split, idx) => {
				const thisSplit = maxSplit + idx + 1
				const thisId = parseInt(expense.id) + thisSplit / 100
				return validateExpense(
					{
						...expense,
						id: thisId,
						split: thisSplit,
						status: null,
						...split,
					},
					this.state.propertyNames
				)
			})

			this.state.vouching.expenses.splice(expensesIndex + 1, 0, ...splitExpenses)
		}
		this.onVouchingChanged({ vouching: this.state.vouching, expenseEditItem: undefined })
	}

	onSave() {
		this.setState({ isBusy: true }, _ =>
			Promise.all([
				Api.savePeriodEndDataFile(this.props.periodEnd, "vouching/vouching", dematerialiseVouching(this.state.vouching)),
				this.updateVendorAliases(),
			]).then(() => this.loadVouching())
		)
	}

	async updateVendorAliases() {
		if (!this.state.vendorAliasesChanged) return Promise.resolve()

		const existingVendorAliases = await Api.loadPreferences(clearview.TopClientFor(this.props.periodEnd.property), "vendor-aliases", {})
		const mergedVendorAliases = _.mergeWith(existingVendorAliases, this.state.vendorAliases, (objValue, srcValue) => {
			if (_.isArray(objValue)) {
				return _.uniq(objValue.concat(srcValue))
			}
		})
		return await Api.savePreferences(clearview.TopClientFor(this.props.periodEnd.property), "vendor-aliases", mergedVendorAliases)
	}

	onCancel() {
		if (window.confirm(`Are you sure you wish to undo the unsaved changes you have made?`)) {
			this.loadVouching()
		}
	}

	checkForNewExpenseJsonFiles_Timer = null

	getNewJsonFiles() {
		return Api.findInFolders(`${this.vouchingRootUrl()}/Invoices`, `type=json`) // this is >=
			.then(folder => folder.objects?.map(it => ({ ...it, entityPath: clearview.S3EntityPath(it.s3) })) || [])
			.then(files => files.filter(it => !this.state.vouching.expenseFiles[it.entityPath]))
	}

	async checkForNewExpenseJsonFiles(isAuto = false) {
		if (!isAuto && this.checkForNewExpenseJsonFiles_Timer != null) {
			// Stop timer
			clearInterval(this.checkForNewExpenseJsonFiles_Timer)
			this.checkForNewExpenseJsonFiles_Timer = null
		}

		await this.getNewJsonFiles().then(files =>
			this.setState(prevState => ({
				newExpenseJsonFilesCount:
					!files.length && isAuto ? prevState.newExpenseJsonFilesCount : _.uniqBy(files, it => clearview.BeforeFirst(it.name, ".")).length,
			}))
		)

		if (!isAuto) {
			// Start timer - 2 minutes.
			this.checkForNewExpenseJsonFiles_Timer = setInterval(() => this.checkForNewExpenseJsonFiles(true), 120000)
		}
	}

	async onImportExpenseFiles() {
		this.setState({
			isImporting: true,
			expensesSearch: "",
		})

		let vouching = this.state.vouching

		let importedCount = 0
		let propertyVouchedCount = 0
		let expenseAnalysisRules = null

		const toastId = uuid()

		const files = await this.getNewJsonFiles()

		for (let file of files.sort(clearview.orderBy(it => it.entityPath))) {
			try {
				const simpleFilename = clearview.BeforeLast(file.entityPath, ".")
				if (vouching.expenseFiles[file.entityPath]) throw Error(`'${simpleFilename}' has already been imported!`)
				clearview.ShowToast("info", <p>Importing {simpleFilename}...</p>, null, toastId)

				const value = await Promise.all([Api.fetchPresignedUrl(file.key), Api.AnonRequest("GET")])
					.then(resps => fetch(resps[0].presignedUrl, resps[1]))
					.then(res => (res.ok ? res.json() : {}))

				if (value.JobStatus !== "SUCCEEDED") throw Error(`'${file.entityPath}' failed in OCR!`)

				if (value.AnalyzeExpenseModelVersion) {
					if (!expenseAnalysisRules)
						expenseAnalysisRules = await Api.loadPreferences(clearview.CustomerFor(this.props.periodEnd.property), "textract-analysis-rules", [])
					const processed = processExpenseAnalysis(this.state.propertyNames, this.state.vouching, value, expenseAnalysisRules)
					vouching = processed.vouching
					importedCount += processed.importedCount
				} else if (value.DetectDocumentTextModelVersion) {
					const processed = processTextDetection(this.state.propertyNames, this.state.vouching, value)
					vouching = processed.vouching
					propertyVouchedCount += processed.importedCount
				} else throw Error(`'${file.entityPath}' is not a recognised file type`)

				vouching.expenseFiles[file.entityPath] = { lastModifiedDate: file.lastModifiedDate, importedAt: new Date().toISOString() }
			} catch (err) {
				console.error(err)
				clearview.ShowToast("error", <p>{err.message || JSON.stringify(err)}</p>, null, toastId)
			}
		}

		const filter = {
			...this.state.filter,
			incomplete: true,
			notMatched: true,
		}

		clearview.ShowToast(
			importedCount || propertyVouchedCount ? "success" : "warning",
			<p>
				{importedCount} Invoice items imported. {propertyVouchedCount} property names confirmed.
			</p>,
			null,
			toastId
		)

		if (importedCount || propertyVouchedCount)
			this.onVouchingChanged({
				isImporting: false,
				importedCount,
				propertyNotVouchedCount: this.getPropertyNotVouchedCount(vouching),
				vouching,
				filter: filter,
			})
		else
			this.setState({
				isImporting: false,
				importedCount,
				propertyNotVouchedCount: undefined,
			})

		this.checkForNewExpenseJsonFiles()
	}

	async doAutoMatch() {
		const expenseAnalysisRules = await Api.loadPreferences(clearview.CustomerFor(this.props.periodEnd.property), "textract-analysis-rules", [])

		const updatedFromRules = reapplyImportRulesToExistingExpenses(
			this.state.vouching.expenses.filter(it => [null, undefined, "", "?"].includes(it.status)),
			expenseAnalysisRules
		)

		const { autoMatchCount, vouching } = AutoMatch(this.state.vouching, this.state.vendorAliases)

		const messages = []
		if (updatedFromRules)
			messages.push(`${updatedFromRules} ${updatedFromRules === 1 ? "Invoice was" : "Invoices were"} updated using the latest Import Rules.`)
		if (autoMatchCount) messages.push(`${autoMatchCount} ${autoMatchCount === 1 ? "Invoice was" : "Invoices were"} auto-matched with Expenses.`)
		if (!autoMatchCount) messages.push("No Invoices could be auto-matched with Expenses.")

		clearview.ShowToast(
			autoMatchCount ? "success" : "warning",
			<React.Fragment>
				{messages.map((it, idx) => (
					<p key={idx}>{it}</p>
				))}
			</React.Fragment>
		)

		if (autoMatchCount || updatedFromRules) {
			this.onVouchingChanged({ autoMatchCount, vouching })
		} else {
			this.setState({ autoMatchCount: 0 })
		}
	}

	onVouchingChanged(newState) {
		this.setState({
			...newState,
			isChanged: true,
			vouching: withUpdatedSummary(newState?.vouching || this.state.vouching),
			areYouSure: undefined,
		})
	}

	onQuery(item) {
		switch (isLedgerItemOrExpense(item)) {
			case "LedgerItem":
				this.setState({
					newQuery: {
						subject: "Missing Invoices",
						title: `Expenditure: ${item.account?.name || ""} ${item.date || ""} ${item.vendorName || ""} ${
							item.ref || ""
						} ${clearview.formatCurrency(item.total)}`,
						comments: `<p>${item.account?.name || ""}: ${item.date || ""} ${item.vendorName || ""} ${item.ref || ""} ${clearview.formatCurrency(
							item.total
						)}<blockquote>${item.narrative || ""}</blockquote></p>
`,
					},
				})
				break
			case "Expense":
				this.setState({
					newQuery: {
						subject: "Missing Invoices",
						title: `Invoice: ${item.date || ""} ${item.vendorName || ""} ${item.ref || ""} ${clearview.formatCurrency(item.total)}`,
						comments: `<p>${item.date || ""} ${item.vendorName || ""} ${item.ref || ""} page:${item.page} ${clearview.formatCurrency(
							item.total
						)}<blockquote>
		${item.narrative || ""}<blockquote></p>`,
					},
				})
				break

			default:
				break
		}
	}

	onLinkItems(ledgerItem, expense) {
		for (let line of this.state.vouching.ledger) {
			if (line.id === ledgerItem.id || line.isSelected) {
				line.status = "M"
				line.expenseId = expense.id
				line.expense = expense
			}
			line.isSelected = false
		}

		expense.status = "M"

		// Check for new Alias
		if (!vendorNamesMatch(expense.vendorName, ledgerItem.vendorName, this.state.vendorAliases)) {
			const newVendorNameAlias = addVendorNameAlias(expense.vendorName, ledgerItem.vendorName, this.state.vendorAliases)

			clearview.ShowToast(
				"info",
				<React.Fragment>
					<p>
						<b>{_.startCase(newVendorNameAlias.expenseVendorName)}</b> has been created as an "alias" of{" "}
						<b>{_.startCase(newVendorNameAlias.ledgerItemVendorName)}</b>.
					</p>
					<p>
						Re-running the <b>Auto-match Wizard</b> now might find other matches for you.
					</p>
				</React.Fragment>,
				10000
			)

			this.onVouchingChanged({
				expenseBeingDragged: undefined,
				selectedAggregate: undefined,
				vendorAliases: this.state.vendorAliases,
				vendorAliasesChanged: true,
			})
		} else {
			this.onVouchingChanged({
				expenseBeingDragged: undefined,
				selectedAggregate: undefined,
			})
		}
	}

	onUnlinkExpense(expenseId, refresh = true) {
		for (let line of this.state.vouching.ledger.filter(it => it.expenseId === expenseId)) {
			line.expenseId = null
			delete line.expense
			line.status = null
		}

		this.state.vouching.expenses.find(it => it.id === expenseId).status = null

		if (refresh) this.onVouchingChanged()
	}

	onUnlinkExpenseMulti(expenseIds) {
		for (let expenseId of expenseIds) {
			this.onUnlinkExpense(expenseId, false)
		}
		this.onVouchingChanged()
	}

	onConfirmLinked(item, refresh = true) {
		switch (isLedgerItemOrExpense(item)) {
			case "LedgerItem":
				item.expense.status = "M"
				for (let ledgerItem of this.state.vouching.ledger.filter(it => it.expenseId === item.expenseId)) {
					ledgerItem.status = "M"
				}
				break
			case "Expense":
				item.status = "M"
				for (let ledgerItem of this.state.vouching.ledger.filter(it => it.expenseId === item.id)) {
					ledgerItem.status = "M"
				}
				break
			default:
				break
		}

		if (refresh) this.onVouchingChanged()
	}

	onSetOtherStatus(item, status, refresh = true) {
		item.status = item.status !== status ? status : null
		switch (isLedgerItemOrExpense(item)) {
			case "LedgerItem":
				validateLedgerItem(item)
				break
			case "Expense":
				validateExpense(item, this.state.propertyNames)
				break
			default:
				break
		}
		if (refresh) this.onVouchingChanged()
	}

	onDismissWarning(item, refresh = true) {
		item.warning.severity = "warning"
		validateExpense(item, this.state.propertyNames)
		if (refresh) this.onVouchingChanged()
	}

	onQueryMulti(items) {
		switch (isLedgerItemOrExpense(items[0])) {
			case "LedgerItem":
				this.setState({
					newQuery: {
						subject: "Missing Invoices",
						title: `Expenditure: ${items.length} items`,
						comments: items
							.map(
								(item, idx) =>
									`<p>${idx + 1}. ${item.account?.name || ""}: ${item.date || ""} ${item.vendorName || ""} ${
										item.ref || ""
									} ${clearview.formatCurrency(item.total)}<blockquote>${item.narrative || ""}</blockquote></p>`
							)
							.join(""),
					},
				})
				break
			case "Expense":
				this.setState({
					newQuery: {
						subject: "Missing Invoices",
						title: `Invoices: ${items.length}`,
						comments: items
							.map(
								(item, idx) =>
									`<p>${idx + 1}. ${item.date || ""} ${item.vendorName || ""} ${item.ref || ""} page:${item.page} ${clearview.formatCurrency(
										item.total
									)}<blockquote>${item.narrative || ""}</blockquote></p>`
							)
							.join(""),
					},
				})
				break
			default:
				break
		}
	}

	onConfirmLinkedMulti(items) {
		for (let item of items) {
			this.onConfirmLinked(item, false)
		}
		this.onVouchingChanged()
	}

	onSetOtherStatusMulti(items, status) {
		for (let item of items) {
			this.onSetOtherStatus(item, status, false)
		}
		this.onVouchingChanged()
	}

	onDismissWarningMulti(items) {
		for (let item of items) {
			this.onDismissWarning(item, false)
		}
		this.onVouchingChanged()
	}

	onMergeAdjacentInvoices(items) {
		const item = items.reduce((prev, next) => {
			if (!prev) {
				next.isSelected = false
				return next
			} else {
				prev.toPage = next.toPage
				prev.total = prev.total + next.total
				prev.summaryFields = prev.summaryFields.concat(next.summaryFields)
				prev.lineItemGroups = prev.lineItemGroups.concat(next.lineItemGroups)

				next.status = "X"
				next.isSelected = false
				return prev
			}
		}, null)

		this.setState({ expensesAggregate: undefined, expenseEditItem: item })
	}

	onLedgerItemSelected(item) {
		item.isSelected = !item.isSelected

		this.setState({ selectedAggregate: calculateSelectedAggregate(this.state.vouching) })
	}

	onExpenseSelected(item) {
		item.isSelected = !item.isSelected

		this.setState({ expensesAggregate: calculateExpensesAggregate(this.state.vouching) })
	}

	onShowExpense(ledgerItem) {
		this.setState({
			expensesSearch: `id: ${ledgerItem.expense.id}`,
		})
	}
	onShowLedgerItems(expense) {
		this.setState({
			ledgerSearch: `expenseId: ${expense.id}`,
		})
	}

	onDeselectAllLedgerItems() {
		for (let item of this.state.vouching.ledger) item.isSelected = false
		this.setState({ selectedAggregate: calculateSelectedAggregate(this.state.vouching) })
	}

	onDeselectAllExpenses() {
		for (let item of this.state.vouching.expenses) item.isSelected = false
		this.setState({ expensesAggregate: calculateExpensesAggregate(this.state.vouching) })
	}

	onDragStart(e) {
		this.setState(prevState => {
			return { expenseBeingDragged: prevState.vouching.expenses.find(it => it.id == e.draggableId) }
		})
	}

	onDragEnd(result) {
		const { destination } = result

		// dropped outside the list
		if (!destination) {
			this.setState({ expenseBeingDragged: undefined })
			return
		}

		// Handle List Items
		const ledgerItem = this.state.vouching.ledger.find(it => it.id === destination.droppableId)
		this.onLinkItems(ledgerItem, this.state.expenseBeingDragged)
	}

	searchPredicate = (item, searchSpec) => {
		if (!searchSpec) return true

		const specialFilter = searchSpec.toString().match(PROPERTY_FILTER_REGEX)

		if (specialFilter) {
			switch (specialFilter[1]) {
				case "id":
				case "expenseId":
					return item[specialFilter[1]] === specialFilter[2]
				default:
					const specialRegex = clearview.Search.SafeRegex(specialFilter[2], "gi")
					return item[specialFilter[1]]?.toString().match(specialRegex)
			}
		}

		const regex = clearview.Search.SafeRegex(searchSpec, "gi")
		if (item.vendorName?.match(regex)) return true
		if (item.ref?.match(regex)) return true
		if (item.date?.match(regex)) return true
		if (clearview.formatCurrency(item.total)?.match(regex)) return true
		if (item.narrative?.match(regex)) return true
		if (item.account?.name?.match(regex)) return true
		if (clearview.S3EntityPath(item?.s3)?.match(regex)) return true
		return false
	}

	render() {
		const { periodEnd } = this.props
		const { sort, vouching, ledgerSearch, expensesSearch, expenseBeingDragged } = this.state

		if (!periodEnd.id) {
			return <Spinner />
		}

		if (!vouching || vouching.id !== periodEnd.id) {
			this.loadVouching()
			return <Spinner />
		}

		const ledger = this.sortBy(
			vouching.ledger?.filter(it => this.searchPredicate(it, ledgerSearch)),
			sort.ledger
		)

		const expenses = this.sortBy(
			vouching.expenses?.filter(it => this.searchPredicate(it, expensesSearch)),
			sort.expenses
		)

		return (
			<VouchingDetailsContext.Provider value={this.state}>
				{periodEnd.currentStage && (
					<Vouching_NewQueryForm
						periodEnd={periodEnd}
						stage={periodEnd.currentStage}
						isOpen={!!this.state.newQuery}
						onClose={e => this.setState({ newQuery: undefined })}
						initial={this.state.newQuery}
					/>
				)}
				<PropertyNames_Form
					isOpen={!!this.state.propertyNotVouchedCount}
					count={this.state.propertyNotVouchedCount}
					property={periodEnd.property}
					propertyNames={this.state.propertyNames}
					onSave={this.onChangePropertyNames}
					onCancel={e => this.setState({ propertyNotVouchedCount: undefined })}
				/>
				<QueueManager target="queueManagerButton" />
				<Container className={this.state.isBusy || this.state.isImporting ? "isBusy" : ""}>
					{this.state.isBusy && <Spinner key="spinner"></Spinner>}
					<AnimatedProgressBar isVisible={this.state.isImporting}></AnimatedProgressBar>
					<Prompt
						when={this.state.isChanged}
						message={() => "You have made changes to the Vouching data.\r\rAre you sure you want to leave this page and discard your changes?"}
						onLeave={this.loadVouching}
					/>
					<HeaderMain
						superTitle={
							<span>
								<PeriodEndUnread periodEnd={periodEnd} />
								{periodEnd.reference}
								<sup>
									<PeriodEndFavourite periodEnd={periodEnd} />
								</sup>
							</span>
						}
						title={
							<span className="text-primary d-flex-row justify-content-start">
								<span
									hidden={!vouching.isBalanced}
									title="Balanced"
									style={{ fontSize: "0.8em", lineHeight: "0.8em", marginTop: "0.3em", marginRight: "0.2em" }}
								>
									{clearview.Icon.balanced}
								</span>
								<span
									hidden={vouching.isBalanced}
									className="text-warning"
									title="Not balanced"
									style={{
										fontSize: "0.8em",
										lineHeight: "0.8em",
										marginLeft: "-0.2em",
										marginTop: "-0.2em",
										marginBottom: "-0.3em",
										marginRight: "-0.3em",
									}}
								>
									{clearview.Icon.unbalanced}
								</span>
								<span>{periodEnd.property?.name}</span>
								<span
									className="ml-2 h2 text-primary hover"
									id="editPropertyNames"
									title="View/edit Property Names"
									onClick={e => {
										this.setState({ propertyNotVouchedCount: this.getPropertyNotVouchedCount(vouching) || -1 })
									}}
								>
									{clearview.Icon.propertyNames}
								</span>
							</span>
						}
						subTitle={<span className="text-primary">Vouching: {(periodEnd.template || {}).name}</span>}
						superActions={
							<span id="queueManagerButton" title="Show/hide Queue Status" className="hover">
								{clearview.Icon.QueueManager}
							</span>
						}
						className="mb-4 mt-0"
					/>
					<Row>
						<Col lg={12}>
							<Card>
								{vouching.summary && (
									<CardHeader className="d-flex-row justify-content-between">
										<div className="flex-1 mr-4 align-self-center">
											<VouchingFilters vouching={this.state.vouching} />
										</div>
										<div className="d-flex-row justify-content-end" style={{ alignItems: "center" }}>
											<EditSaveOrCancel
												isReadOnly={periodEnd.status === "Closed"}
												isEditing={periodEnd.status !== "Closed"}
												isChanged={this.state.isChanged}
												onSave={this.onSave}
												onCancel={this.onCancel}
											/>
										</div>
									</CardHeader>
								)}
								<CardBody>
									<DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
										<div className={expenseBeingDragged ? "row dragging-is-happening" : "row"}>
											<Column
												size={6}
												id="Ledger"
												index={0}
												label="Expenditure"
												title={`Import Expenditure Report for ${periodEnd.reference}`}
												key="Ledger"
												isReadOnly={periodEnd.status === "Closed"}
												action={() => this.onImportLedger()}
												actions={[
													<Button
														key="onClear"
														color="transparent"
														disabled={!this.state.vouching.ledger?.length}
														className="ml-3"
														style={{ padding: 0, border: 0 }}
														outline={false}
														onClick={e => {
															clearview.StopPropagation(e)
															this.onClearAllLedger()
														}}
													>
														<Avatar.Font
															size="md"
															className="d-block avatar-with-text avatar-button"
															bgColor="danger"
															title="Clear all Expenditure"
														>
															{clearview.Icon.delete}
														</Avatar.Font>
													</Button>,
												]}
												searchSelector={
													<VouchingItemsSearch
														key="ledger-search"
														propertySearchRegex={PROPERTY_FILTER_REGEX}
														searchValue={ledgerSearch}
														onSearchChanged={this.setLedgerSearch}
													/>
												}
												sortSelector={
													<VouchingItemsSort
														key="ledger-sort"
														keys={["status", "vendorName", "date", "narrative", "ref", "total"]}
														sortSpec={sort.ledger}
														onSpecChanged={this.setLedgerSort}
														onSelectAll={this.selectAllLedger}
													/>
												}
											>
												<LedgerList
													key="ledger"
													listId={"Ledger"}
													items={ledger}
													itemActionsFn={this.ledgerListActions}
													onItemSelected={this.onLedgerItemSelected}
													onDeselectAll={this.onDeselectAllLedgerItems}
													onEditLedgerItem={this.onEditLedgerItem}
													onShowExpense={this.onShowExpense}
												/>
											</Column>

											<Column
												size={6}
												id="Invoices"
												index={1}
												label="Invoices"
												isReadOnly={periodEnd.status === "Closed"}
												title={`${periodEnd.reference}: Invoices`}
												folder={`${this.vouchingRootUrl()}/Invoices`}
												actions={[
													<Button
														key="checkForNewExpenseJsonFiles"
														color="transparent"
														className="ml-3"
														style={{ padding: 0, border: 0 }}
														outline={false}
														id={clearview.MakeSafeFolderId(this.props.folder)}
														onClick={e => this.checkForNewExpenseJsonFiles()}
													>
														<Avatar.Font
															size="md"
															className="d-block avatar-with-text avatar-button"
															bgColor="primary"
															title="Check for new invoice data to import"
															addOns={
																this.state.newExpenseJsonFilesCount !== undefined
																	? [
																			<AvatarAddOn.Badge
																				pill
																				color="danger"
																				key="avatar-badge"
																				title={`${this.state.newExpenseJsonFilesCount} new invoice files are ready.`}
																			>
																				{this.state.newExpenseJsonFilesCount}
																			</AvatarAddOn.Badge>,
																	  ]
																	: []
															}
														>
															{clearview.Icon.refresh}
														</Avatar.Font>
													</Button>,
													<Button
														key="onImportExpenseFiles"
														color="transparent"
														className="ml-3"
														style={{ padding: 0, border: 0 }}
														outline={false}
														id={clearview.MakeSafeFolderId(this.props.folder)}
														onClick={e => this.onImportExpenseFiles()}
														disabled={!this.state.newExpenseJsonFilesCount}
													>
														<Avatar.Font
															size="md"
															className="d-block avatar-with-text avatar-button"
															bgColor="primary"
															title="Import new invoice data"
															addOns={
																this.state.importedCount !== undefined
																	? [
																			<AvatarAddOn.Badge
																				pill
																				color="warning"
																				key="avatar-badge"
																				title={`${this.state.importedCount} new invoices have been imported.`}
																			>
																				{this.state.importedCount}
																			</AvatarAddOn.Badge>,
																	  ]
																	: []
															}
														>
															{clearview.Icon.download}
														</Avatar.Font>
													</Button>,
													<Button
														key="doAutoMatch"
														color="transparent"
														className="ml-3"
														style={{ padding: 0, border: 0 }}
														outline={false}
														id="doAutoMatch"
														onClick={e => this.doAutoMatch()}
													>
														<Avatar.Font
															size="md"
															className="d-block avatar-with-text avatar-button"
															bgColor="primary"
															title="Perform Auto-Match"
															addOns={
																this.state.autoMatchCount !== undefined
																	? [
																			<AvatarAddOn.Badge
																				pill
																				color={STATUS_COLOR.A}
																				key="avatar-badge"
																				title={`${this.state.autoMatchCount} invoices have been matched.`}
																			>
																				{this.state.autoMatchCount}
																			</AvatarAddOn.Badge>,
																	  ]
																	: []
															}
														>
															{clearview.Icon.wizard}
														</Avatar.Font>
													</Button>,
													<Button
														key="onClear"
														color="transparent"
														disabled={!this.state.vouching.expenses?.length}
														className="ml-3"
														style={{ padding: 0, border: 0 }}
														outline={false}
														onClick={e => {
															clearview.StopPropagation(e)
															this.onClearAllExpenses()
														}}
													>
														<Avatar.Font
															size="md"
															className="d-block avatar-with-text avatar-button"
															bgColor="danger"
															title="Clear all Invoices"
														>
															{clearview.Icon.delete}
														</Avatar.Font>
													</Button>,
												]}
												searchSelector={
													<VouchingItemsSearch
														key="expenses-search"
														propertySearchRegex={PROPERTY_FILTER_REGEX}
														searchValue={expensesSearch}
														onSearchChanged={this.setExpensesSearch}
													/>
												}
												sortSelector={
													<VouchingItemsSort
														key="expenses-sort"
														keys={["index", "status", "vendorName", "date", "narrative", "ref", "total"]}
														sortSpec={sort.expenses}
														onSpecChanged={this.setExpensesSort}
														onSelectAll={this.selectAllExpenses}
													/>
												}
											>
												<ExpenseList
													listId={"Expense"}
													items={expenses}
													itemActionsFn={this.expenseListActions}
													onItemSelected={this.onExpenseSelected}
													onDeselectAll={this.onDeselectAllExpenses}
													onEditExpense={this.onEditExpense}
													onShowLedgerItems={this.onShowLedgerItems}
												/>
											</Column>
										</div>
									</DragDropContext>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col lg={12} className="mt-2 text-right">
							<VouchingActions
								key="VouchingActions"
								isChanged={this.state.isChanged}
								periodEnd={this.props.periodEnd}
								vouching={this.state.vouching}
							/>
						</Col>
					</Row>
				</Container>
				<AreYouSureModal
					isOpen={!!this.state.areYouSure}
					message={this.state.areYouSure?.message}
					action={this.state.areYouSure?.action}
					cancel={this.state.areYouSure?.cancel}
				></AreYouSureModal>
				<LedgerItemEdit
					ledgerItem={this.state.ledgerItemEditItem}
					accounts={this.state.vouching.accounts}
					actions={this.ledgerListActions}
					onCancel={this.onEditLedgerItemCancel}
					onSave={this.onEditLedgerItemSave}
				></LedgerItemEdit>
				<ExpenseEdit
					property={this.props.periodEnd.property}
					propertyNames={this.state.propertyNames}
					expense={this.state.expenseEditItem}
					actions={this.expenseListActions}
					onCancel={this.onEditExpenseCancel}
					onSave={this.onEditExpenseSave}
				></ExpenseEdit>
				<ImportLedger_Form
					isOpen={!!this.state.importLedgerIsOpen}
					business={this.props.periodEnd.property}
					onCancel={this.onImportLedgerCancel}
					onUploaded={this.onImportLedgerSave}
				></ImportLedger_Form>
			</VouchingDetailsContext.Provider>
		)
	}
}

function VouchingDetails() {
	const periodEnd = useLoaderData()
	return <VouchingDetailsComponent periodEnd={periodEnd} />
}

export default VouchingDetails

function calculateSelectedAggregate(vouching) {
	return vouching.ledger
		.filter(it => it.isSelected)
		.reduce(
			(prev, next) => ({
				items: prev.items.concat([next]),
				vendorName: !prev.vendorName || next.vendorName === prev.vendorName ? next.vendorName : "*** multiple suppliers ***",
				narrative: !prev.narrative || next.narrative === prev.narrative ? next.narrative : "*** multiple narratives ***",
				total: prev.total + next.total,
			}),
			{
				items: [],
				vendorName: undefined,
				narrative: undefined,
				total: 0,
			}
		)
}

function calculateExpensesAggregate(vouching) {
	return vouching.expenses
		.filter(it => it.isSelected)
		.sort(clearview.orderBy(it => it.id))
		.reduce(
			(prev, next) => {
				return {
					items: prev.items.concat([next]),
					vendorName: !prev.vendorName || next.vendorName === prev.vendorName ? next.vendorName : "*** multiple suppliers ***",
					narrative: !prev.narrative || next.narrative === prev.narrative ? next.narrative : "*** multiple narratives ***",
					total: prev.total + next.total,
					s3: next.s3,
					page: prev.s3 === undefined ? next.page : prev.s3 === next.s3 && prev.toPage + 1 === next.page ? prev.page : undefined,
					toPage: prev.s3 === undefined ? next.toPage : prev.s3 === next.s3 && prev.toPage + 1 === next.page ? next.toPage : undefined,
				}
			},
			{
				items: [],
				vendorName: undefined,
				narrative: undefined,
				total: 0,
				s3: undefined,
				page: 0,
				toPage: 0,
			}
		)
}

function calculateSummaryForCollection(collection) {
	return {
		unmatchedCount: collection.filter(it => !it.status).length || 0,
		unmatchedTotal:
			_.sumBy(
				collection.filter(it => !it.status),
				it => it.total
			) || 0,
		matchedCount: collection.filter(it => ["A", "M"].includes(it.status)).length || 0,
		matchedTotal:
			_.sumBy(
				collection.filter(it => ["A", "M"].includes(it.status)),
				it => it.total
			) || 0,
		fromDate: _.minBy(collection, it => it.date)?.date,
		toDate: _.maxBy(collection, it => it.date)?.date,
	}
}

function calculateSummary(vouching) {
	return {
		ledger: calculateSummaryForCollection(vouching.ledger),
		expenses: calculateSummaryForCollection(vouching.expenses),
	}
}

function withUpdatedSummary(vouching) {
	vouching.summary = calculateSummary(vouching)
	vouching.isBalanced =
		vouching.summary.ledger.matchedTotal === vouching.summary.expenses.matchedTotal &&
		vouching.ledger.length &&
		vouching.ledger.filter(it => !it.status).length === 0
	return vouching
}

export function materialiseVouching(vouching, periodEnd = null) {
	if (vouching.ledgerFiles === undefined) vouching.ledgerFiles = {}
	if (vouching.expenseFiles === undefined) vouching.expenseFiles = {}
	if (vouching.accounts === undefined) vouching.accounts = []
	if (vouching.ledger === undefined) vouching.ledger = []
	if (vouching.expenses === undefined) vouching.expenses = []

	vouching.ledger.forEach(ledgerItem => {
		ledgerItem.account = vouching.accounts?.find(a => a.id === ledgerItem.accountId)
		ledgerItem.expense = ledgerItem.expenseId ? vouching.expenses.find(ex => ex.id === ledgerItem.expenseId) : undefined
		ledgerItem.isSelected = false
	})

	vouching.expenses.forEach(expense => {
		expense.isSelected = false
	})

	if (periodEnd) {
		if (vouching.id && vouching.id !== periodEnd.id) throw "Vouching Data is not for this Period End! Contact your administrator!"
	}

	return withUpdatedSummary(vouching)
}

function dematerialiseVouching(vouching) {
	vouching.ledger.forEach(ledgerItem => {
		delete ledgerItem.account
		delete ledgerItem.expense
		delete ledgerItem.isSelected
	})
	vouching.expenses.forEach(expense => {
		delete expense.isSelected
	})
	delete vouching.summary
	delete vouching.isBalanced
	return vouching
}
