import React from "react"

import { connect } from "react-redux"
import { Typeahead } from "react-bootstrap-typeahead"
import PropTypes from "prop-types"
import _ from "lodash"

import {
	Button,
	Form,
	FormGroup,
	FormFeedback,
	FormText,
	Input,
	InputGroup,
	InputGroupAddon,
	CustomInput,
	Container,
	UncontrolledModal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "../../../../components"

import { S3Folder } from "../../components/s3/S3Folder"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import CommentsEditor from "./CommentsEditor"

export const DEFAULT_ASSIGN_TO_KEY = "Vouching_NewQueryForm.default.assignTo"

class Stage_NewQueryForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		initial: PropTypes.object,
		raiseQuery: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		let openStages = props.periodEnd.stages.filter(it => it.status !== "Closed")
		if (!openStages.length) openStages = [_.last(props.periodEnd.stages)]

		this.actors = _.union(props.periodEnd.property.actors.inHouse, props.periodEnd.property.actors.client)
		const defaultAssignTo = this.actors.find(
			it => clearview.LoadLocal(DEFAULT_ASSIGN_TO_KEY, null) && it.username === clearview.LoadLocal(DEFAULT_ASSIGN_TO_KEY, null)
		)?.username

		this.handleStageChange = this.handleStageChange.bind(this)
		this.handleAssignToChange = this.handleAssignToChange.bind(this)
		this.handleTitleChange = this.handleTitleChange.bind(this)
		this.handleSubjectChange = this.handleSubjectChange.bind(this)
		this.handleQuillChange = this.handleQuillChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)

		const initial = this.props.initial
		this.state = {
			openStages: openStages,
			stage: props.stage,
			subject: initial?.subject || clearview.QuerySubjects[0],
			title: initial?.title || "",
			comments: initialComments(initial),
			assignTo: initial?.assignTo || defaultAssignTo,
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (state.savedQueryNumber && !state.thisQuery)
			return {
				...state,
				thisQuery: _.find(
					props.periodEnd.stages.flatMap(it => it.comments || []),
					it => it.number === state.savedQueryNumber
				),
			}

		return null
	}

	handleSubjectChange(event) {
		this.setState({
			subject: event.target.value,
		})
	}

	handleTitleChange(event) {
		this.setState({
			title: event.target.value,
		})
	}

	handleQuillChange(value) {
		this.setState({
			comments: value,
		})
	}

	handleStageChange(e) {
		this.setState({
			stage: _.find(this.state.openStages, it => it.id === parseInt(e.target.value)),
		})
	}

	handleAssignToChange(option) {
		const value = option[0] ? option[0].username : null
		if (value) {
			clearview.SaveLocal(DEFAULT_ASSIGN_TO_KEY, value)
		}

		this.setState({
			assignTo: value,
		})
	}

	handleActionButtonClick(event) {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}

		if (event.target.id === "addAttachments") {
			const savedQueryNumber = (_.max(this.props.periodEnd.stages.flatMap(it => it.comments || []).map(it => it.number)) || 0) + 1

			this.setState({
				savedQueryNumber,
				thisQuery: undefined,
			})
		}

		if (this.state.thisQuery)
			this.props.updateQuery({
				id: this.state.thisQuery.id,
				assignTo: this.state.assignTo,
				periodEnd: this.props.periodEnd,
				stage: this.state.stage,
				subject: this.state.subject,
				title: this.state.title,
				comments: this.state.comments,
				isDraft: event.target.name === "draft",
			})
		else
			this.props.raiseQuery({
				assignTo: this.state.assignTo,
				periodEnd: this.props.periodEnd,
				stage: this.state.stage,
				subject: this.state.subject,
				title: this.state.title,
				comments: this.state.comments,
				isDraft: event.target.name !== "send" && this.props.stage.actions.includes("DraftQuery"),
			})

		if (event.target.id !== "addAttachments")
			this.setState({
				savedQueryNumber: undefined,
				thisQuery: undefined,
				subject: clearview.QuerySubjects[0],
				title: "",
				comments: "",
				assignTo: clearview.LoadLocal(DEFAULT_ASSIGN_TO_KEY, null),
			})
		return true
	}

	get isSubjectRequired() {
		return !(this.state.subject && this.state.subject.length)
	}

	get isTitleRequired() {
		return !(this.state.title && this.state.title.length)
	}

	get isStageRequired() {
		return !this.state.stage
	}

	get isAssignToRequired() {
		return !(this.state.assignTo && this.state.assignTo.length)
	}

	get isValid() {
		return !this.isTitleRequired && !this.isSubjectRequired && !this.isAssignToRequired && !this.isStageRequired
	}

	render() {
		const selectedAssignTo = this.actors?.filter(it => it.username === this.state.assignTo)

		return (
			<UncontrolledModal
				style={{ maxWidth: 700 }}
				target={`stage${this.props.stage.id}_NewQueryForm${this.props.initial ? this.props.initial.id : ""}`}
				className={this.state.savedQueryNumber && !this.state.thisQuery ? "isBusy" : ""}
			>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">{this.state.At ? "Edit Query" : "Raise a Query"}</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Resolve by Stage</InputGroupAddon>
								<CustomInput
									type="select"
									id="stage"
									name="stage"
									className="ml-auto"
									defaultValue={this.state.stage.id}
									onChange={e => this.handleStageChange(e)}
								>
									{this.state.openStages.map((it, idx) => (
										<option key={idx} value={it.id}>
											{it.templateStage.sequence}: {it.templateStage.name}
										</option>
									))}
								</CustomInput>
							</InputGroup>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">To</InputGroupAddon>
								<Typeahead
									id="assignTo"
									labelKey="description"
									options={this.actors}
									placeholder="Choose a Recipient..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									selected={selectedAssignTo}
									onChange={this.handleAssignToChange}
								/>
							</InputGroup>
							<FormText hidden={!this.state.showValidation || !this.isAssignToRequired} className="text-danger">
								Queries must be assigned to an individual or team
							</FormText>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Subject</InputGroupAddon>
								<CustomInput
									invalid={this.state.showValidation && this.isSubjectRequired}
									type="select"
									id="subject"
									name="subject"
									className="ml-auto"
									defaultValue=""
									onChange={e => this.handleSubjectChange(e)}
								>
									{clearview.QuerySubjects.map((it, idx) => (
										<option key={idx}>{it}</option>
									))}
								</CustomInput>
								<FormFeedback>You must specify the query subject</FormFeedback>
							</InputGroup>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Title</InputGroupAddon>
								<Input
									invalid={this.state.showValidation && this.isTitleRequired}
									type="text"
									name="title"
									id="title"
									placeholder="Title..."
									value={this.state.title}
									onChange={this.handleTitleChange}
								/>
								<FormFeedback>A short title is required</FormFeedback>
							</InputGroup>
						</FormGroup>
						<FormGroup row>
							<CommentsEditor value={this.state.comments} onChange={this.handleQuillChange} />
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<Button
									disabled={!this.isValid}
									hidden={this.state.savedQueryNumber}
									color="secondary"
									id="addAttachments"
									onClick={this.handleActionButtonClick}
								>
									Add Attachments...
								</Button>
								<Container hidden={!this.state.thisQuery} className="mt-2">
									<S3Folder
										folder={clearview.queryFolder(this.state.stage, this.state.thisQuery)}
										label="Attachments"
										title="Query Attachments"
										className="query"
									></S3Folder>
								</Container>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel hidden={this.state.thisQuery} color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close
							hidden={!this.props.stage.actions.includes("DraftQuery")}
							color="default"
							className="text-primary"
							disabled={!this.isValid}
							name="draft"
							onClick={this.handleActionButtonClick}
						>
							Save As Draft
						</UncontrolledModal.Close>
						<UncontrolledModal.Close
							color="default"
							className="text-primary"
							disabled={!this.isValid}
							name="send"
							onClick={this.handleActionButtonClick}
						>
							{this.props.stage.actions.includes("DraftQuery") ? "Send" : "Save"}
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

function initialComments(query) {
	if (!query) return ""

	return [
		`${query.comments}<p>${(query.updatedBy || query.createdBy).name} ${clearview.FormatDateAndTime(query.createdAt)}<p>`,
		...query.replies.map(
			it =>
				`<blockquote>${it.comments}<p>${(it.updatedBy || it.createdBy).name} ${clearview.FormatDateAndTime(
					it.updatedAt || it.createdAt
				)}</p></blockquote>`
		),
	].join("<br /><br />")
}

const mapDispatchToProps = dispatch => ({
	raiseQuery: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_RAISE_QUERY,
			...payload,
		}),

	updateQuery: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE_QUERY,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Stage_NewQueryForm)
