import React from "react"
import PropTypes from "prop-types"

import * as clearview from "../../../../components/@Clearview"

import { Accordion, Table } from "../../../../components"

import { Address } from "../../../components/Address"

import { S3Folder } from "../../components/s3/S3Folder"

import ClientActions from "../../Clients/components/ClientActions"

export class ClientCard extends React.Component {
	static propTypes = {
		client: PropTypes.object.isRequired,
		isCompact: PropTypes.bool,
	}

	constructor(props) {
		super(props)
		this.props = props
	}

	render() {
		const { client } = this.props

		return (
			<Accordion className="mb-2" initialOpen={true}>
				<Accordion.Header className="d-flex h6 justify-content-between">
					<span className="align-self-center">
						{clearview.Icon.Business[client.role]} {client.role}
					</span>
					<ClientActions client={client} />
				</Accordion.Header>
				<Accordion.Body>
					<Table size="sm">
						<tbody>
							<tr>
								<th colSpan={2}>{clearview.GetBusinessTitle(client)}</th>
							</tr>
							{client.reference && (
								<tr>
									<td className="align-top">Ref:</td>
									<td className="text-right" title={`Client #${client.id}`}>
										{client.reference}
									</td>
								</tr>
							)}
							{client.parent?.role === "Managing Agent" && (
								<tr>
									<td className="align-top">{client.parent.role}</td>
									<td className="text-right">{client.parent.name}</td>
								</tr>
							)}
							<tr>
								<td className="align-top">Name</td>
								<td className="text-right">{client.name}</td>
							</tr>
							{client.address && (
								<tr>
									<td className="align-top">Address</td>
									<td className="text-left">
										<Address key={client.address?.id || clearview.GenerateKey()} address={client.address} name="address" />
									</td>
								</tr>
							)}
						</tbody>
					</Table>

					{/* <AttributesTable key={client.id} business={client} name="attributes" />

					{/* {client.teams &&
						client.teams.filter(it => it.type === "Users").map(team => <UsersList key={team.username} business={client} team={team} />)} */}
					<S3Folder folder={`/client/${this.props.client.id}`} label={`${client.role} Files`} className="in-card"></S3Folder>
				</Accordion.Body>
			</Accordion>
		)
	}
}
