import React, { useState } from "react"
import { connect } from "react-redux"
import { WP_ACTIONS } from "./store"

import * as clearview from "../../../../components/@Clearview"

import FileUploadButton, { excelSheetReader } from "../../Workflows/components/FileUploadButton"
import { CommonTable, AddUpdateScheduleCodeTotal } from "./components"

function BudgetsPage({ isReadOnly, budgets, onDelete, onSave, onReadWorksheet }) {
	const [rowEditor, setRowEditor] = useState(false)

	const onAdd = isReadOnly
		? false
		: defaultValue => {
				setRowEditor({ title: "Add New Budget Item", value: { schedule: defaultValue.schedule, code: undefined, total: 0 } })
		  }

	const onEdit = isReadOnly
		? false
		: row => {
				setRowEditor({ title: "Edit Budget Item", value: { ...row } })
		  }

	if (isReadOnly) onDelete = false

	const onCancel = e => {
		setRowEditor(false)
	}

	return (
		<React.Fragment>
			<div className="mt-2">
				<CommonTable data={budgets} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} />
			</div>
			{!isReadOnly && (
				<FileUploadButton className="mt-2" onUploaded={excelSheetReader(onReadWorksheet)} accept=".xls,.xlsx,.csv">
					{clearview.Icon.upload} Import Budgets...
				</FileUploadButton>
			)}
			<AddUpdateScheduleCodeTotal
				name="rowEditor"
				config={{ title: rowEditor?.title }}
				isOpen={!!rowEditor}
				onCancel={onCancel}
				onSave={row => {
					onSave(row)
					onCancel()
				}}
				value={rowEditor?.value}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		budgets: state.workingPapersReducer.workingPapers.budgets,
	}
}

const mapDispatchToProps = dispatch => ({
	onDelete: row =>
		dispatch({
			type: WP_ACTIONS.DELETE_ROW,
			page: "budgets",
			row,
		}),

	onSave: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_ROW,
			page: "budgets",
			row,
		}),

	onReadWorksheet: (file, data, worksheetName) =>
		dispatch({
			type: WP_ACTIONS.READ_COMMON_WORKSHEET,
			page: "budgets",
			file,
			data,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(BudgetsPage)
