import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import paginationFactory from "../../../../react-bootstrap-table2-paginator"
import moment from "moment"

import * as clearview from "../../../../components/@Clearview"

import { ButtonGroup } from "../../../../components"

import { QueriesTableBody } from "./QueriesTableBody"

import { CustomExportCSV } from "./CustomExportButton"
import { CustomSearch } from "./CustomSearch"

import { QueriesFilter } from "./QueriesFilter"

import PropTypes from "prop-types"

class QueriesTable extends React.Component {
	static propTypes = {
		queries: PropTypes.array.isRequired,
		filter: PropTypes.object,
	}

	constructor(props) {
		super(props)

		this.state = {
			predicate: it => true,
		}

		this.onFilterChange = this.onFilterChange.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	onFilterChange(predicate) {
		this.setState({
			predicate: predicate,
		})
	}

	onSearch(value) {
		this.setState({
			search: value,
		})
	}

	searchPredicate = query => {
		if (!this.state.search) return true

		const regex = clearview.Search.SafeRegex(this.state.search)

		return (
			clearview.Search.Comment(query, regex) ||
			clearview.Search.Stage(query.stage, regex) ||
			clearview.Search.PeriodEnd(query.stage.periodEnd, regex) ||
			clearview.Search.Business(query.stage.periodEnd.property, regex)
		)
	}

	render() {
		const queries = this.props.queries.filter(q => this.state.predicate(q)).filter(this.searchPredicate)

		const filterMessage = `${queries.length} of ${this.props.queries.length}`

		return (
			<React.Fragment>
				<ToolkitProvider
					keyField="uniq"
					data={queries.flatMap((query, idx) =>
						[{ ...query, uniq: `Q~${idx}` }].concat(query.replies.map((r, idx2) => ({ ...r, query: query, uniq: `Q~${query.id}~R~${idx2}` })))
					)}
					columns={[
						{ dataField: "type", text: "Type" },
						{ dataField: "reference", text: "Ref.", csvFormatter: (col, row) => row.reference || row.query.reference },
						{ dataField: "subject", text: "Subject" },
						{ dataField: "title", text: "Title" },
						{ dataField: "comments", text: "Text" },
						{ dataField: "createdBy.name", text: "By" },
						{ dataField: "createdAt", text: "At" },
						{ dataField: "assignedTo.name", text: "Assigned To", csvFormatter: (col, row) => col || row.query.assignedTo.name },

						{ dataField: "stage.periodEnd.reference", text: "Period End", csvFormatter: (col, row) => col || row.query.stage.periodEnd.reference },
						{
							dataField: "stage.periodEnd.template.name",
							text: "Template",
							csvFormatter: (col, row) => col || row.query.stage.periodEnd.template.name,
						},
						{
							dataField: "stage.periodEnd.property.reference",
							text: "Property Ref.",
							csvFormatter: (col, row) => col || row.query.stage.periodEnd.property.reference,
						},
						{
							dataField: "stage.periodEnd.property.name",
							text: "Property",
							csvFormatter: (col, row) => col || row.query.stage.periodEnd.property.name,
						},
						{
							dataField: "stage.periodEnd.property.parent.reference",
							text: "Client/Landlord Ref.",
							csvFormatter: (col, row) => col || row.query.stage.periodEnd.property.parent.reference,
						},
						{
							dataField: "stage.periodEnd.property.parent.name",
							text: "Client/Landlord Name",
							csvFormatter: (col, row) => col || row.query.stage.periodEnd.property.parent.name,
						},

						{
							dataField: "stage.templateStage.sequence",
							text: "Stage #",
							csvFormatter: (col, row) => col || row.query.stage.templateStage.sequence,
						},
						{ dataField: "stage.templateStage.name", text: "Stage", csvFormatter: (col, row) => col || row.query.stage.templateStage.name },
						{ dataField: "stage.status", text: "Stage Status", csvFormatter: (col, row) => col || row.query.stage.status },
						{ dataField: "stage.rag", text: "Stage Rag", csvFormatter: (col, row) => col || row.query?.stage.rag || "" },
						{
							dataField: "stage.periodEnd.property.actors.inHouseContact.name",
							text: "In House Contact",
							csvFormatter: (col, row) =>
								col ||
								(row.query && row.query.stage.periodEnd.property.actors.inHouseContact
									? row.query.stage.periodEnd.property.actors.inHouseContact.name
									: ""),
						},
						{
							dataField: "stage.periodEnd.property.actors.clientContact.name",
							text: "Client Contact",
							csvFormatter: (col, row) =>
								col ||
								(row.query && row.query.stage.periodEnd.property.actors.clientContact
									? row.query.stage.periodEnd.property.actors.clientContact.name
									: ""),
						},
						{ dataField: "stage.dueDate", text: "Stage Due", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
					]}
					exportCSV
				>
					{props => (
						<React.Fragment>
							<div className="d-flex justify-content-end align-items-center mb-2">
								<div className="d-flex ml-auto">
									<CustomSearch onSearch={this.onSearch} />
									<QueriesFilter
										className="ml-2"
										queries={queries}
										onFilterChange={this.onFilterChange}
										filter={this.props.filter}
										message={filterMessage}
									/>

									<ButtonGroup className="ml-2">
										<CustomExportCSV {...props.csvProps}>Export</CustomExportCSV>
									</ButtonGroup>
								</div>
							</div>
							<BootstrapTable classes="hidden" {...props.baseProps} />
						</React.Fragment>
					)}
				</ToolkitProvider>

				<ToolkitProvider
					keyField="id"
					data={queries}
					columns={[
						{
							dataField: "id",
							text: "Id",
							sort: false,
							formatter: (cell, row) => <QueriesTableBody key={`QTB_${row.id}`} query={row} />,
						},
					]}
					search
				>
					{props => (
						<React.Fragment>
							<BootstrapTable
								pagination={paginationFactory({
									sizePerPage: 20,
									sizePerPageList: [
										{ text: "10 row per page", value: 10 },
										{ text: "20 row per page", value: 20 },
										{ text: "50 row per page", value: 50 },
										{ text: "100 row per page", value: 100 },
									],
								})}
								classes="table-responsive-lg text-inverse table-striped"
								bordered={false}
								responsive
								hover
								{...props.baseProps}
							/>
						</React.Fragment>
					)}
				</ToolkitProvider>
			</React.Fragment>
		)
	}
}

export { QueriesTable }
