import _ from "lodash"
import React from "react"
import { Link } from "react-router-dom"

import { Container, Row, Col } from "../../../../components"

import { TimelineDefault } from "./Releases_TimelineDefault"
import { LabelKeyPopupContent } from "../Workstreams/Workstream"

import * as clearview from "../../../../components/@Clearview"

const colors = {
	info: "info",
	pause: "warning",
	check: "success",
	bug: "danger",
}

export function ItemMap(date) {
	return (it, idx) => (
		<TimelineDefault
			key={idx}
			showPillDate={idx === 0}
			pillDate={date}
			iconCircle={it[0]}
			smallIconColor={colors[it[0]]}
			iconCircleColor={colors[it[0]]}
			title={it[1]}
			content={it[2]}
		/>
	)
}

export function Releases2023() {
	return (
		<React.Fragment>
			<Container>
				<Row>
					<Col lg={8} className="release-timeline">
						{_.keys(Versions)
							.sort(clearview.caseInsensitiveSort)
							.reverse()
							.map(versionKey => {
								const releaseDate = clearview.FormatDate(versionKey.substring(0, 10), "ddd DD MMM YYYY")
								const hotfix = versionKey.substring(10) || ""
								return Versions[versionKey].map(ItemMap(`${releaseDate} ${hotfix}`))
							})}
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	)
}

const Versions = {
	"2023-12-31": [
		[
			"info",
			"Working Papers improvements.",
			<ul>
				<li>Rename Expenditure tab to Client Expenditure</li>
				<li>Working Papers are visible (read-only) for Clients</li>
				<li>
					Accts &amp; Prepay are pulling through to the ETB the wrong way round (now <font color="DarkOrange">DR & Prepay</font> values appear in the
					<font color="DarkOrange">Ref c/f Cr</font> column){" "}
				</li>
				<li>Expenditure tab to show subtotals by Account</li>
				<li>Fee allocation of 0 should not show in CRs &amp; Accruals</li>
				<li>Set account fee Nominal Code, Account, Date and add comment "BW Accounts Fee dd/mm/yyyy"</li>
				<li>Hide Comparatives & Budget tabs from Clients</li>
			</ul>,
		],
		["check", "Vouching manual matches does not work.", "Manual matching using drag-and-drop now fixed."],
		[
			"check",
			"Vouching Select All warns about discarding changes.",
			"After making a change, clicking the select all box on the expenditure in the vouching section causes a 'You have made changes to the Vouching Data' popup to appear and changes are lost. Now fixed.",
		],
		[
			"info",
			"URLs to vouching and working-papers.",
			"It is now possible to follow URLs directly to specific vouching or working-papers pages, e.g. .../vouching/DEMO-071-001/BB/2022",
		],
	],
	"2023-12-17": [
		["check", "Conditions text alignment.", "Conditions with long descriptions now align list a list."],
		["check", "Workstream items could be opened but not closed.", "Now fixed"],
		["info", "Property Card Service Charge Managers.", "Now shows property or parent Managers."],
	],
	"2023-12-09": [["info", "Clearview 18 Beta.", <span>Client screen fixes and improved login page.</span>]],
	"2023-11-28a": [
		["info", "Clearview 18 Beta.", <span>UI has been upgraded to React 6 and Node 18.</span>],
		[
			"info",
			"Schemes table show only Open PE columns and Total",
			<p>
				The <i>Not Due</i> and <i>Imminent</i> columns have been removed from the Schemes table on the Overview page and a <i>Total</i> column has been
				added.
			</p>,
		],
	],
	"2023-08-20": [
		[
			"info",
			"Admin users can now set any in-house team member to receive emails whenever Period Ends are updated.",
			<span>
				On the <i>Clients</i> screen, an admin user can set <i>Email notifications</i> for any member of any in-house team. The user will then receive
				emails every time a Period End covered by that team is updated. A small envelope icon {clearview.Icon.IsNotified[true]} will appear next to the
				name of the user. However, this icon will only be visible to Admin users and the named user.
			</span>,
		],
		[
			"info",
			"Schemes table show only Open PE columns and Total",
			<p>
				The <i>Not Due</i> and <i>Imminent</i> columns have been removed from the Schemes table on the Overview page and a <i>Total</i> column has been
				added.
			</p>,
		],
	],
	"2023-06-10": [
		[
			"info",
			"Expenditure Fields added to Period Ends",
			"Final Expenditure Total and Budgeted Expenditure Total are now available (in-house only) and are included in Period End and Stage Exports.",
		],
		[
			"info",
			"Updated Property Attributes",
			"The following attributes are now available on Properties: 'No of schedules', 'Demand type', 'Property tenure', 'Property use', 'VAT Elected (Y/N), if management company', 'Company number, if management company', 'CAC, if management company'.",
		],
		[
			"info",
			"Assign user's properties to another user, e.g. Holiday cover",
			"A new menu option 'User Assignments...' is available to Admins in the Client screen Customer popup menu.",
		],
		[
			"check",
			"Auto Renew incorrectly creates new PEs with /A",
			"When a template has been replaced with another one, renewing Period Ends can create /A. /B, /C, etc on subsequent overnight runs. Now fixed.",
		],
	],
	"2023-04-11 Hotfix": [["check", "Unable to view PE's with Queries", "Now fixed."]],
	"2023-04-10": [
		[
			"info",
			"Property includes links to other Period Ends",
			"To assist in navigation, the Property card on the Period End details page contains links to all Period Ends for that property. Note, hold down Ctrl when clicking to open the Period End in a new tab.",
		],
		[
			"info",
			"Alert the user when they upload an empty file.",
			"Note that files dragged in from zip files are not actually uploaded. They result in 0 byte long files.",
		],
		["info", "Times are now displayed in BST during the summer", "All times are now displayed correctly in Local time."],
		["check", "Open stages table was showing HTML markup in Comments", "Now fixed. Comments are now correctly formatted."],
		[
			"check",
			"Error 200 when assigning Users to Teams",
			"Now fixed. Assigning users to teams can throw Error 022 if the property has had its Reference changed and subsequently re-used. This is surprisingly common.",
		],
		[
			"info",
			"Closing multiple steps should not add a comment to each step.",
			"To avoid 'noisy comments' when closing multiple stages in one go, the 'Advance to stage ...' comment is no longer added to intermediate stages.",
		],
	],
	"2023-04-02": [
		["info", "Clients cannot close their own queries (BW ONLY)", "Once sent (i.e. not draft), a Client user cannot close a query raised by the Client."],
	],
	"2023-03-21": [["info", "Overview page Month Summary most recent first", "The overview page Month Summary shows the most recent month first, for Fortus."]],
	"2023-03-17": [
		[
			"check",
			"Working Papers: Accounts page does not refresh",
			"When moving from the Working Papers for one Period End to another, the Accounts folder shows files for th previously viewed period end. Now fixed.",
		],
		[
			"check",
			"Query Management hide Queries in future Stages",
			"Although hidden in the Period End detail screen, Queries in future Stages were visible to Client users under Query Management. Now fixed.",
		],
		[
			"check",
			"Query Management menu option stays highlighted after selection",
			"When the Query Management menu item was selected, the previously selected item remained highlighted. Now fixed.",
		],
		["info", "Force https", "The web app will now redirect from http to https (for users who still have the old test url)."],
	],
	"2023-03-16": [
		[
			"bug",
			"Working Papers: Template can exclude pages",
			"Pages can be included or excluded on the report preview page. However, the table of contents is not yet taking this into account.",
		],
		["info", "Working Papers: faster loading", "During materialization, Client and Period Ends are attached rather than cloned using _.defaultsDeep."],
	],
	"2023-03-09": [
		[
			"info",
			"Period End Last Touched report.",
			"A new report has been added to the Reports Page. The Period End Last Touched report shows the most recent dates that an In-House user and Client user updated a Period End. This includes closing stages, adding comments, and adding or replying to queries. Note that some changes are applied by the system at around 5am daily.",
		],
		[
			"info",
			"Stage comments left align.",
			"To produce consistent column width in the comments section of the Stage, the columns are now shunted to the left.",
		],
	],
	"2023-03-02": [
		["info", "Export Client Property Manager.", "Client Property Manager now added to the Period Ends export."],
		["bug", "Some users cannot paste new rules.", "The code has been re-organized and some debug 'warnings' added to the console."],
		["check", "Invoice Import Rules - buttons do not align.", "The Import button is now aligned with the Add, Save and Cancel buttons."],
	],
	"2023-02-10": [
		["info", "Checklists now visible in Workflow item details.", "Trello checklists are now rendered in the Workflow details under the Support page."],
		[
			"check",
			"System users now also have Admin permission.",
			"System level users were unable to see the Workflow pages because they were not classed as IsAdmin. Now fixed.",
		],
		[
			"check",
			"Overview Manager Summary doesn't group by Landlord manager.",
			"Previously the Manager Summary on the overview page was only grouping by the Client In-House: 'Other' team. Now, it will be grouped by the Landlord or Managing Agent In-House: 'Other' team if specified.",
		],
	],
	"2023-01-31 Hotfix": [
		[
			"check",
			"Queries can default to a user in another Client",
			"If the Assigned To username is left blank by an in-house user, a new query may be assigned to the previous default user who may be in another Client. Now fixed.",
		],
		[
			"info",
			"Working Papers: Comparatives page, import & export",
			"Data can no longer be edited on the Comparatives page. However, Comparatives can now be populated by importing a file exported from the previous ETB page. If a previous ETB isn't available, a template can be exported for manual completion and re-import.",
		],
	],
	"2023-01-25": [
		[
			"check",
			"Vouching: Was unavailable to users who were not members of a team at the Client level. Now fixed!",
			"In-house users who were configured to see only certain properties for a client where not able to view/amend the Vouching data or Working Papers. This was because the user was forbidden from loading the vendor alias file which is stored at the Client level. Such users now have 'SettingsOnly' access to Client files.",
		],
		[
			"info",
			"Working Papers: ETB Export to Excel.",
			"The ETB page can now be exported to Excel via a link at the bottom of the page. This is useful for generating template files for uploading the previous year's ETB data into subsequent Working Papers.",
		],
		[
			"info",
			"Working Papers: Tab buttons are now in Content Header.",
			"Tab buttons in the content header saves vertical space on the page and looks more consistent with Vouching page.",
		],
	],
	"2023-01-17 Hotfix B": [
		[
			"info",
			"Working Papers: Import Vouching Data Should not include 'Excluded' Ledger items.",
			"Excluded ledger items are now excluded. Working Papers will now cope with missing Account values in the Ledger",
		],
	],
	"2023-01-17": [
		[
			"info",
			"Working Papers: Expenditure Report learns Nominal Codes based on Account name.",
			<div>
				<p>When a Nominal Code is assigned or changed on the Expenditure Report page, the user will be able to, optionally:</p>
				<ul>
					<li>
						Associate this code with the Account Name, for all future Vouching data Imports <b>for this Client</b>
					</li>
					<li>Apply the Code to all items with this Account Name, on this page only</li>
					<li>Apply the code to just this item</li>
				</ul>
			</div>,
		],
		["check", "Vouching: Vouching Report button does not work if any Ledger data has blank Account.", "Fixed."],
		[
			"check",
			"Vouching/Working Papers: Account Name colour codes inconsistent.",
			"The same Account Name now has different colour codes in all Properties.",
		],
		[
			"info",
			"Working Papers: Account Name column added to Expenditure Report page.",
			"The same Account Name now has different colour codes in all Properties.",
		],
		[
			"check",
			"The progress bar for Closed Period Ends often shows incorrect stage progress.",
			"Now fixed. This was related to displaying the progress bat for Closed Period ends.",
		],
		[
			"check",
			"Overview page statistics does not always show the correct number of Alerts / Priority period ends.",
			"Fixed again to include Closed Period Ends in both columns.",
		],
	],
	"2023-01-12 Hotfix A": [["check", "Working Papers: Error when clicking on Import Vouching Data.", "Now fixed."]],
	"2023-01-12": [
		[
			"info",
			"Working Papers: Read-only if Period End is Closed.",
			"All working papers editing and import features are Read-only if the Period End is Closed.",
		],
		[
			"check",
			"Clicking on Alerts / Priority does not always show the indicated number of Period Ends.",
			"Clicking on the Priority / Alert links now clears any other filters, such as Status = Open.",
		],
		[
			"check",
			"Filename validation in Rename File.",
			"Users must specify a valid filename with a file extension not containing invalid characters, when renaming files.",
		],
		[
			"info",
			"Markdown in Workstream items.",
			"In Trello, it is possible to make certain text **bold**, _italic_, urls, etc. This mod ensures this is displayed correctly in Clearview. See https://www.markdownguide.org/basic-syntax.",
		],
	],
	"2023-01-11": [
		["info", "Delete Property.", "In House admins can delete any Property (and all its Period Ends)."],
		["info", "Delete Client will delete all Children.", "In House admins can delete any Client (and all its Properties and Period Ends)."],
		[
			"info",
			"Clearview Version now displayed on the side bar.",
			"The current Clearview software version number is displayed at the bottom of the sidebar. Just above the copyright notice.",
		],
		["info", "Reopen multiple stages.", "Where permitted, any closed stage may be re-opened. All stages back to the selected stage will be re-opened."],
		["check", "Error saving new template.", "Fixed."],
	],
	"2023-01-06": [
		[
			"check",
			"Vouching: Expenditure Import will round the amount to the neatest penny.",
			"Unhelpfully, some spreadsheets may store expenditure amounts to many decimal places, e.g. 777.3599999999999. The Import will now round this to 777.36 rather than truncate it to 777.35.",
		],
		[
			"info",
			"Files can be renamed.",
			<span>
				Right click on a file and select <b>rename</b> to rename it. Files can only be renamed one at a time. Folders cannot be renamed.
			</span>,
		],
		[
			"check",
			"Cannot move or delete files containing special characters - now fixed.",
			"Files containing special characters (e.g. commas) can now be moved and deleted directly in Clearview.",
		],
	],
	"2023-01-05": [
		[
			"info",
			"Period Ends export now includes more Team data.",
			"The exported spreadsheet now contains In-House Service Charge Manager, Client Surveyors and Client Accounts team members.",
		],
		[
			"check",
			<span>
				Reliability of <b>{clearview.Icon.FileType.Excel} Upload New Users...</b> improved.
			</span>,
			"This function only creates the Users group once, if required. Usernames are trimmed of whitespace. Logins or users which already exist in the system are handled and an appropriate error email is sent.",
		],
		[
			"info",
			"Admin users can update Period End Refs.",
			<div>
				<p>
					If a property reference has changed, an Admin user can update any Period Ends whose Reference is now out of date. A new menu option is
					available on the Period End card on the Period End details page: <b className="nowrap">{clearview.Icon.wizard} Update Reference...</b>
				</p>
			</div>,
		],
	],
	"2023-01-03": [
		[
			"info",
			"Re-open query using 'Re-open' button.",
			"In-house admins can re-open a Query by deleing the closing comment or using the new Re-open button.",
		],
		[
			"info",
			"Workflow Dashboards for Admin users.",
			<div>
				<p>
					In-house Admins can now view the up-to-date planned and current Clearview application development workstreams in the <b>Support/Releases</b>{" "}
					Page.
				</p>
				<h6 className="mt-2">There are 3 Workflows:</h6>
				<p className="ml-2">
					<Link to="/workstream/clearview" className="hover text-primary">
						{clearview.Icon.workStream} Clearview Workstream
					</Link>{" "}
					Work items for the main <b>Clearview functions and User features</b>.
				</p>
				<p className="ml-2">
					<Link to="/workstream/infrastructure" className="hover text-primary">
						{clearview.Icon.workStream} Infrastructure Workstream
					</Link>{" "}
					Work items for Clearview <b>Infrastructure and Support</b> features.
				</p>
				<p className="ml-2">
					<Link to="/workstream/roadmap" className="hover text-primary">
						{clearview.Icon.workStream} Road Map / Phase 2
					</Link>{" "}
					Major Modules & Features being planned/designed for development in the future, or on the back burner.
				</p>
				<h6 className="mt-2">Work items are typically classified as follows:</h6>
				{LabelKeyPopupContent({ backgroundColor: "white", paddingLeft: "0.4rem" })}
			</div>,
		],
	],
}
