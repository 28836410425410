import _ from "lodash"
// import fetch from "node-fetch"
import pako from "pako"
import { Auth } from "aws-amplify"
import { v4 as uuid } from "uuid"

import * as clearview from "../components/@Clearview"

export const API = {
	CLIENT_ID: uuid(),

	// ENV: {
	// 	// DEV
	// 	SERVICE_URL: "https://clearviewapi.localtest.me/api",
	// 	FILES_URL: "https://3fo6hyf3inr7eix6pvcabfxfni0sznbc.lambda-url.eu-west-2.on.aws", //PROD
	// 	FILES_PROCESS_URL: "https://njqpuqzoxop5v33fkyfxjkslqu0yqurc.lambda-url.eu-west-2.on.aws/", //PROD
	// 	QUEUE_MANAGER_URL: "https://2vqaxbcfcut6ahlhf4kerb5pvm0tkvmi.lambda-url.eu-west-2.on.aws", //PROD
	// 	IMPERSONATE_URL: "https://xaibjz234onvvwwlokmixmc5vu0xcufj.lambda-url.eu-west-2.on.aws", //PROD
	// 	WEBSOCKET_URL: undefined, //PROD
	//  REPORT_URL: "https://xwmzkx63ggtnnlghbjfao2gzki0gjrxu.lambda-url.eu-west-2.on.aws",
	// 	TRELLO_URL: "https://api.trello.com",
	// 	TRELLO_AUTH: "key=2651a1498db4461ea21c54a60d3f77f2&token=ATTA5d1eeb64458aa9e5384aa99aa31ddcc89b84ff5a1cd96ec3e1c1da38517ddbc6E8CBA4A7",
	// },

	ENV: {
		//PROD
		SERVICE_URL: "https://15ohm5stsf.execute-api.eu-west-2.amazonaws.com/v1/api", //PROD
		FILES_URL: "https://3fo6hyf3inr7eix6pvcabfxfni0sznbc.lambda-url.eu-west-2.on.aws", //PROD
		FILES_PROCESS_URL: "https://njqpuqzoxop5v33fkyfxjkslqu0yqurc.lambda-url.eu-west-2.on.aws", //PROD
		QUEUE_MANAGER_URL: "https://2vqaxbcfcut6ahlhf4kerb5pvm0tkvmi.lambda-url.eu-west-2.on.aws", //PROD
		IMPERSONATE_URL: "https://xaibjz234onvvwwlokmixmc5vu0xcufj.lambda-url.eu-west-2.on.aws", //PROD
		WEBSOCKET_URL: "wss://rnt613gsdi.execute-api.eu-west-2.amazonaws.com/Prod/", //PROD
		REPORT_URL: "https://xwmzkx63ggtnnlghbjfao2gzki0gjrxu.lambda-url.eu-west-2.on.aws",
		TRELLO_URL: "https://api.trello.com",
		TRELLO_AUTH: "key=2651a1498db4461ea21c54a60d3f77f2&token=ATTA5d1eeb64458aa9e5384aa99aa31ddcc89b84ff5a1cd96ec3e1c1da38517ddbc6E8CBA4A7",
	},

	// ENV: {
	// 	//TEST
	// 	SERVICE_URL: "https://gx07k1z14f.execute-api.eu-west-2.amazonaws.com/test/api", //TEST
	// 	FILES_URL: "https://3fo6hyf3inr7eix6pvcabfxfni0sznbc.lambda-url.eu-west-2.on.aws", //PROD
	// 	FILES_PROCESS_URL: "https://njqpuqzoxop5v33fkyfxjkslqu0yqurc.lambda-url.eu-west-2.on.aws/", //PROD
	// 	QUEUE_MANAGER_URL: "https://2vqaxbcfcut6ahlhf4kerb5pvm0tkvmi.lambda-url.eu-west-2.on.aws", //PROD
	// 	IMPERSONATE_URL: "https://xaibjz234onvvwwlokmixmc5vu0xcufj.lambda-url.eu-west-2.on.aws", //PROD
	// 	WEBSOCKET_URL: "wss://rnt613gsdi.execute-api.eu-west-2.amazonaws.com/Prod/", //PROD
	//  REPORT_URL: "https://xwmzkx63ggtnnlghbjfao2gzki0gjrxu.lambda-url.eu-west-2.on.aws",
	//  TRELLO_URL: "https://api.trello.com",
	//  TRELLO_AUTH: "key=2651a1498db4461ea21c54a60d3f77f2&token=ATTA5d1eeb64458aa9e5384aa99aa31ddcc89b84ff5a1cd96ec3e1c1da38517ddbc6E8CBA4A7"
	// },

	loginUrl: function () {
		// eslint-disable-next-line
		let redirectTo = window.location.pathname.toLowerCase().startsWith("/pages/register")
			? `${window.location.protocol}//${window.location.host}/`
			: window.location.href
		return `https://clearview.auth.eu-west-2.amazoncognito.com/login?client_id=p8o1ocef0fhh1uolvl2opvetn&response_type=token&redirect_uri=${redirectTo}`
	},
}

export function AnonRequest(method = "GET", body) {
	return new Promise((resolve, reject) => {
		const requestInit = {
			method: method,
			mode: "cors",
			compress: true,
			headers: {
				"X-Api-Key": API.CLIENT_ID,
				// Accept: "application/x-clearview"
			},
		}
		if (body) {
			requestInit.body = body
			requestInit.headers["Content-Type"] = "application/json"
			requestInit.headers["Accept"] = "application/json"
		}

		resolve(requestInit)
	})
}

function AuthRequest(method = "GET", body) {
	return Auth.currentSession()
		.then(
			(
				session // User is logged in. Set auth header
			) =>
				new Promise((resolve, reject) => {
					const requestInit = {
						method: method,
						mode: "cors",
						compress: true,
						headers: {
							"X-Api-Key": API.CLIENT_ID,
							Authorization: "Bearer " + session.idToken.jwtToken,
						},
					}
					if (body) {
						requestInit.body = body
						requestInit.headers["Content-Type"] = "application/json"
						requestInit.headers["Accept"] = "application/json"
					}

					resolve(requestInit)
				})
		)
		.catch(() => {
			console.error("API: NOT AUTHENTICATED!")
			throw Error("API: NOT AUTHENTICATED!")
		})
}

export function ExternalRequest(method = "GET", body) {
	return new Promise((resolve, reject) => {
		const requestInit = {
			method: method,
			mode: "cors",
			compress: true,
		}
		if (body) {
			requestInit.body = body
			requestInit.headers["Content-Type"] = "application/json"
			requestInit.headers["Accept"] = "application/json"
		}

		resolve(requestInit)
	})
}

function addRequestHeader(name, value) {
	return requestInit => {
		requestInit.headers[name] = value
		return requestInit
	}
}
const requestCompression = addRequestHeader("Accept", "application/x-clearview")

export const CheckStatus = res => {
	if (!res.ok) {
		return {
			...res,
			json: () =>
				new Promise((resolve, reject) =>
					res.text().then(txt => {
						console.error(`HTTP ${res.status}`, txt)
						reject(txt || `HTTP Error ${res.status.toString()}`)
					})
				),
		}
	} else if (res.headers.get("content-type")?.startsWith("application/x-clearview")) {
		return {
			...res,
			json: () =>
				new Promise((resolve, reject) => {
					res.text().then(base64string => {
						try {
							const compressedBytes = new Buffer(base64string, "base64")
							const uncompressedBytes = pako.inflate(compressedBytes)
							const result = stringFromUTF8Array(uncompressedBytes)
							resolve(JSON.parse(result))
						} catch (err) {
							reject(`application/x-clearview reinflate error: ${err}`)
						}
					})
				}),
		}
	}
	return res
}

export const fetchReports = () => {
	const reports = [
		{
			key: "Report_ActiveProperties",
			name: "Report - Active Properties",
		},
		{
			key: "Report_PeriodEnd",
			name: "Report - Period End Report",
		},
		{
			key: "Report_PeriodEndExpectedLate",
			name: "Report - Period Ends Expected Late Report",
			disabledParams: { periodEndStatus: true },
		},
		{
			key: "Report_PeriodEndForecast",
			name: "Report - Period End Forecast Report",
			description: "Defaults to Today + 2 years. Set the Ends parameter to change this.",
			disabledParams: { periodEndStatus: true, periodEndRag: true, stageStatus: true, stageRag: true, dueDate: true },
		},
		{
			key: "Report_PeriodEndLastTouched",
			name: "Report - Period End Last Touched Report",
		},
		{
			key: "Report_StageSummary",
			name: "Report - Stage Summary Report",
		},
		{
			key: "Report_PeriodEndDetail",
			name: "Report - Period End Detail",
		},
		{
			key: "Report_QueryDetail",
			name: "Report - Query Detail",
		},
	]

	return Promise.resolve(reports)
}

export const fetchReportsAttachments = () => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/reports/folder`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const requestReport = (client, key, params) => {
	const body = JSON.stringify(params)
	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.REPORT_URL}/client/${client.id}/${key}`, requestInit))
		.then(CheckStatus)
}

export const fetchTemplates = () => {
	return AuthRequest()
		.then(requestCompression)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/templates`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchTemplate = id => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/template/${id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

const attributesDtiProperties = ["attributes", "id", "typeId", "value"]

const templateDtiProperties = [
	//Specify the properties that will not cause recursion
	"customerTemplate",
	"templates",
	"phases",
	"phaseId",
	"customerId",
	"id",
	"clonedFromId",
	"reference",
	"name",
	"sequence",
	"family",
	"durationMonths",
	"startDaysAfterPeriodEnd",
	"dueMonths",
	"imminentDays",
	"warningDays",
	"nextId",
	"invoiceAfterStageSequence",
	"stages",
	//stages:
	"folderName",
	"isInHouse",
	"plannedDays",
	"warningDays",
	"conditions",
	//conditions:
]

export const updateTemplates = templates => {
	const customerTemplates = templates.map(customer => ({
		customerId: customer.id,
		phases: customer.phases.map(it => ({ customerId: customer.id, ...it })),
		templates: customer.templates.map(it => ({
			customerId: customer.id,
			nextId: it.next?.id,
			invoiceAfterStageSequence: it.invoiceAfterStage?.sequence,
			...it,
		})),
	}))

	const body = JSON.stringify(customerTemplates, templateDtiProperties)

	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/templates`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updateTemplate = template => {
	template.customerId = template.customer.id
	template.nextId = template.next ? template.next.id : null
	template.invoiceAfterStageSequence = template.invoiceAfterStage ? template.invoiceAfterStage.sequence : null

	const body = JSON.stringify(template, templateDtiProperties)

	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/template/${template.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const retireTemplate = (template, nextId) => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/template/${template.id}/retire/${nextId || ""}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchCurrentUser = () => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/user`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
		.catch(err => ({
			...clearview.User.UnauthenticatedUser,
			error: {
				title: "An error has occurred during Login",
				message: `${err}\n\nPlease contact your Clearview administrator.`,
			},
		}))
}

export const userPasswordReset = username => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/user/reset/${username}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchClients = () => {
	return AuthRequest()
		.then(requestCompression)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const recalculatePeriodEnds = () => {
	return AuthRequest("POST")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodends/recalculate`, requestInit))
		.then(CheckStatus)
		.then(res => res.text())
}

export const renewPeriodEnds = () => {
	return AuthRequest("POST")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodends/renew`, requestInit))
		.then(CheckStatus)
		.then(res => res.text())
}

export const rebuildClients = () => {
	return AuthRequest("POST")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients/RebuildHierarchy`, requestInit))
		.then(CheckStatus)
		.then(res => res.text())
}

export const fetchPeriodEnds = (lft, rgt) => {
	return AuthRequest()
		.then(requestCompression)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodends/${lft}/to/${rgt}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchQueries = (lft, rgt) => {
	return AuthRequest()
		.then(requestCompression)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query/${lft}/to/${rgt}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchPeriodEnd = id => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodends/${id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updatePeriodEnd = (action, periodEnd, subject, title, comments, stage, otherParameters) => {
	const body = JSON.stringify({
		subject: subject,
		title: title,
		comments: comments,
		...otherParameters,
	})

	var url = `${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/${action}`
	if (stage) url = `${url}/${stage.id}`

	return AuthRequest("PUT", body)
		.then(requestInit => fetch(url, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updatePeriodEndField = (periodEnd, fieldName) => {
	const method = clearview.isNothingOrEmpty(periodEnd[fieldName]) ? "DELETE" : "PUT"
	const url = clearview.isNothingOrEmpty(periodEnd[fieldName])
		? `${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/${fieldName}`
		: `${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/${fieldName}/${periodEnd[fieldName]}`

	return AuthRequest(method)
		.then(requestInit => fetch(url, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}
export const updatePeriodEndExpenditureFinal = periodEnd => {
	const method = clearview.isNothingOrEmpty(periodEnd.expenditureFinal) ? "DELETE" : "PUT"
	const url = clearview.isNothingOrEmpty(periodEnd.expenditureFinal)
		? `${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/expenditure/final`
		: `${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/expenditure/final/${periodEnd.expenditureFinal}`

	return AuthRequest(method)
		.then(requestInit => fetch(url, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}
export const updatePeriodEndExpenditureBudget = periodEnd => {
	const method = clearview.isNothingOrEmpty(periodEnd.expenditureBudget) ? "DELETE" : "PUT"
	const url = clearview.isNothingOrEmpty(periodEnd.expenditureBudget)
		? `${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/expenditure/budget`
		: `${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/expenditure/budget/${periodEnd.expenditureBudget}`

	return AuthRequest(method)
		.then(requestInit => fetch(url, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}
export const updatePeriodEndDoNotRenew = periodEnd => {
	const value = periodEnd.doNotRenew ? "true" : "false"
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/doNotRenew/${value}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updatePeriodEndIsPublished = periodEnd => {
	const value = periodEnd.isPublished ? "false" : "true"
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/isPublished/${value}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updatePeriodEndCondition = (periodEnd, conditionId, isChecked) => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/condition/${conditionId}/${isChecked}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updateComment = (commentId, periodEnd, stage, title, comments) => {
	const body = JSON.stringify({
		periodEndId: periodEnd.id,
		stageId: stage.id,
		title: title,
		comments: comments,
	})
	return AuthRequest("PUT", body).then(requestInit => fetch(`${API.ENV.SERVICE_URL}/comment/${commentId}`, requestInit))
}

export const deleteComment = (commentId, periodEnd, stage) => {
	return AuthRequest("DELETE").then(requestInit => fetch(`${API.ENV.SERVICE_URL}/comment/${commentId}/${periodEnd.id}/${stage.id}`, requestInit))
}

export const raiseQuery = (periodEnd, stage, subject, title, comments, assignTo, isDraft) => {
	const body = JSON.stringify({
		periodEndId: periodEnd.id,
		stageId: stage.id,
		subject: subject,
		title: title,
		comments: comments,
		assignTo: assignTo,
		isDraft: isDraft,
	})
	return AuthRequest("POST", body).then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query`, requestInit))
}

export const updateQuery = (queryId, periodEnd, stage, subject, title, comments, assignTo, isDraft) => {
	const body = JSON.stringify({
		periodEndId: periodEnd.id,
		stageId: stage.id,
		subject: subject,
		title: title,
		comments: comments,
		assignTo: assignTo,
		isDraft: isDraft,
	})
	return AuthRequest("PUT", body).then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query/${queryId}`, requestInit))
}

export const deleteQuery = (queryId, periodEnd, stage) => {
	return AuthRequest("DELETE").then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query/${queryId}/${periodEnd.id}/${stage.id}`, requestInit))
}

export const closeQuery = (queryId, comments) => {
	const body = JSON.stringify({ comments: comments })
	return AuthRequest("PUT", body).then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query/${queryId}/close`, requestInit))
}

export const reOpenQuery = (queryId, comments) => {
	const body = JSON.stringify({ comments: comments })
	return AuthRequest("DELETE", body).then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query/${queryId}/close`, requestInit))
}

export const replyToQuery = (queryId, comments) => {
	const body = JSON.stringify({ comments: comments })
	return AuthRequest("POST", body).then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query/${queryId}/reply`, requestInit))
}

export const updateReply = (queryId, replyId, comments) => {
	const body = JSON.stringify({
		comments: comments,
	})
	return AuthRequest("POST", body).then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query/${queryId}/reply/${replyId}`, requestInit))
}

export const deleteReply = (queryId, replyId) => {
	return AuthRequest("DELETE").then(requestInit => fetch(`${API.ENV.SERVICE_URL}/query/${queryId}/reply/${replyId}`, requestInit))
}

export const createPeriodEnd = (propertyId, templateId, endDate) => {
	const body = JSON.stringify({
		PropertyId: propertyId,
		TemplateId: templateId,
		EndDate: endDate,
	})
	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodEnds`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updatePeriodEndReference = periodEnd => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/updatereference`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const startPeriodEnd = (periodEnd, comments) => {
	const body = JSON.stringify({ comments: comments })
	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/start`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const deletePeriodEnd = (periodEnd, comments, doNotRenew) => {
	const body = JSON.stringify({ comments })
	return AuthRequest("DELETE", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/periodEnds/${periodEnd.id}/permanently/${doNotRenew.toString()}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fileUploaded = (entityType, id, key, meta) => {
	const body = JSON.stringify({
		...meta,
		key: key,
	})

	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/attachments/${entityType}/${id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const deleteAttachment = key => {
	return AuthRequest("DELETE")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/attachments/${encodeURIComponent(key)}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const getPreSignedUploadParams = key => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/attachments/PreSignedUrl/${encodeURIComponent(key)}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchAttachments = (type, id) => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/attachments/folder/${type}/${id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updateClient = client => {
	const body = JSON.stringify(client, ["reference", "name", "email", "address", "postcode", "fullAddressExcludingPostcode", ...attributesDtiProperties])
	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients/${client.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const addClient = (parent, client) => {
	const body = JSON.stringify(client, ["reference", "name", "address", "postcode", "fullAddressExcludingPostcode"])

	return AuthRequest("POST", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients/${parent.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const removeClient = client => {
	return AuthRequest("DELETE")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients/${client.parent.id}/${client.id}`, requestInit))
		.then(CheckStatus)
}

export const addProperty = (parent, property) => {
	const body = JSON.stringify(property, ["reference", "name", "address", "postcode", "fullAddressExcludingPostcode", ...attributesDtiProperties])
	return AuthRequest("POST", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients/property/${parent.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const updateProperty = property => {
	const body = JSON.stringify(property, ["reference", "name", "email", "address", "postcode", "fullAddressExcludingPostcode", ...attributesDtiProperties])
	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients/${property.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const removeProperty = property => {
	return AuthRequest("DELETE")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients/property/${property.parent.id}/${property.id}`, requestInit))
		.then(CheckStatus)
}

export const moveClients = moves => {
	const body = JSON.stringify(moves)
	return AuthRequest("POST", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/clients/Move`, requestInit))
		.then(CheckStatus)
}

export const manageTeams = (business, teams) => {
	const body = JSON.stringify(teams)
	return AuthRequest("PUT", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/teams/${business.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const userAssignUsersProperties = (user1, user2, mode) => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/teams/${mode.toLowerCase()}/${user1.username}/${user2.username}`, requestInit))
		.then(CheckStatus)
}

export const addFavourite = periodEnd => {
	return AuthRequest("POST")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/user/favourite/${periodEnd.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const resetFavourite = periodEnd => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/user/favourite/${periodEnd.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const removeFavourite = periodEnd => {
	return AuthRequest("DELETE")
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/user/favourite/${periodEnd.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const workflowCreateUsers = (business, users) => {
	const body = JSON.stringify(users)
	return AuthRequest("POST", body)
		.then(requestInit => fetch(`${API.ENV.SERVICE_URL}/workflow/createUsers/${business.id}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchFolder = (path, requiredSubfolders = [], versions = false) => {
	const queryParams =
		versions && requiredSubfolders.length
			? `?v=1&rs=${requiredSubfolders.join("&rs=")}`
			: requiredSubfolders.length
			? `?rs=${requiredSubfolders.join("&rs=")}`
			: versions
			? "?v=1"
			: ""

	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}${queryParams}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
		.catch(err => [])
}

export const fetchTree = (path, requiredSubfolders = []) => {
	const queryParams = requiredSubfolders?.length ? `?tree=1&rs=${requiredSubfolders.join("&rs=")}` : "?tree=1"

	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}${queryParams}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchFolderCounts = (path, requiredSubfolders = []) => {
	const queryParams = requiredSubfolders.length ? `?c=1&rs=${requiredSubfolders.join("&rs=")}` : "?c=1"

	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}${queryParams}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const findInFolders = (path, query) => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}?${query || ""}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchPresignedUrl = (path, versionId) => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}${versionId ? "?versionId=" + versionId : ""}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchHead = path => {
	return AuthRequest("HEAD")
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json()) // This seems to be empty! Should be in headers anyway .ToDo: Should use response header!
}

export const fetchOptions = path => {
	return AuthRequest("OPTIONS")
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}`, requestInit))
		.then(CheckStatus)
		.then(res => res.text()) // ToDo: Should use response header!
		.then(res => res.split(","))
}

export const fetchPresignedUploadUrl = path => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const fetchPresignedUrlBlob = (path, versionId) => {
	return fetchPresignedUrl(path, versionId).then(resp =>
		AnonRequest("GET")
			.then(requestInit => fetch(resp.presignedUrl, requestInit))
			.then(res => res.blob())
	)
}

export const uploadPresignedBlob = (path, blob) =>
	fetchPresignedUploadUrl(path).then(resp =>
		AnonRequest("PUT", blob)
			.then(requestInit => fetch(resp.presignedUrl, requestInit))
			.then(CheckStatus)
	)

export const createFolder = path => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const moveItems = (path, keys) => {
	const fileOrFolderKeys = keys.map(key => fileOrFolderKey(key))
	const querystring = "?" + fileOrFolderKeys.map((key, idx) => `mv${idx}=${encodeURIComponent(key)}`).join("&")

	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}${querystring}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const renameFile = (file, newName) => {
	const querystring = "?ren=" + encodeURIComponent(newName)
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(file.key)}${querystring}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const deleteFilesOrFolders = keys => {
	const fileOrFolderKeys = keys.map(key => fileOrFolderKey(key))
	const path = clearview.BeforeLast(fileOrFolderKeys[0], "/")
	const querystring = "?" + fileOrFolderKeys.map((key, idx) => `f${idx}=${encodeURIComponent(clearview.AfterLast(key, "/"))}`).join("&")

	return AuthRequest("DELETE")
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${safePath(path)}${querystring}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const saveJsonFile = (path, value) =>
	fetchPresignedUploadUrl(path).then(resp =>
		AnonRequest("PUT", JSON.stringify(value))
			.then(requestInit => fetch(resp.presignedUrl, requestInit))
			.then(CheckStatus)
	)

export const loadJsonFile = (path, defaultValue) =>
	fetchPresignedUrl(path).then(resp =>
		AnonRequest("GET")
			.then(requestInit => fetch(resp.presignedUrl, requestInit))
			.then(res => (res.ok ? res.json() : Promise.resolve(defaultValue)))
	)

//ToDo: Can only do this if data is object, not array!
const loadJsonFileWithMeta = (path, defaultValue, scope) =>
	fetchPresignedUrl(path).then(resp =>
		AnonRequest("GET")
			.then(requestInit => fetch(resp.presignedUrl, requestInit))
			.then(res => {
				if (res.ok)
					return res.json().then(json => ({
						"~meta": {
							path,
							scope,
							isCustom: true,
							lastModified: new Date(res.headers.get("last-modified")).toISOString(),
						},
						...json,
					}))

				return Promise.resolve({
					"~meta": { path, isCustom: false, scope: "default" },
					...defaultValue,
				})
			})
	)

const businessRoot = business => {
	let root = ""
	switch (business.type) {
		case "Customer":
		case "Client":
			root = `client/${business.id}`
			break
		case "Property":
			root = `property/${business.id}`
			break
		default:
			throw Error(`cannot load preferences for ${business.type}`)
	}
	return root
}

const preferencesPath = (business, flavour, scope = false) => {
	const root = businessRoot(business)
	if (!!scope) return `${root}/preferences.${flavour}.${business.id}.${scope}.json`
	return `${root}/preferences.${flavour}.${business.id}.json`
}

const getFileScope = fileName => {
	const parts = fileName.split(".")
	if (parts.length === 6) return parts[4]
	if (parts.length === 5) return parts[3]
	if (parts.length === 4) return parts[2]
	return ""
}

const getOwnerKey = (owner, type = undefined) => `${owner.type || type}:${owner.id}`

export const treeFolders = periodEnd => {
	let depth = 0
	const folders = [{ depth, owner: { ...periodEnd, type: "periodEnd" }, ownerKey: getOwnerKey(periodEnd, "periodEnd"), path: `periodEnd/${periodEnd.id}` }]

	let parent = periodEnd.property
	while (parent) {
		depth--
		const typeFolder = parent.type === "Property" ? "property" : "client"
		folders.push({ depth, owner: parent, ownerKey: getOwnerKey(parent), path: `${typeFolder}/${parent.id}` })
		parent = parent.parent
	}

	return folders
}

export const listPreferencesInTree = (periodEnd, flavour) => {
	const filePrefix = req => `preferences.${flavour}.${req.owner.type}.${req.owner.id}.`

	const promises = treeFolders(periodEnd).map(req =>
		AuthRequest()
			.then(requestInit => fetch(`${API.ENV.FILES_URL}/${req.path}?type=json`, requestInit))
			.then(CheckStatus)
			.then(res => res.json())
			.then(res => res.objects.filter(it => it.name.startsWith(filePrefix(req))))
			.then(res => res.map(it => ({ ...it, owner: req.owner, ownerKey: getOwnerKey(req.owner), scope: getFileScope(it.name) })))
			.catch(err => [])
	)

	return Promise.all(promises).then(promiseResults => promiseResults.flatMap(res => res))
}

export const listPreferences = (business, flavour) => {
	const fileNamePrefix = `preferences.${flavour}.${business.id}`
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${businessRoot(business)}?type=json`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
		.then(res => res.objects.filter(it => it.name.startsWith(fileNamePrefix)))
		.then(res => res.map(it => ({ ...it, scope: getFileScope(it.name) })))
}

export const loadPreferences = (business, flavour, defaultValue, scope = false) => {
	const path = preferencesPath(business, flavour, scope)
	if (!!scope) return loadJsonFileWithMeta(path, defaultValue, scope)
	return loadJsonFile(path, defaultValue)
}

export const loadInScopePreferences = (business, flavour, defaultValue, scope) => {
	return listPreferences(business, flavour)
		.then(res => _.sortBy(res, (a, b) => clearview.caseInsensitiveSort(a?.scope, b?.scope)))
		.then(res => {
			const applicableScope = _.findLast(res, it => it.scope <= scope)
			return loadPreferences(business, flavour, defaultValue, applicableScope?.scope || scope)
		})
}

export const savePreferences = (business, flavour, value, scope = false) => {
	const path = preferencesPath(business, flavour, scope)
	return saveJsonFile(path, value)
}

export const saveTreePreferences = (owner, flavour, value, scope = false) => {
	let name = `preferences.${flavour}.${owner.type}.${owner.id}`
	if (scope) name = name + "." + clearview.MakeSafeFileName(scope)
	name = name + ".json"

	const root = owner.type === "periodEnd" ? `periodEnd/${owner.id}` : businessRoot(owner)
	const key = `${root}/${name}`
	return saveJsonFile(key, value).then(res => ({ key, name, scope, owner }))
}

export const deletePreferences = (business, flavour, scope = false) => {
	const path = preferencesPath(business, flavour, scope)
	return deleteFilesOrFolders([path])
}

export const periodEndDataFilePath = (periodEnd, flavour, scope = false) =>
	scope
		? `periodEnd/${periodEnd.id}/${flavour}.${periodEnd.id}.${clearview.MakeSafeFileName(scope)}.json`
		: `periodEnd/${periodEnd.id}/${flavour}.${periodEnd.id}.json`

export const listPeriodEndDataFiles = (periodEnd, flavour) => {
	const folderPath = `periodEnd/${periodEnd.id}/${clearview.BeforeLast(flavour, "/")}`
	return listJsonFiles(folderPath, clearview.AfterLast(flavour, "/"))
}

export const listJsonFiles = (folderPath, fileNamePrefix) => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.FILES_URL}/${folderPath}?type=json`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
		.then(res => res.objects.filter(it => it.name.startsWith(fileNamePrefix)))
		.then(res => res.map(it => ({ ...it, scope: getFileScope(it.name) })))
}

export const loadPeriodEndDataFile = (periodEnd, flavour, defaultValue) => {
	const path = periodEndDataFilePath(periodEnd, flavour)
	return loadJsonFile(path, defaultValue)
}

export const savePeriodEndDataFile = (periodEnd, flavour, value) => {
	const path = periodEndDataFilePath(periodEnd, flavour)
	return saveJsonFile(path, value)
}

export const processFiles = () => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.FILES_PROCESS_URL}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const getQueueManager = () => {
	return AuthRequest()
		.then(requestInit => fetch(`${API.ENV.QUEUE_MANAGER_URL}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const setImpersonate = impersonate => {
	return AuthRequest("PUT")
		.then(requestInit => fetch(`${API.ENV.IMPERSONATE_URL}?impersonate=${impersonate}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

export const removeImpersonate = () => {
	return AuthRequest("DELETE")
		.then(requestInit => fetch(`${API.ENV.IMPERSONATE_URL}`, requestInit))
		.then(CheckStatus)
		.then(res => res.json())
}

function fileOrFolderKey(key) {
	return key
		.split("/")
		.filter(it => it)
		.join("/")
}

function safePath(key) {
	return key
		.split("/")
		.filter(it => it)
		.map(it => encodeURIComponent(it))
		.join("/")
}

export const getWorkstream = boardId => {
	// return loadJsonFile(`client/1597/${boardId}.cards.json`, [])
	return ExternalRequest("GET")
		.then(requestInit => fetch(`${API.ENV.TRELLO_URL}/1/boards/${boardId}/lists?cards=open&${API.ENV.TRELLO_AUTH}`, requestInit))
		.then(res => res.json())
}

export const getWorkstreamItemComments = cardId => {
	return ExternalRequest("GET")
		.then(requestInit => fetch(`${API.ENV.TRELLO_URL}/1/cards/${cardId}/actions?filter=commentCard&${API.ENV.TRELLO_AUTH}`, requestInit))
		.then(res => res.json())
}

export const getWorkstreamItemChecklists = cardId => {
	return ExternalRequest("GET")
		.then(requestInit => fetch(`${API.ENV.TRELLO_URL}/1/cards/${cardId}?checklists=all&${API.ENV.TRELLO_AUTH}`, requestInit))
		.then(res => res.json())
}

// https://weblog.rogueamoeba.com/2017/02/27/javascript-correctly-converting-a-byte-array-to-a-utf-8-string/
function stringFromUTF8Array(data) {
	const extraByteMap = [1, 1, 1, 1, 2, 2, 3, 0]
	var count = data.length
	var str = ""

	for (var index = 0; index < count; ) {
		var ch = data[index++]
		if (ch & 0x80) {
			var extra = extraByteMap[(ch >> 3) & 0x07]
			if (!(ch & 0x40) || !extra || index + extra > count) return null

			ch = ch & (0x3f >> extra)
			for (; extra > 0; extra -= 1) {
				var chx = data[index++]
				if ((chx & 0xc0) !== 0x80) return null

				ch = (ch << 6) | (chx & 0x3f)
			}
		}

		str += String.fromCharCode(ch)
	}

	return str
}
