import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"

import _ from "lodash"

import PropTypes from "prop-types"

import { PhasesPeriodEndRagBadge } from "./PhasesPeriodEndRagBadge"

export class LivePeriodEndsPhasesInfoTable extends React.Component {
	static propTypes = {
		context: PropTypes.object.isRequired,
		periodEnds: PropTypes.array.isRequired,
		groupsSpecification: PropTypes.object.isRequired,
		isScrollable: PropTypes.bool,
	}

	render() {
		const { context, periodEnds, isScrollable } = this.props
		const customer = context.state.filter.customer

		if (!customer) return

		const phaseInHouse = _.flatMap(customer.templates, it => it.stages.map(s => ({ phaseId: s.phaseId, isInHouse: s.isInHouse })))
		const phaseInHouseLookup = _.groupBy(phaseInHouse, it => it.phaseId)

		const ownerMap = {
			true: customer.name,
			false: "Client",
			undefined: "Shared",
		}

		let groups = {}

		for (let it of periodEnds) {
			it.group = this.props.groupsSpecification.groupByFunction(it)
			groups = { ...groups, [it.group]: this.props.groupsSpecification.groupLabelFunction(it.group) }
		}

		const byPhaseId = _.groupBy(periodEnds, it => it.currentStage.templateStage.phaseId)

		const rowData = _.sortBy(customer.phases, it => it.sequence).map(phase => {
			const isInHouse = _.first(phaseInHouseLookup[phase.id])?.isInHouse
			const currentPhase = byPhaseId[phase.id]

			const byGroup = _.groupBy(currentPhase, periodEnd => periodEnd.group)

			return {
				phase: phase,
				seq: phase.sequence,
				owner: ownerMap[isInHouse],
				name: phase.name,
				link: `/periodEnds?phaseName=${phase.name}&periodEndStatus=Open`,

				data: byGroup,

				Total: currentPhase?.length,
			}
		})

		const rowClasses = (row, idx) => {
			return row.owner === "Client" ? `table-info` : `table-primary`
		}

		const classNames = isScrollable ? "clearview-horizontal-table-scroll" : "clearview-horizontal-table-no-scroll"

		return (
			<div className={classNames}>
				<ToolkitProvider
					keyField="seq"
					data={rowData}
					columns={[
						{
							dataField: "seq",
							text: "#",
							headerAlign: "center",
							align: "center",
						},
						{
							dataField: "owner",
							text: "Owner",
							classes: "nowrap",
							headerAlign: "center",
							align: "center",
						},
						{
							dataField: "name",
							text: "Phase",
							classes: "nowrap",
							headerAlign: "center",
							align: "left",
						},
					]}
				>
					{props => (
						<BootstrapTable
							keyField="seq"
							classes="table-responsive-lg csl-stage-table text-inverse mt-1 mb-0"
							bordered={true}
							rowClasses={rowClasses}
							responsive
							hover
							{...props.baseProps}
						/>
					)}
				</ToolkitProvider>
				<ToolkitProvider
					keyField="seq"
					data={rowData}
					columns={[
						...this.props.groupsSpecification.dataColumns(groups, rowData),

						{
							dataField: "Total", //Current year ends
							text: "Total",
							headerAlign: "center",
							align: "center",
							formatter: (cell, row) => (cell > 0 ? PhasesPeriodEndRagBadge("Total", cell, context, periodEnds, row.link) : "-"),
						},
					]}
				>
					{props => (
						<BootstrapTable
							keyField="seq"
							classes="table-responsive-lg csl-stage-table text-inverse mt-1 mb-0"
							bordered={true}
							rowClasses={rowClasses}
							responsive
							hover
							{...props.baseProps}
						/>
					)}
				</ToolkitProvider>
			</div>
		)
	}
}
