import React, { useState } from "react"

import * as _ from "lodash"
import * as clearview from "../../../../../components/@Clearview"

import { Form, FormGroup, Input, CustomInput, InputGroup, Button, Modal, ModalHeader, ModalBody } from "../../../../../components"
import { Typeahead } from "react-bootstrap-typeahead"

import Formatters from "./Formatters"
import { ListSelector } from "../../../../../components/@Clearview/ListSelector"

const tokenCaret = "~"

export function ItemEditor({ ...props }) {
	const [item, setItem] = useState(_.cloneDeep(props.item))

	if (!_.isMatch(props.item, item)) {
		setItem(_.cloneDeep(props))
	}

	const title = () => {
		switch (props.type) {
			case "text":
				return "Text Editor"
			case "note":
				return "Note Editor"
			default:
				return "Report Item Editor"
		}
	}

	const body = () => {
		switch (props.type) {
			case "text":
				return <TextEditor key={clearview.GenerateKey()} path={props.path} element={item} onUpdate={props.onUpdate} onRemove={props.onRemove} />
			case "note":
				return (
					<NoteEditor
						key={clearview.GenerateKey()}
						path={props.path}
						element={item}
						config={props.config}
						onUpdate={props.onUpdate}
						onRemove={props.onRemove}
					/>
				)
			default:
				return <DefaultViewer key={clearview.GenerateKey()} path={props.path} element={item} />
		}
	}

	return (
		<Modal isOpen={true} id="itemEditor" style={{ maxWidth: 1000 }} className="overflowScroll">
			<ModalHeader key={clearview.GenerateKey()}>
				<div>{title()}</div>
				<div title="Close" color="link" className="text-primary close-modal-icon" onClick={props.onCancel}>
					<i className="fa fa-fw fa-close" />
				</div>
			</ModalHeader>
			<ModalBody>{body()}</ModalBody>
		</Modal>
	)

	function DefaultViewer({ path, element }) {
		return <p>{JSON.stringify(element, null, 2)}</p>
	}

	function TokenEditorControls({ token, setToken }) {
		const isChecked = key => !!token.pipes?.includes(key)

		return (
			<React.Fragment>
				<FormGroup>
					<InputGroup>
						<label className="full-width">Path:</label>
						<Typeahead
							id="at"
							name="at"
							options={props.dataCatalogue}
							placeholder="Start typing path to value..."
							allowNew={false}
							caseSensitive={false}
							clearButton={true}
							highlightOnlyResult={true}
							title={token.at}
							value={token.at}
							onChange={option => {
								if (!!option.length) {
									setToken({ ...token, at: option[0], isChanged: true })
								}
							}}
							defaultSelected={!!token.at ? [token.at] : []}
						/>
					</InputGroup>
				</FormGroup>
				<FormGroup>
					<InputGroup>
						<label className="full-width">Pipes:</label>
						<dl className="inline">
							{_.keys(Formatters)
								.filter(it => it !== "undefined")
								.map((key, idx) => (
									<React.Fragment key={idx}>
										<dt>
											<CustomInput
												className="ml-0 mr-1 mt-0 mb-0"
												type="checkbox"
												key={key}
												id={key}
												name={key}
												inline
												defaultChecked={isChecked(key)}
												onClick={event =>
													setToken({
														...token,
														pipes: event.target.checked ? _.uniq([...token.pipes, key]) : _.difference(token.pipes, [key]),
														isChanged: true,
													})
												}
											/>
										</dt>
										<dd>{_.startCase(key)}</dd>
									</React.Fragment>
								))}
						</dl>
					</InputGroup>
				</FormGroup>
			</React.Fragment>
		)
	}

	function removeDoubleWhiteSpace(text) {
		return text.replace(/[ |\t]{2,}/gm, " ")
	}

	function TextEditor({ path, element, onUpdate, onRemove }) {
		const [form, setForm] = useState({ text: clearview.firstValue(element), isChanged: false, token: false })

		const insertToken = e => {
			if (!form.token?.at?.length) return clearview.StopPropagation(e)
			setForm({ ...form, text: form.text.replace(tokenCaret, tokenToString(form.token)), token: false })
		}

		return (
			<Form
				onSubmit={e => {
					if (form.isChanged) {
						onUpdate(path, { [clearview.firstKey(element)]: removeDoubleWhiteSpace(form.text) })
						setForm({ ...form, isChanged: false })
					}
					return clearview.StopPropagation(e)
				}}
			>
				<FormGroup>
					<InputGroup>
						<label className="full-width">Text:</label>
						<Input
							type="textarea"
							name="text"
							id="text"
							placeholder="Type any text..."
							value={form.text}
							onKeyUp={e => {
								if (e.key === tokenCaret) {
									setForm({ ...form, token: { path: tokenCaret, pipes: [] } })
								}
							}}
							onChange={e => setForm({ ...form, text: e.target.value, isChanged: true })}
							rows={10}
						/>
					</InputGroup>
				</FormGroup>

				{form.token && (
					<fieldset className="clearview">
						<legend>Token:</legend>
						<TokenEditorControls key={clearview.GenerateKey()} token={form.token} setToken={token => setForm({ ...form, token })} />
						<Button color="primary" className="compact float-right" onClick={insertToken}>
							Insert
						</Button>
						<Button color="primary" className="compact float-right mr-2" onClick={e => setForm({ ...form, token: false })}>
							Cancel
						</Button>
					</fieldset>
				)}

				{!form.token && (
					<React.Fragment>
						<p>Type a ~ to insert a value from the Working Papers data.</p>
						<Button type="submit" color={form.isChanged ? "primary" : "secondary"} className="float-right">
							Update
						</Button>
						<Button color="danger" className="float-right mr-2" onClick={e => onRemove(path)}>
							Remove
						</Button>
					</React.Fragment>
				)}
			</Form>
		)
	}

	function NoteEditor({ path, element, config, onUpdate, onRemove }) {
		const [form, setForm] = useState({
			number: element?.number,
			text: element?.h3,
			nominalCodes: element?.nominalCodes || [],
			isChanged: false,
			token: false,
		})

		const insertToken = e => {
			if (!form.token?.at?.length) return clearview.StopPropagation(e)
			setForm({ ...form, text: form.text.replace(tokenCaret, tokenToString(form.token)), token: false })
		}

		return (
			<Form
				onSubmit={e => {
					if (form.isChanged) {
						onUpdate(path, { [clearview.firstKey(element)]: removeDoubleWhiteSpace(form.text), nominalCodes: form.nominalCodes })
						setForm({ ...form, isChanged: false })
					}
					return clearview.StopPropagation(e)
				}}
			>
				<FormGroup>
					<InputGroup>
						<label className="full-width">
							Note: {form.number}
							{!!form.nominalCodes && !!form.nominalCodes.length && (
								<span className="ml-2 note-info">applies to {form.nominalCodes.join(", ")}</span>
							)}
						</label>
						<Input
							type="textarea"
							name="text"
							id="text"
							placeholder="Type any text..."
							value={form.text}
							onKeyUp={e => {
								if (e.key === tokenCaret) {
									setForm({ ...form, token: { path: tokenCaret, pipes: [] } })
								}
							}}
							onChange={e => setForm({ ...form, text: e.target.value, isChanged: true })}
							rows={3}
						/>
					</InputGroup>
				</FormGroup>

				<FormGroup>
					<InputGroup>
						<ListSelector
							name="nominalCodes"
							id="nominalCodes"
							placeholder="Optionally specify Nominal Codes..."
							className="full-width"
							value={form.nominalCodes}
							valueLabel={"Applies to Nominal Codes"}
							optionsLabel={"Available Nominal Codes"}
							options={config.NOMINAL_CODES.all}
							optionValue={it => it.code}
							optionLabel={it => `${it.code}: ${it.name}`}
							onChange={selectedValues => setForm({ ...form, nominalCodes: selectedValues, isChanged: true })}
							rows={10}
						/>
					</InputGroup>
				</FormGroup>

				{form.token && (
					<fieldset className="clearview">
						<legend>Token:</legend>
						<TokenEditorControls key={clearview.GenerateKey()} token={form.token} setToken={token => setForm({ ...form, token })} />
						<Button color="primary" className="compact float-right" onClick={insertToken}>
							Insert
						</Button>
						<Button color="primary" className="compact float-right mr-2" onClick={e => setForm({ ...form, token: false })}>
							Cancel
						</Button>
					</fieldset>
				)}

				{!form.token && (
					<React.Fragment>
						<p>Type a ~ to insert a value from the Working Papers data.</p>
						<Button type="submit" color={form.isChanged ? "primary" : "secondary"} className="float-right">
							Update
						</Button>
						<Button color="danger" className="float-right mr-2" onClick={e => onRemove(path)}>
							Remove
						</Button>
					</React.Fragment>
				)}
			</Form>
		)
	}
}

function tokenToString(token) {
	return `{${token.at}${token.pipes?.length ? "|" : ""}${token.pipes?.join("|")}}`
}
