import React from "react"
import PropTypes from "prop-types"

import { Button } from "../../components"

import * as clearview from "../../components/@Clearview"

const EditSaveOrCancel = props => (
	<div className="d-flex-row justify-content-end">
		{!props.isReadOnly && !!props.onDelete && (
			<Button disabled={!!props.isChanged} style={{ height: "fit-content" }} outline color="danger" title="Delete..." onClick={props.onDelete}>
				{clearview.Icon.delete}
			</Button>
		)}
		{!props.isEditing && props.toggleEditing && (
			<Button style={{ height: "fit-content" }} outline color="link" title={props.editTitle || "Edit..."} onClick={props.toggleEditing}>
				{clearview.Icon.Editing[true]}
			</Button>
		)}
		{props.isEditing && (
			<Button
				disabled={!props.isChanged}
				style={{ height: "fit-content" }}
				className="ml-1"
				outline={props.isChanged !== true}
				color="primary"
				title={props.saveTitle || "Save"}
				onClick={e => {
					clearview.StopPropagation(e)
					props.onSave()
				}}
			>
				{clearview.Icon.save}
			</Button>
		)}
		{props.isEditing && (
			<Button
				disabled={!props.isChanged}
				style={{ height: "fit-content" }}
				className="ml-1"
				outline={props.isChanged !== true}
				color="primary"
				title={props.cancelTitle || "Cancel"}
				onClick={e => {
					clearview.StopPropagation(e)
					props.onCancel()
				}}
			>
				{clearview.Icon.cancel}
			</Button>
		)}
		{props.isReadOnly && (
			<Button
				disabled={true}
				style={{ height: "fit-content" }}
				className="ml-1"
				outline={true}
				color="warning"
				title="Read-Only. You will not be able to save any changes you make on this form."
			>
				{clearview.Icon.readOnly}
			</Button>
		)}
	</div>
)

EditSaveOrCancel.propTypes = {
	isReadOnly: PropTypes.bool,
	isEditing: PropTypes.bool.isRequired,
	isChanged: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
}

export { EditSaveOrCancel }
