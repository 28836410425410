import * as ACTIONS from "../actions"

const filesReducer = (state, action) => {
	if (!state)
		state = {
			files: { isBusy: false, lastUpdated: null, data: [] },
		}

	switch (action.type) {
		case ACTIONS.FOLDER_FETCH:
			return ACTIONS.setSubStateFetching(state, "files", [])

		case ACTIONS.FOLDER_FETCH_CLEAR:
			return ACTIONS.setSubStateFetched(state, "files", [])

		case ACTIONS.FOLDER_FETCHED:
			return ACTIONS.setSubStateFetched(state, "files", {
				data: action.files.map(it => ({
					...it,
					id: `${it.key}`,
				})),
			})

		default:
			return state
	}
}

export default filesReducer
