import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

import { Consumer } from "./context"

const UncontrolledModalCancel = props => {
	const { tag, ...otherProps } = props
	const Tag = tag

	return (
		<Consumer>
			{value => (
				<Tag
					{...otherProps}
					onClick={e => {
						let ok = true //confirm("Are you sure you want to Cancel?\n\nAny changes you have made will not be saved!")
						if (ok) {
							if (!props.onClick || props.onClick(e)) value.closeModal()
						}
					}}
				/>
			)}
		</Consumer>
	)
}
UncontrolledModalCancel.propTypes = {
	tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	onClick: PropTypes.func,
}
UncontrolledModalCancel.defaultProps = {
	tag: Button,
}

export { UncontrolledModalCancel }
