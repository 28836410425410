import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import classes from "./classes.scss"

import { Button, CustomInput, Accordion, Table, Avatar } from "../../../../components"
import Toggle from "react-toggle"

import { DragDropContext, Draggable } from "react-beautiful-dnd"
import { StrictModeDroppable } from "../../../components/StrictModeDroppable"

import _ from "lodash"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"
import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"
import { IsNotifiedIcon } from "../../../components/IsNotifiedIcon"

export const defaultTeams = (business, type) => [
	{ id: 0, actions: [], type: type, name: "Director", username: `${business.id}~${type}~Director`, users: [] },
	{ id: 0, actions: [], type: type, name: "Accounts", username: `${business.id}~${type}~Accounts`, users: [] },
	{
		id: 0,
		actions: [],
		type: type,
		name: type === "InHouseAppointees" ? "Service Charge Manager" : "Property Manager",
		username: `${business.id}~${type}~Manager`,
		users: [],
	},
	{ id: 0, actions: [], type: type, name: "Surveyor", username: `${business.id}~${type}~Surveyor`, users: [] },
	{ id: 0, actions: [], type: type, name: "Other", username: `${business.id}~${type}~Other`, users: [] },
]
export const teamSequence = {
	Director: 1,
	Accounts: 2,
	"Service Charge Manager": 3,
	"Property Manager": 3,
	Surveyor: 4,
	Other: 5,
}

class AppointeesCard extends React.Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		type: PropTypes.string.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.actorType = props.type === "InHouseAppointees" ? "InHouse" : "Client"

		this.initState = this.initState.bind(this)
		this.onToggleEditing = this.onToggleEditing.bind(this)
		this.onSave = this.onSave.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.onDragEnd = this.onDragEnd.bind(this)

		this.state = this.initState()
	}

	initState() {
		const { business, type } = this.props

		var userPool = []

		switch (type) {
			case "InHouseAppointees":
				ACTIONS.climbBranch(business, it => {
					if (it.type === "Customer") {
						var userTeam = _.find(it.teams, it => it.type === "Users")
						if (userTeam) userPool = userTeam.users
					}
				})
				break

			case "ClientAppointees":
				ACTIONS.climbBranch(business, it => {
					if (["Client", "Landlord", "Property"].includes(it.type)) {
						var userTeam = _.find(it.teams, it => it.type === "Users")
						if (userTeam) userPool.push(...userTeam.users)
					}
				})
				break

			default:
				break
		}

		userPool = _.sortBy(userPool, it => it.name)

		const teams = _.unionBy(
			business.teams.filter(it => it.type === type),
			defaultTeams(business, type),
			it => it.name
		)

		ACTIONS.climbBranch(business, it => {
			if (it !== business && it.teams) {
				it.teams.forEach(inheritedTeam => {
					const team = _.find(teams, t => t.type === inheritedTeam.type && t.name === inheritedTeam.name)
					if (team) {
						team.inheritedTeam = _.unionBy(team.inheritedTeam || [], inheritedTeam.users, u => u.username)
					}
				})
			}
		})

		return {
			userPool: _.sortBy(userPool, [it => it.initials, it => it.name]),
			businessId: this.props.business.id,
			teams: _.sortBy(_.cloneDeep(teams), [it => teamSequence[it.name]]),
			isChanged: false,
		}
	}

	onToggleEditing(e) {
		clearview.StopPropagation(e)
		this.setState((prevState, props) => ({ isEditing: !prevState.isEditing }))
	}

	onSave() {
		this.props.doAction(
			this.props.business,
			_.unionBy(
				this.state.teams.filter(it => it.id || it.users.length),
				this.props.business.teams,
				it => it.username
			)
		)
		this.setState({
			isChanged: false,
			isEditing: false,
		})
	}

	onCancel() {
		this.setState(this.initState())
	}

	componentDidUpdate(prevProps) {
		if (prevProps.business.reference !== this.props.business.reference) {
			this.setState(this.initState())
		}
	}

	onDragEnd(result, announce) {
		const { draggableId, source, destination } = result

		if (!source || !destination || source.droppableId === destination.droppableId) return

		const draggedUsername = draggableId.split("|")[0]
		let draggedMember = null

		//If we are dragging to somewhere else remove from source team
		if (destination && source.droppableId !== "UserPool") {
			var sourceTeam = _.find(this.state.teams, it => it.username === source.droppableId)
			draggedMember = _.find(sourceTeam.users, it => it.username === draggedUsername)
			_.remove(sourceTeam.users, it => it.username === draggedUsername)
		}

		//Are we dragging into an appointees team?
		if (destination && destination.droppableId !== "UserPool") {
			var destinationTeam = _.find(this.state.teams, it => it.username === destination.droppableId)
			var destinationTeamUser = _.find(destinationTeam.users, it => it.username === draggedUsername)
			if (!destinationTeamUser) {
				destinationTeamUser = draggedMember || _.cloneDeep(_.find(this.state.userPool, it => it.username === draggedUsername))
				destinationTeam.users.push(destinationTeamUser)
			}
		}

		if (announce) announce
		this.setState({ isChanged: true })
	}

	render() {
		const { isEditing } = this.state

		const { business, type } = this.props
		const handleRoleChanged = (e, user) => {
			user.role = e.target.value
			this.setState({ isChanged: true })
		}

		const handleIsContactChanged = (e, user) => {
			if (e.target.checked) {
				this.state.teams.forEach(t => {
					t.users.forEach(u => (u.isContact = false))
				})
			}
			user.isContact = e.target.checked
			this.setState({ isChanged: true })
		}

		const handleIsNotifiedChanged = (e, user) => {
			user.isNotified = e.target.checked
			this.setState({ isChanged: true })
		}

		const canManageTeams = (business.actions || []).includes("ManageTeams")

		return (
			<Accordion className="mb-2" initialOpen={true}>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Accordion.Header className="d-flex h6">
						<div className="flex-1">
							{type === "InHouseAppointees" ? (
								<span>{clearview.Icon.InHouse[true]} In-House</span>
							) : type === "ClientAppointees" ? (
								<span>{clearview.Icon.InHouse[false]} Client</span>
							) : null}
						</div>
						<Button
							className="inline-text"
							color="link"
							hidden={isEditing || !canManageTeams}
							title="Edit appointees..."
							onClick={this.onToggleEditing}
						>
							{clearview.Icon.edit}
						</Button>

						<EditSaveOrCancel
							isEditing={!!isEditing && canManageTeams}
							isChanged={this.state.isChanged}
							onSave={this.onSave}
							onCancel={this.onCancel}
						/>
					</Accordion.Header>
					<Accordion.Body>
						<Table size="sm">
							{isEditing && canManageTeams && (
								<thead>
									<tr>
										<td colSpan={3} className="card-header">
											<StrictModeDroppable droppableId="UserPool" direction="horizontal">
												{(provided, snapshot) => (
													<table>
														<tbody
															className={"d-flex"}
															style={{ flexWrap: "wrap" }}
															ref={provided.innerRef}
															{...provided.droppableProps}
														>
															{this.state.userPool.map((actor, index) => (
																<Draggable
																	key={`actor${actor.username}`}
																	draggableId={actor.username}
																	index={index}
																	isDragDisabled={!isEditing || !canManageTeams}
																>
																	{provided => (
																		<tr
																			className="px-1"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																		>
																			<td colSpan={3}>
																				<Avatar.Font user={actor} size="md" className="avatar-with-text"></Avatar.Font>
																			</td>
																		</tr>
																	)}
																</Draggable>
															))}
															{provided.placeholder}
														</tbody>
													</table>
												)}
											</StrictModeDroppable>
										</td>
									</tr>
								</thead>
							)}
							{this.state.teams.map(team => (
								<React.Fragment key={`team${team.username}`}>
									<StrictModeDroppable droppableId={`${team.username}`} direction="vertical">
										{(provided, snapshot) => (
											<tbody
												key={`team${team.username}$tr`}
												className={`${getListClass(snapshot.isDraggingOver)}`}
												style={{ overflowX: "auto" }}
												ref={provided.innerRef}
												{...provided.droppableProps}
											>
												<tr>
													<th colSpan={3}>{team.name}:</th>
												</tr>

												{team.inheritedTeam &&
													_.sortBy(team.inheritedTeam, iu => iu.name).map(inheritedUser => (
														<tr key={`I_A_${inheritedUser.username}`}>
															<td className="align-middle text-left">
																<Avatar.Font user={inheritedUser} size="sm" bgColor="gray" className="avatar-with-text mr-1" />
																<span>
																	{inheritedUser.name}
																	<IsNotifiedIcon user={inheritedUser} />
																</span>
															</td>
															<td key={`I_B0_${inheritedUser.username}`} className="text-right">
																<span>{clearview.Icon.IsContact[inheritedUser.isContact]}</span>
															</td>
															<td key={`I_B1_${inheritedUser.username}`} className="text-right">
																<span>{inheritedUser.role}</span>
															</td>
														</tr>
													))}

												{_.sortBy(team.users, [it => it.initials, it => it.name]).map((user, userIdx) => (
													<Draggable
														key={`team${team.username}user${user.username}`}
														draggableId={`${user.username}|team${team.username}user${user.username}`}
														index={userIdx}
														isDragDisabled={!isEditing || !canManageTeams}
													>
														{(provided, snapshot) => (
															<tr
																key={`A_${user.username}`}
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
															>
																<td key={`A_${user.username}`} className="align-middle text-left">
																	<Avatar.Font user={user} size="sm" className="avatar-with-text mr-1" />
																	<span>
																		{user.name}
																		<IsNotifiedIcon
																			hidden={isEditing && canManageTeams && type !== "ClientAppointees"}
																			user={user}
																		/>
																	</span>
																</td>
																<td key={`B_${user.username}`} className="align-middle tight">
																	{isEditing && canManageTeams && type !== "ClientAppointees" && (
																		<Toggle
																			defaultChecked={user.isNotified}
																			icons={{
																				checked: (
																					<span className="text-white">
																						{clearview.Icon.IsNotified[user.isNotified]}
																					</span>
																				),
																				unchecked: null,
																			}}
																			onChange={e => handleIsNotifiedChanged(e, user)}
																			title="Is Notified of Changes via Email"
																		/>
																	)}
																	{isEditing && canManageTeams && (
																		<Toggle
																			defaultChecked={user.isContact}
																			icons={{
																				checked: (
																					<span className="text-white">
																						{clearview.Icon.IsContact[user.isContact]}
																					</span>
																				),
																				unchecked: null,
																			}}
																			onChange={e => handleIsContactChanged(e, user)}
																			title="Team Contact"
																		/>
																	)}
																	{!isEditing && <span>{clearview.Icon.IsContact[user.isContact]}</span>}
																</td>
																<td key={`C_${user.username}`} className="text-right nowrap">
																	{isEditing && canManageTeams && (
																		<CustomInput
																			type="select"
																			id={`S-team${team.username}user${user.username}`}
																			defaultValue={user.role}
																			onChange={e => handleRoleChanged(e, user)}
																		>
																			<option>Reader</option>
																			<option>Member</option>
																			<option>Approver</option>
																			<option>Admin</option>
																		</CustomInput>
																	)}
																	{!isEditing && <span>{user.role}</span>}
																</td>
															</tr>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</tbody>
										)}
									</StrictModeDroppable>
								</React.Fragment>
							))}
						</Table>
					</Accordion.Body>
				</DragDropContext>
			</Accordion>
		)
	}
}

const getListClass = isDraggedOver =>
	classNames(classes["list"], {
		[classes["list--drag-over"]]: isDraggedOver,
	})

const mapDispatchToProps = dispatch => ({
	doAction: (business, teams) =>
		dispatch({
			type: ACTIONS.TEAMS_MANAGE,
			business: business,
			teams: teams,
		}),
})

export default connect(null, mapDispatchToProps)(AppointeesCard)
