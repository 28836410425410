import React from "react"
import PropTypes from "prop-types"

import { UncontrolledModal, ModalHeader, ModalBody } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"
import S3Navigator from "./S3Navigator"

export default class S3UncontrolledModal extends React.Component {
	static propTypes = {
		folder: PropTypes.string.isRequired,
		requiredSubfolders: PropTypes.array,
		className: PropTypes.string,
		children: PropTypes.node,
		title: PropTypes.string,
		name: PropTypes.string,
		isReadOnly: PropTypes.bool,
		onOpened: PropTypes.func,
		onChange: PropTypes.func,
		onClosed: PropTypes.func,
	}

	render() {
		return (
			<React.Fragment>
				<UncontrolledModal
					style={{ minWidth: 900 }}
					target={clearview.MakeSafeFolderId(this.props.folder)}
					onOpened={this.props.onOpened}
					onClosed={this.props.onClosed}
				>
					<ModalHeader tag="div">
						<div className="d-flex">
							<h1 className="text-primary mr-2">{clearview.Icon.attachments}</h1>
							<h1 className="text-primary mr-2">{this.props.title || this.props.folder}</h1>
						</div>
					</ModalHeader>
					<ModalBody style={{ height: 600 }}>
						<S3Navigator
							folder={this.props.folder}
							requiredSubfolders={this.props.requiredSubfolders}
							name={this.props.name}
							isReadOnly={this.props.isReadOnly}
							onChange={this.props.onChange}
						/>
					</ModalBody>
					<UncontrolledModal.Close color="default" className="text-primary">
						Close
					</UncontrolledModal.Close>
				</UncontrolledModal>
			</React.Fragment>
		)
	}
}
