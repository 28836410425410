import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import Property_NewPeriodEndForm from "./Property_NewPeriodEndForm"
import Dropdown from "react-bootstrap/Dropdown"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"

import AreYouSure from "../../../components/AreYouSure"
import EditClient_Form from "../../Clients/components/EditClient_Form"
import ManageUsers_Form from "../../Clients/components/ManageUsers_Form"
import ManageScopes_Form from "../../Clients/components/ManageScopes_Form"

class PropertyActions extends React.Component {
	static propTypes = {
		property: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props
	}

	render() {
		const { property, doRemoveProperty } = this.props
		const actions = property.actions || []

		return (
			<React.Fragment>
				<Dropdown hidden={!actions.length} className="align-self-center ml-auto" direction="left" onToggle={clearview.StopPropagation}>
					<Dropdown.Toggle variant="none">{clearview.Icon.actions}</Dropdown.Toggle>
					<Dropdown.Menu persist>
						<Dropdown.Item hidden={!actions.includes("NewPeriodEnd")}>
							<button className="tight" id="NewPeriodEndForm">
								{clearview.Icon.periodEnd} New Period End
							</button>
						</Dropdown.Item>
						<Dropdown.Item
							hidden={!actions.includes("NewPeriodEnd") && !actions.includes("RemoveProperty") && !actions.includes("SetProperties")}
							divider
						/>
						<Dropdown.Item hidden={!actions.includes("SetProperties")}>
							<button className="tight" id="EditClient_Form">
								{clearview.Icon.edit} Edit...
							</button>
						</Dropdown.Item>
						<Dropdown.Item hidden={!actions.includes("RemoveProperty")}>
							<button className="tight" id="RemoveProperty_AreYouSure">
								{clearview.Icon.delete} Delete
							</button>
						</Dropdown.Item>
						<Dropdown.Item hidden={!actions.includes("ManageTeams") && !actions.includes("ManageScopes")} divider />
						<Dropdown.Item hidden={!actions.includes("ManageTeams")}>
							<button className="tight" id="ManageUsers_Form">
								{clearview.Icon.Business.Users} Users &amp; Teams
							</button>
						</Dropdown.Item>
						<Dropdown.Item hidden={!actions.includes("ManageTeams")}>
							<button className="tight" id="ManageScopes_Form">
								{clearview.Icon.Business.Teams} Team Scopes
							</button>
						</Dropdown.Item>
						<Property_NewPeriodEndForm property={property} />
						<EditClient_Form client={property} />
						<AreYouSure
							id="RemoveProperty_AreYouSure"
							message={
								<span>
									Delete <b className="nowrap">{property.name}</b> from <b className="nowrap">{property.parent.name}</b>, and all its Period
									Ends?
								</span>
							}
							action={() => doRemoveProperty(property)}
						/>
						<ManageUsers_Form business={property} />
						<ManageScopes_Form business={property} />
					</Dropdown.Menu>
				</Dropdown>
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doRemoveProperty: property =>
		dispatch({
			type: ACTIONS.PROPERTY_REMOVE,
			property: property,
		}),
})

export default connect(null, mapDispatchToProps)(PropertyActions)
