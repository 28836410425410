import React from "react"

import * as _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import { Container } from "../../../../components"

import S3Navigator from "../../components/s3/S3Navigator"

export default function AccountsPage({ periodEnd }) {
	const folder = `/periodEnd/${periodEnd.id}/accounts`

	return (
		<Container style={{ width: 1000 }}>
			<div>
				<div className="d-flex">
					<h1 className="text-primary mr-2">{clearview.Icon.attachments}</h1>
					<h1 className="text-primary mr-2">Accounts</h1>
				</div>
				<div style={{ width: "100%", height: 350 }}>
					<S3Navigator folder={folder} name={"Accounts"} />
				</div>
			</div>
		</Container>
	)
}
