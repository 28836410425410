import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"

import { Row, Col, Card, CardBody, CardTitle, ButtonGroup } from "../../../../components"

import { CustomExportCSV } from "./CustomExportButton"

import * as ACTIONS from "../../../../store/actions"

class QueryReports extends Component {
	static propTypes = {
		queries: PropTypes.array.isRequired,
	}

	render() {
		const allReplies = this.props.queries.flatMap(q => [
			{
				type: q.type,
				reference: q.reference,
				number: `Q${q.number}`,
				subject: q.subject,
				title: q.title,
				comments: q.comments,
				createdBy: q.createdBy,
				createdAt: q.createdAt,
				assignedTo: q.assignedTo,
				stage: q.stage,
			},
			...q.replies.map(r => ({
				...r,
				reference: q.reference,
				number: `Q${q.number}`,
				subject: q.subject,
				title: q.title,
				assignedTo: q.assignedTo,
				stage: q.stage,
			})),
		])

		return (
			<React.Fragment>
				<Row>
					<Col lg={4}>
						<Card className="mb-3">
							<CardBody>
								<CardTitle tag="h6">
									Query Report<span className="small ml-1 text-muted">#Q.01</span>
								</CardTitle>
								<p>All Queries for all Period Ends</p>

								<ToolkitProvider
									exportCSV={{ fileName: "Queries.csv" }}
									data={allReplies}
									keyField="id"
									columns={[
										{
											dataField: "stage.periodEnd.reference",
											text: "Period End",
											csvFormatter: (col, row) => col || row.stage.periodEnd.reference,
										},
										{ dataField: "number", text: "Number" },
										{ dataField: "reference", text: "Reference" },
										{ dataField: "createdAt", text: "At" },
										{ dataField: "createdBy.name", text: "By" },
										{ dataField: "subject", text: "Subject" },
										{ dataField: "title", text: "Title" },
										{ dataField: "type", text: "Type" },
										{ dataField: "comments", text: "Text" },
										{ dataField: "assignedTo.name", text: "Assigned To" },

										{
											dataField: "stage.periodEnd.template.name",
											text: "Template",
											csvFormatter: (col, row) => col || row.stage.periodEnd.template.name,
										},
										{
											dataField: "stage.periodEnd.property.reference",
											text: "Property Ref.",
											csvFormatter: (col, row) => col || row.quy.stage.periodEnd.property.reference,
										},
										{
											dataField: "stage.periodEnd.property.name",
											text: "Property",
											csvFormatter: (col, row) => col || row.stage.periodEnd.property.name,
										},
										{
											dataField: "stage.periodEnd.property.parent.reference",
											text: "Client/Landlord Ref.",
											csvFormatter: (col, row) => col || row.stage.periodEnd.property.parent.reference,
										},
										{
											dataField: "stage.periodEnd.property.parent.name",
											text: "Client/Landlord Name",
											csvFormatter: (col, row) => col || row.stage.periodEnd.property.parent.name,
										},

										{
											dataField: "stage.templateStage.sequence",
											text: "Stage #",
											csvFormatter: (col, row) => col || row.stage.templateStage.sequence,
										},
										{
											dataField: "stage.templateStage.name",
											text: "Stage",
											csvFormatter: (col, row) => col || row.stage.templateStage.name,
										},
										{ dataField: "stage.status", text: "Stage Status", csvFormatter: (col, row) => col || row.stage.status },
										{ dataField: "stage.rag", text: "Stage Rag", csvFormatter: (col, row) => col || row.stage.rag },
										{
											dataField: "stage.periodEnd.property.actors.inHouseContact.name",
											text: "In House Contact",
											csvFormatter: (col, row) =>
												col ||
												(row.stage.periodEnd.property.actors.inHouseContact
													? row.stage.periodEnd.property.actors.inHouseContact.name
													: ""),
										},
										{
											dataField: "stage.periodEnd.property.actors.clientContact.name",
											text: "Client Contact",
											csvFormatter: (col, row) =>
												col ||
												(row.stage.periodEnd.property.actors.clientContact
													? row.stage.periodEnd.property.actors.clientContact.name
													: ""),
										},
										{ dataField: "stage.dueDate", text: "Stage Due", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
									]}
								>
									{props => (
										<React.Fragment>
											<div className="d-flex justify-content-end align-items-center mb-2">
												<div className="d-flex ml-auto">
													<ButtonGroup>
														<CustomExportCSV color="secondary" {...props.csvProps}>
															<i className="fa fa-download mr-2"></i>Download
														</CustomExportCSV>
													</ButtonGroup>
												</div>
											</div>
											<BootstrapTable classes="hidden" {...props.baseProps} />
										</React.Fragment>
									)}
								</ToolkitProvider>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, { location }) => {
	var subState = ACTIONS.getSubState(state, "clientsReducer\\periodEnds")
	var queries = ACTIONS.dictionaryToArray(subState.dict).flatMap(pe =>
		pe.stages.flatMap(s => s.comments.filter(it => ["DraftQuery", "Query", "ClosedQuery"].includes(it.type)))
	)

	return {
		queries: queries,
	}
}

QueryReports = connect(mapStateToProps)(QueryReports)

export { QueryReports }
