import React from "react"

import * as clearview from "../../../../../components/@Clearview"

import { SimpleTable } from "../../../../../components"

export function CommonTable({ data, onAdd, onDelete, onEdit }) {
	if (!data) return

	const columns = {
		schedule: { map: clearview.tidy.string },
		code: { map: clearview.tidy.string },
		account: {
			map: (col, row) => clearview.tidy.string(row.nominalCode?.name),
			className: "nowrap",
		},
		total: { map: clearview.tidy.currency, className: "currency" },
	}

	return (
		<SimpleTable
			className="auto-width"
			columns={columns}
			data={data}
			groupBy={["schedule"]}
			summaryColumns={["total"]}
			onAdd={onAdd}
			onDelete={onDelete}
			onEdit={onEdit}
		/>
	)
}
