import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"

import { Container, Row, Col, UncontrolledTabs, TabPane, Nav, NavItem } from "../../../components"

import { HeaderMain } from "../../components/HeaderMain"

import { PeriodEndFilter } from "../components/PeriodEndFilter"

import { PeriodEndReports } from "./components/PeriodEndDownloads"
import { PropertyReports } from "./components/PropertyDownloads"
import { QueryReports } from "./components/QueryDownloads"
// import { SimpleBarChart } from "../Overview/components/SimpleBarChart"

import { Consumer } from "../../../layout/context"

class DownloadsDashboard extends Component {
	render() {
		const maxOpenStage = _.max(this.props.periodEnds.map(it => it.stages.length)) || 0
		const stageRange = _.range(maxOpenStage ? 1 : 0, maxOpenStage ? maxOpenStage + 1 : 0)

		return (
			<Consumer>
				{context => {
					const livePeriodEnds = context.filtered(this.props.periodEnds.filter(it => ["NotYetOpen", "Open"].includes(it.status))) || []

					const openStagesBySequence = _.groupBy(
						(livePeriodEnds || []).filter(it => it.currentStage),
						it => it.currentStage.templateStage.sequence
					)
					// const openStages = stageRange.map(key => ({
					// 	name: `S${key}`,
					// 	Client: (openStagesBySequence[key] || []).filter(it => !it.currentStage.templateStage.isInHouse).length,
					// 	"In House": (openStagesBySequence[key] || []).filter(it => it.currentStage.templateStage.isInHouse).length,
					// }))
					// const openStagesOverdue = stageRange.map(key => ({
					// 	name: `S${key}`,
					// 	Client: (openStagesBySequence[key] || []).filter(it => !it.currentStage.templateStage.isInHouse && it.currentStage.rag === "Overdue")
					// 		.length,
					// 	"In House": (openStagesBySequence[key] || []).filter(it => it.currentStage.templateStage.isInHouse && it.currentStage.rag === "Overdue")
					// 		.length,
					// }))

					// const stagesLongerThanPlanned = stageRange.map(key => ({
					// 	name: `S${key}`,
					// 	Client: (openStagesBySequence[key] || []).filter(
					// 		it => !it.currentStage.templateStage.isInHouse && it.currentStage.durationDays > it.currentStage.templateStage.plannedDays
					// 	).length,
					// 	"In House": (openStagesBySequence[key] || []).filter(
					// 		it => it.currentStage.templateStage.isInHouse && it.currentStage.durationDays > it.currentStage.templateStage.plannedDays
					// 	).length,
					// }))

					// const stagesStartedLaterThanPlanned = stageRange.map(key => ({
					// 	name: `S${key}`,
					// 	Client: (openStagesBySequence[key] || []).filter(
					// 		it => !it.currentStage.templateStage.isInHouse && it.currentStage.startedAt > it.currentStage.plannedStart
					// 	).length,
					// 	"In House": (openStagesBySequence[key] || []).filter(
					// 		it => it.currentStage.templateStage.isInHouse && it.currentStage.startedAt > it.currentStage.plannedStart
					// 	).length,
					// }))

					return (
						<React.Fragment>
							<Container>
								<Row className="mb-5">
									<Col lg={8}>
										<HeaderMain
											title={<span className="text-primary">{clearview.Icon.downloads}Downloads</span>}
											className="mb-4 mb-lg-5 mt-0"
											// onRefresh={() => this.props.doRefresh()}
										/>
									</Col>
									<Col lg={4}>
										<PeriodEndFilter key={clearview.GenerateKey()} className="mr-2" periodEnds={this.props.periodEnds} />
									</Col>
								</Row>

								<Row>
									<Col lg={12}>
										<UncontrolledTabs initialActiveTabId="periodEnds">
											<Nav pills className="mb-4 flex-column flex-md-row mt-4 mt-lg-0">
												<NavItem>
													<UncontrolledTabs.NavLink tabId="periodEnds">Period Ends</UncontrolledTabs.NavLink>
												</NavItem>
												<NavItem>
													<UncontrolledTabs.NavLink tabId="queries">Queries</UncontrolledTabs.NavLink>
												</NavItem>
												<NavItem>
													<UncontrolledTabs.NavLink tabId="properties">Properties</UncontrolledTabs.NavLink>
												</NavItem>
												{/* <NavItem>
                                    <UncontrolledTabs.NavLink tabId="charts">
                                        Charts
                                    </UncontrolledTabs.NavLink>
                                </NavItem> */}
											</Nav>
											<UncontrolledTabs.TabContent>
												<TabPane tabId="periodEnds">
													<PeriodEndReports />
												</TabPane>

												<TabPane tabId="queries">
													<QueryReports />
												</TabPane>

												<TabPane tabId="properties">
													<PropertyReports />
												</TabPane>

												{/* <TabPane tabId="charts">
                                    <Row className="mb-5">
                                        <Col lg={ 6 } md={ 12 } className="mb-4 mb-lg-0">
                                            <div className="hr-text hr-text-left my-2">
                                                <span>Open Stages</span>
                                            </div>
                                            <Media>
                                                <Media left className="mr-3">
                                                    <SimpleBarChart data={openStages} />
                                                </Media>
                                            </Media>
                                        </Col>
                                        <Col lg={ 6 } md={ 12 } className="mb-4 mb-lg-0">
                                            <div className="hr-text hr-text-left my-2">
                                                <span>Open Stage Is Late</span>
                                            </div>
                                            <Media>
                                                <Media left className="mr-3">
                                                    <SimpleBarChart data={openStagesOverdue} />
                                                </Media>
                                            </Media>
                                        </Col>
                                    </Row>

                                    <Row className="mb-5">
                                        <Col lg={ 6 } md={ 12 }>
                                            <div className="hr-text hr-text-left my-2">
                                                <span>Open Stage Duration Longer than Planned</span>
                                            </div>
                                            <Media>
                                                <Media left className="mr-3">
                                                    <SimpleBarChart data={stagesLongerThanPlanned} />
                                                </Media>
                                            </Media>
                                        </Col>
                                        <Col lg={ 6 } md={ 12 } className="mb-4 mb-lg-0">
                                            <div className="hr-text hr-text-left my-2">
                                                <span>Open Stage Started Later than Planned</span>
                                            </div>
                                            <Media>
                                                <Media left className="mr-3">
                                                    <SimpleBarChart data={stagesStartedLaterThanPlanned} />
                                                </Media>
                                            </Media>
                                        </Col>
                                    </Row>
                                </TabPane> */}
											</UncontrolledTabs.TabContent>
										</UncontrolledTabs>
									</Col>
								</Row>
							</Container>
						</React.Fragment>
					)
				}}
			</Consumer>
		)
	}
}

const mapStateToProps = state => {
	const periodEndsSubState = ACTIONS.getSubState(state, "clientsReducer\\periodEnds")

	return {
		...periodEndsSubState,
		periodEnds: ACTIONS.dictionaryToArray(periodEndsSubState.dict),
	}
}

export default connect(mapStateToProps)(DownloadsDashboard)
