import React from 'react';
const TinyDonutLegend = (props) => (
    props.data.map( (it,idx) => <div key={idx}>
        <i className="fa fa-circle mr-1" style={{color: it.color}}></i> 
        <span className="text-inverse">{it.value}</span> {it.name}
    </div>
    )
);

export { TinyDonutLegend };
