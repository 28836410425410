import React from "react"

import { Container, Row, Col } from "../../../../components"

import { TimelineDefault } from "./Releases_TimelineDefault"

import { ItemMap } from "./Releases"

export const Releases2020 = () => (
	<React.Fragment>
		<Container>
			<Row>
				<Col lg={8} className="release-timeline">
					{Release_20201130.map(ItemMap("30 Nov 2020"))}
					{Release_20201103.map(ItemMap("03 Nov 2020"))}
					{Release_20201020.map(ItemMap("20 Oct 2020"))}
					{Release_20201012.map(ItemMap("12 Oct 2020"))}
					{Release_20201009.map(ItemMap("09 Oct 2020"))}
					{Release_20201004.map(ItemMap("04 Oct 2020"))}
					{Release_20200930.map(ItemMap("30 Sep 2020"))}
					{Release_20200917}
					{Release_20200909}
					{Release_20200901}
				</Col>
			</Row>
		</Container>
	</React.Fragment>
)

const Release_20201130 = [
	["check", "On the dashboard, the Active Period End total doesn’t match the total under properties.", "Now fixed. On-hold Period Ends were being omitted."],
	["check", "There is no option to export queries to Excel", "You can now export all queries from the Query Management page."],
	[
		"info",
		'"I tried entering another Query requesting further information which I managed to obtain from the client account, however the files and the query disappeared from the system as soon as I closed the query."',
		"Closed queries are now visible in the Query Management page and in the Stage they apply to.",
	],
	[
		"info",
		"On the Period End page add filter on a period end date.",
		"Period Ends and Open Stages can now be filtered by Period End date and Due date range.",
	],
	[
		"info",
		"Option to enter budget fee for all Period Ends.",
		'In-House users can see and update Period End "Fee" values on the Period End details page. This field is not visible to Client users.',
	],
	["info", "Query Management option to filter on Open queries as well as Closed Queries.", "Now available on the Query Management page filter."],
	[
		"info",
		"New Period Ends to be pre-filled with the next Period End date.",
		"Period Ends will be created automatically by the auto-renew function. However, if the user wishes to raise a Period End early, they can do so manually. When the user selects the Template type, the date will be pre-filled with the next Period End date IF THERE IS ALREADY A PERIOD END OF THAT TYPE.",
	],
	[
		"info",
		"Option to assign schemes to Accountants so that they take ownership of drafting the assigned schemes.",
		'To do this, create an In-House team for each scheme and add the accountant to it. The Period End filter now includes a "Filter by assigned In-House team member" option.',
	],
	[
		"info",
		"Have a submit button for queries so that they are not submitted to the client until the drafts are ready to send out.",
		'New queries can now be "Saved as Draft" and will not be visible to the other party. You can "Edit" a Draft Query and "Send" it from the Query Management page when you are happy with it.',
	],
	["info", "Production URLs & SSL certificates", "Customer specific URLs with SSL certificates are now in production."],
	[
		"info",
		"Link to an open period from the Client screen so that its quick for us to navigate through the system",
		"If you load the Period Ends page before visiting the Clients page then a link to the Period End will appear below the Property in the tree.",
	],
]

const Release_20201103 = [
	[`info`, `Add/Rename/Delete new Users`, `Users can be added to Customers and Clients on the Clients screen.`],
	[`info`, `Add/Remove Users from Teams`, `Users can be added to Teams on the Clients screen.`],
	[
		`check`,
		`Queries: Queries When you select a person to send a query to, if you select a person but want to change the selected person, the system crashes`,
		`Now fixed.`,
	],
	[`check`, `Unable to create new In-House and Client Teams`, `Now fixed.`],
]

const Release_20201020 = [
	[`check`, `Queries: Client users cannot select Customer recipients`, `Now fixed. Clients users can also now see Customer entity in the Client hierarchy.`],
	[`check`, `Queries: Unable to raise queries`, `Now fixed..`],
]

const Release_20201012 = [
	[`info`, `Multiple File Upload`, `Up to 20 files can be upload at once.`],
	[
		`info`,
		`Query Filter In-House/With Client Icon changed`,
		`The icon for filtering between In House and With Client queries has been changed from a heart to a query bubble to differentiate it from the In-House/Client Stage Icon that appears elsewhere.`,
	],
	[
		`info`,
		`Template Editing`,
		`In-House Admin users can create, edit and delete Templates, Template Stages and Template Conditions. Note that deleting a template, stage or condition WILL NOT remove it from an existing Period End that uses it, but editing one WILL change the wording.`,
	],
	[
		`info`,
		`User Permission Editing`,
		`In-House Admin users remove users from teams and change their Role within a team. The In-House and Client Contact can also be set.`,
	],
	[`info`, `Client and Property Editing`, `In-House Admin can correct the Reference, Name and Address of Clients and Properties.`],
	[`bug`, `User and Team Create and Delete`, `Not yet implemented.`],
	[`bug`, `Client and Property Create and Delete`, `Not yet implemented.`],
]

const Release_20201009 = [
	[`check`, `Crashes on Property Search`, `Now fixed.`],
	[`check`, `Unable to create New Period Ends`, `Now fixed.`],
	[
		`info`,
		`Differentiate between "In-House" Queries and "With Client" Queries`,
		`Queries which require an In-House response now have Red icons whereas Queries requiring a response from the client are Orange. The "speech bubbles" icon is also slightly different. Replies to Queries are now coloured pale Blue.`,
	],
	[
		`info`,
		`Templates load faster`,
		`The template data was taking more than 6 seconds to load. This could cause problems accessing the Templates tab and other delays in the UI. This has been sped up considerably.`,
	],
]

const Release_20201004 = [
	[`check`, `Error Uploading files`, `Now fixed.`],
	[`info`, `Attachments`, `Files can now be attached to Stages, Queries, Properties and Clients.`],
	[`info`, `Branded Logo`, `In-House and Client users will now get the Customer's logo.`],
]

const Release_20200930 = [
	[`bug`, `Error Uploading files`, `This bug is under active investigation.`],
	[`bug`, `Crashes on Refresh`, `This bug is under active investigation.`],
	[
		`check`,
		`Manual Refresh on all pages`,
		`When one side makes a comment/query, the other side has to come out and go back to receive it – the page does not updated automatically. Unfortunately its too expensing in terms of bandwidth to constantly check for updates. I have added a manual refresh icon to each page to force a fresh. However, in practice, the data is refreshed as users navigate around the app. Users will not usually be sitting on a screen waiting for the other party to do an update.`,
	],
	[`check`, `Bug Fix: Progress Bars where showing complete when actually not started.`, `Fixed`],
	[
		`info`,
		`New Stage Rules`,
		`Users with the "Member" role can only "Request Close". Only users with the "Approver" role can "Close" a stage. In-House Approvers can Close Client stages, but Client users cannot close In-House stages. Client users cannot see Comments on In-House stages.`,
	],
	[
		`info`,
		`New Query Rules`,
		`Although Client Users can raise Queries in In-House stages, they cannot see Queries raised in In-House stages by In-House users unless the query was Assigned To a Client User or Team. A Client User cannot Close a query that was raised by an In-House user, but an In-House user can Close any Client query.`,
	],
	[`info`, `Period End Progress Bar Improvements`, `Removed blue rings around progress bar dots. Removed process bar labels when shown on Properties card.`],
	[
		`info`,
		`Filter Improvements`,
		`Stage Name added to Period End and Query filters. Filters show the number of records filtered. Query page now has a filter.`,
	],
	[
		`info`,
		`Overview Statistics Improvements`,
		`Stages in house and With client are now hyperlinks. Added Queries Response Required and Queries Waiting For Reply.`,
	],
	[`info`, `Enhanced Exports`, `Can now export from the Open Stages page. More information is exported than is displayed on the screen.`],
	[`info`, `Concepts Page`, `Concepts tab added to the Support page. This is the start of the online help and information system.`],
	[
		`info`,
		`In-House/Client Account is now "Contact"`,
		`A single user in the In-House or Client teams can be defined as the "Contact". This person's details will be shown in the In-House/Client Contact field, where available.`,
	],
	[`info`, `Enhanced Search`, `The search box on the Open Stages, Period End and Query Management pages now searches more fields than are displayed`],
	[`pause`, `Not included: Editing`, `Editing of Clients, Properties, Users and Templates is not included in this release. Under development.`],
	[`pause`, `Not included: Export from Clients page`, `Under consideration. Meanwhile use export from Properties page.`],
]

const Release_20200917 = (
	<React.Fragment>
		<TimelineDefault
			showPillDate
			pillDate="17 Sep 2020"
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Property cards on Properties table"
			content="Expanding a row will now show the property details card"
		/>
		<TimelineDefault
			iconCircle="check"
			smallIconColor="success"
			iconCircleColor="success"
			title="Re-open stage fixed!"
			content="The previous stage can now be re-opened."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Filter and Sort"
			content="Open Stages and Period Ends now have filter options. The Open Stages also now has sort and custom search. Filters are also available on the Properties table."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Theme selector removed"
			content="Users can no longer experiment with the menu bar colour settings."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Clients, Managing Agents and Landlords"
			content="The UI now differentiates between Client, Managing Agent and Landlord in Open Stages, Period End, Clients and Properties pages."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="In-house and Client Accountants"
			content="In-House and Client accountants are now displayed for all businesses (if specified in an owning team)."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Period End progress bars"
			content="PeriodEnd progress bars now show progress against plan, not actual days."
		/>
	</React.Fragment>
)

const Release_20200909 = (
	<React.Fragment>
		<TimelineDefault
			showPillDate
			pillDate="09 Sep 2020"
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Period End Attachments"
			content="Files can n ow be attached to Period Ends."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Client logins enabled"
			content="Client users can now access the Client stages of Period Ends and perform appropriate actions."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Bell and Envelope icons removed"
			content="The bell and envelope icons have been removed from the top of the window. These did not show any useful information."
		/>
		<TimelineDefault
			iconCircle="check"
			smallIconColor="success"
			iconCircleColor="success"
			title="Clients view failed to load"
			content="Resolve bug where being a member of two teams could prevent the Clients view from loading."
		/>
	</React.Fragment>
)

const Release_20200901 = (
	<React.Fragment>
		<TimelineDefault
			showPillDate
			pillDate="01 Sep 2020"
			iconCircle="check"
			smallIconColor="success"
			iconCircleColor="success"
			title="Clients Tree"
			content="Search function and navigation should now work as expected."
		/>
		<TimelineDefault
			iconCircle="check"
			smallIconColor="success"
			iconCircleColor="success"
			title="Period End"
			content="“Projected end date” is now correctly shown and persisted in the database."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Period End Card"
			content="New action on the Period End card to “Start” a Period end that has not yet reached is templated start date."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Period End Card, Property Card"
			content="These cards now contain more info, better formatted."
		/>
		<TimelineDefault
			iconCircle="info"
			smallIconColor="info"
			iconCircleColor="info"
			title="Period End Card, Property Card, Template Card"
			content="These cards can now be expanded/collapsed by clicking on their headers."
		/>
	</React.Fragment>
)
