import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import _ from "lodash"

import { UncontrolledModal } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import WorkflowExcelForm from "../components/WorkflowExcelForm"

import sampleFile from "./PropertiesAndPeriodEnds.xlsx"

class ImportPeriodEnds_Form extends React.Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		customerTemplates: PropTypes.array.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.onUploaded = this.onUploaded.bind(this)
	}

	onUploaded(file, data) {
		const thisCustomer = _.first(this.props.customerTemplates.filter(it => it.lft <= this.props.business.lft && it.rgt >= this.props.business.rgt))
		const templates = thisCustomer ? _.sortBy(thisCustomer.templates, it => it.reference).filter(it => !it.isRetired) : []

		const periodEnds = (data || [])
			.map(row => ({
				property: ACTIONS.findInBranch(
					this.props.business,
					p => p.reference === row["Ref"]?.toString(),
					p => p.children
				),
				template: templates.find(t => t.reference === row["Template Ref"]?.toString()),
				endDate: clearview.DateToString(row["Period End Date"]),
			}))
			.filter(
				it =>
					it.property && it.template && it.endDate && !_.find(it.property.periodEnds, pe => pe.endDate === it.endDate && pe.template === it.template)
			)

		this.props.doAction(this.props.business, periodEnds)
		clearview.ShowToast(
			"Info",
			<div>
				<p>Creating {periodEnds.length} Period Ends.</p>
				<p>Please wait...</p>
			</div>,
			false,
			"importPeriodEnds"
		)
	}

	render() {
		const { business } = this.props

		return (
			<UncontrolledModal style={{ maxWidth: 600 }} className="overflowScroll" target="ImportPeriodEnds_Form">
				<WorkflowExcelForm
					title={
						<span>
							Start <b>New Period Ends</b> under <b className="nowrap">{business.name}</b>
						</span>
					}
					onUploaded={this.onUploaded}
					handleCancelButtonClick={this.handleCancelButtonClick}
					sampleFile={sampleFile}
				></WorkflowExcelForm>
			</UncontrolledModal>
		)
	}
}

const mapStateToProps = state => {
	return {
		customerTemplates: ACTIONS.dictionaryToArray(ACTIONS.getSubState(state, "clientsReducer\\templates").dict),
	}
}

const mapDispatchToProps = dispatch => ({
	doAction: (parent, periodEnds) =>
		dispatch({
			type: ACTIONS.PERIODENDS_CREATE,
			parent: parent,
			periodEnds: periodEnds,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportPeriodEnds_Form)
