import React from "react"
import moment from "moment"
import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"
import { Consumer } from "../../../../layout/context"

import { Link } from "react-router-dom"
import { Avatar, Card, ButtonGroup } from "../../../../components"

import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import paginationFactory from "../../../../react-bootstrap-table2-paginator"

import { CustomExportCSV } from "./CustomExportButton"
import { CustomSearch } from "./CustomSearch"

import { PeriodEndFilter } from "../../components/PeriodEndFilter"
import { PeriodEndSort } from "../../components/PeriodEndSort"

import { PeriodEndProgressBar } from "../../PeriodEnds/components/PeriodEndProgressBar"
import { PeriodEndFavourite } from "../../PeriodEnds/components/PeriodEndFavourite"
import { PeriodEndUnread } from "../../PeriodEnds/components/PeriodEndUnread"

import { PeriodEndBadges } from "../../PeriodEnds/components/PeriodEndBadges"
import Conditions from "./Conditions"
import { StageActionsLinks } from "./StageActions"
import { Comment, CommentTerse } from "./Comment"

import PropTypes from "prop-types"

class OpenStagesTable extends React.Component {
	static propTypes = {
		periodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {}

		this.onSearch = this.onSearch.bind(this)
		this.searchPredicate = this.searchPredicate.bind(this)
	}

	onSearch(value) {
		this.setState({
			search: value,
		})
	}

	searchPredicate = periodEnd => {
		if (!this.state.search) return true

		const regex = clearview.Search.SafeRegex(this.state.search)

		return (
			clearview.Search.Stage(periodEnd.currentStage, regex) ||
			clearview.Search.PeriodEnd(periodEnd, regex) ||
			clearview.Search.Business(periodEnd.property, regex) ||
			(periodEnd.currentStage && clearview.Search.Comments(periodEnd.currentStage.comments, regex))
		)
	}

	render() {
		const periodEnds = this.props.periodEnds.filter(this.searchPredicate)

		const { user } = this.props

		let columns = [
			{ dataField: "reference", text: "Ref." },

			{ dataField: "template.name", text: "Template" },
			{ dataField: "property.reference", text: "Property Ref." },

			{ dataField: "endDate", text: "Period End", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
			{ dataField: "dueDate", text: "Due Date", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
			{ dataField: "projectedEndDate", text: "Expected", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },

			{ dataField: "property.name", text: "Property" },
			{ dataField: "property.parent.reference", text: "Client/Landlord Ref." },
			{ dataField: "property.parent.name", text: "Client/Landlord Name" },

			{ dataField: "property.actors.inHouseContact.name", text: "In House Contact" },
			{ dataField: "property.actors.clientContact.name", text: "Client Contact" },

			{ dataField: "currentStage.templateStage.sequence", text: "Stage #" },
			{ dataField: "currentStage.templateStage.name", text: "Stage" },
			{ dataField: "currentStage.status", text: "Stage Status" },
			{ dataField: "currentStage.rag", text: "Stage Rag" },
			{ dataField: "currentStage.dueDate", text: "Stage Due", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
			{ dataField: "fee", text: "Fee", csvFormatter: (col, row) => col / 100 },
			{ dataField: "expenditureFinal", text: "Final Expenditure", csvFormatter: (col, row) => col / 100 },
			{ dataField: "expenditureBudget", text: "Budget Expenditure", csvFormatter: (col, row) => col / 100 },
			{ dataField: "doNotRenew", text: "Do Not Renew" },
			{ dataField: "isPublished", text: "Published" },
		]

		if (!user.isInHouse) columns = columns.filter(it => !["projectedEndDate", "fee", "Final Expenditure", "Budget Expenditure"].includes(it.dataField))

		return (
			<Consumer>
				{context => (
					<React.Fragment>
						<ToolkitProvider keyField="reference" data={context.sortedAndFiltered(periodEnds)} columns={columns} exportCSV>
							{props => (
								<React.Fragment>
									<div className="d-flex justify-content-end align-items-center mb-2">
										<div className="d-flex ml-auto">
											<CustomSearch onSearch={this.onSearch} />
											<PeriodEndSort className="ml-2" />
											<PeriodEndFilter key={clearview.GenerateKey()} className="ml-2" periodEnds={this.props.periodEnds} />

											<ButtonGroup className="ml-2">
												<CustomExportCSV {...props.csvProps}>Export</CustomExportCSV>
											</ButtonGroup>
										</div>
									</div>
									<BootstrapTable classes="hidden" {...props.baseProps} />
								</React.Fragment>
							)}
						</ToolkitProvider>

						<Card className="mb-3">
							<div className="table-responsive-xl">
								<ToolkitProvider
									keyField="reference"
									data={context.sortedAndFiltered(periodEnds)}
									columns={[
										{
											dataField: "property.parent.reference",
											text: "Client,Property",
											formatter: (col, row) => {
												return (
													<React.Fragment>
														{row.property.parent.parent && row.property.parent.parent.role === "Managing Agent" && (
															<Link
																to={`/clients/${row.property.parent.parent.reference}`}
																className="text-decoration-none small"
															>
																{clearview.Icon.Business[row.property.parent.parent.role]}{" "}
																{clearview.GetBusinessTitle(row.property.parent.parent)}
															</Link>
														)}
														<br />
														<Link to={`/clients/${row.property.parent.reference}`} className="text-decoration-none small">
															{clearview.Icon.Business[row.property.parent.role]}{" "}
															{clearview.GetBusinessTitle(row.property.parent)}
														</Link>
														<br />
														<Link to={`/clients/${row.property.reference}`} className="text-decoration-none small">
															{clearview.Icon.Business[row.property.role]} {row.property.reference}: {row.property.name}
															<br />
															{clearview.AddressBlock(row.property.address?.fullAddressExcludingPostcode)}
															{row.property.address?.postcode && (
																<React.Fragment>
																	<br />
																	{row.property.address?.postcode}
																</React.Fragment>
															)}
														</Link>
														<br />
														<b className="nowrap">In House Contact:</b>{" "}
														{row.property.actors.inHouseContact && (
															<span className="text-inverse">
																<Avatar.Font
																	user={row.property.actors.inHouseContact}
																	size="sm"
																	className="avatar-with-text mr-1"
																></Avatar.Font>
																{row.property.actors.inHouseContact.name}
															</span>
														)}
														<br />
														<b className="nowrap">Client Contact:</b>{" "}
														{row.property.actors.clientContact && (
															<span className="text-inverse">
																<Avatar.Font
																	user={row.property.actors.clientContact}
																	size="sm"
																	className="avatar-with-text mr-1"
																></Avatar.Font>
																{row.property.actors.clientContact.name}
															</span>
														)}
													</React.Fragment>
												)
											},
										},
										{
											dataField: "currentStage.templateStage.name",
											text: "Period End, Stage",
											formatter: (col, row) => {
												const lastLog = row.currentStage.comments.reduce((acc, it) => {
													if (["Query", "ClosedQuery"].includes(it.type)) {
														return it.replies.reduce((acc, r) => {
															if (r.createdAt > acc.createdAt) return r
															else return acc
														}, it)
													} else if (it.createdAt > acc.createdAt) {
														return it
													} else {
														return acc
													}
												}, _.first(row.currentStage.comments))

												return (
													<React.Fragment>
														<div className="d-flex">
															<Link title="Period End" to={`/periodend/${row.reference}`} className="text-decoration-none flex-1">
																<span>
																	<PeriodEndUnread periodEnd={row} />
																	{row.reference}
																	<sup>
																		<PeriodEndFavourite periodEnd={row} />
																	</sup>
																</span>{" "}
																{row.template.name}
															</Link>
															<div style={{ width: "250px" }}>
																<Link to={`/periodend/${row.reference}`} className="text-decoration-none highlight">
																	{row.currentStage && <PeriodEndProgressBar user={user} periodEnd={row} hasLabels={false} />}
																</Link>
															</div>
														</div>
														<br />
														<div className="d-flex">
															<Link title="Open Stage" to={`/periodend/${row.reference}`} className="text-decoration-none flex-1">
																S{row.currentStage.templateStage.sequence}: {row.currentStage.templateStage.name}
															</Link>
															<Link
																title="Comments & Queries"
																to={`/periodend/${row.reference}`}
																className="text-decoration-none nowrap ml-4"
															>
																<PeriodEndBadges periodEnd={row} />
															</Link>
															<Link to={`/periodend/${row.reference}`} className="text-decoration-none ml2">
																{row.currentStage.templateStage.isInHouse ? clearview.Icon.isInHouse : null}
															</Link>
															<Link
																title="On Day of Planned Stage Days"
																to={`/periodend/${row.reference}`}
																className="text-decoration-none ml-4"
															>
																{row.currentStage.durationDays !== null
																	? `${row.currentStage.durationDays}/${row.currentStage.templateStage.plannedDays}`
																	: `${row.currentStage.templateStage.plannedDays}`}
															</Link>
															<Link title="Stage Status" to={`/periodend/${row.reference}`} className="text-decoration-none ml-4">
																{clearview.StageStatusBadge(row.currentStage)}
															</Link>
															<Link title="Stage Rag" to={`/periodend/${row.reference}`} className="text-decoration-none ml-4">
																{clearview.StageRagBadge(
																	row.currentStage,
																	row.currentStage.status !== "NotYetOpen"
																		? moment(row.currentStage.closedAt || row.currentStage.dueDate).format("DD/MM/YYYY")
																		: null
																)}
															</Link>
														</div>
														<br />
														{lastLog && ["Query", "Reply", "Closer"].includes(lastLog.type) ? (
															<Comment periodEnd={row} stage={row.currentStage} comment={lastLog} />
														) : lastLog ? (
															<CommentTerse comment={lastLog} />
														) : null}
														{row.currentStage.conditions && row.currentStage.conditions.length ? (
															<Conditions periodEnd={row} stage={row.currentStage} />
														) : null}
														<div className="mt-0 mb-2 text-right">
															<StageActionsLinks key="sal" periodEnd={row} stage={row.currentStage} />
														</div>
													</React.Fragment>
												)
											},
										},
									]}
								>
									{props => (
										<React.Fragment>
											<BootstrapTable
												striped
												pagination={paginationFactory({
													sizePerPage: 20,
													sizePerPageList: [
														{ text: "10 row per page", value: 10 },
														{ text: "20 row per page", value: 20 },
														{ text: "50 row per page", value: 50 },
														{ text: "100 row per page", value: 100 },
													],
												})}
												{...props.baseProps}
											/>
										</React.Fragment>
									)}
								</ToolkitProvider>
							</div>
						</Card>
					</React.Fragment>
				)}
			</Consumer>
		)
	}
}

export { OpenStagesTable }
