import React, { useState } from "react"
import { connect } from "react-redux"
import { WP_ACTIONS } from "./store"

import * as clearview from "../../../../components/@Clearview"

import { AddUpdateScheduleCodeTotal } from "./components"
import { SimpleTable } from "../../../../components"

function IncomeAllocationsTable({ isReadOnly, incomeAllocations, onDelete, onSave }) {
	const [rowEditor, setRowEditor] = useState(false)

	const onAdd = isReadOnly
		? false
		: defaultValue => {
				setRowEditor({ title: "Add New Income Allocation", value: { schedule: defaultValue.schedule, code: undefined, total: 0 } })
		  }

	const onEdit = isReadOnly
		? false
		: row => {
				setRowEditor({ title: "Edit Income Allocation", value: { ...row } })
		  }

	if (isReadOnly) onDelete = false

	const onCancel = e => {
		setRowEditor(false)
	}

	const columns = {
		schedule: { map: clearview.tidy.string },
		code: { map: clearview.tidy.string },
		account: {
			map: (col, row) => clearview.tidy.string(row.nominalCode?.name),
			className: "nowrap",
		},
		total: { map: clearview.tidy.currency, className: "currency" },
	}

	return (
		<React.Fragment>
			{incomeAllocations && (
				<div className="mt-2">
					<SimpleTable
						className="auto-width"
						columns={columns}
						data={incomeAllocations}
						summaryColumns={["total"]}
						onAdd={onAdd}
						onDelete={onDelete}
						onEdit={onEdit}
					/>
				</div>
			)}
			<AddUpdateScheduleCodeTotal
				name="rowEditor"
				config={{ title: rowEditor?.title }}
				isOpen={!!rowEditor}
				onCancel={onCancel}
				onSave={row => {
					onSave(row)
					onCancel()
				}}
				value={rowEditor?.value}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		incomeAllocations: state.workingPapersReducer.workingPapers.incomeAllocations,
	}
}

const mapDispatchToProps = dispatch => ({
	onDelete: row =>
		dispatch({
			type: WP_ACTIONS.DELETE_ROW,
			page: "incomeAllocations",
			row,
		}),

	onSave: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_ROW,
			page: "incomeAllocations",
			row,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(IncomeAllocationsTable)
