import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Typeahead } from "react-bootstrap-typeahead"
import _ from "lodash"
import Dropdown from "react-bootstrap/Dropdown"

import { Avatar, FormGroup, Input, Button, CustomInput, InputGroup, InputGroupAddon, ExtendedDropdown } from "../../../../components"

import Toggle from "react-toggle"

import * as clearview from "../../../../components/@Clearview"

class QueriesFilter extends React.Component {
	static propTypes = {
		queries: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
		onFilterChange: PropTypes.func.isRequired,
		filter: PropTypes.object,
		message: PropTypes.string,
		className: PropTypes.string,
	}

	constructor(props) {
		super(props)

		this.props = props
		this.state = {}

		this.handleChange = this.handleChange.bind(this)
		this.handleRadioChange = this.handleRadioChange.bind(this)
		this.handleToggleChange = this.handleToggleChange.bind(this)
		this.filterCount = this.criteriaCount.bind(this)
		this.clearFilters = this.clearFilters.bind(this)

		this.preFilter = props.filter && Object.keys(props.filter).length > 0 ? props.filter : null
	}

	periodEndStatuses = Object.keys(clearview.PeriodEndStatusNames).map(key => ({ value: key, _title: clearview.PeriodEndStatusNames[key] }))
	periodEndRags = Object.keys(clearview.PeriodEndRagNames).map(key => ({ value: key, _title: clearview.PeriodEndRagNames[key] }))
	stageStatuses = Object.keys(clearview.StageStatusNames).map(key => ({ value: key, _title: clearview.StageStatusNames[key] }))
	stageRags = Object.keys(clearview.StageRagNames).map(key => ({ value: key, _title: clearview.StageRagNames[key] }))

	predicate = query => {
		if (
			(this.state.isAlert === undefined ||
				this.state.isAlert === "undefined" ||
				(this.state.isAlert === "true" && query.stage.periodEnd.isAlert) ||
				(this.state.isAlert === "false" && query.stage.periodEnd.isFavourite)) &&
			(this.state.currentOrFuture === undefined ||
				this.state.currentOrFuture === "undefined" ||
				(this.state.currentOrFuture === "current" && query.isCurrent) ||
				(this.state.currentOrFuture === "future" && !query.isCurrent)) &&
			(this.state.type === undefined || this.state.type === "undefined" || query.type === this.state.type) &&
			(!this.state.actor ||
				query.createdBy.name === this.state.actor.name ||
				query.assignedTo.name === this.state.actor.name ||
				_.find(query.replies, r => r.createdBy.name === this.state.actor.name)) &&
			(this.state.with === undefined ||
				this.state.with === "undefined" ||
				(this.state.with === "Us" && query.isWithUs === true) ||
				(this.state.with === "Them" && query.isWithUs === false)) &&
			(this.state.withWhom === undefined ||
				this.state.withWhom === "undefined" ||
				([query.createdBy.name, query.assignedTo.name].includes(this.props.user.name) &&
					((this.state.withWhom === "Me" && query.isWithUs) || (this.state.withWhom === "Them" && !query.isWithUs)))) &&
			(this.state.hasReplies === undefined ||
				this.state.hasReplies === "undefined" ||
				(this.state.hasReplies === "yes" && query.replies.length > 0) ||
				(this.state.hasReplies === "no" && query.replies.length === 0)) &&
			(!this.state.client ||
				(query.stage.periodEnd.property.lft >= this.state.client.lft && query.stage.periodEnd.property.lft <= this.state.client.rgt)) &&
			(!this.state.inHouseTeamMember || _.find(query.stage.periodEnd.property.actors.inHouse, a => a.id === this.state.inHouseTeamMember.id)) &&
			(!this.state.clientTeamMember || _.find(query.stage.periodEnd.property.actors.client, a => a.id === this.state.clientTeamMember.id)) &&
			(!this.state.property || query.stage.periodEnd.property.id === this.state.property.id) &&
			(!this.state.template || query.stage.periodEnd.template.id === this.state.template.id) &&
			(!this.state.phaseName || (query.stage.templateStage.phase && query.stage.templateStage.phase.name === this.state.phaseName.value)) &&
			(!this.state.stageName || query.stage.templateStage.name === this.state.stageName.value) &&
			(!this.state.periodEndStatus || query.stage.periodEnd.status === this.state.periodEndStatus.value) &&
			(!this.state.periodEndRag || query.stage.periodEnd.rag === this.state.periodEndRag.value) &&
			(!this.state.stageStatus || query.stage.status === this.state.stageStatus.value) &&
			(!this.state.stageRag || query.stage.rag === this.state.stageRag.value)
		)
			return true

		return false
	}

	handleChange(option, type) {
		this.setState(
			{
				[type]: _.first(option),
			},
			() => this.props.onFilterChange(this.predicate.bind(this))
		)
	}

	handleRadioChange(event, type) {
		this.setState(
			{
				[type]: event.target.value,
			},
			() => this.props.onFilterChange(this.predicate.bind(this))
		)
	}

	handleToggleChange(event) {
		this.setState(
			{
				[event.target.name]: event.target.checked ? { name: event.target.value, _title: event.target.value } : undefined,
			},
			() => this.props.onFilterChange(this.predicate.bind(this))
		)
	}

	criteriaCount() {
		return Object.keys(this.state).reduce((acc, key) => (this.state[key] ? acc + 1 : acc), 0)
	}

	clearFilters(event, type) {
		this.setState(
			{
				type: "Query",
				currentOrFuture: undefined,

				actor: undefined,
				with: undefined,
				withWhom: undefined, // False means my query with them.
				hasReplies: undefined,

				client: undefined,
				property: undefined,
				template: undefined,
				phaseName: undefined,
				stageName: undefined,
				periodEndStatus: undefined,
				periodEndRag: undefined,
				stageStatus: undefined,
				stageRag: undefined,

				inHouseTeamMember: undefined,
				clientTeamMember: undefined,
			},
			() => this.props.onFilterChange(this.predicate.bind(this))
		)
	}

	render() {
		const { queries, user } = this.props

		const actors = _.sortBy(
			_.uniqBy(
				queries
					.map(it => it.createdBy.name)
					.concat(queries.map(it => it.assignedTo.name))
					.concat(queries.flatMap(it => it.replies).map(it => it.createdBy.name))
					.filter(it => it),
				it => it
			).map(it => ({ name: it, _title: it })),
			it => it._title
		)

		const clients = _.sortBy(
			_.uniqBy(
				queries
					.map(it => it.stage.periodEnd.property.parent)
					.concat(queries.map(it => it.stage.periodEnd.property.parent.parent))
					.filter(it => it),
				it => it.id
			).map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
			it => it._title
		)
		const properties = _.sortBy(
			_.uniqBy(
				queries.map(it => it.stage.periodEnd.property),
				it => it.id
			).map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
			it => it._title
		)
		const templates = _.sortBy(
			_.uniqBy(
				queries.map(it => it.stage.periodEnd.template),
				it => it.id
			).map(it => ({ ...it, _title: `${it.reference}: ${it.name}` })),
			it => it.reference
		)
		const phaseNames = _.sortBy(
			_.uniqBy(
				queries
					.filter(it => it.stage.templateStage.phase)
					.map(it => ({ value: it.stage.templateStage.phase.name, _title: it.stage.templateStage.phase.name })),
				it => it.value
			),
			it => it._title
		)
		const stageNames = _.sortBy(
			_.uniqBy(
				queries.map(it => ({ value: it.stage.templateStage.name, _title: it.stage.templateStage.name })),
				it => it.value
			),
			it => it._title
		)
		const inHouseTeamMembers = _.sortBy(
			_.uniqBy(
				_.flatMap(queries, it => it.stage.periodEnd.property.actors.inHouse),
				"username"
			),
			it => it.Name
		)
		const clientTeamMembers = _.sortBy(
			_.uniqBy(
				_.flatMap(queries, it => it.stage.periodEnd.property.actors.client),
				"username"
			),
			it => it.Name
		)

		if (this.preFilter) {
			this.state = {}

			Object.keys(this.preFilter).forEach(key => {
				switch (key) {
					case "type":
						this.state[key] = this.preFilter[key]
						break
					case "currentOrFuture":
						this.state[key] = this.preFilter[key]
						break

					case "actor":
						this.state[key] = _.find(actors, it => it.name === this.preFilter[key])
						break
					case "with":
						this.state[key] = this.preFilter[key]
						break
					case "withWhom":
						this.state[key] = this.preFilter[key]
						break
					case "isAlert":
						this.state[key] = this.preFilter[key]
						break
					case "hasReplies":
						this.state[key] = this.preFilter[key]
						break

					case "client":
						this.state[key] = _.find(clients, it => it.reference === this.preFilter[key])
						break
					case "property":
						this.state[key] = _.find(properties, it => it.reference === this.preFilter[key])
						break
					case "template":
						this.state[key] = _.find(templates, it => it.reference === this.preFilter[key])
						break
					case "phaseName":
						this.state[key] = _.find(phaseNames, it => it.value === this.preFilter[key])
						break
					case "stageName":
						this.state[key] = _.find(stageNames, it => it.value === this.preFilter[key])
						break

					case "periodEndStatus":
						this.state[key] = _.find(this.periodEndStatuses, it => it.value === this.preFilter[key])
						break
					case "periodEndRag":
						this.state[key] = _.find(this.periodEndRags, it => it.value === this.preFilter[key])
						break
					case "stageStatus":
						this.state[key] = _.find(this.stageStatuses, it => it.value === this.preFilter[key])
						break
					case "stageRag":
						this.state[key] = _.find(this.stageRags, it => it.value === this.preFilter[key])
						break

					case "inHouseTeamMember":
						this.state[key] = _.find(inHouseTeamMembers, it => it.username === this.preFilter[key])
						break
					case "clientTeamMember":
						this.state[key] = _.find(clientTeamMembers, it => it.username === this.preFilter[key])
						break

					default:
						break
				}
			})

			this.preFilter = null
			this.props.onFilterChange(this.predicate.bind(this))
		}

		return (
			<InputGroup className={this.props.className} size="sm">
				<InputGroupAddon addonType="prepend">
					<Toggle
						name="actor"
						value={user.name}
						className="mt-1 mr-2"
						defaultChecked={this.state.actor && this.state.actor.name === user.name}
						checked={this.state.actor && this.state.actor.name === user.name}
						icons={{
							checked: <Avatar.Font size="sm" bgColor="secondary" style={{ top: -5, left: -3 }} title="My queries" user={user} />,
							unchecked: (
								<Avatar.Font size="sm" bgColor="secondary" style={{ top: -5, left: -3 }} title="Anyone's queries">
									{"?"}
								</Avatar.Font>
							),
						}}
						onChange={e => this.handleToggleChange(e, "me")}
					/>

					<Dropdown className="align-self-center ml-auto" direction="left">
						<Dropdown.Toggle
							color="link"
							title="Queries Filter..."
							outline
							caret
							variant="none"
							className="btn-outline-secondary"
							style={{ paddingTop: 2, paddingBottom: 2 }}
						>
							{this.criteriaCount() === 0 && clearview.Icon.filter}
							{this.criteriaCount() !== 0 && <span className="text-primary">{clearview.Icon.filter}</span>}
						</Dropdown.Toggle>
						<ExtendedDropdown>
							<ExtendedDropdown.Section className="d-flex justify-content-between align-items-center">
								<FormGroup row>
									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.CurrentOrFuture[null]}</InputGroupAddon>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="CurrentOrFuture2"
											name="CurrentOrFuture"
											label="Current"
											inline
											value="current"
											defaultChecked={this.state.currentOrFuture === "current"}
											onChange={e => this.handleRadioChange(e, "currentOrFuture")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="CurrentOrFuture1"
											name="CurrentOrFuture"
											label="Future"
											inline
											value="future"
											defaultChecked={this.state.currentOrFuture === "future"}
											onChange={e => this.handleRadioChange(e, "currentOrFuture")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="CurrentOrFuture0"
											name="CurrentOrFuture"
											label="n/a"
											inline
											value="undefined"
											defaultChecked={!["current", "future"].includes(this.state.currentOrFuture)}
											onChange={e => this.handleRadioChange(e, "currentOrFuture")}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.Alert[true]}</InputGroupAddon>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="IsAlert2"
											name="IsAlert"
											label="Alert"
											inline
											value="true"
											defaultChecked={this.state.isAlert === "true"}
											onChange={e => this.handleRadioChange(e, "isAlert")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="IsAlert1"
											name="IsAlert"
											label="Priority"
											inline
											value="false"
											defaultChecked={this.state.isAlert === "false"}
											onChange={e => this.handleRadioChange(e, "isAlert")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="IsAlert0"
											name="IsAlert"
											label="n/a"
											inline
											value="undefined"
											defaultChecked={!["true", "false"].includes(this.state.isAlert)}
											onChange={e => this.handleRadioChange(e, "isAlert")}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.queryOpenOrClosed}</InputGroupAddon>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="Type3"
											name="type"
											label="Draft"
											inline
											value="DraftQuery"
											defaultChecked={this.state.type === "DraftQuery"}
											onChange={e => this.handleRadioChange(e, "type")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="Type2"
											name="type"
											label="Open"
											inline
											value="Query"
											defaultChecked={this.state.type === "Query"}
											onChange={e => this.handleRadioChange(e, "type")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="Type1"
											name="type"
											label="Closed"
											inline
											value="ClosedQuery"
											defaultChecked={this.state.type === "ClosedQuery"}
											onChange={e => this.handleRadioChange(e, "type")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="Type0"
											name="type"
											label="n/a"
											inline
											value="undefined"
											defaultChecked={!["DraftQuery", "Query", "ClosedQuery"].includes(this.state.type)}
											onChange={e => this.handleRadioChange(e, "type")}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.queryInHouseOrClient}</InputGroupAddon>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="with2"
											name="with"
											label="With Us"
											inline
											value="Us"
											defaultChecked={this.state.with === "Us"}
											onChange={e => this.handleRadioChange(e, "with")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="with1"
											name="with"
											label={`With ${this.props.user.isInHouse ? "Client" : clearview.ShortName(this.props.user?.brand, 7)}`}
											inline
											value="Them"
											defaultChecked={this.state.with === "Them"}
											onChange={e => this.handleRadioChange(e, "with")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="with0"
											name="with"
											label="n/a"
											inline
											value="undefined"
											defaultChecked={!["Us", "Them"].includes(this.state.with)}
											onChange={e => this.handleRadioChange(e, "with")}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.me}</InputGroupAddon>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="withWhom2"
											name="withWhom"
											label="With Me"
											inline
											value="Me"
											defaultChecked={this.state.withWhom === "Me"}
											onChange={e => this.handleRadioChange(e, "withWhom")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="withWhom1"
											name="withWhom"
											label={`With ${this.props.user.isInHouse ? "Client" : clearview.ShortName(this.props.user?.brand, 7)}`}
											inline
											value="Them"
											defaultChecked={this.state.withWhom === "Them"}
											onChange={e => this.handleRadioChange(e, "withWhom")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="withWhom0"
											name="withWhom"
											label="n/a"
											inline
											value="undefined"
											defaultChecked={!["Me", "Them"].includes(this.state.withWhom)}
											onChange={e => this.handleRadioChange(e, "withWhom")}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.reply}</InputGroupAddon>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="HasReplies2"
											name="HasReplies"
											label="Replies"
											inline
											value="yes"
											defaultChecked={this.state.hasReplies === "yes"}
											onChange={e => this.handleRadioChange(e, "hasReplies")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="HasReplies1"
											name="HasReplies"
											label="No Replies"
											inline
											value="no"
											defaultChecked={this.state.hasReplies === "no"}
											onChange={e => this.handleRadioChange(e, "hasReplies")}
										/>
										<CustomInput
											className="ml-2 mt-2 mb-2"
											type="radio"
											id="HasReplies0"
											name="HasReplies"
											label="n/a"
											inline
											value="undefined"
											defaultChecked={!["yes", "no"].includes(this.state.hasReplies)}
											onChange={e => this.handleRadioChange(e, "hasReplies")}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.Business.User}</InputGroupAddon>
										<Typeahead
											id="Actor"
											labelKey="_title"
											options={actors}
											placeholder="Filter by user..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.actor}
											onChange={e => this.handleChange(e, "actor")}
											defaultSelected={this.state.actor ? [this.state.actor] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.Business.Client}</InputGroupAddon>
										<Typeahead
											id="Client"
											labelKey="_title"
											options={clients}
											placeholder="Filter by client/managing agent/landlord..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.client}
											onChange={e => this.handleChange(e, "client")}
											defaultSelected={this.state.client ? [this.state.client] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.Business.InHouseUser}</InputGroupAddon>
										<Typeahead
											id="InHouseTeamMember"
											labelKey="name"
											options={inHouseTeamMembers}
											placeholder="Filter by In-House team member..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.inHouseTeamMember}
											onChange={e => this.handleChange(e, "inHouseTeamMember")}
											defaultSelected={this.state.inHouseTeamMember ? [this.state.inHouseTeamMember] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.Business.ClientUser}</InputGroupAddon>
										<Typeahead
											id="ClientTeamMember"
											labelKey="name"
											options={clientTeamMembers}
											placeholder="Filter by Client team member..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.clientTeamMember}
											onChange={e => this.handleChange(e, "clientTeamMember")}
											defaultSelected={this.state.clientTeamMember ? [this.state.clientTeamMember] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.Business.Property}</InputGroupAddon>
										<Typeahead
											id="Property"
											labelKey="_title"
											options={properties}
											placeholder="Filter by property..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.property}
											onChange={e => this.handleChange(e, "property")}
											defaultSelected={this.state.property ? [this.state.property] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.template}</InputGroupAddon>
										<Typeahead
											id="Template"
											labelKey="_title"
											options={templates}
											placeholder="Filter by template..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.template}
											onChange={e => this.handleChange(e, "template")}
											defaultSelected={this.state.template ? [this.state.template] : []}
										/>
									</InputGroup>

									{phaseNames.length > 0 && (
										<InputGroup>
											<InputGroupAddon addonType="prepend">{clearview.Icon.phase}</InputGroupAddon>
											<Typeahead
												id="PhaseName"
												labelKey="_title"
												options={phaseNames}
												placeholder="Filter by phase..."
												allowNew={false}
												caseSensitive={false}
												clearButton={true}
												highlightOnlyResult={true}
												value={this.state.phaseName}
												onChange={e => this.handleChange(e, "phaseName")}
												defaultSelected={this.state.phaseName ? [this.state.phaseName] : []}
											/>
										</InputGroup>
									)}

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.stage}</InputGroupAddon>
										<Typeahead
											id="StageName"
											labelKey="_title"
											options={stageNames}
											placeholder="Filter by stage..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.stageName}
											onChange={e => this.handleChange(e, "stageName")}
											defaultSelected={this.state.stageName ? [this.state.stageName] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndStatus}</InputGroupAddon>
										<Typeahead
											id="PeriodEndStatus"
											labelKey="_title"
											options={this.periodEndStatuses}
											placeholder="Filter by period end status..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.periodEndStatus}
											onChange={e => this.handleChange(e, "periodEndStatus")}
											defaultSelected={this.state.periodEndStatus ? [this.state.periodEndStatus] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndRag}</InputGroupAddon>
										<Typeahead
											id="PeriodEndRag"
											labelKey="_title"
											options={this.periodEndRags}
											placeholder="Filter by period end RAG..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.periodEndRag}
											onChange={e => this.handleChange(e, "periodEndRag")}
											defaultSelected={this.state.periodEndRag ? [this.state.periodEndRag] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.stageStatus}</InputGroupAddon>
										<Typeahead
											id="StageStatus"
											labelKey="_title"
											options={this.stageStatuses}
											placeholder="Filter by stage status..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.stageStatus}
											onChange={e => this.handleChange(e, "stageStatus")}
											defaultSelected={this.state.stageStatus ? [this.state.stageStatus] : []}
										/>
									</InputGroup>

									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.stageRag}</InputGroupAddon>
										<Typeahead
											id="stageRag"
											labelKey="_title"
											options={this.stageRags}
											placeholder="Filter by stage RAG..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.stageRag}
											onChange={e => this.handleChange(e, "stageRag")}
											defaultSelected={this.state.stageRag ? [this.state.stageRag] : []}
										/>
									</InputGroup>
								</FormGroup>
							</ExtendedDropdown.Section>
						</ExtendedDropdown>
					</Dropdown>
				</InputGroupAddon>
				<Input disabled hidden={!this.props.message} value={this.props.message} className="bg-white" />
				{this.criteriaCount() !== 0 && (
					<InputGroupAddon addonType="append" title="Clear filters">
						<Button outline onClick={() => this.clearFilters()}>
							<i className="fa fa-fw fa-times"></i>
						</Button>
					</InputGroupAddon>
				)}
			</InputGroup>
		)
	}
}

const mapStateToProps = state => {
	const { userReducer } = state

	return {
		user: userReducer.user || {},
	}
}

QueriesFilter = connect(mapStateToProps)(QueriesFilter)
export { QueriesFilter }
