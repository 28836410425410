import React from "react"
import { connect, useDispatch } from "react-redux"

import { AppLayout } from "../layout"
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Outlet } from "react-router-dom"

import { Consumer } from "../layout/context"

// ----------- Pages Imports ---------------
import OverviewDashboard from "./Dashboards/Overview"
import PeriodEndsDashboard from "./Dashboards/PeriodEnds"
import PeriodEndDetails from "./Dashboards/PeriodEnds/PeriodEndDetails"
import VouchingDetails from "./Dashboards/PeriodEnds/VouchingDetails"
import WorkingPapers from "./Dashboards/PeriodEnds/WorkingPapers"
import StagesDashboard from "./Dashboards/Stages"
import ClientsDashboard from "./Dashboards/Clients"
import { ClientDetails } from "./Dashboards/Clients/components/ClientDetails"
import PropertiesDashboard from "./Dashboards/Properties"
import SubjectsDashboard from "./Dashboards/Properties/SubjectsDashboard"
import TemplatesDashboard from "./Dashboards/Templates"
import TemplateDetails from "./Dashboards/Templates/TemplateDetails"
import QueriesDashboard from "./Dashboards/Queries"
import ReportsDashboard from "./Dashboards/Reports"
import DownloadsDashboard from "./Dashboards/Downloads"
import SupportDashboard from "./Dashboards/Support"

import Workstream from "./Dashboards/Support/Workstreams/Workstream"

import Error404 from "./Pages/Error404"

// ----------- Auth & API ---------------
import TextractAnalysisRules from "./Dashboards/Clients/Preferences/TextractAnalysisRules"
import VendorAliases from "./Dashboards/Clients/Preferences/VendorAliases"
import FeeMatrixEditorPage from "./Dashboards/Clients/FeeMatrix/FeeMatrixEditorPage"

import * as ACTIONS from "../store/actions"

function RoutedContent(state) {
	const basename = process.env.BASE_PATH || "/"
	const dispatch = useDispatch()

	const periodEndIdLoader = params => {
		const periodEndsArray = ACTIONS.dictionaryToArray(ACTIONS.getSubState(state, "clientsReducer\\periodEnds").dict)
		const periodEnd = _.find(periodEndsArray, it => it.id === parseInt(params.periodEndId))

		if (periodEnd && periodEnd.$type !== "periodEnd" && !periodEnd.isBusy) {
			dispatch({ type: ACTIONS.PERIODEND_FETCH, payload: { periodEnd: periodEnd } })
			return { ...periodEnd, isBusy: true }
		}

		return periodEnd || { isBusy: true }
	}

	const periodEndLoader = params => {
		const propertyRef = params.propertyRef || params.propertyRefA + "/" + params.propertyRefB
		const ref = `${propertyRef}/${params.templateRef}/${params.year}`
		const periodEnd = ACTIONS.getSubState(state, "clientsReducer\\periodEnds").dict[ref]

		if (periodEnd && periodEnd.$type !== "periodEnd" && !periodEnd.isBusy) {
			dispatch({ type: ACTIONS.PERIODEND_FETCH, payload: { periodEnd: periodEnd } })
			return { ...periodEnd, isBusy: true }
		}

		return periodEnd || { isBusy: true }
	}

	const clientLoader = params => {
		const ref = params.clientRef || params.clientRefA + "/" + params.clientRefB
		const rootNode = ACTIONS.getSubState(state, "clientsReducer\\clients").data[0]

		return ACTIONS.findInBranch(
			rootNode,
			s => s?.reference === ref,
			s => s?.children
		)
	}

	const createRouter = context => {
		const router = createBrowserRouter(
			createRoutesFromElements(
				<Route element={<AppLayout />}>
					<Route path="/" element={<OverviewDashboard preFilterContext={context} />} />
					<Route path="/overview" element={<OverviewDashboard preFilterContext={context} />} />
					<Route path="/unread" element={<PeriodEndsDashboard preFilterContext={context} />} />
					<Route path="/periodends" element={<PeriodEndsDashboard preFilterContext={context} />} />
					<Route path="/periodend" element={<Outlet />}>
						<Route
							path="/periodend/:propertyRef/:templateRef/:year"
							element={<PeriodEndDetails />}
							loader={async ({ params }) => periodEndLoader(params)}
						/>
						<Route
							path="/periodend/:propertyRefA/:propertyRefB/:templateRef/:year"
							element={<PeriodEndDetails />}
							loader={async ({ params }) => periodEndLoader(params)}
						/>
						<Route path="/periodend/:periodEndId" element={<PeriodEndDetails />} loader={async ({ params }) => periodEndIdLoader(params)} />
					</Route>
					<Route path="/workflows" element={<PeriodEndsDashboard preFilterContext={context} />} />
					<Route path="/workflow" element={<Outlet />}>
						<Route
							path="/workflow/:propertyRef/:templateRef/:year"
							element={<PeriodEndDetails />}
							loader={async ({ params }) => periodEndLoader(params)}
						/>
						<Route
							path="/workflow/:propertyRefA/:propertyRefB/:templateRef/:year"
							element={<PeriodEndDetails />}
							loader={async ({ params }) => periodEndLoader(params)}
						/>
						<Route path="/workflow/:periodEndId" element={<PeriodEndDetails />} loader={async ({ params }) => periodEndIdLoader(params)} />
					</Route>
					<Route path="/vouching" element={<Outlet />}>
						<Route
							path="/vouching/:propertyRef/:templateRef/:year"
							element={<VouchingDetails />}
							loader={async ({ params }) => periodEndLoader(params)}
						/>
						<Route
							path="/vouching/:propertyRefA/:propertyRefB/:templateRef/:year"
							element={<VouchingDetails />}
							loader={async ({ params }) => periodEndLoader(params)}
						/>
					</Route>
					<Route path="/working-papers" element={<Outlet />}>
						<Route
							path="/working-papers/:propertyRef/:templateRef/:year"
							element={<WorkingPapers />}
							loader={async ({ params }) => periodEndLoader(params)}
						/>
						<Route
							path="/working-papers/:propertyRefA/:propertyRefB/:templateRef/:year"
							element={<WorkingPapers />}
							loader={async ({ params }) => periodEndLoader(params)}
						/>
					</Route>
					<Route path="/stages" element={<StagesDashboard preFilterContext={context} />} />
					<Route path="/clients" element={<ClientsDashboard />}>
						<Route path="/clients/:clientRef" element={<ClientDetails />} loader={async ({ params }) => clientLoader(params)} />
						<Route path="/clients/:clientRefA/:clientRefB" element={<ClientDetails />} loader={async ({ params }) => clientLoader(params)} />
					</Route>
					<Route path="/properties" element={<PropertiesDashboard />} />
					<Route path="/property/:propertyRef+" element={<PropertiesDashboard />} />
					<Route path="/subjects" element={<SubjectsDashboard />} />
					<Route path="/subject/:propertyRef+" element={<SubjectsDashboard />} />
					<Route path="/templates" element={<TemplatesDashboard />} />
					<Route path="/template/:customerRef/:templateRef" element={<TemplateDetails />} />
					<Route path="/queries" element={<QueriesDashboard preFilterContext={context} />} />
					<Route path="/reports" element={<ReportsDashboard />} />
					<Route path="/downloads" element={<DownloadsDashboard />} />
					<Route path="/support" element={<SupportDashboard />} />
					<Route path="/workstream" element={<Outlet />}>
						<Route path="clearview" element={<Workstream guid="Oez8ZcUJ" name="Clearview Workstream" />} />
						<Route path="infrastructure" element={<Workstream guid="sPG7ZfgT" name="Infrastructure Workstream" />} />
						<Route path="roadmap" element={<Workstream guid="lGhuKSJ5" name="Road Map" />} />
					</Route>
					<Route path="/preferences" element={<AppLayout />}>
						<Route path="textract-analysis-rules/:id" element={<TextractAnalysisRules />} />
						<Route path="vendor-aliases/:id" element={<VendorAliases />} />
						<Route path="working-papers-fee-matrix/:id" element={<FeeMatrixEditorPage />} />
					</Route>
					<Route path="/pages/error-404" element={<Error404 />} />
				</Route>
			),
			{ basename: basename }
		)

		return router
	}

	return (
		<Consumer>
			{context => {
				return <RouterProvider router={createRouter(context)} />
			}}
		</Consumer>
	)
}

const mapStateToProps = state => {
	return state
}

RoutedContent = connect(mapStateToProps)(RoutedContent) // eslint-disable-line no-func-assign
export { RoutedContent }
