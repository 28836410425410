import * as ACTIONS from "../actions"

const workflowsReducer = (state, action) => {
	if (!state)
		state = {
			workflows: [],
		}

	switch (action.type) {
		case ACTIONS.WORKFLOW_SUBMITTED:
			return ACTIONS.setSubState(state, "workflows", [...state.workflows, action.workflow])

		default:
			return state
	}
}

export default workflowsReducer
