import _ from "lodash"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Provider } from "../../../layout/context"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"
import { Spinner } from "../../components/Spinner"

import { Container, Row, Col } from "../../../components"

import { HeaderMain } from "../../components/HeaderMain"

import { ClientsTree, mapToTreeNodes } from "./components/ClientsTree"
import { Outlet } from "react-router-dom"

class ClientsDashboard extends Component {
	static propTypes = {
		treeData: PropTypes.array.isRequired,
		isBusy: PropTypes.bool.isRequired,
		lastUpdated: PropTypes.number,
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	render() {
		const { clients, treeData, selectedItem, user } = this.props

		return (
			<Provider
				value={{
					getPotentialUsers: (business, team) => {
						let businesses = []
						ACTIONS.crawlBranches(clients.data, (it, parent) => {
							if (
								it.lft <= business.lft &&
								it.rgt >= business.rgt &&
								((it.type === "Customer" && team.type === "InHouseTeam") || (it.type === "Client" && team.type !== "InHouseTeam"))
							)
								businesses.push(it)
						})
						let teams = businesses.flatMap(it => it.teams).filter(it => it.type === "Users")
						return teams.flatMap(it => it.users).filter(it => !_.find(team.users || [], t => t.id === it.id))
					},
				}}
			>
				<Container className={this.props.isBusy ? "isBusy" : ""}>
					{this.props.isBusy && <Spinner key="spinner"></Spinner>}
					<HeaderMain
						title={<span className="text-primary">{clearview.Icon.client}Clients</span>}
						className="mb-4 mt-0"
						onRefresh={() => this.props.doRefresh()}
					/>
					<Row>
						<Col lg={8}>
							<ClientsTree treeData={treeData} selectedItem={selectedItem} user={user} onMove={this.props.doMoveClients} />
						</Col>
						<Col lg={4}>
							<Outlet></Outlet>
						</Col>
					</Row>
				</Container>
			</Provider>
		)
	}
}

const mapStateToProps = state => {
	const clients = ACTIONS.getSubState(state, "clientsReducer\\clients")

	let selectedItem = null
	const treeData = mapToTreeNodes(clients.data, it => {
		if (it.reference === clearview.PathPart(2)) {
			selectedItem = it
		}
	})

	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
		isBusy: clients.isBusy,
		clients: clients,
		treeData: treeData,
		selectedItem: selectedItem,
	}
}

const mapDispatchToProps = dispatch => ({
	doRefresh: () => {
		dispatch({ type: ACTIONS.CLIENTS_FETCH, payload: {} })
	},
	doMoveClients: moves => {
		dispatch({ type: ACTIONS.CLIENTS_MOVE_CLIENTS, payload: { moves: moves } })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientsDashboard)
