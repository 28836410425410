import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import { UncontrolledPopover, PopoverHeader, PopoverBody } from "./../../components"

const HelpPopup = props => {
	const id = props.id || _.uniqueId("help_")
	const icon = props.icon || <i className="fa fa-fw fa-info-circle text-info hover" title="Click for help"></i>
	return (
		<React.Fragment>
			<sup id={id}>{icon}</sup>
			<UncontrolledPopover mode="help" placement={props.placement} target={id}>
				<PopoverHeader>{props.title}</PopoverHeader>
				<PopoverBody>{props.children}</PopoverBody>
			</UncontrolledPopover>
		</React.Fragment>
	)
}
HelpPopup.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.node.isRequired,
	placement: PropTypes.string,
}
HelpPopup.defaultProps = {
	title: "Help",
	placement: "top",
}

export { HelpPopup }
