import React from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone-uploader"
import _ from "lodash"

import * as XLSX from "sheetjs-style"

import * as clearview from "../../../../components/@Clearview"

export default class ExcelUpload extends React.Component {
	static propTypes = {
		onUploaded: PropTypes.func.isRequired,
		sheetNames: PropTypes.array,
		importAll: PropTypes.bool,
	}

	constructor(props) {
		super(props)

		this.state = { file: null }
	}

	handleChangeStatus = ({ meta, file, remove }, status) => {
		if (status === "preparing") {
			this.setState({ file: file })
		}

		if (status === "done") {
			const reader = new FileReader()

			reader.onload = evt => {
				try {
					const bstr = evt.target.result
					const wb = XLSX.read(bstr, { type: "binary", cellDates: true, dateNF: "dd/mm/yyyy" })

					// Import the specified sheets ("" will indicate the first sheet), or the first sheet
					const sheetsToImport = !this.props.sheetNames?.length ? [wb.SheetNames[0]] : this.props.sheetNames.map(it => (it ? it : wb.SheetNames[0]))

					for (let worksheetName of _.uniq(sheetsToImport)) {
						const ws = wb.Sheets[worksheetName]
						if (ws) {
							const data = XLSX.utils.sheet_to_json(ws, {
								blankrows: false,
								skipHidden: true,
								rawNumbers: true,
							})

							this.props.onUploaded(file, data, worksheetName)
							if (!this.props.importAll) break
						}
					}
				} catch (ex) {
					clearview.ShowToast("error", <p>{ex.message}</p>, null, null)
				}
			}

			reader.readAsBinaryString(file)
			remove()
		}
	}

	render() {
		return (
			<React.Fragment>
				{!this.state.file && (
					<Dropzone
						onChangeStatus={this.handleChangeStatus}
						styles={{
							dropzone: { width: "100%", height: "100%" },
						}}
						inputContent="Drag file here or Click to Browse..."
						multiple={false}
						maxFiles={1}
						submitButtonDisabled={true}
					/>
				)}
				{this.state.file && (
					<p>
						Loading file <b>{this.state.file.name}</b>.
					</p>
				)}
				{this.state.file && <p>Please wait...</p>}
			</React.Fragment>
		)
	}
}
