import React from "react"
import PropTypes from "prop-types"

import _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import { Button, Media, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert, Input } from "../../../../components"

const EMPTY_STATE = { isOpen: false }

export default class PropertyNames_Form extends React.Component {
	static propTypes = {
		isOpen: PropTypes.bool.isRequired,
		count: PropTypes.number,
		property: PropTypes.object.isRequired,
		propertyNames: PropTypes.array.isRequired,
		onSave: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = EMPTY_STATE

		this.onHandleChange = this.onHandleChange.bind(this)
		this.onAddNew = this.onAddNew.bind(this)
		this.onSave = this.onSave.bind(this)
	}

	static getDerivedStateFromProps(props, state) {
		if (!props.isOpen) return EMPTY_STATE

		if (state.isOpen) return null

		return {
			isOpen: true,
			property: props.property,
			propertyNames: [...props.propertyNames],
			count: props.count,
		}
	}

	onHandleChange(e, idx) {
		this.state.propertyNames[idx] = e.target.value
		this.setState({ isChanged: true })
	}

	onAddNew(e, idx) {
		this.state.propertyNames.push(this.props.property.name)
		this.setState({ isChanged: true })
	}

	onSave() {
		const names = _.uniq(this.state.propertyNames.map(it => it?.trim()).filter(it => it)).sort(clearview.caseInsensitiveSort)
		this.props.onSave(names)
	}

	render() {
		const { isOpen, property, propertyNames, count } = this.state

		const severity = count > 0 ? "danger" : "info"

		return (
			<Modal isOpen={isOpen} style={{ minWidth: 600 }}>
				<Form>
					<ModalHeader tag="div">
						<div className="d-flex">
							<h1 className="text-primary">
								{clearview.Icon.property} {property?.name}
							</h1>
						</div>
					</ModalHeader>

					<ModalBody>
						{count > 0 && (
							<Alert color={severity}>
								<Media>
									<Media left middle className="mr-3">
										<span className="fa-stack fa-lg">
											<i className="fa fa-circle fa-fw fa-stack-2x alert-bg-icon"></i>
											{severity === "danger" && <i className="fa fa-exclamation-circle fa-stack-2x fa-inverse alert-icon"></i>}
											{severity === "info" && <i className="fa fa-info fa-stack-1x fa-inverse alert-icon"></i>}
										</span>
									</Media>
									<Media body>
										<h6 className="alert-heading mb-1">{_.startCase(severity)}</h6>
										<span>
											The property Name has not been found on <b>{count} invoices</b>.
										</span>
									</Media>
								</Media>
							</Alert>
						)}
						<p>List all the names or phrases to search for in invoices (case insensitive):</p>
						<p>
							{propertyNames?.map((it, idx) => (
								<Input
									key={`pn-${idx}`}
									type="text"
									name={`pn-${idx}`}
									id={`pn-${idx}`}
									placeholder="Property Name..."
									value={it}
									onChange={e => this.onHandleChange(e, idx)}
								/>
							))}
						</p>
						<Button key="addNew" outline={true} color="primary" title="Add new alias" onClick={e => this.onAddNew(e)}>
							{clearview.Icon.create}
						</Button>
					</ModalBody>

					<ModalFooter>
						<Button disabled={!this.state.isChanged} color="link" className="text-primary" onClick={this.onSave}>
							Save
						</Button>
						<Button color="default" className="text-primary" name="send" onClick={this.props.onCancel}>
							Cancel
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}
