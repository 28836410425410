import _ from "lodash"
import React from "react"

import { Container, Row, Col } from "../../../../components"

import { TimelineDefault } from "./Releases_TimelineDefault"
import Workstreams from "../Workstreams/Workstreams"

import * as clearview from "../../../../components/@Clearview"

const colors = {
	info: "info",
	pause: "warning",
	check: "success",
	bug: "danger",
}

export function ItemMap(date) {
	return (it, idx) => (
		<TimelineDefault
			key={idx}
			showPillDate={idx === 0}
			pillDate={date}
			iconCircle={it[0]}
			smallIconColor={colors[it[0]]}
			iconCircleColor={colors[it[0]]}
			title={it[1]}
			content={it[2]}
		/>
	)
}

export function Releases() {
	return (
		<React.Fragment>
			<Container>
				<Workstreams />
				<Row>
					<Col lg={8} className="release-timeline">
						{_.keys(Versions)
							.sort(clearview.caseInsensitiveSort)
							.reverse()
							.map(versionKey => {
								const releaseDate = clearview.FormatDate(versionKey.substring(0, 10), "ddd DD MMM YYYY")
								const hotfix = versionKey.substring(10) || ""
								return Versions[versionKey].map(ItemMap(`${releaseDate} ${hotfix}`))
							})}
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	)
}

export const Versions = {
	"2024-10-20": [
		["info", "Report Upgrade", "The Reports generation system has been upgraded. It is now faster, simpler and more reliable."],
		[
			"info",
			"Period End Forecast Report",
			"'Period End Forecast Report' now added. This report calculates which current Period Ends will be renewed or wound down in the near future. For In-house users, the fee column will contain the Period End fee (for future Period Ends, the fee will be assumed the be the same as existing Period Ends).",
		],
	],
	"2024-09-08": [
		[
			"check",
			"Bug clicking through from Service Charge Manager table",
			"Now fixed. The Service Charge Manager list for a Property was having inherited managers inadvertently added to it.",
		],
	],
	"2024-06-08": [
		[
			"info",
			"Moving Properties",
			<span>
				In-House Admins can now move properties between Clients by clicking on the new {clearview.Icon.organize} button on the Clients page then
				dragging Properties to other Landlords or Agents.
			</span>,
		],
		[
			"check",
			"Filter not editable after clicking on overview link",
			"After clicking on the 'Unread Period Ends', or other Overview page button, some filter properties cannot be edited. Now fixed.",
		],
	],
	"2024-05-23": [["check", "New stage does not expand when stage is closed.", "Now fixed. Expanded stages are reset when a stage is closed or re-opened."]],
	"2024-05-19": [
		[
			"check",
			"Error when viewing a new Property.",
			"After adding a Property in the Clients view, when clicking away then clicking back into the Property there was a fatal error. Now fixed.",
		],
		["check", "Persistent 'You have made changes' warning in Vouching, even after clicking on 'Ok'.", "Now fixed."],
		["info", "Period End order on Client page Property node.", "Period Ends are now listed with most recent first and highlight on hover."],
		["check", "Wrong date showing for Log/Comment/Query items in Period End Stage.", "Now fixed. Now shows time converted to Local time of browser."],
	],
	"2024-04-28": [
		[
			"check",
			"Links in Dormant Company Scheme not working correctly.",
			"Now fixed. This was caused by different templates having different names for the stages. Now all stage names in the template family are listed.",
		],
		[
			"check",
			"In the Period End Details view, expanded stages keep closing.",
			"Now fixed. The list of expanded stages is persisted locally and will survive a remote refresh.",
		],
		[
			"check",
			"Clear Filters not working on Overview page.",
			"Now fixed. Note that the Overview menu item and Avatar icon will take the user to the Overview page with the filter reset to 'Open Stages'. Use the Back button to keep any filters set on lower pages.",
		],
	],
	"2024-04-10 Hotfix": [
		["check", "Cannot export Vouching Report if any ledger item has no category or account. This was reported for YL-CHA-001/RSC-YL/2021.", "Now fixed."],
	],
	"2024-03-31": [
		[
			"info",
			"Working Papers improvements",
			<React.Fragment>
				<ul style={{ clear: "left" }}>
					<li>Report details are saved in the report</li>
					<li>Notes can be edited as a checklist</li>
					<li>Selected report pages are previewed as table</li>
					<li>Selected report tabs are wysiwyg</li>
				</ul>
			</React.Fragment>,
		],
		["check", "On the Support page, all 3 workstream buttons led to the same Workstream page.", "Now fixed."],
		[
			"check",
			"Broken links to Clients and Properties.",
			"Clicking on clients and properties on the Period End, Open Stages or Query Management tables led to 404 page. Now fixed.",
		],
		[
			"check",
			"Refreshing clients view shows 'reference' error.",
			"Refreshing the clients view following a new user assignment caused a 'reference' error page to be displayed. Now fixed.",
		],
		["check", "Flickering on Period End Unread badge.", "Flickering was caused by out-of-date tooltip widget. Now fixed."],
		[
			"check",
			"Period End 'Read' button does not work",
			"The Period End 'Read' button on the Period End details form does not set the Period End to unread. Now fixed",
		],
		[
			"check",
			"Clear all filters, after following 'Unread' link, disables the filter dropdown options.",
			"Pre-filter links are now correctly cleared after navigation.",
		],
	],
	"2024-02-20": [
		["check", "Cannot edit queries in future stages.", "Now fixed."],
		["check", "Cannot edit Properties with slash in the ref.", "Now fixed."],
		["check", "Cannot find Period Ends for Properties with slash in the ref.", "Now fixed."],
	],
	"2024-02-17": [
		[
			"check",
			"Query attachments in wrong Stage.",
			"Now fixed: If we raise a query in a future stage from the vouching system and add an attachment, it is not showing under the query in the stages view. It is showing in the files but under the current stage and not the selected stage.",
		],
		[
			"info",
			"Missing Invoice query attachments",
			"Attachments to Missing Invoices queries will now be stored in the Query folder and not in the Invoices folder. In-house users will need to copy the attachments to the Invoice folder if required.",
		],
	],
	"2024-01-27": [
		[
			"info",
			"File overwrite prompts.",
			"When uploading a file with the same name as an existing one, a Client users will see a 'Permission Denied' popup message. In-house users will be asked whether they wish to overwrite the existing file.",
		],
		[
			"info",
			"Working Papers Adjusted Expenditure tab.",
			"Adj. Expenditure tab has been added showing Journal items interleaved with Ledger items. Also allows editing Journal items.",
		],
	],
}
