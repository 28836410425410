import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { ThemeConsumer } from "../../../components/Theme"

import config from "../../../config"

let LogoThemed = ({ checkBackground, className, ...otherProps }) => {
	const brand = config.brand(window.location)
	return (
		<ThemeConsumer>
			{({ style, color }) => <img src={brand.logo} className={classNames("d-block", className)} alt={brand.name} title={brand.name} {...otherProps} />}
		</ThemeConsumer>
	)
}

LogoThemed.propTypes = {
	checkBackground: PropTypes.bool,
	className: PropTypes.string,
}

export { LogoThemed }
