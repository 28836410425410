import React from "react"
import { connect } from "react-redux"

import { Consumer } from "../context"
import { SidebarMenu } from "../"

import * as clearview from "../../components/@Clearview"

const onOverviewClick = context => {
	context.setPreFilter([], { periodEndStatus: "Open" }, true)
}

let SidebarMiddleNav = props => {
	if (props.user?.brand.reference == "$AML")
		return (
			<Consumer>
				{context => (
					<SidebarMenu>
						<SidebarMenu.Item icon={clearview.Icon.overview} title="Overview" to="overview" exact onClick={() => onOverviewClick(context)} />
						<SidebarMenu.Item icon={clearview.Icon.stage} title="Open Stages" to="stages" exact />
						<SidebarMenu.Item icon={clearview.Icon.workflow} title="Workflows" to="workflows" exact />
						<SidebarMenu.Item icon={clearview.Icon.client} title="Clients" to="clients" exact />
						<SidebarMenu.Item icon={clearview.Icon.subject} title="Subjects" to="subjects" exact />
						<SidebarMenu.Item icon={clearview.Icon.template} title="Templates" to="templates" exact />
						<SidebarMenu.Item icon={clearview.Icon.query} title="Query Management" to="queries?with=Us" exact={false} />
						<SidebarMenu.Item icon={clearview.Icon.reports} title="Reports" to="reports" exact />
						<SidebarMenu.Item icon={clearview.Icon.downloads} title="Downloads" to="downloads" exact />
						<SidebarMenu.Item icon={clearview.Icon.support} title="Support" to="support" exact />
					</SidebarMenu>
				)}
			</Consumer>
		)

	if (props.user?.isInHouse)
		return (
			<Consumer>
				{context => (
					<SidebarMenu>
						<SidebarMenu.Item icon={clearview.Icon.overview} title="Overview" to="overview" exact onClick={() => onOverviewClick(context)} />
						<SidebarMenu.Item icon={clearview.Icon.stage} title="Open Stages" to="stages" exact />
						<SidebarMenu.Item icon={clearview.Icon.periodEnd} title="Period Ends" to="periodends" exact />
						<SidebarMenu.Item icon={clearview.Icon.client} title="Clients" to="clients" exact />
						<SidebarMenu.Item icon={clearview.Icon.property} title="Properties" to="properties" exact />
						<SidebarMenu.Item icon={clearview.Icon.template} title="Templates" to="templates" exact />
						<SidebarMenu.Item icon={clearview.Icon.query} title="Query Management" to="queries?with=Us" exact={false} />
						<SidebarMenu.Item icon={clearview.Icon.reports} title="Reports" to="reports" exact />
						<SidebarMenu.Item icon={clearview.Icon.downloads} title="Downloads" to="downloads" exact />
						<SidebarMenu.Item icon={clearview.Icon.support} title="Support" to="support" exact />
					</SidebarMenu>
				)}
			</Consumer>
		)

	return (
		<Consumer>
			{context => (
				<SidebarMenu>
					<SidebarMenu.Item icon={clearview.Icon.overview} title="Overview" to="overview" exact onClick={() => onOverviewClick(context)} />
					<SidebarMenu.Item icon={clearview.Icon.stage} title="Open Stages" to="stages" exact />
					<SidebarMenu.Item icon={clearview.Icon.periodEnd} title="Period Ends" to="periodends" exact />
					<SidebarMenu.Item icon={clearview.Icon.client} title="Clients" to="clients" exact />
					<SidebarMenu.Item icon={clearview.Icon.property} title="Properties" to="properties" exact />
					<SidebarMenu.Item icon={clearview.Icon.query} title="Query Management" to="queries?with=Us" exact={false} />
					<SidebarMenu.Item icon={clearview.Icon.reports} title="Reports" to="reports" exact />
					<SidebarMenu.Item icon={clearview.Icon.support} title="Support" to="support" exact />
				</SidebarMenu>
			)}
		</Consumer>
	)
}

const mapStateToProps = state => {
	const { userReducer } = state
	return {
		user: userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

SidebarMiddleNav = connect(mapStateToProps)(SidebarMiddleNav)

export { SidebarMiddleNav }
