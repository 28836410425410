/* eslint default-case: 0 */
import React from "react"
import { ToastContainer } from "react-toastify"
import _ from "lodash"

import PropTypes from "prop-types"
import { Auth } from "aws-amplify"
import { Outlet } from "react-router-dom"

import { connect } from "react-redux"
import * as ACTIONS from "../store/actions"
import config from "../config"

import { ThemeProvider, Alert, Media, NavLink } from "../components"
import { Button } from "@aws-amplify/ui-react"

import { Layout } from "."

import "./../styles/bootstrap.scss"
import "./../styles/main.scss"
import "./../styles/plugins/plugins.scss"
import "./../styles/plugins/plugins.css"

import "./../styles/@clearview.scss"
import "./../styles/splashScreen.scss"
import "./../styles/Calendar.css"
import "./../styles/DateRangePicker.scss"

import { removeImpersonate } from "../api/CustomerApi"
import { DefaultSidebar } from "./components/DefaultSidebar"

//------ Route Definitions --------
// eslint-disable-next-line no-unused-vars

const favIcons = [
	{ rel: "icon", type: "image/x-icon", href: require("./../images/favicon.ico") },
	{ rel: "apple-touch-icon", sizes: "180x180", href: require("./../images/apple-touch-icon.png") },
	{ rel: "icon", type: "image/png", sizes: "32x32", href: require("./../images/favicon-32x32.png") },
	{ rel: "icon", type: "image/png", sizes: "16x16", href: require("./../images/favicon-16x16.png") },

	{ rel: "$BW", type: "image/png", href: require("./../images/$BW.png") },
	{ rel: "$Demo", type: "image/png", href: require("./../images/$Demo.png") },
]

function signOut() {
	removeImpersonate().then(_ =>
		Auth.signOut()
			.then(data => (window.location.href = "/")) /* eslint-disable-line */
			.catch(err => console.error(err))
	)
}

class AppLayout extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		customerTemplates: PropTypes.object,
		user: PropTypes.object,
		clients: PropTypes.array,
	}

	render() {
		const { user } = this.props
		const brand = config.brand(window.location) /* eslint-disable-line */

		return (
			<React.Fragment>
				<ThemeProvider initialStyle="light" initialColor="primary">
					{!user && (
						<div className="splash-screen initial-loader-wrap">
							<div className="initial-loader">
								<div className="initial-loader__row mb-3">
									<img src={brand.logo} className="d-block" alt={brand.name} title={brand.name} />
								</div>
								<div className="initial-loader__row">
									<svg
										version="1.1"
										id="loader-circle"
										xmlns="http://www.w3.org/2000/svg"
										width="30px"
										height="30px"
										viewBox="0 0 40 40"
										enable-background="new 0 0 40 40"
									>
										<g>
											<path
												fill="#e7e7e7"
												d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
											/>
											<path
												fill="#1EB7FF"
												d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0C22.32,8.481,24.301,9.057,26.013,10.047z"
											></path>
										</g>
									</svg>
								</div>
								<div className="initial-loader__row">Please Wait. Logging in...</div>
							</div>
						</div>
					)}
					{user && user.error && (
						<div className="splash-screen initial-loader-wrap">
							<div className="initial-loader">
								<div className="initial-loader__row mb-3">
									<img src={brand.logo} className="d-block" alt={brand.name} title={brand.name} />
								</div>
								<Alert color="danger">
									<Media>
										<Media left middle className="mr-2">
											<i className="fa fa-close fa-fw fa-2x alert-icon"></i>
										</Media>
										<Media body>
											<h4 className="mb-1 alert-heading">{user.error.title}</h4>
											<h6 style={{ whiteSpace: "pre-wrap", lineHeight: "1.3rem" }}>{user.error.message}</h6>
										</Media>
										<Media right top className="ml-2">
											{!!Auth.currentSession() && (
												<NavLink title="Log out" tag={Button} onClick={signOut} to="/" className="text-primary">
													<i className="fa fa-power-off"></i>
												</NavLink>
											)}
										</Media>
									</Media>
								</Alert>
							</div>
						</div>
					)}
					{user && !user.error && (
						<Layout sidebarSlim favIcons={favIcons}>
							<Layout.Sidebar>
								<DefaultSidebar />
							</Layout.Sidebar>
							<Layout.Content>
								<Outlet />
							</Layout.Content>
						</Layout>
					)}
				</ThemeProvider>
				<ToastContainer position="top-right" draggable={false} hideProgressBar={false} />
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	const customerTemplates = ACTIONS.getSubState(state, "clientsReducer\\templates")
	return {
		user: state.userReducer.user,
		clients: state.clientsReducer.clients.data,
		customerTemplates: customerTemplates.dict,
	}
}

export default connect(mapStateToProps)(AppLayout)
