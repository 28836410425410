import React from "react"
// import { hot } from 'react-hot-loader'

import { withAuthenticator, View } from "@aws-amplify/ui-react"
import { Amplify } from "aws-amplify"
import { amplifyConfig } from "./appSettings"
import "@aws-amplify/ui-react/styles.css"
import { Auth } from "aws-amplify"

import { RoutedContent } from "./routes/RoutedContent"
import { setChonkyDefaults } from "chonky"
import { ChonkyIconFA } from "chonky-icon-fontawesome"

import Toast from "./layout/Layout/Toast"
import setupWebSocket from "./websockets"

import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"

import createSagaMiddleware from "redux-saga"
import { createLogger } from "redux-logger"

import rootReducer from "./store/reducers"
import userAuthenticatedSaga from "./store/actions/userAuthenticatedSaga"
import reportsSaga from "./store/actions/reportsSaga"
import templatesSaga from "./store/actions/templatesSaga"
import templateUpdateSaga from "./store/actions/templateUpdateSaga"
import clientsSaga from "./store/actions/clientsSaga"
import periodEndsSaga from "./store/actions/periodEndsSaga"
import periodEndUpdateSaga from "./store/actions/periodEndUpdateSaga"
import periodEndCreateSaga from "./store/actions/periodEndCreateSaga"
import periodEndStartSaga from "./store/actions/periodEndStartSaga"
import fileSaga from "./store/actions/fileSaga"
import teamSaga from "./store/actions/teamSaga"
import favouritesSaga from "./store/actions/favouritesSaga"
import workflowsSaga from "./store/actions/workflowsSaga"

import { workingPapersSaga } from "./routes/Dashboards/PeriodEnds/WorkingPapers/store"
import PreFilterContext from "./routes/PreFilterContext"

import logo from "./images/$BW.svg"

Amplify.configure(amplifyConfig)

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV !== "production") {
	middlewares.push(createLogger())
}

const store = configureStore({ reducer: rootReducer, middleware: middlewares, devTools: process.env.NODE_ENV !== "production" })
sagaMiddleware.run(reportsSaga)
sagaMiddleware.run(templatesSaga)
sagaMiddleware.run(templateUpdateSaga)
sagaMiddleware.run(clientsSaga)
sagaMiddleware.run(periodEndsSaga)
sagaMiddleware.run(periodEndUpdateSaga)
sagaMiddleware.run(periodEndCreateSaga)
sagaMiddleware.run(periodEndStartSaga)
sagaMiddleware.run(fileSaga)
sagaMiddleware.run(teamSaga)
sagaMiddleware.run(favouritesSaga)
sagaMiddleware.run(workflowsSaga)
sagaMiddleware.run(workingPapersSaga)

setChonkyDefaults({ iconComponent: ChonkyIconFA })

const SUPPRESSED_ERRORS = ["If you intentionally want it to appear in the DOM as a custom attribute"]

const consoleError = console.error
console.error = function (msg) {
	if (!SUPPRESSED_ERRORS.some(entry => msg?.toString().includes(entry))) {
		consoleError.apply(console, arguments)
	}
}

const App = ({ signOut, user }) => {
	Auth.currentAuthenticatedUser().then(user => {
		setupWebSocket(store, user)
	})

	sagaMiddleware.run(userAuthenticatedSaga)

	return (
		<Provider store={store}>
			<PreFilterContext>
				<RoutedContent />
			</PreFilterContext>
			<Toast />
		</Provider>
	)
}

export default withAuthenticator(App, {
	hideSignUp: true,
	components: {
		SignIn: {
			Header() {
				return (
					<View textAlign="center" className="mt-0 mb-2">
						<img src={logo} className="d-block m-4" alt="Bennett Whitehouse" title="Bennett Whitehouse" />
					</View>
				)
			},
			Footer() {
				return (
					<View textAlign="center" className="mt-0 mb-2">
						If you experience problems logging in, please contact your Bennett&nbsp;Whitehouse administrator.
					</View>
				)
			},
		},
	},
})
