import React, { useState } from "react"

import _ from "lodash"

import { Form, UncontrolledModal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "../../../../../components"
import * as clearview from "../../../../../components/@Clearview"

export default function ChecklistEditor({ id, checklist, onSave }) {
	const [list, setList] = useState(checklist.content.map(it => ({ ...it, selected: it.selected ?? true })))

	const updateList = e => {
		setList(previousState => {
			previousState[parseInt(e.target.id)].selected = e.target.checked
			return [...previousState]
		})
	}

	return (
		<UncontrolledModal style={{ maxWidth: 700 }} target={id}>
			<Form>
				<ModalHeader tag="h6">{_.startCase(checklist.page)}</ModalHeader>

				<ModalBody>
					{list.map((it, idx) => {
						const type = Object.keys(it)[0]
						const label = clearview.TruncateHtml(it[type].toString(), 80)

						return (
							<CustomInput
								style={{ zIndex: 1 }}
								className={`mr-4 type-label-${type}`}
								type="checkbox"
								id={idx}
								key={`c_${idx}`}
								label={label}
								defaultChecked={it.selected}
								onChange={updateList}
							></CustomInput>
						)
					})}
				</ModalBody>

				<ModalFooter>
					<UncontrolledModal.Cancel color="link" className="text-primary">
						Cancel
					</UncontrolledModal.Cancel>
					<UncontrolledModal.Close color="default" className="text-primary" name="close" onClick={e => onSave(list)}>
						Save
					</UncontrolledModal.Close>
				</ModalFooter>
			</Form>
		</UncontrolledModal>
	)
}
