import React from "react"
import classNames from "classnames"
import { Input as RSCustomInput } from "reactstrap"
// import { CustomInput as RSCustomInput } from 'reactstrap';
import { Icon } from "../@Clearview/Icon"

const CustomInput = props => {
	const { className, ...otherProps } = props
	let inputClass = classNames(className, {
		"custom-control-empty": !props.label,
	})

	if (["checkbox", "radio"].includes(props.type)) {
		const isChecked = props.checked === true || props.checked === false ? props.checked : props.defaultChecked

		inputClass = classNames(inputClass, {
			"d-flex": true,
			"custom-control": true,
			"custom-radio": props.type == "radio",
			"custom-checkbox": props.type == "checkbox",
			disabled: props.disabled,
			hover: !props.disabled,
			"custom-control-inline": props.inline,
		})

		return (
			<div
				id={props.id}
				className={inputClass}
				onClick={e => !props.disabled && props.onChange({ target: { id: props.id, checked: !isChecked, name: props.name, value: props.value } })}
			>
				{props.type === "checkbox" && Icon.Checked[isChecked]}
				{props.type === "radio" && Icon.Radiod[isChecked]}
				{props.label && props.children && (
					<label>
						{props.label}
						{props.children}
					</label>
				)}
				{props.label && !props.children && <label>{props.label}</label>}
				{!props.label && props.children && <React.Fragment>{props.children}</React.Fragment>}
			</div>
		)
	}
	return <RSCustomInput className={inputClass} {...otherProps} />
}
CustomInput.propTypes = { ...RSCustomInput.propTypes }

export { CustomInput }
