import _ from "lodash"
import { API } from "../api/CustomerApi"
import * as ACTIONS from "../store/actions"

const setupWebSocket = (store, user) => {
	try {
		const webSocket = new WebSocket(`${API.ENV.WEBSOCKET_URL}?clientId=${API.CLIENT_ID}&username=${user.username}`)

		webSocket.onopen = event => {}

		webSocket.onmessage = event => {
			var command = JSON.parse(event.data)

			switch (command.$type) {
				case "Toast":
					store.dispatch({
						type: command.type,
						message: command.message,
					})
					break

				case "PeriodEnd":
					// Check we can access this property Id!
					var property = _.first(ACTIONS.filter(store.getState().clientsReducer.properties.dict, it => it.id === command.propertyId))
					if (property) {
						console.warn("WebSocket Action", event.data)
						store.dispatch({
							type: command.type,
							payload: command.payload,
						})
					} else {
						console.warn("WebSocket Ignored", event.data)
					}
					break

				default:
					break
			}
		}

		webSocket.onclose = event => {
			console.warn("Restarting websocket")
			setupWebSocket(store, user)
		}

		webSocket.onerror = event => {
			if (API.ENV.WEBSOCKET_URL) console.error("Websocket error", event)
			//Suppress Websocket errors:
			// store.dispatch({
			//   type: "ERROR",
			//   message: "An error has occurred with your connection to the Clearview Web Socket!"
			// })
		}

		return webSocket
	} catch (e) {
		console.error(e)
	}
}

export default setupWebSocket
