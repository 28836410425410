import _ from "lodash"
import React, { Component } from "react"
import PropTypes from "prop-types"

import { Media } from "../../../../components"
import { TinyDonutChart } from "../components/TinyDonutChart"
import { TinyDonutLegend } from "../components/TinyDonutLegend"
import { HelpPopup } from "../../../components/HelpPopup"

import * as clearview from "../../../../components/@Clearview"

export class LivePeriodEndsByRagWithUsDonut extends Component {
	static propTypes = {
		livePeriodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
	}

	render() {
		const { user, livePeriodEnds } = this.props

		const stagesByRagWithUs = _.groupBy(
			(livePeriodEnds || []).filter(it => it.currentStage && it.currentStage.templateStage.isInHouse === user.isInHouse),
			it => it.rag
		)
		const stagesByRagNameWithUs = Object.keys(clearview.PeriodEndRagNames)
			.reverse()
			.map(key => ({
				name: clearview.PeriodEndRagNames[key],
				value: (stagesByRagWithUs[key] || []).length,
				color: clearview.PeriodEndRagColorValues[key],
			}))

		return (
			<React.Fragment>
				<div className="hr-text hr-text-left my-2">
					<span>
						Period Ends With {clearview.Us(user).name}
						<HelpPopup id="od_hp_peu" title={`Period Ends With ${clearview.Us(user).name}`}>
							<p>
								This chart shows the <b>Period End RAG state</b> of Open Period Ends where the current Stage is owned by{" "}
								{clearview.Us(user).name}.
							</p>
						</HelpPopup>
					</span>
				</div>
				<Media>
					<Media left className="mr-3">
						<TinyDonutChart data={stagesByRagNameWithUs} navigateTo="/periodEnds/" />
					</Media>
					<Media body>
						<TinyDonutLegend data={stagesByRagNameWithUs} />
					</Media>
				</Media>
			</React.Fragment>
		)
	}
}
