import React from "react"
import { connect } from "react-redux"

import { Typeahead } from "react-bootstrap-typeahead"

function Schedule({ value, onChange, schedules }) {
	return (
		<Typeahead
			id="schedule_ta"
			className="ml-0 schedule"
			onChange={e => {}}
			onBlur={e => onChange(e.target.value)}
			options={schedules}
			placeholder="Schedule..."
			allowNew={true}
			caseSensitive={false}
			clearButton={true}
			highlightOnlyResult={false}
			selected={!!value ? [value] : []}
			size="lg"
		></Typeahead>
	)
}

const mapStateToProps = state => {
	return {
		schedules: state.workingPapersReducer.workingPapers.schedules,
	}
}

Schedule = connect(mapStateToProps)(Schedule)
export { Schedule }
