import React from "react"
import PropTypes from "prop-types"

import { Avatar, Accordion, Table } from "../../../../components"

import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"

class TeamCard extends React.Component {
	render() {
		const { team } = this.props
		team.users = _.sortBy(team.users || [], it => it.name)

		return (
			<Accordion className="mb-2" initialOpen={true}>
				<Accordion.Header className="d-flex h6">
					{team.type === "Users"
						? clearview.Icon.Business.Users
						: team.type === "InHouseTeam"
						? clearview.Icon.Business.Team
						: team.type === "ClientTeam"
						? clearview.Icon.Business.Team
						: null}
					{_.startCase(team.type)}: <b className="ml-2">{team.type === "Users" && team.type === team.name ? null : team.name}</b>
				</Accordion.Header>
				<Accordion.Body>
					<Table size="sm">
						<tbody>
							{(team.users || []).map(user => (
								<tr key={`${user.id}`}>
									<td key={`A_${user.id}`} className="d-flex align-middle hover">
										<Avatar.Font
											user={user}
											size="sm"
											bgColor="blue"
											className="avatar-with-text mr-1"
											title={`${user.name} (${user.username})`}
										>
											{user.initials || user.name.substring(0, 1) + "*"}
										</Avatar.Font>
										{user.name}
									</td>
									<td key={`B_${user.id}`} className="text-right">
										{user.role}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Accordion.Body>
			</Accordion>
		)
	}
}

export default TeamCard
