import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import moment from "moment"

import * as clearview from "../../../../components/@Clearview"
import PropTypes from "prop-types"

import { Stage } from "../../Stages/components/Stage"
import { StageBadges } from "../../Stages/components/StageBadges"

class StagesTable extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
	}

	render() {
		const { periodEnd } = this.props

		const columnDefs = createColumnDefinitions(periodEnd.template) //.filter(it => userCanEdit || it.text !== "Action");

		const rowClasses = (row, idx) => {
			return row.status === "NotYetOpen" || row.status === "Closed"
				? `table-${clearview.StageStatusColors[row.status]}`
				: `table-${clearview.StageRagColors[row.rag]}`
		}

		return (
			<ToolkitProvider keyField="id" data={periodEnd.stages} columns={columnDefs}>
				{props => (
					<React.Fragment>
						<BootstrapTable
							keyField="id"
							classes="table-responsive-lg csl-stage-table text-inverse table-striped"
							bordered={true}
							expandRow={expandRow(periodEnd)}
							rowClasses={rowClasses}
							responsive
							hover
							{...props.baseProps}
						/>
					</React.Fragment>
				)}
			</ToolkitProvider>
		)
	}
}

function createColumnDefinitions(template) {
	return [
		{
			dataField: "templateStage.sequence",
			text: template.stages.find(it => it.phase) ? "Stage" : "Stage",
			headerAlign: "center",
			align: "center",
			formatter: (cell, row) => (
				<React.Fragment>
					<span className="mr-2" hidden={!row.templateStage.phase} style={{ opacity: 0.5 }}>
						P{row.templateStage.phase?.sequence}
					</span>
					<span title={`#${row.id}`}>{cell}</span>
				</React.Fragment>
			),
		},
		{
			dataField: "templateStage.isInHouse",
			text: "In Hse",
			headerAlign: "center",
			align: "center",
			formatter: (cell, row) => clearview.Icon.InHouse[cell],
		},
		{
			dataField: "templateStage.name",
			text: "Description",
			formatter: (cell, row) => (
				<React.Fragment>
					<span>{cell}</span>
					<StageBadges stage={row} />
				</React.Fragment>
			),
		},
		{
			dataField: "durationDays",
			text: "Days",
			headerAlign: "center",
			align: "center",
			formatter: (cell, row) => {
				const title =
					row.status === "Closed"
						? `Expended ${row.durationDays} of ${row.templateStage.plannedDays} planned days`
						: row.durationDays !== null
						? `On day ${row.durationDays} of ${row.templateStage.plannedDays} planned days`
						: `${row.templateStage.plannedDays} planned days`

				return (
					<span title={title}>
						{row.durationDays !== null ? `${row.durationDays}/${row.templateStage.plannedDays}` : `${row.templateStage.plannedDays}`}
					</span>
				)
			},
		},
		{
			dataField: "startedAt",
			text: "Started",
			formatter: cell => (cell ? moment(cell).format("DD/MM/YYYY") : null),
		},
		{
			dataField: "status",
			text: "Status",
			formatter: (cell, row) => clearview.StageStatusBadge(row),
		},
		{
			dataField: "rag",
			text: "Closed/Due",
			formatter: (cell, row) =>
				clearview.StageRagBadge(row, row.status !== "NotYetOpen" ? moment(row.closedAt || row.dueDate).format("DD/MM/YYYY") : null),
		},
	]
}

function expandRow(periodEnd) {
	// Persist list of open stages in local storage so same stages are
	// expanded after a remote refresh and if the user navigates away and back in.
	let stickyStagesTable = clearview.LoadLocal("stickyStagesTable", {})
	if (stickyStagesTable?.periodEndId !== periodEnd.id)
		stickyStagesTable = {
			periodEndId: periodEnd.id,
			expandedStageIds: periodEnd.stages
				.filter(it => !["NotYetOpen", "Closed"].includes(it.status) || it.comments.filter(c => c.type === "Query" && c.isWithUs).length)
				.map(it => it.id),
		}

	return {
		expanded: stickyStagesTable.expandedStageIds,
		renderer: row => <Stage periodEnd={periodEnd} stage={row} />,
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) =>
			isAnyExpands ? <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i> : <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>,
		expandColumnRenderer: ({ expanded }) =>
			expanded ? <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i> : <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>,
		onExpand: (row, isExpanded) => {
			if (isExpanded) stickyStagesTable.expandedStageIds.push(row.id)
			else _.remove(stickyStagesTable.expandedStageIds, it => it === row.id)

			clearview.SaveLocal("stickyStagesTable", stickyStagesTable)
		},
		onExpandAll: isExpandAll => {
			if (isExpandAll) stickyStagesTable.expandedStageIds = periodEnd.stages.map(it => it.id)
			else stickyStagesTable.expandedStageIds = []

			clearview.SaveLocal("stickyStagesTable", stickyStagesTable)
		},
	}
}

export default StagesTable
