import * as _ from "lodash"
import * as clearview from "../../../../../components/@Clearview"

export default function getReportData(workingPapers, report) {
	const data = {
		parameters: _.mapValues(report.parameters, it => it.value),
		details: workingPapers.details /*{
			period: "2022",
			duration: "Year",
			previousPeriod: "2021",
			previousDuration: "Qtr"
		}*/,
		periodEnd: workingPapers.periodEnd,
		template: workingPapers.periodEnd.template,
		property: workingPapers.periodEnd.property,
		client: clearview.TopClientFor(workingPapers.periodEnd.property),
		customer: clearview.CustomerFor(workingPapers.periodEnd.property),
		// allSchedules: { groups: {}, aggregates: {} }
		// schedules: [{ groups: {}, aggregates: {} }]
		// numberOfSchedules: 0
		// balanceSheet: { groups: {}, aggregates: {} }
	}

	const scheduleReducer = (rows, previousSchedule) =>
		rows.reduce(
			(totals, row) => {
				let existingRow = totals[row.code]
				if (!existingRow) {
					totals[row.code] = {
						nominalCode: row.nominalCode,
					}
					existingRow = totals[row.code]
				}

				existingRow.budget = clearview.sumValues(existingRow.budget, row.budget)
				existingRow.clientReserve = clearview.sumValues(existingRow.clientReserve, row.clientReserve)
				existingRow.clientTrialBalance = clearview.sumValues(existingRow.clientTrialBalance, row.clientTrialBalance)
				existingRow.crAndAccrual = clearview.sumValues(existingRow.crAndAccrual, row.crAndAccrual)
				existingRow.drAndPrepay = clearview.sumValues(existingRow.drAndPrepay, row.drAndPrepay)
				existingRow.expenditure = clearview.sumValues(existingRow.expenditure, row.expenditure)
				existingRow.income = clearview.sumValues(existingRow.income, row.income)
				existingRow.journalCr = clearview.sumValues(existingRow.journalCr, row.journalCr)
				existingRow.journalDr = clearview.sumValues(existingRow.journalDr, row.journalDr)
				existingRow.perAccountsDrCr = clearview.sumValues(existingRow.perAccountsDrCr, row.perAccountsDrCr)
				existingRow.perAmendedAgentReportsDrCr = clearview.sumValues(existingRow.perAmendedAgentReportsDrCr, row.perAmendedAgentReportsDrCr)
				existingRow.perOriginalReportsCr = clearview.sumValues(existingRow.perOriginalReportsCr, row.perOriginalReportsCr)
				existingRow.perOriginalReportsDr = clearview.sumValues(existingRow.perOriginalReportsDr, row.perOriginalReportsDr)
				existingRow.presentationCr = clearview.sumValues(existingRow.presentationCr, row.presentationCr)
				existingRow.presentationDr = clearview.sumValues(existingRow.presentationDr, row.presentationDr)

				if (previousSchedule && previousSchedule[row.code]) {
					existingRow.previous = previousSchedule[row.code]
				}
				return totals
			},
			{ groups: {}, aggregates: {} }
		)

	const aggregate = group => {
		const aggregator = (objValue, srcValue, key, object, source) => {
			if (_.isNumber(objValue) || _.isNumber(srcValue)) {
				return clearview.sumValues(objValue, srcValue)
			}

			if (key === "nominalCode") return null
			if (key === "previous") return [objValue, srcValue].reduce(reducer)

			return undefined
		}

		const reducer = (agg, next) => {
			if (!agg) return _.cloneDeep(next)
			_.assignWith(agg, next, aggregator)
			return agg
		}

		return group.reduce(reducer, undefined)
	}

	const previous = {
		allSchedules: scheduleReducer(workingPapers.comparatives.filter(it => it.schedule !== "BS")),
		schedules: workingPapers.schedules
			.filter(it => it !== "BS")
			.sort(clearview.caseInsensitiveSort)
			.map(scheduleName => ({
				name: scheduleName,
				...scheduleReducer(workingPapers.comparatives.filter(it => it.schedule === scheduleName)),
			})),
		balanceSheet: scheduleReducer(workingPapers.comparatives.filter(it => it.schedule === "BS")),
	}

	data.allSchedules = scheduleReducer(
		workingPapers.etb.filter(it => it.schedule !== "BS"),
		previous.allSchedules
	)
	data.schedules = workingPapers.schedules
		.filter(it => it !== "BS")
		.sort(clearview.caseInsensitiveSort)
		.map((scheduleName, idx) => ({
			name: scheduleName,
			...scheduleReducer(
				workingPapers.etb.filter(it => it.schedule === scheduleName),
				previous.schedules[idx]
			),
		}))
	data.numberOfSchedules = data.schedules.length
	data.balanceSheet = scheduleReducer(
		workingPapers.etb.filter(it => it.schedule === "BS"),
		previous.balanceSheet
	)

	if (!!report.template.groups) {
		_.keys(report.template.groups).map(name => {
			const nominalCodes = report.template.groups[name]

			data.allSchedules.groups[name] = {
				rows: nominalCodes.map(code => data.allSchedules[code]).filter(it => !!it),
			}
			data.allSchedules.groups[name].total = aggregate(data.allSchedules.groups[name].rows)

			for (let i = 0; i < data.schedules.length; i++) {
				data.schedules[i].groups[name] = { rows: nominalCodes.map(code => data.schedules[i][code]).filter(it => !!it) }
				data.schedules[i].groups[name].total = aggregate(data.schedules[i].groups[name].rows)
			}

			data.balanceSheet.groups[name] = { rows: nominalCodes.map(code => data.balanceSheet[code]).filter(it => !!it) }
			data.balanceSheet.groups[name].total = aggregate(data.balanceSheet.groups[name].rows)
		})
	}

	//Aggregate total each schedules
	const allNominalCodes = Object.keys(report.template.groups)
		.map(key => report.template.groups[key])
		.flat()

	const allScheduleRows = []
	for (let i = 0; i < data.schedules.length; i++) {
		const rows = allNominalCodes.map(code => data.schedules[i][code]).filter(it => !!it)
		data.schedules[i].aggregates.total = aggregate(rows)
		allScheduleRows.push(...rows)
	}
	data.allSchedules.aggregates.total = aggregate(allScheduleRows)

	//Aggregate total balance sheet
	const rowsBS = allNominalCodes.map(code => data.balanceSheet[code]).filter(it => !!it)
	data.balanceSheet.aggregates.total = aggregate(rowsBS)

	return data
}
