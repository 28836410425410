import React from "react"
import PropTypes from "prop-types"

import * as Api from "../../../../api/CustomerApi"
import * as clearview from "../../../../components/@Clearview"

import classNames from "classnames"

import S3UncontrolledModal from "./S3UncontrolledModal"

const EMPTY_STATE = {
	folder: undefined,
	folders: undefined,
	files: undefined,
}

class S3Folder extends React.Component {
	static propTypes = {
		folder: PropTypes.string.isRequired,
		requiredSubfolders: PropTypes.array,
		label: PropTypes.string,
		title: PropTypes.string,
		children: PropTypes.node,
		className: PropTypes.string,
		isReadOnly: PropTypes.bool,
		actions: PropTypes.node,
		onOpened: PropTypes.func,
		onChange: PropTypes.func,
		onClosed: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = EMPTY_STATE

		this.fetchFolderCounts = this.fetchFolderCounts.bind(this)

		this.fetchFolderCounts()
	}

	fetchFolderCounts() {
		if (!this.props.children)
			Api.fetchFolderCounts(this.props.folder, this.props.requiredSubfolders)
				.then(result =>
					this.setState({
						folder: this.props.folder,
						folders: result.folders,
						files: result.files,
					})
				)
				.catch(err =>
					this.setState({
						folder: this.props.folder,
						folders: null,
						files: null,
					})
				)

		return true
	}

	render() {
		const { folder, label, title, children, className, actions } = this.props
		const { folders, files } = this.state

		const contentMarginTop = folders ? "0%" : "10%"
		const folderClass = classNames(className, "d-flex-column", "s3-folder")

		return (
			<div className={folderClass} title={title}>
				<div className="d-flex-row">
					<div className="label">{label}</div>
					<div className="flex-1">{actions}</div>
				</div>
				<div className="flex-1 body hover" id={clearview.MakeSafeFolderId(folder)}>
					{children || (
						<div className={!files && !folders ? "greyed-out" : ""} style={{ marginTop: contentMarginTop, marginLeft: "35%", fontSize: "1.75em" }}>
							<div title={`${files || 0} files`}>
								{clearview.Icon.filesMany} {files}
							</div>
							<div hidden={!folders} title={`${folders || 0} folders`}>
								{clearview.Icon.folder} {folders}
							</div>
						</div>
					)}
				</div>
				<S3UncontrolledModal
					folder={folder}
					requiredSubfolders={this.props.requiredSubfolders}
					title={label}
					name={label}
					isReadOnly={this.props.isReadOnly}
					onOpened={this.props.onOpened}
					onChange={this.props.onChange}
					onClosed={this.props.onClosed || this.fetchFolderCounts}
				></S3UncontrolledModal>
			</div>
		)
	}
}

export { S3Folder }
