import React from "react"

import { ReplyLeft, ReplyRight } from "./Reply"
import { Container } from "../../../../components"

const Replies = props => {
	const { periodEnd, query, items } = props
	let { startSide } = props

	startSide = startSide || "Left"

	let isLeft = false
	let currentAuthor = {}
	let fragments = items.map((it, idx) => {
		if (!currentAuthor.username) {
			isLeft = startSide === "Left"
			currentAuthor = it.createdBy
		} else if (currentAuthor.username !== it.createdBy.username) {
			isLeft = !isLeft
			currentAuthor = it.createdBy
		}
		return isLeft ? (
			<ReplyLeft key={idx} periodEnd={periodEnd} query={query} reply={it} />
		) : (
			<ReplyRight key={idx} periodEnd={periodEnd} query={query} reply={it} />
		)
	})

	return <Container hidden={fragments.length === 0}>{fragments}</Container>
}

export { Replies }
