import React from "react"

import _ from "lodash"
import moment from "moment"

import { LivePeriodEndsPhasesOverviewTable } from "./LivePeriodEndsPhasesOverviewTable"
import { LivePeriodEndsPhasesInfoTable } from "./LivePeriodEndsPhasesInfoTable"

import { PhasesPeriodEndRagBadge } from "./PhasesPeriodEndRagBadge"

import * as clearview from "../../../../components/@Clearview"

import PropTypes from "prop-types"

class LivePeriodEndsPhasesView extends React.Component {
	static propTypes = {
		context: PropTypes.object.isRequired,
		livePeriodEnds: PropTypes.array.isRequired,
		view: PropTypes.string.isRequired,
		isReverse: PropTypes.bool,
	}

	render() {
		const { context, livePeriodEnds } = this.props
		const customer = context.state.filter.customer

		const user = context.user
		if (!customer) return

		const groupsSpecification = {
			groupByFunction: periodEnd => periodEnd.status,
			groupLabelFunction: val => val,
			cellLinkParameters: key => `&key=${key}`,

			dataColumnsInKeyOrder(columnPropertyName, headerClasses, filterValueFunc = null, isReverseOrder = false) {
				if (!filterValueFunc) filterValueFunc = key => key

				let columnsFn = (groups, rowData) =>
					_.sortBy(
						Object.keys(groups).map(key => ({
							dataField: `~${key}`,
							text: groups[key],
							style: { width: "10%" },
							headerClasses: headerClasses,
							headerAlign: "center",
							align: "center",
							formatter: (cell, row) =>
								row.data[key]?.length
									? PhasesPeriodEndRagBadge(
											"Info",
											row.data[key]?.length,
											context,
											livePeriodEnds,
											`${row.link}&${new URLSearchParams({ [columnPropertyName]: filterValueFunc(key) }).toString()}`
									  )
									: undefined,
						})),
						it => it.dataField
					)

				if (isReverseOrder) return (groups, rowData) => columnsFn(groups, rowData).reverse()
				return columnsFn
			},

			dataColumnsInTotalsOrder(columnPropertyName) {
				return (groups, rowData) => {
					const columnTotals = Object.keys(groups).reduce((prev, next) => {
						return { ...prev, [next]: (prev[next] || 0) + (_.sumBy(rowData, row => row.data[next]?.length) || 0) }
					}, {})
					return _.sortBy(
						Object.keys(groups).map(key => ({
							dataField: `~${key}`, //Current year ends
							text: groups[key],
							style: { width: "10%" },
							headerAlign: "center",
							align: "center",
							formatter: (cell, row) =>
								row.data[key]?.length
									? PhasesPeriodEndRagBadge(
											"Info",
											row.data[key]?.length,
											context,
											livePeriodEnds,
											`${row.link}&${new URLSearchParams({ [columnPropertyName]: filterValueFunc(key) }).toString()}`
									  )
									: undefined,
						})),
						it => -columnTotals[it.text]
					)
				}
			},
		}

		switch (this.props.view) {
			case "PhasesMonthTable":
				groupsSpecification.groupByFunction = periodEnd => clearview.PeriodEndMeta(periodEnd).YearMonth
				groupsSpecification.groupLabelFunction = val => moment(`${val}-01`).format("MMM-YY")
				groupsSpecification.dataColumns = groupsSpecification.dataColumnsInKeyOrder("yearMonth", "nowrap", null, this.props.isReverse)
				break

			case "PhasesClientTable":
				groupsSpecification.groupByFunction = periodEnd => clearview.PeriodEndMeta(periodEnd).Client?.reference
				groupsSpecification.groupLabelFunction = val => context.findBusiness(it => it.reference === val)?.name
				groupsSpecification.dataColumns = groupsSpecification.dataColumnsInKeyOrder("client")
				break

			case "PhasesManagerTable":
				groupsSpecification.groupByFunction = periodEnd => clearview.PeriodEndMeta(periodEnd).InHouseManagerName
				groupsSpecification.groupLabelFunction = val => val || "Unassigned"
				groupsSpecification.dataColumns = groupsSpecification.dataColumnsInKeyOrder("inHouseManagerName")
				break

			case "PhasesAppointeesTable_Director":
			case "PhasesAppointeesTable_Accounts":
			case "PhasesAppointeesTable_ServiceChargeManager":
			case "PhasesAppointeesTable_Surveyor":
			case "PhasesAppointeesTable_Other":
				const type = user.isInHouse ? "InHouseAppointees" : "ClientAppointees"
				const teamName = _.startCase(this.props.view.substring(22))
				groupsSpecification.groupByFunction = periodEnd => clearview.PeriodEndMeta(periodEnd).Appointees(type, teamName)
				groupsSpecification.groupLabelFunction = val => val || "Unassigned"
				groupsSpecification.dataColumns = groupsSpecification.dataColumnsInKeyOrder("appointees", null, key =>
					JSON.stringify({ type, teamName, value: key })
				)
				break

			default:
				break
		}

		return (
			<React.Fragment>
				{this.props.view === "PhasesOverviewTable" && (
					<LivePeriodEndsPhasesOverviewTable context={context} periodEnds={periodEndsWithCustomerPhase(livePeriodEnds, customer)} />
				)}
				{this.props.view !== "PhasesOverviewTable" && (
					<LivePeriodEndsPhasesInfoTable
						context={context}
						periodEnds={periodEndsWithCustomerPhase(livePeriodEnds, customer)}
						groupsSpecification={groupsSpecification}
						isScrollable={["PhasesMonthTable", "PhasesClientTable"].includes(this.props.view)}
					/>
				)}
			</React.Fragment>
		)
	}
}

function periodEndsWithCustomerPhase(periodEnds, customer) {
	const phaseIdLookup = _.keyBy(customer.phases, it => it.id)
	return periodEnds.filter(it => it.currentStage).filter(it => phaseIdLookup[it.currentStage.templateStage.phaseId])
}

export { LivePeriodEndsPhasesView }
