import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import {
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	CustomInput,
	UncontrolledModal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class Stage_CloseForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired,
		doUpdate: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = { comments: "" }
		this.props = props

		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleAdvanceToStageChanged = this.handleAdvanceToStageChanged.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleCommentsChange(event) {
		this.setState({
			comments: event.target.value,
		})
	}

	handleAdvanceToStageChanged(event) {
		this.setState({
			advanceToStageNumber: parseInt(event.target.value),
		})
	}

	handleActionButtonClick(event, action) {
		this.props.doUpdate({
			action: action,
			periodEnd: this.props.periodEnd,
			comments: this.state.comments,
			otherParameters: {
				advanceToStageNumber: this.state.advanceToStageNumber,
			},
		})

		// Reset the sticky stages
		clearview.SaveLocal("stickyStagesTable", {})

		this.setState({ comments: "", advanceToStageNumber: undefined })
		return true
	}

	render() {
		let advanceToStageNumber = this.props.periodEnd.currentStage?.templateStage.sequence + 1
		const advanceToStages = !this.props.user.isInHouse
			? []
			: this.props.periodEnd.stages.reduce((current, next, currentIndex, arr) => {
					if (advanceToStageNumber === next.templateStage.sequence && !arr[currentIndex - 1].conditions.find(it => !it.isComplete)) {
						current.push(next)
						advanceToStageNumber++
					}
					return current
			  }, [])

		return (
			<UncontrolledModal target={`stage${this.props.stage.id}_CloseForm`}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">
						{clearview.CustomerAlt(
							clearview.PeriodEndMeta(this.props.periodEnd).Customer,
							this.props.stage.templateStage.isInHouse ? "stage.closeForm.title.isInHouse" : "stage.closeForm.title.isClient",
							"Close Stage"
						)}
					</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<Input
									type="textarea"
									name="comments"
									id="comments"
									placeholder="Comments..."
									value={this.state.comments}
									onChange={this.handleCommentsChange}
								/>
							</InputGroup>
						</FormGroup>

						{!!advanceToStages.length && (
							<FormGroup row>
								<InputGroup>
									<InputGroupAddon addonType="prepend">Advance to stage</InputGroupAddon>
									<CustomInput
										type="select"
										id="advanceToStageNumber"
										name="advanceToStageNumber"
										className="ml-auto"
										defaultValue={advanceToStages[0].templateStage.sequence}
										value={this.state.advanceToStageNumber}
										onChange={e => this.handleAdvanceToStageChanged(e)}
									>
										{advanceToStages.map((it, idx) => (
											<option key={idx} value={it.templateStage.sequence}>
												{it.templateStage.sequence} {it.templateStage.name}
											</option>
										))}
									</CustomInput>
								</InputGroup>
							</FormGroup>
						)}
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close color="default" className="text-primary" onClick={e => this.handleActionButtonClick(e, "Close")}>
							{clearview.CustomerAlt(
								clearview.PeriodEndMeta(this.props.periodEnd).Customer,
								this.props.stage.templateStage.isInHouse ? "stage.closeForm.close.label.isInHouse" : "stage.closeForm.close.label.isClient",
								"Close"
							)}
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

const mapDispatchToProps = dispatch => ({
	doUpdate: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE,
			...payload,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(Stage_CloseForm)
