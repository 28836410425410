import React, { useState } from "react"
import { connect } from "react-redux"
import { WP_ACTIONS } from "./store"

import * as _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import { lookupNominalCodes } from "./WorkingPapers.Materialise"
import FileUploadButton, { excelSheetReader } from "../../Workflows/components/FileUploadButton"
import { SimpleTable } from "../../../../components"
import { AddUpdateCrsDrs } from "./components"

function CRsAndAccrualsPage({ isReadOnly, workingPapers, crsAndAccruals, onDelete, onSave, onReadWorksheet }) {
	const [rowEditor, setRowEditor] = useState(false)

	const onAdd = isReadOnly
		? false
		: defaultValue => {
				setRowEditor({ title: "Add New Cr/Accrual", value: { schedule: defaultValue.schedule, code: undefined, total: 0 } })
		  }

	const onEdit = isReadOnly
		? false
		: row => {
				setRowEditor({ title: "Edit Cr/Accrual", value: { ...row } })
		  }

	if (isReadOnly) onDelete = false

	const onCancel = e => {
		setRowEditor(false)
	}

	const columns = {
		// schedule: { map: clearview.tidy.string },
		date: { map: clearview.tidy.date, className: "nowrap" },
		code: { map: (col, row) => clearview.tidy.string(row.nominalCode?.code) },
		account: {
			map: (col, row) => (
				<span title={row.isReadOnly ? "Fee is set on the Calc tab." : undefined}>
					{clearview.tidy.string(row.nominalCode?.name)} {row.isReadOnly ? clearview.Icon.calculated : undefined}
				</span>
			),
			className: "nowrap",
		},
		reference: { heading: "BW Ref", map: clearview.tidy.string },
		total: {
			map: clearview.tidy.currency,
			className: "currency text-dark",
		},
		supplier: { map: clearview.tidy.string },
		comments: { map: clearview.tidy.string },
	}

	const data = [...crsAndAccruals]

	const feeAllocations = workingPapers.fees.allocations.reduce((prev, allocation) => {
		const readOnlyAllocation = { ...allocation, isReadOnly: true }
		if (readOnlyAllocation.total) {
			const beforeIndex = _.findIndex(data, it => it.schedule >= readOnlyAllocation.schedule)
			data.splice(beforeIndex, 0, readOnlyAllocation)
			prev.push(readOnlyAllocation)
		}
		return prev
	}, [])
	lookupNominalCodes(workingPapers, feeAllocations)

	return (
		<React.Fragment>
			<div className="mt-2">
				<SimpleTable
					columns={columns}
					data={data}
					groupBy={["schedule"]}
					summaryColumns={["total"]}
					onAdd={onAdd}
					onEdit={onEdit}
					onDelete={onDelete}
				/>
			</div>
			<AddUpdateCrsDrs
				name="rowEditor"
				config={{ title: rowEditor?.title }}
				isOpen={!!rowEditor}
				onCancel={onCancel}
				onSave={row => {
					onSave(row)
					onCancel()
				}}
				value={rowEditor?.value}
			/>
			{!isReadOnly && (
				<FileUploadButton className="mt-2" onUploaded={excelSheetReader(onReadWorksheet)} accept=".xls,.xlsx,.csv">
					{clearview.Icon.upload} Import CRs and Accruals...
				</FileUploadButton>
			)}
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		workingPapers: state.workingPapersReducer.workingPapers,
		crsAndAccruals: state.workingPapersReducer.workingPapers.crsAndAccruals,
	}
}

const mapDispatchToProps = dispatch => ({
	onDelete: row =>
		dispatch({
			type: WP_ACTIONS.DELETE_ROW,
			page: "crsAndAccruals",
			row,
		}),

	onSave: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_ROW,
			page: "crsAndAccruals",
			row,
		}),

	onReadWorksheet: (file, data, worksheetName) =>
		dispatch({
			type: WP_ACTIONS.READ_CRS_AND_ACCRUALS_WORKSHEET,
			page: "crsAndAccruals",
			file,
			data,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(CRsAndAccrualsPage)
