import { call, put, takeLatest } from "redux-saga/effects"

import { Auth } from "aws-amplify"

import * as ACTIONS from "./index"
import * as Api from "../../api/CustomerApi"

function* userAuthenticated() {
	yield Auth.currentAuthenticatedUser()
		.then(user => {
			console.info(`SAGA: bearer token: ${user.signInUserSession.idToken.jwtToken}`)
		})
		.catch(err => alert(`ERROR CODE 001: ${err}`))

	const user = yield call(Api.fetchCurrentUser)
	yield put({ type: ACTIONS.USER_GOT_CURRENT_USER, user: user })
	if (!user.error) yield put({ type: ACTIONS.TEMPLATES_FETCH, firstLoad: true })
}

function* userPasswordReset(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const info = yield call(Api.userPasswordReset, action.username)

		yield put({
			type: ACTIONS.USER_PASSWORD_RESET_OK,
			message: `${info.email}\nurl:\thttps://${window.location.hostname}\nusername:\t\t${info.username}\ntemp password:\t${info.password}`,
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 024",
			type: ACTIONS.USER_PASSWORD_RESET_FAILED,
			message: e.message,
		})
	}
}

export default function* userAuthenticatedSaga() {
	yield takeLatest(ACTIONS.USER_AUTHENTICATED, userAuthenticated)
	yield takeLatest(ACTIONS.USER_PASSWORD_RESET, userPasswordReset)
}
