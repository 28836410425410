import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"

import * as clearview from "../../../../components/@Clearview"

import VouchingReport from "./VouchingReport"

class VouchingActions extends React.Component {
	static propTypes = {
		isChanged: PropTypes.bool,
		periodEnd: PropTypes.object,
		vouching: PropTypes.object,
		user: PropTypes.object,
	}

	render() {
		const { isChanged, periodEnd, vouching, user } = this.props

		const editTextractAnalysisRulesUrl = clearview.editPreferencesUrl(user, clearview.CustomerFor(periodEnd.property), "textract-analysis-rules")
		const editVouchingVendorAliasesUrl = clearview.editPreferencesUrl(user, clearview.ClientFor(periodEnd.property), "vendor-aliases")

		return (
			<React.Fragment>
				{editTextractAnalysisRulesUrl && (
					<Link
						to={`${editTextractAnalysisRulesUrl}?sideBar=0`}
						className="text-decoration-none nowrap mr-2 text-primary"
						target="textract-analysis-rules"
					>
						<span title="Opens in a new tab">{clearview.Icon.settings} Invoice Import Rules...</span>
					</Link>
				)}

				{editVouchingVendorAliasesUrl && (
					<Link to={`${editVouchingVendorAliasesUrl}?sideBar=0`} className="text-decoration-none nowrap mr-2 text-primary" target="vendor-aliases">
						<span title="Opens in a new tab">{clearview.Icon.settings} Vendor Aliases...</span>
					</Link>
				)}

				<Link
					to="."
					disabled={!!isChanged}
					title={!!isChanged ? "You must save your changes before running the Vouching Report." : undefined}
					className="text-decoration-none nowrap mr-2 text-primary"
					onClick={e => {
						if (!isChanged) VouchingReport(periodEnd, vouching)
						clearview.StopPropagation(e)
						return false
					}}
				>
					{clearview.Icon.FileType.Excel} Vouching Report
				</Link>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

export default connect(mapStateToProps)(VouchingActions)
