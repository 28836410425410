import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import classNames from "classnames"

import _ from "lodash"

import config from "../../../../config"

import { Badge, UncontrolledTabs, TabPane, Nav, NavItem } from "../../../../components"

import { PreFilterLink } from "../../../components/PreFilterLink"
import { HelpPopup } from "../../../components/HelpPopup"
import * as clearview from "../../../../components/@Clearview"
import PropTypes from "prop-types"

class LivePeriodEndsStagesOverview extends React.Component {
	static propTypes = {
		context: PropTypes.object.isRequired,
		periodEnds: PropTypes.array.isRequired,
		livePeriodEnds: PropTypes.array.isRequired,
	}

	tabTitle(grouping) {
		return _.uniq(grouping.map(it => it.name)).join(", ")
	}

	render() {
		const { context, periodEnds, livePeriodEnds } = this.props
		const customer = context.state.filter.customer
		if (!customer) return

		const allTemplatesByFamily = _.groupBy(
			periodEnds.map(it => it.template),
			it => it.family
		)

		const allTemplateFamilies = _.sortBy(Object.keys(allTemplatesByFamily), label => {
			let seq = allTemplatesByFamily[label][0].sequence
			if (seq === 0) seq = 99999
			return String(seq).padStart(6, "0") + label
		})

		const currentPeriodEnds = livePeriodEnds.filter(it => it.currentStage)
		const byTemplateFamily = _.groupBy(currentPeriodEnds.filter(it => it.currentStage) || [], it => it.template.family)
		const selectedTemplateFamily = allTemplateFamilies.length === 1 ? allTemplateFamilies[0] : context.state.filter.templateFamily?.value

		return (
			<React.Fragment>
				<div className="hr-text hr-text-left my-2">
					<span>
						Schemes
						<HelpPopup id="od_hp_schemes" title="Schemes">
							<p>
								This section shows the Templates for All Period Ends, grouped by their Template Family. The table shows Open Period Ends in each
								Stage of the Template by Period End RAG status.
							</p>
						</HelpPopup>
					</span>
				</div>
				<UncontrolledTabs initialActiveTabId="default">
					<Nav pills className="mb-0 flex-column flex-md-row mt-0 mt-lg-0" id="LivePeriodEndsStagesOverview">
						{allTemplateFamilies.map(templateFamily => (
							<NavItem key={templateFamily} title={this.tabTitle(allTemplatesByFamily[templateFamily])}>
								<a
									href="#"
									className={classNames({
										active: templateFamily == selectedTemplateFamily,
										"nav-link": true,
									})}
									onClick={e => context.handleChange([{ value: templateFamily, _title: templateFamily }], "templateFamily")}
								>
									{templateFamily}
									{byTemplateFamily[templateFamily] && <sup>{byTemplateFamily[templateFamily].length}</sup>}
								</a>
							</NavItem>
						))}
						{allTemplateFamilies.length > 1 && selectedTemplateFamily && (
							<NavItem key="~all" title="All">
								<a
									href="#"
									className={classNames({
										active: !selectedTemplateFamily,
										"nav-link": true,
									})}
									onClick={e => context.handleChange([], "templateFamily")}
								>
									{clearview.Icon.clear}
								</a>
							</NavItem>
						)}
					</Nav>
					<UncontrolledTabs.TabContent>
						{allTemplateFamilies.map(templateFamily => (
							<TabPane key={templateFamily} tabId={templateFamily === selectedTemplateFamily ? "default" : templateFamily}>
								<StagesOverviewTable
									context={context}
									template={allTemplatesByFamily[templateFamily][0]}
									periodEnds={byTemplateFamily[templateFamily] || []}
								/>
							</TabPane>
						))}
					</UncontrolledTabs.TabContent>
				</UncontrolledTabs>
			</React.Fragment>
		)
	}
}

class StagesOverviewTable extends React.Component {
	static propTypes = {
		context: PropTypes.object.isRequired,
		template: PropTypes.object.isRequired,
		periodEnds: PropTypes.array.isRequired,
	}

	render() {
		const { context, template, periodEnds } = this.props

		const bySequence = _.groupBy(
			periodEnds.flatMap(it => it.stages),
			it => `${it.templateStage.sequence}|${it.templateStage.name}`
		)

		const rowData = Object.keys(bySequence)
			.sort()
			.map(key => {
				const seq = key.split("|")[0]

				const templateStage = _.find(_.first(bySequence[key]).periodEnd.stages, it => it.templateStage.sequence === parseInt(seq))?.templateStage || {}
				const currentStages = bySequence[key].filter(it => it === it.periodEnd.currentStage)

				const byPeriodEndRag = _.groupBy(currentStages, it => it.periodEnd.rag)

				return {
					template: template,
					templateStage: templateStage,
					seq: seq,
					owner: templateStage.isInHouse ? config.brand(window.location).customer : "Client",
					name: templateStage.name,
					link: `/periodEnds?templateFamily=${template.family}&periodEndStatus=Open&stageName=${templateStage.name}`,

					OnHold: (byPeriodEndRag["OnHold"] || []).length,
					OnTime: (byPeriodEndRag["OnTime"] || []).length,
					Warning: (byPeriodEndRag["Warning"] || []).length,
					BehindSchedule: (byPeriodEndRag["BehindSchedule"] || []).length,
					Overdue: (byPeriodEndRag["Overdue"] || []).length,
				}
			})
		rowData.forEach(it => {
			it.Total = it.OnHold + it.OnTime + it.Warning + it.BehindSchedule + it.Overdue
		})

		const rowClasses = (row, idx) => {
			return row.owner === "Client" ? `table-info` : `table-primary`
		}

		return (
			<ToolkitProvider
				keyField="seq"
				data={rowData}
				columns={[
					{
						dataField: "seq",
						text: "#",
						headerAlign: "center",
						align: "center",
					},
					{
						dataField: "owner",
						text: "Owner",
						headerAlign: "center",
						align: "center",
					},
					{
						dataField: "name",
						text: "Stage",
						headerAlign: "center",
						align: "left",
					},
					{
						dataField: "OnHold",
						text: clearview.PeriodEndRagNames["OnHold"],
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0 ? PeriodEndRagBadge("OnHold", cell, context, periodEnds, `${row.link}&periodEndRag=OnHold`) : undefined,
					},
					{
						dataField: "OnTime",
						text: clearview.PeriodEndRagNames["OnTime"],
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0 ? PeriodEndRagBadge("OnTime", cell, context, periodEnds, `${row.link}&periodEndRag=OnTime`) : undefined,
					},
					{
						dataField: "Warning",
						text: clearview.PeriodEndRagNames["Warning"],
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0 ? PeriodEndRagBadge("Warning", cell, context, periodEnds, `${row.link}&periodEndRag=Warning`) : undefined,
					},
					{
						dataField: "BehindSchedule",
						text: clearview.PeriodEndRagNames["BehindSchedule"],
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0 ? PeriodEndRagBadge("BehindSchedule", cell, context, periodEnds, `${row.link}&periodEndRag=BehindSchedule`) : undefined,
					},
					{
						dataField: "Overdue",
						text: clearview.PeriodEndRagNames["Overdue"],
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0 ? PeriodEndRagBadge("Overdue", cell, context, periodEnds, `${row.link}&periodEndRag=Overdue`) : undefined,
					},
					{
						dataField: "Total",
						text: "Total",
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) => (cell > 0 ? PeriodEndRagBadge("Total", cell, context, periodEnds, `${row.link}`) : undefined),
					},
				]}
			>
				{props => (
					<React.Fragment>
						<BootstrapTable
							keyField="seq"
							classes="table-responsive-lg csl-stage-table text-inverse"
							bordered={true}
							rowClasses={rowClasses}
							responsive
							hover
							{...props.baseProps}
						/>
					</React.Fragment>
				)}
			</ToolkitProvider>
		)
	}
}

function PeriodEndRagBadge(rag, content, context, periodEnds, to, options = { className: "badge-pill" }) {
	var totalColor = rag === "Total" ? "dark" : undefined
	return (
		<PreFilterLink context={context} periodEnds={periodEnds} to={to} clear={false} className="text-decoration-none">
			<Badge color={totalColor || clearview.PeriodEndRagColors[rag]} title={to} className={options.className}>
				{content || clearview.PeriodEndRagNames[rag]}
			</Badge>
		</PreFilterLink>
	)
}

export { LivePeriodEndsStagesOverview }
