import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { Popover } from "reactstrap"

class UncontrolledPopover extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpen: false,
			isOpening: false,
		}

		this.clickEventHandler = this.clickEventHandler.bind(this)
	}

	componentDidMount() {
		const { target } = this.props

		if (typeof document !== "undefined") {
			this.triggerElement = document.querySelector(`#${target}`)

			if (!this.triggerElement) {
				// eslint-disable-next-line no-console
				console.error("UncontrolledPopover: 'target' element has not been found in the DOM via querySelector")
				return
			}

			this.triggerElement.addEventListener("click", this.clickEventHandler.bind(this))
		}
	}

	componentDidUpdate() {
		if (this.props.activateTrigger && this.triggerElement) {
			const { activeClassName } = this.props
			this.triggerElement.classList.toggle(activeClassName, this.state.isOpen)
		}
	}

	componentWillUnmount() {
		if (this.triggerElement) {
			this.triggerElement.removeEventListener("click", this.clickEventHandler)
		}
	}

	clickEventHandler() {
		if (!this.state.isOpen && this.props.beforeOpenAsync) {
			this.setState({ isOpen: true, isOpening: true })
			this.props.beforeOpenAsync().then(() => this.setState({ isOpening: false }))
		} else this.setState({ isOpen: !this.state.isOpen, isOpening: false })
	}

	render() {
		return (
			<Popover
				{..._.omit(this.props, ["activateTrigger", "activeClassName"])}
				isOpen={this.state.isOpen}
				toggle={() => this.setState({ isOpen: false, isOpening: false })}
			>
				{this.state.isOpening && this.props.beforeOpenContent}
				{!this.state.isOpening && this.props.children}
			</Popover>
		)
	}
}
UncontrolledPopover.propTypes = {
	activateTrigger: PropTypes.bool,
	activeClassName: PropTypes.string,
	beforeOpenAsync: PropTypes.func,
	beforeOpenContent: PropTypes.node,
}
UncontrolledPopover.defaultProps = {
	activateTrigger: true,
	activeClassName: "active",
	beforeOpenContent: <p>Please wait...</p>,
}

export { UncontrolledPopover }
