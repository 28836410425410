import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import * as clearview from "../../../../../components/@Clearview"

function FeeMatrixActions({ periodEnd, user }) {
	const editFeeMatrixUrl = clearview.editPreferencesUrl(user, clearview.ClientFor(periodEnd.property), "working-papers-fee-matrix")

	return (
		<React.Fragment>
			{editFeeMatrixUrl && (
				<Link to={`${editFeeMatrixUrl}?sideBar=0`} className="text-decoration-none nowrap mr-2 text-primary" target="working-papers-fee-matrix">
					<span title="Opens in a new tab">{clearview.Icon.matrix} Edit Fee Matrix...</span>
				</Link>
			)}
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

export default connect(mapStateToProps)(FeeMatrixActions)
