import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import _ from "lodash"
import Dropdown from "react-bootstrap/Dropdown"

import PeriodEnd_StartForm from "./PeriodEnd_StartForm"
import PeriodEnd_DeleteForm from "./PeriodEnd_DeleteForm"
import AreYouSure from "../../../components/AreYouSure"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"

class PeriodEndActions extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props
	}

	render() {
		const { periodEnd, doUpdateReference } = this.props
		const actions = _.intersection(periodEnd.actions, ["UpdateReference", "Start", "Delete"])

		return (
			<Dropdown hidden={!actions.length} className="align-self-center ml-auto" direction="left" onToggle={clearview.StopPropagation}>
				<Dropdown.Toggle variant="none">{clearview.Icon.actions}</Dropdown.Toggle>
				<Dropdown.Menu end persist>
					<Dropdown.Item hidden={!actions.includes("UpdateReference")}>
						<button className="tight" id="UpdateReference_AreYouSure">
							{clearview.Icon.wizard} Update Reference...
						</button>
					</Dropdown.Item>

					<Dropdown.Item hidden={!actions.includes("Start")}>
						<button className="tight" id={`periodEnd${periodEnd.id}_StartForm`}>
							{clearview.Icon.periodEnd} Start Period End...
						</button>
					</Dropdown.Item>
					<Dropdown.Item hidden={!actions.includes("Delete")}>
						<button className="tight" id={`periodEnd${periodEnd.id}_DeleteForm`}>
							{clearview.Icon.delete} Delete Period End...
						</button>
					</Dropdown.Item>
					<AreYouSure
						id="UpdateReference_AreYouSure"
						message={
							<span>
								Update <b className="nowrap">{periodEnd.reference}</b> to be{" "}
								<b className="nowrap">
									{periodEnd.property.reference}/{periodEnd.template.reference}/{periodEnd.startOn?.substring(0, 4)}
								</b>
								?
							</span>
						}
						action={() => doUpdateReference(periodEnd)}
					/>
					<PeriodEnd_StartForm periodEnd={periodEnd} />
					<PeriodEnd_DeleteForm periodEnd={periodEnd} />
				</Dropdown.Menu>
			</Dropdown>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doUpdateReference: periodEnd =>
		dispatch({
			type: ACTIONS.PERIODEND_REFERENCE_UPDATE,
			periodEnd: periodEnd,
		}),
})

PeriodEndActions = connect(null, mapDispatchToProps)(PeriodEndActions)
export { PeriodEndActions }
