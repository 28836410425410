import React, { useState } from "react"
import { connect } from "react-redux"
import { WP_ACTIONS } from "./store"

import * as _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import { SimpleTable } from "../../../../components"

import { AddUpdateJournalEntry } from "./components/AddUpdateJournalEntry"

function JournalsPage({ isReadOnly, journals, onDelete, onSave }) {
	const [rowEditor, setRowEditor] = useState(false)

	const onAdd = isReadOnly
		? false
		: defaultValue => {
				setRowEditor({ title: "Add New Journal Entry", value: { description: "", isPresentationOnly: false, items: [{}] } })
		  }

	const onEdit = isReadOnly
		? false
		: item => {
				setRowEditor({ title: "Edit Journal Entry", value: { ...item } })
		  }

	if (isReadOnly) onDelete = false

	const onCancel = e => {
		setRowEditor(false)
	}

	const dataMap = it => ({ ...it, "Dr £": _.sumBy(it.items, it => it.dr), "Cr £": _.sumBy(it.items, it => it.cr) })

	const itemColumnMap =
		(valueMap, displayMap = clearview.tidy.string) =>
		(col, row) =>
			(
				<ul className="list-style-type-none nowrap">
					{row.items ? row.items.map((it, idx) => <li key={`it_${idx}`}>{displayMap(valueMap(it)) || "-"}</li>) : displayMap(col)}
				</ul>
			)
	const columns = {
		description: { map: clearview.tidy.string, className: "vertical-align-top" },
		isPresentationOnly: {
			heading: <span title="Presentation only?">Pres?</span>,
			map: clearview.tidy.boolean,
			className: "vertical-align-top text-align-center",
		},
		code: {
			map: itemColumnMap(it => it.code),
			className: "vertical-align-top",
		},
		schedule: {
			map: itemColumnMap(it => it.schedule),
			className: "vertical-align-top",
		},
		detailsAndWorkings: {
			heading: <span className="nowrap">Details & Workings</span>,
			map: itemColumnMap(it => it.nominalCode?.name),
			className: "vertical-align-top",
		},
		"Dr £": {
			map: itemColumnMap(it => it.dr, clearview.tidy.currency),
			className: "vertical-align-top currency",
		},
		"Cr £": {
			map: itemColumnMap(it => it.cr, clearview.tidy.currency),
			className: "vertical-align-top currency",
		},
	}

	return (
		<React.Fragment>
			{journals && (
				<div className="mt-2">
					<SimpleTable
						className="auto-width table-striped"
						columns={columns}
						data={journals.map(dataMap)}
						onAdd={onAdd}
						onDelete={onDelete}
						onEdit={onEdit}
						summaryColumns={["Dr £", "Cr £"]}
					/>
				</div>
			)}
			<AddUpdateJournalEntry
				name="rowEditor"
				config={{ title: rowEditor?.title }}
				isOpen={!!rowEditor}
				onCancel={onCancel}
				onSave={item => {
					onSave(item)
					onCancel()
				}}
				value={rowEditor?.value}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		journals: state.workingPapersReducer.workingPapers.journals,
	}
}

const mapDispatchToProps = dispatch => ({
	onDelete: row =>
		dispatch({
			type: WP_ACTIONS.DELETE_ROW,
			page: "journals",
			row,
		}),

	onSave: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_ROW,
			page: "journals",
			row,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(JournalsPage)
