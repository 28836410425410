import { connect } from "react-redux"
import React from "react"
import PropTypes from "prop-types"

import { UncontrolledTooltip } from "./../../../../components"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class PeriodEndFavourite extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		doAddFavourite: PropTypes.func.isRequired,
		doRemoveFavourite: PropTypes.func.isRequired,
		doResetFavourite: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props
		this.onFavourite = this.onFavourite.bind(this)
		this.onReset = this.onReset.bind(this)
	}

	onFavourite(isFavourite) {
		if (isFavourite) {
			this.props.doAddFavourite(this.props.periodEnd)
		} else {
			this.props.doRemoveFavourite(this.props.periodEnd)
		}
	}
	onReset() {
		this.props.doResetFavourite(this.props.periodEnd)
	}

	render() {
		const { periodEnd } = this.props

		return (
			<React.Fragment>
				<button
					hidden={periodEnd.isAlert}
					className="inline-text"
					href="#"
					onClick={e => {
						clearview.StopPropagation(e)
						this.onFavourite(!periodEnd.isFavourite)
					}}
					id={`isFavourite_${periodEnd.id}`}
				>
					{periodEnd.isFavourite && <i title="Click to Remove Priority" className="fa fa-fw fa-star text-warning"></i>}
					{!periodEnd.isFavourite && <i title="Click to set as Priority" className="fa fa-fw fa-star-o text-dark"></i>}
				</button>
				{periodEnd.isFavourite && !periodEnd.isAlert && (
					<UncontrolledTooltip placement="left-end" target={`isFavourite_${periodEnd.id}`}>
						<span>No changes since {new Date(periodEnd.isFavourite.alertsResetAt).toLocaleString()}</span>
					</UncontrolledTooltip>
				)}
				<button
					hidden={!periodEnd.isAlert}
					className="inline-text"
					href="#"
					onClick={e => {
						clearview.StopPropagation(e)
						this.onReset()
					}}
					id={`isAlert_${periodEnd.id}`}
				>
					<i title={"Click to Reset Alert"} className="fa fa-fw fa-bell text-danger"></i>
				</button>
				{periodEnd.isAlert && (
					<UncontrolledTooltip placement="left-end" target={`isAlert_${periodEnd.id}`}>
						<span>Changed {new Date(periodEnd.changedAt).toLocaleString()}</span>
					</UncontrolledTooltip>
				)}
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAddFavourite: periodEnd =>
		dispatch({
			type: ACTIONS.FAVOURITE_ADD,
			periodEnd: periodEnd,
		}),

	doRemoveFavourite: periodEnd =>
		dispatch({
			type: ACTIONS.FAVOURITE_REMOVE,
			periodEnd: periodEnd,
		}),

	doResetFavourite: periodEnd =>
		dispatch({
			type: ACTIONS.FAVOURITE_RESET,
			periodEnd: periodEnd,
		}),
})

PeriodEndFavourite = connect(null, mapDispatchToProps)(PeriodEndFavourite)

export { PeriodEndFavourite }
