import * as _ from "lodash"
import * as clearview from "../../../../../components/@Clearview"

import Formatters from "./Formatters"
import DocumentToPdf from "./DocumentToPdf"

export default function Pdf({ reportData, reportTemplate, meta }) {
	const generatePages = () =>
		reportTemplate.pages
			.filter(it => !it.isExcluded)
			.flatMap(templatePage => {
				if (!!templatePage.per) {
					return reportData[templatePage.per].map((it, idx) => perPage(templatePage, templatePage.per, idx))
				}

				return [simplePage(templatePage)]
			})

	const resolveTokens = (stringTemplate, fromNode) => {
		if (!stringTemplate) return stringTemplate

		if (!fromNode) fromNode = reportData

		return stringTemplate
			.split(/(\{[^\}]+\})/gm)
			.map(part => {
				if (!/^\{[^\}]+\}$/.test(part)) return part

				const pipes = part.match(/[^\|(\{\}?! )]+/g)
				if (!pipes.length) return part

				const path = pipes.shift()

				const values = _.at(fromNode, path).filter(it => !!it)
				if (!values.length) return `{${path}}`

				return values.map(it => {
					return pipes.reduce((prev, pipe) => {
						const formatter = Formatters[pipe] || Formatters.undefined
						return formatter(prev)
					}, it)
				})
			})
			.join("")
	}

	const perPage = (templatePage, collectionPrefix, collectionIndex) => {
		const resolveCollectionInstance = value =>
			value
				?.replaceAll(`{${collectionPrefix}[n]`, `{${collectionPrefix}[${collectionIndex}]`)
				.replaceAll(`.${collectionPrefix}[n]`, `.${collectionPrefix}[${collectionIndex}]`)
				.replaceAll(`~${collectionPrefix}[n]~`, `${collectionPrefix}[${collectionIndex}]`) // For the Table Data attribute Schedules[n]

		const thisTemplatePage = _.cloneDeep(templatePage)
		thisTemplatePage.bookmark = resolveCollectionInstance(thisTemplatePage.bookmark)
		thisTemplatePage.content.forEach(it =>
			_.forOwn(it, (value, key) => {
				if (_.isString(value)) {
					it[key] = resolveCollectionInstance(value)
				}
			})
		)

		return simplePage(thisTemplatePage)
	}

	const simplePage = templatePage => {
		let noteNumber = 0

		return {
			...templatePage,
			bookmark: resolveTokens(templatePage.bookmark) || _.startCase(templatePage.page),
			content: templatePage.content.flatMap(it =>
				_.keys(it)
					.flatMap(key => {
						let value = it[key]

						switch (key) {
							case "table":
								if (_.isString(value)) value = translateTableDef(it)
								return layoutTable(value, it.class, it.style)

							case "h1":
							case "h2":
							case "p":
								return [{ [key]: resolveTokens(value?.toString()), class: it.class, style: it.style }]

							case "h3":
								noteNumber++
								return [{ [key]: `${noteNumber}. ${resolveTokens(value?.toString())}`, class: it.class, style: it.style }]

							case "svg":
								return [it]

							case "id":
							case "data":
							case "key":
							case "rows":
							case "class":
							case "style":
							case "format":
							case "nominalCodes":
								return undefined

							default:
								return [{ [key]: `[${key}:${resolveTokens(value?.toString())}]`, class: it.class, style: it.style }]
						}
					})
					.filter(it => !!it)
			),
		}
	}

	const translateTableDef = it => {
		const assigner = (objValue, srcValue, key, object, source) => {
			if (_.isString(srcValue)) return srcValue.replaceAll("{data}", it.data)
			if (_.isArray(srcValue))
				return srcValue.map(it => {
					if (_.isString(it)) return it.replaceAll("{data}", it.data)
					if (_.isObject(it)) return _.assignWith({}, it, assigner)
					return it
				})
			if (_.isObject(srcValue)) return _.assignWith({}, srcValue, assigner)
		}

		return _.assignWith(it, reportTemplate.tableDefs[it.table], assigner)
	}

	let tableTotals = { 0: {} }

	const layoutTable = (table, className, style) => {
		if (!table) return undefined
		if (!table.rows && !table.src) return undefined

		if (table.src) {
			return layoutTable(_.get(reportData, table.src), className, style)
		}

		className = table.class || className

		tableTotals = tableTotals = { 0: {} }
		const rows = [
			{
				table: {
					rows: table.rows.flatMap((row, idx) => layoutTableRow(row, idx, 1)),
				},
				class: className,
				style: style,
			},
		]

		if (!!table.key) reportData[table.key] = _.cloneDeep(tableTotals)
		return rows
	}

	const layoutTableRow = (row, idx, depth) => {
		const rows = []

		if (!!row.per) {
			const group = _.at(reportData, row.per)[0] || []
			rows.push(
				...group.map((groupItem, gIdx) => ({
					tr: layoutTableRowCells(depth, row, { details: reportData.details, row: { ...groupItem, index: gIdx + 1 } }),
				}))
			)
		} else {
			rows.push({ tr: layoutTableRowCells(depth, row) })
		}

		if (row.children)
			_.each(row.children, (nestedRow, rIdx) => {
				rows.push(...layoutTableRow(nestedRow, rIdx, depth + 1))
			})

		return rows
	}

	const layoutTableRowCells = (depth, row, fromNode) => {
		if (!fromNode) fromNode = reportData

		const tableGroupRef = row.key || row.tr?.toString() || clearview.GenerateKey()
		tableTotalsStartGroup(depth, tableGroupRef)

		const dataCells = (row.columns || []).map((column, idx) => {
			const dataColumn = layoutDataColumn(column, idx, fromNode)
			tableTotalsAddCellValue(depth, tableGroupRef, idx, dataColumn.value)

			delete dataColumn.value
			return dataColumn
		})

		const tr = _.isArray(row.tr) ? trSelectConditionalHeading(row.tr, fromNode) : row.tr
		return [{ [`th${depth}`]: resolveTokens(tr, fromNode), class: row.class }, ...dataCells]
	}

	const trSelectConditionalHeading = (options, fromNode) => {
		const selectedOption = _.find(options, option => {
			const conditions = _.values(option)[0]
			return _.every(conditions, condition => {
				if (condition === true) return true
				return resolveValueOf(undefined, condition, fromNode)
			})
		})
		return selectedOption ? _.keys(selectedOption)[0] : undefined
	}

	const tableTotalsStartGroup = (depth, thRef) => {
		if (!tableTotals[depth]) tableTotals[depth] = {}
		tableTotals[depth]._ = thRef // This is the current grouping at this depth
		if (!tableTotals[depth][thRef]) tableTotals[depth][thRef] = {}
	}

	const tableTotalsAddCellValue = (depth, th, idx, value) => {
		tableTotals[depth][th][idx] = value
		let parentDepth = depth - 1
		let parentTotalGroupHame = tableTotals[parentDepth]?._

		while (parentDepth > 0) {
			tableTotals[parentDepth][parentTotalGroupHame][idx] = clearview.sumValues(tableTotals[parentDepth][parentTotalGroupHame][idx], value)
			parentDepth = parentDepth - 1
			parentTotalGroupHame = tableTotals[parentDepth]?._
		}
		tableTotals[0][idx] = clearview.sumValues(tableTotals[0][idx], value)
	}

	const layoutDataColumn = (column, idx, fromNode) => {
		if (!fromNode) fromNode = reportData

		let content = "???"
		let cellValue = undefined

		if (column.text !== undefined) {
			content = resolveTokens(column.text, fromNode)
		} else if (column.note !== undefined) {
			content = resolveNotes(column.note, fromNode)
		} else if (!!column.sumOf?.length) {
			const values = resolveSumOf(idx, column.sumOf, fromNode)

			cellValue = clearview.sumValues(...values)
			switch (column.format) {
				case "integer":
					content = parseInt(cellValue)?.toString()
					break

				case "currency":
				default:
					content = clearview.formatCurrency(cellValue, "", true)?.toString()
			}
		}
		return { td: clearview.ifNothing(content, ""), class: column.class, value: cellValue }
	}

	const numberedNotes =
		reportTemplate.pages
			.find(it => it.page === "notes")
			?.content?.filter(element => !!element.h3)
			.map((note, idx) => ({ ...note, number: idx + 1 })) || []

	const resolveNotes = (noteSpec, fromNode) => {
		const specPartsRegex = /(^[^:]+):?(.*)/gim
		const specParts = specPartsRegex.exec(noteSpec)
		if (!specParts || !specParts.shift()) return "BAD" // Shift will remove the whole string match element

		const notes = []
		if (specParts[0] === "nominalCode") {
			const valueListRegex = /([^,'\[\]]+)/gim
			const specCodes = specParts[1]?.match(valueListRegex)
			const codes = specCodes || [fromNode?.row?.nominalCode?.code]

			notes.push(...numberedNotes.filter(note => !!_.intersection(note.nominalCodes || [], codes).length).map(note => note.number))
		}
		return _.uniq(notes).sort(clearview.caseInsensitiveSort).join(",")
	}

	const resolveSumOf = (columnIdx, sumOf, fromNode) => sumOf.map(it => resolveValueOf(columnIdx, it, fromNode))

	const resolveValueOf = (columnIdx, it, fromNode) => {
		if (_.isNumber(it)) return it

		const parts = /(-|\+|^)([^<>=]+)([<>=]+0\??)?/.exec(it)
		const sign = parseInt(`${parts[1] || "+"}1`)

		let val = undefined
		if (parts[2]?.startsWith("{reportData}")) {
			val = _.get({ "{reportData}": reportData }, parts[2])
		} else if (parts[2]?.startsWith("{table}")) {
			const node = _.get({ "{table}": tableTotals }, parts[2])
			val = !!node ? node[columnIdx] || node : undefined
		} else {
			val = _.get(fromNode, parts[2])
		}

		const condition = parts[3]
		switch (condition) {
			case "<0": // return the value if it is <0 otherwise undefined
				val = Math.min(val, 0) || undefined
				break

			case ">0": // return the value if it is >0 otherwise undefined
				val = Math.max(val, 0) || undefined
				break

			case "<=0?": // is the value <= 0 or null
				val = Math.max(val, 0) <= 0
				break

			case ">=0?": // is the value >= 0 or null
				val = Math.min(val, 0) >= 0
				break

			default:
				break
		}

		return sign * val
	}

	const document = {
		styles: reportTemplate.styles,
		classes: reportTemplate.classes,
		pages: generatePages(),
	}

	console.warn("Update Contents according to Actual Pages")

	return DocumentToPdf(document, meta)
}
