import { useBlocker } from "react-router-dom"

function Prompt(props) {
	const block = props.when
	const onLeave = props.onLeave

	useBlocker(() => {
		if (block) {
			const okToLeave = window.confirm(props.message())
			if (okToLeave && onLeave) onLeave()
			return okToLeave ? false : true
		}
		return false
	})

	return <div key={block} />
}

export default Prompt
