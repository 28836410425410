import React from "react"
import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"

import Slider from "rc-slider"
import PropTypes from "prop-types"

export const PeriodEndProgressBar = ({ user, periodEnd, hasLabels, className }) => {
	if (!periodEnd.stages || !periodEnd.stages.length) return <div key={periodEnd.id}>Period end has no stages!</div>
	if (user.preferences.periodEndProgressBar.ByPhase && periodEnd.template.stages.find(it => it.phase))
		return PeriodEndProgressBar_ByPhase({ periodEnd, hasLabels, className })
	return PeriodEndProgressBar_ByStage({ periodEnd, hasLabels, className })
}

const PeriodEndProgressBar_ByStage = ({ periodEnd, hasLabels, className }) => {
	const currentStageId = (_.find(periodEnd.stages, it => !["NotYetOpen", "Closed"].includes(it.status)) || {}).id

	let cumulativeDays = 0
	let currentRange = []
	let trackStyle = []
	let handleStyle = []

	let isInHouse = periodEnd.status === "Closed" ? null : undefined
	let iconClass = null

	const marksArray = (periodEnd.stages || [])
		.concat([{ id: -1, durationDays: 0 }]) //End
		.map(it => {
			const mark = {
				value: cumulativeDays,
				label: it.templateStage ? `S${it.templateStage.sequence}` : "End",
			}

			if (it.id === currentStageId) {
				if (it.templateStage) {
					isInHouse = it.templateStage.isInHouse
				}
				iconClass = `text-${clearview.StageRagColors[periodEnd.rag]}`

				currentRange = [0, cumulativeDays]
				trackStyle = [
					{
						backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
					},
				]
				handleStyle = [
					{
						backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
					},
					{
						backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
					},
				]
			}
			cumulativeDays = cumulativeDays + (it.templateStage ? it.templateStage.plannedDays : 0)
			return mark
		})

	//Convert to dictionary
	const marks = marksArray.reduce((acc, it) => {
		if (acc[it.value]) acc[it.value] = acc[it.value].substring(0, (acc[it.value] + "-").indexOf("-")) + "-" + it.label
		else acc[it.value] = it.label

		if (hasLabels === false) acc[it.value] = ""
		return acc
	}, {})

	return (
		<div key={periodEnd.id} className={`${className || ""} d-flex`}>
			<div style={{ flexGrow: 0, marginLeft: isInHouse ? -1 : -3, marginRight: 9 }} className={`${iconClass} align-top`}>
				{clearview.Icon.InHouse[isInHouse]}
			</div>
			<div style={{ flexGrow: 1 }}>
				<Slider
					range
					disabled
					className={hasLabels === false ? "mb-1" : "mb-4"}
					min={0}
					max={cumulativeDays}
					marks={marks}
					defaultValue={currentRange}
					trackStyle={trackStyle}
					handleStyle={handleStyle}
					dotStyle={{
						backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
					}}
					activeDotStyle={{
						backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
						borderColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
						borderWidth: 1,
					}}
				/>
			</div>
		</div>
	)
}

const PeriodEndProgressBar_ByPhase = ({ periodEnd, hasLabels, className }) => {
	const byPhaseNumberDict = _.groupBy(periodEnd.stages || [], it => it.templateStage.phase?.sequence)

	const byPhaseNumber = _.keys(byPhaseNumberDict)
		.map(phaseNumber => byPhaseNumberDict[phaseNumber])
		.concat([
			[
				{
					status: "NotYetOpen",
					durationDays: 0,
					templateStage: {
						id: -1,
						plannedDays: 0,
						phase: { sequence: 0 },
					},
				},
			],
		])

	let cumulativeDays = 0
	let currentRange = []
	let trackStyle = []
	let handleStyle = []

	let isInHouse = periodEnd.status === "Closed" ? null : undefined
	let iconClass = null

	const marksArray = byPhaseNumber.map(phaseStages => {
		const phaseDays = _.sumBy(phaseStages, it => it.templateStage.plannedDays)

		const mark = {
			value: cumulativeDays,
			label: phaseStages[0].templateStage.id < 0 ? "End" : phaseDays < 10 ? "" : `P${phaseStages[0].templateStage.phase?.sequence}`,
		}

		const currentStage = _.find(phaseStages, it => !["NotYetOpen", "Closed"].includes(it.status))
		if (currentStage) {
			if (currentStage) {
				isInHouse = currentStage.templateStage.isInHouse
			}
			iconClass = `text-${clearview.StageRagColors[periodEnd.rag]}`

			currentRange = [0, cumulativeDays]
			trackStyle = [
				{
					backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
				},
			]
			handleStyle = [
				{
					backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
				},
				{
					backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
				},
			]
		}
		cumulativeDays = cumulativeDays + phaseDays
		return mark
	})

	//Convert to dictionary
	const marks = marksArray.reduce((acc, it) => {
		if (acc[it.value]) acc[it.value] = acc[it.value].substring(0, (acc[it.value] + "-").indexOf("-")) + "-" + it.label
		else acc[it.value] = it.label

		if (hasLabels === false) acc[it.value] = ""
		return acc
	}, {})

	return (
		<div className={className || "d-flex"}>
			<div style={{ flexGrow: 0, marginLeft: isInHouse ? -1 : -3, marginRight: 9 }} className={`${iconClass} align-top`}>
				{clearview.Icon.InHouse[isInHouse]}
			</div>
			<div style={{ flexGrow: 1 }}>
				<Slider
					range
					disabled
					className={hasLabels === false ? "mb-1" : "mb-4"}
					min={0}
					max={cumulativeDays}
					marks={marks}
					defaultValue={currentRange}
					trackStyle={trackStyle}
					handleStyle={handleStyle}
					dotStyle={{
						backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
					}}
					activeDotStyle={{
						backgroundColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
						borderColor: clearview.PeriodEndRagColorValues[periodEnd.rag],
						borderWidth: 1,
					}}
				/>
			</div>
		</div>
	)
}

PeriodEndProgressBar.propTypes = {
	periodEnd: PropTypes.object.isRequired,
}
