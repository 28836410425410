import React from "react"
import PropTypes from "prop-types"

import { multiSortCaret, StopPropagation, Icon } from "../../../../components/@Clearview"

export default class VouchingItemsSort extends React.Component {
	static propTypes = {
		sortSpec: PropTypes.array, // [{propName: "vendorName", order: "asc"}, {propName: "date", order: "desc"}]
		keys: PropTypes.array,
		onSpecChanged: PropTypes.func,
		onSelectAll: PropTypes.func,
	}

	sortBy(oldSortSpec, e) {
		StopPropagation(e)
		const propName = e.target.name
		const oldProp1 = oldSortSpec[0]

		const sortSpec =
			oldProp1?.propName === propName
				? [{ propName, order: oldProp1?.order === "desc" ? "asc" : "desc" }, oldSortSpec[1]].filter(it => it)
				: [{ propName, order: "asc" }, oldSortSpec[0]].filter(it => it)

		this.props.onSpecChanged(sortSpec)
	}

	render() {
		const oldSortSpec = this.props.sortSpec
		const sortProps = this.props.sortSpec?.reduce((prev, it, idx) => ({ ...prev, [it.propName]: { order: it.order, priority: idx + 1 } }), {})
		const keys = this.props.keys || ["index", "status", "vendorName", "date", "narrative", "ref", "total"]

		return (
			<div className="d-flex-row justify-content-between multi-sort-select">
				{this.props.onSelectAll && (
					<button className="tight" key="selectAll" name="selectAll" onClick={this.props.onSelectAll} title="Select all">
						{Icon.selectAll}
					</button>
				)}
				{keys.includes("index") && (
					<button className="tight" key="index" name="index" onClick={e => this.sortBy(oldSortSpec, e)} title="Sort by Document number in file">
						# {multiSortCaret(sortProps?.index)}
					</button>
				)}
				{keys.includes("status") && (
					<button className="tight" key="status" name="status" onClick={e => this.sortBy(oldSortSpec, e)} title="Sort by Status">
						Status {multiSortCaret(sortProps?.status)}
					</button>
				)}
				{keys.includes("vendorName") && (
					<button className="tight" key="vendorName" name="vendorName" onClick={e => this.sortBy(oldSortSpec, e)} title="Sort by Vendor Name">
						Supplier {multiSortCaret(sortProps?.vendorName)}
					</button>
				)}
				{keys.includes("date") && (
					<button className="tight" key="date" name="date" onClick={e => this.sortBy(oldSortSpec, e)} title="Sort by Date">
						Date {multiSortCaret(sortProps?.date)}
					</button>
				)}
				{keys.includes("narrative") && (
					<button className="tight" key="narrative" name="narrative" onClick={e => this.sortBy(oldSortSpec, e)} title="Sort by Narrative">
						Narrative {multiSortCaret(sortProps?.narrative)}
					</button>
				)}
				{keys.includes("ref") && (
					<button className="tight" key="ref" name="ref" onClick={e => this.sortBy(oldSortSpec, e)} title="Sort by Reference Number">
						Ref. {multiSortCaret(sortProps?.ref)}
					</button>
				)}
				{keys.includes("total") && (
					<button className="tight" key="total" name="total" onClick={e => this.sortBy(oldSortSpec, e)} title="Sort by Total">
						Amount {multiSortCaret(sortProps?.total)}
					</button>
				)}
			</div>
		)
	}
}
