import React from "react"

import { Navigate } from "react-router-dom"

import _ from "lodash"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { TemplateCard } from "../components/TemplateCard"
import { TemplateStages } from "../components/TemplateStages"

import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"
import { Spinner } from "../../../components/Spinner"

import { TemplateContext } from "../TemplateContext"
import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

import { Button, Container, Row, Col, Card, CardHeader, CardBody, CardFooter } from "../../../../components"

import { HeaderMain } from "../../../components/HeaderMain"

class TemplateDetails extends React.Component {
	static propTypes = {
		templates: PropTypes.array.isRequired,
		template: PropTypes.object.isRequired,
		phases: PropTypes.array.isRequired,
		redirectTo: PropTypes.string,
		onRedirected: PropTypes.func.isRequired,
		doUpdate: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.newId = 0

		this.toggleEditing = this.toggleEditing.bind(this)
		this.onChange = this.onChange.bind(this)

		this.onSave = this.onSave.bind(this)
		this.onCancel = this.onCancel.bind(this)

		this.state = {
			isEditing: false,
			isChanged: false,
			template: _.cloneDeep(props.template),

			toggleEditing: this.toggleEditing,
			onChange: this.onChange,
		}
	}

	toggleEditing() {
		this.setState(prevState => ({
			isEditing: !prevState.isEditing,
		}))
	}

	onChange(eventOrType, stage, condition) {
		const template = this.state.template
		let oldSequence
		let newSequence

		switch (eventOrType.target ? eventOrType.target.type : eventOrType) {
			case "addStage":
				template.stages.push({
					id: -template.stages.length,
					sequence: template.stages.length + 1,
					isInHouse: false,
					name: "New Stage",
					folderName: null,
					plannedDays: 0,
					warningDays: 0,
					conditions: [],
				})
				break

			case "removeStage":
				template.stages = template.stages.filter(it => it.id !== stage.id).map((it, idx) => ({ ...it, sequence: idx + 1 }))
				break

			case "moveUpStage":
				oldSequence = stage.sequence
				newSequence = stage.sequence - 1

				template.stages = _.sortBy(
					template.stages.map(s => {
						if (s.id === stage.id) s.sequence = newSequence
						else if (s.sequence === newSequence) s.sequence = oldSequence
						return s
					}),
					it => it.sequence
				)
				break

			case "moveDownStage":
				oldSequence = stage.sequence
				newSequence = stage.sequence + 1
				template.stages = _.sortBy(
					template.stages.map(s => {
						if (s.id === stage.id) s.sequence = newSequence
						else if (s.sequence === newSequence) s.sequence = oldSequence
						return s
					}),
					it => it.sequence
				)
				break

			case "addCondition":
				this.newId--
				template.stages = template.stages.map(it =>
					it.id !== stage.id
						? it
						: {
								...it,
								conditions: it.conditions.concat([
									{
										id: this.newId,
										sequence: it.conditions.length + 1,
										name: "Condition...",
									},
								]),
						  }
				)
				break

			case "removeCondition":
				template.stages = template.stages.map(it =>
					it.id !== stage.id
						? it
						: {
								...it,
								conditions: it.conditions.filter(it => it.id !== condition.id).map((it, idx) => ({ ...it, sequence: idx + 1 })),
						  }
				)
				break

			case "moveUpCondition":
				oldSequence = condition.sequence
				newSequence = condition.sequence - 1

				template.stages = template.stages.map(s =>
					s.id !== stage.id || condition.sequence === 1
						? s
						: {
								...s,
								conditions: _.sortBy(
									s.conditions.map((c, idx) => {
										if (c.id === condition.id) c.sequence = newSequence
										else if (c.sequence === newSequence) c.sequence = oldSequence
										return c
									}),
									it => it.sequence
								),
						  }
				)
				break

			case "moveDownCondition":
				oldSequence = condition.sequence
				newSequence = condition.sequence + 1

				template.stages = template.stages.map(s =>
					s.id !== stage.id || condition.sequence === s.conditions.length
						? s
						: {
								...s,
								conditions: _.sortBy(
									s.conditions.map((c, idx) => {
										if (c.id === condition.id) c.sequence = newSequence
										else if (c.sequence === newSequence) c.sequence = oldSequence
										return c
									}),
									it => it.sequence
								),
						  }
				)
				break

			case "editStage":
				//state had already been updated
				break

			case "editCondition":
				//state had already been updated
				break

			case "next":
				template.next = _.find(this.props.templates, it => it.id === parseInt(eventOrType.target.value))
				break

			case "invoiceAfterStage":
				template.invoiceAfterStage = _.find(template.stages, it => it.id === parseInt(eventOrType.target.value))
				template.invoiceAfterStageId = template.invoiceAfterStage?.id
				break

			case "number":
				template[eventOrType.target.name] = parseInt(eventOrType.target.value)
				break

			default:
				template[eventOrType.target.name] = eventOrType.target.value
				break
		}

		this.setState(prevState => ({
			template: template,
			isChanged: true,
		}))
	}

	onSave() {
		this.props.doUpdate(this.state.template)
		this.setState({
			isEditing: false,
			isChanged: false,
		})
	}

	onCancel() {
		this.setState({
			template: _.cloneDeep(this.props.template),
			isEditing: false,
			isChanged: false,
		})
	}

	render() {
		const { template, isEditing, isChanged } = this.state

		if (this.props.redirectTo) {
			this.props.onRedirected()
			return <Navigate push to={`/template/${this.props.redirectTo}`} />
		}

		if (!template) return <Spinner></Spinner>

		return (
			<TemplateContext.Provider value={this.state}>
				<Container className={template.isBusy ? "isBusy" : ""}>
					{template.isBusy && <Spinner key="spinner"></Spinner>}
					<Container className="d-flex">
						<HeaderMain
							className="mb-5 mt-0 flex-1"
							superTitle={template.customer.name}
							title={
								<span className="text-primary">
									{clearview.Icon.template}
									{`${template.reference}: ${template.name}`}
								</span>
							}
							actions={
								this.props.user.isInHouse &&
								clearview.User.IsAdmin(this.props.user) && (
									<EditSaveOrCancel
										isEditing={isEditing}
										isChanged={isChanged}
										toggleEditing={this.toggleEditing}
										onSave={this.onSave}
										onCancel={this.onCancel}
									/>
								)
							}
						/>
					</Container>

					<Row>
						<Col lg={3}>
							<TemplateCard allTemplates={this.props.templates} onChange={this.onChange} />
						</Col>
						<Col lg={9}>
							<Card>
								<CardHeader className="h6 d-flex">
									<div className="align-self-center flex-1">{clearview.Icon.stage} Stages</div>
								</CardHeader>
								<CardBody>
									<TemplateStages phases={this.props.phases} />
								</CardBody>
								{isEditing && (
									<CardFooter className="h6 text-right">
										<Button
											hidden={!(template.actions || []).includes("AddStage")}
											outline
											color="primary"
											title="Add new stage..."
											onClick={() => this.onChange("addStage")}
										>
											{clearview.Icon.add}
										</Button>
									</CardFooter>
								)}
							</Card>
						</Col>
					</Row>
				</Container>
			</TemplateContext.Provider>
		)
	}
}

const mapStateToProps = state => {
	const newTemplate = ACTIONS.getSubState(state, `clientsReducer\\${ACTIONS.TEMPLATE_CREATED}`)
	var parts = clearview.PathParts(window.location.pathname.split("/"))

	if (newTemplate && newTemplate.customer.reference !== parts[2] && newTemplate.reference !== parts[3])
		return {
			redirectTo: `${newTemplate.customer.reference}/${newTemplate.reference}`,
			template: {},
		}

	const customer = ACTIONS.getSubState(state, "clientsReducer\\templates").dict[parts[2]]
	const template = _.find(customer.templates, it => it.reference === parts[3]) || {}

	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
		template: template,
		templates: customer.templates.filter(it => !it.isRetired),
		phases: customer.phases,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onRedirected: () => dispatch({ type: ACTIONS.TEMPLATE_REDIRECTED, payload: {} }),
		doUpdate: template => dispatch({ type: ACTIONS.TEMPLATE_UPDATE, payload: { template: template } }),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDetails)
