import React from "react"
import { useLoaderData } from "react-router-dom"

import PropTypes from "prop-types"
import { connect } from "react-redux"
import { PeriodEndsContext } from "../PeriodEndsContext"

import StagesTable from "../components/StagesTable"

import { PeriodEndCopyUrl } from "./PeriodEndCopyUrl"
import { PeriodEndProgressBar } from "../components/PeriodEndProgressBar"
import { PeriodEndCard } from "../../PeriodEnds/components/PeriodEndCard"
import { PeriodEndUnread } from "../../PeriodEnds/components/PeriodEndUnread"
import { PeriodEndFavourite } from "../../PeriodEnds/components/PeriodEndFavourite"
import { PeriodEndFolders } from "../../PeriodEnds/components/PeriodEndFolders"
import { PropertyCard } from "../../Properties/components/PropertyCard"
import { TemplateCard } from "../../Templates/components/TemplateCard"
import { TemplateContext } from "../../Templates/TemplateContext"
import { Spinner } from "../../../components/Spinner"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

import { Container, Row, Col, Card, CardHeader, CardBody } from "./../../../../components"

import { HeaderMain } from "../../../components/HeaderMain"

class PeriodEndDetailsComponent extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired,
		redirectTo: PropTypes.string,
		onRedirected: PropTypes.func.isRequired,
		doRefresh: PropTypes.func.isRequired,
		doPeriodEndRead: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		if (props.periodEnd.isUnread) {
			this.props.doPeriodEndRead(this.props.periodEnd)
			props.periodEnd.isUnread = false
		}

		this.state = {}
	}

	render() {
		const { periodEnd, user } = this.props

		if (!periodEnd.id) return <Spinner />

		return (
			<PeriodEndsContext.Provider value={this.state}>
				<TemplateContext.Provider value={periodEnd}>
					<Container className={periodEnd.isBusy ? "isBusy" : ""}>
						{periodEnd.isBusy && <Spinner key="spinner"></Spinner>}
						<HeaderMain
							superTitle={
								<span>
									<PeriodEndUnread periodEnd={periodEnd} />
									{periodEnd.reference}
									<sup>
										<PeriodEndFavourite periodEnd={periodEnd} />
									</sup>
								</span>
							}
							superActions={<PeriodEndCopyUrl />}
							title={
								<span className="text-primary">
									{clearview.Icon.periodEnd}
									{periodEnd.property?.name}
								</span>
							}
							subTitle={(periodEnd.template || {}).name}
							className="mb-4 mt-0"
							onRefresh={() => this.props.doRefresh(periodEnd)}
						/>
						<Row>
							<Col lg={3}>
								<PeriodEndCard periodEnd={periodEnd} />
								<PropertyCard property={periodEnd.property} isCompact={true} />
								<TemplateCard />
							</Col>
							<Col lg={9}>
								<Card>
									<CardHeader className="d-flex">
										<PeriodEndProgressBar user={user} periodEnd={periodEnd} className="d-flex flex-1 mr-3" />
									</CardHeader>
									<CardBody>
										<StagesTable periodEnd={periodEnd} />
										{periodEnd.status !== "Open" && <PeriodEndFolders periodEnd={periodEnd} />}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</TemplateContext.Provider>
			</PeriodEndsContext.Provider>
		)
	}
}

function PeriodEndDetails({ user, redirectTo, onRedirected, doRefresh, doPeriodEndRead }) {
	const periodEnd = useLoaderData()

	return (
		<PeriodEndDetailsComponent
			periodEnd={periodEnd}
			user={user}
			redirectTo={redirectTo}
			onRedirected={onRedirected}
			doRefresh={doRefresh}
			doPeriodEndRead={doPeriodEndRead}
		/>
	)
}

const mapStateToProps = state => ({
	user: state.userReducer.user || clearview.User.UnauthenticatedUser,
})

const mapDispatchToProps = dispatch => {
	return {
		onRedirected: () => dispatch({ type: ACTIONS.PERIODEND_REDIRECTED, payload: {} }),
		doRefresh: periodEnd => dispatch({ type: ACTIONS.PERIODEND_FETCH, payload: { periodEnd: periodEnd } }),
		doPeriodEndRead: periodEnd => dispatch({ type: ACTIONS.PERIODEND_UNREAD_UPDATE, periodEnd: periodEnd, isUnread: false }),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodEndDetails)
