import { call, put, takeLatest } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

function* addFavourite(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const user = yield call(Api.addFavourite, action.periodEnd)

		yield put({
			type: ACTIONS.FAVOURITE_ADDED,
			user: user,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.FAVOURITE_ADD_FAILED,
			message: e.message,
		})
	}
}

function* removeFavourite(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const user = yield call(Api.removeFavourite, action.periodEnd)

		yield put({
			type: ACTIONS.FAVOURITE_REMOVED,
			user: user,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.FAVOURITE_ADD_FAILED,
			message: e.message,
		})
	}
}

function* resetFavourite(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const user = yield call(Api.resetFavourite, action.periodEnd)

		yield put({
			type: ACTIONS.FAVOURITE_RESETED,
			user: user,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.FAVOURITE_RESET_FAILED,
			message: e.message,
		})
	}
}

export default function* favouritesSaga() {
	yield takeLatest(ACTIONS.FAVOURITE_ADD, addFavourite)
	yield takeLatest(ACTIONS.FAVOURITE_REMOVE, removeFavourite)
	yield takeLatest(ACTIONS.FAVOURITE_RESET, resetFavourite)
}
