import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"

import { Container, Row, Col } from "../../../components"

import { HeaderMain } from "../../components/HeaderMain"
import { Spinner } from "../../components/Spinner"

import { OpenStagesTable } from "./components/OpenStagesTable"

class StagesDashboardComponent extends Component {
	static propTypes = {
		periodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
		isBusy: PropTypes.bool.isRequired,
		lastUpdated: PropTypes.number,
	}

	componentDidMount() {
		// this.props.doRefresh()
	}

	render() {
		const periodEnds = this.props.periodEnds.filter(it => it.currentStage)

		return (
			<React.Fragment>
				<Container className={this.props.isBusy ? "isBusy" : ""}>
					{this.props.isBusy && <Spinner key="spinner"></Spinner>}
					<HeaderMain
						title={<span className="text-primary">{clearview.Icon.stages}Open Stages</span>}
						className="mb-4 mt-0"
						onRefresh={() => this.props.doRefresh()}
					/>
					<Row>
						<Col lg={12}>
							<OpenStagesTable periodEnds={periodEnds} user={this.props.user} maxWidth={700} />
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		)
	}
}

function StagesDashboard({ periodEnds, user, isBusy, lastUpdated, preFilterContext, doRefresh }) {
	preFilterContext.checkIfPreFilterLinked()
	return <StagesDashboardComponent periodEnds={periodEnds} user={user} isBusy={isBusy} lastUpdated={lastUpdated} doRefresh={doRefresh} />
}

const mapStateToProps = state => {
	const subState = ACTIONS.getSubState(state, "clientsReducer\\periodEnds")
	return {
		...subState,
		periodEnds: ACTIONS.dictionaryToArray(subState.dict),
		user: state.userReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	doRefresh: () => {
		dispatch({ type: ACTIONS.USER_AUTHENTICATED, payload: {} })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(StagesDashboard)
