import _ from "lodash"

export function AttributeValue(thing, name, mappings = null) {
	const attribute = _.find((thing || {}).attributes || [], it => it.name === name)
	const value = mappings ? mappings[(attribute || {}).value] : attribute ? attribute.value : null
	return value || ""
}

export function YesNoAttributeValue(thing, name) {
	return AttributeValue(thing, name, { Y: "Yes", N: "No" })
}
