import React from "react"

import _ from "lodash"

import PropTypes from "prop-types"

import {
	Alert,
	Table,
	Row,
	Col,
	Button,
	Form,
	FormGroup,
	InputGroupAddon,
	CustomInput,
	Input,
	Media,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	UncontrolledTabs,
	NavItem,
	Nav,
	TabPane,
} from "../../../../components"

import { DragDropContext, Draggable } from "react-beautiful-dnd"
import { ExpenseAvatar } from "./ExpenseAvatar"
import { StrictModeDroppable } from "../../../components/StrictModeDroppable"

import * as Api from "../../../../api/CustomerApi"
import * as clearview from "../../../../components/@Clearview"

import { toISODate, stringToDate } from "clearview-aws-common"

import { validateExpense, getAllTokenSummaries } from "./Parsers"
import { formatValue } from "./VouchingTheme"

const EMPTY_STATE = {
	isOpen: false,
	actions: () => [],
	expense: {},
	zIndex: 1,
	allTokens: [],
	overlappingTokens: [],
	splits: [],
	file: {},
}

export default class ExpenseEdit extends React.Component {
	static propTypes = {
		onCancel: PropTypes.func.isRequired,
		onSave: PropTypes.func.isRequired,
		expense: PropTypes.object,
		property: PropTypes.object.isRequired,
		propertyNames: PropTypes.array.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = EMPTY_STATE

		this.handleChange = this.handleChange.bind(this)
		this.showFile = this.showFile.bind(this)

		this.onDragStart = this.onDragStart.bind(this)
		this.onDragEnd = this.onDragEnd.bind(this)

		this.onOverlapIndexEnter = this.onOverlapIndexEnter.bind(this)
		this.onValueTextClick = this.onValueTextClick.bind(this)

		this.onWarningDismiss = this.onWarningDismiss.bind(this)
		this.copyTokenToClipboard = this.copyTokenToClipboard.bind(this)
	}

	static getDerivedStateFromProps(props, state) {
		if (!props.expense) return EMPTY_STATE

		if (state.isOpen) return null

		const expense = validateExpense({ ...EMPTY_STATE.expense, ..._.cloneDeep(props.expense) }, props.propertyNames)

		// Make values bindable for the form
		expense.ref = expense.ref || ""
		expense.vendorName = expense.vendorName || ""
		expense.narrative = expense.narrative || ""
		expense.total = expense.total || 0

		const allTokens = [...(expense?.summaryFields || []), ...(expense?.lineItemGroups?.flatMap(lig => lig.lineItems) || [])]
		const overlappingTokens = allTokens.filter(it => allTokens.find(token => token.id !== it.id && overlappingValueText(it, token)))

		const area = allTokens.reduce(
			(prev, next) => {
				return {
					top: Math.min(prev.top, next.label?.box.top || prev.top, next.value?.box.top || prev.top),
					left: Math.min(prev.left, next.label?.box.left || prev.left, next.value?.box.left || prev.left),
					bottom: Math.max(
						prev.bottom,
						next.label?.box.top + next.label?.box.height || prev.bottom,
						next.value?.box.top + next.value?.box.height || prev.bottom
					),
					right: Math.max(
						prev.right,
						next.label?.box.left + next.label?.box.width || prev.right,
						next.value?.box.left + next.value?.box.width || prev.right
					),
				}
			},
			{ top: 0.2, left: 0.2, bottom: 0.8, right: 0.8 } // We don't want to zoom in any further than this
		)

		const available = { width: 738, height: 1300 }
		const scale = {
			startingPage: expense.page,
			top: area.top,
			left: area.left,
			hOffset: 5,
			hScale: (available.width - 10) / (area.right - area.left),
			vOffset: 5,
			vScale: (available.height - 10) / (area.bottom - area.top),
		}

		return {
			isOpen: true,
			actions: props.actions || (() => []),
			expense,
			allTokens,
			overlappingTokens,
			scale,
			splits: [],
			file: {
				name: clearview.AfterLast(props.expense.s3, "/"),
				type: clearview.AfterLast(props.expense.s3, "."),
			},
		}
	}

	showFile(expense) {
		Api.fetchPresignedUrl(expense.s3.substring(expense.s3.indexOf("/", 6) + 1)).then(resp => {
			this.setState({
				file: {
					name: clearview.AfterLast(expense.s3, "/"),
					type: clearview.AfterLast(expense.s3, "."),
					url: `${resp.presignedUrl}#page=${expense.page}`,
				},
			})
		})
	}

	handleChange(e) {
		let typedValue = e.target.value
		if (e.type === "blur" && e.target.type === "number") typedValue = parseInt(e.target.value)
		if (e.type === "blur" && e.target.name === "total") typedValue = clearview.parseCurrency(e.target.value)

		this.setState(prevState => ({
			expense: validateExpense(
				{
					...prevState.expense,
					[e.target.name]: typedValue,
				},
				this.props.propertyNames
			),
		}))
	}

	handleSplit() {
		const splits = this.state.splits || []

		splits.push({ page: this.state.expense.page, toPage: this.state.expense.toPage, ref: this.state.expense.ref || `${splits.length + 2}`, total: 0 })

		this.setState({ splits })
	}

	handleSplitChange(e) {
		const expense = this.state.expense
		const splits = this.state.splits

		const parts = e.target.name.split("_")
		const idx = parseInt(parts[1])

		let typedValue = e.target.value
		if (e.type === "blur" && e.target.type === "number") typedValue = parseInt(e.target.value)
		if (e.type === "blur" && parts[2] === "total") typedValue = clearview.parseCurrency(e.target.value)

		splits[idx][parts[2]] = typedValue

		this.setState({
			expense,
			splits,
		})
	}

	onDragStart(e) {
		this.setState({ draggedText: clearview.AfterFirst(e.draggableId, "|") })
	}

	onDragEnd(result) {
		const { destination } = result

		// dropped outside the list
		if (!destination) {
			this.setState({ draggedText: undefined })
			return
		}

		let draggedText = this.state.draggedText
		switch (destination.droppableId) {
			case "page":
				draggedText = parseInt(draggedText)
				break
			case "toPage":
				draggedText = parseInt(draggedText)
				break
			case "total":
				draggedText = clearview.parseCurrency(draggedText)
				break
			case "date":
				draggedText = toISODate(stringToDate(draggedText))
				break
			default:
				break
		}

		this.setState(prevState => ({
			expense: validateExpense({ ...prevState.expense, [destination.droppableId]: draggedText }, this.props.property),
			draggedText: undefined,
		}))
	}

	onOverlapIndexEnter(e) {
		this.setState(prevState => {
			for (let it of prevState.allTokens) {
				it.isSelected = it.id === e.target.id
				it.zIndex = it.id === e.target.id ? (e.ctrlKey ? 1 : prevState.zIndex + 1) : it.zIndex
			}

			return {
				zIndex: prevState.zIndex + (e.ctrlKey ? 0 : 1),
			}
		})
	}

	onValueTextClick(e) {
		clearview.copyToClipboard(e.target.textContent)
		this.onOverlapIndexEnter(e)
	}

	onWarningDismiss() {
		this.setState(prevState => ({
			expense: validateExpense({ ...prevState.expense, warning: { ...prevState.expense.warning, severity: "warning" } }, this.props.propertyNames),
			draggedText: undefined,
		}))
	}

	async copyTokenToClipboard(e, it) {
		const text = JSON.stringify(_.pick(it, ["type", "label", "value"]))
		clearview.copyToClipboard(text).then(() => clearview.ShowToast("info", <p>Copied</p>, 1500))
	}

	render() {
		const { isOpen, expense, allTokens, overlappingTokens, splits, file, scale } = this.state

		const pageNumbers = _.uniq(allTokens.map(it => it.pageNumber)).sort((a, b) => (a < b ? -1 : 1))
		const allTokenSummaries = getAllTokenSummaries(expense)

		if (isOpen && !file.url) this.showFile(expense)

		return (
			<Modal id="expense-edit" isOpen={isOpen} style={{ minWidth: 1200, minHeight: 800 }}>
				<Form onSubmit={this.handleSubmit}>
					<DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
						<ModalHeader tag="h6">
							<div className="d-flex-row justify-content-start align-items-center">
								<ExpenseAvatar key="expenseEditAvatar" expense={expense}></ExpenseAvatar>
								<span>Edit Expense</span>
							</div>
						</ModalHeader>

						<ModalBody>
							<Row>
								<Col md={4}>
									<FormGroup row>
										<StrictModeDroppable droppableId="vendorName" isDropDisabled={false}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={getDropItemClass(snapshot)}
												>
													<InputGroupAddon addonType="prepend">Vendor</InputGroupAddon>
													<CustomInput
														type="text"
														key="vendorName"
														id="vendorName"
														name="vendorName"
														className="form-control"
														invalid={!expense.vendorName}
														value={expense.vendorName}
														onChange={e => this.handleChange(e)}
													></CustomInput>
													<div style={{ display: "none" }}>{provided.placeholder}</div>
												</div>
											)}
										</StrictModeDroppable>
									</FormGroup>

									{expense.index && (
										<FormGroup row>
											<h6 className="ml-1 w-100">Document #{expense.index} in file:</h6>
											<StrictModeDroppable droppableId="page" isDropDisabled={false}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={getDropItemClass(snapshot)}
														style={{ width: "50%" }}
													>
														<InputGroupAddon addonType="prepend">Page</InputGroupAddon>
														<CustomInput
															type="number"
															key="page"
															id="page"
															name="page"
															className="form-control currency"
															value={expense.page}
															onChange={e => this.handleChange(e)}
															onBlur={e => this.handleChange(e)}
														></CustomInput>
														<div style={{ display: "none" }}>{provided.placeholder}</div>
													</div>
												)}
											</StrictModeDroppable>
											<StrictModeDroppable droppableId="toPage" isDropDisabled={false}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={getDropItemClass(snapshot)}
														style={{ width: "50%" }}
													>
														<InputGroupAddon addonType="prepend">to page</InputGroupAddon>
														<CustomInput
															type="number"
															key="toPage"
															id="toPage"
															name="toPage"
															className="form-control currency"
															value={expense.toPage}
															onChange={e => this.handleChange(e)}
															onBlur={e => this.handleChange(e)}
														></CustomInput>
														<div style={{ display: "none" }}>{provided.placeholder}</div>
													</div>
												)}
											</StrictModeDroppable>
										</FormGroup>
									)}

									<FormGroup row>
										<StrictModeDroppable droppableId="ref" isDropDisabled={false}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={getDropItemClass(snapshot)}
												>
													<InputGroupAddon addonType="prepend">Invoice Ref.</InputGroupAddon>
													<CustomInput
														type="text"
														key="ref"
														id="ref"
														name="ref"
														className="form-control"
														invalid={!expense.ref}
														value={expense.ref}
														onChange={e => this.handleChange(e)}
													></CustomInput>
													<div style={{ display: "none" }}>{provided.placeholder}</div>
												</div>
											)}
										</StrictModeDroppable>
									</FormGroup>
									<FormGroup row>
										<StrictModeDroppable droppableId="narrative" isDropDisabled={false}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={getDropItemClass(snapshot)}
												>
													<InputGroupAddon addonType="prepend">Narrative</InputGroupAddon>
													<Input
														type="textarea"
														rows={4}
														key="narrative"
														id="narrative"
														name="narrative"
														className="form-control"
														value={expense.narrative}
														onChange={e => this.handleChange(e)}
													></Input>
													<div style={{ display: "none" }}>{provided.placeholder}</div>
												</div>
											)}
										</StrictModeDroppable>
									</FormGroup>
									<FormGroup row>
										<StrictModeDroppable droppableId="date" isDropDisabled={false}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={getDropItemClass(snapshot)}
													style={{ width: "50%" }}
												>
													<InputGroupAddon addonType="prepend">Date</InputGroupAddon>
													<CustomInput
														type="date"
														key="date"
														id="date"
														name="date"
														className="form-control"
														invalid={!expense.date}
														value={expense.date}
														onChange={e => this.handleChange(e)}
													></CustomInput>
													<div style={{ display: "none" }}>{provided.placeholder}</div>
												</div>
											)}
										</StrictModeDroppable>

										<StrictModeDroppable droppableId="total" isDropDisabled={["A", "M"].includes(expense.status)}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={getDropItemClass(snapshot)}
													style={{ width: "50%" }}
												>
													<InputGroupAddon className="ml4" addonType="prepend">
														Amount
													</InputGroupAddon>
													<CustomInput
														disabled={["A", "M"].includes(expense.status)}
														type="number"
														key="total"
														id="total"
														name="total"
														className="form-control currency"
														invalid={!expense.total}
														value={clearview.formatCurrencyValue(expense.total)}
														onChange={e => this.handleChange(e)}
														onBlur={e => this.handleChange(e)}
													></CustomInput>
													<div style={{ display: "none" }}>{provided.placeholder}</div>
												</div>
											)}
										</StrictModeDroppable>
									</FormGroup>

									{expense.warning && (
										<FormGroup row>
											<Alert color={expense.warning.severity}>
												<Media>
													<Media left middle className="mr-3">
														<span className="fa-stack fa-lg">
															<i className="fa fa-circle fa-fw fa-stack-2x alert-bg-icon"></i>
															{expense.warning.severity === "danger" && (
																<i className="fa fa-exclamation-circle fa-stack-2x fa-inverse alert-icon"></i>
															)}
															{expense.warning.severity === "warning" && (
																<i className="fa fa-warning fa-stack-1x fa-inverse alert-icon"></i>
															)}
															{expense.warning.severity === "info" && (
																<i className="fa fa-info fa-stack-1x fa-inverse alert-icon"></i>
															)}
														</span>
													</Media>
													<Media body>
														<h6 className="alert-heading mb-1">{_.startCase(expense.warning.severity)}</h6>
														{expense.warning.message}
														{expense.warning.severity === "danger" && (
															<div className="mt-2">
																<Button color="danger" onClick={e => this.onWarningDismiss()}>
																	Dismiss
																</Button>{" "}
															</div>
														)}
													</Media>
												</Media>
											</Alert>
										</FormGroup>
									)}

									<FormGroup row>
										{!!splits.length && (
											<Table className="edit-expense-split-table" style={{ maxHeight: 200, overflowY: "scroll" }}>
												<thead>
													<tr>
														<th>Page</th>
														<th>to Page</th>
														<th>Ref</th>
														<th>Total</th>
													</tr>
												</thead>
												<tbody>
													{splits.map((split, idx) => (
														<tr key={`split_${idx}`}>
															<td>
																<CustomInput
																	type="number"
																	key={`split_${idx}_page`}
																	id={`split_${idx}_page`}
																	name={`split_${idx}_page`}
																	placeholder="Page"
																	className="form-control page-no"
																	value={split.page}
																	onChange={e => this.handleSplitChange(e)}
																	onBlur={e => this.handleSplitChange(e)}
																></CustomInput>
															</td>
															<td>
																<CustomInput
																	type="number"
																	key={`split_${idx}_toPage`}
																	id={`split_${idx}_toPage`}
																	name={`split_${idx}_toPage`}
																	placeholder="To Page"
																	className="form-control page-no"
																	value={split.toPage}
																	onChange={e => this.handleSplitChange(e)}
																	onBlur={e => this.handleSplitChange(e)}
																></CustomInput>
															</td>
															<td>
																<CustomInput
																	type="text"
																	id={`split_${idx}_ref`}
																	name={`split_${idx}_ref`}
																	className="form-control"
																	value={split.ref}
																	onChange={e => this.handleSplitChange(e)}
																	onBlur={e => this.handleSplitChange(e)}
																></CustomInput>
															</td>
															<td>
																<CustomInput
																	type="number"
																	id={`split_${idx}_total`}
																	name={`split_${idx}_total`}
																	disabled={["A", "M"].includes(expense.status)}
																	className="form-control currency"
																	invalid={!split.total}
																	value={clearview.formatCurrencyValue(split.total)}
																	onChange={e => this.handleSplitChange(e)}
																	onBlur={e => this.handleSplitChange(e)}
																></CustomInput>
															</td>
														</tr>
													))}
												</tbody>
												<tfoot>
													<tr>
														<th colSpan={3} style={{ textAlign: "right" }}>
															Total:
														</th>
														<th style={{ textAlign: "right" }}>{formatValue(expense.total + _.sumBy(splits, it => it.total))}</th>
													</tr>
												</tfoot>
											</Table>
										)}
										<Button color="primary" disabled={["A", "M"].includes(expense.status)} onClick={e => this.handleSplit()}>
											Split...
										</Button>
									</FormGroup>
								</Col>

								<Col md={8}>
									<UncontrolledTabs initialActiveTabId="tabFields">
										<Nav pills className="mb-0 flex-column flex-md-row mt-4 mt-lg-0">
											<NavItem>
												<UncontrolledTabs.NavLink tabId="tabFields">Fields</UncontrolledTabs.NavLink>
											</NavItem>
											<NavItem>
												<UncontrolledTabs.NavLink tabId="tabImage">Image</UncontrolledTabs.NavLink>
											</NavItem>
											<NavItem>
												<UncontrolledTabs.NavLink tabId="tabTokens">Tokens</UncontrolledTabs.NavLink>
											</NavItem>
											<NavItem>
												<UncontrolledTabs.NavLink tabId="tabRaw">Raw</UncontrolledTabs.NavLink>
											</NavItem>
										</Nav>
										<UncontrolledTabs.TabContent>
											<TabPane tabId="tabFields">
												<div id="token-index" className="d-flex-row align-items-stretch">
													<span hidden={!overlappingTokens?.length}>Overlapping:</span>
													{overlappingTokens?.map((it, idx) => (
														<div
															key={`i-${idx}`}
															id={it.id}
															className={it.isSelected ? "value selected" : "value"}
															onMouseEnter={e => this.onOverlapIndexEnter(e)}
														>
															{idx + 1}
														</div>
													))}
												</div>
												<StrictModeDroppable droppableId="tokens" isDropDisabled={false}>
													{provided => (
														<div
															ref={provided.innerRef}
															className="expense-edit-tokens"
															style={{ position: "relative", height: 600, width: 750, overflow: "hidden", overflowY: "scroll" }}
														>
															<div style={{ display: "none" }}>{provided.placeholder}</div>
															{pageNumbers.map(it => (
																<div key={`p-${it}`}>
																	<span
																		className="page"
																		style={positionStyle(
																			it,
																			{ box: { top: scale.top, left: scale.left, width: 1 } },
																			scale
																		)}
																	>
																		P{it}
																	</span>
																</div>
															))}
															{allTokens.map((it, idx) => (
																<div key={`tl-${it.id}`}>
																	{it.label && (
																		<span className="label" style={positionStyle(it.pageNumber, it.label, scale)}>
																			{it.label.text}
																		</span>
																	)}
																	{it.value.text && (
																		<div
																			id={it.id}
																			className={it.isSelected ? "value selected" : "value"}
																			style={{ zIndex: it.zIndex, ...positionStyle(it.pageNumber, it.value, scale) }}
																			title={it.value.text}
																			onClick={e => this.onValueTextClick(e)}
																		>
																			<Draggable
																				key={`tv-${it.id}`}
																				draggableId={`sfd-${it.id}|${it.value.text}`}
																				index={idx}
																			>
																				{(provided, draggableSnapshot) => (
																					<React.Fragment>
																						<span
																							ref={provided.innerRef}
																							{...provided.draggableProps}
																							{...provided.dragHandleProps}
																							className={getDragItemClass(draggableSnapshot)}
																						>
																							{it.value.text}
																						</span>
																						{draggableSnapshot.isDragging && (
																							<span className="value">{it.value.text}</span>
																						)}
																					</React.Fragment>
																				)}
																			</Draggable>
																		</div>
																	)}
																</div>
															))}
														</div>
													)}
												</StrictModeDroppable>
											</TabPane>

											<TabPane tabId="tabImage">
												{file && (
													<center style={{ fontFamily: "sans-serif" }}>
														<a
															href={file?.url}
															target={file.name}
															rel="noreferrer noopener"
															className="text-primary"
															title="Open file in new Tab"
														>
															{file.name}
														</a>
													</center>
												)}
												<div style={{ flexGrow: 1, minHeight: 630 }}>
													{file?.type === "pdf" && (
														<object data={file?.url} type="application/pdf" width="750" height="630">
															Loading...
														</object>
													)}
													{![undefined, "pdf", "json"].includes(file?.type) && (
														<img
															alt="Loading..."
															className="flex-1"
															src={file?.url}
															style={{ width: 750, height: 630, objectFit: "scale-down" }}
														/>
													)}
												</div>
											</TabPane>

											<TabPane tabId="tabTokens" id="tab-tokens" style={{ maxHeight: 650 }}>
												<table>
													<thead>
														<tr>
															<th colSpan={3}>Summary Fields</th>
														</tr>
														<tr>
															<th>Type</th>
															<th>Label</th>
															<th colSpan={2}>Value</th>
														</tr>
													</thead>
													<tbody>
														{allTokenSummaries.summaryFields.map(it => (
															<tr key={it.id}>
																<td>{it.type}</td>
																<td>{it.label}</td>
																<td>{it.value}</td>
																<td>
																	<span className="copy" onClick={e => this.copyTokenToClipboard(e, it)}>
																		{clearview.Icon.copy}
																	</span>
																</td>
															</tr>
														))}
													</tbody>
													<thead>
														<tr>
															<th colSpan={3}>All Line Items</th>
														</tr>
														<tr>
															<th>Type</th>
															<th>Label</th>
															<th>Value</th>
														</tr>
													</thead>
													<tbody>
														{allTokenSummaries.lineItems.map(it => (
															<tr key={it.id}>
																<td>{it.type}</td>
																<td>{it.label}</td>
																<td>{it.value}</td>
															</tr>
														))}
													</tbody>
												</table>
											</TabPane>

											<TabPane tabId="tabRaw">
												<pre style={{ maxHeight: 650 }}>{JSON.stringify(expense, null, 2)}</pre>
											</TabPane>
										</UncontrolledTabs.TabContent>
									</UncontrolledTabs>
								</Col>
							</Row>
						</ModalBody>

						<ModalFooter>
							<Button color="link" className="text-primary" onClick={e => this.props.onCancel()}>
								Cancel
							</Button>
							<Button color="default" className="text-primary" onClick={e => this.props.onSave(expense, splits)}>
								Save
							</Button>
						</ModalFooter>
					</DragDropContext>
				</Form>
			</Modal>
		)
	}
}

const getDropItemClass = snapshot => ({ false: "input-group not-droppable", true: "input-group droppable" }[snapshot.isDraggingOver])

const getDragItemClass = snapshot => ({ false: "", true: "dragging" }[snapshot.isDragging])

const positionStyle = (pageNumber, tokenPart, scale) => ({
	position: "absolute",
	top: (pageNumber - scale.startingPage) * scale.hScale + scale.vOffset + (tokenPart.box.top - scale.top) * scale.vScale,
	left: scale.hOffset + (tokenPart.box.left - scale.left) * scale.hScale,
	width: tokenPart.box.width * scale.hScale,
})

const overlappingValueText = (t1, t2) =>
	t1.pageNumber === t2.pageNumber &&
	t1.value?.text &&
	t2.value?.text &&
	t1.value.box.left < t2.value.box.left + t2.value.box.width &&
	t1.value.box.left + t1.value.box.width > t2.value.box.left &&
	t1.value.box.top < t2.value.box.top + t2.value.box.height &&
	t1.value.box.top + t1.value.box.height > t2.value.box.top
