import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as clearview from "../../components/@Clearview"

let IsNotifiedIcon = props => {
	const Tag = props.tag || "sup"

	if (props.user && props.user.isNotified && (clearview.User.IsAdmin(props.currentUser) || props.currentUser.username === props.user.username))
		return (
			<Tag className={props.className || "text-primary"} hidden={props.hidden}>
				<i className="fa fa-fw fa-envelope-o" title="Is Notified of Changes via Email"></i>
			</Tag>
		)
}

IsNotifiedIcon.propTypes = {
	currentUser: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	className: PropTypes.string,
	tag: PropTypes.string,
	hidden: PropTypes.bool,
}

const mapStateToProps = state => {
	return {
		currentUser: state.userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

IsNotifiedIcon = connect(mapStateToProps)(IsNotifiedIcon)

export { IsNotifiedIcon }
