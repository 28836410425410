import React from "react"

import { Row, Col, Accordion } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"

export const Concepts = () => (
	<React.Fragment>
		<Row>
			<Col lg={4}>
				<BusinessHierarchy />
			</Col>

			<Col lg={4}>
				<UsersAndLogins />
				<Teams />
			</Col>
		</Row>
	</React.Fragment>
)

const BusinessHierarchy = () => (
	<Accordion className="mb-3">
		<Accordion.Header tag="h6">{clearview.Icon.Business.Client} Business Hierarchy</Accordion.Header>
		<Accordion.Body>
			<ul>
				<li>
					Customer
					<ul>
						<li>
							Client A
							<ul>
								<li>Property A1</li>
								<li>Property A2</li>
								<li>Property A3</li>
							</ul>
						</li>
						<li>
							Managing Agent B
							<ul>
								<li>Landlord C</li>
								<ul>
									<li>Property C1</li>
									<li>Property C2</li>
									<li>Property C3</li>
								</ul>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
			<p>
				In Clearview, a <b>Customer</b> is the accounting firm responsible for the production of Period End accounts for its <b>Clients</b>,{" "}
				<b>Managing Agents</b>, <b>Landlords</b> and <b>Properties</b>. Each of these is a diffrent <b>Business</b> in the Clearview system.
			</p>
			<p>
				Individual Customer users ("In-House" users) and teams will be assigned responsibility for the Period Ends for specific Clients, Managing
				Agents, Landlords and Properties.
			</p>
			<p>
				Users and teams can also be set up for Clients, Managing Agents and Landlords (all known as "Client" users). These individuals will be
				responsible for the "Client" stages of the Period Ends in their area of the Business, i.e. "Managing Agent B" is responsible for the{" "}
				<em>Client</em> stages of all thier Landlords and their Properties.
			</p>
		</Accordion.Body>
	</Accordion>
)

const UsersAndLogins = () => (
	<Accordion className="mb-3">
		<Accordion.Header tag="h6">{clearview.Icon.Business.User} Users and Logins</Accordion.Header>
		<Accordion.Body>
			<p>
				<b>Users</b> are unique named individuals referenced in the Clearview system. They will have a unique username and will typically have a contact
				email address. Users <em>will not</em> be able to log into the system and view data <em>unless</em> they also have a <b>Login</b>.
			</p>
			<p>
				<b>Logins</b> will be set up for most users. A Login consists of the User's unique username plus a <em>password</em>.
			</p>
			<p>
				For example, a landlord will need to be a <em>user</em> in Clearview so their name is associated with their Property. However, if they leave all
				their Period End work to a <em>Managing Agent</em>, and don't use Clearview to view any reports, they would not need a login.
			</p>
			<p>
				<b>In-House users</b> are users created and managed by the Clearview <em>Customer</em>. In-House users can be assigned as administrators or to
				the In-House teams of any Client, Managing Agent, Landlord or Property.
			</p>
			<p>
				<b>Client users</b> are users created and managed by a Client, Managing Agent, Landlord or Property. Client users can only deal with thier own
				Client stages and perform client specific actions.
			</p>
		</Accordion.Body>
	</Accordion>
)

const Teams = () => (
	<Accordion className="mb-3">
		<Accordion.Header tag="h6">{clearview.Icon.Business.Teams} Teams, Appointees, Scopes &amp; Roles</Accordion.Header>
		<Accordion.Body>
			<h5>Teams</h5>
			<ul>
				<li>
					Customer
					<ul>
						<li>
							Users - this team contains all of the Customer's Users
							<ul>
								<li>In-House User A</li>
								<li>In-House User B</li>
								<li>In-House User C</li>
							</ul>
						</li>
						<li>
							In-House Team - zero or more teams grouping users together who shore a portfolio of Properties, e.g. "Accounting", "Northern Region"
							<ul>
								<li>In-House User B</li>
								<li>In-House User C</li>
							</ul>
						</li>

						<li>
							Client (Managing Agent)
							<ul>
								<li>
									Users - this team contains all of the Client's Users
									<ul>
										<li>Client User 1</li>
										<li>Client User 2</li>
										<li>Client User 3</li>
									</ul>
								</li>
								<li>
									Client Team - zero or more teams grouping users together who shore a portfolio of Properties, e.g. "Surveyors","Eastern
									Region"
									<ul>
										<li>Client User 2</li>
										<li>Client User 3</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
			<p>
				<i>Landlords and individual Properties can also have users and teams that behave in a very similar way to the managing agent teams.</i>
			</p>

			<h6>
				At the <b>Customer</b> level, users are grouped together as follows:
			</h6>

			<dl className="dl-horizontal">
				<dt>
					<b>Users</b>
				</dt>
				<dd>
					This is a list of all the users created by the Customer ("In-House users"). These users will not be visible to the Clienmt unless they are
					also in an <em>In-House Team</em>.
				</dd>
				<dt>
					<b>In-House Teams</b>
				</dt>
				<dd>
					There will usually be one or more teams of In-House users assigned to a Business. These users are reponsible for the In-House stages in the
					Period Ends. In-House team members are visble to the client users.
				</dd>
			</dl>

			<h6>
				At the <b>Business</b> level (e.g. a Client, Managing Agent, Landlord or Property), users are grouped together as follows:
			</h6>
			<dl className="dl-horizontal">
				<dt>
					<b>Users</b>
				</dt>
				<dd>
					This is a list of all the users created for a Client, Managing Agent, Landlord or even Propery ("Client users"). Client users will not be
					visible to businesses beneath them uless they are also in a <em>Client Team</em>.
				</dd>
				<dt>
					<b>Client Teams</b>
				</dt>
				<dd>
					Client teams and their members can have Queries assigned to them. Although a user does not have to be a member of a Client Team in order to
					use the system, most Client Users will be in one or more Client Team.
				</dd>
			</dl>

			<h5>Appointees</h5>
			<p>
				Each Property has, potentially, several teams of "Appointees". These are specific individuals with specific roles for that property, e.g.
				Accountants, Managing Agents, Surveyors (these will often only contain a ingle user):
			</p>
			<ul>
				<li>Property 1</li>
				<ul>
					<li>
						In House Appointees - Managing Agents
						<ul>
							<li>In-House User B</li>
							<li>In-House User C</li>
						</ul>
					</li>
					<li>
						Client Appointees - Surveyors
						<ul>
							<li>Client User E</li>
						</ul>
					</li>
				</ul>
			</ul>

			<h5>Scopes</h5>
			<p>In-house and Client teams can be assigned to specific Properties.</p>

			<h5>Roles:</h5>
			<p>
				Each member of a team has one or more <b>Roles</b> within the team. A user's role governs what the user can do in the Clearview system and how
				the Clearview system treats them. Current roles are:
			</p>
			<dl className="dl-horizontal">
				<dt>
					<b>Team Member</b>
				</dt>
				<dd>These users can see Period End stages and can perform actions on them.</dd>
				<dt>
					<b>Approver</b>
				</dt>
				<dd>A user must be an Approver in order to Close a Period End stage.</dd>
				<dt>
					<b>Admin</b>
				</dt>
				<dd>
					An Admin user can edit the team membersip and assign roles. (Client teams will often have their own admins, whereas In-House teams will
					probably be managed by the Customer's Admin users)
				</dd>
				<dt>
					<b>Contact</b>
				</dt>
				<dd>
					A single user will be assigned as the In-House or Client Contact for each Business. Being a contact confers no additional rights, but
					Contacts will appear in the Contact field on Period End and Business screens and reports.
				</dd>
				<dt>
					<b>Reader</b>
				</dt>
				<dd>These users can see the Period End and its stages, but cannot perform any actions.</dd>
			</dl>
			<h5>Inheritance:</h5>
			<p>
				If a Business does not have its own Teams, it will inherit Teams its parent in the Business Hierarchy. Hence, it is not necessary to specify
				users and teams for each Business.
			</p>
		</Accordion.Body>
	</Accordion>
)
