import React from "react"
import PropTypes from "prop-types"

import _ from "lodash"

import { Form, InputGroup, InputGroupAddon, Input } from "../../components"

import * as clearview from "../../components/@Clearview"

class Address extends React.Component {
	static propTypes = {
		address: PropTypes.object,
		name: PropTypes.string,
		isEditing: PropTypes.bool,
		onChange: PropTypes.func,
	}

	static defaultProps = {
		isEditing: false,
		onChange: () => {},
	}

	constructor(props) {
		super(props)
		this.props = props

		this.addressChanged = this.addressChanged.bind(this)
		this.postcodeChanged = this.postcodeChanged.bind(this)
		this.nameChanged = this.nameChanged.bind(this)
	}

	addressChanged(e) {
		this.props.onChange({
			target: {
				type: "address",
				name: this.props.name,
				value: {
					...(this.props.address || {}),
					fullAddressExcludingPostcode: e.target.value ? e.target.value.replace("\n", ", ") : "",
				},
			},
		})
	}

	postcodeChanged(e) {
		this.props.onChange({
			target: {
				type: "address",
				name: this.props.name,
				value: {
					...(this.props.address || {}),
					postcode: e.target.value ? e.target.value.toUpperCase() : "",
				},
			},
		})
	}

	nameChanged(e) {
		this.props.onChange({
			target: {
				type: "address",
				name: this.props.name,
				value: {
					...(this.props.address || {}),
					name: e.target.value,
				},
			},
		})
	}

	render() {
		const { address, isEditing } = this.props

		const splitAddress = ((address || {}).fullAddressExcludingPostcode || "")
			.split(",")
			.filter(it => it)
			.map(it => it.trim())
		const name = (address || {}).name
		const postcode = (address || {}).postcode

		if (!isEditing && splitAddress.length) {
			if (name) splitAddress.unshift(name)
			if (postcode) splitAddress.push(postcode)
			return (
				<p>
					{splitAddress.map((it, idx) => (
						<span key={idx}>
							{idx ? <br /> : null}
							{it}
						</span>
					))}
				</p>
			)
		}

		const addressLines = splitAddress.join("\n")

		if (isEditing)
			return (
				<Form>
					<InputGroup>
						<InputGroupAddon addonType="prepend">To</InputGroupAddon>
						<Input
							id="name"
							type="text"
							placeholder="Name..."
							defaultValue={name}
							onChange={e => {
								this.nameChanged(e)
								clearview.StopPropagation(e)
							}}
						/>
					</InputGroup>
					<Input
						id="address"
						type="textarea"
						rows={_.max([splitAddress.length, 4])}
						placeholder="Address..."
						defaultValue={addressLines}
						onChange={e => {
							this.addressChanged(e)
							clearview.StopPropagation(e)
						}}
					/>
					<InputGroup>
						<InputGroupAddon addonType="prepend">Postcode</InputGroupAddon>
						<Input
							id="postcode"
							type="text"
							placeholder="Postcode..."
							defaultValue={postcode}
							onChange={e => {
								this.postcodeChanged(e)
								clearview.StopPropagation(e)
							}}
						/>
					</InputGroup>
				</Form>
			)

		return null
	}
}

export { Address }
