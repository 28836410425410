import _ from "lodash"
import * as ACTIONS from "../actions"
import { LookupConstants } from "../../routes/Dashboards/components/PeriodEndFilter"

const reportsReducer = (state, action) => {
	if (!state)
		state = {
			reports: { isBusy: false, lastUpdated: null, dict: {} },
			attachments: { isBusy: false, lastUpdated: null, data: [] },
		}

	switch (action.type) {
		case ACTIONS.REPORTS_ALL_FETCH:
			return ACTIONS.setSubStateFetching(state, "reports", { dict: state.reports.dict })

		case ACTIONS.REPORTS_ALL_FETCHED:
			const reports = action.reports.map(it => ({
				...it,
				name: _.startCase(it.name.replace("Report - ", "")),
				defaultValues: { periodEndStatus: LookupConstants.periodEndStatuses.find(it => it.value === "Open") },
				status: !it.latestRunTime ? "Idle" : !it.finishedTime ? "Busy" : it.finishedTime < it.latestRunTime ? "Busy" : "Idle",
				finishedTime: it.finishedTime && it.latestRunTime && it.finishedTime > it.latestRunTime ? it.finishedTime : null,
			}))
			return ACTIONS.setSubStateFetched(state, "reports", { dict: ACTIONS.arrayToDictionary(reports, it => it.key, state.reports.dict) })

		case ACTIONS.REPORTS_ATTACHMENTS_FETCHED:
			return ACTIONS.setSubStateFetched(state, "attachments", {
				data: action.attachments.map(it => ({
					...it,
					type: "user",
					id: `user/${it.key}`,
				})),
			})

		default:
			return state
	}
}

export default reportsReducer
