import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import cellEditFactory, { Type } from "../../../../react-bootstrap-table2-editor"

import { Button, Card, CardHeader, CardBody, CardFooter } from "../../../../components"
import { CustomExportCSV } from "./CustomExportButton"
import { CustomSearch } from "./CustomSearch"

import PropTypes from "prop-types"

import * as clearview from "../../../../components/@Clearview"
import { sortCaret } from "../../../../components/@Clearview"

export class TemplateTable extends React.Component {
	static propTypes = {
		customer: PropTypes.object.isRequired,
		isEditing: PropTypes.bool.isRequired,
		removeTemplate: PropTypes.func.isRequired,
		cloneTemplate: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props
	}

	render() {
		const { customer, isEditing, onChange, addTemplate, removeTemplate, cloneTemplate } = this.props

		const editColumns = [
			{
				isDummyField: true,
				dataField: "id",
				text: "Del",
				align: "center",
				formatter: (cell, row) => (
					<Button color="link" className="text-danger" title="Delete template..." onClick={() => removeTemplate(row)}>
						{clearview.Icon.delete}
					</Button>
				),
				editable: false,
			},
			{
				isDummyField: true,
				dataField: "rowVersion",
				text: "Clone",
				align: "center",
				formatter: (cell, row) => (
					<Button color="link" className="text-success" title="Clone this template..." onClick={() => cloneTemplate(row)}>
						{clearview.Icon.clone}
					</Button>
				),
				editable: false,
			},
		]

		const nonEditableRows = isEditing ? [] : customer.templates.map(it => it.id)
		const columns = isEditing ? editableColumns.concat(columnDefs).concat(editColumns) : linkColumns.concat(columnDefs)

		return (
			<ToolkitProvider
				keyField="id"
				data={customer.templates.sort(clearview.orderBy(it => ((it.family || "") + "|" + it.reference).toLowerCase()))}
				columns={columns}
				search
				exportCSV
			>
				{props => (
					<React.Fragment>
						<Card>
							<CardHeader>
								<div className="d-flex justify-content-between align-items-center mb-2">
									<h4 className="p-0 m-0">Templates</h4>
									<div className="d-flex ml-auto">
										<CustomSearch className="mr-2" {...props.searchProps} />
										<CustomExportCSV {...props.csvProps}>Export</CustomExportCSV>
									</div>
								</div>
							</CardHeader>
							<CardBody>
								<BootstrapTable
									classes="table-responsive-lg table-striped"
									bordered={false}
									responsive
									hover
									cellEdit={cellEditFactory({
										mode: "click",
										blurToSave: true,
										afterSaveCell: (oldValue, newValue, row, column) => onChange({ target: { type: "changed" } }, row),
										nonEditableRows: () => nonEditableRows,
									})}
									{...props.baseProps}
								/>
							</CardBody>
							{isEditing && (
								<CardFooter className="h6 text-right">
									<Button outline color="primary" title="Add template..." onClick={e => addTemplate(customer)}>
										{clearview.Icon.add}
									</Button>
								</CardFooter>
							)}
						</Card>
					</React.Fragment>
				)}
			</ToolkitProvider>
		)
	}
}

const linkColumns = [
	{
		dataField: "reference",
		text: "Ref.",
		sort: true,
		sortCaret,
		classes: "text-decoration-none nowrap",
		formatter: (cell, row) => (
			<React.Fragment>
				<Link to={`/template/${row.customer.reference}/${row.reference}`}>
					{row.reference && <span style={{ opacity: 0.5, clear: "both", display: "block" }}>{row.family}:</span>} {cell}
				</Link>
				{clearview.Icon.InHouseTemplate[row.isInHouse]} {clearview.Icon.RetiredTemplate[row.isRetired]}
			</React.Fragment>
		),
		editable: false,
	},
	{
		dataField: "name",
		text: "Template",
		sort: true,
		sortCaret,
		classes: "text-decoration-none nowrap",
		formatter: (cell, row) => (
			<Link to={`/template/${row.customer.reference}/${row.reference}`}>
				{cell}
				{invoiceAfter(row)}
			</Link>
		),
		editable: false,
	},
]

const invoiceAfter = template => {
	if (template.invoiceAfterStage)
		return (
			<p className="mt-0 mb-0" title="Invoice after stage">
				{template.invoiceAfterStage.sequence}: {template.invoiceAfterStage.name}
			</p>
		)
}

const editableColumns = [
	{
		dataField: "reference",
		text: "Ref.",
		sort: true,
		sortCaret,
		classes: "text-decoration-none nowrap",
		editable: true,
		editor: { type: Type.TEXT, placeholder: "Ref..." },
	},
	{
		dataField: "name",
		text: "Template",
		sort: true,
		sortCaret,
		classes: "text-decoration-none nowrap",
		editable: true,
		editor: { type: Type.TEXT, placeholder: "Template name..." },
	},
]

const columnDefs = [
	{
		dataField: "durationMonths",
		headerStyle: { whiteSpace: "break-spaces" },
		text: "Duration (Months)",
		sort: true,
		sortCaret,
		type: "number",
		editor: { type: "number" },
	},
	{
		dataField: "dueMonths",
		headerStyle: { whiteSpace: "break-spaces" },
		text: "Due (Months)",
		sort: true,
		sortCaret,
		type: "number",
		editor: { type: "number" },
	},
	{
		dataField: "imminentDays",
		headerStyle: { whiteSpace: "break-spaces" },
		text: "Imminent (Days)",
		sort: true,
		sortCaret,
		type: "number",
		editor: { type: "number" },
	},
	{
		dataField: "warningDays",
		headerStyle: { whiteSpace: "break-spaces" },
		text: "Warning (Days)",
		sort: true,
		sortCaret,
		type: "number",
		editor: { type: "number" },
	},
	{
		dataField: "countOfPeriodEnds",
		headerStyle: { whiteSpace: "break-spaces" },
		text: "PE Count",
		sort: true,
		sortCaret,
		type: "number",
		editable: false,
	},
]
