import React from "react"
import { connect } from "react-redux"

import * as clearview from "../../../../components/@Clearview"

import { TemplateContext } from "../TemplateContext"
import { TemplateActions } from "./TemplateActions"

import { Accordion, Table, Input, CustomInput, Badge } from "../../../../components"

let TemplateCard = ({ allTemplates, onChange, user }) => {
	return (
		<TemplateContext.Consumer>
			{({ template, isEditing }) =>
				template && (
					<Accordion className="mb-2" initialOpen={true}>
						<Accordion.Header className="d-flex-row justify-content-between h6">
							<span className="align-self-center">
								{clearview.Icon.template} Template {clearview.Icon.InHouseTemplate[template.isInHouse]}
							</span>
							{template.actions?.length && <TemplateActions template={template} />}
							{template.isRetired && (
								<Badge pill color="secondary">
									Retired!
								</Badge>
							)}
						</Accordion.Header>
						<Accordion.Body>
							<Table key="ref" size="sm">
								<tbody>
									<tr>
										<td className="align-top">Ref.</td>
										<td className="text-right">
											{!isEditing && template.reference}
											{isEditing && <Input placeholder="reference..." name="reference" value={template.reference} onChange={onChange} />}
										</td>
									</tr>
									<tr>
										<td className="align-top">Name</td>
										<td className="text-right">
											{!isEditing && template.name}
											{isEditing && <Input type="textarea" placeholder="name..." name="name" value={template.name} onChange={onChange} />}
										</td>
									</tr>

									<tr>
										<td className="align-top">Sequence</td>
										<td className="text-right">
											{!isEditing && template.sequence}
											{isEditing && (
												<Input type="number" placeholder="sequence..." name="sequence" value={template.sequence} onChange={onChange} />
											)}
										</td>
									</tr>
									<tr>
										<td className="align-top">Family</td>
										<td className="text-right">
											{!isEditing && template.family}
											{isEditing && (
												<Input type="textarea" placeholder="family..." name="family" value={template.family} onChange={onChange} />
											)}
										</td>
									</tr>
								</tbody>
							</Table>
							<Table size="sm">
								<tbody>
									<tr>
										<td className="align-top">Duration</td>
										<td className="text-right">
											{!isEditing && <span>{template.durationMonths || 0} months</span>}
											{isEditing && (
												<Input
													type="number"
													placeholder="Months..."
													name="durationMonths"
													value={template.durationMonths}
													onChange={onChange}
												/>
											)}
										</td>
									</tr>
									<tr>
										<td className="align-top">Start</td>
										<td className="text-right">
											{!isEditing && <span>{template.startDaysAfterPeriodEnd || 0} days after end of period</span>}
											{isEditing && (
												<Input
													type="number"
													placeholder="Start days after period end..."
													name="startDaysAfterPeriodEnd"
													value={template.startDaysAfterPeriodEnd}
													onChange={onChange}
												/>
											)}
										</td>
									</tr>
									<tr>
										<td className="align-top">Imminent</td>
										<td className="text-right">
											{!isEditing && <span>{template.imminentDays || 0} warning days before end of period</span>}
											{isEditing && (
												<Input
													type="number"
													placeholder="Start days after period end..."
													name="imminentDays"
													value={template.imminentDays}
													onChange={onChange}
												/>
											)}
										</td>
									</tr>
									<tr>
										<td className="align-top">Warning</td>
										<td className="text-right">
											{!isEditing && <span>{template.warningDays} days before due date</span>}
											{isEditing && (
												<Input
													type="number"
													min={0}
													placeholder="Days before due date..."
													name="warningDays"
													value={template.warningDays}
													onChange={onChange}
												/>
											)}
										</td>
									</tr>
									<tr>
										<td className="align-top">Due</td>
										<td className="text-right">
											{!isEditing && <span>{template.dueMonths} months after period ends</span>}
											{isEditing && (
												<Input
													type="number"
													placeholder="Months after period ends..."
													name="dueMonths"
													value={template.dueMonths}
													onChange={onChange}
												/>
											)}
										</td>
									</tr>
									<tr>
										<td className="align-top">Next</td>
										<td className="text-right">
											{!isEditing && (
												<span>
													{template.next && template.next.id === template.id
														? "Self"
														: template.next
														? `${template.next.reference}: ${template.name}`
														: "None"}
												</span>
											)}
											{isEditing && (
												<CustomInput
													type="select"
													id="next"
													name="next"
													defaultValue={template.next ? template.next.id : 0}
													onChange={e =>
														onChange({
															target: {
																type: "next",
																name: "next",
																value: e.target.value,
															},
														})
													}
												>
													<option id={null} value={0} data-val={null}>
														None
													</option>
													{allTemplates.map((it, idx) => (
														<option key={idx} value={it.id}>
															{it.reference}: {it.name}
														</option>
													))}
												</CustomInput>
											)}
										</td>
									</tr>
									{user.isInHouse && clearview.User.IsAdmin(user) && (
										<React.Fragment>
											<tr>
												<td className="align-top">Invoice After</td>
												<td className="text-right">
													{!isEditing && (
														<span>
															{template.invoiceAfterStage?.sequence} {template.invoiceAfterStage?.name}
														</span>
													)}
													{isEditing && (
														<CustomInput
															type="select"
															id="invoiceAfterStage"
															name="invoiceAfterStage"
															defaultValue={template.invoiceAfterStage ? template.invoiceAfterStage.id : 0}
															onChange={e =>
																onChange({
																	target: {
																		type: "invoiceAfterStage",
																		name: "invoiceAfterStage",
																		value: e.target.value,
																	},
																})
															}
														>
															<option id={null} value={0} data-val={null}>
																None
															</option>
															{template.stages.map(it => (
																<option key={it.id} value={it.id}>
																	{it.sequence}: {it.name}
																</option>
															))}
														</CustomInput>
													)}
												</td>
											</tr>
										</React.Fragment>
									)}
								</tbody>
							</Table>
						</Accordion.Body>
					</Accordion>
				)
			}
		</TemplateContext.Consumer>
	)
}

const mapStateToProps = state => ({
	user: state.userReducer.user,
})

TemplateCard = connect(mapStateToProps)(TemplateCard)

export { TemplateCard }
