import React from "react"
import { connect } from "react-redux"

import PropTypes from "prop-types"

import { Form, FormGroup, Input, InputGroup, UncontrolledModal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"

class Stage_RequestCloseForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		doUpdate: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = { comments: "" }
		this.props = props

		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleCommentsChange(event) {
		this.setState({
			comments: event.target.value,
		})
	}

	handleActionButtonClick(event, action) {
		this.props.doUpdate({
			action: action,
			periodEnd: this.props.periodEnd,
			comments: this.state.comments,
		})
		this.setState({ comments: "" })
		return true
	}

	render() {
		return (
			<UncontrolledModal target={`stage${this.props.stage.id}_RequestCloseForm`}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">Request to Close Stage</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<Input
									type="textarea"
									name="comments"
									id="comments"
									placeholder="Comments..."
									value={this.state.comments}
									onChange={this.handleCommentsChange}
								/>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close color="default" className="text-primary" onClick={e => this.handleActionButtonClick(e, "RequestClose")}>
							Request Close
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doUpdate: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Stage_RequestCloseForm)
