import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import cellEditFactory, { Type } from "../../../../react-bootstrap-table2-editor"

import { TemplateContext } from "./../TemplateContext"

import * as clearview from "../../../../components/@Clearview"

import { Button, ButtonGroup } from "../../../../components"

export class TemplateConditions extends React.Component {
	render() {
		const { stage } = this.props

		const columnDefs = createColumnDefinitions()

		return (
			<TemplateContext.Consumer>
				{({ isEditing, onChange }) => {
					const editColumns = [
						{
							dataField: "id",
							text: "Del",
							align: "center",
							formatter: (cell, row) => (
								<Button
									color="link"
									className="text-danger"
									title="Delete condition..."
									onClick={() => onChange("removeCondition", stage, row)}
								>
									{clearview.Icon.delete}
								</Button>
							),
							editable: false,
						},
						{
							dataField: "rowVersion",
							text: "Move",
							align: "center",
							formatter: (cell, row) => (
								<ButtonGroup>
									<Button
										color="link"
										className="text-info ml-1 mr-2"
										title="Move Up"
										onClick={() => onChange("moveUpCondition", stage, row)}
									>
										{clearview.Icon.up}
									</Button>
									<Button color="link" className="text-info mr-1" title="Move Down" onClick={() => onChange("moveDownCondition", stage, row)}>
										{clearview.Icon.down}
									</Button>
								</ButtonGroup>
							),
							editable: false,
						},
					]

					const nonEditableRows = isEditing ? [] : stage.conditions.map(it => it.id)

					return (
						<ToolkitProvider keyField="id" data={stage.conditions} columns={isEditing ? columnDefs.concat(editColumns) : columnDefs}>
							{props => (
								<React.Fragment>
									<BootstrapTable
										classes="table-responsive-lg csl-stage-table table-striped"
										bordered={true}
										responsive
										hover
										cellEdit={cellEditFactory({
											mode: "click",
											blurToSave: true,
											afterSaveCell: (oldValue, newValue, row, column) => onChange("editCondition", stage, row),
											nonEditableRows: () => nonEditableRows,
										})}
										{...props.baseProps}
									/>
								</React.Fragment>
							)}
						</ToolkitProvider>
					)
				}}
			</TemplateContext.Consumer>
		)
	}
}

function createColumnDefinitions() {
	return [
		{
			dataField: "sequence",
			text: "Condition",
			headerAlign: "center",
			align: "center",
			editable: false,
		},
		{
			dataField: "name",
			text: "Description",
			align: "top",
			type: "string",
			editor: { type: Type.TEXT, placeholder: "Condition description..." },
		},
	]
}
