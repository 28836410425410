/* spell-checker: disable */
import _ from "lodash"
import React from "react"
import { connect } from "react-redux"

import { Badge, IconWithBadge } from ".."

export const PastelColorValues = {
	Greyish: "#ADB7C3",
	Neutral: "#b2b2b2",
	Beige: "#f7bf47",
	Greenish: "#a6c843",
	Infoish: "#4bbBba",
	Purplish: "#b8a9c9",
	Blueish: "#80ced6",
	Redish: "#f18973",
}

export const Icon = {
	overview: <i className="fa fa-fw fa-pie-chart" title="Overview"></i>,
	periodEnd: <i className="fa fa-fw fa-tasks" title="Period End"></i>,
	workflow: <i className="fa fa-fw fa-tasks" title="Workflow"></i>,
	periodEndStatus: (
		<span className="fa-fw fa-stack tight" title="Period End Status">
			<i className="fa fa-fw fa-tasks fa-stack-1x text-info"></i>
			<i className="fa fa-fw fa-check fa-stack-1x text-dark"></i>
		</span>
	),
	periodEndFilterStatusOpen: (
		<span className="fa-fw fa-stack">
			<i className="fa fa-fw fa-tasks fa-stack-1x text-info"></i>
			<i className="fa fa-fw fa-check fa-stack-1x text-dark"></i>
		</span>
	),
	periodEndRag: (
		<span className="fa-fw fa-stack tight" title="Period End RAG">
			<i className="fa fa-fw fa-tasks fa-stack-1x text-warning"></i>
			<i className="fa fa-fw fa-exclamation fa-stack-1x text-dark"></i>
		</span>
	),

	stage: <i className="fa fa-fw fa-list-ol" title="Stage"></i>,
	stages: <i className="fa fa-fw fa-list-ol" title="Stages"></i>,
	phase: <i className="fa fa-fw fa-list-ul" title="Phase"></i>,
	phases: <i className="fa fa-fw fa-list-ul" title="Phases"></i>,
	stageStatus: (
		<span className="fa-fw fa-stack tight" title="Stage Status">
			<i className="fa fa-fw fa-list-ol fa-stack-1x text-info"></i>
			<i className="fa fa-fw fa-check fa-stack-1x text-dark"></i>
		</span>
	),
	stageRag: (
		<span className="fa-fw fa-stack tight" title="Stage RAG">
			<i className="fa fa-fw fa-list-ol fa-stack-1x text-warning"></i>
			<i className="fa fa-fw fa-exclamation fa-stack-1x text-dark"></i>
		</span>
	),
	condition: <i className="fa fa-fw fa-check-square-o" title="Condition"></i>,

	customer: <i className="fa fa-fw fa-institution" title="Customer"></i>,
	client: <i className="fa fa-fw fa-building" title="Client"></i>,
	landlord: <i className="fa fa-fw fa-building text-info" title="Landlord"></i>,
	managingAgent: <i className="fa fa-fw fa-building text-primary" title="Managing Agent"></i>,
	property: <i className="fa fa-fw fa-home" title="Property"></i>,
	subject: <i className="fa fa-fw fa-usd" title="Subject"></i>,
	propertyNames: <i className="fa fa-fw fa-home" title="Property Names"></i>,
	folder: <i className="fa fa-fw fa-folder-o" title="Folder"></i>,
	folderOpen: <i className="fa fa-fw fa-folder-open-o" title="Folder"></i>,
	files: <i className="fa fa-fw fa-folder-open-o" title="Files"></i>,
	filesMany: <i className="fa fa-fw fa-files-o" title="Files"></i>,
	templateFamily: <i className="fa fa-fw fa-bars text-primary" title="Template Family"></i>,
	template: <i className="fa fa-fw fa-bars" title="Template"></i>,
	query: <i className="fa fa-fw fa-comments-o" title="Query"></i>,
	reply: <i className="fa fa-fw fa-comments" title="Reply"></i>,
	replies: <i className="fa fa-fw fa-commenting" title="Replies"></i>,
	noReplies: <i className="fa fa-fw fa-commenting-0" title="No Replies"></i>,
	reports: <i className="fa fa-fw fa-table" title="Reports"></i>,
	downloads: <i className="fa fa-fw fa-download" title="Downloads"></i>,
	support: <i className="fa fa-fw fa-info-circle" title="Support"></i>,
	workStream: <i className="fa fa-fw fa-trello" title="Workstream"></i>,
	vouching: <i className="fa fa-fw fa-check-circle" title="Vouching"></i>,
	workingPapers: <i className="fa fa-fw fa-book" title="Working Papers"></i>,
	ledger: <i className="fa fa-fw fa-bars"></i>,
	adjustment: <i className="fa fa-fw fa-pencil-square" title="Adjustment"></i>,
	due: <i className="fa fa-fw fa-calendar" title="Due Date"></i>,
	end: <i className="fa fa-fw fa-calendar-o" title="End Date"></i>,

	me: <i className="fa fa-fw fa-user text-success" title="Me"></i>,
	inHouseOrClient: <i className="fa fa-fw fa-institution" title="In-House or Client Stage"></i>,
	queryInHouseOrClient: <i className="fa fa-fw fa-comments-o" title="In-House or With Client"></i>,
	queryOpenOrClosed: <i className="fa fa-fw fa-commenting-o" title="Open or Closed Query"></i>,

	copyLinkToPeriodEnd: <i className="fa fa-fw fa-copy text-secondary" title="Copy Link to this Period End"></i>,
	copiedLink: <i className="fa fa-fw fa-flip-horizontal fa-copy text-success" title="Link copied to clipboard"></i>,

	details: {
		true: <i className="fa fa-fw fa-plus-square"></i>,
		false: <i className="fa fa-fw fa-plus-square-o"></i>,
	},

	IsContact: {
		true: <i className="fa fa-fw fa-at" title="Team Contact"></i>,
		false: <i></i>,
	},

	IsNotified: {
		true: <i className="fa fa-fw fa-envelope-o" title="Is Notified of Changes via Email"></i>,
	},

	IsImpersonating: {
		true: <i className="fa fa-fw fa-universal-access text-danger"></i>,
		false: <i className="fa fa-fw fa-user text-primary"></i>,
	},

	InHouse: {
		true: <i style={{ fontSize: "0.7rem", marginTop: "0.1rem" }} className="fa fa-fw fa-institution text-primary" title="In House"></i>,
		false: <i className="fa fa-fw fa-home text-success" title="With Client"></i>,
		undefined: <i className="fa fa-fw fa-square-o" title="Not Yet Open"></i>,
		null: <i className="fa fa-fw fa-flag-checkered" title="Closed/Mixed"></i>,
	},

	inHouse: {
		true: <i className="fa fa-fw fa-institution"></i>,
		false: <i className="fa fa-fw fa-home text-success"></i>,
	},

	Unread: {
		true: <i className="fa fa-fw fa-circle text-info"></i>,
		false: <i className="fa fa-fw fa-circle-o text-dark-02"></i>,
		undefined: <i className="fa fa-fw fa-circle-o text-info"></i>,
		null: <i className="fa fa-fw fa-circle-o text-dark-04"></i>,
	},

	Alert: {
		true: <i className="fa fa-fw fa-star text-danger"></i>,
		false: <i className="fa fa-fw fa-star text-warning"></i>,
		undefined: <i className="fa fa-fw fa-star-o text-dark"></i>,
	},

	Info: <i className="fa fa-fw fa-info-circle text-info"></i>,

	Severity: {
		danger: <i className="fa fa-fw fa-exclamation-circle text-danger"></i>,
		warning: <i className="fa fa-fw fa-warning text-warning"></i>,
		success: <i className="fa fa-fw fa-check-circle text-success"></i>,
		info: <i className="fa fa-fw fa-info-circle text-info"></i>,
	},

	CurrentOrFuture: {
		undefined: <i className="fa fa-fw fa-hourglass-o text-dark"></i>,
		null: <i className="fa fa-fw fa-hourglass-o text-secondary"></i>,
		true: <i className="fa fa-fw fa-hourglass-1 text-success"></i>,
		false: <i className="fa fa-fw fa-hourglass-3 text-danger"></i>,
	},

	InHouseTemplate: {
		true: <i style={{ fontSize: "0.7rem", marginTop: "0.1rem" }} className="fa fa-fw fa-institution text-primary" title="In House"></i>,
		false: <i className="fa fa-fw fa-home text-success" title="All stages are for Client"></i>,
	},

	RetiredTemplate: {
		true: <i className="fa fa-fw fa-ban text-secondary" title="Retired, do not use!"></i>,
	},

	Editing: {
		undefined: <i className="fa fa-fw fa-pencil"></i>,
		false: <i className="fa fa-fw fa-pencil"></i>,
		true: <i className="fa fa-fw fa-edit"></i>,
	},

	SelectedForEditing: {
		undefined: <i className="fa fa-fw fa-circle-thin text-secondary"></i>,
		false: <i className="fa fa-fw fa-circle-thin text-secondary"></i>,
		true: <i className="fa fa-fw fa-pencil"></i>,
		new: <i className="fa fa-fw fa-plus-circle text-secondary"></i>,
	},

	QueueManager: <i className="fa fa-fw fa-tachometer"></i>,

	busy: <i className="fa fa-fw fa-hourglass-start"></i>,
	disabled: <i className="fa fa-fw fa-ban"></i>,
	actions: <i className="fa fa-fw fa-gear"></i>,
	filter: <i className="fa fa-fw fa-filter"></i>,
	sort: <i className="fa fa-fw fa-sort"></i>,
	sorted: <i className="fa fa-fw fa-sort"></i>,
	unsorted: <i className="fa fa-fw fa-unsorted"></i>,
	sortAsc: <i className="fa fa-fw fa-sort-alpha-asc"></i>,
	sortDesc: <i className="fa fa-fw fa-sort-alpha-desc"></i>,
	up: <i className="fa fa-fw fa-arrow-circle-o-up"></i>,
	down: <i className="fa fa-fw fa-arrow-circle-o-down"></i>,
	create: <i className="fa fa-fw fa-plus"></i>,
	upload: <i className="fa fa-fw fa-upload"></i>,
	download: <i className="fa fa-fw fa-download"></i>,
	edit: <i className="fa fa-fw fa-edit"></i>,
	cancel: <i className="fa fa-fw fa-undo"></i>,
	readOnly: <i className="fa fa-fw fa-ban"></i>,
	calculated: <i className="fa fa-fw fa-calculator"></i>,
	clear: <i className="fa fa-fw fa-remove"></i>,
	organize: <i className="fa fa-fw fa-bars"></i>,
	delete: <i className="fa fa-fw fa-trash"></i>,
	locked: <i className="fa fa-fw fa-lock"></i>,
	unlocked: <i className="fa fa-fw fa-unlock"></i>,
	save: <i className="fa fa-fw fa-save"></i>,
	send: <i className="fa fa-fw fa-send-o"></i>,
	refresh: <i className="fa fa-fw fa-refresh"></i>,
	wizard: <i className="fa fa-fw fa-magic"></i>,
	otherFilter: <i className="fa fa-fw fa-question-circle-o"></i>,
	anyStatus: <i className="fa fa-fw fa-circle-thin"></i>,
	settings: <i className="fa fa-fw fa-cogs"></i>,
	matrix: <i className="fa fa-fw fa-table"></i>,
	copy: <i className="fa fa-fw fa-copy"></i>,
	paste: <i className="fa fa-fw fa-paste"></i>,
	merge: <i className="fa fa-fw fa-bars"></i>,
	more: <i className="fa fa-fw fa-ellipsis-h"></i>,

	selectAll: <i className="fa fa-fw fa-check-square-o"></i>,
	selectNone: <i className="fa fa-fw fa-square-o"></i>,

	link: <i className="fa fa-fw fa-link"></i>,
	unlink: <i className="fa fa-fw fa-unlink"></i>,
	confirm: <i className="fa fa-fw fa-check"></i>,
	exclude: <i className="fa fa-fw fa-ban"></i>,
	unavailable: <i className="fa fa-fw fa-times"></i>,
	split: <i className="fa fa-fw fa-rotate-90 fa-code-fork"></i>,
	unbalanced: (
		<span className="fa-stack">
			<i className="fa fa-balance-scale fa-stack-1x text-secondary"></i>
			<i className="fa fa-ban fa-stack-1x"></i>
		</span>
	),
	balanced: <i className="fa fa-fw fa-balance-scale"></i>,

	attachments: <i className="fa fa-fw fa-folder-open-o" title="Files"></i>,
	addAttachments: <i className="fa fa-fw fa-plus"></i>,
	add: <i className="fa fa-fw fa-plus"></i>,
	clone: <i className="fa fa-fw fa-clone"></i>,
	// private: <i className="fa fa-fw fa-user-times"></i>,

	Publish: {
		true: <i className="fa fa-check-square-o"></i>,
		false: <i className="fa fa-square-o"></i>,
	},

	Checked: {
		true: <i className="fa fa-check-square-o text-success"></i>,
		false: <i className="fa fa-square-o text-secondary"></i>,
		undefined: <i className="fa fa-square-o text-warning"></i>,
		null: <i className="fa fa-square-o text-warning"></i>,
	},

	Radiod: {
		true: <i className="fa fa-dot-circle-o text-success"></i>,
		false: <i className="fa fa-circle-o text-secondary"></i>,
		undefined: <i className="fa fa-circle-o text-warning"></i>,
		null: <i className="fa fa-circle-o text-warning"></i>,
	},

	StageAction: {
		requestClosed: <i className="fa fa-check-square-o mr-2"></i>,

		close: <i className="fa fa-check-square mr-2"></i>,
		reopen: <i className="fa fa-undo mr-2"></i>,

		onHold: <i className="fa fa-pause mr-2"></i>,
		offHold: <i className="fa fa-play mr-2"></i>,

		comment: <i className="fa fa-comment-o mr-2"></i>,
		query: <i className="fa fa-comments-o mr-2"></i>,
		queries: <i className="fa fa-comments mr-2"></i>,
	},

	Business: {
		Root: <i className="fa fa-fw fa-globe" title="Clearview"></i>,
		Customer: <i className="fa fa-fw fa-institution" title="Customer"></i>,

		Client: <i className="fa fa-fw fa-building-o" title="Client"></i>,
		"Managing Agent": <i className="fa fa-fw fa-briefcase" title="Managing Agent"></i>,
		Landlord: <i className="fa fa-fw fa-building" title="Landlord"></i>,

		Property: <i className="fa fa-fw fa-home" title="Property"></i>,
		Teams: <i className="fa fa-fw fa-sitemap" title="Teams"></i>,
		Team: <i className="fa fa-fw fa-users" title="Team"></i>,
		AddTeam: <i className="fa fa-fw fa-users" title="Add New Team"></i>,

		Assignments: <i className="fa fa-fw fa-object-group" title="User Assignments"></i>,
		Users: <i className="fa fa-fw fa-users" title="Users"></i>,
		ClientTeam: <i className="fa fa-fw fa-building-o" title="Client Team"></i>,
		InHouseTeam: <i className="fa fa-fw fa-institution" title="In House Team"></i>,

		User: <i className="fa fa-fw fa-user" title="User"></i>,
		InHouseUser: <i className="fa fa-fw fa-user text-primary" title="In-House User"></i>,
		ClientUser: <i className="fa fa-fw fa-user text-danger" title="Client User"></i>,
		CreateUser: <i className="fa fa-fw fa-user-plus" title="Create User"></i>,
		AddUser: <i className="fa fa-fw fa-user-plus" title="Add User"></i>,
		Contact: <i className="fa fa-fw fa-at" title="Contact"></i>,
	},

	FileType: {
		Excel: <i className="fa fa-fw fa-file-excel-o"></i>,
		Image: <i className="fa fa-fw fa-file-image-o"></i>,
		Pdf: <i className="fa fa-fw fa-file-pdf-o"></i>,
		undefined: <i className="fa fa-fw fa-file-o"></i>,
	},

	MimeType: {
		"audio/aac": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"application/x-freearc": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
		"video/x-msvideo": <i className="fa fa-fw fa-file-movie-o mr-2"></i>,
		"application/octet-stream": <i className="fa fa-fw fa-file-o mr-2"></i>,
		"image/bmp": <i className="fa fa-fw fa-file-image-o mr-2"></i>,
		"application/x-bzip": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
		"application/x-bzip2": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
		"text/csv": <i className="fa fa-fw fa-file-excel-o mr-2"></i>,
		"application/msword": <i className="fa fa-fw fa-file-word-o mr-2"></i>,
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document": <i className="fa fa-fw fa-file-word-o mr-2"></i>,
		"application/gzip": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
		"image/gif": <i className="fa fa-fw fa-file-image-o mr-2"></i>,
		"text/html": <i className="fa fa-fw fa-file-text-o mr-2"></i>,
		"image/vnd.microsoft.icon": <i className="fa fa-fw fa-file-image-o mr-2"></i>,
		"text/calendar": <i className="fa fa-fw fa-file-o mr-2"></i>,
		"application/java-archive": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
		"image/jpeg": <i className="fa fa-fw fa-file-photo-o mr-2"></i>,
		"audio/midi audio/x-midi": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"audio/mpeg": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"video/mpeg": <i className="fa fa-fw fa-file-movie-o mr-2"></i>,
		"application/vnd.oasis.opendocument.presentation": <i className="fa fa-fw fa-file-powerpoint-o mr-2"></i>,
		"application/vnd.oasis.opendocument.spreadsheet": <i className="fa fa-fw fa-file-excel-o mr-2"></i>,
		"application/vnd.oasis.opendocument.text": <i className="fa fa-fw fa-file-word-o mr-2"></i>,
		"audio/ogg": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"video/ogg": <i className="fa fa-fw fa-file-movie-o mr-2"></i>,
		"audio/opus": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"image/png": <i className="fa fa-fw fa-file-image-o mr-2"></i>,
		"application/pdf": <i className="fa fa-fw fa-file-pdf-o mr-2"></i>,
		"application/vnd.ms-powerpoint": <i className="fa fa-fw fa-file-powerpoint-o mr-2"></i>,
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": <i className="fa fa-fw fa-file-powerpoint-o mr-2"></i>,
		"application/vnd.rar": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
		"application/rtf": <i className="fa fa-fw fa-file-word-o mr-2"></i>,
		"image/svg+xml": <i className="fa fa-fw fa-file-image-o mr-2"></i>,
		"application/x-tar": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
		"image/tiff": <i className="fa fa-fw fa-file-photo-o mr-2"></i>,
		"video/mp2t": <i className="fa fa-fw fa-file-movie-o mr-2"></i>,
		"text/plain": <i className="fa fa-fw fa-file-text-o mr-2"></i>,
		"application/vnd.visio": <i className="fa fa-fw fa-file-picture-o mr-2"></i>,
		"audio/wav": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"audio/webm": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"video/webm": <i className="fa fa-fw fa-file-movie-o mr-2"></i>,
		"image/webp": <i className="fa fa-fw fa-file-photo-o mr-2"></i>,
		"application/vnd.ms-excel": <i className="fa fa-fw fa-file-excel-o mr-2"></i>,
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": <i className="fa fa-fw fa-file-excel-o mr-2"></i>,
		"application/zip": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
		"video/3gpp": <i className="fa fa-fw fa-file-movie-o mr-2"></i>,
		"audio/3gpp": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"video/3gpp2": <i className="fa fa-fw fa-file-movie-o mr-2"></i>,
		"audio/3gpp2": <i className="fa fa-fw fa-file-audio-o mr-2"></i>,
		"application/x-7z-compressed": <i className="fa fa-fw fa-file-archive-o mr-2"></i>,
	},
}

export const IconName = {
	overview: "pie-chart",
	periodEnd: "tasks",
	stage: "list-ol",
	client: "users",
	property: "home",
	template: "bars",
	query: "comments-o",
	support: "info-circle",

	vouching: "check-circle",

	actions: "gear",
	create: "plus",
	edit: "edit",
	cancel: "undo",
	delete: "trash",
	save: "save",

	StageAction: {
		requestClose: "check-square-o",

		close: "check-square",
		reopen: "undo",

		onHold: "pause",
		offHold: "play",

		comment: "comment-o",
		query: "comments-o",
	},

	Business: {
		Root: "globe",
		Customer: "institution",
		Client: "building-o",
		Property: "home",
		Teams: "sitemap",
		Team: "users",
		ClientTeam: "users",
		InHouseTeam: "cubes",
		User: "user",
	},

	MimeType: {
		"audio/aac": "file-audio-o",
		"application/x-freearc": "file-archive-o",
		"video/x-msvideo": "file-movie-o",
		"application/octet-stream": "file-o",
		"image/bmp": "file-image-o",
		"application/x-bzip": "file-archive-o",
		"application/x-bzip2": "file-archive-o",
		"text/csv": "file-excel-o",
		"application/msword": "file-word-o",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "file-word-o",
		"application/gzip": "file-archive-o",
		"image/gif": "file-image-o",
		"text/html": "file-text-o",
		"image/vnd.microsoft.icon": "file-image-o",
		"text/calendar": "file-o",
		"application/java-archive": "file-archive-o",
		"image/jpeg": "file-photo-o",
		"audio/midi audio/x-midi": "file-audio-o",
		"audio/mpeg": "file-audio-o",
		"video/mpeg": "file-movie-o",
		"application/vnd.oasis.opendocument.presentation": "file-powerpoint-o",
		"application/vnd.oasis.opendocument.spreadsheet": "file-excel-o",
		"application/vnd.oasis.opendocument.text": "file-word-o",
		"audio/ogg": "file-audio-o",
		"video/ogg": "file-movie-o",
		"audio/opus": "file-audio-o",
		"image/png": "file-image-o",
		"application/pdf": "file-pdf-o",
		"application/vnd.ms-powerpoint": "file-powerpoint-o",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": "file-powerpoint-o",
		"application/vnd.rar": "file-archive-o",
		"application/rtf": "file-word-o",
		"image/svg+xml": "file-image-o",
		"application/x-tar": "file-archive-o",
		"image/tiff": "file-photo-o",
		"video/mp2t": "file-movie-o",
		"text/plain": "file-text-o",
		"application/vnd.visio": "file-picture-o",
		"audio/wav": "file-audio-o",
		"audio/webm": "file-audio-o",
		"video/webm": "file-movie-o",
		"image/webp": "file-photo-o",
		"application/vnd.ms-excel": "file-excel-o",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "file-excel-o",
		"application/zip": "file-archive-o",
		"video/3gpp": "file-movie-o",
		"audio/3gpp": "file-audio-o",
		"video/3gpp2": "file-movie-o",
		"audio/3gpp2": "file-audio-o",
		"application/x-7z-compressed": "file-archive-o",
	},
}

export const IconClass = {
	overview: "fa fa-fw fa-pie-chart mr-2",
	periodEnd: "fa fa-fw fa-tasks mr-2",
	stage: "fa fa-fw fa-list-ol mr-2",
	client: "fa fa-fw fa-users mr-2",
	property: "fa fa-fw fa-home mr-2",
	template: "fa fa-fw fa-bars mr-2",
	query: "fa fa-fw fa-comments-o mr-2",
	support: "fa fa-fw fa-info-circle mr-2",

	vouching: "fa fa-fw fa-check-circle mr-2",

	actions: "fa fa-fw fa-gear mr-2",
	create: "fa fa-fw fa-plus mr-2",
	edit: "fa fa-fw fa-edit mr-2",
	cancel: "fa fa-fw fa-undo mr-2",
	delete: "fa fa-fw fa-trash mr-2",
	save: "fa fa-fw fa-save mr-2",

	StageAction: {
		RequestClose: "fa fa-fw fa-check-square-o mr-2",

		Close: "fa fa-fw fa-check-square mr-2",
		Reopen: "fa fa-fw fa-undo mr-2",

		OnHold: "ffa fa-fw a-pause mr-2",
		OffHold: "fa fa-fw fa-play mr-2",

		Comment: "fa fa-fw fa-comment-o mr-2",
		Query: "fa fa-fw fa-comments-o mr-2",
	},

	Business: {
		Root: "fa fa-fw fa-globe mr-2",
		Customer: "fa fa-fw fa-institution mr-2",
		Client: "fa fa-fw fa-building-o mr-2",
		Property: "fa fa-fw fa-home mr-2",
		Teams: "fa fa-fw fa-sitemap mr-2",
		Team: "fa fa-fw fa-users mr-2",
		ClientTeam: "fa fa-fw fa-users mr-2",
		InHouseTeam: "fa fa-fw fa-cubes mr-2",
		User: "fa fa-fw fa-user mr-2",
	},

	MimeType: {
		"audio/aac": "fa fa-fw fa-file-audio-o mr-2",
		"application/x-freearc": "fa fa-fw fa-file-archive-o mr-2",
		"video/x-msvideo": "fa fa-fw fa-file-movie-o mr-2",
		"application/octet-stream": "fa fa-fw fa-file-o mr-2",
		"image/bmp": "fa fa-fw fa-file-image-o mr-2",
		"application/x-bzip": "fa fa-fw fa-file-archive-o mr-2",
		"application/x-bzip2": "fa fa-fw fa-file-archive-o mr-2",
		"text/csv": "fa fa-fw fa-file-excel-o mr-2",
		"application/msword": "fa fa-fw fa-file-word-o mr-2",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "fa fa-fw fa-file-word-o mr-2",
		"application/gzip": "fa fa-fw fa-file-archive-o mr-2",
		"image/gif": "fa fa-fw fa-file-image-o mr-2",
		"text/html": "fa fa-fw fa-file-text-o mr-2",
		"image/vnd.microsoft.icon": "fa fa-fw fa-file-image-o mr-2",
		"text/calendar": "fa fa-fw fa-file-o mr-2",
		"application/java-archive": "fa fa-fw fa-file-archive-o mr-2",
		"image/jpeg": "fa fa-fw fa-file-photo-o mr-2",
		"audio/midi audio/x-midi": "fa fa-fw fa-file-audio-o mr-2",
		"audio/mpeg": "fa fa-fw fa-file-audio-o mr-2",
		"video/mpeg": "fa fa-fw fa-file-movie-o mr-2",
		"application/vnd.oasis.opendocument.presentation": "fa fa-fw fa-file-powerpoint-o mr-2",
		"application/vnd.oasis.opendocument.spreadsheet": "fa fa-fw fa-file-excel-o mr-2",
		"application/vnd.oasis.opendocument.text": "fa fa-fw fa-file-word-o mr-2",
		"audio/ogg": "fa fa-fw fa-file-audio-o mr-2",
		"video/ogg": "fa fa-fw fa-file-movie-o mr-2",
		"audio/opus": "fa fa-fw fa-file-audio-o mr-2",
		"image/png": "fa fa-fw fa-file-image-o mr-2",
		"application/pdf": "fa fa-fw fa-file-pdf-o mr-2",
		"application/vnd.ms-powerpoint": "fa fa-fw fa-file-powerpoint-o mr-2",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": "fa fa-fw fa-file-powerpoint-o mr-2",
		"application/vnd.rar": "fa fa-fw fa-file-archive-o mr-2",
		"application/rtf": "fa fa-fw fa-file-word-o mr-2",
		"image/svg+xml": "fa fa-fw fa-file-image-o mr-2",
		"application/x-tar": "fa fa-fw fa-file-archive-o mr-2",
		"image/tiff": "fa fa-fw fa-file-photo-o mr-2",
		"video/mp2t": "fa fa-fw fa-file-movie-o mr-2",
		"text/plain": "fa fa-fw fa-file-text-o mr-2",
		"application/vnd.visio": "fa fa-fw fa-file-picture-o mr-2",
		"audio/wav": "fa fa-fw fa-file-audio-o mr-2",
		"audio/webm": "fa fa-fw fa-file-audio-o mr-2",
		"video/webm": "fa fa-fw fa-file-movie-o mr-2",
		"image/webp": "fa fa-fw fa-file-photo-o mr-2",
		"application/vnd.ms-excel": "fa fa-fw fa-file-excel-o mr-2",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "fa fa-fw fa-file-excel-o mr-2",
		"application/zip": "fa fa-fw fa-file-archive-o mr-2",
		"video/3gpp": "fa fa-fw fa-file-movie-o mr-2",
		"audio/3gpp": "fa fa-fw fa-file-audio-o mr-2",
		"video/3gpp2": "fa fa-fw fa-file-movie-o mr-2",
		"audio/3gpp2": "fa fa-fw fa-file-audio-o mr-2",
		"application/x-7z-compressed": "fa fa-fw fa-file-archive-o mr-2",
	},
}

export const messageIcons = messagesObject => {
	if (!messagesObject) return
	const messages = _.keys(messagesObject).map((key, idx) => ({ key: idx, title: key, severity: messagesObject[key].severity }))
	if (!messages.length) return

	const messagesBySeverity = {
		danger: [],
		warning: [],
		success: [],
		info: [],
		..._.groupBy(messages, it => it.severity),
	}

	const severityMap = (severity, msgs) => {
		if (!msgs.length) return

		const aggregateMessage = msgs.map(it => it.title).join(" ")
		if (msgs.length > 1)
			return (
				<span key={severity} title={aggregateMessage}>
					<IconWithBadge
						className={`text-${severity}`}
						badge={
							<Badge pill color="transparent">
								{msgs.length}
							</Badge>
						}
					>
						{Icon.Severity[severity]}
					</IconWithBadge>
				</span>
			)

		return (
			<span key={severity} title={aggregateMessage}>
				{Icon.Severity[severity]}
			</span>
		)
	}

	return (
		<div className="message-icons">
			{severityMap("danger", messagesBySeverity["danger"])}
			{severityMap("warning", messagesBySeverity["warning"])}
			{severityMap("success", messagesBySeverity["success"])}
			{severityMap("info", messagesBySeverity["info"])}
		</div>
	)
}

let ListMessageActions = ({ actions, doDispatch }) => {
	if (!actions) return
	return (
		<span key="ma" className="message-actions">
			{actions.map((act, idx) => (
				<span key={`ma-${idx}`} className="hover" onClick={e => doDispatch(act.action)}>
					{act.label}
				</span>
			))}
		</span>
	)
}

ListMessageActions = connect(null, dispatch => ({
	doDispatch: action => {
		dispatch(action)
	},
}))(ListMessageActions)

export const listMessages = messagesObject => {
	if (!messagesObject) return
	return (
		<ul key="m" className="list-messages">
			{_.keys(messagesObject).map((key, idx) => (
				<li key={`m-${idx}`} className={`text-${messagesObject[key].severity}`}>
					{Icon.Severity[messagesObject[key].severity || "info"]}{" "}
					<span>
						{key} <ListMessageActions actions={messagesObject[key].actions} />
					</span>
				</li>
			))}
		</ul>
	)
}
