import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Avatar } from "./Avatar"

const AvatarFont = props => {
	const { user, children, bgColor, fgColor, bgColorCustom, fgColorCustom, ...avatarProps } = props

	const ifUser = {
		bgColor: user && (user.isDisabled ? "secondary" : user.isInHouse ? "in-house" : "client"),
		children: user && (user.initials || user.name.substring(0, 1) + "*"),
		avatarProps: user &&
			!avatarProps.title && {
				title: `${user.name} (${user.username}) ${user.isInHouse ? "In House" : "Client"}${user.isDisabled ? " - ACCOUNT DISABLED" : ""}`,
			},
	}

	const parentClass = classNames("avatar-font", `avatar-font--${avatarProps.size}`, `bg-color--${bgColor || ifUser.bgColor || "400"}`)
	const childClass = classNames("avatar-font__text", fgColor && `fg-color--${fgColor}`)
	const parentCustomStyle = bgColorCustom
		? {
				backgroundColor: bgColorCustom,
		  }
		: {}
	const childCustomStyle = fgColorCustom
		? {
				color: fgColorCustom,
		  }
		: {}
	const child = <span>{children || ifUser.children}</span>

	return (
		<Avatar {...avatarProps} {...ifUser.avatarProps}>
			<div className={parentClass} style={parentCustomStyle}>
				{React.cloneElement(child, {
					style: childCustomStyle,
					className: classNames(child.props.className, childClass),
				})}
			</div>
		</Avatar>
	)
}
AvatarFont.propTypes = {
	user: PropTypes.object,
	children: PropTypes.node,
	bgColor: PropTypes.string,
	fgColor: PropTypes.string,
	bgColorCustom: PropTypes.string,
	fgColorCustom: PropTypes.string,
	...Avatar.propTypes,
}
AvatarFont.defaultProps = {
	fgColor: "white",
	size: "md",
}

export { AvatarFont }
