import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import paginationFactory from "../../../../react-bootstrap-table2-paginator"
import moment from "moment"
import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"
import { sortCaret } from "../../../../components/@Clearview"

import { Link } from "react-router-dom"
import { ButtonGroup, Avatar } from "../../../../components"
import { CustomExportCSV } from "./CustomExportButton"
import { CustomSearch } from "./CustomSearch"

import { PeriodEndFilter } from "../../components/PeriodEndFilter"
import { PeriodEndBadges } from "./PeriodEndBadges"
import { PeriodEndProgressBar } from "./PeriodEndProgressBar"
import { PeriodEndUnread } from "./PeriodEndUnread"
import { PeriodEndFavourite } from "./PeriodEndFavourite"

import { Consumer } from "../../../../layout/context"
import { getTeamsInfo } from "../../Properties/components/PropertyCard"

import PropTypes from "prop-types"

const propertyTeamsInfoLookup = {}

class PeriodEndsTable extends React.Component {
	static propTypes = {
		periodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {}

		this.onSearch = this.onSearch.bind(this)
		this.searchPredicate = this.searchPredicate.bind(this)
	}

	onSearch(value) {
		this.setState({
			search: value,
		})
	}

	searchPredicate = periodEnd => {
		if (!this.state.search) return true

		const regex = clearview.Search.SafeRegex(this.state.search)

		return (
			clearview.Search.Stage(periodEnd.currentStage, regex) ||
			clearview.Search.PeriodEnd(periodEnd, regex) ||
			clearview.Search.Business(periodEnd.property, regex) ||
			(periodEnd.currentStage && clearview.Search.Comments(periodEnd.currentStage.comments, regex))
		)
	}

	render() {
		const periodEnds = this.props.periodEnds.filter(this.searchPredicate)

		const columnDefs = createColumnDefinitions(
			_.find(periodEnds, it => !!getBusinessForRole(it, "Client")),
			_.find(periodEnds, it => !!getBusinessForRole(it, "Managing Agent")),
			_.find(periodEnds, it => !!getBusinessForRole(it, "Landlord")),
			this.props.user
		)

		_.each(periodEnds, periodEnd => {
			if (!propertyTeamsInfoLookup[periodEnd.property.id]) {
				propertyTeamsInfoLookup[periodEnd.property.id] = getTeamsInfo(periodEnd.property)
			}
		})

		const usersAndInheritedUsers = team => {
			const users = []
			if (team) users.push(...team.users)
			if (team?.inheritedTeam) users.push(...team.inheritedTeam)
			return _.uniqBy(users, it => it.username)
		}

		let exportColumns = [
			{ dataField: "reference", text: "Ref." },

			{ dataField: "template.name", text: "Template" },
			{ dataField: "property.reference", text: "Property Ref." },

			{ dataField: "endDate", text: "Period End", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
			{ dataField: "dueDate", text: "Due Date", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
			{ dataField: "projectedEndDate", text: "Expected", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },

			{ dataField: "property.name", text: "Property" },
			{ dataField: "property.parent.reference", text: "Client/Landlord Ref." },
			{ dataField: "property.parent.name", text: "Client/Landlord Name" },

			{
				dataField: "inHouseServiceChargeManagerTeam",
				text: "Service Charge Manager",
				csvFormatter: (col, row) => propertyTeamsInfoLookup[row.property.id]?.inHouseServiceChargeManagerTeam?.users.map(u => u.name).join(", ") || "",
			},

			{
				dataField: "clientAccounts",
				text: "Client Accounts",
				csvFormatter: (col, row) =>
					usersAndInheritedUsers(propertyTeamsInfoLookup[row.property.id]?.teams.find(it => it.type === "ClientAppointees" && it.name === "Accounts"))
						.map(it => it.name)
						.join(", "),
			},

			{
				dataField: "clientSurveyors",
				text: "Client Surveyors",
				csvFormatter: (col, row) =>
					usersAndInheritedUsers(propertyTeamsInfoLookup[row.property.id]?.teams.find(it => it.type === "ClientAppointees" && it.name === "Surveyor"))
						.map(it => it.name)
						.join(", "),
			},

			{
				dataField: "clientPropertyManagers",
				text: "Client Property Managers",
				csvFormatter: (col, row) =>
					usersAndInheritedUsers(
						propertyTeamsInfoLookup[row.property.id]?.teams.find(it => it.type === "ClientAppointees" && it.name === "Property Manager")
					)
						.map(it => it.name)
						.join(", "),
			},

			{ dataField: "property.actors.inHouseContact.name", text: "In House Contact" },
			{ dataField: "property.actors.clientContact.name", text: "Client Contact" },

			{ dataField: "currentStage.templateStage.sequence", text: "Stage #" },
			{ dataField: "currentStage.templateStage.name", text: "Stage" },
			{ dataField: "currentStage.status", text: "Stage Status" },
			{ dataField: "currentStage.rag", text: "Stage Rag" },
			{ dataField: "currentStage.dueDate", text: "Due Date", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
			{ dataField: "fee", text: "Fee", csvFormatter: (col, row) => col / 100 },
			{ dataField: "expenditureFinal", text: "Final Expenditure", csvFormatter: (col, row) => col / 100 },
			{ dataField: "expenditureBudget", text: "Budget Expenditure", csvFormatter: (col, row) => col / 100 },
			{ dataField: "doNotRenew", text: "Do Not Renew" },
			{ dataField: "isPublished", text: "Published" },
		]
		if (!this.props.user.isInHouse)
			exportColumns = exportColumns.filter(it => !["Expected", "Fee", "Final Expenditure", "Budget Expenditure"].includes(it.text))

		return (
			<Consumer>
				{context => (
					<React.Fragment>
						<ToolkitProvider keyField="reference" data={context.sortedAndFiltered(periodEnds)} columns={exportColumns} exportCSV>
							{props => (
								<React.Fragment>
									<div className="d-flex justify-content-end align-items-center mb-2">
										<div className="d-flex ml-auto">
											<CustomSearch onSearch={this.onSearch} />
											<PeriodEndFilter key={clearview.GenerateKey()} className="ml-2" periodEnds={this.props.periodEnds} />

											<ButtonGroup className="ml-2">
												<CustomExportCSV {...props.csvProps}>Export</CustomExportCSV>
											</ButtonGroup>
										</div>
									</div>
									<BootstrapTable classes="hidden" {...props.baseProps} />
								</React.Fragment>
							)}
						</ToolkitProvider>

						<ToolkitProvider keyField="reference" data={context.sortedAndFiltered(periodEnds)} columns={columnDefs} search>
							{props => (
								<React.Fragment>
									<BootstrapTable
										pagination={paginationFactory({
											sizePerPage: 20,
											sizePerPageList: [
												{ text: "10 row per page", value: 10 },
												{ text: "20 row per page", value: 20 },
												{ text: "50 row per page", value: 50 },
												{ text: "100 row per page", value: 100 },
											],
										})}
										classes="table-responsive-lg text-inverse table-striped"
										bordered={false}
										// expandRow={ expandRow }
										// rowEvents={ rowEvents }
										// rowClasses={ rowClasses }
										responsive
										hover
										{...props.baseProps}
									/>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</React.Fragment>
				)}
			</Consumer>
		)
	}
}

function getBusinessForRole(periodEnd, roleName) {
	if (periodEnd.property?.role === roleName) return periodEnd.property
	if (periodEnd.property?.parent?.role === roleName) return periodEnd.property.parent
	if (periodEnd.property?.parent?.parent?.role === roleName) return periodEnd.property.parent.parent
	return null
}

function createColumnDefinitions(hasClients, hasManagingAgents, hasLandlords, user) {
	let allColumns = [
		{
			dataField: "reference",
			text: "Ref.",
			sort: true,
			sortCaret,
			formatter: (cell, row) => {
				return (
					<Link key={cell} to={`/periodend/${cell}`} className="text-decoration-none nowrap">
						<span>
							<PeriodEndUnread periodEnd={row} />
							{cell}
							<sup>
								<PeriodEndFavourite periodEnd={row} />
							</sup>
						</span>
						<br />
						<div hidden={row.status === "Closed"}>
							<PeriodEndProgressBar user={user} periodEnd={row} hasLabels={false} />
							<span hidden={["NotYetOpen", "Closed"].includes(row.status)} className="small">
								S{row.currentStage?.templateStage.sequence}: {row.currentStage?.templateStage.name}{" "}
								{clearview.StageStatusBadge(row.currentStage)}
							</span>
							<span className="ml-2">
								<PeriodEndBadges periodEnd={row} />
								<span className="ml-2">{row.currentStage?.templateStage.isInHouse ? clearview.Icon.isInHouse : null}</span>
							</span>
						</div>
						<div hidden={row.status !== "Closed"}>
							<span className="small">
								{clearview.PeriodEndStatusNames[row.status]}: {clearview.PeriodEndStatusBadge(row)}
							</span>
						</div>
					</Link>
				)
			},
		},
		{
			dataField: "property.parent",
			text: "Client",
			sort: true,
			sortCaret,
			formatter: (cell, row) => {
				var it = getBusinessForRole(row, "Client")
				return (
					it && (
						<Link to={`/clients/${it.reference}`} className="text-decoration-none">
							{clearview.GetBusinessTitle(it)}
						</Link>
					)
				)
			},
		},
		{
			dataField: "property.parent.parent.name",
			text: "Managing Agent",
			sort: true,
			sortCaret,
			formatter: (cell, row) => {
				var it = getBusinessForRole(row, "Managing Agent")
				return (
					it && (
						<Link to={`/clients/${it.reference}`} className="text-decoration-none">
							{clearview.GetBusinessTitle(it)}
						</Link>
					)
				)
			},
		},
		{
			dataField: "property.parent.name",
			text: "Landlord",
			sort: true,
			sortCaret,
			formatter: (cell, row) => {
				var it = getBusinessForRole(row, "Landlord")
				return (
					it && (
						<Link to={`/clients/${it.reference}`} className="text-decoration-none">
							{clearview.GetBusinessTitle(it)}
						</Link>
					)
				)
			},
		},
		{
			dataField: "property.name",
			text: "Property",
			sort: true,
			sortCaret,
			formatter: (cell, row) =>
				row.property && (
					<Link to={`/clients/${row.property.reference}`} className="text-decoration-none">
						{clearview.GetBusinessTitle(row.property)}
					</Link>
				),
		},
		{
			dataField: "currentStage.dueDate",
			text: "Stage Due",
			sort: true,
			sortCaret,
			formatter: (cell, row) => clearview.StageRagBadge(row.currentStage, moment(cell).format("DD/MM/YYYY")),
		},
		{
			dataField: "property.actors.inHouseContact.name",
			text: "In House Contact",
			sort: true,
			sortCaret,
			formatter: (cell, row) =>
				row.property.actors.inHouseContact && (
					<span>
						<Avatar.Font user={row.property.actors.inHouseContact} size="sm" className="avatar-with-text mr-1"></Avatar.Font>
						{row.property.actors.inHouseContact.name}
					</span>
				),
		},
		{
			dataField: "property.actors.clientContact.name",
			text: "Client Contact",
			sort: true,
			sortCaret,
			formatter: (cell, row) =>
				row.property.actors.clientContact && (
					<span>
						<Avatar.Font user={row.property.actors.clientContact} size="sm" className="avatar-with-text mr-1"></Avatar.Font>
						{row.property.actors.clientContact.name}
					</span>
				),
		},
		{
			dataField: "endDate",
			text: "Period End",
			sort: true,
			sortCaret,
			formatter: (cell, row) => moment(cell).format("DD/MM/YYYY"),
		},
		{
			dataField: "dueDate",
			text: "Due Date",
			sort: true,
			sortCaret,
			formatter: (cell, row) => clearview.PeriodEndRagBadge(row, moment(cell).format("DD/MM/YYYY")),
		},
		{
			dataField: "projectedEndDate",
			text: "Expected",
			sort: true,
			sortCaret,
			formatter: (cell, row) => (cell ? moment(cell).format("DD/MM/YYYY") : null),
		},
	]

	if (!hasClients) allColumns = allColumns.filter(it => it.text !== "Client")
	if (!hasLandlords) allColumns = allColumns.filter(it => it.text !== "Landlord")
	if (!hasManagingAgents) allColumns = allColumns.filter(it => it.text !== "Managing Agent")
	if (!user.isInHouse) allColumns = allColumns.filter(it => !["Expected", "Fee", "Final Expenditure", "Budget Expenditure"].includes(it.text))
	return allColumns
}

export { PeriodEndsTable }
