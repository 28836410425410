import React from "react"

import _ from "lodash"
import { deClutter, stringsMatch } from "clearview-aws-common"
import * as clearview from "../../../../components/@Clearview"
import CATEGORIES from "./Config/CATEGORIES.json"
import { scheduleCodeSort } from "./WorkingPapers.Common"

import * as WP_ACTIONS from "./store/workingPapersActions"

import config from "../../../../config"

import getReportData from "./Report/Data"

export function updateETB(workingPapers) {
	const journalDrs = clearview.SummaryBy(
		workingPapers.journals
			.filter(it => !it.isPresentationOnly)
			.flatMap(it => it.items)
			.filter(it => !!it.dr),
		it => ({ schedule: it.schedule, code: it.code }),
		it => it.dr
	)

	const journalCrs = clearview.SummaryBy(
		workingPapers.journals
			.filter(it => !it.isPresentationOnly)
			.flatMap(it => it.items)
			.filter(it => !!it.cr),
		it => ({ schedule: it.schedule, code: it.code }),
		it => it.cr
	)

	const presentationDrs = clearview.SummaryBy(
		workingPapers.journals
			.filter(it => !!it.isPresentationOnly)
			.flatMap(it => it.items)
			.filter(it => !!it.dr),
		it => ({ schedule: it.schedule, code: it.code }),
		it => it.dr
	)
	const presentationCrs = clearview.SummaryBy(
		workingPapers.journals
			.filter(it => !!it.isPresentationOnly)
			.flatMap(it => it.items)
			.filter(it => !!it.cr),
		it => ({ schedule: it.schedule, code: it.code }),
		it => it.cr
	)

	const incomeAllocations = clearview.SummaryBy(
		workingPapers.incomeAllocations,
		it => ({ schedule: it.schedule, code: it.code }),
		it => it.total
	)

	const drsAndPrepay = clearview.SummaryBy(workingPapers.drsAndPrepay, it => ({
		schedule: it.schedule,
		code: it.code,
	}))

	// include Fees is crsAndAccruals
	const crsAndAccruals = clearview.SummaryBy([...workingPapers.crsAndAccruals, ...workingPapers.fees.allocations], it => ({
		schedule: it.schedule,
		code: it.code,
	}))

	const expenditureReport = clearview.SummaryBy(workingPapers.expenditureReport.ledger, it => ({
		schedule: it.schedule,
		code: it.code,
	}))

	const allSchedulesAndCodes = _.uniqWith(
		[
			...workingPapers.budgets,
			...workingPapers.clientReserves,
			...workingPapers.clientTrialBalances,
			...drsAndPrepay,
			...crsAndAccruals,
			...expenditureReport,
			...incomeAllocations,
			...journalDrs,
			...journalCrs,
			...presentationDrs,
			...presentationCrs,
		],
		(arrVal, othVal) => arrVal.schedule === othVal.schedule && arrVal.code === othVal.code
	)
		.filter(it => it.schedule && it.code)
		.map(it => ({ schedule: it.schedule, code: it.code }))
		.sort(scheduleCodeSort)

	lookupNominalCodes(workingPapers, allSchedulesAndCodes, "etb_")

	const matchFn = (it, key) => it.schedule === key.schedule && it.code === key.code
	workingPapers.etb = allSchedulesAndCodes.map(key => {
		const expenditure = expenditureReport.find(it => matchFn(it, key))
		const journalDr = journalDrs.find(it => matchFn(it, key))
		const journalCr = journalCrs.find(it => matchFn(it, key))

		const budget = workingPapers.budgets.find(it => matchFn(it, key))
		const comparative = workingPapers.comparatives.find(it => matchFn(it, key))

		const clientReserve = workingPapers.clientReserves.find(it => matchFn(it, key))
		const clientTrialBalance = workingPapers.clientTrialBalances.find(it => matchFn(it, key))

		const presentationDr = presentationDrs.find(it => matchFn(it, key))
		const presentationCr = presentationCrs.find(it => matchFn(it, key))

		const incomeAllocation = incomeAllocations.find(it => matchFn(it, key))

		const crAndAccrual = crsAndAccruals.find(it => matchFn(it, key))
		const drAndPrepay = drsAndPrepay.find(it => matchFn(it, key))

		const perOriginalReportsDr = clearview.sumAny(expenditure?.total, clientReserve?.total, clientTrialBalance?.total) // X
		const perOriginalReportsCr = clearview.sumAny(incomeAllocation?.total) // Y

		const perAmendedAgentReportsDrCr = clearview.sumAny(
			perOriginalReportsDr,
			-perOriginalReportsCr,
			journalDr?.total,
			-journalCr?.total,
			drAndPrepay?.total,
			-crAndAccrual?.total
		)
		const perAccountsDrCr = clearview.sumAny(perAmendedAgentReportsDrCr, presentationDr?.total, -presentationCr?.total)

		return {
			...key,
			budget: budget?.total,
			comparativeBudget: comparative?.budget,
			expenditure: expenditure?.total,
			journalDr: journalDr?.total,
			journalCr: journalCr?.total,
			income: incomeAllocation?.total,
			clientReserve: clientReserve?.total,
			clientTrialBalance: clientTrialBalance?.total,
			crAndAccrual: drAndPrepay?.total,
			drAndPrepay: crAndAccrual?.total,
			presentationDr: presentationDr?.total,
			presentationCr: presentationCr?.total,

			perOriginalReportsDr,
			perOriginalReportsCr,
			perAmendedAgentReportsDrCr,
			perAccountsDrCr,
		}
	})

	return workingPapers.etb
}

export function materialiseWorkingPapers(client, periodEnd, workingPapers, rawFeeMatrix, rawNominalCodes) {
	workingPapers.client = client
	workingPapers.periodEnd = periodEnd

	_.defaultsDeep(workingPapers, {
		meta: {},
		schedules: ["BS"],
		config: {},
		comparatives: [],
		incomeReport: { summary: {}, data: [] },
		expenditureReport: { categories: {}, ledger: [] },
		details: {
			period: clearview.AfterLast(periodEnd.reference, "/"),
			duration: clearview.TemplateDurationName(periodEnd.template),
			previousPeriod: parseInt(periodEnd.endDate.substring(0, 4)) - 1,
			previousDuration: clearview.TemplateDurationName(periodEnd.template),
		},
	})

	workingPapers.config.NOMINAL_CODES = materialiseNominalCodes(rawNominalCodes)

	if (!workingPapers.config?.FEE_MATRIX?.matrix) {
		workingPapers.config.FEE_MATRIX = materialiseFeeMatrix(rawFeeMatrix)
	}

	workingPapers.expenditureReport = materialiseExpenditureReport(workingPapers)
	workingPapers.incomeAllocations = materialiseCommonImport(workingPapers, workingPapers.incomeAllocations)
	workingPapers.journals = materialiseJournals(workingPapers)
	workingPapers.budgets = materialiseCommonImport(workingPapers, workingPapers.budgets)
	workingPapers.clientReserves = materialiseCommonImport(workingPapers, workingPapers.clientReserves)
	workingPapers.clientTrialBalances = materialiseCommonImport(workingPapers, workingPapers.clientTrialBalances)
	workingPapers.drsAndPrepay = materialiseCommonImport(workingPapers, workingPapers.drsAndPrepay)
	workingPapers.crsAndAccruals = materialiseCommonImport(workingPapers, workingPapers.crsAndAccruals)
	workingPapers.details = materialiseDetails(workingPapers.details)

	// Fix/upgrade contents to current version
	delete workingPapers.ExpenditureReport

	updateAllSchedules(workingPapers)

	workingPapers.etb = updateETB(workingPapers)

	if (!workingPapers.config.FEE_MATRIX["~meta"]?.isCustom) {
		workingPapers.fees.messages["The default Fee Matrix is being used! This is unlikely to yield the correct fee."] = {
			severity: "danger",
		}
	} else if (workingPapers.config.FEE_MATRIX["~meta"].lastModified !== rawFeeMatrix["~meta"].lastModified) {
		workingPapers.fees.messages["The Fee Matrix for this Client has changed since you started using it!"] = {
			severity: "danger",
			actions: [
				{
					label: <span>{clearview.Icon.refresh} Import updated Fee Matrix</span>,
					action: { type: WP_ACTIONS.IMPORT_UPDATED_FEE_MATRIX, rawFeeMatrix },
				},
			],
		}
	}

	return {
		workingPapers,
	}
}

const removeNominalCode = it => {
	delete it.nominalCode
	return it
}

export function dematerialiseWorkingPapers(workingPapers) {
	delete workingPapers.meta
	delete workingPapers.client
	delete workingPapers.periodEnd
	delete workingPapers.etb

	workingPapers.expenditureReport.ledger = workingPapers.expenditureReport.ledger.sort(scheduleCodeSort)
	delete workingPapers.expenditureReport.summary
	delete workingPapers.expenditureReport.categories

	workingPapers.incomeAllocations = workingPapers.incomeAllocations.map(removeNominalCode).sort(scheduleCodeSort)
	workingPapers.budgets = workingPapers.budgets.map(removeNominalCode).sort(scheduleCodeSort)
	workingPapers.clientReserves = workingPapers.clientReserves.map(removeNominalCode).sort(scheduleCodeSort)
	workingPapers.clientTrialBalances = workingPapers.clientTrialBalances.map(removeNominalCode).sort(scheduleCodeSort)
	workingPapers.drsAndPrepay = workingPapers.drsAndPrepay.map(removeNominalCode).sort(scheduleCodeSort)
	workingPapers.crsAndAccruals = workingPapers.crsAndAccruals.map(removeNominalCode).sort(scheduleCodeSort)

	workingPapers.journals = workingPapers.journals.map(entry => {
		entry.items = entry.items.map(removeNominalCode)
		return entry
	})

	return workingPapers
}

export function dematerialiseReport(report) {
	delete report.fileInfo
	delete report.reportData
	return report
}

export function updateAllSchedules(workingPapers) {
	workingPapers.schedules = ["BS"]

	if (workingPapers.expenditureReport?.ledger) updateSchedules(workingPapers, workingPapers.expenditureReport.ledger)
	if (workingPapers.incomeAllocations) updateSchedules(workingPapers, workingPapers.incomeAllocations)
	if (workingPapers.budgets) updateSchedules(workingPapers, workingPapers.budgets)
	if (workingPapers.clientReserves) updateSchedules(workingPapers, workingPapers.clientReserves)
	if (workingPapers.clientTrialBalances) updateSchedules(workingPapers, workingPapers.clientTrialBalances)
	if (workingPapers.drsAndPrepay) updateSchedules(workingPapers, workingPapers.drsAndPrepay)
	if (workingPapers.crsAndAccruals) updateSchedules(workingPapers, workingPapers.crsAndAccruals)

	if (workingPapers.journals)
		updateSchedules(
			workingPapers,
			workingPapers.journals.flatMap(it => it.items)
		)

	updateFees(workingPapers)
}

export function materialiseExpenditureReport(workingPapers, vouchingLedger = false) {
	const ledger = (!!vouchingLedger ? vouchingLedger.filter(it => !["X"].includes(it.status)) : workingPapers.expenditureReport.ledger) || []

	const categories = _.cloneDeep(CATEGORIES)

	const messages = {}

	for (let row of ledger) {
		// if (row.id === undefined) row.id = _.uniqueId("exp_")
		let categoryName = null

		if (row.Category) categoryName = row.Category
		else if (row.vendorName === undefined) categoryName = "Other"
		else if (categories[row.vendorName]) categoryName = row.vendorName
		else categoryName = "Suppliers"

		row.category = categories[categoryName]
		if (row.category === undefined) {
			categories[categoryName] = { label: categoryName, className: "text-warning", total: 0 }
			row.category = categories[categoryName]
		}
		row.category.total = row.category.total + row.total || 0

		row.code = row.code || workingPapers.config.NOMINAL_CODES.lookupAccountName(row.account?.name)?.code
		row.nominalCode = workingPapers.config.NOMINAL_CODES.lookup(row.code)
		row.schedule = row.schedule || row.Schedule || row.Sch

		if (!row.schedule || !row.nominalCode)
			messages["Unallocated expenditure!"] = {
				severity: "danger",
				count: messages["Unallocated expenditure!"]?.count || 0 + 1,
			}
	}

	return { categories, ledger, messages }
}

export function materialiseReport(workingPapers, report) {
	report.reportData = getReportData(workingPapers, report)
	return report
}

export function materialiseDetails(details) {
	details.messages = {}

	if (!details.previousPeriod)
		details.messages["Previous Period is missing!"] = {
			severity: "danger",
		}

	if (!details.previousDuration)
		details.messages["Previous Duration is missing!"] = {
			severity: "danger",
		}

	return details
}

export function materialiseJournals(workingPapers) {
	if (!workingPapers.journals) return []

	for (let entry of workingPapers.journals) {
		if (entry.id === undefined) entry.id = _.uniqueId()

		for (let row of entry.items) {
			if (row.nominalCode?.code !== row.code) {
				row.nominalCode = workingPapers.config.NOMINAL_CODES.lookup(row.code)
				row.code = row.nominalCode?.code
			}
		}
	}

	return workingPapers.journals
}

export function materialiseFees(workingPapers) {
	updateFees(workingPapers)
	return workingPapers.fees
}

export function lookupNominalCodes(workingPapers, data, idPrefix = undefined) {
	for (let row of data) {
		if (row.id === undefined) row.id = _.uniqueId(idPrefix)
		if (row.nominalCode?.code !== row.code) {
			row.nominalCode = workingPapers.config.NOMINAL_CODES.lookup(row.code)
			row.code = row.nominalCode?.code
		}
	}
}

export function materialiseCommonImport(workingPapers, data) {
	if (!data) return []

	lookupNominalCodes(workingPapers, data)
	updateSchedules(workingPapers, data)

	return data
}

export function updateSchedules(workingPapers, data) {
	if (!data) return

	if (workingPapers.schedules === undefined) {
		workingPapers.schedules = []
	}

	_.each(
		data.filter(it => it?.schedule),
		datum => {
			const sch = datum.schedule.toString()
			const foundSchedule = _.find(workingPapers.schedules, it => stringsMatch(it, sch))
			if (foundSchedule) datum.schedule = foundSchedule
			else {
				workingPapers.schedules.push(sch)
				datum.schedule = sch
			}
		}
	)

	workingPapers.schedules = workingPapers.schedules.sort((a, b) => {
		if (a === "BS" && b !== "BS") return +1
		if (a !== "BS" && b === "BS") return -1
		return clearview.caseInsensitiveSort(a, b)
	})
}

export function materialiseFeeMatrix(raw) {
	// Client has been renamed to matrix
	if (raw["Client"]) {
		raw.matrix = raw["Client"]
		delete raw["Client"]
	}
	return raw
}

function materialiseNominalCodes(raw) {
	// ToDo: Check for updated nominal codes? Perhaps have API return file Version in payload?

	clearview.addIdx(raw, "idx", extra => ({ key: extra.code || deClutter(extra.name), desc: `${extra.code} - ${extra.name}` }))

	const accountNameIndex = _.keyBy(
		raw.map(it => ({ key: deClutter(it.name), nominalCode: it })),
		it => it.key
	)

	const generateAliasIndex = () =>
		_.keyBy(
			raw.flatMap(it => (it.aliases || []).map(alias => ({ key: alias, nominalCode: it }))),
			it => it.key
		)
	let aliasIndex = generateAliasIndex()

	const generateKeyIndex = () => _.keyBy(raw, it => it.key)
	let keyIndex = generateKeyIndex()

	return {
		all: raw,
		lookupAccountName: accountName => {
			const conciseAccountName = deClutter(accountName)
			return (accountNameIndex[conciseAccountName] || aliasIndex[conciseAccountName])?.nominalCode
		},
		lookup: (code, name) => {
			if (keyIndex[code]) return keyIndex[code]
			const conciseName = deClutter(name)
			return (accountNameIndex[conciseName] || aliasIndex[conciseName])?.nominalCode
		},
		setAlias: (code, alias) => {
			const conciseAlias = deClutter(alias)
			let existingItem = accountNameIndex[conciseAlias]
			if (existingItem) return

			existingItem = aliasIndex[conciseAlias]
			if (existingItem) {
				if (existingItem.nominalCode === code) return
				existingItem.aliases = existingItem.aliases.filter(it => it !== conciseAlias)
				delete aliasIndex[conciseAlias]
			}

			const item = keyIndex[code]
			if (!item.aliases) item.aliases = []

			item.aliases.push(conciseAlias)

			aliasIndex = generateAliasIndex()
			keyIndex = generateKeyIndex()
		},
		dematerialise: _ =>
			raw.map(it => ({
				code: it.code,
				name: it.name,
				aliases: it.aliases,
				isFee: it.isFee,
			})),
	}
}

export function updateFees(workingPapers) {
	const lines = workingPapers.expenditureReport?.ledger || []

	workingPapers.fees = {
		allocations: [],
		...workingPapers.fees,
		messages: {},
		schedules: (workingPapers.schedules || []).filter(it => !stringsMatch(it, "BS")),
		expenditure: _.sumBy(lines, it => (it.nominalCode?.isFee ? it.total : null)),
	}
	workingPapers.fees.calculated = calculateFee(workingPapers.config.FEE_MATRIX.matrix, workingPapers.fees.schedules, workingPapers.fees.expenditure)

	const removed = _.remove(workingPapers.fees.allocations, it => !workingPapers.fees.schedules.find(sch => sch === it.schedule))
	const added = workingPapers.fees.schedules.reduce((prev, next) => {
		let allocation = workingPapers.fees.allocations.find(it => it.schedule === next)
		if (!allocation) {
			allocation = {
				schedule: next,
				total: "",
			}
			workingPapers.fees.allocations.push(allocation)
			prev.push(allocation)
		}

		const customerConfig = config[clearview.CustomerFor(workingPapers.periodEnd.property).reference]
		const feeNominalCode = workingPapers.config.NOMINAL_CODES.all.find(customerConfig.workingPapers.customerFeeNominalCodePredicate)
		if (
			feeNominalCode &&
			clearview.doesUpdate(allocation, {
				date: workingPapers.periodEnd.endDate,
				code: feeNominalCode.code,
				account: feeNominalCode.name,
				comments: `${customerConfig.alts["brand.shortName"]} Accounts Fee ${clearview.FormatDate(workingPapers.periodEnd.endDate)}`,
			})
		) {
			workingPapers.fees.messages["Fee descriptions have changed. Please be sure to save these Working Papers!"] = {
				severity: "danger",
			}
		}

		return prev
	}, [])

	if (removed.length || added.length) {
		workingPapers.fees.messages["Schedules have changed."] = {
			severity: "danger",
		}
	}
	if (!!_.countBy(workingPapers.fees.allocations, it => clearview.isNothingOrEmpty(it?.total)).true) {
		workingPapers.fees.messages["Some schedules have not been allocated a fee."] = {
			severity: "warning",
		}
	}

	const allocatedFee = _.sumBy(workingPapers.fees.allocations, it => it.total || 0)
	if (allocatedFee < workingPapers.fees.calculated?.total) {
		workingPapers.fees.messages["Calculated fee is not fully allocated."] = {
			severity: "warning",
		}
	}
	if (allocatedFee > workingPapers.fees.calculated?.total) {
		workingPapers.fees.messages["Allocated fees exceed calculated fee."] = {
			severity: "warning",
		}
	}
}

function calculateFee(matrix, schedules, expenditure) {
	if (!schedules.length || clearview.isNothing(expenditure)) return

	const scheduleCount = schedules.length
	const band = _.find(_.keys(matrix), band => expenditure <= parseInt(band)) || _.last(_.keys(matrix))

	return { band: band, schedules: scheduleCount, total: band ? matrix[band][scheduleCount - 1] : undefined }
}
