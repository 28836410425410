import React, { Component } from "react"
import { connect } from "react-redux"

import { Container, Row, Col, UncontrolledTabs, TabPane, Nav, NavItem } from "../../../components"

import * as clearview from "../../../components/@Clearview"

import { HeaderMain } from "../../components/HeaderMain"

import { Releases } from "./components/Releases"
import { Releases2023 } from "./components/Releases2023"
import { Releases2022 } from "./components/Releases2022"
import { Releases2021 } from "./components/Releases2021"
import { Releases2020 } from "./components/Releases2020"
import { PeriodEndColorCoding } from "./components/PeriodEndColorCoding"
import { StageColorCoding } from "./components/StageColorCoding"
import { Licences } from "./components/Licences"
import { Concepts } from "./components/Concepts"

class SupportDashboard extends Component {
	render() {
		const { user } = this.props

		return (
			<React.Fragment>
				<Container>
					<HeaderMain title={<span className="text-primary">{clearview.Icon.support}Support</span>} className="mb-4 mt-0" />
					<Row>
						<Col lg={12}>
							<UncontrolledTabs initialActiveTabId={user && user.isInHouse ? "releases" : "concepts"}>
								<Nav pills className="mb-4 flex-column flex-md-row mt-4 mt-lg-0">
									<NavItem>
										<UncontrolledTabs.NavLink tabId="concepts">Concepts</UncontrolledTabs.NavLink>
									</NavItem>
									<NavItem>
										<UncontrolledTabs.NavLink tabId="periodEnds">Period Ends Key</UncontrolledTabs.NavLink>
									</NavItem>
									<NavItem>
										<UncontrolledTabs.NavLink tabId="stages">Stages Key</UncontrolledTabs.NavLink>
									</NavItem>
									{user && user.isInHouse && (
										<React.Fragment>
											<NavItem>
												<UncontrolledTabs.NavLink tabId="releases">Releases</UncontrolledTabs.NavLink>
											</NavItem>
											<NavItem>
												<UncontrolledTabs.NavLink tabId="releases2023">Releases 2023</UncontrolledTabs.NavLink>
											</NavItem>
											<NavItem>
												<UncontrolledTabs.NavLink tabId="releases2022">Releases 2022</UncontrolledTabs.NavLink>
											</NavItem>
											<NavItem>
												<UncontrolledTabs.NavLink tabId="releases2021">Releases 2021</UncontrolledTabs.NavLink>
											</NavItem>
											<NavItem>
												<UncontrolledTabs.NavLink tabId="releases2020">Releases 2020</UncontrolledTabs.NavLink>
											</NavItem>
										</React.Fragment>
									)}
									<NavItem>
										<UncontrolledTabs.NavLink tabId="licences">Licences</UncontrolledTabs.NavLink>
									</NavItem>
								</Nav>
								<UncontrolledTabs.TabContent>
									{user && user.isInHouse && (
										<React.Fragment>
											<TabPane tabId="releases">
												<Releases />
											</TabPane>
											<TabPane tabId="releases2023">
												<Releases2023 />
											</TabPane>
											<TabPane tabId="releases2022">
												<Releases2022 />
											</TabPane>
											<TabPane tabId="releases2021">
												<Releases2021 />
											</TabPane>
											<TabPane tabId="releases2020">
												<Releases2020 />
											</TabPane>
										</React.Fragment>
									)}

									<TabPane tabId="concepts">
										<Concepts />
									</TabPane>

									<TabPane tabId="periodEnds">
										<PeriodEndColorCoding />
									</TabPane>

									<TabPane tabId="stages">
										<StageColorCoding />
									</TabPane>

									<TabPane tabId="licences">
										<Licences />
									</TabPane>
								</UncontrolledTabs.TabContent>
							</UncontrolledTabs>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	const { userReducer } = state
	return {
		user: userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

export default connect(mapStateToProps)(SupportDashboard)
