import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"
import PropTypes from "prop-types"

import { Badge } from "reactstrap"
import { Comment, Query } from "./Comment"
import { Avatar, AvatarAddOn } from "../../../../components"

class CommentsTable extends React.Component {
	static propTypes = {
		stage: PropTypes.object.isRequired,
		showLog: PropTypes.bool,
	}

	render() {
		const { stage, showLog } = this.props

		const rowClasses = (row, idx) => {
			return row.type === "Query"
				? `csl-expando text-${clearview.CommentTypeColors.Query[row.isWithUs]}`
				: `csl-expando text-${clearview.CommentTypeColors[row.type]}`
		}

		return (
			<ToolkitProvider
				keyField="id"
				data={_.sortBy(
					stage.comments.filter(it => !!showLog || it.type !== "Log" || it.comments),
					it => it.id
				)}
				columns={[
					{
						dataField: "number",
						text: "#",
						headerAlign: "center",
						align: "left",
						formatter: (cell, row) => (row.number ? <span>{QueryRipenessBadge(row)}</span> : getCommentTypeIcon(row)),
					},
					{
						dataField: "assignedTo",
						text: "To",
						headerAlign: "center",
						align: "left",
						formatter: (cell, row) => <span>{AssignedToAvatar(row)}</span>,
					},
					{
						dataField: "subject",
						text: "Activity",
						headerAlign: "left",
						align: "left",
						formatter: (cell, row) => (
							<React.Fragment>
								{row.subject ? QuerySubjectBadge(row) : EventNameBadge(row)}{" "}
								{(
									row.title ||
									clearview.TruncateHtml(row.comments, 60) ||
									`${clearview.FormatDateAndTime(row.createdAt)} ${row.createdBy?.name}` ||
									""
								).substring(0, 80)}
							</React.Fragment>
						),
					},
				]}
			>
				{props => (
					<React.Fragment>
						<BootstrapTable
							keyField="id"
							headerClasses="hidden"
							classes="table-responsive-lg csl-comments-table text-inverse compact-rows table-striped"
							bordered={false}
							expandRow={expandRow(stage)}
							rowClasses={rowClasses}
							responsive
							hover
							{...props.baseProps}
						/>
					</React.Fragment>
				)}
			</ToolkitProvider>
		)
	}
}

export const QueryWithBadges = {
	undefined: (
		<Badge color="pink accent" className="badge-pill">
			Query
		</Badge>
	),
	true: (
		<Badge color="danger" className="badge-pill">
			Query
		</Badge>
	),
	false: (
		<Badge color="warning" className="badge-pill">
			Query
		</Badge>
	),
}

export const EventNameBadges = {
	undefined: undefined,
	"": undefined,
	Comment: (
		<Badge color="info" className="badge-pill">
			Comment
		</Badge>
	),
	Closed: (
		<Badge color="secondary" className="badge-pill">
			Closed
		</Badge>
	),
	NotDue: (
		<Badge color="secondary" className="badge-pill">
			Not Due
		</Badge>
	),
	NotYetOpen: (
		<Badge color="info" className="badge-pill">
			Not Yet open
		</Badge>
	),
	OnHold: (
		<Badge color="info" className="badge-pill">
			On Hold
		</Badge>
	),
	OnTime: (
		<Badge color="success" className="badge-pill">
			On Time
		</Badge>
	),
	Open: (
		<Badge color="success" className="badge-pill">
			Open
		</Badge>
	),
	Overdue: (
		<Badge color="danger" className="badge-pill">
			Overdue
		</Badge>
	),
	"Period End BehindSchedule": (
		<Badge color="pink accent" className="badge-pill">
			Behind Schedule
		</Badge>
	),
	"Period End OnTime": (
		<Badge color="success" className="badge-pill">
			On Time
		</Badge>
	),
	"Period End Overdue": (
		<Badge color="danger" className="badge-pill">
			Overdue
		</Badge>
	),
	"Period End Warning": (
		<Badge color="warning" className="badge-pill">
			Warning
		</Badge>
	),
	PeriodEndDelete: (
		<Badge color="dark" className="badge-pill">
			Deleted
		</Badge>
	),
	RequestClosed: (
		<Badge color="secondary" className="badge-pill">
			Request Closed
		</Badge>
	),
	Warning: (
		<Badge color="warning" className="badge-pill">
			Warning
		</Badge>
	),
}

export function queryColor(query) {
	const queryColors = {
		undefined: "pink accent",
		true: "danger",
		false: "warning",
	}
	if (["ClosedQuery"].includes(query.type)) return "success"
	if (["DraftQuery"].includes(query.type)) return "secondary"
	if (!query.isCurrent) return "dark"
	return queryColors[query.isWithUs]
}

export function QuerySubjectBadge(query, options = { className: "badge-pill" }) {
	return (
		<Badge color={queryColor(query)} className={options.className}>
			{query.subject}
		</Badge>
	)
}

export function EventNameBadge(event) {
	return event.type === "Query" ? QueryWithBadges[event.isWithUs] : EventNameBadges[event.eventName]
}

export function AssignedToAvatar(query) {
	const isPrivateBadge = QueryIsPrivateBadge(query)
	if (query.assignedTo)
		return (
			<Avatar.Font
				title={`Assigned to ${query.assignedTo.name} ${isPrivateBadge ? "(Private. This query is only visible In House)" : ""}`}
				style={{ marginTop: 8 }}
				size="sm"
				user={query.assignedTo}
				addOns={[isPrivateBadge]}
			></Avatar.Font>
		)
}

export function QueryIsPrivateBadge(query) {
	if (query.createdBy?.isInHouse && query.assignedTo?.isInHouse)
		return <AvatarAddOn.Icon className="fa fa-user-times" color="dark" title="Private. This query is only visible In House." key="avatar-icon-bg" />
}

export function QueryRipenessBadge(query, options = {}) {
	const ripenessIcon =
		query.type === "ClosedQuery" ? null : query.type === "DraftQuery" ? (
			<i title={`This is a Draft query.`} className="fa fa-edit ml-2 text-secondary"></i>
		) : !query.isCurrent ? (
			<i
				title={`This query in stage ${query.stage.templateStage.sequence} but this Period End is only in stage ${query.stage.periodEnd.currentStage.templateStage.sequence}.`}
				className="fa fa-hourglass-o ml-2 text-dark"
			></i>
		) : query.ageInDays <= 0 ? null : query.ageInDays <= 2 ? (
			<i title={`There has been no activity on this query in ${query.ageInDays} days.`} className="fa fa-hourglass-1 ml-2 text-success"></i>
		) : query.ageInDays <= 7 ? (
			<i title={`There has been no activity on this query in ${query.ageInDays} days.`} className="fa fa-hourglass-2 ml-2 text-warning"></i>
		) : (
			<i title={`There has been no activity on this query in ${query.ageInDays} days.`} className="fa fa-hourglass-3 ml-2 text-danger"></i>
		)

	return (
		<React.Fragment>
			<Badge color={queryColor(query)} title={queryNumberTitle(query)}>
				{!query.isCurrent && query.type === "Query" && <span>S{query.stage.templateStage?.sequence}:</span>}Q{query.number}
			</Badge>
			{ripenessIcon}
		</React.Fragment>
	)
}

function queryNumberTitle(query) {
	switch (query.type) {
		case "Query":
			if (query.isWithUs) return "Query is with us"
			else return "Query is with them"
		default:
			return `${query.createdBy.isInHouse ? "In-House" : "Client"} ${_.startCase(query.type)}`
	}
}

export function getCommentTypeIcon(comment) {
	return comment.type === "Query" ? clearview.CommentTypeIcon.Query[comment.isWithUs] : clearview.CommentTypeIcon[comment.type]
}

function expandRow(stage) {
	// const expandedComments = comments.filter(it => !["NotYetOpen","Closed"].includes(it.status) ).map(it => it.id)

	return {
		// expanded: expandedComments,
		renderer: row => (["DraftQuery", "Query", "ClosedQuery"].includes(row.type) ? <Query stage={stage} query={row} /> : <Comment comment={row} />),
		showExpandColumn: false,
		parentClassName: "csl-expanded",
		className: "csl-expanded",
	}
}

export default CommentsTable
