import React from "react"
import { connect } from "react-redux"

import * as clearview from "../../../../components/@Clearview"
import PropTypes from "prop-types"

import { IconWithBadge, Badge } from "../../../../components"

class PeriodEndBadges extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired,
	}

	render() {
		const { periodEnd } = this.props

		const anyBadges =
			periodEnd.badges &&
			(periodEnd.badges.comments ||
				periodEnd.badges.openQueriesWithUs ||
				periodEnd.badges.openQueriesWithThem ||
				periodEnd.badges.draftQueries ||
				periodEnd.badges.closedQueries)
			//  ||				periodEnd.badges.attachments

		if (anyBadges)
			return (
				<React.Fragment>
					{!!periodEnd.badges.draftQueries && (
						<span title="Draft Queries">
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors["DraftQuery"]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors["DraftQuery"]}>
										{periodEnd.badges.draftQueries}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass["DraftQuery"]} />
							</IconWithBadge>
						</span>
					)}
					{!!periodEnd.badges.openQueriesWithUs && (
						<span title={`Open Queries with ${clearview.Us(this.props.user).name}`}>
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors.Query[true]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors.Query[true]}>
										{periodEnd.badges.openQueriesWithUs}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass.Query[true]} />
							</IconWithBadge>
						</span>
					)}
					{!!periodEnd.badges.openQueriesWithThem && (
						<span title={`Open Queries with ${clearview.Them(this.props.user).name}`}>
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors.Query[false]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors.Query[false]}>
										{periodEnd.badges.openQueriesWithThem}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass.Query[false]} />
							</IconWithBadge>
						</span>
					)}
					{!!periodEnd.badges.closedQueries && (
						<span title="Closed Queries">
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors["ClosedQuery"]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors["ClosedQuery"]}>
										{periodEnd.badges.closedQueries}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass["ClosedQuery"]} />
							</IconWithBadge>
						</span>
					)}
					{!!periodEnd.badges.comments && (
						<span title="Comments">
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors["Comment"]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors["Comment"]}>
										{periodEnd.badges.comments}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass["Comment"]} />
							</IconWithBadge>
						</span>
					)}
					{/* {!!periodEnd.badges.attachments && (
						<span title="Attachments">
							<IconWithBadge
								className={`text-${clearview.EntityColors.Attachment}`}
								badge={
									<Badge pill color={clearview.EntityColors.Attachment}>
										{periodEnd.badges.attachments}
									</Badge>
								}
							>
								{clearview.Icon.attachments}
							</IconWithBadge>
						</span>
					)} */}
				</React.Fragment>
			)
		return null
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

PeriodEndBadges = connect(mapStateToProps)(PeriodEndBadges)
export { PeriodEndBadges }
