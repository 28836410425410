import React from "react"
import PropTypes from "prop-types"

const AnimatedProgressBar = props => (
	<div className="animatedProgressBar isBusy" style={{ display: props.isVisible ? "block" : "none" }}>
		<div></div>
	</div>
)
AnimatedProgressBar.propTypes = {
	isVisible: PropTypes.bool,
}
AnimatedProgressBar.defaultProps = {
	isVisible: false,
}

export { AnimatedProgressBar }
