import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import {
	Row,
	Col,
	Form,
	FormGroup,
	FormText,
	Input,
	InputGroup,
	InputGroupAddon,
	Label,
	UncontrolledModal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"
import AttributesTable from "./AttributesTable"

class EditClient_Form extends React.Component {
	static propTypes = {
		client: PropTypes.object.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		if (props.client) {
			this.state = {
				id: props.client.id,
				type: props.client.type,
				lft: props.client.lft,
				rgt: props.client.rgt,
				reference: props.client.reference,
				name: props.client.name,
				attributes: props.client.attributes,
				"address.name": props.client.address?.name,
				"address.fullAddressExcludingPostcode": props.client.address?.fullAddressExcludingPostcode,
				"address.postcode": props.client.address?.postcode,
			}
		} else this.state = {}

		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.isPropertyRequired = this.isPropertyRequired.bind(this)
	}

	handleActionButtonClick(e) {
		clearview.StopPropagation(e)

		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}

		const clientUpdate = {
			...this.state,
			address: {
				name: this.state["address.name"],
				fullAddressExcludingPostcode: this.state["address.fullAddressExcludingPostcode"],
				postcode: this.state["address.postcode"],
			},
		}

		this.props.doAction(clientUpdate)
		return true
	}

	handleChange(e) {
		let value = e.target.name === "address.postcode" && e.target.value ? e.target.value.toUpperCase() : e.target.value
		if (e.target.type === "number") value = parseInt(e.target.value)

		this.setState({
			[e.target.name]: value,
		})
	}

	isPropertyRequired(propertyName) {
		return this.state[propertyName] === undefined || !this.state[propertyName]?.length
	}

	get isValid() {
		return (
			!this.isPropertyRequired("reference") &&
			!this.isPropertyRequired("name") &&
			!this.isPropertyRequired("address.name") &&
			!this.isPropertyRequired("address.fullAddressExcludingPostcode") &&
			!this.isPropertyRequired("address.postcode")
		)
	}

	render() {
		const { client } = this.props

		const pageSpec = ["Client", "Landlord", "Property"].includes(client.role)
			? { maxWidth: 750, col1: 6, col2: 6 }
			: { maxWidth: undefined, col1: 12, col2: 0 }

		return (
			<UncontrolledModal style={{ maxWidth: pageSpec.maxWidth }} target="EditClient_Form">
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="div">
						<div className="d-flex">
							<h1 className="text-primary mr-2">{clearview.Icon.client.Client}</h1>
							<h6>
								Edit <b className="nowrap">{client?.name}</b>
							</h6>
						</div>
					</ModalHeader>

					<ModalBody>
						<Row className="mb-0">
							<Col lg={pageSpec.col1}>
								<FormGroup row>
									<InputGroup>
										<InputGroupAddon addonType="prepend">Ref:</InputGroupAddon>
										<Input
											invalid={this.state.showValidation && this.isPropertyRequired("reference")}
											type="text"
											name="reference"
											id="reference"
											placeholder="Ref..."
											value={this.state.reference}
											onKeyDown={clearview.ValidRefCharacters}
											onChange={this.handleChange}
										/>
									</InputGroup>
									<FormText hidden={!this.state.showValidation || !this.isPropertyRequired("reference")} className="text-danger">
										Ref is required and must be unique
									</FormText>
								</FormGroup>
								<FormGroup row>
									<InputGroup>
										<InputGroupAddon addonType="prepend">Name:</InputGroupAddon>
										<Input
											invalid={this.state.showValidation && this.isPropertyRequired("name")}
											type="text"
											name="name"
											id="name"
											placeholder="Name..."
											value={this.state.name}
											onChange={this.handleChange}
										/>
									</InputGroup>
									<FormText hidden={!this.state.showValidation || !this.isPropertyRequired("name")} className="text-danger">
										Name is required and must be unique
									</FormText>
								</FormGroup>
								<FormGroup row>
									<Label>Address:</Label>
								</FormGroup>
								<FormGroup row>
									<InputGroup>
										<InputGroupAddon addonType="prepend">To:</InputGroupAddon>
										<Input
											invalid={this.state.showValidation && this.isPropertyRequired("address.name")}
											type="text"
											name="address.name"
											id="address.name"
											placeholder="Name..."
											value={this.state["address.name"]}
											onChange={this.handleChange}
										/>
									</InputGroup>
									<FormText hidden={!this.state.showValidation || !this.isPropertyRequired("address.name")} className="text-danger">
										Address Name is required
									</FormText>
								</FormGroup>
								<FormGroup row>
									<InputGroup>
										<InputGroupAddon addonType="prepend">Address:</InputGroupAddon>
										<Input
											invalid={this.state.showValidation && this.isPropertyRequired("address.fullAddressExcludingPostcode")}
											type="textarea"
											name="address.fullAddressExcludingPostcode"
											id="address.fullAddressExcludingPostcode"
											placeholder="Full Address Excluding Postcode..."
											value={this.state["address.fullAddressExcludingPostcode"]}
											onChange={this.handleChange}
											rows={6}
										/>
									</InputGroup>
									<FormText
										hidden={!this.state.showValidation || !this.isPropertyRequired("address.fullAddressExcludingPostcode")}
										className="text-danger"
									>
										Address is required
									</FormText>
								</FormGroup>
								<FormGroup row>
									<InputGroup>
										<InputGroupAddon addonType="prepend">Postcode:</InputGroupAddon>
										<Input
											invalid={this.state.showValidation && this.isPropertyRequired("address.postcode")}
											type="text"
											name="address.postcode"
											id="address.postcode"
											placeholder="Postcode..."
											value={this.state["address.postcode"]}
											onChange={this.handleChange}
										/>
									</InputGroup>
									<FormText hidden={!this.state.showValidation || !this.isPropertyRequired("address.postcode")} className="text-danger">
										Postcode is required
									</FormText>
								</FormGroup>
							</Col>
							<Col lg={pageSpec.col2} hidden={pageSpec.col2 === 0}>
								<FormGroup>
									<AttributesTable
										key="attributesTableEdit"
										business={this.state}
										isEditing={true}
										name="attributes"
										onChange={this.handleChange}
									/>
								</FormGroup>
							</Col>
						</Row>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close color="default" className="text-primary" disabled={!this.isValid} onClick={this.handleActionButtonClick}>
							Save
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAction: client =>
		dispatch({
			type: ACTIONS.CLIENT_UPDATE,
			client: client,
		}),
})

export default connect(null, mapDispatchToProps)(EditClient_Form)
