import React from "react"
import { connect } from "react-redux"
import { WP_ACTIONS } from "./store"

import * as _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import FileUploadButton, { excelSheetReader } from "../../Workflows/components/FileUploadButton"
import { Accordion, Table, SimpleTable, Row, Col } from "../../../../components"
import ImportAllocationsTable from "./IncomeAllocationsTable"

function IncomeReportPage({ isReadOnly, incomeReport, onReadWorksheet }) {
	return (
		<React.Fragment>
			<Row className="mt-2">
				<Col lg={5}>
					<h5 className="mt-4">Income Summary</h5>
					<IncomeSummary summary={incomeReport.summary} />
				</Col>
				<Col lg={7}>
					<h5 className="mt-4">Income Allocations</h5>
					<ImportAllocationsTable isReadOnly={isReadOnly} />
				</Col>
			</Row>
			<Row className="mt-2">
				<Col>
					<Accordion initialOpen={true}>
						<Accordion.Header className="d-flex h6">Income Report Details</Accordion.Header>
						<Accordion.Body>
							<IncomeData data={incomeReport.data} />
						</Accordion.Body>
					</Accordion>
				</Col>
			</Row>
			{!isReadOnly && (
				<FileUploadButton
					className="mt-2"
					onUploaded={excelSheetReader(onReadWorksheet, null, false, [
						"Audit",
						"Period From",
						"Period to",
						"Transaction Description",
						"Posted",
						"Trans Date",
						"Nominal",
						"Heading Description",
						"Nett",
						"VAT",
						"Gross",
					])}
					accept=".xls,.xlsx,.csv"
				>
					{clearview.Icon.upload} Import Income Report...
				</FileUploadButton>
			)}
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		incomeReport: state.workingPapersReducer.workingPapers.incomeReport,
	}
}

const mapDispatchToProps = dispatch => ({
	onReadWorksheet: (file, data, worksheetName) =>
		dispatch({
			type: WP_ACTIONS.READ_INCOME_REPORT_WORKSHEET,
			page: "incomeReport",
			file,
			data,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(IncomeReportPage)

function IncomeSummary({ summary }) {
	if (!summary) return

	const categories = _.keys(summary)

	let grandTotal = 0

	return (
		<Table className="compact-table compact-rows">
			<tbody>
				{categories.map((categoryName, cIdx) => {
					const total = summary[categoryName].total || 0
					grandTotal = grandTotal + total

					return (
						<tr key={`cat_${cIdx}`}>
							<th>{categoryName}</th>
							<td className="currency text-dark">{clearview.FormatCurrency(total)}</td>
						</tr>
					)
				})}
			</tbody>
			{!!(categories > 1) && (
				<tfoot>
					<tr>
						<th>Grand Total</th>
						<td className="currency text-dark">{clearview.FormatCurrency(grandTotal)}</td>
					</tr>
				</tfoot>
			)}
		</Table>
	)
}

function IncomeData({ data }) {
	if (!data) return

	const columns = {
		Audit: { map: clearview.tidy.string },
		"Period From": { map: clearview.tidy.date },
		"Period to": { map: clearview.tidy.date },
		"Transaction Description": { map: clearview.tidy.string },
		Posted: { map: clearview.tidy.date },
		"Trans Date": { map: clearview.tidy.date },
		"Heading Description": {
			map: it => (it?.endsWith("Totals") ? <span className="text-dark text-total">{clearview.tidy.string(it)}</span> : clearview.tidy.string(it)),
		},
		Gross: {
			map: (it, row) =>
				row["Heading Description"]?.endsWith("Totals") ? (
					<span className="text-total">{clearview.tidy.currency(it)}</span>
				) : (
					clearview.tidy.currency(it)
				),
			className: "currency text-dark",
		},
	}

	return <SimpleTable columns={columns} data={data} />
}
