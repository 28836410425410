module.exports = {
    "body-color": "#8493A5",
    "primary": "#1EB7FF",
    "info": "#33AE9A",
    "success": "#1BB934",
    "warning": "#F7BF47",
    "danger": "#ED1C24",
    "secondary": "#868E96",
    "dark": "#3F4651",
    "light": "#F8F9FA",
    "white": "#FFFFFF",
    "blue": "#0081FC",
    "indigo": "#6610f2",
    "purple": "#CA8EFF",
    "pink": "#e83e8c",
    "red": "#ED1C24",
    "orange": "#F27212",
    "yellow": "#F7BF47",
    "green": "#1BB934",
    "teal": "#20c997",
    "cyan": "#33AE9A",
    "gray-100": "#F8F9FA",
    "gray-200": "#E9ECEF",
    "gray-300": "#DEE2E6",
    "gray-400": "#CED4DA",
    "gray-500": "#ADB5BD",
    "gray-600": "#868E96",
    "gray-700": "#5D636D",
    "gray-800": "#3F4651",
    "gray-900": "#353C48",
    "facebook": "#3A6094",
    "twitter": "#61AEDA",
    "lastfm": "#DE0F35",
    "pinterest": "#BC0223",
    "linkedin": "#097EB1",
    "medium": "#60B660",
    "skype": "#13B4ED",
    "foursquare": "#F64A78",
    "android": "#A8C64B",
    "spotify": "#3BD46A",
    "paypal": "#2C9CD5",
    "dribbble": "#EF6998",
    "youtube": "#EB1627",
    "windows": "#1AC1F0",
    "amazon": "#FDA12F",
    "primary-01": "rgba(46, 165, 246, 0.1)",
    "primary-02": "rgba(46, 165, 246, 0.2)",
    "primary-03": "rgba(46, 165, 246, 0.3)",
    "primary-04": "rgba(46, 165, 246, 0.4)",
    "primary-05": "rgba(46, 165, 246, 0.5)",
    "primary-06": "rgba(46, 165, 246, 0.6)",
    "primary-07": "rgba(46, 165, 246, 0.7)",
    "primary-08": "rgba(46, 165, 246, 0.8)",
    "primary-09": "rgba(46, 165, 246, 0.9)",
    "info-01": "rgba(57, 174, 154, 0.1)",
    "info-02": "rgba(57, 174, 154, 0.2)",
    "info-03": "rgba(57, 174, 154, 0.3)",
    "info-04": "rgba(57, 174, 154, 0.4)",
    "info-05": "rgba(57, 174, 154, 0.5)",
    "info-06": "rgba(57, 174, 154, 0.6)",
    "info-07": "rgba(57, 174, 154, 0.7)",
    "info-08": "rgba(57, 174, 154, 0.8)",
    "info-09": "rgba(57, 174, 154, 0.9)",
    "success-01": "rgba(41, 183, 60, 0.1)",
    "success-02": "rgba(41, 183, 60, 0.2)",
    "success-03": "rgba(41, 183, 60, 0.3)",
    "success-04": "rgba(41, 183, 60, 0.4)",
    "success-05": "rgba(41, 183, 60, 0.5)",
    "success-06": "rgba(41, 183, 60, 0.6)",
    "success-07": "rgba(41, 183, 60, 0.7)",
    "success-08": "rgba(41, 183, 60, 0.8)",
    "success-09": "rgba(41, 183, 60, 0.9)",
    "warning-01": "rgba(247, 189, 79, 0.1)",
    "warning-02": "rgba(247, 189, 79, 0.2)",
    "warning-03": "rgba(247, 189, 79, 0.3)",
    "warning-04": "rgba(247, 189, 79, 0.4)",
    "warning-05": "rgba(247, 189, 79, 0.5)",
    "warning-06": "rgba(247, 189, 79, 0.6)",
    "warning-07": "rgba(247, 189, 79, 0.7)",
    "warning-08": "rgba(247, 189, 79, 0.8)",
    "warning-09": "rgba(247, 189, 79, 0.9)",
    "yellow-01": "rgba(255, 198, 59, 0.1)",
    "yellow-02": "rgba(255, 198, 59, 0.2)",
    "yellow-03": "rgba(255, 198, 59, 0.3)",
    "yellow-04": "rgba(255, 198, 59, 0.4)",
    "yellow-05": "rgba(255, 198, 59, 0.5)",
    "yellow-06": "rgba(255, 198, 59, 0.6)",
    "yellow-07": "rgba(255, 198, 59, 0.7)",
    "yellow-08": "rgba(255, 198, 59, 0.8)",
    "yellow-09": "rgba(255, 198, 59, 0.9)",
    "danger-01": "rgba(220, 53, 69, 0.1)",
    "danger-02": "rgba(220, 53, 69, 0.2)",
    "danger-03": "rgba(220, 53, 69, 0.3)",
    "danger-04": "rgba(220, 53, 69, 0.4)",
    "danger-05": "rgba(220, 53, 69, 0.5)",
    "danger-06": "rgba(220, 53, 69, 0.6)",
    "danger-07": "rgba(220, 53, 69, 0.7)",
    "danger-08": "rgba(220, 53, 69, 0.8)",
    "danger-09": "rgba(220, 53, 69, 0.9)",
    "secondary-01": "rgba(108, 117, 125, 0.1)",
    "secondary-02": "rgba(108, 117, 125, 0.2)",
    "secondary-03": "rgba(108, 117, 125, 0.3)",
    "secondary-04": "rgba(108, 117, 125, 0.4)",
    "secondary-05": "rgba(108, 117, 125, 0.5)",
    "secondary-06": "rgba(108, 117, 125, 0.6)",
    "secondary-07": "rgba(108, 117, 125, 0.7)",
    "secondary-08": "rgba(108, 117, 125, 0.8)",
    "secondary-09": "rgba(108, 117, 125, 0.9)",
    "dark-01": "rgba(52, 58, 64, 0.1)",
    "dark-02": "rgba(52, 58, 64, 0.2)",
    "dark-03": "rgba(52, 58, 64, 0.3)",
    "dark-04": "rgba(52, 58, 64, 0.4)",
    "dark-05": "rgba(52, 58, 64, 0.5)",
    "dark-06": "rgba(52, 58, 64, 0.6)",
    "dark-07": "rgba(52, 58, 64, 0.7)",
    "dark-08": "rgba(52, 58, 64, 0.8)",
    "dark-09": "rgba(52, 58, 64, 0.9)",
    "white-01": "rgba(248, 249, 250, 0.1)",
    "white-02": "rgba(248, 249, 250, 0.2)",
    "white-03": "rgba(248, 249, 250, 0.3)",
    "white-04": "rgba(248, 249, 250, 0.4)",
    "white-05": "rgba(248, 249, 250, 0.5)",
    "white-06": "rgba(248, 249, 250, 0.6)",
    "white-07": "rgba(248, 249, 250, 0.7)",
    "white-08": "rgba(248, 249, 250, 0.8)",
    "white-09": "rgba(248, 249, 250, 0.9)",
    "black-01": "rgba(0, 0, 0, 0.1)",
    "black-02": "rgba(0, 0, 0, 0.2)",
    "black-03": "rgba(0, 0, 0, 0.3)",
    "black-04": "rgba(0, 0, 0, 0.4)",
    "black-05": "rgba(0, 0, 0, 0.5)",
    "black-06": "rgba(0, 0, 0, 0.6)",
    "black-07": "rgba(0, 0, 0, 0.7)",
    "black-08": "rgba(0, 0, 0, 0.8)",
    "black-09": "rgba(0, 0, 0, 0.9)",
    "purple-01": "rgba(111, 66, 193, 0.1)",
    "purple-02": "rgba(111, 66, 193, 0.2)",
    "purple-03": "rgba(111, 66, 193, 0.3)",
    "purple-04": "rgba(111, 66, 193, 0.4)",
    "purple-05": "rgba(111, 66, 193, 0.5)",
    "purple-06": "rgba(111, 66, 193, 0.6)",
    "purple-07": "rgba(111, 66, 193, 0.7)",
    "purple-08": "rgba(111, 66, 193, 0.8)",
    "purple-09": "rgba(111, 66, 193, 0.9)"
};