import { call, put, takeLatest } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

function* fetchTemplate(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const template = yield call(Api.fetchTemplate, action.template.id)

		yield put({
			type: ACTIONS.TEMPLATE_FETCHED,
			template: template,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 020",
			type: ACTIONS.TEMPLATE_FETCH_FAILED,
			message: e.message,
		})
	}
}

function* updateTemplate(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const template = yield call(Api.updateTemplate, action.payload.template)

		yield put({
			type: ACTIONS.TEMPLATE_UPDATED,
			template: template,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 021",
			type: ACTIONS.TEMPLATE_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* retireTemplate(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const template = yield call(Api.retireTemplate, action.template, action.nextId)

		yield put({
			type: ACTIONS.TEMPLATE_RETIRED,
			template: template,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 028",
			type: ACTIONS.TEMPLATE_RETIRE_FAILED,
			message: e.message,
		})
	}
}

export default function* templateUpdateSaga() {
	yield takeLatest(ACTIONS.TEMPLATE_FETCH, fetchTemplate)
	yield takeLatest(ACTIONS.TEMPLATE_UPDATE, updateTemplate)
	yield takeLatest(ACTIONS.TEMPLATE_RETIRE, retireTemplate)
}
