import Formatters from "./Formatters"
import React, { useState } from "react"

import * as _ from "lodash"
import * as clearview from "../../../../../components/@Clearview"
import * as Api from "../../../../../api/CustomerApi"

import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer"
import {
	CustomInput,
	UncontrolledTabs,
	NavItem,
	TabPane,
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "../../../../../components"
import { InputPrompt, NOT_BLANK_CONSTRAINT } from "../../../../components/InputPrompt"

import { ItemEditor } from "./ItemEditor"

import Pdf from "./Pdf"

import { DetailsDataTables } from "./ParametersEditor"
import ParametersEditor from "./ParametersEditor"
import ChecklistEditor from "./ChecklistEditor"
import { DropdownDivider } from "react-bootstrap"

function tabIdGenerator(pageName, perIdx) {
	return `tabReport.${pageName}.${perIdx ?? 0}`
}

export function EditorTabs_NavItems({ report }) {
	if (!report || !report.reportData) return
	return (
		<React.Fragment>
			<ReportMenuNavItem tabId={tabIdGenerator("#parameters")}>Parameters</ReportMenuNavItem>
			<ReportMenuNavItem tabId={tabIdGenerator("#pages")}>Pages</ReportMenuNavItem>
			{report.template.pages
				.map(page => {
					if (page.per)
						return report.reportData[page.per].map((pageData, perIdx) => (
							<ReportMenuNavItem key={tabIdGenerator(page.page, perIdx)} tabId={tabIdGenerator(page.page, perIdx)}>
								{pageData.name}
							</ReportMenuNavItem>
						))
					else
						return [
							<ReportMenuNavItem key={tabIdGenerator(page.page)} tabId={tabIdGenerator(page.page)}>
								{_.startCase(page.page)}
							</ReportMenuNavItem>,
						]
				})
				.flat()}
			<ReportMenuNavItem tabId={tabIdGenerator("#preview")}>{clearview.Icon.FileType.Pdf} Preview</ReportMenuNavItem>
		</React.Fragment>
	)

	function ReportMenuNavItem({ tabId, children }) {
		return (
			<NavItem className="text-info">
				<UncontrolledTabs.NavLink tabId={tabId}>{children}</UncontrolledTabs.NavLink>
			</NavItem>
		)
	}
}

export function EditorTabs_TabPanes({ config, report, onAfterSaveReport, onUpdateTemplate, onUpdateParameters }) {
	if (!report || !report.reportData) return
	const pdfSize = { width: 1000, height: 830 }

	const template = report.template
	// const [template, setTemplate] = useState(_.cloneDeep(reportTemplate))
	const setTemplate = updatedTemplate => {
		onUpdateTemplate(updatedTemplate)
	}
	const setParameters = updatedParameters => {
		onUpdateParameters(updatedParameters)
	}

	const folder = `/periodEnd/${report.reportData.periodEnd.id}/accounts`

	const [isTemplateChanged, setIsTemplateChanged] = useState(false)
	const [workingPapersReport, setWorkingPapersReport] = useState(false)
	const [pdfBlob, setPdfBlob] = useState(false)
	const [isPdfCurrent, setIsPdfCurrent] = useState(false)
	const [isPromptSaveReport, setIsPromptSaveReport] = useState(false)
	const [isPdfSaved, setIsPdfSaved] = useState(false)

	const [itemEditor, setItemEditor] = useState(false)
	const [dataCatalogue, setDataCatalogue] = useState([])

	const onInsert = (path, value) => {
		const pathParts = path.split("#")
		const insertAtIndex = pathParts.length === 1 ? 0 : parseInt(pathParts[1]) + 1
		const element = _.get(template, pathParts[0])
		element.splice(insertAtIndex, 0, value)
		setTemplate({ ...template })
		setIsTemplateChanged(true)
		setIsPdfCurrent(false)

		return `${pathParts[0]}#${insertAtIndex}`
	}

	const onUpdate = (path, value) => {
		const element = _.at(template, path)[0]
		_.assign(element, value)
		setTemplate({ ...template })
		setIsTemplateChanged(true)
		setIsPdfCurrent(false)
		setItemEditor(false)
	}

	const onRemove = path => {
		const regex = /(.*)\[(\d+)\]$/gm
		const mutatedArrayPath = regex.exec(path)

		if (mutatedArrayPath.length === 3) {
			let array = _.at(template, mutatedArrayPath[1])[0]
			array.splice(parseInt(mutatedArrayPath[2]), 1)
		}

		setTemplate({ ...template })
		setIsTemplateChanged(true)
		setIsPdfCurrent(false)
		setItemEditor(false)
	}

	const onDeselect = path => {
		const element = _.at(template, path)[0]
		_.assign(element, { selected: false })
		setTemplate({ ...template })
		setIsTemplateChanged(true)
		setIsPdfCurrent(false)
		setItemEditor(false)
	}

	const setExcludePage = (page, value) => {
		page.isExcluded = value
		setTemplate({ ...template })
		setIsTemplateChanged(true)
		setIsPdfCurrent(false)
	}

	const onCancel = _ => {
		setItemEditor(false)
	}

	const excludeProperties = [
		"id",
		"rowVersion",
		"createdAt",
		"createdById",
		"createdBy",
		"changedAt",
		"changedById",
		"changedBy",
		"updatedAt",
		"updatedById",
		"updatedBy",
		"isBusy",
		"isChanged",
		"parentId",
		"lft",
		"rgt",
		"sequence",
		"teams",
		"parent",
		"children",
		"attributes",
		"actions",
		"500000",
		"1000000",
		"2000000",
		"3000000",
		"5000000",
		"7500000",
		"10000000",
		"actors",
		"propertyId",
		"templateId",
		"badges",
		"isFavourite",
		"role",
		"acting",
		"comments",
		"$type",
		"openQueries",
		"openConditions",
		"isPublished",
		"doNotRenew",
		"isAlert",
		"isUnread",
		"lastUpdated",
		"aliases",
		"isFee",
		"idx",
		"key",
		"stages",
		"invoiceAfterStageSequence",
		"countOfPeriodEnds",
		"customer",
		"isInHouse",
		"isAllInHouse",
		"isRetired",
		"status",
		"rag",
	]

	const reduceKeys = (obj, prefix = "") =>
		_.keys(obj)
			.filter(key => !excludeProperties.includes(key))
			.reduce((totals, key) => {
				const at = prefix ? prefix + "." + key.toString() : key
				const child = obj[key]
				if (_.isObject(child) && !_.isArray(child)) totals.push(...reduceKeys(child, at))
				else totals.push(at)
				return totals
			}, [])

	const doShowItemEditor = (path, type, item) => {
		setItemEditor(
			<ItemEditor
				onUpdate={onUpdate}
				onRemove={onRemove}
				onCancel={onCancel}
				path={path}
				type={type}
				item={item}
				config={config}
				dataCatalogue={dataCatalogue}
			/>
		)
	}

	const doRefreshReport = () => {
		setWorkingPapersReport(
			<Pdf
				key="workingPapersReport"
				reportData={report.reportData}
				reportTemplate={template}
				meta={{
					title: `${report.reportData.periodEnd.reference} Report`,
					subject: `${report.reportData.periodEnd.property.reference}: ${report.reportData.periodEnd.property.name}`,
					author: "user.name",
				}}
			/>
		)
		setIsPdfSaved(false)
		setPdfBlob(false)
		setIsPdfCurrent(true)
	}

	if (!workingPapersReport) doRefreshReport()

	const doCancelSaveReport = async () => {
		setIsPromptSaveReport(false)
	}

	const doPromptSaveReport = async () => {
		setIsPromptSaveReport({
			title: "Save Report",
			message: <span>Save report as</span>,
			errors: [
				NOT_BLANK_CONSTRAINT,
				val => (/^[^\s].*[^\s]$/.test(val) ? undefined : "Must not start or end in space"),
				val => (/^[^:"\\\/]+$/.test(val) ? undefined : 'Must not contain " : \\ or /'),
				val =>
					/.+\.pdf$/.test(val) ? undefined : (
						<span>
							Must end with .<i>pdf</i>
						</span>
					),
			],
			isChanged: true,
		})
	}

	const doSaveReport = async filename => {
		setIsPromptSaveReport(false)
		await Api.uploadPresignedBlob(`${folder}/${clearview.MakeSafeFileName(filename.slice(0, -4), "_")}.pdf`, pdfBlob)
		setIsPdfSaved(true)
		onAfterSaveReport()
	}

	let noteNumber = 0
	const NormalPageEditor = ({ path, page }) => {
		noteNumber = 0
		return (
			<div key="reportEditor-page" className="report-editor-page">
				<InsertPoint path={`${path}.content`} />
				{page.content.flatMap((it, idx) => {
					const type = _.keys(it)[0]
					const value = _.values(it)[0]
					const insertPoint = <InsertPoint key={`${path}#${idx}`} path={`${path}.content#${idx}`} />
					const deletePoint = elementTypeIcons[type] && (
						<span
							className="ml-1 fa fa-fw fa-remove danger hover delete-point"
							title={`Click to remove ${elementTypeIcons[type].label}`}
							onClick={e => onRemove(`${path}.content[${idx}]`)}
						/>
					)

					const itemPath = `${path}.content[${idx}]`
					const TypeTag = type

					switch (type) {
						case "tableDef":
							return [<h1 key={`it.${idx}`}>[Table Def]</h1>, insertPoint]
						case "table":
							if (value.src !== undefined)
								return [
									<DetailsTableEditor key={`it.${idx}`} element={it} path={itemPath}>
										{deletePoint}
									</DetailsTableEditor>,
									insertPoint,
								]
							return [
								<TableEditor key={`it.${idx}`} element={it}>
									{deletePoint}
								</TableEditor>,
								insertPoint,
							]
						case "h3":
							noteNumber++
							return [
								<TypeTag key={`it.${idx}`} title={elementTypeIcons[type].label} style={combineClassAndStyle(it)}>
									<NotePreview key={idx} path={itemPath} number={noteNumber} item={it}>
										{value}
									</NotePreview>
									{deletePoint}
								</TypeTag>,
								insertPoint,
							]

						default:
							return [
								<TypeTag key={`it.${idx}`} title={elementTypeIcons[type]?.label} style={combineClassAndStyle(it)}>
									<TextPreview key={idx} path={itemPath} item={it}>
										{value}
									</TextPreview>
									{deletePoint}
								</TypeTag>,
								insertPoint,
							]
					}
				})}
			</div>
		)
	}

	const ChecklistPageEditor = ({ path, page }) => {
		noteNumber = 0
		return (
			<div key="reportEditor-checklist-page" className="report-editor-page report-editor-checklist-page">
				<ChecklistPoint checklist={page} />
				{page.content.flatMap((it, idx) => {
					if (it.selected === false) return
					const type = _.keys(it)[0]
					const value = _.values(it)[0]
					const insertPoint = <InsertPoint key={`${path}#${idx}`} path={`${path}.content#${idx}`} />
					const deselectPoint = elementTypeIcons[type] && (
						<span
							className="ml-1 fa fa-fw fa-ban text-warning hover deselect-point"
							title={`Click to deselect ${elementTypeIcons[type].label}`}
							onClick={e => onDeselect(`${path}.content[${idx}]`)}
						/>
					)

					const itemPath = `${path}.content[${idx}]`
					const TypeTag = type

					switch (type) {
						case "tableDef":
							return [<h1 key={`it.${idx}`}>[Table Def]</h1>, insertPoint]
						case "table":
							if (value.src !== undefined)
								return [
									<DetailsTableEditor key={`it.${idx}`} element={it} path={itemPath}>
										{deselectPoint}
									</DetailsTableEditor>,
									insertPoint,
								]
							return [
								<TableEditor key={`it.${idx}`} element={it}>
									{deselectPoint}
								</TableEditor>,
								insertPoint,
							]
						case "h3":
							noteNumber++
							return [
								<TypeTag key={`it.${idx}`} title={elementTypeIcons[type].label} style={combineClassAndStyle(it)}>
									<NotePreview key={idx} path={itemPath} number={noteNumber} item={it}>
										{value}
									</NotePreview>
									{deselectPoint}
								</TypeTag>,
								insertPoint,
							]

						default:
							return [
								<TypeTag key={`it.${idx}`} title={elementTypeIcons[type]?.label} style={combineClassAndStyle(it)}>
									<TextPreview key={idx} path={itemPath} item={it}>
										{value}
									</TextPreview>
									{deselectPoint}
								</TypeTag>,
								insertPoint,
							]
					}
				})}
			</div>
		)
	}

	const combineClassAndStyle = it => {
		const style = {}
		if (!!it.class)
			_.each(it.class.split(/(\S+)/gm), className => {
				const classDefinition = template.classes[className]
				if (classDefinition) _.assign(style, classDefinition)
			})
		if (it.style) _.assign(style, it.style)
		return style
	}

	const addElement = (e, path, initialValue) => {
		onInsert(path, _.cloneDeep(initialValue))
		clearview.StopPropagation(e)
	}

	const elementTypeIcons = {
		h1: {
			label: "Title (H1)",
			icon: (
				<i className="fa fa-fw fa-header type-icon">
					<sup>1</sup>
				</i>
			),
			initialValue: { h1: "Title" },
		},
		h2: {
			label: "Section Heading (H2)",
			icon: (
				<i className="fa fa-fw fa-header type-icon">
					<sup>2</sup>
				</i>
			),
			initialValue: { h2: "Section" },
		},
		h3: {
			label: "Numbered Heading / Note (H3)",
			icon: (
				<i className="fa fa-fw fa-header type-icon">
					<sup>n</sup>
				</i>
			),
			initialValue: { h3: "Note", id: clearview.GenerateKey() },
		},
		p: { label: "Paragraph", icon: <i className="fa fa-fw fa-paragraph type-icon" />, initialValue: { p: "Paragraph" } },
		table: { label: "Details Table", icon: <i className="fa fa-fw fa-table type-icon" />, initialValue: { table: { src: "" } } },
	}

	const InsertPoint = ({ path, checklist = false }) => {
		return (
			<React.Fragment>
				{!!checklist && <ChecklistEditor id={`checklistEditor_${checklist.page}`} checklist={checklist} title={_.startCase(checklist.page)} />}
				<UncontrolledButtonDropdown direction="right" className={!!checklist ? "insert-point checklist" : "insert-point"}>
					<DropdownToggle color="link">
						{!!checklist && <i className="fa fa-fw fa-check-square-o" />}
						{!checklist && <i className="fa fa-fw fa-plus-square" />}
					</DropdownToggle>
					<DropdownMenu>
						{!!checklist && (
							<React.Fragment>
								<DropdownItem>
									<button id={`checklistEditor_${checklist.page}`} className="text-secondary tight" title="Choose from list...">
										<i className="fa fa-fw fa-check-square-o type-icon" />
										Choose from list...
									</button>
								</DropdownItem>
								<DropdownDivider />
							</React.Fragment>
						)}
						<DropdownItem>
							<span onClick={e => addElement(e, path, elementTypeIcons.h1.initialValue)}>
								{elementTypeIcons.h1.icon}Insert {elementTypeIcons.h1.label}
							</span>
						</DropdownItem>

						<DropdownItem>
							<span onClick={e => addElement(e, path, elementTypeIcons.h2.initialValue)}>
								{elementTypeIcons.h2.icon}Insert {elementTypeIcons.h2.label}
							</span>
						</DropdownItem>

						<DropdownItem>
							<span onClick={e => addElement(e, path, elementTypeIcons.p.initialValue)}>
								{elementTypeIcons.p.icon}Insert {elementTypeIcons.p.label}
							</span>
						</DropdownItem>

						<DropdownItem>
							<span onClick={e => addElement(e, path, elementTypeIcons.h3.initialValue)}>
								{elementTypeIcons.h3.icon}Insert {elementTypeIcons.h3.label}
							</span>
						</DropdownItem>

						<DropdownItem>
							<span onClick={e => addElement(e, path, elementTypeIcons.table.initialValue)}>
								{elementTypeIcons.table.icon}Insert {elementTypeIcons.table.label}
							</span>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</React.Fragment>
		)
	}

	const ChecklistPoint = ({ checklist }) => {
		return (
			<React.Fragment>
				<ChecklistEditor
					id={`checklistEditor_${checklist.page}`}
					checklist={checklist}
					onSave={list => {
						checklist.content = list
						setTemplate(template)
					}}
				/>
				<button id={`checklistEditor_${checklist.page}`} className="text-primary tight" title="Choose from list...">
					<i className="fa fa-fw fa-check-square-o type-icon" />
					Choose from list...
				</button>
			</React.Fragment>
		)
	}

	const DetailsTableEditor = ({ element, path, children }) => (
		<div className="report-editor-details-table">
			<CustomInput
				type="select"
				id={path}
				defaultValue={element?.table?.src}
				title={elementTypeIcons.table.label}
				onChange={e => {
					onUpdate(path, { value: { src: e.target.value } })
				}}
			>
				<option value="">&lt;Unspecified table&gt;</option>
				{DetailsDataTables?.map(it => (
					<option key={it} value={`details.${it}`}>
						{_.startCase(it)}
					</option>
				))}
			</CustomInput>
			{children}
		</div>
	)

	const TableEditor = ({ element, children }) => {
		return (
			<React.Fragment>
				<table className="report-editor-table" border={1}>
					<tbody>
						{element.table.rows?.map((row, idx) => (
							<TableRowEditor key={`tr.${idx}`} element={row} idx={idx} depth={0} />
						))}
					</tbody>
				</table>
				{children}
			</React.Fragment>
		)
	}

	const TableRowEditor = ({ element, idx, depth }) => {
		return (
			<React.Fragment key={`tr.${idx}`}>
				<tr>
					<th className={`th${depth}`}>
						<TextPreview key={`th.${idx}`}>{element.tr}</TextPreview>
					</th>
					{element.columns?.map((col, cIdx) => (
						<td key={`col.${cIdx}`} className={col.class}>
							<TextPreview key={`td.${cIdx}`}>{col.text}</TextPreview>
							{!!col.sumOf && <SumOfEditor sumOf={col.sumOf} />}
						</td>
					))}
				</tr>
				{element.children?.map((child, rIdx) => (
					<TableRowEditor key={`tr.child.${rIdx}`} element={child} idx={rIdx} depth={depth + 1} />
				))}
			</React.Fragment>
		)
	}

	const SumOfEditor = ({ sumOf }) => {
		return (
			<div className="sumOf">
				{sumOf.map((sum, sIdx) => (
					<span key={`token.${sIdx}`} className="token">
						{sum}
					</span>
				))}
			</div>
		)
	}

	const editText = (e, path, item) => {
		doShowItemEditor(path, "text", item)
		clearview.StopPropagation(e)
	}

	const TextPreview = ({ path, children, item }) => {
		return <span onClick={e => editText(e, path, item)}>{previewTokens(children, report.reportData)}</span>
	}

	const editNote = (e, path, item) => {
		doShowItemEditor(path, "note", item)
		clearview.StopPropagation(e)
	}

	const NotePreview = ({ path, children, number, item }) => {
		return (
			<span onClick={e => editNote(e, path, { ...item, number })}>
				<span className="note-number hover">{number}</span>
				{previewTokens(children, report.reportData)}
				{!!item.nominalCodes && !!item.nominalCodes.length && <span className="ml-2 note-info hover">applies to {item.nominalCodes.join(", ")}</span>}
			</span>
		)
	}

	const ParametersTabPane = ({ tabId }) => {
		return (
			<TabPane tabId={tabId} className="overflow-scroll-both" style={pdfSize}>
				<ParametersEditor isReadOnly={false} parameters={report.parameters} periodEnd={report.reportData.periodEnd} setParameters={setParameters} />
			</TabPane>
		)
	}

	const EditorTabPane = ({ page, pageData, tabId }) => {
		let Tag = NormalPageEditor

		switch (page.editor) {
			case "checklist":
				Tag = ChecklistPageEditor
				break
		}

		return (
			<TabPane tabId={tabId} className="overflow-scroll-both" style={pdfSize}>
				<Tag path={`pages[${_.findIndex(template.pages, it => it.page === page.page)}]`} page={page} />
			</TabPane>
		)
	}

	if (!dataCatalogue.length) {
		const simplifiedData = _.cloneDeep(report.reportData)

		_.keys(simplifiedData.allSchedules.groups).forEach(key => {
			simplifiedData.allSchedules.groups[key] = { row: { ..._.values(simplifiedData.allSchedules).find(it => !!it.nominalCode) } }
		})

		simplifiedData.balanceSheet.groups = simplifiedData.allSchedules.groups

		simplifiedData["schedules[n]"] = {
			name: "name",
			...simplifiedData.allSchedules,
		}

		delete simplifiedData.schedules
		delete simplifiedData.template.next
		delete simplifiedData.template.invoiceAfterStage
		delete simplifiedData.periodEnd.template
		delete simplifiedData.periodEnd.property
		delete simplifiedData.periodEnd.currentStage
		delete simplifiedData.periodEnd.stages
		delete simplifiedData.client.parent
		delete simplifiedData.client.children
		delete simplifiedData.property.periodEnds

		setDataCatalogue(reduceKeys(simplifiedData))
	}

	return (
		<React.Fragment>
			<TabPane tabId={tabIdGenerator("#parameters")} style={pdfSize}>
				<ParametersTabPane key={tabIdGenerator("#parameters")} tabId={tabIdGenerator("#parameters")} />
			</TabPane>
			<TabPane tabId={tabIdGenerator("#pages")} style={pdfSize}>
				<table>
					<thead>
						<tr>
							<th>Page</th>
							<th>Include?</th>
						</tr>
					</thead>
					<tbody>
						{template?.pages?.map(page => (
							<tr key={page.page}>
								<td style={{ paddingRight: "6rem" }}>{_.startCase(page.page)}</td>
								<td style={{ textAlign: "center" }} className="hover" onClick={e => setExcludePage(page, !page.isExcluded)}>
									{clearview.Icon.Checked[!page.isExcluded]}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</TabPane>
			<TabPane tabId={tabIdGenerator("#preview")}>
				<div className="d-flex-row justify-content-between">
					<InputPrompt
						name="promptSaveReportName"
						config={isPromptSaveReport}
						isOpen={!!isPromptSaveReport}
						onCancel={doCancelSaveReport}
						onSave={value => {
							doSaveReport(value)
						}}
						defaultValue={`${clearview.MakeSafeFileName(report.reportData.periodEnd.reference + " - " + report.fileInfo.scope, "_")}.pdf`}
					/>
					<PDFDownloadLink
						className={`text-decoration-none nowrap mr-2 mb-1 ${
							!!pdfBlob || !isPdfSaved || !isTemplateChanged ? "text-primary" : !isPdfCurrent ? "text-danger" : "text-secondary"
						}`}
						document={workingPapersReport}
						onClick={e => {
							if (!!pdfBlob && !isPdfSaved && !isTemplateChanged) doPromptSaveReport()
							else if (!isPdfCurrent) doRefreshReport()
							return clearview.StopPropagation(e)
						}}
					>
						{({ blob, url, loading, error }) => {
							if (!loading) {
								setPdfBlob(blob)
							}
							return loading ? (
								<span>{clearview.Icon.busy} Loading document</span>
							) : !isPdfCurrent ? (
								<span>{clearview.Icon.refresh} Refresh</span>
							) : isTemplateChanged ? (
								<span>{clearview.Icon.disabled} Template has not been saved!</span>
							) : (
								<span>{clearview.Icon.save} Save report to Accounts folder</span>
							)
						}}
					</PDFDownloadLink>
				</div>
				<PDFViewer width={pdfSize.width} height={pdfSize.height} showToolbar={true}>
					{workingPapersReport}
				</PDFViewer>
			</TabPane>

			{template.pages
				.map(page => {
					if (page.per)
						return report.reportData[page.per].map((pageData, perIdx) => (
							<EditorTabPane page={page} key={tabIdGenerator(page.page, perIdx)} tabId={tabIdGenerator(page.page, perIdx)} />
						))
					else return [<EditorTabPane page={page} key={tabIdGenerator(page.page)} tabId={tabIdGenerator(page.page)} />]
				})
				.flat()}
			{!!itemEditor && itemEditor}
		</React.Fragment>
	)
}

function splitTokens(path, stringTemplate, reportData) {
	if (!stringTemplate) return []

	return stringTemplate
		.split(/(\{[^\}]+\})/gm)
		.map((part, idx) => {
			if (!/^\{[^\}]+\}$/.test(part)) return { type: "text", path: `${path}#${idx}`, text: part }

			const pipes = part.match(/[^\|(\{\}?! )]+/g)
			if (!pipes.length) return { type: "text", path: `${path}#${idx}`, text: part }

			const at = pipes.shift()

			return { type: "token", path: `${path}#${idx}`, at: at, pipes: pipes }
		})
		.map((it, idx) => {
			switch (it.type) {
				case "token":
					return (
						<span key={`token.${idx}`} className="token">
							{it.at}
							{it.pipes?.length ? "|" : ""}
							{it.pipes?.join("|")}
						</span>
					)

				default:
					return (
						<span key={`text.${idx}`} className="text">
							{it.text}
						</span>
					)
			}
		})
}

export const previewTokens = (stringTemplate, fromNode) => {
	if (!stringTemplate) return stringTemplate

	const items = stringTemplate.split(/(\{[^\}]+\})/gm).flatMap(part => {
		if (!part) return []
		if (!/^\{[^\}]+\}$/.test(part)) return [<span className="text">{part}</span>]

		const pipes = part.match(/[^\|(\{\}?! )]+/g)
		if (!pipes.length)
			return [
				<span className="value" title={part}>
					{part}
				</span>,
			]

		const path = pipes.shift()

		const values = _.at(fromNode, path).filter(it => !!it)
		if (!values.length)
			return [
				<span className="bad" title={path}>
					{path}
				</span>,
			]

		return values.map(it => {
			return (
				<span className="value" title={[path, ...pipes].join(" | ")}>
					{pipes.reduce((prev, pipe) => {
						const formatter = Formatters[pipe] || Formatters.undefined
						return formatter(prev)
					}, it)}
				</span>
			)
		})
	})

	return <span className="tokens">{items}</span>
}
