import React from "react"
import DOMPurify from "dompurify"
import parse from "html-react-parser"

import { Container, Avatar, Card, Media, AvatarAddOn, Badge } from "../../../../components"

import _ from "lodash"

import { S3Folder } from "../../components/s3/S3Folder"
import * as clearview from "../../../../components/@Clearview"
import { CommentActions } from "./CommentActions"
import { QueryActions } from "./QueryActions"
import { RepliesActions } from "./RepliesActions"
import { Replies } from "./Replies"

const Comment = props => {
	return (
		<Container className={props.comment.type}>
			<Media className="mb-2">
				<Media left className="mr-3">
					<CommentAvatar comment={props.comment} />
				</Media>
				<Media body>
					<CommentBody comment={props.comment} />
					<CreatedByFooter comment={props.comment} />
				</Media>
			</Media>
		</Container>
	)
}

const CommentAvatar = props => (
	<Avatar.Font
		size="md"
		user={props.comment.createdBy}
		className="avatar-with-text mr-2"
		addOns={[<AvatarAddOn.Icon className={getCommentIconClass(props.comment)} color={getCommentColor(props.comment)} key="avatar-icon-md" />]}
	/>
)

const CreatedByFooter = props => (
	<div className="mb-2">
		<span className="text-inverse mr-2"> {props.comment.createdBy.name} </span>
		<span className="small text-inverse mr-2">{clearview.FormatDateAndTime(props.comment.createdAt)}</span>
	</div>
)

const CommentBody = props => {
	const { comments, assignedTo } = props.comment
	const color = getCommentColor(props.comment)

	const cleanHTML = DOMPurify.sanitize((comments || "").startsWith("<") ? comments : (comments || "").replaceAll("\n", "<br/>"), {
		USE_PROFILES: { html: true },
	})

	return (
		<Card body className="mb-2" type="border" color={color}>
			{assignedTo && (
				<div className="mb-0">
					To:
					<Avatar.Font size="sm" className="avatar-with-text ml-1 mr-0 mb-2" user={assignedTo} />
					<span className="text-inverse mr-2"> {assignedTo.name}</span>
				</div>
			)}
			<div className="mb-0">
				{parse(cleanHTML)}
				<CommentActions periodEnd={props.comment.stage.periodEnd} stage={props.comment.stage} comment={props.comment} />
			</div>
		</Card>
	)
}

const CommentTerse = props => {
	const comment = props.comment
	const comments = comment.comments
	const color = getCommentColor(props.comment)

	const cleanHTML = DOMPurify.sanitize((comments || "").startsWith("<") ? comments : (comments || "").replaceAll("\n", " "), {
		USE_PROFILES: { html: true },
	})

	return (
		<React.Fragment>
			<Badge color={color} className="badge-pill mr-2">
				{_.startCase(comment.eventName)}
			</Badge>
			{comment.comments && <span className={`small mr-2 text-${color}`}>{parse(cleanHTML)}</span>}
			<span className="text-inverse mr-2">{(comment.closedBy || comment.createdBy).name}</span>
			<span className="small text-inverse mr-2">{clearview.FormatDateAndTime(comment.closedAt || comment.createdAt)}</span>
		</React.Fragment>
	)
}

const QueryBody = props => {
	const { comments, assignedTo } = props.query
	const cleanHTML = DOMPurify.sanitize((comments || "").startsWith("<") ? comments : (comments || "").replaceAll("\n", "<br/>"), {
		USE_PROFILES: { html: true },
	})

	return (
		<Card body className="mb-2" type="border" color={getCommentColor(props.query)}>
			<div className="mb-0">
				{assignedTo && (
					<div className="mb-0">
						To:
						<Avatar.Font size="sm" className="avatar-with-text ml-1 mr-0 mb-2" user={assignedTo} />
						<span className="text-inverse mr-2"> {assignedTo.name}</span>
					</div>
				)}
				{parse(cleanHTML)}
				<QueryActions periodEnd={props.stage.periodEnd} stage={props.stage} query={props.query} />
			</div>
		</Card>
	)
}

const QueryFooter = props => (
	<div className="mb-2">
		<span className="text-inverse mr-1">
			<span className={"small mr-2 text-" + getCommentColor(props.query)}>{clearview.Icon.InHouse[props.query.isWithUs]}</span>
			{clearview.CommentTypeName[props.query.type]}:
		</span>
		<span className={"small mr-2 text-" + getCommentColor(props.query)}>{props.query.reference}</span>
		<span className="text-inverse mr-2"> {props.query.createdBy?.name} </span>
		<span className="small text-inverse mr-2">{clearview.FormatDateAndTime(props.query.createdAt)}</span>
		{props.query.type === "ClosedQuery" && (
			<React.Fragment>
				<span className="text-inverse mr-2"> Closed by </span>
				<span className="text-inverse mr-2"> {props.query.closedBy?.name} </span>
				<span className="small text-inverse mr-2">{clearview.FormatDateAndTime(props.query?.closedAt)}</span>
			</React.Fragment>
		)}
	</div>
)

const RepliesFooter = props => <RepliesActions periodEnd={props.stage.periodEnd} stage={props.stage} query={props.query} />

const Query = props => {
	const color = getCommentColor(props.query)

	return (
		<Media className="mb-2">
			<Media left className="mr-3">
				<CommentAvatar comment={props.query} />
			</Media>
			<Media body>
				<Card body type="border" color={color}>
					<QueryBody stage={props.stage} query={props.query} />
					<QueryFooter query={props.query} />
					<Replies periodEnd={props.stage.periodEnd} query={props.query} items={props.query.replies} startSide={"Right"} />
					<div className="d-flex-row justify-content-between align-items-start">
						<RepliesFooter stage={props.stage} query={props.query} />
						<S3Folder
							folder={clearview.queryFolder(props.stage, props.query)}
							label="Attachments"
							title="Query Attachments"
							className="query"
						></S3Folder>
					</div>
				</Card>
			</Media>
		</Media>
	)
}

function getCommentColor(comment) {
	return comment.type === "Query" ? clearview.CommentTypeColors.Query[comment.isWithUs] : clearview.CommentTypeColors[comment.type]
}
function getCommentIconClass(comment) {
	return comment.type === "Query" ? clearview.CommentTypeIconClass.Query[comment.isWithUs] : clearview.CommentTypeIconClass[comment.type]
}

export { Comment, CommentTerse, Query }
