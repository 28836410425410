import React from "react"
import PropTypes from "prop-types"
import { saveAs } from "file-saver"

import _ from "lodash"

import * as Api from "../../../../api/CustomerApi"
import * as clearview from "../../../../components/@Clearview"
import { Spinner } from "../../../components/Spinner"
import { InputPrompt, NOT_BLANK_CONSTRAINT } from "../../../components/InputPrompt"

import { ChonkyActions, defineFileAction, FileBrowser, FileContextMenu, FileList, FileNavbar, FileToolbar, ChonkyIconName } from "chonky"

import { Container } from "../../../../components"

import Dropzone from "react-dropzone-uploader"

import S3MoveTo from "./S3MoveTo"

export default class S3Navigator extends React.Component {
	static propTypes = {
		folder: PropTypes.string.isRequired,
		requiredSubfolders: PropTypes.array,
		name: PropTypes.string,
		isReadOnly: PropTypes.bool,
		onChange: PropTypes.func,
		hideUpload: PropTypes.bool,
	}

	BUSY_STATE = {
		isBusy: true,
		files: undefined,
		moveItemsIsOpen: false,
		selectedItemsToMove: [],
		rename: false,
	}

	constructor(props) {
		super(props)

		this.state = {
			isBusy: true,
			defaultFileViewActionId: ChonkyActions.EnableListView.id,
			defaultSortActionId: SortByFoldersAction.id,
			folder: this.props.folder,
			requiredSubfolders: this.props.requiredSubfolders,
			showVersions: false,
			role: "Reader",
			files: undefined,
			tree: undefined,
			moveItemsIsOpen: false,
			rename: false,
		}

		this.handleFileAction = this.handleFileAction.bind(this)
		this.loadS3 = this.loadS3.bind(this)
		this.refreshS3 = this.refreshS3.bind(this)
		this.handleItemDefaultAction = this.handleItemDefaultAction.bind(this)
		this.navigateTo = this.navigateTo.bind(this)

		this.getUploadParams = this.getUploadParams.bind(this)
		this.handleChangeStatus = this.handleChangeStatus.bind(this)
		this.onChange = this.onChange.bind(this)

		this.onCancelMoveTo = this.onCancelMoveTo.bind(this)
		this.onSelectMoveTo = this.onSelectMoveTo.bind(this)

		this.onCancelRename = this.onCancelRename.bind(this)
		this.onSaveRename = this.onSaveRename.bind(this)
	}

	componentDidMount() {
		this.loadS3()
	}

	loadS3() {
		return Api.fetchFolder(this.state.folder, this.state.requiredSubfolders, this.state.showVersions).then(folder =>
			this.setState(() => ({
				isBusy: false,
				role: folder.role,
				files: folder.objects?.map(mapToChonkyFiles) || [],
			}))
		)
	}

	async navigateTo(key) {
		this.setState(
			() => ({
				folder: key,
				...this.BUSY_STATE,
			}),
			() => this.loadS3()
		)
	}

	refreshS3() {
		return this.setState(
			() => this.BUSY_STATE,
			() => this.loadS3()
		)
	}

	async createFolder(name) {
		await Api.createFolder(`${this.state.folder}/${name}`)
		return this.refreshS3()
	}

	async deleteFilesOrFolders(filesOrFolders) {
		return Api.deleteFilesOrFolders(filesOrFolders.map(it => it.key)).then(_ =>
			this.onChange(
				"delete",
				filesOrFolders.map(it => it.key)
			)
		)
	}

	async handleItemDefaultAction(item) {
		if (item.isDir === false && ["docx", "doc", "dot", "xls", "xlsx"].includes(item.type) && navigator.userAgent.indexOf("Edg") > -1) {
			console.info("Downloading document because Edge will not open a presignedUrl (AnonRequest.blob)")
			saveAs(await Api.fetchPresignedUrlBlob(item.key, item.versionId), item.name)
		} else if (item.isDir === false) {
			const url = await Api.fetchPresignedUrl(item.key, item.versionId)
			saveAs(url.presignedUrl, item.name)
		} else {
			this.navigateTo(item.key)
		}
	}

	getUploadParams = async ({ file, meta: { name } }) => {
		const options = await Api.fetchOptions(`${this.state.folder}/${name}`)
		let doIt = true

		if (!options.includes("PUT")) {
			clearview.ShowToast("error:Access Denied!", <p>You do not have permission to {options.includes("GET") ? "replace" : "upload"} files.</p>)
			doIt = false
		}
		if (options.includes("DELETE")) {
			doIt = await new Promise((resolve, reject) => {
				clearview.ShowToast(
					"warning:File already exists!",
					<div>
						<p>Do you wish to overwrite the existing file?</p>
						<p>
							{clearview.ActionButtons(
								{ label: "Yes", onClick: e => resolve(true) },
								{ label: "No", onClick: e => resolve(false), isDefault: true }
							)}
						</p>
					</div>,
					false,
					"PromptForOverwrite",
					undefined,
					false,
					_ => resolve(false)
				)
			})
			clearview.DismissToast("PromptForOverwrite")
		}

		if (!doIt) return { method: "PUT", body: file, url: undefined }

		const { presignedUrl } = await Api.fetchPresignedUploadUrl(`${this.state.folder}/${name}`).catch(err => showErr(err))
		return { method: "PUT", body: file, url: presignedUrl }
	}

	handleChangeStatus = ({ meta, remove }, status) => {
		if (status === "done") {
			remove()
			if (!meta.size) {
				clearview.ShowToast(
					"warning",
					<div>
						<p>{meta.name} is 0 bytes.</p>
						<p>Check that you are not trying to upload files from within an open Zip file.</p>
					</div>,
					false
				)
			} else if (this.state.folder.startsWith("/periodEnd/") && meta.name.endsWith(".zip")) {
				Api.processFiles()
				clearview.ShowToast("info", <p>Your zip file will be extracted in the background. Refresh your folder view in a few minutes.</p>)
			}
			this.onChange("upload", [`${this.state.folder}/${meta.name}`])
			this.refreshS3()
		}
	}

	onChange(action, fileList) {
		if (!this.props.onChange) return
		this.props.onChange(action, fileList)
	}

	async handleFileAction(data) {
		switch (data.id) {
			case ChonkyActions.EnableListView.id:
			case ChonkyActions.EnableGridView.id:
			case ChonkyActions.EnableCompactView.id:
				this.setState((prevState, props) => ({
					defaultFileViewActionId: data.id,
				}))
				break

			case SortByFoldersAction.id:
			case ChonkyActions.SortFilesByDate.id:
			case ChonkyActions.SortFilesByName.id:
			case ChonkyActions.SortFilesBySize.id:
				this.setState((prevState, props) => ({
					defaultSortActionId: data.id,
				}))
				break

			case RefreshAction.id:
				this.refreshS3()
				break

			case ChonkyActions.CreateFolder.id:
				let newFolderName = prompt("Please enter the new folder's name:")
				if (!newFolderName || !newFolderName.trim().length) break
				newFolderName = newFolderName.trim()
				if (newFolderName.indexOf(".") >= 0) {
					clearview.ShowToast("error", <p>Folder names must not contain a "."</p>)
					break
				}
				this.createFolder(newFolderName)
				break

			case ShowFileVersionsAction.id:
			case HideFileVersionsAction.id:
				this.setState(prevState => ({ showVersions: !prevState.showVersions }))
				this.loadS3()
				break

			case ChonkyActions.OpenFiles.id:
				//Note that browsers will only open 1 window at a time
				this.handleItemDefaultAction(_.first(data.payload.files))
				break

			case ChonkyActions.DownloadFiles.id:
				const selectedFilesToDownload = data.state.selectedFilesForAction.filter(it => !it.isDir)
				if (selectedFilesToDownload.length > 1) clearview.ShowToast("warning", <p>For security reasons, you may only download one file at a time.</p>)
				this.handleItemDefaultAction(selectedFilesToDownload[0])
				break

			case RenameFileAction.id:
				const selectedFileToRename = data.state.selectedFilesForAction.filter(it => !it.isDir)[0]
				if (!selectedFileToRename) break
				this.setState(() => ({
					isBusy: false,
					rename: {
						file: selectedFileToRename,
						config: {
							title: "Rename File",
							message: (
								<span>
									Rename <b>{selectedFileToRename.name}</b> as
								</span>
							),
							errors: [
								NOT_BLANK_CONSTRAINT,
								val => (/^[^\s].*[^\s]$/.test(val) ? undefined : "Must not start or end in space"),
								val => (/^[^:"\\\/]+$/.test(val) ? undefined : 'Must not contain " : \\ or /'),
								val =>
									/.+\.\w{3,}$/.test(val) ? undefined : (
										<span>
											Must end with .<i>ext</i>
										</span>
									),
							],
						},
						defaultValue: selectedFileToRename.name,
					},
				}))
				break

			case ChonkyActions.MoveFiles.id:
				Api.fetchTree(this.props.folder, this.props.requiredSubfolders).then(folders => {
					this.setState(() => ({
						isBusy: false,
						tree: folders,
						moveItemsIsOpen: true,
						selectedItemsToMove: data.state.selectedFilesForAction,
					}))
				})
				break

			case ChonkyActions.DeleteFiles.id:
				const selectedToDelete = data.state.selectedFilesForAction
				if (
					selectedToDelete.length &&
					window.confirm(`Are you sure you wish to permanently delete the following:\n${selectedToDelete.map(it => it.name).join("\n")}`)
				) {
					try {
						await this.deleteFilesOrFolders(selectedToDelete)
					} catch (err) {
						console.error(err)
						clearview.ShowToast("error", <p>You can only delete folders if they are empty.</p>)
					}

					this.refreshS3()
				}
				break

			default:
				break
		}
	}

	onCancelMoveTo = () => this.setState({ moveItemsIsOpen: false })
	onSelectMoveTo = target => {
		return Api.moveItems(
			target,
			this.state.selectedItemsToMove.map(it => it.key)
		)
			.then(_ =>
				this.onChange(
					"move",
					this.state.selectedItemsToMove.map(it => `/${it.key}`)
				)
			)
			.finally(() => {
				this.refreshS3()
			})
	}

	onCancelRename = () => this.setState({ rename: false })

	onSaveRename = (file, newName) => {
		newName = newName.trim()
		if (!!newName && !file.key.endsWith(`/${newName}`))
			return Api.renameFile(file, newName).finally(() => {
				this.refreshS3()
			})
	}

	validateFile = fileWithMeta => {
		if (!fileWithMeta.file.size && !fileWithMeta.file.type) {
			clearview.ShowToast(
				"error",
				<React.Fragment>
					<p style={{ fontWeight: "bold", color: "crimson" }}>Directories cannot be uploaded!</p>
					<p>You can select up to 20 files to upload, or consider uploading a zip file.</p>
				</React.Fragment>
			)
			return "Directories cannot be uploaded"
		}
	}

	render() {
		const files = this.state.files
		let pathSoFar = this.props.folder
		const folderChain = [
			{ id: "~", name: this.props.name || "Home", key: this.props.folder },
			...clearview
				.AsFolders(this.state.folder)
				.slice(clearview.AsFolders(this.props.folder).length)
				.map((it, idx) => {
					pathSoFar = pathSoFar + "/" + it
					return { id: idx + 1, name: it, key: pathSoFar }
				}),
		]
		const fileActions = [ChonkyActions.DownloadFiles]
		let enableDragAndDrop = false

		fileActions.push(SortByFoldersAction)
		if (!this.props.isReadOnly && ["Member", "Approver", "Admin", "System"].includes(this.state.role)) {
			enableDragAndDrop = !this.props.hideUpload
			fileActions.push(CreateFolderAction)
			fileActions.push(ChonkyActions.DeleteFiles)
			fileActions.push(MoveItemsAction)
			fileActions.push(RenameFileAction)
		}
		if (["Admin", "System"].includes(this.state.role)) {
			fileActions.push(this.state.showVersions ? HideFileVersionsAction : ShowFileVersionsAction)
		}
		fileActions.push(RefreshAction)

		if (!files) return <Container style={{ height: "100%" }}>loading...</Container>
		return (
			<Container style={{ height: "100%" }} className="p-0 s3-navigator">
				{this.state.isBusy && <Spinner key="spinner"></Spinner>}

				<InputPrompt
					name="promptRenameAs"
					config={this.state.rename.config}
					isOpen={!!this.state.rename}
					onCancel={this.onCancelRename}
					onSave={value => {
						this.onSaveRename(this.state.rename.file, value)
						this.onCancelRename()
					}}
					defaultValue={this.state.rename.defaultValue}
				/>

				<FileBrowser
					disableDragAndDrop={true}
					disableDragAndDropProvider={true}
					files={files}
					folderChain={folderChain}
					defaultFileViewActionId={this.state.defaultFileViewActionId}
					defaultSortActionId={this.state.defaultSortActionId}
					disableDefaultFileActions={[ChonkyActions.ToggleHiddenFiles.id, ChonkyActions.ToggleShowFoldersFirst.id, ChonkyActions.OpenSelection.id]}
					fileActions={fileActions}
					onFileAction={this.handleFileAction}
				>
					{folderChain.length > 1 && <FileNavbar />}
					<FileToolbar />
					{!!files.length && <FileList />}
					{enableDragAndDrop && (
						<Dropzone
							getUploadParams={this.getUploadParams}
							onChangeStatus={this.handleChangeStatus}
							// onSubmit={this.handleSubmit}
							styles={{
								dropzone: { minHeight: "64px", overflow: "hidden" },
							}}
							inputContent="Drag files here or Click to Browse..."
							validate={this.validateFile}
							multiple={true}
							maxFiles={20}
							submitButtonDisabled={true}
						/>
					)}
					{!!files.length && <FileContextMenu />}
				</FileBrowser>
				<S3MoveTo
					isOpen={this.state.moveItemsIsOpen}
					folder={this.props.folder}
					tree={this.state.tree}
					onCancel={this.onCancelMoveTo}
					onSelect={this.onSelectMoveTo}
				/>
			</Container>
		)
	}
}

const RefreshAction = defineFileAction({
	id: "refresh_files_and_folders",
	button: {
		name: "Refresh",
		toolbar: true,
		contextMenu: false,
		// group: "Actions",
		icon: ChonkyIconName.loading,
		iconOnly: true,
	},
})

const SortByFoldersAction = defineFileAction({
	id: "sort_files_by_folders",
	sortKeySelector: file => (file ? (file.isDir ? `.${file.name}` : `~${file.name}`) : undefined),
	button: {
		name: "Sort by folder",
		toolbar: true,
		group: "Options",
	},
})

const CreateFolderAction = defineFileAction({
	id: ChonkyActions.CreateFolder.id,
	button: {
		name: "Create Folder",
		toolbar: true,
		contextMenu: false,
		// group: "Actions",
		icon: ChonkyActions.CreateFolder.button.icon,
		iconOnly: true,
	},
})

const MoveItemsAction = defineFileAction({
	id: ChonkyActions.MoveFiles.id,
	button: {
		name: "Move Files",
		toolbar: true,
		contextMenu: true,
		// group: "Actions",
		icon: ChonkyIconName.folder,
		iconOnly: true,
	},
})

const ShowFileVersionsAction = defineFileAction({
	id: "action_file_versions",
	button: {
		name: "Show File Versions",
		toolbar: true,
		contextMenu: false,
		// group: "Options",
		icon: ChonkyIconName.git,
		iconOnly: true,
	},
})

const HideFileVersionsAction = defineFileAction({
	id: "action_file_versions",
	button: {
		name: "Hide File Versions",
		toolbar: true,
		contextMenu: false,
		// group: "Options",
		icon: ChonkyIconName.fallbackIcon,
		iconOnly: true,
	},
})

const RenameFileAction = defineFileAction({
	id: "action_file_rename",
	button: {
		name: "Rename File...",
		toolbar: false,
		contextMenu: true,
		// group: "Options",
		icon: ChonkyIconName.file,
		iconOnly: true,
	},
})

const mapToChonkyFiles = it => ({
	...it,
	id: `${it.key}:${it.versionId}`,
	modDate: it.lastModifiedDate,
	isDir: it.type === "folder",
})
