import React, { useEffect, useState } from "react"
import { Droppable } from "react-beautiful-dnd"

// https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
const StrictModeDroppable = ({ children, ...props }) => {
	const [enabled, setEnabled] = useState(false)

	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true))

		return () => {
			cancelAnimationFrame(animation)
			setEnabled(false)
		}
	}, [])

	if (!enabled) {
		return null
	}

	return <Droppable {...props}>{children}</Droppable>
}

export { StrictModeDroppable }
