export const scheduleCodeSort = (a, b) => {
	if (a.schedule === "BS" && b.schedule !== "BS") return +1
	if (a.schedule !== "BS" && b.schedule === "BS") return -1

	if (a.schedule > b.schedule) return +1
	if (a.schedule < b.schedule) return -1
	if (a.code > b.code) return +1
	if (a.code < b.code) return -1
	if (a.total > b.total) return +1
	if (a.total < b.total) return -1
}

export const scheduleDateCodeSort = (a, b) => {
	if (a.schedule === "BS" && b.schedule !== "BS") return +1
	if (a.schedule !== "BS" && b.schedule === "BS") return -1

	if (a.schedule > b.schedule) return +1
	if (a.schedule < b.schedule) return -1

	if (a.date > b.date) return +1
	if (a.date < b.date) return -1

	if (a.code > b.code) return +1
	if (a.code < b.code) return -1
	if (a.total > b.total) return +1
	if (a.total < b.total) return -1
}
