import bw_logo from "./images/$BW.svg"
import clearview_logo from "./images/$BW.svg"

var path = require("path")

var root = path.join(__dirname)

var config = {
	rootDir: root,
	// Targets ========================================================
	serveDir: path.join(root, ".serve"),
	distDir: path.join(root, "dist"),
	clientManifestFile: "manifest.webpack.json",
	clientStatsFile: "stats.webpack.json",

	// Source Directory ===============================================
	srcDir: path.join(root, "app"),
	srcServerDir: path.join(root, "server"),

	// HTML Layout ====================================================
	srcHtmlLayout: path.join(root, "app", "index.html"),

	// Site Config ====================================================
	siteTitle: "Clearview",
	siteDescription: "Clearview - Service Charge Task Manager",
	siteCannonicalUrl: "https://clearviewweb.localtest.me",
	siteKeywords: "Clearview Service Charge Manager",
	scssIncludes: [],

	brand: loc => {
		const brandSettings = {
			"bennettwhitehouse.clearviewsoftware.co.uk": {
				name: "Bennett Whitehouse",
				pageTitle: "Clearview",
				customer: "Bennett Whitehouse",
				logo: bw_logo,
				avatarColor: "#1eb7ff",
				chunkSize: 5000,
				chunkDelay: 1000,
			},
			"servicecharge.clearviewsoftware.co.uk": {
				name: "Service Charge",
				pageTitle: "Clearview",
				customer: "Clearview",
				logo: clearview_logo,
				avatarColor: "#1eb7ff",
				chunkSize: 5000,
				chunkDelay: 0,
			},
			"clearviewcustomerwebtest.s3-website.eu-west-2.amazonaws.com": {
				name: "Clearview TEST",
				pageTitle: "Clearview TEST",
				customer: "Clearview TEST",
				logo: clearview_logo,
				avatarColor: "#ff0000",
				chunkSize: 5000,
				chunkDelay: 0,
			},
			"clearviewweb.localtest.me": {
				name: "Clearview",
				pageTitle: "Clearview (Local)",
				customer: "Clearview",
				logo: clearview_logo,
				avatarColor: "#1eb7ff",
				chunkSize: 5000,
				chunkDelay: 1000,
			},
			default: {
				name: "Clearview",
				pageTitle: "Clearview",
				customer: "Clearview",
				logo: clearview_logo,
				avatarColor: "#1eb7ff",
				chunkSize: 5000,
				chunkDelay: 0,
			},
		}

		return brandSettings[loc.hostname.toLowerCase()] || brandSettings.default
	},

	$AML: {
		periodEndSubfolders: [],
		alts: {
			"brand.shortName": "AML",
			periodEnd: "Workflow",
		},
	},

	$BW: {
		periodEndSubfolders: ["Invoices"],
		alts: {
			"brand.shortName": "BW",
			"LivePeriodEndsPhasesOverviewTable.periodEnds.label": "Period Ends",
			"LivePeriodEndsPhasesOverviewTable.stages.label": "Stages",
		},
		vouchingReportUserColumns: [
			"Client NL Ac name",
			"Schedule",
			"Date",
			"Period from",
			"Period to",
			"Audit",
			"Category",
			"Narrative",
			"Internal audit number",
			"Supplier",
			"Invoice",
			"Net",
			"VAT",
			"Code",
			"Gross",
		],
		workingPapers: {
			customerFeeNominalCodePredicate: nc => ["ACF", "21002"].includes(nc.code),
		},
	},
	$Demo: {
		periodEndSubfolders: ["Invoices"],
		alts: {
			"brand.shortName": "Demo",
		},
		vouchingReportUserColumns: [],
		workingPapers: {
			customerFeeNominalCodePredicate: nc => ["ACF", "21002"].includes(nc.code),
		},
	},
}

export default config
