import { connect } from "react-redux"
import * as _ from "lodash"
import React, { Component } from "react"
import PropTypes from "prop-types"

import { Media, UncontrolledTabs, TabPane, Nav, NavItem } from "../../../../components"
import { TinyDonutChart } from "../components/TinyDonutChart"
import { TinyDonutLegend } from "../components/TinyDonutLegend"
import { HelpPopup } from "../../../components/HelpPopup"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class LivePeriodEndsByRag2WithUsDonut extends Component {
	static propTypes = {
		livePeriodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
		customerTemplates: PropTypes.object.isRequired,
	}

	render() {
		const { user, livePeriodEnds, customerTemplates } = this.props
		return (
			<LivePeriodEndsByRag2Donut_Customers
				user={user}
				livePeriodEnds={livePeriodEnds}
				customerTemplates={customerTemplates}
				isWithUs={true}
			></LivePeriodEndsByRag2Donut_Customers>
		)
	}
}

class LivePeriodEndsByRag2WithThemDonut extends Component {
	static propTypes = {
		livePeriodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
		customerTemplates: PropTypes.object.isRequired,
	}

	render() {
		const { user, livePeriodEnds, customerTemplates } = this.props
		return (
			<LivePeriodEndsByRag2Donut_Customers
				user={user}
				livePeriodEnds={livePeriodEnds}
				customerTemplates={customerTemplates}
				isWithUs={false}
			></LivePeriodEndsByRag2Donut_Customers>
		)
	}
}

class LivePeriodEndsByRag2Donut_Customers extends Component {
	static propTypes = {
		livePeriodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
		customerTemplates: PropTypes.object.isRequired,
		isWithUs: PropTypes.bool.isRequired,
	}

	render() {
		const { user, livePeriodEnds, customerTemplates, isWithUs } = this.props

		const byCustomerName = _.keyBy(customerTemplates, it => it.name)
		const customerNames = Object.keys(byCustomerName)

		const initialTabId = _.first(customerNames)

		return (
			<UncontrolledTabs initialActiveTabId="default">
				<Nav pills className="mb-4 flex-column flex-md-row mt-4 mt-lg-0">
					{customerNames.length > 1 &&
						customerNames.reverse().map(customerName => (
							<NavItem key={customerName} title={customerName}>
								<UncontrolledTabs.NavLink tabId={customerName === initialTabId ? "default" : customerName}>
									{customerName}
								</UncontrolledTabs.NavLink>
							</NavItem>
						))}
				</Nav>
				<UncontrolledTabs.TabContent>
					{customerNames.reverse().map(customerName => (
						<TabPane key={customerName} tabId={customerName === initialTabId ? "default" : customerName}>
							<LivePeriodEndsByRag2Donut_Customer
								user={user}
								livePeriodEnds={periodEndsWithCustomerPhase(livePeriodEnds, byCustomerName[customerName])}
								customer={byCustomerName[customerName]}
								isWithUs={isWithUs}
							/>
						</TabPane>
					))}
				</UncontrolledTabs.TabContent>
			</UncontrolledTabs>
		)
	}
}

class LivePeriodEndsByRag2Donut_Customer extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		livePeriodEnds: PropTypes.array.isRequired,
		customer: PropTypes.object.isRequired,
		isWithUs: PropTypes.bool.isRequired,
	}

	render() {
		const { user, livePeriodEnds, customer, isWithUs } = this.props

		const phaseInHouse = _.flatMap(customer.templates, it => it.stages.map(s => ({ phaseId: s.phaseId, isInHouse: s.isInHouse })))
		const phaseInHouseLookup = _.mapValues(
			_.groupBy(phaseInHouse, it => it.phaseId),
			it => _.first(it)?.isInHouse
		)

		const filteredPeriodEnds = livePeriodEnds.filter(
			it => phaseInHouseLookup[it.currentStage.templateStage.phaseId] === (isWithUs ? user.isInHouse : !user.isInHouse)
		)
		const periodEndsByRag2 = _.groupBy(filteredPeriodEnds, periodEnd => clearview.PeriodEndToRag2Mapper(periodEnd))

		const periodEndsByRag2Name = Object.keys(clearview.Rag2Names)
			.reverse()
			.map(key => ({
				name: clearview.Rag2Names[key],
				value: (periodEndsByRag2[key] || []).length,
				color: clearview.Rag2ColorValues[key],
			}))

		return (
			<React.Fragment>
				<div className="hr-text hr-text-left my-2">
					<span>
						Year Ends With {isWithUs ? clearview.Us(user).name : clearview.Them(user).name}
						<HelpPopup id="od_hp_ye_us_them" title={`Year Ends With ${isWithUs ? clearview.Us(user).name : clearview.Them(user).name}`}>
							<p>
								This chart shows the <b>RAG status of the current Stage</b> of Open Period Ends where the current Phase is owned by
								{isWithUs ? clearview.Us(user).name : clearview.Them(user).name}.
							</p>
						</HelpPopup>
					</span>
				</div>
				<Media>
					<Media left className="mr-3">
						<TinyDonutChart data={periodEndsByRag2Name} navigateTo="/periodEnds/" />
					</Media>
					<Media body>
						<TinyDonutLegend data={periodEndsByRag2Name} />
					</Media>
				</Media>
			</React.Fragment>
		)
	}
}

function periodEndsWithCustomerPhase(periodEnds, customer) {
	const phaseIdLookup = _.keyBy(customer.phases, it => it.id)
	return periodEnds.filter(it => it.currentStage).filter(it => phaseIdLookup[it.currentStage.templateStage.phaseId])
}

const mapStateToProps = state => {
	const customerTemplates = ACTIONS.getSubState(state, "clientsReducer\\templates")

	return {
		customerTemplates: customerTemplates.dict,
	}
}

LivePeriodEndsByRag2WithUsDonut = connect(mapStateToProps)(LivePeriodEndsByRag2WithUsDonut)
export { LivePeriodEndsByRag2WithUsDonut }

LivePeriodEndsByRag2WithThemDonut = connect(mapStateToProps)(LivePeriodEndsByRag2WithThemDonut)
export { LivePeriodEndsByRag2WithThemDonut }
