import { call, put, takeLatest } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

//Worker Functions
function* fetchAllReports(action) {
	try {
		const reports = yield call(Api.fetchReports)
		yield put({
			type: ACTIONS.REPORTS_ALL_FETCHED,
			reports: reports,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.REPORTS_ALL_FETCH_FAILED,
			message: e.message,
		})
	}
}

function* fetchReportsAttachments(action) {
	try {
		const attachments = yield call(Api.fetchReportsAttachments)
		yield put({
			type: ACTIONS.REPORTS_ATTACHMENTS_FETCHED,
			attachments: attachments,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.REPORTS_ATTACHMENTS_FETCH_FAILED,
			message: e.message,
		})
	}
}

function* requestReport(action) {
	try {
		yield call(Api.requestReport, action.payload.business, action.payload.report.key, action.payload.params)
		yield put({
			type: ACTIONS.REPORTS_REQUESTED,
			message: `${action.payload.report.name.replace(
				"Report ",
				""
			)} has been requested.\nRefresh this page in a few minutes to view the completed report.`,
		})
	} catch (e) {
		yield put({
			type: ACTIONS.REPORTS_REQUEST_FAILED,
			message: e.message,
		})
	}
}
export default function* reportsSaga() {
	yield takeLatest(ACTIONS.REPORTS_ALL_FETCH, fetchAllReports)
	yield takeLatest(ACTIONS.REPORTS_ATTACHMENTS_FETCH, fetchReportsAttachments)
	yield takeLatest(ACTIONS.REPORTS_REQUEST, requestReport)
}
