import React from "react"
import { Link } from "react-router-dom"

import { EmptyLayout } from "./../../../layout"

import { HeaderAuth } from "../../components/Pages/HeaderAuth"
import { FooterAuth } from "../../components/Pages/FooterAuth"

const Error404 = () => (
	<EmptyLayout>
		<EmptyLayout.Section center>
			<HeaderAuth title="Error 404" text="This page cannot be found." />
			<div className="d-flex mb-5">
				<Link to="/overview">Back to Home Page</Link>
			</div>
			<FooterAuth />
		</EmptyLayout.Section>
	</EmptyLayout>
)

export default Error404
