import * as XLSX from "sheetjs-style"
import * as _ from "lodash"

import { MakeSafeFileName } from "../../../../../components/@Clearview"
import { xlsx } from "clearview-aws-common"
import * as clearview from "../../../../../components/@Clearview"

export function ETBExportAsTemplate(periodEnd, rawEtbData) {
	const workbook = XLSX.utils.book_new()
	XLSX.utils.book_append_sheet(workbook, etbWorksheet(rawEtbData.map(withoutFigures)), "ETB")
	XLSX.writeFile(workbook, MakeSafeFileName(`${periodEnd.property.reference} - ${periodEnd.property.name} - ETB Template.xlsx`))
}

export function ETBExport(periodEnd, rawEtbData) {
	const workbook = XLSX.utils.book_new()
	XLSX.utils.book_append_sheet(workbook, etbWorksheet(rawEtbData), "ETB")
	XLSX.writeFile(workbook, MakeSafeFileName(`${periodEnd.reference} - ${periodEnd.property.name} - ETB.xlsx`))
}

function etbWorksheet(rawEtbData) {
	const etb = rawEtbData.map(it => ({
		Schedule: clearview.tidy.string(it.schedule),
		Code: clearview.tidy.string(it.code),
		Account: clearview.tidy.string(it.nominalCode?.name),
		Budget: clearview.currencyOutput(it.budget, null),
		"Prior Period Budget": clearview.currencyOutput(it.comparativeBudget, null),
		Expenditure: clearview.currencyOutput(it.expenditure, null),
		"Income Report": clearview.currencyOutput(it.income, null),
		"Reserve Fund Dr/(Cr)": clearview.currencyOutput(it.clientReserve, null),
		"Client Trial Balance Dr/(Cr)": clearview.currencyOutput(it.clientTrialBalance, null),
		"Per Original Reports Dr": clearview.currencyOutput(it.perOriginalReportsDr, null),
		"Per Original Reports Cr": clearview.currencyOutput(it.perOriginalReportsCr, null),
		"Journal Dr": clearview.currencyOutput(it.journalDr, null),
		"Journal Cr": clearview.currencyOutput(it.journalCr, null),
		"Res c/f Dr": clearview.currencyOutput(it.drAndPrepay, null),
		"Res c/f Cr": clearview.currencyOutput(it.crAndAccrual, null),
		"Per Amended Agent Reports Dr/(Cr)": clearview.currencyOutput(it.perAmendedAgentReportsDrCr, null),
		"Presentation Adjustments Dr": clearview.currencyOutput(it.presentationDr, null),
		"Presentation Adjustments Cr": clearview.currencyOutput(it.presentationCr, null),
		"Per Accounts Dr/(Cr)": clearview.currencyOutput(it.perAccountsDrCr, null),
	}))

	const worksheet = XLSX.utils.json_to_sheet(etb)
	const columnSpecs = deduceColumnsSpec(etb)
	xlsx.formatSheet(worksheet, [], [36], columnSpecs)

	return worksheet
}

function deduceColumnsSpec(data) {
	const columnNames = _.keys(data[0])

	const res = {
		[`A1:AZ1`]: xlsx.HEADER_CELL_FORMAT,
	}

	let i = 0
	for (let columnName of columnNames) {
		if (!columnName.match(/^(schedule|code)$/i)) {
			res[`${xlsx.AllColumns[i]}2:${xlsx.AllColumns[i]}${data.length + 1}`] = xlsx.CURRENCY_CELL_FORMAT
		}
		i++
	}
	return res
}

const withoutFigures = it => ({
	...it,
	budget: null,
	comparativeBudget: null,
	expenditure: null,
	income: null,
	clientReserve: null,
	clientTrialBalance: null,
	perOriginalReportsDr: null,
	perOriginalReportsCr: null,
	journalDr: null,
	journalCr: null,
	drAndPrepay: null,
	crAndAccrual: null,
	perAmendedAgentReportsDrCr: null,
	presentationDr: null,
	presentationCr: null,
	perAccountsDrCr: null,
})
