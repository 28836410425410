import config from "./../../config"
import { delay, call, put, takeEvery, takeLatest } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

const retryLimit = 1
let retryDelay = 1000
let retryAttempts = 0

//Worker Functions
function* fetchPeriodEndsAll(action) {
	retryAttempts = 0

	//eslint-disable-next-line
	const brand = config.brand(window.location)

	let chunkLft = action.lft
	while (chunkLft <= action.rgt) {
		let chunkRgt = Math.min(chunkLft + brand.chunkSize, action.rgt)
		yield put({
			type: ACTIONS.PERIODENDS_RANGE_FETCH,
			lft: chunkLft,
			rgt: chunkRgt,
		})
		if (brand.chunkDelay) yield delay(brand.chunkDelay)
		chunkLft = chunkRgt + 1
	}
}

function* fetchPeriodEndsRange(action) {
	try {
		const periodEnds = yield call(Api.fetchPeriodEnds, action.lft, action.rgt)
		yield put({
			type: ACTIONS.PERIODENDS_RANGE_FETCHED,
			periodEnds: periodEnds,
			receivedAt: Date.now(),
		})
		// yield delay(1000)
		yield put({
			type: ACTIONS.QUERIES_RANGE_FETCH,
			lft: action.lft,
			rgt: action.rgt,
		})
	} catch (e) {
		retryAttempts++

		if (retryAttempts === 3) {
			yield put({
				type: ACTIONS.PERIODENDS_RANGE_FETCH_RETRYING,
				message: `The server is experiencing heavy load. Remaining Period Ends will be loaded in the background.`,
			})
		}

		if (retryAttempts >= retryLimit)
			yield put({
				type: ACTIONS.PERIODENDS_RANGE_FETCH_FAILED,
				message: `Max retry attempts ${retryAttempts} exceeded. Unable to load all Period Ends.`,
			})
		else {
			console.warn(`RETRY: ${retryAttempts} DELAY ${retryDelay}: ${action.lft} to ${action.rgt}`)
			yield delay(retryDelay)
			yield put({
				type: ACTIONS.PERIODENDS_RANGE_FETCH,
				lft: action.lft,
				rgt: action.rgt,
			})

			// retryDelay = retryDelay + 1000
		}
	}
}

function* fetchQueriesRange(action) {
	try {
		const queries = yield call(Api.fetchQueries, action.lft, action.rgt)
		yield put({
			type: ACTIONS.QUERIES_RANGE_FETCHED,
			queries: queries,
			receivedAt: Date.now(),
		})
	} catch (e) {
		retryAttempts++

		if (retryAttempts === 3) {
			yield put({
				type: ACTIONS.QUERIES_RANGE_FETCH_RETRYING,
				message: `The server is experiencing heavy load. Remaining Queries will be loaded in the background.`,
			})
		}

		if (retryAttempts >= retryLimit)
			yield put({
				type: ACTIONS.QUERIES_RANGE_FETCH_FAILED,
				message: `Max retry attempts ${retryAttempts} exceeded. Unable to load all Queries.`,
			})
		else {
			console.warn(`RETRY: ${retryAttempts} DELAY ${retryDelay}: ${action.lft} to ${action.rgt}`)
			yield delay(retryDelay)
			yield put({
				type: ACTIONS.QUERIES_RANGE_FETCH,
				lft: action.lft,
				rgt: action.rgt,
			})

			// retryDelay = retryDelay + 1000
		}
	}
}

function* recalculatePeriodEnds() {
	try {
		const periodEndsRecalculated = yield call(Api.recalculatePeriodEnds)
		yield put({
			type: "SUCCESS",
			message: `Number of Period Ends Recalculated: ${periodEndsRecalculated}`,
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODENDS_RECALCULATE_FAILED,
			message: e.message,
		})
	}
}

function* renewPeriodEnds() {
	try {
		const periodEndsRenewed = yield call(Api.renewPeriodEnds)
		yield put({
			type: "SUCCESS",
			message: `Number of Period Ends Renewed: ${periodEndsRenewed}`,
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODENDS_RENEW_FAILED,
			message: e.message,
		})
	}
}

export default function* periodEndsSaga() {
	yield takeLatest(ACTIONS.PERIODENDS_ALL_FETCH, fetchPeriodEndsAll)
	yield takeEvery(ACTIONS.PERIODENDS_RANGE_FETCH, fetchPeriodEndsRange)
	yield takeEvery(ACTIONS.QUERIES_RANGE_FETCH, fetchQueriesRange)
	yield takeLatest(ACTIONS.PERIODENDS_RECALCULATE, recalculatePeriodEnds)
	yield takeLatest(ACTIONS.PERIODENDS_RENEW, renewPeriodEnds)
}
