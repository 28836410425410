import React from "react"
import PropTypes from "prop-types"

import * as clearview from "../../../../components/@Clearview"

import { v4 as uuid } from "uuid"

import { Modal } from "../../../../components"

import WorkflowExcelForm_Modal from "../../Workflows/components/WorkflowExcelForm_Modal"
import sampleFile from "./Expenditure_Report_Template.xlsx"

const EMPTY_STATE = { isOpen: false }

export class ImportLedger_Form extends React.Component {
	static propTypes = {
		isOpen: PropTypes.bool.isRequired,
		business: PropTypes.object.isRequired,
		onCancel: PropTypes.func.isRequired,
		onUploaded: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = EMPTY_STATE

		this.parseUploadedData = this.parseUploadedData.bind(this)
	}

	static getDerivedStateFromProps(props, state) {
		if (!props.isOpen) return EMPTY_STATE

		if (state.isOpen) return null

		return {
			isOpen: true,
			business: props.business,
		}
	}

	parseUploadedData(file, data, sheetName) {
		let toastId = null

		try {
			switch (sheetName) {
				case "A2- Expenditure - Fortus":
					toastId = clearview.ShowToast("info", <p>Importing "{sheetName}"</p>, 3000)
					this.parseUploadedDataFortus(file, data)
					clearview.DismissToast(toastId)
					return

				default:
					toastId = clearview.ShowToast("info", <p>Importing "{sheetName}"</p>, 3000)
					this.parseUploadedDataBW(file, data)
					clearview.DismissToast(toastId)
					return
			}
		} catch (ex) {
			clearview.ShowToast("error", <p>{ex.message}</p>, null, null, toastId)
		}
	}

	hasData = it => it.ref || it.vendorName || it.date || it.total
	notOnlyTotal = it => !(!it.ref && !it.vendorName && !it.date && it.total && it.accountName?.endsWith(" Total"))
	notHeadings = it => it.vendorName !== "Supplier Name"

	parseUploadedDataFortus(file, data) {
		const mappedData = data
			.map(it => ({
				id: uuid(),
				file: file.name,
				lastModified: file.lastModified,
				row: it.__rowNum__,

				ref: clearview.SafeTrim(it.__EMPTY_9),
				vendorName: clearview.SafeTrim(it.__EMPTY_10),
				date: clearview.DateToString(it.__EMPTY_5),
				accountDate: null,
				narrative: clearview.SafeTrim(it.__EMPTY_8),

				total: clearview.parseCurrency(it.__EMPTY_16?.toString()),

				accountName: clearview.SafeTrim(it.__EMPTY_2),
			}))
			.filter(this.hasData)
			.filter(this.notOnlyTotal)
			.filter(this.notHeadings)

		this.props.onUploaded(file, mappedData)
	}

	parseUploadedDataBW(file, data) {
		const mappedData = data
			.map(it => ({
				...it,
				id: uuid(),
				file: file.name,
				lastModified: file.lastModified,
				row: it.__rowNum__,

				ref: clearview.SafeTrim(it.Invoice),
				vendorName: clearview.SafeTrim(it.Supplier),
				date: clearview.DateToString(it.Date),
				narrative: clearview.SafeTrim(it.Narrative),

				total: clearview.parseCurrency(it.Gross?.toString()),

				accountName: clearview.SafeTrim(it["Client NL Ac name"]),
			}))
			.filter(this.hasData)

		this.props.onUploaded(file, mappedData)
	}

	render() {
		const { isOpen, business } = this.state

		return (
			<Modal isOpen={isOpen} style={{ maxWidth: 600 }} className="overflowScroll">
				<WorkflowExcelForm_Modal
					title={
						<span>
							Upload <b>Expenditure Report</b> for <b className="nowrap">{business?.name}</b>
						</span>
					}
					sheetNames={["A2- Expenditure - Fortus", ""]}
					onUploaded={(file, data, sheetName) => {
						this.parseUploadedData(file, data, sheetName)
					}}
					onCancel={this.props.onCancel}
					sampleFile={sampleFile}
				></WorkflowExcelForm_Modal>
			</Modal>
		)
	}
}
