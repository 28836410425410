import React from "react"
import PropTypes from "prop-types"

import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"

export default class S3MoveTo extends React.Component {
	static propTypes = {
		isOpen: PropTypes.bool.isRequired,
		onCancel: PropTypes.func.isRequired,
		onSelect: PropTypes.func.isRequired,
		folder: PropTypes.string,
		tree: PropTypes.array,
	}

	render() {
		const { isOpen, folder, tree, onCancel, onSelect } = this.props

		return (
			<Modal isOpen={isOpen} className="clearview-move-files-to">
				<ModalHeader tag="div">
					<div className="d-flex">
						<h1 className="text-primary mr-2">
							<i className="fa fa-fw fa-folder"></i>
						</h1>
						<h1 className="text-primary mr-2">Move Files to ...</h1>
					</div>
				</ModalHeader>
				<ModalBody>
					<div>
						<ul>
							{tree?.map(it => (
								<li key={it.key}>
									<a href="#" onClick={e => onSelect(it.key)}>
										{it.key.substring(folder.length)}/
									</a>
								</li>
							))}
						</ul>
						<p>If necessary, create target folder before moving files.</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<a href="#" onClick={() => onCancel()}>
						Cancel
					</a>
				</ModalFooter>
			</Modal>
		)
	}
}
