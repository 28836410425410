import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import _ from "lodash"

import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"

import { Form, UncontrolledModal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"
import { QueryRipenessBadge, getCommentTypeIcon, AssignedToAvatar, QuerySubjectBadge, EventNameBadge } from "./CommentsTable"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class Stage_CloseMultipleQueriesForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			openQueries: props.openQueries.map(it => ({ ...it, isChecked: false })),
		}

		this.handleToggleSelected = this.handleToggleSelected.bind(this)
		this.handleToggleAll = this.handleToggleAll.bind(this)

		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleToggleSelected(e) {
		const selectedId = parseInt(e.target.value)
		this.setState(prevState => ({
			openQueries: prevState.openQueries.map(it => ({ ...it, isChecked: it.id === selectedId ? !it.isChecked : it.isChecked })),
		}))
	}

	handleToggleAll(e) {
		const newIsChecked = !!this.state.openQueries.find(it => !it.isChecked)
		this.setState(prevState => ({
			openQueries: prevState.openQueries.map(it => ({ ...it, isChecked: newIsChecked })),
		}))
	}

	handleCommentsChange(event) {
		this.setState({
			comments: event.target.value,
		})
	}

	handleActionButtonClick(event) {
		const closeQueryIds = this.state.openQueries.filter(it => it.isChecked).map(it => it.id)

		this.props.closeQueries({
			periodEnd: this.props.periodEnd,
			queryIds: closeQueryIds,
			comments: `Closed ${closeQueryIds.length} Queries`,
		})

		this.setState(prevState => ({ openQueries: prevState.openQueries.filter(it => !closeQueryIds.includes(it.id)) }))

		return true
	}

	render() {
		const { openQueries } = this.state
		const allChecked = !openQueries.find(it => !it.isChecked)

		const rowClasses = (row, idx) => {
			return `text-${clearview.CommentTypeColors.Query[row.isWithUs]}`
		}

		return (
			<UncontrolledModal style={{ minWidth: 900 }} target={`stage${this.props.stage.id}_CloseMultipleQueriesForm`} className="closeMultipleQueriesForm">
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">
						{clearview.CustomerAlt(
							clearview.PeriodEndMeta(this.props.periodEnd).Customer,
							this.props.stage.templateStage.isInHouse ? "stage.closeForm.title.isInHouse" : "stage.closeForm.title.isClient",
							"Close Multiple Queries"
						)}
					</ModalHeader>

					<ModalBody>
						<input id="checkAll" type="checkbox" value={0} checked={allChecked} onChange={this.handleToggleAll} />
						<label className="ml-1" htmlFor="checkAll">
							All
						</label>
						<ToolkitProvider
							keyField="id"
							data={_.sortBy(openQueries, it => it.id)}
							columns={[
								{
									dataField: "id",
									text: "isChecked",
									headerAlign: "center",
									align: "center",
									formatter: (cell, row) => (
										<input type="checkbox" value={row.id} checked={row.isChecked} onChange={this.handleToggleSelected} />
									),
								},
								{
									dataField: "number",
									text: "#",
									headerAlign: "center",
									align: "left",
									formatter: (cell, row) => (row.number ? <span>{QueryRipenessBadge(row)}</span> : getCommentTypeIcon(row)),
								},
								{
									dataField: "assignedTo",
									text: "To",
									headerAlign: "center",
									align: "left",
									formatter: (cell, row) => <span>{AssignedToAvatar(row)}</span>,
								},
								{
									dataField: "subject",
									text: "Activity",
									headerAlign: "left",
									align: "left",
									formatter: (cell, row) => (
										<React.Fragment>
											{row.subject ? QuerySubjectBadge(row) : EventNameBadge(row)} {(row.title || row.comments || "").substring(0, 80)}
										</React.Fragment>
									),
								},
							]}
						>
							{props => (
								<React.Fragment>
									<BootstrapTable
										keyField="id"
										headerClasses="hidden"
										classes="table-responsive-lg csl-comments-table text-inverse table-striped"
										bordered={false}
										rowClasses={rowClasses}
										responsive
										hover
										{...props.baseProps}
									/>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close
							color="default"
							className="text-primary"
							disabled={!openQueries.filter(it => it.isChecked).length}
							onClick={this.handleActionButtonClick}
						>
							Close Queries
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	closeQueries: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_CLOSE_QUERIES,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Stage_CloseMultipleQueriesForm)
