import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import * as clearview from "../../components/@Clearview"

let PreFilterLink = ({ children, context, to, periodEnds, clear, ...otherProps }) => {
	if (to === undefined) return children

	const preFilterState = {
		to,
		periodEnds,
		// setPreFilter: context.setPreFilter,
		clear,
	}

	return (
		<Link to={to} state={preFilterState} {...otherProps}>
			{children}
		</Link>
	)
}

PreFilterLink.propTypes = {
	context: PropTypes.object.isRequired,
	periodEnds: PropTypes.array.isRequired,
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

PreFilterLink = clearview.withRouter(PreFilterLink)

export { PreFilterLink }
