import React from "react"

import { Svg, Ellipse, Circle, Path } from "@react-pdf/renderer"

export const images = {
	bw_logo: (element, key) => (
		<Svg
			style={element.style}
			key={key}
			preserveAspectRatio="xMidYMid meet"
			xmlns="http://www.w3.org/2000/svg"
			id="Layer_2"
			data-name="Layer 2"
			viewBox="0 0 252.4 101.94"
		>
			<Ellipse fill="#e6e6e6" cx="128.25" cy="49.11" rx="34.26" ry="2.5" />
			<Circle fill="#91c254" cx="125.59" cy="8.45" r="5.01" />
			<Ellipse fill="#91c254" cx="128.63" cy="41.23" rx="5.29" ry="4.25" transform="translate(20.93 115.05) rotate(-65.25)" />
			<Ellipse fill="#91c254" cx="117.7" cy="48.11" rx="4.59" ry="3.46" transform="translate(39.63 133.58) rotate(-83.22)" />
			<Ellipse fill="#91c254" cx="93.99" cy="26.76" rx="1.9" ry="3.32" />
			<Ellipse fill="#91c254" cx="106.42" cy="53.22" rx="0.95" ry="2.28" transform="translate(-19.88 -18.11) rotate(-4.03)" />
			<Ellipse fill="#91c254" cx="154.78" cy="30.06" rx="3.99" ry="4.62" transform="translate(5.02 88.46) rotate(-43.23)" />
			<Ellipse fill="#91c254" cx="165.09" cy="30.13" rx="2.26" ry="4.21" transform="translate(7.77 95.54) rotate(-43.23)" />
			<Ellipse fill="#91c254" cx="172.54" cy="34.17" rx="1.14" ry="4.06" transform="translate(-6.59 75.21) rotate(-33.51)" />
			<Ellipse fill="#245593" cx="138.5" cy="52.2" rx="6.12" ry="5.17" transform="translate(45.24 152.97) rotate(-79.37)" />
			<Ellipse fill="#245593" cx="125.57" cy="58.59" rx="5.27" ry="4.43" transform="translate(36.16 150.64) rotate(-83.22)" />
			<Ellipse fill="#245593" cx="115.78" cy="62.03" rx="2.79" ry="4.51" transform="translate(-30.67 17.85) rotate(-20.1)" />
			<Ellipse fill="#245593" cx="109.4" cy="61.8" rx="1.66" ry="3.48" transform="translate(-30.98 15.65) rotate(-20.1)" />
			<Ellipse fill="#245593" cx="105.59" cy="58" rx="0.71" ry="2.9" transform="translate(-28.2 5.21) rotate(-15.8)" />
			<Ellipse fill="#245593" cx="152.26" cy="45.4" rx="5.6" ry="4.65" transform="translate(72.8 165.51) rotate(-83.22)" />
			<Ellipse fill="#245593" cx="163.56" cy="41.31" rx="3.71" ry="4.79" transform="translate(-18.05 49.74) rotate(-25.86)" />
			<Ellipse fill="#245593" cx="171.7" cy="41.01" rx="2" ry="4.06" transform="translate(-17.1 53.26) rotate(-25.86)" />
			<Ellipse fill="#245593" cx="176.6" cy="44.7" rx="0.9" ry="3.37" transform="translate(-21.97 19.96) rotate(-14.51)" />
			<Ellipse fill="#00abdd" cx="148.8" cy="62.94" rx="5.74" ry="4.74" transform="matrix(0.46, -0.89, 0.89, 0.46, 8.38, 140.77)" />
			<Ellipse fill="#00abdd" cx="135.6" cy="69.34" rx="5.11" ry="4.74" transform="translate(-4.47 132.49) rotate(-62.79)" />
			<Ellipse fill="#00abdd" cx="123.7" cy="72.51" rx="3.3" ry="4.57" transform="translate(-30.36 86.93) rotate(-46.63)" />
			<Ellipse fill="#00abdd" cx="115.16" cy="71.89" rx="1.75" ry="4.34" transform="translate(-36.8 53.49) rotate(-35.06)" />
			<Ellipse fill="#00abdd" cx="109.01" cy="67.58" rx="0.82" ry="3.39" transform="translate(-35.34 32.92) rotate(-27.8)" />
			<Ellipse fill="#00abdd" cx="161.17" cy="55.81" rx="5.15" ry="3.77" transform="translate(26.61 153.08) rotate(-65.25)" />
			<Ellipse fill="#00abdd" cx="170.24" cy="51.03" rx="3.65" ry="2.59" transform="translate(92.46 192.1) rotate(-86.49)" />
			<Ellipse fill="#00abdd" cx="175.33" cy="50" rx="1.36" ry="2.62" transform="translate(-23.34 19.81) rotate(-14.51)" />
			<Path
				fill="#2b3076"
				d="M17.94,108.22a.72.72,0,0,1-.22.55.7.7,0,0,1-.54.22.77.77,0,0,1-.78-.77V88.81a.73.73,0,0,1,.23-.54.74.74,0,0,1,.55-.22.7.7,0,0,1,.54.22.72.72,0,0,1,.22.54v7.88a6.46,6.46,0,0,1,.74-.83,7.71,7.71,0,0,1,2.49-1.66,7.85,7.85,0,0,1,8.57,1.66A7.72,7.72,0,0,1,32,101.38a7.7,7.7,0,0,1-.61,3,7.8,7.8,0,0,1-4.15,4.15,7.91,7.91,0,0,1-6.09,0,7.93,7.93,0,0,1-2.49-1.68,7.21,7.21,0,0,1-.74-.83Zm0-6.84a6.14,6.14,0,0,0,.48,2.41,6.23,6.23,0,0,0,3.31,3.33,6.3,6.3,0,0,0,4.92,0A6.23,6.23,0,0,0,30,103.79a6.14,6.14,0,0,0,.48-2.41,6.18,6.18,0,0,0-3.79-5.75,6.29,6.29,0,0,0-2.47-.48,6.18,6.18,0,0,0-2.44.48,6.27,6.27,0,0,0-2,1.33,6,6,0,0,0-1.34,2A6.21,6.21,0,0,0,18,101.38Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M35.13,102.1a5.86,5.86,0,0,0,.61,2.2,6.24,6.24,0,0,0,1.33,1.76A6.13,6.13,0,0,0,39,107.25a5.87,5.87,0,0,0,2.26.43,6.13,6.13,0,0,0,2.63-.57,5.26,5.26,0,0,0,2-1.55.79.79,0,0,1,.79-.2.66.66,0,0,1,.34.29.87.87,0,0,1,.19.52.7.7,0,0,1-.29.56,7.56,7.56,0,0,1-2.36,1.76,7.22,7.22,0,0,1-3.27.7,7.49,7.49,0,0,1-3-.61,8,8,0,0,1-2.48-1.67A7.88,7.88,0,0,1,34,104.43a7.62,7.62,0,0,1-.61-3.05,7.49,7.49,0,0,1,.61-3,7.73,7.73,0,0,1,1.68-2.47,7.85,7.85,0,0,1,2.48-1.67,7.5,7.5,0,0,1,3-.62,7.81,7.81,0,0,1,5.51,2.27,8.18,8.18,0,0,1,1.68,2.47,7.49,7.49,0,0,1,.62,3,.72.72,0,0,1-.75.75Zm12.29-1.32a6.1,6.1,0,0,0-.6-2.24,6,6,0,0,0-1.35-1.8,6.4,6.4,0,0,0-1.92-1.22,6.34,6.34,0,0,0-4.64,0A6.07,6.07,0,0,0,37,96.74a6.55,6.55,0,0,0-1.34,1.82,5.81,5.81,0,0,0-.59,2.22Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M52.92,96.87A7.37,7.37,0,0,1,54,95.53a7.16,7.16,0,0,1,1.31-1,6.69,6.69,0,0,1,1.5-.67,5.48,5.48,0,0,1,1.62-.24,6,6,0,0,1,2.51.51,5.4,5.4,0,0,1,1.92,1.46,6.81,6.81,0,0,1,1.23,2.35,11.37,11.37,0,0,1,.45,3.11v7.2a.79.79,0,0,1-.81.79.73.73,0,0,1-.54-.23.79.79,0,0,1-.21-.56V101.1a6.84,6.84,0,0,0-1.25-4.43,4.23,4.23,0,0,0-3.46-1.52,4.77,4.77,0,0,0-2.09.46,5.2,5.2,0,0,0-1.69,1.25,6.07,6.07,0,0,0-1.15,1.86,6.33,6.33,0,0,0-.42,2.32v7.15a.81.81,0,0,1-.23.57.73.73,0,0,1-.54.23.77.77,0,0,1-.56-.23.81.81,0,0,1-.23-.57V94.36a.75.75,0,0,1,.23-.55.73.73,0,0,1,.56-.23.69.69,0,0,1,.54.23.75.75,0,0,1,.23.55Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M69.2,96.87a8.33,8.33,0,0,1,1.08-1.34,6.9,6.9,0,0,1,1.32-1,6.42,6.42,0,0,1,1.49-.67,5.59,5.59,0,0,1,1.63-.24,6,6,0,0,1,2.51.51,5.45,5.45,0,0,1,1.91,1.46,6.81,6.81,0,0,1,1.23,2.35,10.65,10.65,0,0,1,.45,3.11v7.2a.76.76,0,0,1-.22.56A.78.78,0,0,1,80,109a.71.71,0,0,1-.54-.23.8.8,0,0,1-.22-.56V101.1A6.78,6.78,0,0,0,78,96.67a4.22,4.22,0,0,0-3.46-1.52,4.8,4.8,0,0,0-2.09.46,5.31,5.31,0,0,0-1.69,1.25,6.27,6.27,0,0,0-1.15,1.86A6.33,6.33,0,0,0,69.2,101v7.15a.8.8,0,0,1-.22.57.75.75,0,0,1-.55.23.79.79,0,0,1-.56-.23.8.8,0,0,1-.22-.57V94.36a.74.74,0,0,1,.22-.55.75.75,0,0,1,.56-.23.71.71,0,0,1,.55.23.74.74,0,0,1,.22.55Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M84.71,102.1a5.87,5.87,0,0,0,.62,2.2,6.24,6.24,0,0,0,1.33,1.76,6.13,6.13,0,0,0,1.88,1.19,5.87,5.87,0,0,0,2.26.43,6.13,6.13,0,0,0,2.63-.57,5.26,5.26,0,0,0,2-1.55.79.79,0,0,1,.79-.2.66.66,0,0,1,.34.29.87.87,0,0,1,.19.52.72.72,0,0,1-.29.56,7.56,7.56,0,0,1-2.36,1.76,7.25,7.25,0,0,1-3.27.7,7.49,7.49,0,0,1-3-.61,8,8,0,0,1-2.48-1.67,7.88,7.88,0,0,1-1.68-2.48,7.62,7.62,0,0,1-.61-3.05,7.49,7.49,0,0,1,.61-3,7.73,7.73,0,0,1,1.68-2.47,7.85,7.85,0,0,1,2.48-1.67,7.5,7.5,0,0,1,3-.62,7.81,7.81,0,0,1,5.51,2.27A8.18,8.18,0,0,1,98,98.33a7.49,7.49,0,0,1,.62,3,.73.73,0,0,1-.21.54.76.76,0,0,1-.54.21ZM97,100.78a6.1,6.1,0,0,0-.6-2.24,6.3,6.3,0,0,0-3.27-3,6.34,6.34,0,0,0-4.64,0,6.07,6.07,0,0,0-1.9,1.22,6.55,6.55,0,0,0-1.34,1.82,5.81,5.81,0,0,0-.59,2.22Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M104,93.59h2.49a.82.82,0,0,1,.56.22.75.75,0,0,1,.23.55.78.78,0,0,1-.23.57.82.82,0,0,1-.56.22H104v13.07a.7.7,0,0,1-.23.55.8.8,0,0,1-1.1,0,.72.72,0,0,1-.22-.55V95.15h-2a.75.75,0,0,1-.55-.23.73.73,0,0,1-.23-.56.71.71,0,0,1,.23-.55.78.78,0,0,1,.55-.22h2V88.81a.72.72,0,0,1,.22-.54.8.8,0,0,1,1.1,0,.69.69,0,0,1,.23.54Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M112.19,93.59h2.49a.82.82,0,0,1,.56.22.75.75,0,0,1,.23.55.78.78,0,0,1-.23.57.82.82,0,0,1-.56.22h-2.49v13.07a.7.7,0,0,1-.23.55.75.75,0,0,1-.55.22.74.74,0,0,1-.55-.22.72.72,0,0,1-.22-.55V95.15h-2a.75.75,0,0,1-.55-.23.73.73,0,0,1-.23-.56.71.71,0,0,1,.23-.55.78.78,0,0,1,.55-.22h2V88.81a.72.72,0,0,1,.22-.54.74.74,0,0,1,.55-.22.75.75,0,0,1,.55.22.69.69,0,0,1,.23.54Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M133.81,98.37l1.87-4.3a.77.77,0,0,1,1-.43.71.71,0,0,1,.42.43.75.75,0,0,1,0,.62l-2.45,5.6,2.66,6.15,5.36-12.37a.77.77,0,0,1,1-.43.74.74,0,0,1,.42.43.75.75,0,0,1,0,.62l-6,13.83-.15.23a.83.83,0,0,1-.24.18.85.85,0,0,1-.23.06h-.21a.53.53,0,0,1-.23-.06.94.94,0,0,1-.25-.18.91.91,0,0,1-.15-.23l-2.76-6.34-2.77,6.34-.15.23a.83.83,0,0,1-.24.18.85.85,0,0,1-.23.06h-.21a.56.56,0,0,1-.23-.06.94.94,0,0,1-.25-.18.68.68,0,0,1-.14-.23l-6-13.83a.8.8,0,0,1,0-.62.79.79,0,0,1,.41-.43.72.72,0,0,1,.61,0,.75.75,0,0,1,.42.45l5.36,12.37,2.66-6.15-2.44-5.6a.84.84,0,0,1,0-.62.75.75,0,0,1,.41-.43.72.72,0,0,1,.61,0,.75.75,0,0,1,.42.45Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M159.11,108.78a.81.81,0,0,1-.59.23.74.74,0,0,1-.54-.23.79.79,0,0,1-.21-.56V101a6.81,6.81,0,0,0-1.26-4.4,4.26,4.26,0,0,0-3.45-1.49,4.8,4.8,0,0,0-2.09.46,5.2,5.2,0,0,0-1.69,1.25,6.27,6.27,0,0,0-1.15,1.86,6.33,6.33,0,0,0-.42,2.32v7.15a.85.85,0,0,1-.22.57.75.75,0,0,1-.55.23.77.77,0,0,1-.56-.23.81.81,0,0,1-.23-.57V88.85a.81.81,0,0,1,.23-.57.73.73,0,0,1,.56-.23.71.71,0,0,1,.55.23.85.85,0,0,1,.22.57v8a7.37,7.37,0,0,1,1.08-1.34,7.16,7.16,0,0,1,1.31-1,6.69,6.69,0,0,1,1.5-.67,5.53,5.53,0,0,1,1.62-.24,6,6,0,0,1,2.51.51,5.4,5.4,0,0,1,1.92,1.46,6.81,6.81,0,0,1,1.23,2.35,10.65,10.65,0,0,1,.45,3.11v7.2A.8.8,0,0,1,159.11,108.78Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M162.58,89.32a1,1,0,0,1,.71-.29,1,1,0,0,1,1,1,1,1,0,0,1-.3.7,1,1,0,0,1-.73.3.93.93,0,0,1-.71-.3.94.94,0,0,1-.29-.7A1,1,0,0,1,162.58,89.32Zm1.49,18.87a.8.8,0,0,1-.22.57.77.77,0,0,1-1.1,0,.77.77,0,0,1-.23-.57V94.36a.78.78,0,0,1,1.33-.55.74.74,0,0,1,.22.55Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M170.32,93.59h2.49a.82.82,0,0,1,.56.22.75.75,0,0,1,.23.55.78.78,0,0,1-.23.57.82.82,0,0,1-.56.22h-2.49v13.07a.7.7,0,0,1-.23.55.8.8,0,0,1-1.1,0,.72.72,0,0,1-.22-.55V95.15h-2.05a.75.75,0,0,1-.55-.23.73.73,0,0,1-.23-.56.71.71,0,0,1,.23-.55.78.78,0,0,1,.55-.22h2.05V88.81a.72.72,0,0,1,.22-.54.8.8,0,0,1,1.1,0,.69.69,0,0,1,.23.54Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M176.08,102.1a6.06,6.06,0,0,0,.62,2.2,6.24,6.24,0,0,0,1.33,1.76,6.13,6.13,0,0,0,1.88,1.19,5.87,5.87,0,0,0,2.26.43,6.13,6.13,0,0,0,2.63-.57,5.26,5.26,0,0,0,2-1.55.79.79,0,0,1,.79-.2.66.66,0,0,1,.34.29.87.87,0,0,1,.19.52.72.72,0,0,1-.29.56,7.56,7.56,0,0,1-2.36,1.76,7.22,7.22,0,0,1-3.27.7,7.49,7.49,0,0,1-3-.61,8,8,0,0,1-2.48-1.67,7.88,7.88,0,0,1-1.68-2.48,7.62,7.62,0,0,1-.61-3.05,7.49,7.49,0,0,1,.61-3,7.73,7.73,0,0,1,1.68-2.47,7.85,7.85,0,0,1,2.48-1.67,7.5,7.5,0,0,1,3-.62,7.81,7.81,0,0,1,5.51,2.27,8.18,8.18,0,0,1,1.68,2.47,7.49,7.49,0,0,1,.62,3,.73.73,0,0,1-.21.54.75.75,0,0,1-.54.21Zm12.3-1.32a6.1,6.1,0,0,0-.6-2.24,6,6,0,0,0-1.35-1.8,6.4,6.4,0,0,0-1.92-1.22,6.34,6.34,0,0,0-4.64,0,6.07,6.07,0,0,0-1.9,1.22,6.55,6.55,0,0,0-1.34,1.82,5.81,5.81,0,0,0-.59,2.22Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M205.27,108.78a.77.77,0,0,1-.58.23.71.71,0,0,1-.54-.23.79.79,0,0,1-.21-.56V101a6.81,6.81,0,0,0-1.26-4.4,4.26,4.26,0,0,0-3.45-1.49,4.77,4.77,0,0,0-2.09.46,5.2,5.2,0,0,0-1.69,1.25,6.07,6.07,0,0,0-1.15,1.86,6.33,6.33,0,0,0-.42,2.32v7.15a.81.81,0,0,1-.23.57.73.73,0,0,1-.54.23.77.77,0,0,1-.56-.23.81.81,0,0,1-.23-.57V88.85a.81.81,0,0,1,.23-.57.73.73,0,0,1,.56-.23.69.69,0,0,1,.54.23.81.81,0,0,1,.23.57v8A7.37,7.37,0,0,1,195,95.53a7.16,7.16,0,0,1,1.31-1,6.69,6.69,0,0,1,1.5-.67,5.48,5.48,0,0,1,1.62-.24,6,6,0,0,1,2.51.51,5.4,5.4,0,0,1,1.92,1.46A6.63,6.63,0,0,1,205,97.91a11,11,0,0,1,.46,3.11v7.2A.77.77,0,0,1,205.27,108.78Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M208.37,98.35A7.68,7.68,0,0,1,210,95.86a8.07,8.07,0,0,1,2.49-1.67,7.86,7.86,0,0,1,6.07,0,8.1,8.1,0,0,1,2.47,1.67,7.84,7.84,0,0,1,1.67,2.49,7.88,7.88,0,0,1,0,6.08,7.83,7.83,0,0,1-4.14,4.15,7.86,7.86,0,0,1-6.07,0,8.07,8.07,0,0,1-2.49-1.67,7.85,7.85,0,0,1-1.67-2.48,7.76,7.76,0,0,1,0-6.08Zm1.44,5.46a6.36,6.36,0,0,0,1.32,2,6.81,6.81,0,0,0,2,1.35,6.28,6.28,0,0,0,4.91,0,6.19,6.19,0,0,0,3.29-3.33,6.14,6.14,0,0,0,.49-2.41,6.25,6.25,0,0,0-.49-2.44A6.18,6.18,0,0,0,220,97a6.44,6.44,0,0,0-2-1.33,6.37,6.37,0,0,0-4.9,0,6.56,6.56,0,0,0-2,1.33,6.18,6.18,0,0,0-1.32,2,6.25,6.25,0,0,0-.49,2.44A6.14,6.14,0,0,0,209.81,103.81Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M225.78,93.8a.78.78,0,0,1,.58-.22.69.69,0,0,1,.53.22.75.75,0,0,1,.22.56v7.11a6.8,6.8,0,0,0,1.26,4.45,4.23,4.23,0,0,0,3.46,1.52,5,5,0,0,0,2.08-.45,5.24,5.24,0,0,0,1.7-1.25,6,6,0,0,0,1.14-1.86,6.14,6.14,0,0,0,.43-2.32V94.38a.8.8,0,0,1,.22-.57.77.77,0,0,1,1.1,0,.77.77,0,0,1,.23.57v13.83a.75.75,0,0,1-.23.56.75.75,0,0,1-1.1,0,.78.78,0,0,1-.22-.56v-2.49a7.44,7.44,0,0,1-1.09,1.33,7.64,7.64,0,0,1-1.32,1,6.66,6.66,0,0,1-1.49.67,5.82,5.82,0,0,1-1.62.23,6.08,6.08,0,0,1-2.53-.51A5.49,5.49,0,0,1,227.2,107a6.8,6.8,0,0,1-1.21-2.37,10.57,10.57,0,0,1-.43-3.15V94.36A.73.73,0,0,1,225.78,93.8Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M244.28,101.1a6,6,0,0,1-1.45-.83,4,4,0,0,1-1-1.15,3.18,3.18,0,0,1-.38-1.58,3.42,3.42,0,0,1,.44-1.75A4,4,0,0,1,243,94.55a4.89,4.89,0,0,1,1.58-.74,6.45,6.45,0,0,1,1.79-.25,7,7,0,0,1,1.39.14,4.81,4.81,0,0,1,1.26.43,4.28,4.28,0,0,1,1.06.71,3.53,3.53,0,0,1,.79,1,.65.65,0,0,1-.08.81.81.81,0,0,1-.39.2.56.56,0,0,1-.42-.07,4.82,4.82,0,0,0-1.54-1.38,4.15,4.15,0,0,0-2-.45,6.42,6.42,0,0,0-1.23.13,3.58,3.58,0,0,0-1.12.43,2.36,2.36,0,0,0-.82.78A2.2,2.2,0,0,0,243,97.5a2,2,0,0,0,.28,1.09,2.76,2.76,0,0,0,.74.79,5.16,5.16,0,0,0,1.07.57c.41.16.83.3,1.28.44.72.21,1.41.44,2.06.67a8.19,8.19,0,0,1,1.7.86,4.17,4.17,0,0,1,1.16,1.21,3.1,3.1,0,0,1,.43,1.69,3.65,3.65,0,0,1-.41,1.73,4.24,4.24,0,0,1-1.12,1.38,5.35,5.35,0,0,1-1.66.92,6.4,6.4,0,0,1-2,.32,8.3,8.3,0,0,1-1.67-.16,6.53,6.53,0,0,1-1.49-.5,5.21,5.21,0,0,1-1.23-.83,4,4,0,0,1-.88-1.17.78.78,0,0,1-.1-.63.68.68,0,0,1,.31-.41.81.81,0,0,1,.54-.07.9.9,0,0,1,.54.37,3.77,3.77,0,0,0,1.59,1.53,5.42,5.42,0,0,0,2.39.48,5,5,0,0,0,1.38-.2,4,4,0,0,0,1.19-.59,3.06,3.06,0,0,0,.84-.92,2.36,2.36,0,0,0,.31-1.18,2.05,2.05,0,0,0-.34-1.18,3,3,0,0,0-.91-.85,6.87,6.87,0,0,0-1.36-.63c-.51-.18-1-.36-1.63-.52A16.16,16.16,0,0,1,244.28,101.1Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M254.9,102.1a5.87,5.87,0,0,0,.62,2.2,6.24,6.24,0,0,0,1.33,1.76,6.13,6.13,0,0,0,1.88,1.19,5.83,5.83,0,0,0,2.26.43,6.13,6.13,0,0,0,2.63-.57,5.26,5.26,0,0,0,2-1.55.79.79,0,0,1,.79-.2.66.66,0,0,1,.34.29.87.87,0,0,1,.19.52.72.72,0,0,1-.29.56,7.56,7.56,0,0,1-2.36,1.76,7.28,7.28,0,0,1-3.27.7,7.49,7.49,0,0,1-3-.61,8,8,0,0,1-2.48-1.67,7.88,7.88,0,0,1-1.68-2.48,7.62,7.62,0,0,1-.61-3.05,7.49,7.49,0,0,1,.61-3,7.73,7.73,0,0,1,1.68-2.47A7.85,7.85,0,0,1,258,94.21a7.5,7.5,0,0,1,3-.62,7.74,7.74,0,0,1,3,.61,7.83,7.83,0,0,1,2.48,1.66,8.18,8.18,0,0,1,1.68,2.47,7.49,7.49,0,0,1,.62,3,.73.73,0,0,1-.21.54.76.76,0,0,1-.54.21Zm12.29-1.32a5.9,5.9,0,0,0-.59-2.24,6.27,6.27,0,0,0-3.28-3,6.13,6.13,0,0,0-2.31-.44,6.22,6.22,0,0,0-2.32.44,6.07,6.07,0,0,0-1.9,1.22,6.55,6.55,0,0,0-1.34,1.82,6,6,0,0,0-.6,2.22Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M73.84,123.2a1.4,1.4,0,0,0-.87-.44,1,1,0,0,0-.86.57,4.06,4.06,0,0,0-.32,1.59c0,1.36.6,2,1.19,2a1.33,1.33,0,0,0,.86-.46v.85a1.8,1.8,0,0,1-1,.31c-1.29,0-1.87-1.32-1.87-2.76s.64-2.84,1.91-2.84a1.74,1.74,0,0,1,.93.28Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path fill="#2b3076" d="M78.38,122.14v2.25H80v-2.25h.77v5.44H80v-2.5H78.38v2.5h-.77v-5.44Z" transform="translate(-16.4 -25.72)" />
			<Path
				fill="#2b3076"
				d="M86.7,122.14l1.53,5.44h-.86L87,126H85.64l-.39,1.56h-.8L86,122.14Zm-.38,1.17h0l-.49,2h1Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M93.48,122.14a1.31,1.31,0,0,1,1.44,1.33,1.49,1.49,0,0,1-1.22,1.42h0c.46.11.82.28,1,1.2l.31,1.48H94.2L94,126.27c-.15-.81-.44-1-1-1h-.34v2.35h-.77v-5.44Zm-.86.72v1.67h.49a.93.93,0,0,0,1-.9.75.75,0,0,0-.86-.77Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path fill="#2b3076" d="M101.63,122.14v.72h-1.27v4.72h-.78v-4.72H98.31v-.72Z" transform="translate(-16.4 -25.72)" />
			<Path fill="#2b3076" d="M108.17,122.14v.72h-2.12v1.53h2v.69h-2v1.78h2.12v.72h-2.89v-5.44Z" transform="translate(-16.4 -25.72)" />
			<Path
				fill="#2b3076"
				d="M113.69,122.14a1.31,1.31,0,0,1,1.44,1.33,1.49,1.49,0,0,1-1.21,1.42h0c.46.11.81.28,1,1.2l.32,1.48h-.82l-.24-1.31c-.15-.81-.45-1-1-1h-.34v2.35h-.78v-5.44Zm-.85.72v1.67h.49a.93.93,0,0,0,1-.9.76.76,0,0,0-.86-.77Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path fill="#2b3076" d="M121.84,122.14v.72h-2.11v1.53h2v.69h-2v1.78h2.11v.72H119v-5.44Z" transform="translate(-16.4 -25.72)" />
			<Path
				fill="#2b3076"
				d="M127.21,122.14c1.5,0,1.82,1.49,1.82,2.66a3.29,3.29,0,0,1-.71,2.26c-.36.39-.72.52-1.59.52h-1v-5.44Zm-.71.72v4H127a1,1,0,0,0,.9-.53,3.85,3.85,0,0,0,.3-1.58c0-.74-.16-1.89-1.2-1.89Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M139.72,122.14l1.53,5.44h-.85L140,126h-1.35l-.39,1.56h-.79l1.51-5.44Zm-.38,1.17h0l-.49,2h1Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M147.39,123.2a1.43,1.43,0,0,0-.87-.44,1.07,1.07,0,0,0-.87.57,4.06,4.06,0,0,0-.32,1.59c0,1.36.6,2,1.2,2a1.36,1.36,0,0,0,.86-.46v.85a1.84,1.84,0,0,1-1,.31c-1.28,0-1.87-1.32-1.87-2.76s.64-2.84,1.91-2.84a1.8,1.8,0,0,1,.94.28Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M153.77,123.2a1.43,1.43,0,0,0-.88-.44,1,1,0,0,0-.86.57,4.06,4.06,0,0,0-.32,1.59c0,1.36.6,2,1.19,2a1.36,1.36,0,0,0,.87-.46v.85a1.84,1.84,0,0,1-1,.31c-1.29,0-1.87-1.32-1.87-2.76s.64-2.84,1.91-2.84a1.8,1.8,0,0,1,.94.28Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M161.06,124.82c0,1.34-.46,2.84-1.9,2.84s-1.88-1.69-1.88-2.7a3.26,3.26,0,0,1,1-2.64,1.6,1.6,0,0,1,.85-.26C160.88,122.06,161.06,124,161.06,124.82Zm-3,0c0,1.36.42,2.1,1.1,2.1.85,0,1.1-1.17,1.1-2.13,0-.25,0-2.07-1-2.07S158.07,124.07,158.07,124.86Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M168,122.14V126a1.49,1.49,0,0,1-1.6,1.67c-1.08,0-1.58-.65-1.58-1.71v-3.81h.78V126c0,.49.19,1,.8,1s.83-.46.83-1v-3.81Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M172.79,122.14l1.34,3,.15.36a7.75,7.75,0,0,1,.27.76h.07c0-.26-.07-.76-.07-1.39v-2.69h.74v5.44h-.76l-1.1-2.48c-.17-.36-.22-.51-.43-1a4,4,0,0,1-.21-.6h-.07a8.33,8.33,0,0,1,.05.94v2.58h-.74v-5.44Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path fill="#2b3076" d="M182.29,122.14v.72H181v4.72h-.77v-4.72H179v-.72Z" transform="translate(-16.4 -25.72)" />
			<Path
				fill="#2b3076"
				d="M187.42,122.14l1.53,5.44h-.86l-.38-1.56h-1.35l-.39,1.56h-.8l1.52-5.44Zm-.38,1.17h0l-.49,2h1Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M193.34,122.14l1.35,3c0,.1.09.24.14.36a7.75,7.75,0,0,1,.27.76h.07c0-.26-.07-.76-.07-1.39v-2.69h.74v5.44h-.76L194,125.1c-.17-.36-.22-.51-.43-1a5.71,5.71,0,0,1-.21-.6h-.07c0,.39,0,.45.06.94,0,0,0,.39,0,.61v2.58h-.74v-5.44Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path fill="#2b3076" d="M202.84,122.14v.72h-1.27v4.72h-.77v-4.72h-1.27v-.72Z" transform="translate(-16.4 -25.72)" />
			<Path
				fill="#2b3076"
				d="M209,123.3a1.58,1.58,0,0,0-1.05-.52.78.78,0,0,0-.84.77c0,.54.56.75.92.88a1.58,1.58,0,0,1,1.29,1.53,1.73,1.73,0,0,1-1.77,1.7,2.21,2.21,0,0,1-1.24-.4v-.93a1.76,1.76,0,0,0,1.19.61,1,1,0,0,0,1-1c0-.61-.58-.82-.94-.95a1.56,1.56,0,0,1-1.28-1.45,1.53,1.53,0,0,1,1.6-1.53,2,2,0,0,1,1.09.35Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M17.94,108.22a.72.72,0,0,1-.22.55.7.7,0,0,1-.54.22.77.77,0,0,1-.78-.77V88.81a.73.73,0,0,1,.23-.54.74.74,0,0,1,.55-.22.7.7,0,0,1,.54.22.72.72,0,0,1,.22.54v7.88a6.46,6.46,0,0,1,.74-.83,7.71,7.71,0,0,1,2.49-1.66,7.85,7.85,0,0,1,8.57,1.66A7.72,7.72,0,0,1,32,101.38a7.7,7.7,0,0,1-.61,3,7.8,7.8,0,0,1-4.15,4.15,7.91,7.91,0,0,1-6.09,0,7.93,7.93,0,0,1-2.49-1.68,7.21,7.21,0,0,1-.74-.83Zm0-6.84a6.14,6.14,0,0,0,.48,2.41,6.23,6.23,0,0,0,3.31,3.33,6.3,6.3,0,0,0,4.92,0A6.23,6.23,0,0,0,30,103.79a6.14,6.14,0,0,0,.48-2.41,6.18,6.18,0,0,0-3.79-5.75,6.29,6.29,0,0,0-2.47-.48,6.18,6.18,0,0,0-2.44.48,6.27,6.27,0,0,0-2,1.33,6,6,0,0,0-1.34,2A6.21,6.21,0,0,0,18,101.38Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M35.13,102.1a5.86,5.86,0,0,0,.61,2.2,6.24,6.24,0,0,0,1.33,1.76A6.13,6.13,0,0,0,39,107.25a5.87,5.87,0,0,0,2.26.43,6.13,6.13,0,0,0,2.63-.57,5.26,5.26,0,0,0,2-1.55.79.79,0,0,1,.79-.2.66.66,0,0,1,.34.29.87.87,0,0,1,.19.52.7.7,0,0,1-.29.56,7.56,7.56,0,0,1-2.36,1.76,7.22,7.22,0,0,1-3.27.7,7.49,7.49,0,0,1-3-.61,8,8,0,0,1-2.48-1.67A7.88,7.88,0,0,1,34,104.43a7.62,7.62,0,0,1-.61-3.05,7.49,7.49,0,0,1,.61-3,7.73,7.73,0,0,1,1.68-2.47,7.85,7.85,0,0,1,2.48-1.67,7.5,7.5,0,0,1,3-.62,7.81,7.81,0,0,1,5.51,2.27,8.18,8.18,0,0,1,1.68,2.47,7.49,7.49,0,0,1,.62,3,.72.72,0,0,1-.75.75Zm12.29-1.32a6.1,6.1,0,0,0-.6-2.24,6,6,0,0,0-1.35-1.8,6.4,6.4,0,0,0-1.92-1.22,6.34,6.34,0,0,0-4.64,0A6.07,6.07,0,0,0,37,96.74a6.55,6.55,0,0,0-1.34,1.82,5.81,5.81,0,0,0-.59,2.22Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M52.92,96.87A7.37,7.37,0,0,1,54,95.53a7.16,7.16,0,0,1,1.31-1,6.69,6.69,0,0,1,1.5-.67,5.48,5.48,0,0,1,1.62-.24,6,6,0,0,1,2.51.51,5.4,5.4,0,0,1,1.92,1.46,6.63,6.63,0,0,1,1.22,2.35,11,11,0,0,1,.46,3.11v7.2a.79.79,0,0,1-.81.79.71.71,0,0,1-.54-.23.79.79,0,0,1-.21-.56V101.1a6.84,6.84,0,0,0-1.25-4.43,4.23,4.23,0,0,0-3.46-1.52,4.77,4.77,0,0,0-2.09.46,5.2,5.2,0,0,0-1.69,1.25,6.07,6.07,0,0,0-1.15,1.86,6.33,6.33,0,0,0-.42,2.32v7.15a.81.81,0,0,1-.23.57.73.73,0,0,1-.54.23.77.77,0,0,1-.56-.23.81.81,0,0,1-.23-.57V94.36a.75.75,0,0,1,.23-.55.73.73,0,0,1,.56-.23.69.69,0,0,1,.54.23.75.75,0,0,1,.23.55Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M69.2,96.87a8.33,8.33,0,0,1,1.08-1.34,6.9,6.9,0,0,1,1.32-1,6.42,6.42,0,0,1,1.49-.67,5.58,5.58,0,0,1,1.62-.24,6,6,0,0,1,2.52.51,5.45,5.45,0,0,1,1.91,1.46,6.81,6.81,0,0,1,1.23,2.35,10.65,10.65,0,0,1,.45,3.11v7.2a.76.76,0,0,1-.22.56A.78.78,0,0,1,80,109a.71.71,0,0,1-.54-.23.8.8,0,0,1-.22-.56V101.1A6.78,6.78,0,0,0,78,96.67a4.22,4.22,0,0,0-3.46-1.52,4.8,4.8,0,0,0-2.09.46,5.2,5.2,0,0,0-1.69,1.25,6.27,6.27,0,0,0-1.15,1.86A6.33,6.33,0,0,0,69.2,101v7.15a.8.8,0,0,1-.22.57.75.75,0,0,1-.55.23.79.79,0,0,1-.56-.23.81.81,0,0,1-.23-.57V94.36a.79.79,0,0,1,.79-.78.71.71,0,0,1,.55.23.74.74,0,0,1,.22.55Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M84.71,102.1a5.87,5.87,0,0,0,.62,2.2,6.24,6.24,0,0,0,1.33,1.76,6.13,6.13,0,0,0,1.88,1.19,5.87,5.87,0,0,0,2.26.43,6.13,6.13,0,0,0,2.63-.57,5.26,5.26,0,0,0,2-1.55.79.79,0,0,1,.79-.2.66.66,0,0,1,.34.29.87.87,0,0,1,.19.52.72.72,0,0,1-.29.56,7.56,7.56,0,0,1-2.36,1.76,7.25,7.25,0,0,1-3.27.7,7.49,7.49,0,0,1-3-.61,8,8,0,0,1-2.48-1.67,7.88,7.88,0,0,1-1.68-2.48,7.62,7.62,0,0,1-.61-3.05,7.49,7.49,0,0,1,.61-3,7.73,7.73,0,0,1,1.68-2.47,7.85,7.85,0,0,1,2.48-1.67,7.5,7.5,0,0,1,3-.62,7.81,7.81,0,0,1,5.51,2.27A8.18,8.18,0,0,1,98,98.33a7.49,7.49,0,0,1,.62,3,.73.73,0,0,1-.21.54.76.76,0,0,1-.54.21ZM97,100.78a6.1,6.1,0,0,0-.6-2.24,6.3,6.3,0,0,0-3.27-3,6.34,6.34,0,0,0-4.64,0,6.07,6.07,0,0,0-1.9,1.22,6.55,6.55,0,0,0-1.34,1.82,5.81,5.81,0,0,0-.59,2.22Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M104,93.59h2.49a.82.82,0,0,1,.56.22.75.75,0,0,1,.23.55.78.78,0,0,1-.23.57.82.82,0,0,1-.56.22H104v13.07a.7.7,0,0,1-.23.55.8.8,0,0,1-1.1,0,.72.72,0,0,1-.22-.55V95.15h-2a.75.75,0,0,1-.55-.23.73.73,0,0,1-.23-.56.71.71,0,0,1,.23-.55.78.78,0,0,1,.55-.22h2V88.81a.72.72,0,0,1,.22-.54.8.8,0,0,1,1.1,0,.69.69,0,0,1,.23.54Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M112.19,93.59h2.49a.82.82,0,0,1,.56.22.75.75,0,0,1,.23.55.78.78,0,0,1-.23.57.82.82,0,0,1-.56.22h-2.49v13.07a.7.7,0,0,1-.23.55.75.75,0,0,1-.55.22.74.74,0,0,1-.55-.22.72.72,0,0,1-.22-.55V95.15h-2a.75.75,0,0,1-.55-.23.73.73,0,0,1-.23-.56.71.71,0,0,1,.23-.55.78.78,0,0,1,.55-.22h2V88.81a.72.72,0,0,1,.22-.54.74.74,0,0,1,.55-.22.75.75,0,0,1,.55.22.69.69,0,0,1,.23.54Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M133.81,98.37l1.87-4.3a.77.77,0,0,1,1-.43.71.71,0,0,1,.42.43.75.75,0,0,1,0,.62l-2.45,5.6,2.66,6.15,5.36-12.37a.78.78,0,0,1,1.45,0,.75.75,0,0,1,0,.62l-6,13.83-.15.23a.83.83,0,0,1-.24.18.85.85,0,0,1-.23.06h-.21a.53.53,0,0,1-.23-.06.94.94,0,0,1-.25-.18.91.91,0,0,1-.15-.23l-2.76-6.34-2.77,6.34-.15.23a.83.83,0,0,1-.24.18.85.85,0,0,1-.23.06h-.21a.53.53,0,0,1-.23-.06.94.94,0,0,1-.25-.18.91.91,0,0,1-.15-.23l-6-13.83a.8.8,0,0,1,0-.62.79.79,0,0,1,.41-.43.72.72,0,0,1,.61,0,.75.75,0,0,1,.42.45l5.36,12.37,2.66-6.15-2.44-5.6a.79.79,0,0,1,0-.62.75.75,0,0,1,.41-.43.72.72,0,0,1,.61,0,.78.78,0,0,1,.42.45Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M159.11,108.78a.81.81,0,0,1-.59.23.74.74,0,0,1-.54-.23.79.79,0,0,1-.21-.56V101a6.81,6.81,0,0,0-1.26-4.4,4.26,4.26,0,0,0-3.45-1.49,4.8,4.8,0,0,0-2.09.46,5.2,5.2,0,0,0-1.69,1.25,6.27,6.27,0,0,0-1.15,1.86,6.33,6.33,0,0,0-.42,2.32v7.15a.85.85,0,0,1-.22.57.75.75,0,0,1-.55.23.77.77,0,0,1-.56-.23.81.81,0,0,1-.23-.57V88.85a.81.81,0,0,1,.23-.57.73.73,0,0,1,.56-.23.71.71,0,0,1,.55.23.85.85,0,0,1,.22.57v8a7.37,7.37,0,0,1,1.08-1.34,7.16,7.16,0,0,1,1.31-1,6.69,6.69,0,0,1,1.5-.67,5.53,5.53,0,0,1,1.62-.24,6,6,0,0,1,2.51.51,5.4,5.4,0,0,1,1.92,1.46,6.81,6.81,0,0,1,1.23,2.35,10.65,10.65,0,0,1,.45,3.11v7.2A.8.8,0,0,1,159.11,108.78Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M162.58,89.32a1,1,0,0,1,.71-.29,1,1,0,0,1,1,1,1,1,0,0,1-.3.7,1,1,0,0,1-.73.3.93.93,0,0,1-.71-.3.94.94,0,0,1-.29-.7A1,1,0,0,1,162.58,89.32Zm1.49,18.87a.8.8,0,0,1-.22.57.77.77,0,0,1-1.1,0,.77.77,0,0,1-.23-.57V94.36a.78.78,0,0,1,1.33-.55.74.74,0,0,1,.22.55Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M170.32,93.59h2.49a.82.82,0,0,1,.56.22.75.75,0,0,1,.23.55.78.78,0,0,1-.23.57.82.82,0,0,1-.56.22h-2.49v13.07a.7.7,0,0,1-.23.55.8.8,0,0,1-1.1,0,.72.72,0,0,1-.22-.55V95.15h-2.05a.75.75,0,0,1-.55-.23.73.73,0,0,1-.23-.56.71.71,0,0,1,.23-.55.78.78,0,0,1,.55-.22h2.05V88.81a.72.72,0,0,1,.22-.54.8.8,0,0,1,1.1,0,.69.69,0,0,1,.23.54Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M176.08,102.1a5.87,5.87,0,0,0,.62,2.2,6.24,6.24,0,0,0,1.33,1.76,6.13,6.13,0,0,0,1.88,1.19,5.87,5.87,0,0,0,2.26.43,6.13,6.13,0,0,0,2.63-.57,5.26,5.26,0,0,0,2-1.55.79.79,0,0,1,.79-.2.66.66,0,0,1,.34.29.87.87,0,0,1,.19.52.72.72,0,0,1-.29.56,7.56,7.56,0,0,1-2.36,1.76,7.25,7.25,0,0,1-3.27.7,7.49,7.49,0,0,1-3-.61,8,8,0,0,1-2.48-1.67,7.88,7.88,0,0,1-1.68-2.48,7.62,7.62,0,0,1-.61-3.05,7.49,7.49,0,0,1,.61-3,7.73,7.73,0,0,1,1.68-2.47,7.85,7.85,0,0,1,2.48-1.67,7.5,7.5,0,0,1,3-.62,7.81,7.81,0,0,1,5.51,2.27,8.18,8.18,0,0,1,1.68,2.47,7.49,7.49,0,0,1,.62,3,.73.73,0,0,1-.21.54.76.76,0,0,1-.54.21Zm12.3-1.32a6.1,6.1,0,0,0-.6-2.24,6.27,6.27,0,0,0-3.28-3,6.13,6.13,0,0,0-2.31-.44,6.22,6.22,0,0,0-2.32.44,6.07,6.07,0,0,0-1.9,1.22,6.55,6.55,0,0,0-1.34,1.82,5.81,5.81,0,0,0-.59,2.22Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M205.27,108.78a.77.77,0,0,1-.58.23.71.71,0,0,1-.54-.23.79.79,0,0,1-.21-.56V101a6.81,6.81,0,0,0-1.26-4.4,4.26,4.26,0,0,0-3.45-1.49,4.77,4.77,0,0,0-2.09.46,5.1,5.1,0,0,0-1.69,1.25,5.88,5.88,0,0,0-1.15,1.86,6.34,6.34,0,0,0-.43,2.32v7.15a.8.8,0,0,1-.22.57.73.73,0,0,1-.55.23.76.76,0,0,1-.55-.23.81.81,0,0,1-.23-.57V88.85a.81.81,0,0,1,.23-.57.73.73,0,0,1,.55-.23.7.7,0,0,1,.55.23.8.8,0,0,1,.22.57v8A8.33,8.33,0,0,1,195,95.53a7.58,7.58,0,0,1,1.32-1,6.69,6.69,0,0,1,1.5-.67,5.48,5.48,0,0,1,1.62-.24,6,6,0,0,1,2.51.51,5.4,5.4,0,0,1,1.92,1.46A6.8,6.8,0,0,1,205,97.91a10.65,10.65,0,0,1,.45,3.11v7.2A.76.76,0,0,1,205.27,108.78Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M208.37,98.35A7.68,7.68,0,0,1,210,95.86a8.07,8.07,0,0,1,2.49-1.67,7.83,7.83,0,0,1,6.06,0,8,8,0,0,1,2.48,1.67,7.81,7.81,0,0,1,2.28,5.54,7.74,7.74,0,0,1-.61,3,7.8,7.8,0,0,1-4.15,4.15,7.83,7.83,0,0,1-6.06,0,8.07,8.07,0,0,1-2.49-1.67,7.85,7.85,0,0,1-1.67-2.48,7.76,7.76,0,0,1,0-6.08Zm1.44,5.46a6.36,6.36,0,0,0,1.32,2,6.56,6.56,0,0,0,2,1.35,6.28,6.28,0,0,0,4.91,0,6.28,6.28,0,0,0,3.29-3.33,6.14,6.14,0,0,0,.48-2.41,6.25,6.25,0,0,0-.48-2.44A6.18,6.18,0,0,0,220,97a6.56,6.56,0,0,0-2-1.33,6.37,6.37,0,0,0-4.9,0,6.33,6.33,0,0,0-2,1.33,6.18,6.18,0,0,0-1.32,2,6.08,6.08,0,0,0-.49,2.44A6,6,0,0,0,209.81,103.81Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M225.78,93.8a.78.78,0,0,1,.58-.22.69.69,0,0,1,.53.22.75.75,0,0,1,.22.56v7.11a6.8,6.8,0,0,0,1.26,4.45,4.23,4.23,0,0,0,3.46,1.52,5,5,0,0,0,2.08-.45,5.24,5.24,0,0,0,1.7-1.25,6,6,0,0,0,1.14-1.86,6.14,6.14,0,0,0,.43-2.32V94.38a.8.8,0,0,1,.22-.57.77.77,0,0,1,1.1,0,.77.77,0,0,1,.23.57v13.83a.75.75,0,0,1-.23.56.75.75,0,0,1-1.1,0,.78.78,0,0,1-.22-.56v-2.49a7.44,7.44,0,0,1-1.09,1.33,7.64,7.64,0,0,1-1.32,1,6.66,6.66,0,0,1-1.49.67,5.82,5.82,0,0,1-1.62.23,6,6,0,0,1-2.53-.51A5.4,5.4,0,0,1,227.2,107a7,7,0,0,1-1.22-2.37,10.92,10.92,0,0,1-.42-3.15V94.36A.73.73,0,0,1,225.78,93.8Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M244.28,101.1a6.29,6.29,0,0,1-1.46-.83,4.12,4.12,0,0,1-1-1.15,3.08,3.08,0,0,1-.38-1.58,3.32,3.32,0,0,1,.44-1.75A4.1,4.1,0,0,1,243,94.55a5,5,0,0,1,1.59-.74,6.45,6.45,0,0,1,1.79-.25,7,7,0,0,1,1.39.14,5.07,5.07,0,0,1,1.26.43,4.47,4.47,0,0,1,1.06.71,3.53,3.53,0,0,1,.79,1,.64.64,0,0,1-.09.81.74.74,0,0,1-.38.2.56.56,0,0,1-.42-.07,4.82,4.82,0,0,0-1.54-1.38,4.16,4.16,0,0,0-2-.45,6.41,6.41,0,0,0-1.22.13,3.58,3.58,0,0,0-1.12.43,2.36,2.36,0,0,0-.82.78A2.12,2.12,0,0,0,243,97.5a2,2,0,0,0,.28,1.09,2.76,2.76,0,0,0,.74.79,5.16,5.16,0,0,0,1.07.57c.41.16.83.3,1.28.44.72.21,1.4.44,2,.67a8,8,0,0,1,1.71.86,4.17,4.17,0,0,1,1.16,1.21,3.1,3.1,0,0,1,.43,1.69,3.65,3.65,0,0,1-.41,1.73,4.24,4.24,0,0,1-1.12,1.38,5.35,5.35,0,0,1-1.66.92,6.4,6.4,0,0,1-2,.32,8.3,8.3,0,0,1-1.67-.16,6.53,6.53,0,0,1-1.49-.5,5,5,0,0,1-1.23-.83,4,4,0,0,1-.88-1.17.79.79,0,0,1-.11-.63.74.74,0,0,1,.32-.41.81.81,0,0,1,.54-.07.9.9,0,0,1,.54.37,3.77,3.77,0,0,0,1.59,1.53,5.42,5.42,0,0,0,2.39.48,5,5,0,0,0,1.38-.2,4,4,0,0,0,1.19-.59,2.9,2.9,0,0,0,.83-.92,2.27,2.27,0,0,0,.32-1.18,2.05,2.05,0,0,0-.34-1.18,2.91,2.91,0,0,0-.92-.85,6.52,6.52,0,0,0-1.35-.63c-.51-.18-1.06-.36-1.63-.52A16.16,16.16,0,0,1,244.28,101.1Z"
				transform="translate(-16.4 -25.72)"
			/>
			<Path
				fill="#2b3076"
				d="M254.9,102.1a5.87,5.87,0,0,0,.62,2.2,6.06,6.06,0,0,0,1.33,1.76,6.2,6.2,0,0,0,1.87,1.19,5.92,5.92,0,0,0,2.27.43,6.16,6.16,0,0,0,2.63-.57,5.26,5.26,0,0,0,2-1.55.79.79,0,0,1,.79-.2.66.66,0,0,1,.34.29.87.87,0,0,1,.19.52.72.72,0,0,1-.29.56,7.56,7.56,0,0,1-2.36,1.76,7.28,7.28,0,0,1-3.27.7,7.49,7.49,0,0,1-3-.61,7.87,7.87,0,0,1-4.16-4.15,7.63,7.63,0,0,1-.62-3.05,7.5,7.5,0,0,1,.62-3A7.89,7.89,0,0,1,258,94.21a7.5,7.5,0,0,1,3-.62,7.74,7.74,0,0,1,3,.61,7.83,7.83,0,0,1,2.48,1.66,8.18,8.18,0,0,1,1.68,2.47,7.49,7.49,0,0,1,.62,3,.7.7,0,0,1-.22.54.73.73,0,0,1-.54.21Zm12.29-1.32a5.9,5.9,0,0,0-.59-2.24,6.27,6.27,0,0,0-3.28-3,6.13,6.13,0,0,0-2.31-.44,6.22,6.22,0,0,0-2.32.44,6.07,6.07,0,0,0-1.9,1.22,6.36,6.36,0,0,0-1.34,1.82,6,6,0,0,0-.6,2.22Z"
				transform="translate(-16.4 -25.72)"
			/>
		</Svg>
	),
}
