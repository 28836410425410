import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import Template_RetireTemplateForm from "./Template_RetireTemplateForm"

import * as clearview from "../../../../components/@Clearview"

import Dropdown from "react-bootstrap/Dropdown"

class TemplateActions extends React.Component {
	static propTypes = {
		template: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props
	}

	render() {
		const { template } = this.props
		const actions = _.intersection(template.actions, ["Retire"])

		return (
			<React.Fragment>
				<Dropdown hidden={!actions.length} className="align-self-center ml-auto" direction="left" onToggle={clearview.StopPropagation}>
					<Dropdown.Toggle variant="none">{clearview.Icon.actions}</Dropdown.Toggle>
					<Dropdown.Menu end persist>
						<Dropdown.Item hidden={!actions.includes("Retire")}>
							<button className="tight" id={`template${template.id}_RetireTemplateForm`}>
								{clearview.Icon.template} Retire this template...
							</button>
						</Dropdown.Item>
						<Template_RetireTemplateForm template={template} />
					</Dropdown.Menu>
				</Dropdown>
			</React.Fragment>
		)
	}
}

export { TemplateActions }
