import { call, put, takeEvery } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

function* createUsers(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const workflow = yield call(Api.workflowCreateUsers, action.business, action.users)

		yield put({
			type: ACTIONS.WORKFLOW_SUBMITTED,
			workflow: workflow,
			receivedAt: Date.now(),
		})

		yield put({
			type: "SUCCESS",
			message: `Your new users have been submitted for processing.\nYou will be emailed when the accounts have been created.\nYou and the user may need to check your junk/spam folders\nfor emails from clearviewsoftware.co.uk.`,
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 026",
			type: ACTIONS.WORKFLOW_SUBMIT_FAILED,
			message: e.message,
		})
	}
}

export default function* workflowsSaga() {
	yield takeEvery(ACTIONS.WORKFLOW_CREATE_USERS, createUsers)
}
