import React from "react"
import PropTypes from "prop-types"

import { Draggable } from "react-beautiful-dnd"

import * as clearview from "../../../../components/@Clearview"

import { Media, Badge, Popover, PopoverHeader, PopoverBody } from "../../../../components"

import { SmartMenu } from "../../../components/SmartMenu"
import { StrictModeDroppable } from "../../../components/StrictModeDroppable"

import { STATUS_CLASS_NAME, SELECTED_CLASS_NAME, DATE_COLOR, formatVendorName, formatRef, formatValue, getDragItemClass } from "./VouchingTheme"
import { ExpenseAvatar } from "./ExpenseAvatar"
import { ExpenseWarningIcon } from "./ExpenseWarningIcon"

import { VouchingDetailsContext } from "./VouchingDetailsContext"

export const ExpenseList = props => (
	<VouchingDetailsContext.Consumer>
		{context => {
			return (
				<div id="expensesList">
					<StrictModeDroppable droppableId={props.listId} isDropDisabled={true}>
						{provided => (
							<div ref={provided.innerRef} className="list-group" style={{ height: 500, overflowY: "scroll" }}>
								{props.items.filter(context.itemFilter).map((item, index) => (
									<Draggable
										style={{ transform: "none" }}
										key={item.id}
										draggableId={item.id.toString()}
										index={index}
										isDragDisabled={["A", "M", "U", "X"].includes(item.status) || context.expensesAggregate?.items.length > 1}
									>
										{(provided, draggableSnapshot) => (
											<React.Fragment>
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={`list-group-item ${getDragItemClass(draggableSnapshot)} ${STATUS_CLASS_NAME[item.status]} ${
														SELECTED_CLASS_NAME[item.isSelected]
													}`}
												>
													{expenseMediaItem(props, item)}
												</div>
												{draggableSnapshot.isDragging && (
													<div className={`list-group-item dragging-placeholder ${STATUS_CLASS_NAME[item.status]}`}>
														{expenseMediaItem(props, item)}
													</div>
												)}
											</React.Fragment>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</StrictModeDroppable>
					<Popover placement="right" isOpen={!!context.expensesAggregate?.items.length} target="expensesList" className="selected-aggregate">
						<PopoverHeader>
							<span>Multi Select [{context.expensesAggregate?.items.length}]</span>
							<input
								type="checkbox"
								id="deselectAllExpenses"
								defaultChecked={true}
								title="Click to deselect all items"
								onChange={e => props.onDeselectAll()}
							/>
						</PopoverHeader>
						<PopoverBody>
							<div>{context.expensesAggregate?.vendorName}</div>
							<div>{context.expensesAggregate?.narrative}</div>
							<div style={{ textAlign: "right" }}>{clearview.formatCurrency(context.expensesAggregate?.total)}</div>
						</PopoverBody>
					</Popover>
				</div>
			)
		}}
	</VouchingDetailsContext.Consumer>
)

ExpenseList.propTypes = {
	items: PropTypes.array,
	listId: PropTypes.string,
	title: PropTypes.string,
	itemActionsFn: PropTypes.func.isRequired,
	onItemSelected: PropTypes.func.isRequired,
	onDeselectAll: PropTypes.func.isRequired,
	onShowLedgerItems: PropTypes.func.isRequired,
	onEditExpense: PropTypes.func.isRequired,
}

const expenseMediaItem = (props, item) => {
	const toPage = item.toPage && item.toPage !== item.page ? item.toPage : false
	const page = item.page !== 1 || toPage ? item.page : false
	const split = parseInt(item.id) !== item.id ? item.id?.toString().split(".")[1] : false

	return (
		<Media>
			<input
				type="checkbox"
				id={item.id}
				name={item.id}
				style={{ marginLeft: -16, marginRight: 2, marginTop: -8 }}
				checked={item.isSelected}
				onChange={e => props.onItemSelected(item)}
			></input>
			<ExpenseAvatar expense={item}></ExpenseAvatar>
			<SmartMenu actions={props.itemActionsFn(item)} />
			<Media body className="hover" onClick={e => props.onEditExpense(e, item)}>
				<div className="d-flex-column">
					<div className="flex-1 d-flex-row justify-content-between">
						{formatVendorName(item.vendorName)}
						<ExpenseWarningIcon expense={item}></ExpenseWarningIcon>
						<Badge className="mt-1 h6 mb-1" color={DATE_COLOR[item.date]}>
							{item.date || "DATE?"}
						</Badge>
					</div>
					{item.narrative && <p className="mt-0 mb-1">{item.narrative}</p>}
					<div className="mt-0 mb-0 flex-1 d-flex-row justify-content-between">
						<p className="h6 flex-1 text-muted" title={`${clearview.S3EntityPath(item.s3)} p${item.page}-${item.toPage}`}>
							<span className="pr-2">{clearview.AfterLast(item.s3, "/")} </span>
							{(page || toPage || split) && (
								<span color="dark" title={`p${item.page}-${item.toPage}`}>
									Page {item.page} {toPage && <span> - {item.toPage}</span>}
									{split && <span> split {split}</span>}
								</span>
							)}
						</p>
						<div>
							<p className="h6 text-right">{formatRef(item.ref)}</p>
							<p className="h6 text-right">
								{["A", "M"].includes(item.status) && (
									<a
										href=""
										onClick={e => {
											clearview.StopPropagation(e)
											props.onShowLedgerItems(item)
										}}
										title="Show Ledger Line(s)"
									>
										{clearview.Icon.ledger}
									</a>
								)}{" "}
								{formatValue(item.total)}
							</p>
						</div>
					</div>
				</div>
			</Media>
		</Media>
	)
}
