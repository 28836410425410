import React, { useState } from "react"
import { connect } from "react-redux"
import { WP_ACTIONS } from "./store"
import { Link } from "react-router-dom"

import * as _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import { SimpleTable } from "../../../../components"
import { ETBExport } from "./components/ETBExport"
import { AddUpdateScheduleCodeTotal } from "./components"

function ETBPage({ isReadOnly, isChanged, periodEnd, workingPapers, onSaveBudget, onSaveClientReserve, onSaveClientTrialBalance }) {
	const [editor, setEditor] = useState(false)

	const onCancel = e => setEditor(false)

	const data = workingPapers.etb

	const editableClass = {
		false: "hover",
	}

	const editBudget = isReadOnly
		? row => false
		: row =>
				setEditor({
					addUpdateScheduleCodeTotal: {
						title: `${row.budget ? "Edit" : "Add"} Budget Item`,
						value: {
							schedule: row.schedule,
							code: row.code,
							nominalCode: row.nominalCode,
							total: row.budget || 0,
						},
						onSave: onSaveBudget,
					},
				})

	const editClientReserve = isReadOnly
		? row => false
		: row =>
				setEditor({
					addUpdateScheduleCodeTotal: {
						title: `${row.clientReserve ? "Edit" : "Add"} Reserve Fund Item`,
						value: {
							schedule: row.schedule,
							code: row.code,
							nominalCode: row.nominalCode,
							total: row.clientReserve || 0,
						},
						onSave: onSaveClientReserve,
					},
				})

	const editClientTrialBalance = isReadOnly
		? row => false
		: row =>
				setEditor({
					addUpdateScheduleCodeTotal: {
						title: `${row.clientTrialBalance ? "Edit" : "Add"} Trial Balance`,
						value: {
							schedule: row.schedule,
							code: row.code,
							nominalCode: row.nominalCode,
							total: row.clientTrialBalance || 0,
						},
						onSave: onSaveClientTrialBalance,
					},
				})

	const currencyAdd = <div style={{ width: "100%" }}>-</div>
	const columns = {
		code: { map: clearview.tidy.string },
		account: { map: (col, row) => clearview.tidy.string(row.nominalCode?.name), className: "nowrap" },
		budget: {
			heading: (
				<span>
					Budget
					<br />£
				</span>
			),
			map: (col, row) => (
				<span title="Click to edit..." className={editableClass[isReadOnly]} onClick={e => editBudget(row)}>
					{clearview.tidy.currencyOr(row.budget, currencyAdd)}
				</span>
			),
			mapSummary: clearview.tidy.currency,
			className: "currency text-dark etb-manually-keyed",
		},
		comparativeBudget: {
			heading: (
				<span>
					Prior Period Budget
					<br />£
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.comparativeBudget),
			mapSummary: clearview.tidy.currency,
			className: "currency text-dark etb-manually-keyed",
		},
		expenditure: {
			heading: (
				<span>
					Expenditure
					<br />£
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.expenditure),
			className: "currency text-dark etb-from-report",
		},
		income: {
			heading: (
				<span>
					Income Rpt
					<br />£
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.income),
			className: "currency text-dark etb-from-report",
		},
		clientReserve: {
			heading: (
				<span>
					Res Fund
					<br />
					Dr/(Cr)
				</span>
			),
			map: (col, row) => (
				<span title="Click to edit..." className={editableClass[isReadOnly]} onClick={e => editClientReserve(row)}>
					{clearview.tidy.currencyOr(row.clientReserve, currencyAdd)}
				</span>
			),
			mapSummary: clearview.tidy.currency,
			className: "currency text-dark etb-manually-keyed",
		},
		clientTrialBalance: {
			heading: (
				<span>
					Client TB
					<br />
					Dr/(Cr)
				</span>
			),
			map: (col, row) => (
				<span title="Click to edit..." className={editableClass[isReadOnly]} onClick={e => editClientTrialBalance(row)}>
					{clearview.tidy.currencyOr(row.clientTrialBalance, currencyAdd)}
				</span>
			),
			mapSummary: clearview.tidy.currency,
			className: "currency text-dark etb-manually-keyed",
		},

		perOriginalReportsDr: {
			heading: (
				<span>
					Per Original Reports
					<br />
					Dr
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.perOriginalReportsDr),
			className: "currency text-dark etb-calc",
		},
		perOriginalReportsCr: {
			heading: (
				<span>
					Per Original Reports
					<br />
					Cr
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.perOriginalReportsCr),
			className: "currency text-dark etb-calc",
		},

		journalDr: {
			heading: (
				<span>
					Journal
					<br />
					Dr
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.journalDr),
			className: "currency text-dark etb-from-working-paper",
		},
		journalCr: {
			heading: (
				<span>
					Journal
					<br />
					Cr
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.journalCr),
			className: "currency text-dark etb-from-working-paper",
		},

		drAndPrepay: {
			heading: (
				<span>
					Res c/f
					<br />
					Dr
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.drAndPrepay),
			className: "currency text-dark etb-from-working-paper",
		},
		crAndAccrual: {
			heading: (
				<span>
					Res c/f
					<br />
					Cr
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.crAndAccrual),
			className: "currency text-dark etb-from-working-paper",
		},

		perAmendedAgentReportsDrCr: {
			heading: (
				<span>
					Per Amended Agent Reports
					<br />
					Dr/(Cr)
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.perAmendedAgentReportsDrCr),
			className: "currency text-dark etb-calc",
		},

		presentationDr: {
			heading: (
				<span>
					Pres Adj
					<br />
					Dr
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.presentationDr),
			className: "currency text-dark",
		},
		presentationCr: {
			heading: (
				<span>
					Pres Adj
					<br />
					Cr
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.presentationCr),
			className: "currency text-dark",
		},

		perAccountsDrCr: {
			heading: (
				<span>
					Per Accounts
					<br />
					Dr/(Cr)
				</span>
			),
			map: (col, row) => clearview.tidy.currencyOr(row.perAccountsDrCr),
			className: "currency text-dark etb-calc",
		},
	}

	if (!data.length) return <p className="text-info">There is no data for this report.</p>
	return (
		<React.Fragment>
			<SimpleTable
				className="etb-table mt-2"
				columns={columns}
				data={data}
				groupBy={["schedule"]}
				summaryColumns={[
					"expenditure",
					"income",
					"clientReserve",
					"clientTrialBalance",
					"journalDr",
					"journalCr",
					"crAndAccrual",
					"drAndPrepay",
					"presentationDr",
					"presentationCr",
					"perOriginalReportsDr",
					"perOriginalReportsCr",
					"perAmendedAgentReportsDrCr",
					"perAccountsDrCr",
				]}
			/>
			<div className="mt-2 text-left">
				<Link
					to="."
					disabled={!!isChanged}
					title={!!isChanged ? "You must save your changes before running the ETB Export." : undefined}
					className="text-decoration-none nowrap mr-2 text-primary"
					onClick={e => {
						if (isChanged) clearview.ShowToast("warning", <p>You must save your changes before running the ETB Export.</p>)
						if (!isChanged) ETBExport(periodEnd, workingPapers.etb)
						clearview.StopPropagation(e)
						return false
					}}
				>
					{clearview.Icon.FileType.Excel} Export ETB
				</Link>
			</div>
			<AddUpdateScheduleCodeTotal
				name="addUpdateScheduleCodeTotal_Editor"
				config={{ title: editor.addUpdateScheduleCodeTotal?.title }}
				isOpen={!!editor.addUpdateScheduleCodeTotal}
				onCancel={onCancel}
				onSave={row => {
					editor.addUpdateScheduleCodeTotal?.onSave(row)
					onCancel()
				}}
				value={editor.addUpdateScheduleCodeTotal?.value}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => ({
	workingPapers: state.workingPapersReducer.workingPapers,
})

const mapDispatchToProps = dispatch => ({
	onSaveBudget: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_SCHEDULE_CODE_ROW,
			page: "budgets",
			row,
		}),
	onSaveClientReserve: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_SCHEDULE_CODE_ROW,
			page: "clientReserves",
			row,
		}),
	onSaveClientTrialBalance: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_SCHEDULE_CODE_ROW,
			page: "clientTrialBalances",
			row,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(ETBPage)
