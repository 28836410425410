import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"

import Dropdown from "react-bootstrap/Dropdown"

import AreYouSure from "../../../components/AreYouSure"
import EditClient_Form from "./EditClient_Form"
import AddClient_Form from "./AddClient_Form"
import AddProperty_Form from "./AddProperty_Form"
import ManageUsers_Form from "./ManageUsers_Form"
import CreateUsers_Form from "../../Workflows/CreateUsers/CreateUsers_Form"
import UserAssignments_Form from "./UserAssignments_Form"
import ImportBusinesses_Form from "../../Workflows/ImportBusinesses/ImportBusinesses_Form"
import ImportProperties_Form from "../../Workflows/ImportProperties/ImportProperties_Form"
import ImportPeriodEnds_Form from "../../Workflows/ImportPeriodEnds/ImportPeriodEnds_Form"

class ClientActions extends React.Component {
	static propTypes = {
		client: PropTypes.object.isRequired,
	}

	render() {
		const { client, doRemoveClient, user } = this.props
		const actions = client.actions || []

		const editPreferences = []

		const editTextractAnalysisRulesUrl = clearview.editPreferencesUrl(user, client, "textract-analysis-rules")
		if (editTextractAnalysisRulesUrl)
			editPreferences.push(
				<Link to={`${editTextractAnalysisRulesUrl}?sideBar=0`} className="text-decoration-none nowrap" target="textract-analysis-rules">
					<span title="Opens in a new window">{clearview.Icon.settings} Invoice Import Rules...</span>
				</Link>
			)

		const editVouchingVendorAliasesUrl = clearview.editPreferencesUrl(user, client, "vendor-aliases")
		if (editVouchingVendorAliasesUrl)
			editPreferences.push(
				<Link to={`${editVouchingVendorAliasesUrl}?sideBar=0`} className="text-decoration-none nowrap" target="vendor-aliases">
					<span title="Opens in a new window">{clearview.Icon.settings} Vendor Aliases...</span>
				</Link>
			)

		const editFeeMatrixUrl = clearview.editPreferencesUrl(user, client, "working-papers-fee-matrix")
		if (editFeeMatrixUrl)
			editPreferences.push(
				<Link to={`${editFeeMatrixUrl}?sideBar=0`} className="text-decoration-none nowrap" target="working-papers-fee-matrix">
					<span title="Opens in a new window">{clearview.Icon.matrix} Fee Matrix...</span>
				</Link>
			)
		return (
			<React.Fragment>
				<Dropdown hidden={!actions.length} className="align-self-center ml-auto" direction="down" onToggle={clearview.StopPropagation}>
					<Dropdown.Toggle variant="none">{clearview.Icon.actions}</Dropdown.Toggle>
					<Dropdown.Menu persist>
						<Dropdown.Item hidden={!actions.includes("SetProperties")}>
							<button id="EditClient_Form" className="tight">
								{clearview.Icon.edit} Edit...
							</button>
						</Dropdown.Item>

						<Dropdown.Item hidden={!actions.includes("AddClient")}>
							<button id="AddClient_Form" className="tight">
								{clearview.Icon.Business.Client} Add Client...
							</button>
						</Dropdown.Item>
						<Dropdown.Item hidden={!actions.includes("AddProperty")}>
							<button id="AddProperty_Form" className="tight">
								{clearview.Icon.Business.Property} Add Property...
							</button>
						</Dropdown.Item>

						<Dropdown.Item hidden={!actions.includes("AddClient")} divider />
						<Dropdown.Item hidden={!actions.includes("AddClient") || client.role === "Landlord"}>
							<button id="ImportBusinesses_Form" className="tight">
								{clearview.Icon.FileType.Excel} Upload Clients...
							</button>
						</Dropdown.Item>
						<Dropdown.Item hidden={!(actions.includes("AddProperty") || (actions.includes("AddClient") && client.role === "Customer"))}>
							<button id="ImportProperties_Form" className="tight">
								{clearview.Icon.FileType.Excel} Upload Properties...
							</button>
						</Dropdown.Item>
						<Dropdown.Item hidden={!actions.includes("AddClient")}>
							<button id="ImportPeriodEnds_Form" className="tight">
								{clearview.Icon.FileType.Excel} Upload Period Ends...
							</button>
						</Dropdown.Item>

						<Dropdown.Item hidden={!actions.includes("RemoveClient")} divider />
						<Dropdown.Item hidden={!actions.includes("RemoveClient")}>
							<button id="RemoveClient" className="tight">
								{clearview.Icon.delete} Delete...
							</button>
						</Dropdown.Item>

						<Dropdown.Item hidden={!actions.includes("ManageTeams")} divider />
						<Dropdown.Item hidden={!actions.includes("ManageTeams")}>
							<button id="ManageUsers_Form" className="tight">
								{clearview.Icon.Business.Users} Manage Users...
							</button>
						</Dropdown.Item>
						<Dropdown.Item hidden={!actions.includes("ManageTeams")}>
							<button id="CreateUsers_Form" className="tight">
								{clearview.Icon.FileType.Excel} Upload New Users...
							</button>
						</Dropdown.Item>
						<Dropdown.Item hidden={!actions.includes("ManageTeams")}>
							<button id="UserAssignments_Form" className="tight">
								{clearview.Icon.Business.Assignments} User Assignments...
							</button>
						</Dropdown.Item>

						<Dropdown.Item hidden={!editPreferences.length} divider />
						{editPreferences.map((it, idx) => (
							<Dropdown.Item key={`pref-${idx}`}>{it}</Dropdown.Item>
						))}
						<EditClient_Form client={client} />
						<AddClient_Form client={client} />
						<AddProperty_Form client={client} />
						<ImportBusinesses_Form business={client} />
						<ImportProperties_Form business={client} />
						<ImportPeriodEnds_Form business={client} />
						{client.parent && (
							<AreYouSure
								id="RemoveClient"
								message={
									<span>
										Remove <b className="nowrap">{client.name}</b> from <b className="nowrap">{client.parent.name}</b>, and all its child
										Properties and Period Ends?
									</span>
								}
								action={() => doRemoveClient(client)}
							/>
						)}
						<ManageUsers_Form business={client} />
						<CreateUsers_Form business={client} />
						<UserAssignments_Form business={client} />
					</Dropdown.Menu>
				</Dropdown>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

const mapDispatchToProps = dispatch => ({
	doRemoveClient: client =>
		dispatch({
			type: ACTIONS.CLIENT_REMOVE,
			client: client,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientActions)
