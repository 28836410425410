import React from "react"
import DOMPurify from "dompurify"
import parse from "html-react-parser"

import { Avatar, Card, Media, AvatarAddOn } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"

import { ReplyActions } from "./ReplyActions"

const ReplyAvatar = props => (
	<Avatar.Font
		size="sm"
		user={props.reply.updatedBy || props.reply.createdBy}
		className="ml-2"
		addOns={[
			<AvatarAddOn.Icon
				className={clearview.CommentTypeIconClass[props.reply.type]}
				color={clearview.CommentTypeColors[props.reply.type]}
				key="avatar-icon-sm"
			/>,
		]}
	/>
)

const ReplyBody = props => {
	const { type, comments, createdAt, createdBy, updatedAt, updatedBy, assignedTo } = props.reply

	const cleanHTML = DOMPurify.sanitize((comments || "").startsWith("<") ? comments : (comments || "").replaceAll("\n", "<br/>"), {
		USE_PROFILES: { html: true },
	})

	return (
		<React.Fragment>
			<Card body className="mb-2" type="border" color={clearview.CommentTypeColors[type]}>
				<div className="mb-0">
					{assignedTo && (
						<React.Fragment>
							<Avatar.Font
								size="sm"
								user={assignedTo}
								className="avatar-with-text mr-2"
								addOns={[
									<AvatarAddOn.Icon
										className={clearview.CommentTypeIconClass[type]}
										color={clearview.CommentTypeColors[type]}
										key="avatar-icon-sm"
									/>,
								]}
							>
								{assignedTo.initials}
							</Avatar.Font>
							<span className="text-inverse mr-2"> {assignedTo.name} </span>
						</React.Fragment>
					)}
					{parse(cleanHTML)}
					<ReplyActions periodEnd={props.periodEnd} query={props.query} reply={props.reply} />
				</div>
			</Card>

			<div className="mb-2">
				<span className="text-inverse mr-2"> {(updatedBy || createdBy).name} </span>
				<span className="small text-inverse mr-2">{clearview.FormatDateAndTime(updatedAt || createdAt)}</span>
			</div>
		</React.Fragment>
	)
}

const ReplyLeft = props => {
	return (
		<React.Fragment>
			<Media className="mb-2">
				<Media body>
					<ReplyBody periodEnd={props.periodEnd} query={props.query} reply={props.reply} side="left" />
				</Media>
				<Media left className="mr-3">
					<ReplyAvatar reply={props.reply} />
				</Media>
			</Media>
		</React.Fragment>
	)
}

const ReplyRight = props => {
	return (
		<React.Fragment>
			<Media className="mb-2">
				<Media right className="mr-3">
					<ReplyAvatar reply={props.reply} side="right" />
				</Media>
				<Media body>
					<ReplyBody periodEnd={props.periodEnd} query={props.query} reply={props.reply} />
				</Media>
			</Media>
		</React.Fragment>
	)
}

export { ReplyLeft, ReplyRight }
