import React from "react"
import { connect } from "react-redux"
import { Typeahead } from "react-bootstrap-typeahead"

function NominalCode({ value, onChange, nominalCodes }) {
	return (
		<Typeahead
			id="nominalCode_ta"
			className="ml-0 nominal-code"
			onChange={e => onChange(e[0])}
			labelKey="desc"
			options={nominalCodes}
			placeholder="NL Code..."
			allowNew={false}
			caseSensitive={false}
			clearButton={false}
			highlightOnlyResult={false}
			selected={!!value ? [value] : []}
		></Typeahead>
	)
}

const mapStateToProps = state => {
	return {
		nominalCodes: state.workingPapersReducer.workingPapers.config.NOMINAL_CODES.all,
	}
}

NominalCode = connect(mapStateToProps)(NominalCode)

export { NominalCode }
