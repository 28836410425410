import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import Reply_EditForm from "./Reply_EditForm"
import AreYouSure from "../../../components/AreYouSure"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class ReplyActions extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		query: PropTypes.object.isRequired,
		reply: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props

		this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
	}

	handleDeleteClicked(e) {
		this.props.deleteReply({
			periodEnd: this.props.periodEnd,
			queryId: this.props.query.id,
			replyId: this.props.reply.id,
		})
	}

	render() {
		const { periodEnd, query, reply } = this.props

		return (
			<React.Fragment>
				<Reply_EditForm target={`reply_EditForm_${reply.id}`} periodEnd={periodEnd} query={query} reply={reply} />

				<div className="ReplyActions">
					<button
						hidden={!(reply.actions || []).includes("Update")}
						id={`reply_EditForm_${reply.id}`}
						className="text-secondary tight"
						title="Edit reply..."
					>
						{clearview.Icon.edit}
					</button>
					<button
						hidden={!(reply.actions || []).includes("Delete")}
						id={`reply_Delete_${reply.id}`}
						className="text-secondary tight"
						title="Delete reply..."
					>
						{clearview.Icon.delete}
					</button>
					{(reply.actions || []).includes("Delete") && (
						<AreYouSure
							id={`reply_Delete_${reply.id}`}
							message={
								<span>
									<span className="mr-1">Permanently delete Reply from</span>
									<span className="bold nowrap mr-1">{(reply.updatedBy || reply.createdBy).name}</span>
									<span className="mr-1">on</span>
									<span className="bold nowrap">{clearview.FormatDateAndTime(reply.updatedAt || reply.createdAt)}</span>
									<span>?</span>
								</span>
							}
							action={() => this.handleDeleteClicked()}
						/>
					)}
				</div>
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	deleteReply: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_DELETE_REPLY,
			...payload,
		}),
})

ReplyActions = connect(null, mapDispatchToProps)(ReplyActions)

export { ReplyActions }
