import React from "react"

import { connect } from "react-redux"
import { Typeahead } from "react-bootstrap-typeahead"
import PropTypes from "prop-types"

import { Form, FormGroup, FormText, InputGroup, InputGroupAddon, UncontrolledModal, ModalHeader, ModalBody, ModalFooter } from "../../../components"

import { Auth } from "aws-amplify"
import * as clearview from "../../../components/@Clearview"
import * as ACTIONS from "../../../store/actions"
import { setImpersonate } from "../../../api/CustomerApi"

class Impersonate_Form extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		actors: PropTypes.array.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.actors = []

		this.handleImpersonateChange = this.handleImpersonateChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)

		this.state = { impersonate: null }
	}

	handleImpersonateChange(option) {
		if (!!option?.customOption) {
			this.setState(
				{
					impersonate: option.description,
				},
				() => this.handleActionButtonClick()
			)
		} else
			this.setState({
				impersonate: option.value,
			})
	}

	handleActionButtonClick(event) {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}

		this.setState({ isBusy: true })

		setImpersonate(this.state.impersonate).then(_ =>
			Auth.signOut()
				.then(data => (window.location.href = "/"))
				.catch(err => console.error(err))
		)
	}

	get isImpersonateRequired() {
		return !this.state.impersonate
	}

	get isValid() {
		return !this.isImpersonateRequired
	}

	render() {
		const { actors } = this.props

		return (
			<UncontrolledModal style={{ maxWidth: 700 }} target="Impersonate_Form">
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="div">
						<div className="d-flex align-items-center">
							<h1 className=" text-primary mr-2">{clearview.Icon.IsImpersonating[false]}</h1>
							<h6>Impersonate</h6>
						</div>
					</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<p className="ml-4">
								When you impersonate another user, you will need to login again <b>as yourself</b> and you will see Clearview with the data and
								options available to the Impersonated user. However, any changes you make will have <b>your</b> credentials recorded against the
								change.
							</p>
						</FormGroup>
						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">User to Impersonate:</InputGroupAddon>
								<Typeahead
									id="impersonate"
									labelKey="description"
									options={actors}
									placeholder="Choose a User to Impersonate..."
									allowNew={true}
									caseSensitive={false}
									clearButton={true}
									onChange={e => this.handleImpersonateChange(e[0])}
								/>
							</InputGroup>
							<FormText hidden={!this.state.showValidation || !this.ImpersonateRequired} className="text-danger">
								You should select a user to impersonate.
							</FormText>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close
							color="default"
							className="text-primary"
							disabled={!this.isValid}
							name="doImpersonate"
							onClick={this.handleActionButtonClick}
						>
							Impersonate!
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapStateToProps = state => {
	const user = state.userReducer.user || clearview.User.UnauthenticatedUser
	const userTeam = user.teams?.find(it => it.type === "Users")
	const userRole = userTeam?.role
	const canImpersonate = (user.isInHouse && ["Admin", "System"].includes(userRole)) || !!user.preferredUsername

	const actors = []
	if (canImpersonate) {
		let isChildBusiness = false
		ACTIONS.crawlBranches(state.clientsReducer.clients.data, client => {
			if (!isChildBusiness && client.reference === userTeam.business.reference) isChildBusiness = true
			if (isChildBusiness)
				client.teams
					?.find(it => it.type === "Users")
					?.users.map(u => {
						if (u.username !== user.username)
							actors.push({ description: `${u.name} / ${u.username} [${u.role}] ${client.name}`, value: u.username })
					})
		})
	}

	return {
		user,
		actors: actors.sort(clearview.orderBy(it => it.description)),
	}
}

export default connect(mapStateToProps)(Impersonate_Form)
