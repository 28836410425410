import React from "react"

const Spinner = props => (
	<div style={{ opacity: 1 }}>
		<svg className="clearview-spinner" viewBox="0 0 50 50">
			<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
		</svg>
	</div>
)

export { Spinner }
