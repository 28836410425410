import React from "react"

import * as clearview from "../../components/@Clearview"
import Dropdown from "react-bootstrap/Dropdown"

export const SmartMenu = props => {
	let actions = props.actions
	if (props.predicate) actions = actions.filter(props.predicate)
	return (
		<React.Fragment>
			<Dropdown hidden={false} className="align-self-center ml-0" direction="left">
				<Dropdown.Toggle className="pl-0" variant="none" onToggle={clearview.StopPropagation}></Dropdown.Toggle>
				<SmartMenuItems actions={actions} />
			</Dropdown>
		</React.Fragment>
	)
}

export const SmartMenuItems = props => (
	<Dropdown.Menu end persist>
		{props.actions.map((it, idx) => (
			<SmartMenuItem key={idx} label={it.label} action={it.action} isDisabled={(it.enabled !== undefined && it.enabled === false) || it.disabled} />
		))}
	</Dropdown.Menu>
)

export const SmartMenuItem = props => (
	<Dropdown.Item disabled={!!props.isDisabled}>
		<button className="tight" onClick={e => (props.action || (e => alert("To Do")))(e)}>
			{props.label}
		</button>
	</Dropdown.Item>
)
