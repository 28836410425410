import React from "react"
import PropTypes from "prop-types"

import { Button, CustomInput, InputGroup, InputGroupAddon, UncontrolledButtonDropdown, FormGroup, DropdownToggle, ExtendedDropdown } from "../../../components"

import * as clearview from "../../../components/@Clearview"
import { Consumer } from "../../../layout/context"

class PeriodEndSort extends React.Component {
	static propTypes = {
		className: PropTypes.string,
	}

	constructor(props) {
		super(props)

		this.props = props
		this.state = {}
	}

	render() {
		return (
			<Consumer>
				{context => (
					<InputGroup className={this.props.className} size="sm" style={{ width: 235 }}>
						<InputGroupAddon addonType="prepend">
							<UncontrolledButtonDropdown className="align-self-center ml-auto" direction="left">
								<DropdownToggle
									color="link"
									title="Sort..."
									outline
									caret
									className="btn-outline-secondary"
									style={{ paddingTop: 2, paddingBottom: 2 }}
								>
									{context.sortCriteriaCount() === 0 && clearview.Icon.unsorted}
									{context.sortCriteriaCount() !== 0 && <span className="text-primary">{clearview.Icon.sorted}</span>}
								</DropdownToggle>
								<ExtendedDropdown>
									<ExtendedDropdown.Section className="d-flex justify-content-between align-items-center">
										<FormGroup row>
											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.managingAgent}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc1"
													name="sort"
													label="Managing Agent"
													inline
													value="asc"
													defaultChecked={context.state.sort.managingAgent === "asc"}
													onChange={e => context.handleSortToggleChange(e, "landlord")}
												/>
												{/* <CustomInput className="ml-2 mt-2 mb-2" type="radio" id="sortDesc1" name="sort" label="Desc" inline value="desc" defaultChecked={context.state.sort.managingAgent === "desc"} onChange={(e) => context.handleSortToggleChange(e,"landlord")} /> */}
											</InputGroup>

											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.landlord}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc2"
													name="sort"
													label="Landlord"
													inline
													value="asc"
													defaultChecked={context.state.sort.landlord === "asc"}
													onChange={e => context.handleSortToggleChange(e, "landlord")}
												/>
												{/* <CustomInput className="ml-2 mt-2 mb-2" type="radio" id="sortDesc2" name="sort" label="Desc" inline value="desc" defaultChecked={context.state.sort.landlord === "desc"} onChange={(e) => context.handleSortToggleChange(e,"landlord")} /> */}
											</InputGroup>

											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.property}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc3"
													name="sort"
													label="Property"
													inline
													value="asc"
													defaultChecked={context.state.sort.property === "asc"}
													onChange={e => context.handleSortToggleChange(e, "property")}
												/>
												{/* <CustomInput className="ml-2 mt-2 mb-2" type="radio" id="sortDesc3" name="sort" label="Desc" inline value="desc" defaultChecked={context.state.sort.property === "desc"} onChange={(e) => context.handleSortToggleChange(e,"property")} /> */}
											</InputGroup>

											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.template}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc4"
													name="sort"
													label="Template"
													inline
													value="asc"
													defaultChecked={context.state.sort.template === "asc"}
													onChange={e => context.handleSortToggleChange(e, "template")}
												/>
												{/* <CustomInput className="ml-2 mt-2 mb-2" type="radio" id="sortDesc4" name="sort" label="Desc" inline value="desc" defaultChecked={context.state.sort.template === "desc"} onChange={(e) => context.handleSortToggleChange(e,"template")} /> */}
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndStatus}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc5"
													name="sort"
													label="Asc"
													inline
													value="asc"
													defaultChecked={context.state.sort.periodEndStatus === "asc"}
													onChange={e => context.handleSortToggleChange(e, "periodEndStatus")}
												/>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortDesc5"
													name="sort"
													label="Desc"
													inline
													value="desc"
													defaultChecked={context.state.sort.periodEndStatus === "desc"}
													onChange={e => context.handleSortToggleChange(e, "periodEndStatus")}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndRag}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc6"
													name="sort"
													label="Asc"
													inline
													value="asc"
													defaultChecked={context.state.sort.periodEndRag === "asc"}
													onChange={e => context.handleSortToggleChange(e, "periodEndRag")}
												/>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortDesc6"
													name="sort"
													label="Desc"
													inline
													value="desc"
													defaultChecked={context.state.sort.periodEndRag === "desc"}
													onChange={e => context.handleSortToggleChange(e, "periodEndRag")}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.stageStatus}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc7"
													name="sort"
													label="Asc"
													inline
													value="asc"
													defaultChecked={context.state.sort.stageStatus === "asc"}
													onChange={e => context.handleSortToggleChange(e, "stageStatus")}
												/>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortDesc7"
													name="sort"
													label="Desc"
													inline
													value="desc"
													defaultChecked={context.state.sort.stageStatus === "desc"}
													onChange={e => context.handleSortToggleChange(e, "stageStatus")}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.stageRag}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc8"
													name="sort"
													label="Asc"
													inline
													value="asc"
													defaultChecked={context.state.sort.stageRag === "asc"}
													onChange={e => context.handleSortToggleChange(e, "stageRag")}
												/>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortDesc8"
													name="sort"
													label="Desc"
													inline
													value="desc"
													defaultChecked={context.state.sort.stageRag === "desc"}
													onChange={e => context.handleSortToggleChange(e, "stageRag")}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">{clearview.Icon.due}</InputGroupAddon>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortAsc9"
													name="sort"
													label="Asc"
													inline
													value="asc"
													defaultChecked={context.state.sort.due === "asc"}
													onChange={e => context.handleSortToggleChange(e, "due")}
												/>
												<CustomInput
													className="ml-2 mt-2 mb-2"
													type="radio"
													id="sortDesc9"
													name="sort"
													label="Desc"
													inline
													value="desc"
													defaultChecked={context.state.sort.due === "desc"}
													onChange={e => context.handleSortToggleChange(e, "due")}
												/>
											</InputGroup>
										</FormGroup>
									</ExtendedDropdown.Section>
								</ExtendedDropdown>
							</UncontrolledButtonDropdown>
						</InputGroupAddon>
						{context.sortCriteriaCount() !== 0 && (
							<InputGroupAddon addonType="append" title="Clear sort">
								<Button outline onClick={context.clearSort}>
									<i className="fa fa-fw fa-times"></i>
								</Button>
							</InputGroupAddon>
						)}
					</InputGroup>
				)}
			</Consumer>
		)
	}
}

export { PeriodEndSort }
