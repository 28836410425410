import React from 'react';


import { 
    Row,
    Col,
    Card,
    CardTitle,
    CardBody
} from '../../../../components';

import {
    TimelineMini
} from "../../../components/Timeline/TimelineMini"

import * as clearview from '../../../../components/@Clearview';



export const StageColorCoding = () => (<React.Fragment>
    <Row>

        <Col lg={ 4 }>
            <Card className="mb-3">
                <CardBody>
                    <CardTitle tag="h6">
                        Status
                    </CardTitle>
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageStatusColors.NotYetOpen}`}
                        badgeTitle={clearview.StageStatusNames.NotYetOpen}
                            badgeColor={clearview.StageStatusColors.NotYetOpen}
                        phrase="Stage has not yet been opened"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageStatusColors.Open}`}
                        badgeTitle={clearview.StageStatusNames.Open}
                            badgeColor={clearview.StageStatusColors.Open}
                        phrase="Stage is open"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageStatusColors.OnHold}`}
                        badgeTitle={clearview.StageStatusNames.OnHold}
                            badgeColor={clearview.StageStatusColors.OnHold}
                        phrase="Stage is on hold"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageStatusColors.RequestClosed}`}
                        badgeTitle={clearview.StageStatusNames.RequestClosed}
                            badgeColor={clearview.StageStatusColors.RequestClosed}
                        phrase="Stage has been requested to be closed"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageStatusColors.Closed}`}
                        badgeTitle={clearview.StageStatusNames.Closed}
                            badgeColor={clearview.StageStatusColors.Closed}
                        phrase="Stage is closed"
                        date={null}
                    />
                </CardBody>
            </Card>
        </Col>                

        <Col lg={ 4 }>
            <Card className="mb-3">
                <CardBody>
                    <CardTitle tag="h6">
                        RAG
                    </CardTitle>
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageRagColors.NotDue}`}
                        badgeTitle={clearview.StageRagNames.NotDue}
                            badgeColor={clearview.StageRagColors.NotDue}
                        phrase="Stage Not Due"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageRagColors.OnHold}`}
                        badgeTitle={clearview.StageRagNames.OnHold}
                            badgeColor={clearview.StageRagColors.OnHold}
                        phrase="Stage On Hold"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageRagColors.OnTime}`}
                        badgeTitle={clearview.StageRagNames.OnTime}
                            badgeColor={clearview.StageRagColors.OnTime}
                        phrase="Stage On Time"
                        date={null}
                    />
                     <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageRagColors.Warning}`}
                        badgeTitle={clearview.StageRagNames.Warning}
                            badgeColor={clearview.StageRagColors.Warning}
                        phrase="Stage Warning"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.stage}
                            iconClassName={`text-${clearview.StageRagColors.Overdue}`}
                        badgeTitle={clearview.StageRagNames.Overdue}
                            badgeColor={clearview.StageRagColors.Overdue}
                        phrase="Stage Overdue"
                        date={null}
                    />
               </CardBody>
            </Card>
        </Col>   

    </Row>
</React.Fragment>)
