import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Row, Col, Card, CardHeader, CardBody, Form, FormGroup, InputGroup, CustomInput } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class Conditions extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		doUpdate: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props
	}

	render() {
		const { periodEnd, stage, doUpdate } = this.props

		return (
			<Row>
				<Col md={12}>
					<Card className="mb-3" type="border">
						<CardHeader tag="h6">Conditions</CardHeader>
						<CardBody>
							<Form>
								<fieldset disabled={periodEnd.isBusy ? "disabled" : ""}>
									<FormGroup>
										{stage.conditions.map(it => (
											<InputGroup key={it.id}>
												<CustomInput
													disabled={!(stage.actions || []).includes("SetCondition")}
													style={{ zIndex: 1 }}
													className="mr-2"
													type="checkbox"
													id={it.id}
													key={`c_${it.id}`}
													label={it.templateCondition?.name}
													defaultChecked={it.isComplete}
													onChange={e => doUpdate({ periodEnd: periodEnd, conditionId: e.target.id, isChecked: e.target.checked })}
												>
													{it.isComplete && it.updatedAt && (
														<span key={`lc_${it.id}`} className="ml-2 text-info">
															{clearview.FormatDateAndTime(it.updatedAt)} {it.updatedBy?.name}
														</span>
													)}
												</CustomInput>
											</InputGroup>
										))}
									</FormGroup>
								</fieldset>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doUpdate: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_CONDITION_UPDATE,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Conditions)
