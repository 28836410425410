import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

import { UncontrolledModal, ModalHeader, ModalBody, Media, Avatar, Container, ListGroup, ListGroupItem, Badge } from "../../../../components"
import { HeaderMain } from "../../../components/HeaderMain"
import { Spinner } from "../../../components/Spinner"
import { HelpPopup } from "../../../components/HelpPopup"

import _ from "lodash"
import * as clearview from "../../../../components/@Clearview"
import * as Api from "../../../../api/CustomerApi"

const labelColors = {
	green: "info",
	yellow: "yellow",
	orange: "warning",
	red: "danger",
	purple: "purple",
	blue: "",
	sky: "",
	lime: "",
	pink: "",
	black: "dark",
	green_dark: "green-dark",
	yellow_dark: "yellow",
	orange_dark: "",
	red_dark: "red-dark",
	purple_dark: "purple",
	blue_dark: "primary",
	sky_dark: "",
	lime_dark: "",
	pink_dark: "",
	black_dark: "",
	green_light: "green-light",
	yellow_light: "",
	orange_light: "",
	red_light: "",
	purple_light: "",
	blue_light: "",
	sky_light: "",
	lime_light: "",
	pink_light: "",
	black_light: "",
}

export default function Workstream({ guid, name }) {
	const [workstream, setWorkstream] = useState(false)

	if (!workstream) {
		Api.getWorkstream(guid).then(resp => setWorkstream(resp))
		return <Spinner key="spinner"></Spinner>
	}

	const lists = workstream.filter(it => !it.closed && !["Done", "Key"].includes(it.name))
	const columnWidth = lists.length ? `${parseInt((1 / lists.length) * 95)}%` : "100%"

	const hasDetails = card => !!card.desc || !!card.badges.comments || !!card.idChecklists.length
	const findCard = (listId, cardId) => workstream.find(it => it.id === listId).cards.find(it => it.id === cardId)

	const insertComments = (listId, cardId, comments) => {
		const card = findCard(listId, cardId)
		card.comments = comments
		setWorkstream(_.cloneDeep(workstream))
	}

	const insertChecklists = (listId, cardId, resp) => {
		const card = findCard(listId, cardId)
		card.checklists = resp.checklists
		setWorkstream(_.cloneDeep(workstream))
	}

	const loadComments = (list, card) => {
		if (!!card.badges.comments && !card.comments) Api.getWorkstreamItemComments(card.id).then(resp => insertComments(list.id, card.id, resp))
	}

	const loadChecklists = (list, card) => {
		if (!!card.idChecklists.length && !card.checklists) Api.getWorkstreamItemChecklists(card.id).then(resp => insertChecklists(list.id, card.id, resp))
	}

	return (
		<Container className="full-width">
			<HeaderMain
				title={
					<span className="text-primary">
						{clearview.Icon.workStream} {name}
					</span>
				}
				className="mb-4 mt-0"
				actions={
					<div>
						{WorkstreamHelpPopups[guid]}
						{LabelKeyPopup}
					</div>
				}
			/>
			<div className="d-flex justify-content-between full-width">
				{lists.map(list => (
					<div key={list.id} style={{ width: columnWidth }}>
						<h5>
							{list.name}
							{ListHelpPopups[list.name]}
						</h5>
						<ListGroup>
							{list.cards
								.sort((a, b) => (a.pos < b.pos ? -1 : 1))
								.map(card => (
									<ListGroupItem key={card.id} className={hasDetails(card) ? "hover mb-2" : "mb-2"} id={`detail_${card.id}`}>
										<div className="d-flex justify-content-between">
											<WorkItemHeader card={card} />
											{hasDetails(card) && (
												<React.Fragment>
													<div title="View Details..." className="text-secondary">
														{clearview.Icon.more}
													</div>
													<WorkItemDetail
														card={card}
														onOpened={e => {
															loadComments(list, card)
															loadChecklists(list, card)
														}}
													/>
												</React.Fragment>
											)}
										</div>
									</ListGroupItem>
								))}
						</ListGroup>
					</div>
				))}
			</div>
		</Container>
	)
}

function WorkItemHeader({ card, tag }) {
	const Tag = tag || "span"

	const hasIcons = !!card.labels.length || !!card.badges.comments
	const idBadge = className => (
		<Badge
			key={card.id}
			color="secondary"
			title={`This item's full ID is ${card.id}. Latest activity ${clearview.FormatDateAndTime(card.dateLastActivity)}`}
			className={className || ""}
		>
			{card.id.slice(-4).toUpperCase()}
		</Badge>
	)

	if (hasIcons)
		return (
			<div>
				<h6>
					{idBadge("mr-2")}
					{card.labels.map(label => (
						<Badge key={label.id} color={labelColors[label.color]} className="mr-2">
							{label.name}
						</Badge>
					))}
					{!!card.badges.comments && (
						<span className="mr-2 text-primary" title="Comments">
							{clearview.CommentTypeIcon.Comment}
							<sup>{card.badges.comments}</sup>
						</span>
					)}
				</h6>
				<Tag>{card.name}</Tag>
			</div>
		)

	return (
		<Tag>
			{idBadge("mr-1")} {card.name}
		</Tag>
	)
}

function WorkItemDetail({ card, onOpened }) {
	return (
		<UncontrolledModal style={{ minWidth: 800 }} target={`detail_${card.id}`} onOpened={onOpened}>
			<ModalHeader>
				<WorkItemHeader card={card} tag="h4" />
				{clearview.CloseModal}
			</ModalHeader>

			<ModalBody className="for-text-content">
				{!!card.desc && <ReactMarkdown className="text-content" children={card.desc} remarkPlugins={[remarkGfm]} />}
				{!!card.badges.comments && <WorkItemComments comments={card.comments} />}
				{!!card.idChecklists.length && <WorkItemChecklists checklists={card.checklists} />}
			</ModalBody>
		</UncontrolledModal>
	)
}

function WorkItemComments({ comments }) {
	if (!comments) return <Spinner />
	return comments.sort(clearview.orderBy(a => a.date)).map(comment => (
		<Media key={comment.id} className="mb-2">
			<Media left className="mr-3">
				<Avatar.Font size="sm" bgColor="blue" className="avatar-with-text mr-1" title={comment.memberCreator.fullName}>
					{comment.memberCreator.initials}
				</Avatar.Font>
			</Media>
			<Media body>
				<h6>
					<b>{comment.memberCreator.fullName}</b> {clearview.FormatDateAndTime(comment.date)}
				</h6>
				<ReactMarkdown children={comment.data.text} remarkPlugins={[remarkGfm]} />
			</Media>
		</Media>
	))
}

function WorkItemChecklists({ checklists }) {
	if (!checklists) return <Spinner />
	return checklists.map(checklist => (
		<div key={checklist.id} className="mt-2 text-content">
			<h6>
				<u>{checklist.name}</u>
			</h6>
			<dl className="inline ml-1">
				{checklist.checkItems.map((it, idx) => (
					<React.Fragment key={idx}>
						<dt className="mr-2">{clearview.Icon.Checked[it.state === "complete"]}</dt>
						<dd>{it.name}</dd>
					</React.Fragment>
				))}
			</dl>
		</div>
	))
}

export const LabelKeyPopupContent = style => (
	<div style={style}>
		<p>
			<Badge color={labelColors.red} className="mr-2">
				Bug
			</Badge>
			An error which prevents normal/expected use of the system.
		</p>
		<p>
			<Badge color={labelColors.green} className="mr-2">
				Small
			</Badge>
			Only minor development is needed &lt; 0.5 day.
		</p>
		<p>
			<Badge color={labelColors.orange} className="mr-2">
				Medium
			</Badge>
			Development may involve minor schema or procedural changes ~ 1 day.
		</p>
		<p>
			<Badge color={labelColors.purple} className="mr-2">
				Large
			</Badge>
			Development will involve schema change, new pages or procedures, etc. &gt; 1 day.
		</p>
		<p>
			<Badge color={labelColors.green_light} className="mr-2">
				Ready
			</Badge>
			Development is done, but not yet released.
		</p>
		<p>
			<Badge color={labelColors.yellow_dark} className="mr-2">
				Fortus
			</Badge>
			Requests raised by Fortus for Fortus specific functionality - work likely to be cross-charged to Fortus.
		</p>
		<p>
			<Badge color={labelColors.blue_dark} className="mr-2">
				BW
			</Badge>
			Functionality specific to Bennett Whitehouse mode of operations.
		</p>
		<p>
			<Badge color={labelColors.black} className="mr-2">
				Blocked
			</Badge>
			Requires input before development can proceed.
		</p>
	</div>
)

const LabelKeyPopup = (
	<HelpPopup id="hp_key" title="Key to Labels" icon={<i className="fa fa-fw fa-question-circle text-info hover" title="Click for key"></i>}>
		{LabelKeyPopupContent({ backgroundColor: "whitesmoke", color: "black", padding: "0.4rem" })}
	</HelpPopup>
)

const WorkstreamHelpPopups = {
	Oez8ZcUJ: (
		<HelpPopup id="hp_Oez8ZcUJ" title="Clearview Workstream">
			<p>
				This board contains the work items for the main <b>Clearview functions and User features</b>.
			</p>
			<p>
				This board is maintained and updated by <b>Keith Myles</b>.
			</p>
			<p>
				This is a <b>fast moving</b> board. Items are likely to be added, moved and changed status every day or so.
			</p>
			<p>
				To add a work request, report a bug or discuss an item, email{" "}
				<b>
					<a color="white" style={{ color: "white" }} href="mailto:keithm@clearviewsoftware.co.uk">
						keithm@clearviewsoftware.co.uk
					</a>
				</b>
				.
			</p>
			<p>
				All new items will be given a random 4 character ID, e.g. <Badge color="secondary">6DF8</Badge> and added to the <b>Backlog</b> list.
			</p>
			<p>
				<b>Backlog</b> items will be reviewed by Keith and other members of the Change Control Board. Once reviewed, items will have a{" "}
				<Badge color="green">Small</Badge>, <Badge color="orange">Medium</Badge> or <Badge color="purple">Large</Badge> badge applied.
			</p>
			<p>
				As part of regular planning (typically monthly), items which have been reviewed will be moved into the <b>To Do</b> list for development.
			</p>
			<p>
				The <b>Doing</b> list shows items which are currently being worked on. The <Badge color={labelColors.blue_dark}>Ready</Badge> label will be
				added when they are ready to be released which will usually happen within a couple of days.
			</p>
			<p>After release, completed items will be shown in the main Support/Releases page and removed from this Workstream.</p>
		</HelpPopup>
	),
	sPG7ZfgT: (
		<HelpPopup id="hp_sPG7ZfgT" title="Infrastructure Workstream">
			<p>
				This board contains the work items for Clearview <b>Infrastructure and Support</b> features.
			</p>
			<p>End-users are unlikely to be interested in this board.</p>
			<p>
				These items typically do not correspond with specific user features, but are required/desired in order to improve reliability, maintainability,
				support and scalability.
			</p>
			<p>
				This board is maintained and updated by <b>Keith Myles</b> and is operated in a very similar way to the <i>Clearview Workstream</i> board.
			</p>
		</HelpPopup>
	),
	lGhuKSJ5: (
		<HelpPopup id="hp_lGhuKSJ5" title="Road Map">
			<p>Major Modules & Features being planned/designed for development in the future, or on the back burner.</p>
		</HelpPopup>
	),
}

const ListHelpPopups = {
	New: (
		<HelpPopup id="hp_new" title="Backlog/New">
			<p>
				Bugs & Feature Requests are raised on this list to be reviewed, categorized and prioritized. Generally, items near the top are the most recent
				or urgent.
			</p>
		</HelpPopup>
	),
	Backlog: (
		<HelpPopup id="hp_backlog" title="Backlog/New">
			<p>Bugs & Feature Requests should be raised on this list first to be reviewed, categorized and prioritized.</p>
		</HelpPopup>
	),
	"To Do": (
		<HelpPopup id="hp_todo" title="To Do">
			<p>This list is for Bug Fixes and Features scheduled for imminent development.</p>
		</HelpPopup>
	),
	Doing: (
		<HelpPopup id="hp_doing" title="Doing">
			<p>Bug Fixes and Features currently under active development.</p>
			<p>
				Items are given the label <Badge color={labelColors.green_light}>Ready</Badge> when they are ready to be released.
			</p>
			<p>After release and acceptance they will be shown in the main Support/Releases page and moved into the Closed table.</p>
		</HelpPopup>
	),
	"Road Map": (
		<HelpPopup id="hp_roadMap" title="Road Pap">
			<p>Major Modules & Features being planned/designed for development in the future.</p>
		</HelpPopup>
	),
	"Phase Two": (
		<HelpPopup id="hp_phaseTwo" title="Phase Two">
			<p>This list is for desirable Feature Requests and Suggestions deferred to a future phase.</p>
		</HelpPopup>
	),
	"Ice Box": (
		<HelpPopup id="hp_iceBox" title="Ice Box">
			<p>This items are on the back burner. They are unlikely to be scheduled for development in the foreseeable future.</p>
		</HelpPopup>
	),
}
