import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Form, FormGroup, FormFeedback, Input, InputGroup, UncontrolledModal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"

class Stage_PutOnHoldForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		doUpdate: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = { comments: "" }
		this.props = props

		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleCommentsChange(event) {
		this.setState({
			comments: event.target.value,
		})
	}

	handleActionButtonClick(event, action) {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}
		this.props.doUpdate({
			action: action,
			periodEnd: this.props.periodEnd,
			comments: this.state.comments,
		})
		this.setState({ comments: "" })
		return true
	}

	get isCommentsRequired() {
		return !(this.state.comments && this.state.comments.length)
	}

	get isValid() {
		return !this.isCommentsRequired
	}

	render() {
		return (
			<UncontrolledModal target={`stage${this.props.stage.id}_PutOnHoldForm`}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">Put On hold</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<Input
									invalid={this.state.showValidation && this.isCommentsRequired}
									type="textarea"
									name="comments"
									id="comments"
									placeholder="Comments..."
									value={this.state.comments}
									onChange={this.handleCommentsChange}
								/>
								<FormFeedback>Comments are required</FormFeedback>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close
							color="default"
							className="text-primary"
							disabled={!this.isValid}
							onClick={e => this.handleActionButtonClick(e, "OnHold")}
						>
							Put On Hold
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doUpdate: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Stage_PutOnHoldForm)
