import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import _ from "lodash"
import cellEditFactory, { Type } from "../../../../react-bootstrap-table2-editor"

import { Button, Card, CardHeader, CardBody, CardFooter } from "../../../../components"
import { CustomExportCSV } from "./CustomExportButton"
import { CustomSearch } from "./CustomSearch"

import PropTypes from "prop-types"

import * as clearview from "../../../../components/@Clearview"

export class PhaseTable extends React.Component {
	static propTypes = {
		customer: PropTypes.object.isRequired,
		isEditing: PropTypes.bool.isRequired,
		onChange: PropTypes.func.isRequired,
		addPhase: PropTypes.func.isRequired,
		removePhase: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props
	}

	render() {
		const { customer, isEditing, onChange, addPhase, removePhase } = this.props

		const columnDefs = [
			{
				dataField: "sequence",
				text: "Phase",
				headerAlign: "center",
				align: "center",
				editable: false,
			},
			{
				dataField: "name",
				text: "Name",
				align: "top",
				type: "string",
				editor: { type: Type.TEXT, placeholder: "Phase name..." },
			},
		]

		const editColumns = [
			{
				isDummyField: true,
				dataField: "id",
				text: "Del",
				align: "center",
				formatter: (cell, row) => (
					<Button color="link" className="text-danger" title="Delete phase..." onClick={() => removePhase(row)}>
						{clearview.Icon.delete}
					</Button>
				),
				editable: false,
			},
			// {
			//     dataField: 'rowVersion',
			//     text: 'Move',
			//     align: 'center',
			//     formatter: (cell, row) => (<ButtonGroup hidden={false} >
			//         <Button color="link" className="text-info ml-1 mr-2" title="Move Up" onClick={() => onChange({"target": {"type": "moveUpPhase"}},row)}>{clearview.Icon.up}</Button>
			//         <Button color="link" className="text-info mr-1" title="Move Down" onClick={() => onChange({"target":{"type":"moveDownPhase"}},row)}>{clearview.Icon.down}</Button>
			//     </ButtonGroup>),
			//     editable: false
			// }
		]

		const nonEditableRows = isEditing ? [] : customer.phases.map(it => it.id)
		const columns = isEditing ? columnDefs.concat(editColumns) : columnDefs

		return (
			<ToolkitProvider keyField="id" data={_.sortBy(customer.phases, it => it.sequence)} columns={columns} search exportCSV>
				{props => (
					<React.Fragment>
						<Card>
							<CardHeader>
								<div className="d-flex justify-content-between align-items-center mb-2">
									<h4 className="p-0 m-0">Phases</h4>
									<div className="d-flex ml-auto">
										<CustomSearch className="mr-2" {...props.searchProps} />
										<CustomExportCSV {...props.csvProps}>Export</CustomExportCSV>
									</div>
								</div>
							</CardHeader>
							<CardBody>
								<BootstrapTable
									classes="table-responsive-lg table-striped"
									bordered={false}
									responsive
									hover
									cellEdit={cellEditFactory({
										mode: "click",
										blurToSave: true,
										afterSaveCell: (oldValue, newValue, row, column) => onChange({ target: { type: "changed" } }, row),
										nonEditableRows: () => nonEditableRows,
									})}
									{...props.baseProps}
								/>
							</CardBody>
							{isEditing && (
								<CardFooter className="h6 text-right">
									<Button outline color="primary" title="Add phase..." onClick={e => addPhase(customer)}>
										{clearview.Icon.add}
									</Button>
								</CardFooter>
							)}
						</Card>
					</React.Fragment>
				)}
			</ToolkitProvider>
		)
	}
}
