import React from "react"
import PropTypes from "prop-types"

import Query_ReplyForm from "./Query_ReplyForm"
import Query_CloseForm from "./Query_CloseForm"
import Query_ReOpenForm from "./Query_ReOpenForm"
import Stage_NewQueryForm from "./Stage_NewQueryForm"

class RepliesActions extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		query: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props

		this.handleSendClicked = this.handleSendClicked.bind(this)
		this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
	}

	handleSendClicked(e) {
		this.props.updateQuery({
			id: this.props.query.id,
			assignTo: this.props.query.assignedTo.username,
			periodEnd: this.props.periodEnd,
			stage: this.props.stage,
			subject: this.props.query.subject,
			title: this.props.query.title,
			comments: this.props.query.comments,
			isDraft: false,
		})
	}

	handleDeleteClicked(e) {
		this.props.deleteQuery({
			id: this.props.query.id,
			periodEnd: this.props.periodEnd,
			stage: this.props.stage,
		})
	}

	render() {
		const { periodEnd, stage, query } = this.props

		return (
			<React.Fragment>
				{!!(query.actions || []).includes("ReplyTo") && (
					<Query_ReplyForm target={`query_ReplyForm_${query.id}`} periodEnd={periodEnd} stage={stage} query={query} />
				)}
				{!!(query.actions || []).includes("Close") && (
					<Query_CloseForm target={`query_CloseForm_${query.id}`} periodEnd={periodEnd} stage={stage} query={query} />
				)}
				{!!(query.actions || []).includes("ReOpen") && (
					<Query_ReOpenForm target={`query_ReOpenForm_${query.id}`} periodEnd={periodEnd} stage={stage} query={query} />
				)}
				{(stage.actions || []).includes("Query") && <Stage_NewQueryForm periodEnd={periodEnd} stage={stage} initial={query} />}

				<div className="RepliesActions">
					<button
						hidden={!(query.actions || []).includes("ReplyTo")}
						className="text-primary tight"
						id={`query_ReplyForm_${query.id}`}
						title="Reply to this Query..."
					>
						Reply
					</button>
					<button
						hidden={!(query.actions || []).includes("Close")}
						className="text-primary tight"
						id={`query_CloseForm_${query.id}`}
						title="Close this Query..."
					>
						Close
					</button>
					<button
						hidden={!(query.actions || []).includes("ReOpen")}
						className="text-primary tight"
						id={`query_ReOpenForm_${query.id}`}
						title="Re-open this Query..."
					>
						Re-open
					</button>
					<button
						hidden={!(stage.actions || []).includes("Query")}
						className="text-primary tight"
						id={`stage${stage.id}_NewQueryForm${query.id}`}
						title="Copy this query..."
					>
						Copy
					</button>
				</div>
			</React.Fragment>
		)
	}
}

export { RepliesActions }
