import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Container } from "../../../../components"
import { S3Folder } from "../../components/s3/S3Folder"
import AreYouSure from "../../../components/AreYouSure"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"

class PeriodEndFolders extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired,
		doSetIsPublished: PropTypes.func.isRequired,
		requiredSubfolders: PropTypes.array,
	}

	constructor(props) {
		super(props)

		this.state = {}

		this.onPublish = this.onPublish.bind(this)
	}

	onPublish() {
		this.props.doSetIsPublished(this.props.periodEnd)
	}

	render() {
		const { periodEnd, user } = this.props

		const requiredSubfolders = clearview.Config(periodEnd).periodEndSubfolders

		return (
			<Container className="s3-folder-container">
				{periodEnd.actions.includes("SetIsPublished") && (
					<AreYouSure
						id="PeriodEndFolder_Accounts_Publish_AreYouSure"
						message={
							<span>
								<b>{periodEnd.isPublished ? "Hide" : "Publish"}</b> the <b>Accounts folder</b> for{" "}
								<b className="nowrap">{periodEnd.reference}</b>?
							</span>
						}
						action={() => this.onPublish()}
					/>
				)}
				<S3Folder
					folder={`/periodEnd/${periodEnd.id}`}
					requiredSubfolders={requiredSubfolders}
					label="Files"
					isReadOnly={periodEnd.status === "Closed"}
				></S3Folder>
				{user.isInHouse && (
					<S3Folder
						folder={`/periodEnd/${periodEnd.id}/inHouse`}
						label="In-House Files"
						title="Not visible to the Client"
						className="in-house"
						isReadOnly={periodEnd.status === "Closed"}
					></S3Folder>
				)}
				{(user.isInHouse || periodEnd.isPublished) && <AccountsFolder periodEnd={periodEnd} />}
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	doSetIsPublished: periodEnd =>
		dispatch({
			type: ACTIONS.PERIODEND_ISPUBLISHED_UPDATE,
			periodEnd: periodEnd,
		}),
})

PeriodEndFolders = connect(mapStateToProps, mapDispatchToProps)(PeriodEndFolders)
export { PeriodEndFolders }

export function AccountsFolder({ periodEnd }) {
	return (
		<S3Folder
			folder={`/periodEnd/${periodEnd.id}/accounts`}
			label={periodEnd.isPublished ? "Accounts" : "Accounts (Private)"}
			title={periodEnd.isPublished ? "Visible to the Client!" : "Not visible to the Client"}
			className={periodEnd.isPublished ? "published" : "in-house"}
			isReadOnly={periodEnd.status === "Closed"}
			actions={
				<span hidden={!periodEnd.actions.includes("SetIsPublished")}>
					<button
						className="tight"
						id="PeriodEndFolder_Accounts_Publish_AreYouSure"
						title={
							periodEnd.isPublished
								? "Published! Click to hide this folder from the Client"
								: "Private. Click to publish this folder to the Client"
						}
					>
						{clearview.Icon.Publish[periodEnd.isPublished]}
					</button>
				</span>
			}
		></S3Folder>
	)
}
