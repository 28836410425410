import React from "react"

import * as clearview from "../../../../components/@Clearview"

import { Badge } from "../../../../components"
import { PreFilterLink } from "../../../components/PreFilterLink"

export function PhasesPeriodEndRagBadge(rag, content, context, periodEnds, to, options = { className: "badge-pill" }) {
	var color = rag === "Total" ? "dark" : rag === "Info" ? "info" : undefined
	return (
		<PreFilterLink context={context} periodEnds={periodEnds} to={to} clear={false} className="text-decoration-none">
			<Badge color={color || clearview.PeriodEndRagColors[rag]} title={to} className={options.className}>
				{content || clearview.PeriodEndRagNames[rag]}
			</Badge>
		</PreFilterLink>
	)
}
