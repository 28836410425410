import React from "react"

import _ from "lodash"
import { Table } from "reactstrap"

export function SimpleTable({
	allKeys,
	columns,
	sort,
	groupBy = [],
	groupByLabels = {},
	summaryColumns = false,
	data,
	className = "table-striped",
	onAdd = false,
	onEdit = false,
	onDelete = false,
}) {
	const hasActionColumn = !!onAdd || !!onDelete

	if (summaryColumns === false) summaryColumns = groupBy.length ? _.difference(allKeys, groupBy) : []
	if (summaryColumns?.length && !groupBy.length) groupBy = _.difference(allKeys, summaryColumns)

	if (!allKeys) allKeys = _.keys(columns)
	const reportData = data && sort ? data.sort(sort) : data

	let numberOfGroups = 0
	const fnNewSubtotals = datum => {
		if (!datum) return {}
		numberOfGroups++
		return groupBy.reduce((prev, next) => {
			prev[next] = datum[next]
			return prev
		}, {})
	}

	const fnIsDifferentGroup =
		groupBy.length || summaryColumns.length
			? (a, b) =>
					groupBy.reduce((prev, next) => {
						if (isNothing(a)) return false
						if (prev) return true
						if (a[next] !== b[next]) return true
						return false
					}, false)
			: false

	const summaryLabelColumn = allKeys.reduce((prev, next, idx) => {
		if (prev.isSet) return prev
		if (!summaryColumns.includes(next)) return { colSpan: idx + 1, key: next }
		if (summaryColumns.includes(next) && prev.key) return { ...prev, isSet: true }
		return prev
	}, {})

	let prevDatum = null
	let subtotals = fnNewSubtotals(reportData[0])
	let totals = {}

	const fnGroupByLabels = {
		...{ "group-header": datum => groupBy.map(col => datum[col]?.toString()).join(", ") },
		...groupByLabels,
	}

	const DEFAULT_SUMMARY_LABELS = { "sub-total": "Subtotal:", "grand-total": "Total:" }

	// Sanitize the column definitions
	for (let key of allKeys) {
		const def = columns[key]
		def.map = def.map || (it => it?.toString())
		if (!def.mapSummary) {
			if (key === summaryLabelColumn.key) def.mapSummary = (sum, summary, idx, type) => <span>{DEFAULT_SUMMARY_LABELS[type]}</span>
			else def.mapSummary = def.map
		}
		// def.mapSummary1 = summaryLabelColumn[key] ? (sum, summary, idx, type) => fnSummaryLabels[type](summary) : def.mapSummary || def.map
	}

	const rowClassNames = (key, row, idx, type) => {
		const classNames = []
		if (columns[key]?.className) classNames.push(columns[key].className)
		if (columns[key]?.classNameFn) classNames.push(columns[key].classNameFn(row[key], row, idx, type))
		return classNames
	}

	const DetailRow = ({ datum, idx }) => {
		const canEdit = !!onEdit && !datum.isReadOnly

		const onClick = !!canEdit ? () => onEdit(datum) : () => {}
		const title = !!canEdit ? "Click to edit" : undefined

		const rowClassName = {
			false: "detail",
			true: "detail read-only",
		}

		return (
			<tr className={rowClassName[!!datum.isReadOnly]}>
				{allKeys.map((key, kIdx) => {
					const classNames = rowClassNames(key, datum, idx, "detail")
					if (!!canEdit) classNames.push("hover")

					return (
						<td key={`td_${idx}_${kIdx}`} title={title} className={classNames.join(" ")} onClick={onClick}>
							{columns[key]?.map(datum[key], datum, idx, "detail")}
						</td>
					)
				})}
				{hasActionColumn && (
					<td key={`td_${idx}_edit`}>
						{onDelete && !datum.isReadOnly && (
							<i className="fa fa-fw fa-remove btn btn-sm text-danger hover pl-0 pr-0" title="Delete row" onClick={e => onDelete(datum, idx)}></i>
						)}
						{/* {onEdit && <i className="fa fa-fw fa-pencil btn btn-sm text-primary hover pl-0 pr-0" title="Edit row" onClick={e => onEdit(datum)}></i>} */}
					</td>
				)}
			</tr>
		)
	}

	const HeaderRow = ({ datum, idx }) => {
		if (!fnGroupByLabels["group-header"](datum)) return null
		return (
			<tr className="group-header">
				<th key={`td_h_${idx}`} colSpan={allKeys.length}>
					{fnGroupByLabels["group-header"](datum)}
				</th>
				{hasActionColumn && (
					<th key={`th_${idx}_edit`}>
						{onAdd && <i className="fa fa-fw fa-plus btn btn-sm text-primary hover pl-0 pr-0" title="Add new item..." onClick={e => onAdd({})}></i>}
					</th>
				)}
			</tr>
		)
	}

	const SummaryRow = ({ summary, idx, type = "grand-total" }) => (
		<tr className={type}>
			{allKeys.map((key, kIdx) => {
				const isSummaryLabelColumn = key === summaryLabelColumn.key
				const colSpan = isSummaryLabelColumn ? summaryLabelColumn.colSpan : kIdx >= summaryLabelColumn.colSpan ? 1 : 0
				const classNames = rowClassNames(key, summary, idx, type)
				if (isSummaryLabelColumn) classNames.push(`${type}-label`)

				if (colSpan)
					return (
						<td key={`td_s_${idx}_${kIdx}`} colSpan={colSpan} className={classNames.join(" ")}>
							<span>{columns[key]?.mapSummary(summary[key], summary, idx, type)}</span>
						</td>
					)
				return undefined
			})}
			{hasActionColumn && (
				<td key={`td_s_${idx}_edit`}>
					{onAdd && (
						<i
							className="fa fa-fw fa-plus btn btn-sm text-primary hover pl-0 pr-0"
							title="Add new item..."
							onClick={e => onAdd({ ...summary })}
						></i>
					)}
				</td>
			)}
		</tr>
	)

	return (
		<Table className={className}>
			<thead>
				{allKeys?.length && (
					<tr>
						{allKeys.map((key, idx) => {
							const classNames = rowClassNames(key, {}, idx, "header")
							return (
								<th key={`th_${idx}`} className={classNames.join(" ")}>
									{columns[key].heading || _.startCase(key)}
								</th>
							)
						})}

						{hasActionColumn && (
							<th key="th_action">
								{onAdd && (
									<i
										className="fa fa-fw fa-plus btn btn-sm text-primary hover pl-0 pr-0"
										title="Add new item..."
										onClick={e => onAdd({})}
									></i>
								)}
							</th>
						)}
					</tr>
				)}
			</thead>
			{!!reportData?.length && (
				<tbody className="compact-rows">
					{reportData.flatMap((datum, idx) => {
						const rows = []
						if (fnIsDifferentGroup) {
							if (fnIsDifferentGroup(prevDatum, datum)) {
								if (!!summaryColumns.length) rows.push(<SummaryRow key={`s_${idx}`} type={"sub-total"} summary={subtotals} idx={idx} />)
								rows.push(<HeaderRow datum={datum} key={`h_${idx}`} />)
								subtotals = fnNewSubtotals(datum)
							} else if (!prevDatum) {
								rows.push(<HeaderRow datum={datum} key={`h_${idx}`} />)
							}
							for (let key of summaryColumns) {
								if (_.isNumber(datum[key])) {
									subtotals[key] = (subtotals[key] || 0) + (parseInt(datum[key]) || 0)
									totals[key] = (totals[key] || 0) + (parseInt(datum[key]) || 0)
								}
							}
						}
						prevDatum = datum
						rows.push(<DetailRow key={`d_${idx}`} datum={datum} idx={idx} />)
						return rows
					})}
					{!!fnIsDifferentGroup && numberOfGroups > 1 && !!summaryColumns.length && (
						<SummaryRow key="s_-1" type={"sub-total"} summary={subtotals} idx={-1} />
					)}
				</tbody>
			)}
			<tfoot>
				{!!reportData?.length && !!fnIsDifferentGroup && !!summaryColumns.length && (
					<SummaryRow key="g" type={"grand-total"} summary={totals} idx={-2} />
				)}
			</tfoot>
		</Table>
	)
}

function isNothing(value) {
	return [undefined, null, NaN].includes(value)
}
