import React from "react"
import PropTypes from "prop-types"
import { Prompt } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"

import { Spinner } from "../../../components/Spinner"

import { Container, Card, CardBody } from "../../../../components"

import { HeaderMain } from "../../../components/HeaderMain"

export default class Preferences extends React.Component {
	static propTypes = {
		business: PropTypes.object,
		title: PropTypes.string.isRequired,
		narrative: PropTypes.string.isRequired,
		isBusy: PropTypes.bool.isRequired,
		isChanged: PropTypes.bool.isRequired,
		children: PropTypes.node.isRequired,
		commands: PropTypes.arrayOf(PropTypes.node).isRequired,
	}

	render() {
		const { business, title, narrative, isBusy, isChanged, children, commands } = this.props

		return (
			<Container className={isBusy ? "isBusy" : ""}>
				{isBusy && <Spinner key="spinner"></Spinner>}
				<Prompt
					when={isChanged}
					message={() => "You have made changes to these Preferences.\r\rAre you sure you want to leave this page and discard your changes?"}
				/>
				<HeaderMain
					superTitle={
						<span className="ml-1">
							{!business ? "" : business?.type === "Customer" ? `${business?.name}` : `${business?.reference} ${business?.name}`}
						</span>
					}
					title={
						<div className="d-flex-row justify-content-between align-items-center">
							<span className="text-primary flex-1">
								{clearview.Icon.settings}
								{title}
							</span>
							<div className="d-flex-row justify-content-end">{!isBusy && commands}</div>
						</div>
					}
					subTitle={<small className="mt-0">{narrative}</small>}
					className="mb-4 mt-0"
				/>
				<Card>
					<CardBody>{children}</CardBody>
				</Card>
			</Container>
		)
	}
}
