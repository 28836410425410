import _ from "lodash"

import { stringsMatch, deClutter, VENDOR_NAME_NOISE_WORDS } from "clearview-aws-common"

export default function AutoMatch(vouching, vendorAliases) {
	let autoMatchCount = 0

	if (vouching.ledger.length && vouching.expenses.length) {
		const availableLedgerItems = vouching.ledger.filter(it => !it.status)

		for (var ledgerItem of availableLedgerItems) {
			const similarExpenses = vouching.expenses.filter(
				expense =>
					!expense.status &&
					expense.total == ledgerItem.total &&
					refsMatch(expense.ref, ledgerItem.ref) &&
					vendorNamesMatch(expense.vendorName, ledgerItem.vendorName, vendorAliases)
			)

			const expense = similarExpenses.find(expense => expense.ref == ledgerItem.ref) || similarExpenses.find(it => true)
			if (expense) {
				ledgerItem.status = "A"
				ledgerItem.expenseId = expense.id
				ledgerItem.expense = expense
				expense.status = "A"
				expense.ledgerItemId = ledgerItem.id
				expense.ledgerItem = ledgerItem

				autoMatchCount++
			}
		}
	}

	const autoMatchMultiCount = AutoMatchMulti(vouching, vendorAliases)

	return { autoMatchCount: autoMatchCount + autoMatchMultiCount, vouching }
}

function AutoMatchMulti(vouching, vendorAliases) {
	let autoMatchMultiCount = 0

	if (vouching.ledger.length && vouching.expenses.length) {
		const multiLedgerItems = _.groupBy(
			vouching.ledger.filter(it => !it.status),
			it => `${it.vendorName}|${it.ref}`
		)

		for (var key of _.keys(multiLedgerItems)) {
			const [ledgerItemsVendorName, ledgerItemsRef] = key.split("|", 2)
			const ledgerItemsTotal = _.sumBy(multiLedgerItems[key], it => it.total)

			const similarExpenses = vouching.expenses.filter(
				expense =>
					!expense.status &&
					expense.total == ledgerItemsTotal &&
					refsMatch(expense.ref, ledgerItemsRef) &&
					vendorNamesMatch(expense.vendorName, ledgerItemsVendorName, vendorAliases)
			)

			const expense = similarExpenses.find(expense => expense.ref == ledgerItemsRef) || similarExpenses.find(it => true)
			if (expense) {
				for (let ledgerItem of multiLedgerItems[key]) {
					ledgerItem.status = "A"
					ledgerItem.expenseId = expense.id
					ledgerItem.expense = expense
					expense.status = "A"
					expense.ledgerItemId = ledgerItem.id
					expense.ledgerItem = ledgerItem

					autoMatchMultiCount++
				}
			}
		}
	}

	return autoMatchMultiCount
}

function refsMatch(expenseRef, ledgerItemRef) {
	let simpleMatch = stringsMatch(expenseRef, ledgerItemRef)

	while (!simpleMatch && expenseRef.startsWith("0")) {
		expenseRef = expenseRef.substring(1)
		simpleMatch = stringsMatch(expenseRef, ledgerItemRef)
	}
	return simpleMatch
}

export function vendorNamesMatch(expenseVendorName, ledgerItemVendorName, vendorAliases) {
	const expenseVendorNameDeCluttered = deClutter(expenseVendorName, VENDOR_NAME_NOISE_WORDS)
	const ledgerItemVendorNameDeCluttered = deClutter(ledgerItemVendorName, VENDOR_NAME_NOISE_WORDS)

	return (
		stringsMatch(expenseVendorNameDeCluttered, ledgerItemVendorNameDeCluttered) ||
		_.find(vendorAliases[ledgerItemVendorNameDeCluttered] || [], alias => stringsMatch(expenseVendorNameDeCluttered, alias))
	)
}

export function addVendorNameAlias(expenseVendorName, ledgerItemVendorName, vendorAliases) {
	const expenseVendorNameDeCluttered = deClutter(expenseVendorName, VENDOR_NAME_NOISE_WORDS)
	const ledgerItemVendorNameDeCluttered = deClutter(ledgerItemVendorName, VENDOR_NAME_NOISE_WORDS)

	if (!vendorAliases[ledgerItemVendorNameDeCluttered]) {
		vendorAliases[ledgerItemVendorNameDeCluttered] = []
	}

	vendorAliases[ledgerItemVendorNameDeCluttered].push(expenseVendorNameDeCluttered)

	return { expenseVendorName: expenseVendorNameDeCluttered, ledgerItemVendorName: ledgerItemVendorNameDeCluttered }
}
