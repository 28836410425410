import * as ACTIONS from "../actions"

const toastReducer = (state, action) => {
	if (!state) state = {}

	//Specific toasts
	switch (action.type) {
		case ACTIONS.REMOVE_TOAST:
			return {
				...state,
				toast: undefined,
			}

		case ACTIONS.SESSION_TIMEOUT:
			return {
				...state,
				toast: {
					category: "TIMEOUT",
					title: "Session Timeout",
					message: "Your Clearview session has been idle for too long. You must log in again before you can proceed.",
				},
			}

		case ACTIONS.PERIODEND_DELETED:
			return {
				...state,
				toast: {
					category: "SUCCESS",
					title: "Deleted",
					message: "This Period End has been deleted.",
				},
			}

		case ACTIONS.PERIODEND_CREATED:
			return {
				...state,
				toast: {
					category: "SUCCESS",
					title: "Period End Created",
					message: `Period End ${action.periodEnd.reference} has been created.`,
				},
			}

		case ACTIONS.REPORTS_REQUESTED:
			return {
				...state,
				toast: {
					category: "SUCCESS",
					title: "Report Request",
					message: action.message,
				},
			}

		case ACTIONS.REPORTS_REQUEST_FAILED:
			return {
				...state,
				toast: {
					category: "ERROR",
					title: "Report Request",
					message: action.message,
				},
			}

		default:
			var typeParts = action.type.split(":", 2)
			if (["SUCCESS", "ERROR", "WARNING", "INFO", "TIMEOUT"].includes(typeParts[0])) {
				return {
					...state,
					toast: {
						category: typeParts[0],
						title: action.title || action.id || typeParts[0],
						message: action.message || typeParts[1],
					},
				}
			}

			return state
	}
}

export default toastReducer
