import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Query_EditForm from "./Query_EditForm"
import AreYouSure from "../../../components/AreYouSure"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class QueryActions extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		query: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props

		this.handleSendClicked = this.handleSendClicked.bind(this)
		this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
	}

	handleSendClicked(e) {
		this.props.updateQuery({
			id: this.props.query.id,
			assignTo: this.props.query.assignedTo.username,
			periodEnd: this.props.periodEnd,
			stage: this.props.stage,
			subject: this.props.query.subject,
			title: this.props.query.title,
			comments: this.props.query.comments,
			isDraft: false,
		})
	}

	handleDeleteClicked(e) {
		this.props.deleteQuery({
			id: this.props.query.id,
			periodEnd: this.props.periodEnd,
			stage: this.props.stage,
		})
	}

	render() {
		const { periodEnd, stage, query } = this.props

		return (
			<React.Fragment>
				<Query_EditForm target={`query_EditForm_${query.id}`} periodEnd={periodEnd} stage={stage} query={query} />

				<div className="QueryActions">
					<button
						hidden={!(query.actions || []).includes("Send")}
						id={query.id}
						className="mr-3 text-secondary tight"
						onClick={e => this.handleSendClicked(e)}
						title="Send query"
					>
						{clearview.Icon.send}
					</button>
					<button
						hidden={!(query.actions || []).includes("Update")}
						id={`query_EditForm_${query.id}`}
						className="text-secondary tight"
						title="Edit query..."
					>
						{clearview.Icon.edit}
					</button>
					<button
						hidden={!(query.actions || []).includes("Delete")}
						id={`query_Delete_${query.id}`}
						className="text-secondary tight"
						title="Delete query..."
					>
						{clearview.Icon.delete}
					</button>
					{(query.actions || []).includes("Delete") && (
						<AreYouSure
							id={`query_Delete_${query.id}`}
							message={
								<span>
									Permanently delete Query <b className="nowrap">{query.reference}</b> from{" "}
									<b className="nowrap">{stage.templateStage.name}</b>?
								</span>
							}
							action={() => this.handleDeleteClicked()}
						/>
					)}
				</div>
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	updateQuery: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE_QUERY,
			...payload,
		}),

	deleteQuery: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_DELETE_QUERY,
			...payload,
		}),
})

QueryActions = connect(null, mapDispatchToProps)(QueryActions)

export { QueryActions }
