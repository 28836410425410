import React from "react"

import { Media, Avatar } from "../../../../components"

import { STATUS_TITLE, STATUS_COLOR, STATUS_ICON } from "./VouchingTheme"

export const LedgerItemAvatar = props => (
	<Media left middle className={["A", "M"].includes(props.ledgerItem.status) ? "align-self-center hover-alias" : "align-self-center"}>
		<Avatar.Font
			bgColor={STATUS_COLOR[props.ledgerItem.status]}
			title={STATUS_TITLE[props.ledgerItem.status]}
			size="md"
			className="d-block avatar-with-text mr-1"
		>
			{STATUS_ICON[props.ledgerItem.status]}
		</Avatar.Font>
	</Media>
)
