import _ from "lodash"

export const User = {
	UnauthenticatedUser: {
		brand: { reference: "Clearview", name: "Clearview" },
		name: "Unauthenticated",
		username: "unauthenticated",
		initials: "...",
		teams: [],
		favourites: [],
		groups: [],
		id: 0,
		isInHouse: false,
	},

	IsAdmin(user) {
		if (!user || !user.teams) return false
		return !!_.find(user.teams, it => it.type === "Users" && (it.role === "Admin" || it.role === "System"))
	},
}
