import React from 'react';


import { 
    Row,
    Col,
    Card,
    CardTitle,
    CardBody
} from '../../../../components';

import {
    TimelineMini
} from "../../../components/Timeline/TimelineMini"

import * as clearview from '../../../../components/@Clearview';



export const PeriodEndColorCoding = () => (<React.Fragment>
    <Row>

        <Col lg={ 4 }>
            <Card className="mb-3">
                <CardBody>
                    <CardTitle tag="h6">
                        Status
                    </CardTitle>
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndStatusColors.NotYetOpen}`}
                        badgeTitle={clearview.PeriodEndStatusNames.NotYetOpen}
                            badgeColor={clearview.PeriodEndStatusColors.NotYetOpen}
                        phrase="Period End has not yet been opened"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndStatusColors.Open}`}
                        badgeTitle={clearview.PeriodEndStatusNames.Open}
                            badgeColor={clearview.PeriodEndStatusColors.Open}
                        phrase="Period end is open"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndStatusColors.Closed}`}
                        badgeTitle={clearview.PeriodEndStatusNames.Closed}
                            badgeColor={clearview.PeriodEndStatusColors.Closed}
                        phrase="Period end is closed"
                        date={null}
                    />
                </CardBody>
            </Card>
        </Col>                

        <Col lg={ 4 }>
            <Card className="mb-3">
                <CardBody>
                    <CardTitle tag="h6">
                    RAG
                    </CardTitle>
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndRagColors.NotDue}`}
                        badgeTitle={clearview.PeriodEndRagNames.NotDue}
                            badgeColor={clearview.PeriodEndRagColors.NotDue}
                        phrase="Period End Not Due"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndRagColors.Imminent}`}
                        badgeTitle={clearview.PeriodEndRagNames.Imminent}
                            badgeColor={clearview.PeriodEndRagColors.Imminent}
                        phrase="Period End Imminent"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndRagColors.OnHold}`}
                        badgeTitle={clearview.PeriodEndRagNames.OnHold}
                            badgeColor={clearview.PeriodEndRagColors.OnHold}
                        phrase="Period End On Hold"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndRagColors.OnTime}`}
                        badgeTitle={clearview.PeriodEndRagNames.OnTime}
                            badgeColor={clearview.PeriodEndRagColors.OnTime}
                        phrase="Period End On Time"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndRagColors.Warning}`}
                        badgeTitle={clearview.PeriodEndRagNames.Warning}
                            badgeColor={clearview.PeriodEndRagColors.Warning}
                        phrase="Period End Warning"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndRagColors.BehindSchedule}`}
                        badgeTitle={clearview.PeriodEndRagNames.BehindSchedule}
                            badgeColor={clearview.PeriodEndRagColors.BehindSchedule}
                        phrase="Period End Behind Schedule"
                        date={null}
                    />
                    <TimelineMini 
                        icon={clearview.IconName.periodEnd}
                            iconClassName={`text-${clearview.PeriodEndRagColors.Overdue}`}
                        badgeTitle={clearview.PeriodEndRagNames.Overdue}
                            badgeColor={clearview.PeriodEndRagColors.Overdue}
                        phrase="Period End Overdue"
                        date={null}
                    />
                </CardBody>
            </Card>
        </Col>   


    </Row>
</React.Fragment>)
