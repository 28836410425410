import React from "react"

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "../../components"

export function InputYesNoCancel({ config, isOpen, actions, onCancel = false }) {
	config = {
		width: 600,
		title: "Yes, No, Cancel",
		message: "Is this OK?",
		buttonLabels: { true: "Yes", false: "No", undefined: "Cancel" },
		...config,
	}

	return (
		<Modal isOpen={isOpen} autoFocus={true} centered={true} style={{ minWidth: config.width }}>
			<ModalHeader tag="div">
				<div className="d-flex">
					<h1 className="text-primary mr-2">
						<i className="fa fa-fw fa-question-circle-o"></i> {config.title}
					</h1>
				</div>
			</ModalHeader>

			<ModalBody>{config.message}</ModalBody>

			<ModalFooter>
				<Button hidden={!onCancel} color="secondary" onClick={onCancel}>
					{config.buttonLabels[undefined]}
				</Button>

				{actions &&
					Object.keys(actions).map(key => (
						<Button key={key} color="primary" name={key} onClick={actions[key]}>
							{key}
						</Button>
					))}
			</ModalFooter>
		</Modal>
	)
}
