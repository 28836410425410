import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Typeahead } from "react-bootstrap-typeahead"
import _ from "lodash"
import Dropdown from "react-bootstrap/Dropdown"

import { FormGroup, Input, Button, CustomInput, InputGroup, InputGroupAddon, Label, ExtendedDropdown, Badge } from "../../../components"
import { PreFilterLink } from "../../components/PreFilterLink"

import DateRangePicker from "@wojtekmaj/react-daterange-picker"

import * as clearview from "../../../components/@Clearview"
import { Consumer } from "../../../layout/context"
import * as ACTIONS from "../../../store/actions"

export const LookupConstants = {
	periodEndStatuses: Object.keys(clearview.PeriodEndStatusNames).map(key => ({ value: key, _title: clearview.PeriodEndStatusNames[key] })),
	periodEndRags: Object.keys(clearview.PeriodEndRagNames).map(key => ({ value: key, _title: clearview.PeriodEndRagNames[key] })),
	stageStatuses: Object.keys(clearview.StageStatusNames).map(key => ({ value: key, _title: clearview.StageStatusNames[key] })),
	stageRags: Object.keys(clearview.StageRagNames).map(key => ({ value: key, _title: clearview.StageRagNames[key] })),
}

class PeriodEndFilter extends React.Component {
	static propTypes = {
		periodEnds: PropTypes.array.isRequired,
		className: PropTypes.string,
		markAllRead: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.props = props
	}

	render() {
		const { periodEnds } = this.props

		const clients = _.sortBy(
			_.uniqBy(
				periodEnds
					.map(it => it.property.parent)
					.concat(periodEnds.map(it => it.property.parent.parent))
					.filter(it => it),
				it => it.id
			).map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
			it => it._title
		)
		const properties = _.sortBy(
			_.uniqBy(
				periodEnds.map(it => it.property).filter(it => it),
				it => it.id
			).map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
			it => it._title
		)
		const templateFamilies = _.sortBy(
			_.uniq(periodEnds.map(it => it.template.family).filter(it => it)).map(it => ({ value: it, _title: `${it}` })),
			it => it._title
		)
		const templates = _.sortBy(
			_.uniqBy(
				periodEnds.map(it => it.template).filter(it => it),
				it => it.id
			).map(it => ({ ...it, _title: `${it.reference}: ${it.name}` })),
			it => it.reference
		)
		const phaseNames = _.sortBy(
			_.uniqBy(
				periodEnds
					.filter(it => it.currentStage && it.currentStage.templateStage.phase)
					.map(pe => ({ value: pe.currentStage.templateStage.phase.name, _title: pe.currentStage.templateStage.phase.name })),
				it => it.value
			),
			it => it._title
		)
		const stageNames = _.sortBy(
			_.uniqBy(
				periodEnds.filter(it => it.currentStage).map(pe => ({ value: pe.currentStage.templateStage.name, _title: pe.currentStage.templateStage.name })),
				it => it.value
			),
			it => it._title
		)
		const inHouseTeamMembers = _.sortBy(
			_.uniqBy(
				_.flatMap(periodEnds, it => it.property.actors.inHouse),
				"username"
			),
			it => it.Name
		)
		const clientTeamMembers = _.sortBy(
			_.uniqBy(
				_.flatMap(periodEnds, it => it.property.actors.client),
				"username"
			),
			it => it.Name
		)

		return (
			<Consumer>
				{context => {
					const objFilters = context.parseObjectFilters()

					return (
						<InputGroup className={this.props.className} size="sm" style={{ width: 525 }}>
							<InputGroupAddon addonType="prepend">
								<Dropdown className="align-self-center ml-auto" direction="left">
									<Dropdown.Toggle
										color="link"
										title="Period End Filter..."
										outline
										caret
										variant="none"
										className="btn-outline-secondary"
										style={{ paddingTop: 2, paddingBottom: 2 }}
									>
										{context.criteriaCount() === 0 && clearview.Icon.filter}
										{context.criteriaCount() !== 0 && <span className="text-primary">{clearview.Icon.filter}</span>}
									</Dropdown.Toggle>
									<ExtendedDropdown>
										<ExtendedDropdown.Section className="d-flex justify-content-between align-items-center">
											<FormGroup row>
												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.Business.Client}</InputGroupAddon>
													<Typeahead
														id="Client"
														labelKey="_title"
														options={clients}
														placeholder="Filter by client/managing agent/landlord..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.client}
														onChange={e => context.handleChange(e, "client")}
														defaultSelected={context.state.filter.client ? [context.state.filter.client] : []}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.Business.InHouseUser}</InputGroupAddon>
													<Typeahead
														id="InHouseTeamMember"
														labelKey="name"
														options={inHouseTeamMembers}
														placeholder="Filter by In-House team member..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.inHouseTeamMember}
														onChange={e => context.handleChange(e, "inHouseTeamMember")}
														defaultSelected={context.state.filter.inHouseTeamMember ? [context.state.filter.inHouseTeamMember] : []}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.Business.ClientUser}</InputGroupAddon>
													<Typeahead
														id="ClientTeamMember"
														labelKey="name"
														options={clientTeamMembers}
														placeholder="Filter by Client team member..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.clientTeamMember}
														onChange={e => context.handleChange(e, "clientTeamMember")}
														defaultSelected={context.state.filter.clientTeamMember ? [context.state.filter.clientTeamMember] : []}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.Business.Property}</InputGroupAddon>
													<Typeahead
														id="Property"
														labelKey="_title"
														options={properties}
														placeholder="Filter by property..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.property}
														onChange={e => context.handleChange(e, "property")}
														defaultSelected={context.state.filter.property ? [context.state.filter.property] : []}
													/>
												</InputGroup>

												{templateFamilies.length && (
													<InputGroup>
														<InputGroupAddon addonType="prepend">{clearview.Icon.templateFamily}</InputGroupAddon>
														<Typeahead
															id="TemplateFamily"
															labelKey="_title"
															options={templateFamilies}
															placeholder="Filter by template family..."
															allowNew={false}
															caseSensitive={false}
															clearButton={true}
															highlightOnlyResult={true}
															value={context.state.filter.templateFamily}
															onChange={e => context.handleChange(e, "templateFamily")}
															defaultSelected={context.state.filter.templateFamily ? [context.state.filter.templateFamily] : []}
														/>
													</InputGroup>
												)}

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.template}</InputGroupAddon>
													<Typeahead
														id="Template"
														labelKey="_title"
														options={templates}
														placeholder="Filter by template..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.template}
														onChange={e => context.handleChange(e, "template")}
														defaultSelected={context.state.filter.template ? [context.state.filter.template] : []}
													/>
												</InputGroup>

												{phaseNames.length > 0 && (
													<InputGroup>
														<InputGroupAddon addonType="prepend">{clearview.Icon.phase}</InputGroupAddon>
														<Typeahead
															id="PhaseName"
															labelKey="_title"
															options={phaseNames}
															placeholder="Filter by phase..."
															allowNew={false}
															caseSensitive={false}
															clearButton={true}
															highlightOnlyResult={true}
															value={context.state.filter.phaseName}
															onChange={e => context.handleChange(e, "phaseName")}
															defaultSelected={context.state.filter.phaseName ? [context.state.filter.phaseName] : []}
														/>
													</InputGroup>
												)}

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.stage}</InputGroupAddon>
													<Typeahead
														id="StageName"
														labelKey="_title"
														options={stageNames}
														placeholder="Filter by stage..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.stageName}
														onChange={e => context.handleChange(e, "stageName")}
														defaultSelected={context.state.filter.stageName ? [context.state.filter.stageName] : []}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndStatus}</InputGroupAddon>
													<Typeahead
														id="PeriodEndStatus"
														labelKey="_title"
														options={LookupConstants.periodEndStatuses}
														placeholder="Filter by period end status..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.periodEndStatus}
														onChange={e => context.handleChange(e, "periodEndStatus")}
														defaultSelected={context.state.filter.periodEndStatus ? [context.state.filter.periodEndStatus] : []}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndRag}</InputGroupAddon>
													<Typeahead
														id="PeriodEndRag"
														labelKey="_title"
														options={LookupConstants.periodEndRags}
														placeholder="Filter by period end RAG..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.periodEndRag}
														onChange={e => context.handleChange(e, "periodEndRag")}
														defaultSelected={context.state.filter.periodEndRag ? [context.state.filter.periodEndRag] : []}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.stageStatus}</InputGroupAddon>
													<Typeahead
														id="StageStatus"
														labelKey="_title"
														options={LookupConstants.stageStatuses}
														placeholder="Filter by stage status..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.stageStatus}
														onChange={e => context.handleChange(e, "stageStatus")}
														defaultSelected={context.state.filter.stageStatus ? [context.state.filter.stageStatus] : []}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.stageRag}</InputGroupAddon>
													<Typeahead
														id="stageRag"
														labelKey="_title"
														options={LookupConstants.stageRags}
														placeholder="Filter by stage RAG..."
														allowNew={false}
														caseSensitive={false}
														clearButton={true}
														highlightOnlyResult={true}
														value={context.state.filter.stageRag}
														onChange={e => context.handleChange(e, "stageRag")}
														defaultSelected={context.state.filter.stageRag ? [context.state.filter.stageRag] : []}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.Unread[true]}</InputGroupAddon>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="IsUnread2"
														name="IsUnread"
														label="Unread"
														inline
														value="unread"
														defaultChecked={context.state.filter.isUnread === "unread"}
														onChange={e => context.handleRadioChange(e, "isUnread")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="IsUnread1"
														name="IsUnread"
														label="Read"
														inline
														value="read"
														defaultChecked={context.state.filter.isUnread === "read"}
														onChange={e => context.handleRadioChange(e, "isUnread")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="IsUnread0"
														name="IsUnread"
														label="n/a"
														inline
														value="undefined"
														defaultChecked={!["unread", "read"].includes(context.state.filter.isUnread)}
														onChange={e => context.handleRadioChange(e, "isUnread")}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.Alert[true]}</InputGroupAddon>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="IsAlert2"
														name="IsAlert"
														label="Alert"
														inline
														value="alert"
														defaultChecked={context.state.filter.isAlert === "alert"}
														onChange={e => context.handleRadioChange(e, "isAlert")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="IsAlert1"
														name="IsAlert"
														label="Priority"
														inline
														value="priority"
														defaultChecked={context.state.filter.isAlert === "priority"}
														onChange={e => context.handleRadioChange(e, "isAlert")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="IsAlert0"
														name="IsAlert"
														label="n/a"
														inline
														value="undefined"
														defaultChecked={!["alert", "priority"].includes(context.state.filter.isAlert)}
														onChange={e => context.handleRadioChange(e, "isAlert")}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.query}</InputGroupAddon>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="HasOpenQueries2"
														name="HasOpenQueries"
														label="Open Queries"
														inline
														value="true"
														defaultChecked={context.state.filter.hasOpenQueries === "true"}
														onChange={e => context.handleRadioChange(e, "hasOpenQueries")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="HasOpenQueries1"
														name="HasOpenQueries"
														label="None"
														inline
														value="false"
														defaultChecked={context.state.filter.hasOpenQueries === "false"}
														onChange={e => context.handleRadioChange(e, "hasOpenQueries")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="HasOpenQueries0"
														name="HasOpenQueries"
														label="n/a"
														inline
														value="undefined"
														defaultChecked={!["true", "false"].includes(context.state.filter.hasOpenQueries)}
														onChange={e => context.handleRadioChange(e, "hasOpenQueries")}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.inHouseOrClient}</InputGroupAddon>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="InHouse2"
														name="InHouse"
														label="In House"
														inline
														value="InHouse"
														defaultChecked={context.state.filter.inHouse === "InHouse"}
														onChange={e => context.handleRadioChange(e, "inHouse")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="InHouse1"
														name="InHouse"
														label="Client"
														inline
														value="WithClient"
														defaultChecked={context.state.filter.inHouse === "WithClient"}
														onChange={e => context.handleRadioChange(e, "inHouse")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="InHouse0"
														name="InHouse"
														label="n/a"
														inline
														value="undefined"
														defaultChecked={!["InHouse", "WithClient"].includes(context.state.filter.inHouse)}
														onChange={e => context.handleRadioChange(e, "inHouse")}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndRag}</InputGroupAddon>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="PeriodEndRagOk2"
														name="PeriodEndRagOk"
														label="Current"
														inline
														value="true"
														defaultChecked={context.state.filter.periodEndRagOk === "true"}
														onChange={e => context.handleRadioChange(e, "periodEndRagOk")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="PeriodEndRagOk1"
														name="PeriodEndRagOk"
														label="Overdue"
														inline
														value="false"
														defaultChecked={context.state.filter.periodEndRagOk === "false"}
														onChange={e => context.handleRadioChange(e, "periodEndRagOk")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="PeriodEndRagOk0"
														name="PeriodEndRagOk"
														label="n/a"
														inline
														value="undefined"
														defaultChecked={!["true", "false"].includes(context.state.filter.periodEndRagOk)}
														onChange={e => context.handleRadioChange(e, "periodEndRagOk")}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.stageRag}</InputGroupAddon>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="StageRagOk2"
														name="StageRagOk"
														label="Current"
														inline
														value="true"
														defaultChecked={context.state.filter.stageRagOk === "true"}
														onChange={e => context.handleRadioChange(e, "stageRagOk")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="StageRagOk1"
														name="StageRagOk"
														label="Overdue"
														inline
														value="false"
														defaultChecked={context.state.filter.stageRagOk === "false"}
														onChange={e => context.handleRadioChange(e, "stageRagOk")}
													/>
													<CustomInput
														className="ml-2 mt-2 mb-2"
														type="radio"
														id="StageRagOk0"
														name="StageRagOk"
														label="n/a"
														inline
														value="undefined"
														defaultChecked={!["true", "false"].includes(context.state.filter.stageRagOk)}
														onChange={e => context.handleRadioChange(e, "stageRagOk")}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.end}</InputGroupAddon>
													<Label style={{ marginTop: "0.5rem", width: "3rem", paddingLeft: "0.5rem" }}>Ends </Label>
													<DateRangePicker
														value={context.state.filter.endDate}
														onChange={v => context.handleDateRangeChange(v, "endDate")}
														rangeDivider="to "
														showLeadingZeros={false}
													/>
												</InputGroup>

												<InputGroup>
													<InputGroupAddon addonType="prepend">{clearview.Icon.due}</InputGroupAddon>
													<Label style={{ marginTop: "0.5rem", width: "3rem", paddingLeft: "0.5rem" }}>Due </Label>
													<DateRangePicker
														value={context.state.filter.dueDate}
														onChange={v => context.handleDateRangeChange(v, "dueDate")}
														rangeDivider="to "
														showLeadingZeros={false}
													/>
												</InputGroup>

												{(context.state.filter.yearMonth ||
													context.state.filter.clientInHouseManagerName ||
													context.state.filter.inHouseManagerName ||
													objFilters.appointees) && (
													<InputGroup>
														<InputGroupAddon addonType="prepend" title="Other Filters">
															{clearview.Icon.otherFilter}
														</InputGroupAddon>
														{context.state.filter.yearMonth && (
															<CustomInput
																className="ml-2 mt-2 mb-2"
																type="checkbox"
																id="yearMonth"
																name="yearMonth"
																label={`Month: ${context.state.filter.yearMonth}`}
																inline
																defaultChecked={"true"}
																onChange={e => context.handleRemoveOtherFilter(e)}
															/>
														)}
														{context.state.filter.clientInHouseManagerName && (
															<CustomInput
																className="ml-2 mt-2 mb-2"
																type="checkbox"
																id="clientInHouseManagerName"
																name="clientInHouseManagerName"
																label={`Client Manager: ${context.state.filter.clientInHouseManagerName}`}
																inline
																defaultChecked={"true"}
																onChange={e => context.handleRemoveOtherFilter(e)}
															/>
														)}
														{context.state.filter.inHouseManagerName && (
															<CustomInput
																className="ml-2 mt-2 mb-2"
																type="checkbox"
																id="inHouseManagerName"
																name="inHouseManagerName"
																label={`Manager: ${context.state.filter.inHouseManagerName}`}
																inline
																defaultChecked={"true"}
																onChange={e => context.handleRemoveOtherFilter(e)}
															/>
														)}
														{objFilters?.appointees && (
															<CustomInput
																className="ml-2 mt-2 mb-2"
																type="checkbox"
																id="appointees"
																name="appointees"
																label={`${objFilters?.appointees.teamName}: ${objFilters?.appointees.value}`}
																inline
																defaultChecked={"true"}
																onChange={e => context.handleRemoveOtherFilter(e)}
															/>
														)}
													</InputGroup>
												)}
											</FormGroup>
										</ExtendedDropdown.Section>
									</ExtendedDropdown>
								</Dropdown>
							</InputGroupAddon>
							<Input
								key={clearview.GenerateKey()}
								disabled
								value={`${context.filtered(this.props.periodEnds).length} of ${this.props.periodEnds.length}`}
								className="bg-white"
								style={{ width: 60 }}
							/>

							<InputGroupAddon addonType="append" title="Unread Period Ends">
								<Button
									outline
									onClick={() => context.clearFilters("PeriodEndUnread")}
									style={{ paddingTop: 1, paddingBottom: 0, lineHeight: 1, fontSize: "1.1em" }}
								>
									<Badge style={{ backgroundColor: clearview.PastelColorValues.Infoish }} pill>
										{this.props.periodEnds.filter(it => it.isUnread).length}
									</Badge>
								</Button>
							</InputGroupAddon>

							<InputGroupAddon addonType="append" title="All Open Period Ends">
								<Button
									outline
									onClick={() => context.clearFilters("PeriodEndFilterStatusOpen")}
									style={{ paddingTop: 3, paddingBottom: 0, paddingLeft: 3, paddingRight: 3 }}
								>
									{clearview.Icon.periodEndFilterStatusOpen}
								</Button>
							</InputGroupAddon>
							<InputGroupAddon addonType="append" title="Clear all filters">
								<PreFilterLink
									key="clear-all-filters"
									context={context}
									periodEnds={this.props.periodEnds}
									to={window.location.pathname}
									clear={true}
									className="btn btn-outline-secondary text-decoration-none"
								>
									<i className="fa fa-fw fa-times"></i>
								</PreFilterLink>
							</InputGroupAddon>
							<InputGroupAddon addonType="append" title="Mark all Period Ends Read">
								<Button outline onClick={this.props.markAllRead}>
									{clearview.Icon.Unread[null]}
								</Button>
							</InputGroupAddon>
						</InputGroup>
					)
				}}
			</Consumer>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	markAllRead: () => dispatch({ type: ACTIONS.PERIODEND_ALL_READ_UPDATE }),
})

PeriodEndFilter = connect(null, mapDispatchToProps)(PeriodEndFilter)
export { PeriodEndFilter }
