import React from "react"
import PropTypes from "prop-types"

import { Form, Button, ModalHeader, ModalBody, ModalFooter } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"
import ExcelUpload from "./ExcelUpload"

export default class WorkflowExcelForm_Modal extends React.Component {
	static propTypes = {
		title: PropTypes.element.isRequired,
		narrative: PropTypes.element,
		sheetNames: PropTypes.array,
		onUploaded: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		sampleFile: PropTypes.string,
	}

	render() {
		return (
			<Form>
				<ModalHeader tag="div">
					<div className="d-flex">
						<h1 className="text-primary mr-2">{clearview.Icon.upload}</h1>
						<h6 className="d-flex" style={{ flexDirection: "column" }}>
							{this.props.title}
							{this.props.narrative}
							{this.props.sampleFile && (
								<a href={this.props.sampleFile} className="d-flex clickable" style={{ flexDirection: "row", alignItems: "center" }}>
									<div>Download Sample File</div>
									<div className="text-primary fa-2x">{clearview.Icon.MimeType["application/vnd.ms-excel"]}</div>
								</a>
							)}
						</h6>
					</div>
				</ModalHeader>

				<ModalBody>
					<div className="file-upload" style={{ width: 300, height: 300, marginLeft: 150, marginRight: 150 }}>
						<ExcelUpload onUploaded={this.props.onUploaded} sheetNames={this.props.sheetNames}></ExcelUpload>
					</div>
				</ModalBody>

				<ModalFooter>
					<div className="ml-5">
						<Button color="link" className="text-primary" onClick={this.props.onCancel}>
							Close
						</Button>
					</div>
				</ModalFooter>
			</Form>
		)
	}
}
