import { toISODate, firstDateIn, deClutter, stringsMatch, VENDOR_NAME_NOISE_WORDS } from "clearview-aws-common"

import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"

const findValue = (summaryFields, type) => summaryFields.find(it => it.Type.Text === type)?.ValueDetection.Text
const findValues = (summaryFields, type, transform) =>
	summaryFields
		.filter(it => it.Type?.Text === type && it.ValueDetection?.Text)
		.map(it => (transform ? transform(it.ValueDetection?.Text) : it.ValueDetection?.Text))
const findOtherValue = (summaryFields, label) => summaryFields.find(it => stringsMatch(it.LabelDetection?.Text, label))?.ValueDetection?.Text

function isLikelyToBeNewInvoice(nextExpense, prevExpense) {
	// No previous expense?
	if (!prevExpense) return true

	// Document Page number is 1
	if (nextExpense.summaryFields.find(it => it.PageNumber === 1)) return true
	if (parseInt(findOtherValue(nextExpense.summaryFields, "Page")) === 1) return true

	// Different Vendor
	const vendorNameMatch = stringsMatch(prevExpense.vendorName, nextExpense.vendorName, VENDOR_NAME_NOISE_WORDS)
	if (vendorNameMatch === false) return true

	// Different Refs?
	const refMatch = stringsMatch(prevExpense.ref, nextExpense.ref)
	if (refMatch === false) return true

	// Null refs, but invoice dates are different?
	if (!prevExpense.ref && !nextExpense.ref && prevExpense.date && nextExpense.date && prevExpense.date !== nextExpense.date) return true

	// Different Total
	if (prevExpense.total && nextExpense.total && prevExpense.total !== nextExpense.total) return true

	// // Same Vendor, Ref & Total
	// if (vendorNameMatch && (refMatch || true) && prevExpense.total == nextExpense.total) return false

	// // No vendor, Ref & Total
	// if (vendorNameMatch === undefined && nextExpense.ref && nextExpense.total && refMatch && prevExpense.total == nextExpense.total) return false

	// // No vendor, No Ref & Total
	// if (vendorNameMatch === undefined && refMatch === undefined && nextExpense.total && prevExpense.total == nextExpense.total) return false

	return false
}

function validateItemStatus(ledgerItemOrExpense) {
	if (!ledgerItemOrExpense.status || ledgerItemOrExpense.status === "?") {
		ledgerItemOrExpense.status =
			!ledgerItemOrExpense.vendorName ||
			!ledgerItemOrExpense.date ||
			!ledgerItemOrExpense.ref ||
			!ledgerItemOrExpense.total ||
			ledgerItemOrExpense.warning?.severity === "danger"
				? "?"
				: undefined
	}
	return ledgerItemOrExpense
}

export function validateLedgerItem(ledgerItem) {
	return validateItemStatus(ledgerItem)
}

function propertyNameRegex(propertyName) {
	const propertyNameRegexString =
		"\\b" +
		deClutter(propertyName)
			.split(" ")
			.reduce((prev, next) => {
				return prev ? prev + "\\W+" + next : next
			}, "") +
		"\\b"

	return new RegExp(propertyNameRegexString, "gim")
}

export function validateExpense(expense, propertyNames) {
	if (!expense.warning && propertyNames.map) {
		const regexes = propertyNames.map(it => propertyNameRegex(it))

		if (
			!expense.summaryFields.find(summaryField => regexes.find(regex => regex.test(summaryField.value?.text) || regex.test(summaryField.label?.text))) &&
			!expense.lineItemGroups.find(lineItemGroup =>
				lineItemGroup.lineItems.find(lineItem => regexes.find(regex => regex.test(lineItem.value?.text) || regex.test(lineItem.label?.text)))
			)
		) {
			expense.warning = { severity: "danger", message: "Could not find the property name on the invoice" }
		}
	}

	if (!expense.page) expense.page = 1
	if (!expense.toPage) expense.toPage = expense.page

	return validateItemStatus(expense)
}

export function parseLedgerItem(it, accounts) {
	it.account = accounts.find(a => a.name === it.accountName)
	it.accountId = it.account?.id
	delete it.accountName
	return validateItemStatus(it)
}

export function isLedgerItemOrExpense(item) {
	if (!item) return item
	return item.index ? "Expense" : "LedgerItem"
}

function parseExpenseDocumentRef(summaryFields) {
	let ref = findValue(summaryFields, "INVOICE_RECEIPT_ID")
	if (!ref) return null

	// Exclude UK phone numbers
	if (/^(?:0|\\+?44\\s*|\\+?44\\s*\\(0\\))(?:\\d){4}\\s*(?:\\d){3}\\s*(?:\\d){3}$/.test(ref)) return null

	while (ref.startsWith("#")) ref = ref.substring(1)
	return ref
}

function parseExpenseDocumentTotal(summaryFields) {
	let total = _.max(findValues(summaryFields, "TOTAL", clearview.parseCurrency))
	if (total) return total

	const subTotal = _.max(findValues(summaryFields, "SUBTOTAL", clearview.parseCurrency))
	const vat = _.max(findValues(summaryFields, "TAX", clearview.parseCurrency))

	if (subTotal && vat) return subTotal + vat

	total = findOtherValue(summaryFields, "Payment now due", clearview.parseCurrency)
	if (total) return total

	return null
}

function applyRules(summaryFields, rules) {
	const applicableRules = rules.filter(rule =>
		_.every(rule.fields, field =>
			summaryFields.find(it =>
				!field.Type || field.Type === "OTHER"
					? it.Type?.Text === "OTHER" &&
					  stringsMatch(it.LabelDetection?.Text || "", field.Label) &&
					  stringsMatch(it.ValueDetection?.Text || "", field.Value)
					: it.Type?.Text === field.Type && stringsMatch(it.ValueDetection?.Text || "", field.Value)
			)
		)
	)

	return applicableRules.reduce((prev, nextRule) => {
		return {
			...prev,
			...nextRule.is,
		}
	}, {})
}

export function parseExpenseDocument(document, rules) {
	const fromRules = applyRules(document.SummaryFields, rules)

	if (!document.SummaryFields.length) {
		return null
	}

	return {
		split: null,
		page: _.minBy(document.SummaryFields, it => it.PageNumber)?.PageNumber,
		toPage: _.maxBy(document.SummaryFields, it => it.PageNumber)?.PageNumber,
		ref: parseExpenseDocumentRef(document.SummaryFields),
		vendorName: fromRules.vendorName !== undefined ? fromRules.vendorName : findValue(document.SummaryFields, "VENDOR_NAME"),
		status: fromRules.status,
		date: toISODate(
			firstDateIn([
				findValue(document.SummaryFields, "INVOICE_RECEIPT_DATE"),
				findValue(document.SummaryFields, "DUE_DATE"),
				findValue(document.SummaryFields, "DATE"),
				findOtherValue(document.SummaryFields, "Tax point:"),
			])
		),
		narrative: findValue(document.SummaryFields, "DESCRIPTION"),
		total: parseExpenseDocumentTotal(document.SummaryFields),
		summaryFields: document.SummaryFields.map(expenseFieldMapper(`e${document.ExpenseIndex}:sf:`)).filter(it => it.value),
		lineItemGroups: document.LineItemGroups.map((it, idx) => ({
			id: idx,
			lineItems: it.LineItems.flatMap((li, lix) =>
				li.LineItemExpenseFields.map(expenseFieldMapper(`e${document.ExpenseIndex}:g${idx}:${lix}:`)).filter(it => it.value)
			),
		})),
	}
}

function reapplyRules(summaryFields, rules) {
	const applicableRules = rules.filter(rule =>
		_.every(rule.fields, field =>
			summaryFields.find(it =>
				!field.Type || field.Type === "OTHER"
					? it.type === "OTHER" && stringsMatch(it.label?.text || "", field.Label) && stringsMatch(it.value?.text || "", field.Value)
					: it.type === field.Type && stringsMatch(it.value?.text || "", field.Value)
			)
		)
	)

	return applicableRules.reduce((prev, nextRule) => {
		return {
			...prev,
			...nextRule.is,
		}
	}, {})
}

export function reapplyImportRulesToExistingExpenses(expenses, rules) {
	let modifiedCount = 0

	for (let expense of expenses) {
		if ([null, undefined, "", "?"].includes(expense.status) && expense.summaryFields?.length) {
			const fromRules = reapplyRules(expense.summaryFields, rules)
			let isModified = false

			if (fromRules.vendorName !== undefined && expense.vendorName !== fromRules.vendorName) {
				expense.vendorName = fromRules.vendorName
				isModified = true
			}
			if (fromRules.status && expense.status !== fromRules.status) {
				expense.status = fromRules.status
				isModified = true
			}

			if (isModified) modifiedCount++
		}
	}

	return modifiedCount
}

const tokenSummaryMapper = it => ({
	id: it.id,
	type: it.type,
	label: it.label?.text,
	value: it.value?.text,
})
export function getAllTokenSummaries(expense) {
	return {
		summaryFields: (expense?.summaryFields || []).map(tokenSummaryMapper),
		lineItems: (expense.lineItemGroups?.flatMap(it => it.lineItems) || []).map(tokenSummaryMapper),
	}
}

function expenseFieldMapper(prefix) {
	return (it, idx) => ({
		id: `${prefix}${idx}`,
		pageNumber: it.PageNumber,
		type: it.Type?.Text,
		label: parseTextractDetection(it.LabelDetection),
		value: parseTextractDetection(it.ValueDetection),
	})
}

function parseTextractDetection(detection) {
	if (!detection || !detection.Text || !detection.Text.trim().length) return undefined

	return {
		text: detection.Text,
		box: {
			height: detection.Geometry?.BoundingBox?.Height || 0,
			left: detection.Geometry?.BoundingBox?.Left || 1,
			top: detection.Geometry?.BoundingBox?.Top || 0,
			width: detection.Geometry?.BoundingBox?.Width || 1,
		},
	}
}

export function processExpenseAnalysis(propertyNames, vouching, analysis, rules) {
	const s3 = `s3://${analysis.Input.bucketName}/${analysis.Input.objectName}`

	const expensesMaxId = parseInt(_.max(vouching.expenses?.map(it => it.id)) || 0)
	let importedCount = 0

	const lastExpense = analysis.ExpenseDocuments.reduce((prevExpense, next) => {
		var nextExpense = parseExpenseDocument(next, rules)
		if (!nextExpense) return prevExpense

		if (isLikelyToBeNewInvoice(nextExpense, prevExpense)) {
			importedCount++
			nextExpense.index = importedCount
			nextExpense.id = expensesMaxId + importedCount
			nextExpense.s3 = s3
			nextExpense.timestamp = parseInt(analysis.Input.timestamp) || 0
			vouching.expenses.push(nextExpense)
			return validateExpense(nextExpense, propertyNames)
		}

		prevExpense.toPage = nextExpense.toPage
		prevExpense.ref = prevExpense.ref || nextExpense.ref
		prevExpense.vendorName = prevExpense.vendorName || nextExpense.vendorName
		prevExpense.date = prevExpense.date || nextExpense.date
		prevExpense.narrative = prevExpense.narrative || nextExpense.narrative
		prevExpense.total = prevExpense.total || nextExpense.total
		prevExpense.summaryFields = _.concat(prevExpense.summaryFields, nextExpense.summaryFields)
		prevExpense.lineItemGroups = _.concat(prevExpense.lineItemGroups, nextExpense.lineItemGroups)

		return validateExpense(prevExpense, propertyNames)
	}, null)

	if (importedCount === 1) delete lastExpense.index

	return { vouching, importedCount }
}

export function processTextDetection(propertyNames, vouching, textDetection) {
	const s3 = `s3://${textDetection.Input.bucketName}/${textDetection.Input.objectName}`

	const correspondingExpenses = vouching.expenses.filter(it => it.s3 === s3)

	if (!correspondingExpenses.length) throw Error(`Expenses from ${clearview.S3EntityPath(s3)} have not yet been imported!`)

	const expensesToVouch = correspondingExpenses.filter(it => it.warning) // Don't care if property has been vouched manually already
	if (!expensesToVouch.length) return { vouching, importedCount: 0 }

	let vouchedCount = 0

	const regexes = propertyNames.map(name => propertyNameRegex(name))

	const pagesContainingPropertyName = {}
	for (let block of textDetection.Blocks) {
		if (block.Text && !pagesContainingPropertyName[block.Page] && regexes.find(regex => regex.test(block.Text))) {
			pagesContainingPropertyName[block.Page] = block.Text
		}
	}

	for (let expense of expensesToVouch) {
		for (let pageNumber = expense.page; pageNumber <= expense.toPage; pageNumber++) {
			if (pagesContainingPropertyName[pageNumber]) {
				expense.warning.message = `Property Name found in text '${pagesContainingPropertyName[pageNumber]}'`
				expense.warning.severity = "info"

				validateItemStatus(expense)
				vouchedCount++
				break
			}
		}
	}

	return { vouching, importedCount: vouchedCount }
}
