import React from "react"

import SortableTree from "@nosferatu500/react-sortable-tree"

import PropTypes from "prop-types"

import _ from "lodash"

import * as ACTIONS from "./../../../../store/actions"

import { Link } from "react-router-dom"

import { Button, Container, Row, Col, IconWithBadge, Badge, Input, InputGroup, InputGroupAddon } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"
import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"
import { Prompt } from "../../../../components"

const SEARCHEABLE_FIELDS = {
	reference: "reference",
	name: "name",
	email: "Email",
}

const EMPTY_STATE = {
	treeNodes: [],
	selectedItem: null,

	searchString: "",
	searchFocusIndex: 0,
	searchFoundCount: null,
}

export class ClientsTree extends React.Component {
	static propTypes = {
		treeData: PropTypes.array,
		selectedItem: PropTypes.object,
		user: PropTypes.object,
		onMove: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = EMPTY_STATE
	}

	static getDerivedStateFromProps(props, state) {
		if (!props.treeData) return EMPTY_STATE

		if (props.treeData && props.treeData.length && state.treeData && state.treeData.length) {
			props.treeData[0].expanded = state.treeData[0].expanded
			ACTIONS.crawlBranches(
				props.treeData[0].children,
				propNode => {
					const stateNode = ACTIONS.findInBranches(
						state.treeData,
						s => s.item.id === propNode.item.id,
						s => s.children
					)
					if (stateNode) {
						stateNode.title = titleElement(propNode.item)
						stateNode.item = propNode.item
					} else {
						const parentNode = ACTIONS.findInBranches(
							state.treeData,
							s => s.item.id === propNode.item.parent.id,
							s => s.children
						)
						if (parentNode) {
							if (!parentNode.children) parentNode.children = []
							parentNode.children.unshift(propNode)
						} else {
							console.warn("MISS, MISS")
						}
					}
				},
				propNode => propNode.children
			)
		}

		return {
			treeData: state.treeData ?? props.treeData,
			selectedItem: props.selectedItem,
		}
	}

	render() {
		const { treeData, selectedItem, searchString, searchFocusIndex, searchFoundCount, isDragEnabled } = this.state

		var node =
			selectedItem &&
			ACTIONS.findInBranches(
				treeData,
				it => it.item.id === selectedItem.id,
				it => it.children
			)
		if (node) {
			node.title = titleElement(selectedItem)
			node.item = selectedItem
		}

		const customSearchMethod = ({ node, searchQuery }) => {
			if (!searchQuery) return false

			const parts = searchQuery.split(":", 2)

			const namedField = parts.length > 1 ? parts[0].toLowerCase() : null
			const regex = clearview.Search.SafeRegex(parts[parts.length - 1])

			if (namedField && SEARCHEABLE_FIELDS[namedField]) return node.item[SEARCHEABLE_FIELDS[namedField]].match(regex)
			else return clearview.Search.Business(node.item, regex)
		}

		const onSearchFinished = matches => {
			const searchFocusIndex = matches.length > 0 ? this.state.searchFocusIndex % matches.length : 0
			this.setState({
				selectedItem: matches.length > 0 ? matches[searchFocusIndex].node.item : null,
				matches: matches,
				searchFoundCount: matches.length,
				searchFocusIndex: searchFocusIndex,
			})
		}

		const selectPrevMatch = () => {
			const searchFocusIndex =
				this.state.searchFocusIndex !== null
					? (this.state.searchFoundCount + this.state.searchFocusIndex - 1) % this.state.searchFoundCount
					: this.state.searchFoundCount - 1
			this.setState({
				searchFocusIndex: searchFocusIndex,
			})
		}

		const selectNextMatch = () => {
			const searchFocusIndex = this.state.searchFocusIndex !== null ? (this.state.searchFocusIndex + 1) % this.state.searchFoundCount : 0
			this.setState({
				searchFocusIndex: searchFocusIndex,
			})
		}

		const isDragAllowed = this.props.user.isInHouse && clearview.User.IsAdmin(this.props.user)

		const onDragEnable = () => {
			this.setState({ isDragEnabled: true })
		}

		const onDragSave = () => {
			const summary = _.keys(this.state.moveClients)
				.map(key => `${key} to ${this.state.moveClients[key].under.reference}: ${this.state.moveClients[key].under.name}`)
				.join("\n")
			const okToSave = window.confirm(`Are you sure you wish to move:\n${summary}`)
			if (okToSave) {
				this.props.onMove(_.values(this.state.moveClients))
				this.setState({ isDragEnabled: false, moveClients: undefined })
			}
		}

		const onDragCancel = () => {
			this.setState({ treeData: this.props.treeData, isDragEnabled: false, moveClients: undefined })
		}

		const canDrag = it => {
			return isDragEnabled && it.node.item.type === "Property"
		}

		const canDrop = it => {
			if (!it.nextParent || !it.nextParent.item) return false
			if (it.prevParent == it.nextParent) return false
			return it.nextParent.item.type === "Client"
		}

		const onDrop = it => {
			this.setState(prevState => {
				const moveClients = prevState.moveClients || {}

				const command = { move: it.node.item, under: it.nextParentNode.item }
				if (command.move.parent.id === command.under.id) delete moveClients[command.move.reference]
				else moveClients[command.move.reference] = command

				return { moveClients: moveClients }
			})
		}

		return (
			<Container>
				<Prompt
					when={this.state.moveClients}
					message={() => "You have moved some properties.\r\rAre you sure you want to leave this page and discard your changes?"}
				/>
				<Row>
					<Col lg={12}>
						<div className="d-flex ml-auto mb-3">
							<InputGroup className="mr-2" size="sm">
								{isDragAllowed && !isDragEnabled && (
									<InputGroupAddon addonType="prepend">
										<Button
											className="btn btn-outline-secondary text-decoration-none"
											color="link"
											title={"Move properties"}
											onClick={onDragEnable}
										>
											{clearview.Icon.organize}
										</Button>
									</InputGroupAddon>
								)}
								{isDragAllowed && (
									<InputGroupAddon addonType="prepend">
										<div style={{ zoom: 0.8, marginRight: 8 }}>
											<EditSaveOrCancel
												isEditing={!!isDragEnabled}
												isChanged={!!this.state.moveClients}
												onSave={onDragSave}
												onCancel={onDragCancel}
											/>
										</div>
									</InputGroupAddon>
								)}{" "}
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-search fa-fw"></i>
								</InputGroupAddon>
								<Input
									disabled={false}
									onChange={e => this.setState({ searchString: e.target.value })}
									value={searchString}
									className="bg-white"
									placeholder="Type to search..."
								/>
								{searchString && (
									<InputGroupAddon addonType="append">
										<Button outline onClick={() => this.setState({ searchString: "" })}>
											<i className="fa fa-fw fa-times"></i>
										</Button>
									</InputGroupAddon>
								)}
								<button type="button" disabled={!searchFoundCount} onClick={selectPrevMatch}>
									&lt;
								</button>
								<button type="submit" disabled={!searchFoundCount} onClick={selectNextMatch}>
									&gt;
								</button>
								<span>
									&nbsp;
									{searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
									&nbsp;/&nbsp;
									{searchFoundCount || 0}
								</span>
							</InputGroup>
						</div>
					</Col>
				</Row>

				{/* <Row>
					<Col lg={12}>
						<pre>{JSON.stringify(_.values(this.state?.moveClients).map(it => ({ [it.move.reference]: it.under.reference })))}</pre>
					</Col>
				</Row> */}

				<Row>
					<Col lg={12} style={{ height: 600 }}>
						{/* https://github.com/frontend-collective/react-sortable-tree/blob/master/stories/only-expand-searched-node.js */}
						<SortableTree
							generateNodeProps={treeNode => ({
								className: `rst__nodeContent_${treeNode.node.item.type}${
									selectedItem && treeNode.node.item.id === selectedItem.id ? " rst__nodeContent_selected" : ""
								}`,
								buttons: orgButtons(treeNode.node.item),
							})}
							canDrag={canDrag}
							canDrop={canDrop}
							onMoveNode={onDrop}
							treeData={treeData}
							onChange={refreshedTreeData => {
								this.setState({ treeData: refreshedTreeData })
							}}
							searchMethod={customSearchMethod}
							searchQuery={searchString}
							searchFocusOffset={searchFocusIndex}
							searchFinishCallback={onSearchFinished}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

function titleElement(client) {
	return (
		<Link to={`/clients/${client.reference}`} className="text-decoration-none nowrap">
			{clearview.Icon.Business[client.role]} {clearview.GetBusinessTitle(client)}
		</Link>
	)
}

function propertySubtitleElement(client) {
	if (client.periodEnds) {
		return client.periodEnds.sort(clearview.periodEndSortDesc).map(periodEnd => (
			<Link
				key={periodEnd.id}
				to={`/periodend/${periodEnd.reference}`}
				className="text-decoration-none nowrap mr-1 hover-highlight"
				title={periodEnd.template.name}
			>
				{periodEnd.reference}
			</Link>
		))
	}
	return undefined
}

export function mapToTreeNodes(items, itemAction, showExpanded = true) {
	return (items || []).map((it, idx) => {
		itemAction(it)

		return {
			title: titleElement(it),
			subtitle: propertySubtitleElement(it),
			children: mapToTreeNodes(it.children, itemAction, false),
			expanded: it.type === "Root" ? true : showExpanded,
			item: it,
		}
	})
}

function orgButtons(client) {
	let buttons = []

	if (client.teams) {
		const teamSummary = _.groupBy(
			client.teams.filter(it => ["Users", "InHouseTeam", "ClientTeam"].includes(it.type)),
			it => it.type
		)

		buttons.push(
			Object.keys(teamSummary).map(type =>
				type === "Users" ? (
					<IconWithBadge
						key={type}
						badge={
							<Badge pill color={client.type === "Customer" ? "primary" : "success"}>
								{teamSummary[type][0].users.length}
							</Badge>
						}
					>
						{clearview.Icon.Business.Users}
					</IconWithBadge>
				) : type === "InHouseTeam" ? (
					<IconWithBadge
						key={type}
						badge={
							<Badge pill color="info">
								{teamSummary[type].length}
							</Badge>
						}
					>
						{clearview.Icon.InHouse[true]}
					</IconWithBadge>
				) : type === "ClientTeam" ? (
					<IconWithBadge
						key={type}
						badge={
							<Badge pill color="success">
								{teamSummary[type].length}
							</Badge>
						}
					>
						{clearview.Icon.InHouse[false]}
					</IconWithBadge>
				) : null
			)
		)
	}

	return buttons
}
