import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import _ from "lodash"

import { Consumer } from "../../../../layout/context"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

import { Avatar, Accordion, Table, Input } from "../../../../components"

import { Address } from "../../../components/Address"
import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"
import { IsNotifiedIcon } from "../../../components/IsNotifiedIcon"

import AttributesTable from "../../Clients/components/AttributesTable"
import PropertyActions from "./PropertyActions"

import { S3Folder } from "../../components/s3/S3Folder"

import { defaultTeams, teamSequence } from "../../Clients/components/AppointeesCard"

class PropertyCard extends React.Component {
	static propTypes = {
		property: PropTypes.object.isRequired,
		isCompact: PropTypes.bool,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.initState = this.initState.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onSave = this.onSave.bind(this)
		this.onCancel = this.onCancel.bind(this)

		this.state = this.initState()
	}

	initState() {
		return {
			key: clearview.GenerateKey(),
			property: _.cloneDeep(this.props.property),
			isEditing: false,
			isValid: false,
			isChanged: false,
		}
	}

	onChange(e) {
		switch (e.target.type) {
			case "number":
				this.state.property[e.target.name] = parseInt(e.target.value)
				break

			default:
				this.state.property[e.target.name] = e.target.value
				break
		}

		this.setState({
			isChanged: true,
		})
	}

	onSave() {
		this.props.doUpdateProperty(this.state.property)
		this.setState(this.initState())
	}

	onCancel() {
		this.setState(this.initState())
	}

	componentDidUpdate(prevProps) {
		if (prevProps.property.reference !== this.props.property.reference) {
			this.setState(this.initState())
		}
	}

	render() {
		const { property } = this.state
		const actions = property.actions || []

		const teamsInfo = getTeamsInfo(property)

		return (
			<Consumer>
				{({ isEditing }) => (
					<Accordion className="mb-2" initialOpen={true}>
						<Accordion.Header className="d-flex-row h6 justify-content-between">
							<span className="align-self-center">{clearview.Icon.Business.Property} Property</span>
							<EditSaveOrCancel isEditing={isEditing || false} isChanged={this.state.isChanged} onSave={this.onSave} onCancel={this.onCancel} />
							<PropertyActions property={property} />
						</Accordion.Header>
						<Accordion.Body>
							<Table size="sm">
								<tbody>
									<tr>
										<th colSpan={2}>{clearview.GetBusinessTitle(property)}</th>
									</tr>
									<tr>
										<td className="align-top">Client:</td>
										<td className="text-right">{property.parent.name}</td>
									</tr>
									{this.props.property.periodEnds && (
										<tr>
											<th colSpan={2}>
												Period Ends:
												<br />
												{this.props.property.periodEnds?.sort(clearview.periodEndSortDesc).map(it => (
													<Link key={it.id} to={`/periodend/${it.reference}`} className="text-decoration-none new-line ml-3">
														{it.closedAt && clearview.PeriodEndStatusBadge(it, it.reference)}
														{!it.closedAt && clearview.PeriodEndRagBadge(it, it.reference)}
													</Link>
												))}
											</th>
										</tr>
									)}

									{(isEditing || property.reference) && (
										<tr>
											<td className="align-top">Ref:</td>
											<td className="text-right" title={`Property #${property.id}`}>
												{(!isEditing || !actions.includes("SetProperties")) && property.reference}
												{isEditing && actions.includes("SetProperties") && (
													<Input
														placeholder="Ref..."
														name="reference"
														value={property.reference}
														onKeyDown={clearview.ValidRefCharacters}
														onChange={this.onChange}
													/>
												)}
											</td>
										</tr>
									)}
									<tr>
										<td className="align-top">Name</td>
										<td className="text-right">
											{(!isEditing || !actions.includes("SetProperties")) && property.name}
											{isEditing && actions.includes("SetProperties") && (
												<Input placeholder="Name..." name="name" value={property.name} onChange={this.onChange} />
											)}
										</td>
									</tr>
									{(property.address || isEditing) && (
										<tr>
											<td className="align-top">Address</td>
											<td className="text-left">
												<Address
													key={this.state.key}
													address={property.address}
													isEditing={isEditing && actions.includes("SetAddress")}
													name="address"
													onChange={this.onChange}
												/>
											</td>
										</tr>
									)}
									<tr>
										<td>Contact:</td>
										<td>
											{property.actors.inHouseContact && (
												<span className="text-inverse">
													<Avatar.Font
														user={property.actors.inHouseContact}
														size="sm"
														className="avatar-with-text mr-1"
													></Avatar.Font>
													<span>
														{property.actors.inHouseContact.name}
														<IsNotifiedIcon user={property.actors.inHouseContact} />
													</span>
												</span>
											)}
										</td>
									</tr>
									{!!teamsInfo.inHouseServiceChargeManagerTeam?.users?.length && (
										<React.Fragment>
											<tr>
												<th colSpan={2}>{teamsInfo.inHouseServiceChargeManagerTeam.name}:</th>
											</tr>
											{_.sortBy(teamsInfo.inHouseServiceChargeManagerTeam.users, [it => it.initials, it => it.name]).map(user => (
												<tr key={`scm_${user.username}`}>
													<td colSpan={2} key={`scm_${user.username}`} className="align-middle text-left">
														<Avatar.Font
															user={user}
															size="sm"
															bgColor={
																teamsInfo.inHouseServiceChargeManagerTeam === teamsInfo.propertyInHouseServiceChargeManagerTeam
																	? "gray"
																	: undefined
															}
															className="avatar-with-text mr-1"
														></Avatar.Font>
														<span>
															{user.name}
															<IsNotifiedIcon user={user} />
														</span>
														<span>{clearview.Icon.IsContact[user.isContact]}</span>
													</td>
												</tr>
											))}
										</React.Fragment>
									)}

									{teamsInfo.teams.map(team => (
										<React.Fragment key={`team${team.username}`}>
											<tr>
												<th colSpan={2}>Client {team.name}:</th>
											</tr>

											{team.inheritedTeam &&
												_.sortBy(team.inheritedTeam, iu => iu.name).map(inheritedUser => (
													<tr key={`I_A_${inheritedUser.username}`}>
														<td colSpan={2} className="align-middle text-left">
															<Avatar.Font
																user={inheritedUser}
																size="sm"
																bgColor="gray"
																className="avatar-with-text mr-1"
															></Avatar.Font>
															<span>
																{inheritedUser.name}
																<IsNotifiedIcon user={inheritedUser} />
															</span>
															<span>{clearview.Icon.IsContact[inheritedUser.isContact]}</span>
														</td>
													</tr>
												))}

											{_.sortBy(team.users, [it => it.initials, it => it.name]).map(user => (
												<tr key={`A_${user.username}`}>
													<td colSpan={2} key={`A_${user.username}`} className="align-middle text-left">
														<Avatar.Font user={user} size="sm" className="avatar-with-text mr-1"></Avatar.Font>
														<span>
															{user.name}
															<IsNotifiedIcon user={user} />
														</span>
														<span>{clearview.Icon.IsContact[user.isContact]}</span>
													</td>
												</tr>
											))}
										</React.Fragment>
									))}
									{/*
                                <tr>
                                    <td>Client Contact:</td>
                                    <td>{property.actors.clientContact && <span className="text-inverse"><Avatar.Font
                                        user={property.actors.clientContact}
                                        size="sm"
                                        className="avatar-with-text mr-1"
                                    ></Avatar.Font>{property.actors.clientContact.name}</span>}
                                    </td>
                                </tr> */}
								</tbody>
							</Table>

							<AttributesTable
								key={this.state.key}
								business={property}
								isEditing={isEditing && actions.includes("SetProperties")}
								name="attributes"
								onChange={this.onChange}
							/>

							<S3Folder folder={`/property/${this.props.property.id}`} label="Property Files" className="in-card"></S3Folder>
						</Accordion.Body>
					</Accordion>
				)}
			</Consumer>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doUpdateProperty: property =>
		dispatch({
			type: ACTIONS.PROPERTY_UPDATE,
			property: property,
		}),
})

PropertyCard = connect(null, mapDispatchToProps)(PropertyCard)

export { PropertyCard }

export const inHouseServiceChargeTeamPredicate = it => !!it && it.type === "InHouseAppointees" && it.name === "Service Charge Manager"

export function getTeamsInfo(property) {
	const propertyInHouseServiceChargeManagerTeam = property.teams.find(inHouseServiceChargeTeamPredicate)
	let inHouseServiceChargeManagerTeam = _.cloneDeep(propertyInHouseServiceChargeManagerTeam || {})

	let teams = _.unionBy(
		property.teams.filter(it => it.type === "ClientAppointees"),
		defaultTeams(property, "ClientAppointees"),
		it => it.name
	)
	ACTIONS.climbBranch(property, it => {
		if (it != property && it.teams) {
			it.teams.forEach(inheritedTeam => {
				const team = _.find(teams, t => t.type === inheritedTeam.type && t.name === inheritedTeam.name)
				if (team) {
					team.inheritedTeam = _.unionBy(team.inheritedTeam || [], inheritedTeam.users, u => u.username)
				}

				if (inHouseServiceChargeTeamPredicate(inheritedTeam)) {
					inHouseServiceChargeManagerTeam.name = inheritedTeam.name
					inHouseServiceChargeManagerTeam.users = _.unionBy(inHouseServiceChargeManagerTeam.users || [], inheritedTeam.users, u => u.username)
				}
			})
		}
	})
	teams = _.sortBy(_.cloneDeep(teams.filter(it => (it.users && it.users.length) || (it.inheritedTeam && it.inheritedTeam.length))), [
		it => teamSequence[it.name],
	])

	return {
		teams,
		propertyInHouseServiceChargeManagerTeam,
		inHouseServiceChargeManagerTeam,
	}
}
