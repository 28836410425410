import React from "react"
import { PieChart, Pie, Cell } from "recharts"

import colors from "../../../../colors"

const TinyDonutChart = props => (
	<PieChart width={200} height={140}>
		<Pie
			data={props.data}
			dataKey="value"
			stroke={colors["white"]}
			innerRadius={48}
			outerRadius={64}
			fill="#8884d8"
			onClick={(segment, idx) => props.navigateTo && console.warn(`Navigate To: ${props.navigateTo}${segment.name}`)}
		>
			{props.data.map((entry, idx) => (
				<Cell key={idx} fill={entry.color} />
			))}
		</Pie>
	</PieChart>
)

export { TinyDonutChart }
