import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"

import { Container, Row, Col } from "../../../components"
import { Spinner } from "../../components/Spinner"

import { HeaderMain } from "../../components/HeaderMain"

import { PeriodEndsTable } from "./components/PeriodEndsTable"

class PeriodEndsDashboardComponent extends Component {
	static propTypes = {
		periodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,

		isBusy: PropTypes.bool.isRequired,
		lastUpdated: PropTypes.number,
	}

	componentDidMount() {
		// this.props.doRefresh()
	}

	render() {
		return (
			<Container className={this.props.isBusy ? "isBusy wide" : "wide"}>
				{this.props.isBusy && <Spinner key="spinner"></Spinner>}
				<HeaderMain
					title={<span className="text-primary">{clearview.Icon.periodEnd}Period Ends</span>}
					className="mb-4 mt-0"
					onRefresh={() => this.props.doRefresh()}
				/>
				<Row>
					<Col lg={12}>
						<PeriodEndsTable periodEnds={this.props.periodEnds} user={this.props.user} maxWidth={700} />
					</Col>
				</Row>
			</Container>
		)
	}
}

function PeriodEndsDashboard({ periodEnds, user, isBusy, lastUpdated, preFilterContext, doRefresh, markAllRead }) {
	preFilterContext.checkIfPreFilterLinked()
	return (
		<PeriodEndsDashboardComponent
			periodEnds={periodEnds}
			user={user}
			isBusy={isBusy}
			lastUpdated={lastUpdated}
			doRefresh={doRefresh}
			markAllRead={markAllRead}
		/>
	)
}

const mapStateToProps = state => {
	const subState = ACTIONS.getSubState(state, "clientsReducer\\periodEnds")

	return {
		...subState,
		periodEnds: ACTIONS.dictionaryToArray(subState.dict),
		user: state.userReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	doRefresh: () => {
		dispatch({ type: ACTIONS.USER_AUTHENTICATED, payload: {} })
	},

	markAllRead: () => {
		dispatch({ type: ACTIONS.PERIODEND_ALL_READ_UPDATE })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(PeriodEndsDashboard)
