import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Form, FormGroup, InputGroup, InputGroupAddon, UncontrolledModal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"

class Template_RetireTemplateForm extends React.Component {
	static propTypes = {
		template: PropTypes.object.isRequired,
		customerTemplates: PropTypes.array.isRequired,
		// dispatch: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {}
		this.props = props

		this.handleTemplateChange = this.handleTemplateChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleTemplateChange(e) {
		this.setState({
			nextId: e.target.value,
		})
	}

	handleActionButtonClick() {
		this.props.dispatch({
			type: ACTIONS.TEMPLATE_RETIRE,
			template: this.props.template,
			nextId: this.state.nextId,
		})
		this.setState({})
		return true
	}

	render() {
		const { customerTemplates, template } = this.props
		const templates = customerTemplates.find(it => it.id === template.customer.id).templates.filter(it => !it.isRetired && it.id !== template.id)

		return (
			<UncontrolledModal target={`template${template.id}_RetireTemplateForm`}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">
						Retire {template.reference}: {template.name}
					</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Use his template instead (optional)</InputGroupAddon>
								<CustomInput type="select" name="nextId" id="nextId" onChange={this.handleTemplateChange}>
									<option defaultValue="">None</option>
									{templates.map((it, idx) => (
										<option key={idx} value={it.id}>
											{it.reference}: {it.name}
										</option>
									))}
								</CustomInput>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close color="default" className="text-primary" onClick={this.handleActionButtonClick}>
							Retire
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapStateToProps = state => {
	return {
		customerTemplates: ACTIONS.dictionaryToArray(ACTIONS.getSubState(state, "clientsReducer\\templates").dict),
	}
}

export default connect(mapStateToProps)(Template_RetireTemplateForm)
