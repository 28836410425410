import React, { useState } from "react"
import { connect } from "react-redux"
import { WP_ACTIONS } from "./store"

import * as _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import { FeeSummary, AddUpdateScheduleCode, AddUpdateJournalEntry } from "./components"
import { Badge, Accordion, Row, Col, Button, Table, SimpleTable } from "../../../../components"
import { InputYesNoCancel } from "../../../components/InputYesNoCancel"

import { accountColor } from "../VouchingDetails/VouchingTheme"

import { expensePageRef } from "../VouchingDetails/VouchingReport"
import { scheduleCodeSort } from "./WorkingPapers.Common"

function ClientExpenditurePage({ isReadOnly, workingPapers, expenditureReport, doImportVouching }) {
	return (
		<React.Fragment>
			<Row className="mt-2">
				<Col>
					<h5 className="mt-4">Client Expenditure Summary</h5>
					<ExpenditureSummary categories={expenditureReport.categories} />
				</Col>
				<Col>
					<h5 className="mt-4">Fees</h5>
					<FeeSummary />
				</Col>
			</Row>
			<Accordion initialOpen={true}>
				<Accordion.Header className="d-flex h6">Details</Accordion.Header>
				<Accordion.Body>
					<ExpenditureTable workingPapers={workingPapers} expenditureReport={expenditureReport} isReadOnly={isReadOnly} />
				</Accordion.Body>
			</Accordion>
			{!isReadOnly && (
				<Button outline color="primary" className="mt-2" onClick={e => doImportVouching(workingPapers.periodEnd)}>
					{clearview.Icon.vouching} Import Vouching Data...
				</Button>
			)}
		</React.Fragment>
	)
}

const mapStateToProps_Client = state => {
	return {
		workingPapers: state.workingPapersReducer.workingPapers,
		expenditureReport: state.workingPapersReducer.workingPapers.expenditureReport,
	}
}

const mapDispatchToProps = dispatch => ({
	doImportVouching: periodEnd =>
		dispatch({
			type: WP_ACTIONS.IMPORT_VOUCHING,
			periodEnd,
		}),
})

ClientExpenditurePage = connect(mapStateToProps_Client, mapDispatchToProps)(ClientExpenditurePage)
export { ClientExpenditurePage }

function AdjustedExpenditurePage({ isReadOnly, workingPapers, expenditureReport, journals }) {
	return (
		<React.Fragment>
			<Accordion initialOpen={true}>
				<Accordion.Header className="d-flex h6">Adjusted Expenditure</Accordion.Header>
				<Accordion.Body>
					<ExpenditureTable workingPapers={workingPapers} expenditureReport={expenditureReport} journals={journals} isReadOnly={isReadOnly} />
				</Accordion.Body>
			</Accordion>
		</React.Fragment>
	)
}

const mapStateToProps_Adjusted = state => {
	return {
		workingPapers: state.workingPapersReducer.workingPapers,
		expenditureReport: state.workingPapersReducer.workingPapers.expenditureReport,
		journals: state.workingPapersReducer.workingPapers.journals,
	}
}

AdjustedExpenditurePage = connect(mapStateToProps_Adjusted, mapDispatchToProps)(AdjustedExpenditurePage)
export { AdjustedExpenditurePage }

function journalsMapper(journals) {
	return journals.flatMap((it, idx) => {
		return it.items.map(itm => ({
			journal: it,
			schedule: itm.schedule,
			code: itm.code,
			category: {
				label: <span className="text-primary">Adjustment {idx + 1}</span>,
			},
			account: itm.nominalCode,
			narrative: it.description,
			total: itm.cr || itm.dr * -1,
		}))
	})
}

function ExpenditureTable({ isReadOnly, workingPapers, expenditureReport, journals, onSave, onSaveAlias, onApplyAlias, onSaveJournal }) {
	const data = [...expenditureReport.ledger, ...journalsMapper(journals || [])].sort(scheduleCodeSort)
	const [rowEditor, setRowEditor] = useState(false)
	const [journalEditor, setJournalEditor] = useState(false)
	const [askSaveAlias, setAskSaveAlias] = useState(false)

	const onEdit = isReadOnly
		? false
		: row => {
				if (row.journal) setJournalEditor({ title: "Edit Journal", value: { ...row.journal } })
				else setRowEditor({ title: "Edit Expenditure", value: { ...row } })
		  }

	const onCancel = e => {
		setRowEditor(false)
		setJournalEditor(false)
		setAskSaveAlias(false)
	}

	const onBeforeSaveRow = row => {
		setAskSaveAlias({
			config: {
				width: 650,
				title: `Change all?`,
				message: (
					<div>
						<h6>
							Change all instances of <b>{row.account?.name}</b> to be <b>{row.code}</b>?
						</h6>
					</div>
				),
			},
			actions: {
				"Yes, and in all future imports": () => {
					onSaveAlias(row, workingPapers)
					onCancel()
				},
				"Yes, only this page": () => {
					onApplyAlias(row)
					onCancel()
				},
				"No, just this item": () => {
					onSave(row)
					onCancel()
				},
			},
		})
	}

	const columns = {
		schedule: { map: clearview.tidy.string },
		code: { map: clearview.tidy.string, classNameFn: (col, row, idx, type) => (!col ? "missing" : "") },
		date: { map: clearview.tidy.date },
		category: { map: (col, row) => clearview.tidy.markup(row.category?.label) },
		account: {
			map: col => (
				<Badge className="h6" color={accountColor(col?.name)}>
					{col?.name || "unknown"}
				</Badge>
			),
		},
		narrative: {
			heading: "Description",
			map: clearview.tidy.string,
		},
		invoice: {
			heading: "Invoice",
			map: clearview.tidy.string,
			mapSummary: (sum, summary, idx, type) => (type === "sub-total" ? `${summary.schedule}:` : "Total:"),
		},
		total: {
			heading: "Gross",
			map: clearview.tidy.currency,
			className: "currency text-dark",
		},
		expense: {
			heading: "Vouched",
			map: (col, row) => clearview.tidy.string(expensePageRef(row.expense)) || row.label,
		},
	}

	return (
		<React.Fragment>
			<SimpleTable groupBy={["schedule", "code"]} columns={columns} data={data} onEdit={onEdit} summaryColumns={["total"]} />
			<AddUpdateScheduleCode
				name="rowEditor"
				config={{ title: rowEditor.title }}
				isOpen={!!rowEditor}
				onCancel={onCancel}
				onSave={onBeforeSaveRow}
				value={rowEditor.value}
			/>
			<AddUpdateJournalEntry
				name="journalEditor"
				config={{ title: journalEditor?.title }}
				isOpen={!!journalEditor}
				onCancel={onCancel}
				onSave={item => {
					onSaveJournal(item)
					onCancel()
				}}
				value={journalEditor?.value}
			/>
			<InputYesNoCancel config={askSaveAlias.config} isOpen={!!askSaveAlias} actions={askSaveAlias.actions} onCancel={onCancel} />
		</React.Fragment>
	)
}

ExpenditureTable = connect(null, dispatch => ({
	onSave: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_LEDGER_ROW,
			row,
		}),
	onSaveAlias: (row, workingPapers) =>
		dispatch({
			type: WP_ACTIONS.SAVE_NOMINAL_CODES,
			row,
			workingPapers,
		}),
	onApplyAlias: row =>
		dispatch({
			type: WP_ACTIONS.APPLY_LEDGER_ALIAS,
			row,
		}),
	onSaveJournal: row =>
		dispatch({
			type: WP_ACTIONS.SAVE_ROW,
			page: "journals",
			row,
		}),
}))(ExpenditureTable)

function ExpenditureSummary({ categories }) {
	const categoryNames = _.keys(categories)

	let grandTotal = 0

	return (
		<Table className="compact-table compact-rows">
			<tbody>
				{categoryNames.map((categoryName, cIdx) => {
					const category = categories[categoryName]
					grandTotal = grandTotal + category.total || 0
					return (
						<tr key={`cat_${cIdx}`}>
							<th className={category.className}>{category.label}</th>
							<td className={`currency ${category.className}`}>{clearview.FormatCurrency(category.total, "currency")}</td>
						</tr>
					)
				})}
			</tbody>
			<tfoot>
				<tr>
					<th className="text-total">Grand Total</th>
					<td className="currency text-dark text-total">{clearview.FormatCurrency(grandTotal)}</td>
				</tr>
			</tfoot>
		</Table>
	)
}
