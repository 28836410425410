import React from "react"
import PropTypes from "prop-types"

import _ from "lodash"

import Toggle from "react-toggle"
import { VouchingDetailsContext } from "./VouchingDetailsContext"
import { STATUS_CLASS_NAME, STATUS_ICON } from "./VouchingTheme"

export default class VouchingFilters extends React.Component {
	static propTypes = {
		vouching: PropTypes.object,
	}

	getVouchingCounts(vouching) {
		const ledgerCounts = _.countBy(vouching?.ledger, it => it.status)
		const expensesCounts = _.countBy(vouching?.expenses, it => it.status)

		return {
			ledgerNotMatchedCount: ledgerCounts[undefined] || 0 + ledgerCounts[null] || 0,
			ledgerIncompleteCount: ledgerCounts["?"] || 0,
			ledgerExcludedCount: ledgerCounts["X"] || 0,
			ledgerAutoMatchedCount: ledgerCounts["A"] || 0,
			ledgerManualMatchedCount: ledgerCounts["M"] || 0,
			ledgerUnavailableCount: ledgerCounts["U"] || 0,

			expensesNotMatchedCount: expensesCounts[undefined] || 0 + expensesCounts[null] || 0,
			expensesIncompleteCount: expensesCounts["?"] || 0,
			expensesExcludedCount: expensesCounts["X"] || 0,
			expensesAutoMatchedCount: expensesCounts["A"] || 0,
			expensesManualMatchedCount: expensesCounts["M"] || 0,
		}
	}

	render() {
		const { vouching } = this.props
		const counts = this.getVouchingCounts(vouching)

		return (
			<VouchingDetailsContext.Consumer>
				{context => {
					return (
						<div id="vouching-filters" className="d-flex-row justify-content-between">
							<div className="d-flex-row justify-content-start align-items-center" title="Incomplete or error">
								<Toggle
									icons={{
										checked: <span style={{ color: "white" }}>{STATUS_ICON["?"]}</span>,
										unchecked: STATUS_ICON["?"],
									}}
									id="incomplete"
									name="incomplete"
									checked={context.filter.incomplete}
									className={STATUS_CLASS_NAME["?"]}
									onChange={e => context.setFilterItem(e)}
								/>
								<span title="Number of Ledger items incomplete">{counts.ledgerIncompleteCount} :</span>
								<span title="Number of Expense items incomplete">{counts.expensesIncompleteCount}</span>
							</div>

							<div className="d-flex-row justify-content-start align-items-center" title="Not matched">
								<Toggle
									icons={{
										checked: <span style={{ color: "white" }}>{STATUS_ICON[undefined]}</span>,
										unchecked: STATUS_ICON[undefined],
									}}
									id="notMatched"
									name="notMatched"
									className={STATUS_CLASS_NAME[undefined]}
									checked={context.filter.notMatched}
									onChange={e => context.setFilterItem(e)}
								/>
								<span title="Number of Ledger items not matched">{counts.ledgerNotMatchedCount} :</span>
								<span title="Number of Expense items not matched">{counts.expensesNotMatchedCount}</span>
							</div>

							<div className="d-flex-row justify-content-start align-items-center" title="Auto-matched">
								<Toggle
									icons={{
										checked: <span style={{ color: "white" }}>{STATUS_ICON.A}</span>,
										unchecked: STATUS_ICON.A,
									}}
									id="autoMatched"
									name="autoMatched"
									className={STATUS_CLASS_NAME.A}
									checked={context.filter.autoMatched}
									onChange={e => context.setFilterItem(e)}
								/>
								<span title="Number of Ledger items matched automatically">{counts.ledgerAutoMatchedCount} :</span>
								<span title="Number of Expense items matched automatically">{counts.expensesAutoMatchedCount}</span>
							</div>

							<div className="d-flex-row justify-content-start align-items-center" title="Manually matched">
								<Toggle
									icons={{
										checked: <span style={{ color: "white" }}>{STATUS_ICON.M}</span>,
										unchecked: STATUS_ICON.M,
									}}
									id="manualMatched"
									name="manualMatched"
									className={STATUS_CLASS_NAME.M}
									checked={context.filter.manualMatched}
									onChange={e => context.setFilterItem(e)}
								/>
								<span title="Number of Ledger items matched manually">{counts.ledgerManualMatchedCount} :</span>
								<span title="Number of Expense items matched manually">{counts.expensesManualMatchedCount}</span>
							</div>

							<div className="d-flex-row justify-content-start align-items-center" title="Excluded">
								<Toggle
									icons={{
										checked: <span style={{ color: "white" }}>{STATUS_ICON.X}</span>,
										unchecked: STATUS_ICON.X,
									}}
									id="excluded"
									name="excluded"
									checked={context.filter.excluded}
									className={STATUS_CLASS_NAME.X}
									onChange={e => context.setFilterItem(e)}
								/>
								<span title="Number of Ledger items excluded">{counts.ledgerExcludedCount} :</span>
								<span title="Number of Expense items excluded">{counts.expensesExcludedCount}</span>
							</div>

							<div className="d-flex-row justify-content-start align-items-center" title="Ledger Lines where the Invoice is Unavailable/Missing">
								<Toggle
									icons={{
										checked: <span style={{ color: "white" }}>{STATUS_ICON.U}</span>,
										unchecked: STATUS_ICON.U,
									}}
									id="unavailable"
									name="unavailable"
									className={STATUS_CLASS_NAME.U}
									checked={context.filter.unavailable}
									onChange={e => context.setFilterItem(e)}
								/>
								<span title="Number of Ledger items with missing Expenses">{counts.ledgerUnavailableCount}</span>
							</div>
						</div>
					)
				}}
			</VouchingDetailsContext.Consumer>
		)
	}
}
