import React from "react"

import { call, put, takeLatest } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"
import * as clearview from "../../components/@Clearview"

//Worker Functions
function* createPeriodEnd(action) {
	try {
		const periodEnd = yield call(Api.createPeriodEnd, action.propertyId, action.templateId, action.endDate)

		yield put({
			type: ACTIONS.PERIODEND_CREATED,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
			// type: ACTIONS.CUSTOMER_FETCHED,
			// templates: customer.templates,
			// receivedAt: Date.now()
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODEND_CREATE_FAILED,
			message: e.message,
		})
	}
}

function* createPeriodEnds(action) {
	try {
		const periodEnds = []

		let i = 0
		for (let periodEnd of action.periodEnds) {
			i++
			if (i % 10 === 0)
				clearview.ShowToast(
					"Info",
					<div>
						<p>
							Creating Period End {i} of {action.periodEnds.length}.
						</p>
						<p>Please wait...</p>
					</div>
				)
			periodEnds.push(yield call(Api.createPeriodEnd, periodEnd.property.id, periodEnd.template.id, periodEnd.endDate))
		}

		yield put({
			type: ACTIONS.PERIODENDS_CREATED,
			periodEnds: periodEnds,
			receivedAt: Date.now(),
			// type: ACTIONS.CUSTOMER_FETCHED,
			// templates: customer.templates,
			// receivedAt: Date.now()
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODENDS_CREATE_FAILED,
			message: e.message,
		})
	}
}

export default function* propertyNewPeriodEnd() {
	yield takeLatest(ACTIONS.PERIODEND_CREATE, createPeriodEnd)
	yield takeLatest(ACTIONS.PERIODENDS_CREATE, createPeriodEnds)
}
