import React from "react"

import { call, put, takeLatest } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"
import * as clearview from "../../components/@Clearview"

//Worker Functions
function* fetchClients(action) {
	try {
		const clients = yield call(Api.fetchClients)
		yield put({
			type: ACTIONS.CLIENTS_FETCHED,
			clients: clients,
			receivedAt: Date.now(),
		})

		if (clients && clients.length) {
			const range = clients
				.filter(it => it.type === "Property")
				.reduce(
					(acc, next) => ({
						lft: Math.min(acc.lft || next.lft, next.lft),
						rgt: Math.max(acc.rgt || next.rgt, next.rgt),
					}),
					{ lft: 0, rgt: 0 }
				)

			yield put({
				type: ACTIONS.PERIODENDS_ALL_FETCH,
				lft: range.lft,
				rgt: range.rgt,
			})
		}
	} catch (e) {
		yield put({
			type: ACTIONS.CLIENTS_FETCH_FAILED,
			message: e.message,
		})
	}
}

function* addClient(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const client = yield call(Api.addClient, action.parent, action.client)

		yield put({
			type: ACTIONS.CLIENT_ADDED,
			client: client,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.CLIENT_ADD_FAILED,
			message: e.message,
		})
	}
}

function* addClients(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const clients = []

		let i = 0
		for (let client of action.clients) {
			i++
			if (i % 10 === 0)
				clearview.ShowToast(
					"Info",
					<div>
						<p>
							Adding client {i} of {action.clients.length}.
						</p>
						<p>Please wait...</p>
					</div>
				)
			clients.push(yield call(Api.addClient, client.parent, client))
		}

		yield put({
			type: ACTIONS.CLIENTS_ADDED,
			clients: clients,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.CLIENTS_ADD_FAILED,
			message: e.message,
		})
	}
}

function* moveClients(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const moves = action.payload.moves.map(it => ({ id: it.move.id, parentId: it.under.id }))

		yield call(Api.moveClients, moves)

		yield put({
			type: ACTIONS.CLIENTS_MOVED_CLIENTS,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.CLIENTS_MOVE_CLIENTS_FAILED,
			message: e.message,
		})
	}
}

function* updateClient(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const client = yield call(Api.updateClient, action.client)

		yield put({
			type: ACTIONS.CLIENT_UPDATED,
			client: client,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.CLIENT_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* removeClient(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		yield call(Api.removeClient, action.client)

		yield put({
			type: ACTIONS.CLIENT_REMOVED,
			client: action.client,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.CLIENT_REMOVE_FAILED,
			message: e.message,
		})
	}
}

function* addProperty(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const property = yield call(Api.addProperty, action.parent, action.property)

		yield put({
			type: ACTIONS.PROPERTY_ADDED,
			property: property,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PROPERTY_ADD_FAILED,
			message: e.message,
		})
	}
}

function* addProperties(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const properties = []

		let i = 0
		for (let property of action.properties) {
			i++
			if (i % 10 === 0)
				clearview.ShowToast(
					"Info",
					<div>
						<p>
							Adding property {i} of {action.properties.length}.
						</p>
						<p>Please wait...</p>
					</div>
				)
			properties.push(yield call(Api.addProperty, property.parent, property))
		}

		yield put({
			type: ACTIONS.PROPERTIES_ADDED,
			properties: properties,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PROPERTIES_ADD_FAILED,
			message: e.message,
		})
	}
}

function* updateProperty(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const property = yield call(Api.updateProperty, action.property)

		yield put({
			type: ACTIONS.PROPERTY_UPDATED,
			property: property,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PROPERTY_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* removeProperty(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		yield call(Api.removeProperty, action.property)

		yield put({
			type: ACTIONS.PROPERTY_REMOVED,
			property: action.property,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PROPERTY_REMOVE_FAILED,
			message: e.message,
		})
	}
}

function* rebuildClients() {
	try {
		const clientsRebuilt = yield call(Api.rebuildClients)
		yield put({
			type: "SUCCESS",
			message: `Number of Clients Rebuilt: ${clientsRebuilt}`,
		})
	} catch (e) {
		yield put({
			type: ACTIONS.CLIENTS_REBUILD_FAILED,
			message: e.message,
		})
	}
}

export default function* clientsSaga() {
	yield takeLatest(ACTIONS.CLIENTS_FETCH, fetchClients)
	yield takeLatest(ACTIONS.CLIENT_ADD, addClient)
	yield takeLatest(ACTIONS.CLIENTS_ADD, addClients)
	yield takeLatest(ACTIONS.CLIENT_UPDATE, updateClient)
	yield takeLatest(ACTIONS.CLIENT_REMOVE, removeClient)
	yield takeLatest(ACTIONS.CLIENTS_MOVE_CLIENTS, moveClients)
	yield takeLatest(ACTIONS.PROPERTY_ADD, addProperty)
	yield takeLatest(ACTIONS.PROPERTIES_ADD, addProperties)
	yield takeLatest(ACTIONS.PROPERTY_UPDATE, updateProperty)
	yield takeLatest(ACTIONS.PROPERTY_REMOVE, removeProperty)
	yield takeLatest(ACTIONS.CLIENTS_REBUILD, rebuildClients)
}
