import { connect } from "react-redux"
import React from "react"
import classNames from "classnames"

import { Nav, NavItem } from "../../../../components"

import PropTypes from "prop-types"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"
class CustomerSelector extends React.Component {
	static propTypes = {
		context: PropTypes.object.isRequired,
		customerTemplates: PropTypes.object,
	}

	render() {
		const { context, customerTemplates } = this.props

		const customerRefs = Object.keys(customerTemplates)

		const selectedCustomerRef = context.state.filter.customer?.reference

		return (
			<Nav pills className="mb-0 mt-0 mt-lg-0" style={{ width: 525 }}>
				{customerRefs.length > 1 &&
					customerRefs.reverse().map(customerRef => (
						<NavItem key={customerRef} title={customerTemplates[customerRef].name}>
							<a
								href="#"
								className={classNames({
									active: customerRef === selectedCustomerRef,
									"nav-link": true,
								})}
								onClick={e => context.handleChange([customerTemplates[customerRef]], "customer")}
							>
								{customerTemplates[customerRef].name}
							</a>
						</NavItem>
					))}
				{selectedCustomerRef && customerRefs.length > 1 && (
					<NavItem key="~all" title="All">
						<a
							href="#"
							className={classNames({
								active: !selectedCustomerRef,
								"nav-link": true,
							})}
							onClick={e => context.handleChange([], "customer")}
						>
							{clearview.Icon.clear}
						</a>
					</NavItem>
				)}
			</Nav>
		)
	}
}

const mapStateToProps = state => {
	const customerTemplates = ACTIONS.getSubState(state, "clientsReducer\\templates")

	return {
		customerTemplates: customerTemplates.dict,
	}
}

CustomerSelector = connect(mapStateToProps)(CustomerSelector)
export { CustomerSelector }
