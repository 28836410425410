import React from "react"

export function AddressBlock(addressWithCommas, postcode = null) {
	const splitAddress = (addressWithCommas || "").split(",\n")
	if (postcode) splitAddress.push(postcode)

	if (splitAddress.length)
		return (
			<p>
				{splitAddress.map((it, idx) => (
					<span key={idx}>
						{it}
						<br />
					</span>
				))}
			</p>
		)

	return null
}
