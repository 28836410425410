import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import PropTypes from "prop-types"

import { Button } from "./../../../../components"

class PeriodEndQueriesExport extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		buttonStyle: PropTypes.object,
	}

	render() {
		const { periodEnd } = this.props

		if (!periodEnd) return null

		const allQueries = periodEnd.stages.flatMap(st => st.comments.filter(it => ["Query", "DraftQuery", "ClosedQuery"].includes(it.type)))

		const allReplies = allQueries.flatMap(q => [
			{
				uniq: `Q:${q.id}`,
				type: q.type,
				reference: q.reference,
				number: `Q${q.number}`,
				subject: q.subject,
				title: q.title,
				comments: q.comments,
				createdBy: q.createdBy,
				createdAt: q.createdAt,
				assignedTo: q.assignedTo,
				stage: q.stage,
			},
			...q.replies.map(r => ({
				uniq: `Q~${q.id}~R~${r.id}`,
				...r,
				reference: q.reference,
				number: `Q${q.number}`,
				subject: q.subject,
				title: q.title,
				assignedTo: q.assignedTo,
				stage: q.stage,
			})),
		])

		return (
			<ToolkitProvider
				exportCSV={{ fileName: `${periodEnd.reference}_Queries.csv` }}
				data={allReplies}
				keyField="uniq"
				columns={[
					{ dataField: "stage.periodEnd.reference", text: "Period End", csvFormatter: (col, row) => col || row.stage.periodEnd.reference },
					{ dataField: "number", text: "Number" },
					{ dataField: "reference", text: "Reference" },
					{ dataField: "createdAt", text: "At" },
					{ dataField: "createdBy.name", text: "By" },
					{ dataField: "subject", text: "Subject" },
					{ dataField: "title", text: "Title" },
					{ dataField: "type", text: "Type" },
					{ dataField: "comments", text: "Text" },
					{ dataField: "assignedTo.name", text: "Assigned To" },

					{ dataField: "stage.periodEnd.template.name", text: "Template", csvFormatter: (col, row) => col || row.stage.periodEnd.template.name },
					{
						dataField: "stage.periodEnd.property.reference",
						text: "Property Ref.",
						csvFormatter: (col, row) => col || row.quy.stage.periodEnd.property.reference,
					},
					{ dataField: "stage.periodEnd.property.name", text: "Property", csvFormatter: (col, row) => col || row.stage.periodEnd.property.name },
					{
						dataField: "stage.periodEnd.property.parent.reference",
						text: "Client/Landlord Ref.",
						csvFormatter: (col, row) => col || row.stage.periodEnd.property.parent.reference,
					},
					{
						dataField: "stage.periodEnd.property.parent.name",
						text: "Client/Landlord Name",
						csvFormatter: (col, row) => col || row.stage.periodEnd.property.parent.name,
					},

					{ dataField: "stage.templateStage.sequence", text: "Stage #", csvFormatter: (col, row) => col || row.stage.templateStage.sequence },
					{ dataField: "stage.templateStage.name", text: "Stage", csvFormatter: (col, row) => col || row.stage.templateStage.name },
					{ dataField: "stage.status", text: "Stage Status", csvFormatter: (col, row) => col || row.stage.status },
					{ dataField: "stage.rag", text: "Stage Rag", csvFormatter: (col, row) => col || row.stage.rag },
					{
						dataField: "stage.periodEnd.property.actors.inHouseContact.name",
						text: "In House Contact",
						csvFormatter: (col, row) => (row.stage.periodEnd.property.actors.inHouseContact || {}).name,
					},
					{
						dataField: "stage.periodEnd.property.actors.clientContact.name",
						text: "Client Contact",
						csvFormatter: (col, row) => (row.stage.periodEnd.property.actors.clientContact || {}).name,
					},
					{ dataField: "stage.dueDate", text: "Stage Due", csvFormatter: (col, row) => col || row.stage.dueDate },
				]}
			>
				{props => (
					<React.Fragment>
						<Button
							color="secondary"
							disabled={allQueries.length === 0}
							onClick={() => {
								props.csvProps.onExport()
							}}
							style={this.props.buttonStyle}
							title="Export all Queries and Responses"
						>
							<i className="fa fa-download mr-2"></i>
							{allQueries.length} Queries
						</Button>
						<BootstrapTable classes="hidden" {...props.baseProps} />
					</React.Fragment>
				)}
			</ToolkitProvider>
		)
	}
}

export { PeriodEndQueriesExport }
