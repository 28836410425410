import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"

import { Row, Col, Card, CardBody, CardTitle, ButtonGroup } from "../../../../components"

import { CustomExportCSV } from "./CustomExportButton"

import * as ACTIONS from "../../../../store/actions"

class PeriodEndReports extends Component {
	static propTypes = {
		periodEnds: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
	}

	render() {
		var periodEndUsers = this.props.periodEnds
			.filter(it => it.property.teams)
			.flatMap(it => ({ periodEnd: it, users: it.property.teams.flatMap(t => t.users) }))
			.flatMap(it => it.users.map(u => ({ periodEnd: it.periodEnd, user: u, key: `${it.periodEnd.id}:${u.username}` })))

		return (
			<React.Fragment>
				<Row>
					<Col lg={4}>
						<Card className="mb-3">
							<CardBody>
								<CardTitle tag="h6">
									Period End Report<span className="small ml-1 text-muted">#PE.01</span>
								</CardTitle>
								<p>All Period Ends</p>

								<ToolkitProvider
									exportCSV={{ fileName: "PeriodEnds.csv" }}
									data={this.props.periodEnds}
									keyField="id"
									columns={[
										{ dataField: "reference", text: "Ref." },

										{ dataField: "template.name", text: "Template" },
										{ dataField: "property.reference", text: "Property Ref." },

										{ dataField: "endDate", text: "Period End", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
										{ dataField: "dueDate", text: "Due Date", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
										{ dataField: "projectedEndDate", text: "Expected", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },

										{ dataField: "property.name", text: "Property" },
										{ dataField: "property.parent.reference", text: "Client/Landlord Ref." },
										{ dataField: "property.parent.name", text: "Client/Landlord Name" },

										{ dataField: "property.actors.inHouseContact.name", text: "In House Contact" },
										{ dataField: "property.actors.clientContact.name", text: "Client Contact" },

										{ dataField: "currentStage.templateStage.sequence", text: "Stage #" },
										{ dataField: "currentStage.templateStage.name", text: "Stage" },
										{ dataField: "currentStage.status", text: "Stage Status" },
										{ dataField: "currentStage.rag", text: "Stage Rag" },
										{ dataField: "currentStage.dueDate", text: "Stage Due", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
										{ dataField: "fee", text: "Fee", csvFormatter: (col, row) => col / 100 },
										{ dataField: "expenditureFinal", text: "Final Expenditure", csvFormatter: (col, row) => col / 100 },
										{ dataField: "expenditureBudget", text: "Budgeted Expenditure", csvFormatter: (col, row) => col / 100 },
										{ dataField: "doNotRenew", text: "Do Not Renew" },
										{ dataField: "isPublished", text: "Published" },
									]}
								>
									{props => (
										<React.Fragment>
											<div className="d-flex justify-content-end align-items-center mb-2">
												<div className="d-flex ml-auto">
													<ButtonGroup>
														<CustomExportCSV color="secondary" {...props.csvProps}>
															<i className="fa fa-download mr-2"></i>Download
														</CustomExportCSV>
													</ButtonGroup>
												</div>
											</div>
											<BootstrapTable classes="hidden" {...props.baseProps} />
										</React.Fragment>
									)}
								</ToolkitProvider>
							</CardBody>
						</Card>
					</Col>

					<Col lg={4}>
						<Card className="mb-3">
							<CardBody>
								<CardTitle tag="h6">
									Open Stages Report<span className="small ml-1 text-muted">#PE.02</span>
								</CardTitle>
								<p>Open Period Ends and their Current Stage</p>

								<ToolkitProvider
									exportCSV={{ fileName: "OpenStages.csv" }}
									data={this.props.periodEnds.filter(it => it.currentStage)}
									keyField="id"
									columns={[
										{ dataField: "reference", text: "Ref." },

										{ dataField: "template.name", text: "Template" },
										{ dataField: "property.reference", text: "Property Ref." },

										{ dataField: "endDate", text: "Period End", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
										{ dataField: "dueDate", text: "Due Date", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
										{ dataField: "projectedEndDate", text: "Expected", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },

										{ dataField: "property.name", text: "Property" },
										{ dataField: "property.parent.reference", text: "Client/Landlord Ref." },
										{ dataField: "property.parent.name", text: "Client/Landlord Name" },

										{ dataField: "property.actors.inHouseContact.name", text: "In House Contact" },
										{ dataField: "property.actors.clientContact.name", text: "Client Contact" },

										{ dataField: "currentStage.templateStage.sequence", text: "Stage #" },
										{ dataField: "currentStage.templateStage.name", text: "Stage" },
										{ dataField: "currentStage.status", text: "Stage Status" },
										{ dataField: "currentStage.rag", text: "Stage Rag" },
										{ dataField: "currentStage.dueDate", text: "Stage Due", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
										{ dataField: "fee", text: "Fee", csvFormatter: (col, row) => col / 100 },
										{ dataField: "expenditureFinal", text: "Final Expenditure", csvFormatter: (col, row) => col / 100 },
										{ dataField: "expenditureBudget", text: "Budgeted Expenditure", csvFormatter: (col, row) => col / 100 },
										{ dataField: "doNotRenew", text: "Do Not Renew" },
										{ dataField: "isPublished", text: "Published" },
									]}
								>
									{props => (
										<React.Fragment>
											<div className="d-flex justify-content-end align-items-center mb-2">
												<div className="d-flex ml-auto">
													<ButtonGroup>
														<CustomExportCSV color="secondary" {...props.csvProps}>
															<i className="fa fa-download mr-2"></i>Download
														</CustomExportCSV>
													</ButtonGroup>
												</div>
											</div>
											<BootstrapTable classes="hidden" {...props.baseProps} />
										</React.Fragment>
									)}
								</ToolkitProvider>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Row>
					<Col lg={4}>
						<Card className="mb-3">
							<CardBody>
								<CardTitle tag="h6">
									Assigned Users Report<span className="small ml-1 text-muted">#PE.03</span>
								</CardTitle>
								<p>Period Ends and their Assigned Users at the Property Level</p>

								<ToolkitProvider
									exportCSV={{ fileName: "PeriodEndAssignedUsers.csv" }}
									data={periodEndUsers}
									keyField="key"
									columns={[
										{ dataField: "periodEnd.reference", text: "Ref." },

										{ dataField: "periodEnd.template.name", text: "Template" },
										{ dataField: "periodEnd.property.reference", text: "Property Ref." },

										{ dataField: "periodEnd.endDate", text: "Period End", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
										{ dataField: "periodEnd.dueDate", text: "Due Date", csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD") },
										{
											dataField: "periodEnd.projectedEndDate",
											text: "Expected",
											csvFormatter: (col, row) => moment(col).format("YYYY-MM-DD"),
										},

										{ dataField: "periodEnd.property.name", text: "Property" },
										{ dataField: "periodEnd.property.parent.reference", text: "Client/Landlord Ref." },
										{ dataField: "periodEnd.property.parent.name", text: "Client/Landlord Name" },

										{ dataField: "user.username", text: "Username" },
										{ dataField: "user.name", text: "Name" },
										{ dataField: "user.role", text: "Role" },
									]}
								>
									{props => (
										<React.Fragment>
											<div className="d-flex justify-content-end align-items-center mb-2">
												<div className="d-flex ml-auto">
													<ButtonGroup>
														<CustomExportCSV color="secondary" {...props.csvProps}>
															<i className="fa fa-download mr-2"></i>Download
														</CustomExportCSV>
													</ButtonGroup>
												</div>
											</div>
											<BootstrapTable classes="hidden" {...props.baseProps} />
										</React.Fragment>
									)}
								</ToolkitProvider>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, { location }) => {
	const subState = ACTIONS.getSubState(state, "clientsReducer\\periodEnds")
	return {
		periodEnds: ACTIONS.dictionaryToArray(subState.dict),
		user: state.userReducer.user,
	}
}

PeriodEndReports = connect(mapStateToProps)(PeriodEndReports)

export { PeriodEndReports }
