import React from "react"
import PropTypes from "prop-types"

import { Button, Card, CardHeader, CardTitle } from "../../../../components"
import S3Popup from "../../components/s3/S3Popup"

import * as Api from "../../../../api/CustomerApi"
import * as clearview from "../../../../components/@Clearview"

import { isInvoiceFile } from "./common"

export class Column extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		isReadOnly: PropTypes.bool,
		id: PropTypes.string,
		index: PropTypes.number,
		label: PropTypes.string,
		title: PropTypes.string,
		size: PropTypes.number,
		folder: PropTypes.string, //Specify an S3 folder
		action: PropTypes.func, //Or a main action
		actions: PropTypes.node,
		sortSelector: PropTypes.node,
	}

	constructor(props) {
		super(props)

		this.state = {
			expensesFilesChanged: false,
		}

		this.onOpenedExpensesFiles = this.onOpenedExpensesFiles.bind(this)
		this.onChangeExpensesFiles = this.onChangeExpensesFiles.bind(this)
		this.onClosedExpensesFiles = this.onClosedExpensesFiles.bind(this)
	}

	onOpenedExpensesFiles() {
		this.setState({ expensesFilesChanged: false })
	}

	onChangeExpensesFiles(action, files) {
		if (files.find(it => isInvoiceFile(it))) this.setState({ expensesFilesChanged: true })
	}

	onClosedExpensesFiles() {
		if (this.state.expensesFilesChanged) {
			Api.processFiles()
			clearview.ShowToast("info", <p>Your Invoice file changes will be available here in a few minutes.</p>)
		}
	}

	render() {
		const { children, label, title, size, folder, action, actions, searchSelector, sortSelector } = this.props

		return (
			<div className={`col-md-${size}`}>
				<Card className="h-100">
					<CardHeader className="b-0 bg-none pl-1 pr-0">
						<CardTitle className="h5 mb-0 d-flex-column">
							<div className="d-flex-row justify-content-between align-items-center">
								<div className="flex-1 justify-content-start">
									{folder && (
										<S3Popup
											folder={folder}
											title={title}
											name={label}
											color="primary"
											className="ml-0 mr-2"
											isReadOnly={this.props.isReadOnly}
											onChange={this.onChangeExpensesFiles}
											onClosed={this.onClosedExpensesFiles}
											onOpened={this.onOpenedExpensesFiles}
										>
											<span className="mr-1">{clearview.Icon.attachments}</span>
											<span>{label}...</span>
										</S3Popup>
									)}
									{action && (
										<Button
											size="sm"
											title={title}
											color="primary"
											className="ml-0 mr-2"
											disabled={this.props.isReadOnly}
											onClick={e => action(e)}
										>
											<span className="mr-1">{clearview.Icon.upload}</span>
											<span>{label}...</span>
										</Button>
									)}
									{!this.props.isReadOnly && actions}
								</div>
								{searchSelector}
							</div>
							<div className="mt-3 mb-0">{sortSelector}</div>
						</CardTitle>
					</CardHeader>
					{children}
				</Card>
			</div>
		)
	}
}
