import React from "react"
import { useLoaderData } from "react-router-dom"
import PropTypes from "prop-types"
import { Prompt } from "../../../../components"

import { connect } from "react-redux"
import Dropdown from "react-bootstrap/Dropdown"

import { WP_ACTIONS } from "./store"

import { PeriodEndUnread } from "../components/PeriodEndUnread"
import { PeriodEndFavourite } from "../components/PeriodEndFavourite"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"
import { AnimatedProgressBar } from "../../../components/AnimatedProgressBar"
import { Spinner } from "../../../components/Spinner"
import S3ChooseFromBranch from "../../components/s3/S3ChooseFromBranch"

import { Container, Row, Col, Card, CardHeader, CardBody, UncontrolledTabs, Nav, NavItem, TabPane } from "../../../../components"

import { HeaderMain } from "../../../components/HeaderMain"

import FeeMatrixPage from "./FeeMatrixPage"
import IncomeReportPage from "./IncomeReportPage"
import { ClientExpenditurePage, AdjustedExpenditurePage } from "./ExpenditureReportPages"
import BudgetsPage from "./BudgetsPage"
import ComparativesPage from "./ComparativesPage"
import DRsAndPrepayPage from "./DRsAndPrepayPage"
import CRsAndAccrualsPage from "./CRsAndAccrualsPage"
import JournalsPage from "./JournalsPage"
import ETBPage from "./ETBPage"

import AccountsPage from "./AccountsPage"
import { EditorTabs_NavItems, EditorTabs_TabPanes } from "./Report/EditorTabs"

class WorkingPapersComponent extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		periodEnd: PropTypes.object.isRequired,
		workingPapers: PropTypes.object.isRequired,
		reports: PropTypes.array.isRequired,
		report: PropTypes.object.isRequired,
		load: PropTypes.func.isRequired,
		onSave: PropTypes.func.isRequired,
		onShowChooseTemplate: PropTypes.func.isRequired,
		onSelectTemplate: PropTypes.func.isRequired,
		onLoadReport: PropTypes.func.isRequired,
		onUpdateTemplate: PropTypes.func.isRequired,
		ui: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
		this.loadWorkingPapers = this.loadWorkingPapers.bind(this)
		this.onSave = this.onSave.bind(this)
		this.onCancel = this.onCancel.bind(this)
	}

	workingPapersRootUrl() {
		return `periodEnd/${this.props.periodEnd.id}`
	}

	loadWorkingPapers() {
		this.props.load(this.props.periodEnd)
	}

	onSave() {
		let saveReportAs =
			this.props.report?.fileInfo && !this.props.report.fileInfo.key
				? prompt("Please specify a name for the report", this.props.report.fileInfo.scope)
				: undefined
		this.props.onSave(this.props.periodEnd, this.props.workingPapers, this.props.report, saveReportAs, this.props.ui.isChanged)
	}

	onCancel() {
		if (window.confirm(`Are you sure you wish to undo the unsaved changes you have made?`)) {
			this.loadWorkingPapers()
		}
	}

	render() {
		const { user, periodEnd, workingPapers, reports, report } = this.props
		const { isBusy, isChanged, isImporting, isLoading, isShowChooseTemplate } = this.props.ui
		const { onShowChooseTemplate, onSelectTemplate, onLoadReport, onUpdateTemplate, onUpdateParameters } = this.props

		if (!periodEnd.id) {
			return <Spinner />
		}

		if (!workingPapers || !workingPapers.periodEnd || workingPapers.periodEnd.id !== periodEnd.id) {
			if (!isLoading) {
				this.loadWorkingPapers()
			}
			return <Spinner />
		}

		return (
			<React.Fragment>
				<Container className={isBusy || isImporting ? "isBusy full-width working-papers" : "full-width working-papers"}>
					{isBusy && <Spinner key="spinner"></Spinner>}
					<AnimatedProgressBar isVisible={isImporting}></AnimatedProgressBar>
					<Prompt
						when={isChanged}
						message={() =>
							"You have made changes to the Working Papers data.\r\rAre you sure you want to leave this page and discard your changes?"
						}
						onLeave={this.loadWorkingPapers}
					/>
					<HeaderMain
						superTitle={
							<span>
								<PeriodEndUnread periodEnd={periodEnd} />
								{periodEnd.reference}
								<sup>
									<PeriodEndFavourite periodEnd={periodEnd} />
								</sup>
							</span>
						}
						title={
							<span className="text-primary d-flex-row justify-content-start">
								<span title="Working Papers" style={{ fontSize: "0.8em", lineHeight: "0.8em", marginTop: "0.3em", marginRight: "0.2em" }}>
									{clearview.Icon.workingPapers}
								</span>
								<span>{periodEnd.property?.name}</span>
							</span>
						}
						subTitle={<span className="text-primary">Working Papers: {(periodEnd.template || {}).name}</span>}
						className="mb-4 mt-0"
					/>
					<Row>
						<Col lg={12}>
							<UncontrolledTabs initialActiveTabId="accountsTab">
								<Card>
									<CardHeader className="d-flex-row justify-content-between">
										<div className="flex-1 mr-4 align-self-center">
											<Nav pills className="mb-0 flex-column flex-md-row mt-0 mt-lg-0" id="workingPapersTabs0">
												<NavItem className="text-orange">
													<UncontrolledTabs.NavLink tabId="etbTab">ETB</UncontrolledTabs.NavLink>
												</NavItem>
												<NavItem className="text-orange">
													<UncontrolledTabs.NavLink tabId="journalsTab">Journals</UncontrolledTabs.NavLink>
												</NavItem>
												<NavItem className="text-orange">
													<UncontrolledTabs.NavLink tabId="crsAndAccrualsTab">CRs & Accruals</UncontrolledTabs.NavLink>
												</NavItem>
												<NavItem className="text-orange">
													<UncontrolledTabs.NavLink tabId="drsAndPrepayTab">DRs & Prepay</UncontrolledTabs.NavLink>
												</NavItem>
												{user.isInHouse && (
													<NavItem className="text-red">
														<UncontrolledTabs.NavLink tabId="budgetsTab">
															<span title="Only visible to In-House users">Budgets</span>
														</UncontrolledTabs.NavLink>
													</NavItem>
												)}
												{user.isInHouse && (
													<NavItem className="text-red">
														<UncontrolledTabs.NavLink tabId="comparativesTab">
															<span title="Only visible to In-House users">Comparatives</span>
														</UncontrolledTabs.NavLink>
													</NavItem>
												)}
												<NavItem className="text-red">
													<UncontrolledTabs.NavLink tabId="feeMatrixTab">
														Calc{clearview.messageIcons(workingPapers?.fees?.messages)}
													</UncontrolledTabs.NavLink>
												</NavItem>
												<NavItem className="text-pink">
													<UncontrolledTabs.NavLink tabId="clientExpenditureTab">
														Client&nbsp;Expenditure{clearview.messageIcons(workingPapers?.expenditureReport?.messages)}
													</UncontrolledTabs.NavLink>
												</NavItem>
												<NavItem className="text-pink">
													<UncontrolledTabs.NavLink tabId="adjustedExpenditureTab">
														<span title="Adjusted Expenditure">Adj.&nbsp;Expenditure</span>
													</UncontrolledTabs.NavLink>
												</NavItem>
												<NavItem className="text-pink">
													<UncontrolledTabs.NavLink tabId="incomeReportTab">Income</UncontrolledTabs.NavLink>
												</NavItem>
											</Nav>
											<Nav pills className="mb-0 flex-column flex-md-row mt-0 mt-lg-0" id="reports">
												<NavItem key="reports">
													<ReportsMenu
														reports={reports}
														report={report}
														isReadOnly={periodEnd.status === "Closed"}
														onSelect={onLoadReport}
														onNew={e => onShowChooseTemplate(true)}
													/>
												</NavItem>
												<EditorTabs_NavItems report={report} />

												<NavItem className="text-primary" title="Accounts Folder">
													<UncontrolledTabs.NavLink tabId="accountsTab">
														<span className="text-primary">{clearview.Icon.folderOpen} Accounts</span>
													</UncontrolledTabs.NavLink>
												</NavItem>
											</Nav>
										</div>
										{periodEnd.status !== "Closed" && (
											<div className="d-flex-row justify-content-end" style={{ alignItems: "center" }}>
												<EditSaveOrCancel
													key="editSaveOrCancel"
													isReadOnly={periodEnd.status === "Closed"}
													isEditing={periodEnd.status !== "Closed"}
													isChanged={!!isChanged}
													onSave={this.onSave}
													onCancel={this.onCancel}
												/>
											</div>
										)}
									</CardHeader>
									<CardBody>
										<UncontrolledTabs.TabContent>
											<TabPane tabId="etbTab" className="overflow-scroll-both">
												<ETBPage
													isReadOnly={periodEnd?.status === "Closed"}
													isChanged={isChanged?.workingPapers}
													periodEnd={periodEnd}
												/>
											</TabPane>
											<TabPane tabId="journalsTab">
												<JournalsPage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="crsAndAccrualsTab">
												<CRsAndAccrualsPage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="drsAndPrepayTab">
												<DRsAndPrepayPage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="budgetsTab">
												<BudgetsPage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="comparativesTab">
												<ComparativesPage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="feeMatrixTab">
												<FeeMatrixPage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="clientExpenditureTab">
												<ClientExpenditurePage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="adjustedExpenditureTab">
												<AdjustedExpenditurePage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="incomeReportTab">
												<IncomeReportPage isReadOnly={periodEnd?.status === "Closed"} />
											</TabPane>
											<TabPane tabId="accountsTab">
												<AccountsPage periodEnd={periodEnd} />
											</TabPane>
											<EditorTabs_TabPanes
												config={workingPapers.config}
												report={report}
												onAfterSaveReport={e => alert("onAfterSaveReport")}
												onUpdateTemplate={onUpdateTemplate}
												onUpdateParameters={onUpdateParameters}
											/>
										</UncontrolledTabs.TabContent>
									</CardBody>
								</Card>
							</UncontrolledTabs>
						</Col>
					</Row>
					<S3ChooseFromBranch
						isOpen={!!isShowChooseTemplate}
						periodEnd={periodEnd}
						title="Select template..."
						flavour="working-papers-report-template"
						onCancel={e => onShowChooseTemplate(false)}
						onSelect={fileInfo => onSelectTemplate(periodEnd, fileInfo)}
					/>
				</Container>
			</React.Fragment>
		)
	}
}

function WorkingPapers({
	user,
	ui,
	workingPapers,
	reports,
	report,
	load,
	onSave,
	onShowChooseTemplate,
	onSelectTemplate,
	onLoadReport,
	onUpdateTemplate,
	onUpdateParameters,
}) {
	const periodEnd = useLoaderData()
	return (
		<WorkingPapersComponent
			user={user}
			periodEnd={periodEnd}
			workingPapers={workingPapers}
			reports={reports}
			report={report}
			load={load}
			onSave={onSave}
			onShowChooseTemplate={onShowChooseTemplate}
			onSelectTemplate={onSelectTemplate}
			onLoadReport={onLoadReport}
			onUpdateTemplate={onUpdateTemplate}
			onUpdateParameters={onUpdateParameters}
			ui={ui}
		/>
	)
}

const mapStateToProps = state => {
	const workingPapersState = ACTIONS.getSubState(state, "workingPapersReducer")
	return {
		...workingPapersState,
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

const mapDispatchToProps = dispatch => ({
	load: periodEnd => {
		dispatch({ type: WP_ACTIONS.LOAD, periodEnd })
	},
	onSave: (periodEnd, workingPapers, report, saveReportAs, isChanged) => {
		dispatch({ type: WP_ACTIONS.SAVE, periodEnd, workingPapers, report, saveReportAs, isChanged })
	},
	onShowChooseTemplate: showHide => {
		dispatch({ type: WP_ACTIONS.SHOW_CHOOSE_TEMPLATE, showHide })
	},
	onSelectTemplate: (periodEnd, fileInfo) => {
		dispatch({ type: WP_ACTIONS.SELECT_TEMPLATE, periodEnd, fileInfo })
	},
	onLoadReport: fileInfo => {
		dispatch({ type: WP_ACTIONS.LOAD_REPORT, fileInfo })
	},
	onUpdateTemplate: template => {
		dispatch({ type: WP_ACTIONS.UPDATE_REPORT_TEMPLATE, template })
	},
	onUpdateParameters: parameters => {
		dispatch({ type: WP_ACTIONS.UPDATE_REPORT_PARAMETERS, parameters })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkingPapers)

function ReportsMenu({ reports, report, isReadOnly, onSelect, onNew }) {
	if (!reports.length && isReadOnly) return
	const menuItems = reports.map(it => ({ isSelected: report?.fileInfo?.scope === it.scope, label: it.scope, action: onSelect, ...it }))
	if (report.fileInfo && !menuItems.find(it => it.isSelected)) menuItems.push({ isSelected: true, label: report.fileInfo.scope, action: onSelect })
	if (!isReadOnly) menuItems.push({ icon: "new", label: "New report...", action: onNew })

	const title = menuItems.find(it => it.isSelected)?.label ?? "Select report"

	return (
		<Dropdown hidden={false} className="align-self-center ml-0" direction="left">
			<Dropdown.Toggle disabled={!!report?.isChanged} className="pl-0 text-info" variant="none" onToggle={clearview.StopPropagation}>
				{title}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{menuItems.map((it, idx) => (
					<Dropdown.Item key={`opt_${idx}`}>
						<button className="tight" onClick={e => it.action(it)}>
							{clearview.Icon.SelectedForEditing[it.icon ?? it.isSelected]}
							<span className="ml-1">{it.label}</span>
						</button>
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	)
}
