import React from 'react';
import PropTypes from 'prop-types'

import { 
    Form,
    UncontrolledModal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '../../components';


export default class AreYouSure extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        message: PropTypes.node.isRequired,
        action: PropTypes.func.isRequired
    }

    render() {

        const { id, message, action } = this.props

        return (
            <UncontrolledModal target={id}>
            
        <Form onSubmit={this.handleSubmit}>

            <ModalHeader tag="div">
                <div className="d-flex">
                    <h1 className="text-primary mr-2"><i className="fa fa-fw fa-question-circle-o"></i></h1>
                    <h6>{message}</h6>
                </div>
            </ModalHeader>

            <ModalBody>

            </ModalBody>

            <ModalFooter>
                <UncontrolledModal.Cancel color="link" className="text-primary">
                    Cancel
                </UncontrolledModal.Cancel>
                <UncontrolledModal.Close color="default" className="text-primary" a onClick={() => {action();return true;}}>
                    Ok
                </UncontrolledModal.Close>
            </ModalFooter>

        </Form>                            

    </UncontrolledModal>
)
    }
        

}

