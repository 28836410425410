import React from "react"

import { connect } from "react-redux"
import PropTypes from "prop-types"
import _ from "lodash"

import {
	Form,
	FormGroup,
	FormFeedback,
	Input,
	InputGroup,
	InputGroupAddon,
	UncontrolledModal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import CommentsEditor from "./CommentsEditor"
import { EventNameBadges } from "./CommentsTable"

class Comment_EditForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		comment: PropTypes.object.isRequired,
		updateComment: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			stage: props.stage,
			...props.comment,
			showValidation: true,
		}

		this.props = props

		this.handleTitleChange = this.handleTitleChange.bind(this)
		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleTitleChange(event) {
		this.setState({
			title: event.target.value,
		})
	}

	handleCommentsChange(value) {
		this.setState({
			comments: value,
		})
	}

	handleActionButtonClick(event) {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}

		this.props.updateComment({
			id: this.state.id,
			periodEnd: this.props.periodEnd,
			stage: this.state.stage,
			title: this.state.title,
			comments: this.state.comments,
		})
		return true
	}

	get isTitleRequired() {
		if (this.state.eventName !== "Comment") return false
		return !(this.state.title && this.state.title.length)
	}

	get isValid() {
		return !this.isTitleRequired
	}

	render() {
		const { comment } = this.props

		return (
			<UncontrolledModal style={{ maxWidth: 700 }} target={`comment_EditForm_${comment.id}`}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">Edit Comment</ModalHeader>

					<ModalBody>
						{this.state.eventName === "Comment" && (
							<FormGroup row>
								<InputGroup>
									<InputGroupAddon addonType="prepend">Title</InputGroupAddon>
									<Input
										invalid={this.state.showValidation && this.isTitleRequired}
										type="text"
										name="title"
										id="title"
										placeholder="Title..."
										value={this.state.title}
										onChange={this.handleTitleChange}
									/>
									<FormFeedback>A short title is required</FormFeedback>
								</InputGroup>
							</FormGroup>
						)}
						{this.state.eventName !== "Comment" && (
							<FormGroup row>
								<InputGroup>{EventNameBadges[this.state.eventName]}</InputGroup>
							</FormGroup>
						)}
						<FormGroup row>
							<CommentsEditor value={this.state.comments} onChange={this.handleCommentsChange} />
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close
							color="default"
							className="text-primary"
							disabled={!this.isValid}
							name="send"
							onClick={e => this.handleActionButtonClick(e)}
						>
							Save
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	updateComment: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE_COMMENT,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Comment_EditForm)
