import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Comment_EditForm from "./Comment_EditForm"
import AreYouSure from "../../../components/AreYouSure"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class CommentActions extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		comment: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props

		this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
	}

	handleDeleteClicked(e) {
		this.props.deleteComment({
			id: this.props.comment.id,
			periodEnd: this.props.periodEnd,
			stage: this.props.stage,
		})
	}

	render() {
		const { periodEnd, stage, comment } = this.props

		return (
			<React.Fragment>
				<Comment_EditForm target={`comment_EditForm_${comment.id}`} periodEnd={periodEnd} stage={stage} comment={comment} />

				<div className="CommentActions">
					<button
						hidden={!(comment.actions || []).includes("Update")}
						id={`comment_EditForm_${comment.id}`}
						className="text-secondary tight"
						title="Edit comment..."
					>
						{clearview.Icon.edit}
					</button>
					<button
						hidden={!(comment.actions || []).includes("Delete")}
						id={`comment_Delete_${comment.id}`}
						className="text-secondary tight"
						title="Delete comment..."
					>
						{clearview.Icon.delete}
					</button>
					{(comment.actions || []).includes("Delete") && (
						<AreYouSure
							id={`comment_Delete_${comment.id}`}
							message={
								<span>
									Permanently delete Comment <b className="nowrap">{comment.reference}</b> from{" "}
									<b className="nowrap">{stage.templateStage.name}</b>?
								</span>
							}
							action={() => this.handleDeleteClicked()}
						/>
					)}
				</div>
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	updateComment: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE_COMMENT,
			...payload,
		}),

	deleteComment: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_DELETE_COMMENT,
			...payload,
		}),
})

CommentActions = connect(null, mapDispatchToProps)(CommentActions)

export { CommentActions }
