import React from "react"

import { useLoaderData } from "react-router-dom"

import { PropertyCard } from "../../Properties/components/PropertyCard"
import { ClientCard } from "../../Clients/components/ClientCard"
import TeamCard from "../../Clients/components/TeamCard"
import AppointeesCard from "../../Clients/components/AppointeesCard"

export function ClientDetails() {
	let client = useLoaderData()

	return (
		<React.Fragment>
			{client && client.type === "Property" && <PropertyCard key={`PC${client.id}`} property={client} isCompact={true} />}
			{client && client.type !== "Property" && <ClientCard key={`CC${client.id}`} client={client} isCompact={true} />}

			{client &&
				client.teams &&
				client.teams.filter(it => ["InHouseTeam", "ClientTeam"].includes(it.type)).map(team => <TeamCard key={`TC${team.id}`} team={team} />)}
			{client && client.teams && <AppointeesCard key={`IAC${client.id}`} business={client} type={"InHouseAppointees"} />}
			{client && client.teams && client.type !== "Customer" && <AppointeesCard key={`CAC${client.id}`} business={client} type={"ClientAppointees"} />}
		</React.Fragment>
	)
}
