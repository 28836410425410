import React from "react"

import { connect } from "react-redux"
import { Typeahead } from "react-bootstrap-typeahead"
import PropTypes from "prop-types"
import _ from "lodash"

import {
	Form,
	FormGroup,
	FormFeedback,
	FormText,
	Input,
	InputGroup,
	InputGroupAddon,
	CustomInput,
	UncontrolledModal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "../../../../components"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import CommentsEditor from "./CommentsEditor"

class Query_EditForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		query: PropTypes.object.isRequired,
		updateQuery: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		var openStages = props.periodEnd.stages.filter(it => it.status !== "Closed")
		if (!openStages.length) openStages = [_.last(props.periodEnd.stages)]

		this.actors = _.union(props.periodEnd.property.actors.inHouse, props.periodEnd.property.actors.client)
		this.actors.push({ ...props.query.assignedTo, description: props.query.assignedTo.name })

		this.state = {
			openStages: openStages,
			stage: props.stage,
			...props.query,
			assignTo: props.query.assignedTo.username,
			showValidation: true,
		}

		this.props = props

		this.handleStageChange = this.handleStageChange.bind(this)
		this.handleAssignToChange = this.handleAssignToChange.bind(this)
		this.handleSubjectChange = this.handleSubjectChange.bind(this)
		this.handleTitleChange = this.handleTitleChange.bind(this)
		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleSubjectChange(event) {
		this.setState({
			subject: event.target.value,
		})
	}

	handleTitleChange(event) {
		this.setState({
			title: event.target.value,
		})
	}

	handleCommentsChange(value) {
		this.setState({
			comments: value,
		})
	}

	handleStageChange(e) {
		this.setState({
			stage: _.find(this.state.openStages, it => it.id === parseInt(e.target.value)),
		})
	}

	handleAssignToChange(option) {
		this.setState({
			assignTo: option[0] ? option[0].username : null,
		})
	}

	handleActionButtonClick(event) {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}

		this.props.updateQuery({
			id: this.state.id,
			assignTo: this.state.assignTo,
			periodEnd: this.props.periodEnd,
			stage: this.state.stage,
			subject: this.state.subject,
			title: this.state.title,
			comments: this.state.comments,
			isDraft: event.target.name === "draft",
		})
		return true
	}

	get isTitleRequired() {
		return !(this.state.title && this.state.title.length)
	}

	get isAssignToRequired() {
		return !(this.state.assignTo && this.state.assignTo.length)
	}

	get isValid() {
		return !this.isTitleRequired || !this.isAssignToRequired
	}

	render() {
		const { query } = this.props

		const selectedUser = [_.find(this.actors, it => it.username === query.assignedTo.username)]

		return (
			<UncontrolledModal style={{ maxWidth: 700 }} target={`query_EditForm_${query.id}`}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">Edit Query</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Resolve by Stage</InputGroupAddon>
								<CustomInput
									type="select"
									id="stage"
									name="stage"
									className="ml-auto"
									defaultValue={this.state.stage.id}
									onChange={e => this.handleStageChange(e)}
								>
									{this.state.openStages.map((it, idx) => (
										<option key={idx} value={it.id}>
											{it.templateStage.sequence}: {it.templateStage.name}
										</option>
									))}
								</CustomInput>
							</InputGroup>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">To</InputGroupAddon>
								<Typeahead
									id="assignTo"
									labelKey="description"
									options={this.actors}
									placeholder="Choose a Recipient..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									value={this.state.assignTo}
									onChange={this.handleAssignToChange}
									defaultSelected={selectedUser}
								/>
							</InputGroup>
							<FormText hidden={!this.state.showValidation || !this.isAssignToRequired} className="text-danger">
								Queries must be assigned to an individual or team
							</FormText>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Subject</InputGroupAddon>
								<CustomInput
									invalid={this.state.showValidation && this.isSubjectRequired}
									type="select"
									id="subject"
									name="subject"
									className="ml-auto"
									defaultValue={this.state.subject}
									onChange={e => this.handleSubjectChange(e)}
								>
									{clearview.QuerySubjects.map((it, idx) => (
										<option key={idx}>{it}</option>
									))}
									<option value="">Unspecified</option>
								</CustomInput>
								<FormFeedback>You must specify the query subject</FormFeedback>
							</InputGroup>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Title</InputGroupAddon>
								<Input
									invalid={this.state.showValidation && this.isTitleRequired}
									type="text"
									name="title"
									id="title"
									placeholder="Title..."
									value={this.state.title}
									onChange={this.handleTitleChange}
								/>
								<FormFeedback>A short title is required</FormFeedback>
							</InputGroup>
						</FormGroup>
						<FormGroup row>
							<CommentsEditor value={this.state.comments} onChange={this.handleCommentsChange} />
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close
							hidden={!(query.actions || []).includes("Send")}
							color="default"
							className="text-primary"
							disabled={!this.isValid}
							name="draft"
							onClick={e => this.handleActionButtonClick(e)}
						>
							Save As Draft
						</UncontrolledModal.Close>
						<UncontrolledModal.Close
							color="default"
							className="text-primary"
							disabled={!this.isValid}
							name="send"
							onClick={e => this.handleActionButtonClick(e)}
						>
							{(query.actions || []).includes("Send") ? "Send" : "Save"}
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	updateQuery: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE_QUERY,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Query_EditForm)
