import React from "react"
import _ from "lodash"

import { UncontrolledTabs, TabPane, Nav, NavItem } from "../../../../components"

import { LivePeriodEndsPhasesView } from "./LivePeriodEndsPhasesView"
import { HelpPopup } from "../../../components/HelpPopup"

import PropTypes from "prop-types"

import * as clearview from "../../../../components/@Clearview"
export class LivePeriodEndsPhasesOverview extends React.Component {
	static propTypes = {
		context: PropTypes.object.isRequired,
		livePeriodEnds: PropTypes.array.isRequired,
	}

	render() {
		const { context, livePeriodEnds } = this.props
		const customer = context.state.filter.customer
		const user = context.user

		const initialViewTabId = clearview.LoadLocal("LivePeriodEndsPhasesOverview.initialTab", "PhasesOverviewTable")
		if (!customer) return

		const userItems = user.preferences.overview.LivePeriodEndsPhasesOverview(user)

		return (
			<React.Fragment>
				<div className="hr-text hr-text-left my-2">
					<span>
						Phases{" "}
						<HelpPopup id="od_hp_phases" title="Phases">
							<p>These tables show Open Period End summary information by Template Phase:</p>
							<dl>
								<dt>Overview</dt>
								<dd>By Period End RAG and Stage RAG Statuses (hover over the column titles for further info)</dd>
								<dt>Month Summary</dt>
								<dd>By Period End Date</dd>
								{user.isInHouse && (
									<React.Fragment>
										<dt>Client Summary</dt>
										<dd>By first level Client/Managing Agent</dd>
										<dt>By Service Charge Manager</dt>
										<dd>
											By In-house Service Charge Manager, i.e. the manager assigned in the In-house "Service Charge Manager" category of
											Property (or its parent Client/Customer if not specified).
										</dd>
										<dt>Manager Summary</dt>
										<dd>
											By In House Manager, i.e. the manager assigned in the "Other" category of the In House Appointees for the first
											level Client/Managing Agent
										</dd>
									</React.Fragment>
								)}
							</dl>
						</HelpPopup>
					</span>
				</div>
				<UncontrolledTabs initialActiveTabId="default">
					<Nav pills className="mb-0 flex-column flex-md-row mt-0 mt-lg-0" id="LivePeriodEndsPhasesOverview">
						{userItems.PhasesOverviewTable && (
							<NavItem
								key="PhasesOverviewTable"
								title="Phases Overview Table"
								onClick={e => clearview.SaveLocal("LivePeriodEndsPhasesOverview.initialTab", "PhasesOverviewTable")}
							>
								<UncontrolledTabs.NavLink tabId={initialViewTabId === "PhasesOverviewTable" ? "default" : "PhasesOverviewTable"}>
									Overview
								</UncontrolledTabs.NavLink>
							</NavItem>
						)}

						{(userItems.PhasesMonthTable || userItems.PhasesMonthTableReverse) && (
							<NavItem
								key="PhasesMonthTable"
								title="Phases Month Summary"
								onClick={e => clearview.SaveLocal("LivePeriodEndsPhasesOverview.initialTab", "PhasesMonthTable")}
							>
								<UncontrolledTabs.NavLink tabId={initialViewTabId === "PhasesMonthTable" ? "default" : "PhasesMonthTable"}>
									Month Summary
								</UncontrolledTabs.NavLink>
							</NavItem>
						)}

						{userItems.PhasesClientTable && (
							<NavItem
								key="PhasesClientTable"
								title="Phases Client Summary"
								onClick={e => clearview.SaveLocal("LivePeriodEndsPhasesOverview.initialTab", "PhasesClientTable")}
							>
								<UncontrolledTabs.NavLink tabId={initialViewTabId === "PhasesClientTable" ? "default" : "PhasesClientTable"}>
									Client Summary<sup title="Only visible to In-House users">{clearview.Icon.inHouse[true]}</sup>
								</UncontrolledTabs.NavLink>
							</NavItem>
						)}

						{userItems.PhasesAppointeesTable &&
							userItems.PhasesAppointeesTable.map(appointees => (
								<NavItem
									key={`PhasesAppointeesTable_${appointees}`}
									title={`Phases by ${_.startCase(appointees)}`}
									onClick={e => clearview.SaveLocal("LivePeriodEndsPhasesOverview.initialTab", `PhasesAppointeesTable_${appointees}`)}
								>
									<UncontrolledTabs.NavLink
										tabId={initialViewTabId === `PhasesAppointeesTable_${appointees}` ? "default" : `PhasesAppointeesTable_${appointees}`}
									>
										By {_.startCase(appointees)}
										<sup title="Only visible to In-House users">{clearview.Icon.inHouse[true]}</sup>
									</UncontrolledTabs.NavLink>
								</NavItem>
							))}

						{userItems.PhasesManagerTable && (
							<NavItem
								key="PhasesManagerTable"
								title="Phases Manager Summary"
								onClick={e => clearview.SaveLocal("LivePeriodEndsPhasesOverview.initialTab", "PhasesManagerTable")}
							>
								<UncontrolledTabs.NavLink tabId={initialViewTabId === "PhasesManagerTable" ? "default" : "PhasesManagerTable"}>
									Manager Summary<sup title="Only visible to In-House users">{clearview.Icon.inHouse[true]}</sup>
								</UncontrolledTabs.NavLink>
							</NavItem>
						)}
					</Nav>
					<UncontrolledTabs.TabContent>
						<TabPane key="PhasesOverviewTable" tabId={initialViewTabId === "PhasesOverviewTable" ? "default" : "PhasesOverviewTable"}>
							<LivePeriodEndsPhasesView view="PhasesOverviewTable" context={context} livePeriodEnds={livePeriodEnds} />
						</TabPane>

						<TabPane key="PhasesMonthTable" tabId={initialViewTabId === "PhasesMonthTable" ? "default" : "PhasesMonthTable"}>
							<LivePeriodEndsPhasesView
								view="PhasesMonthTable"
								context={context}
								livePeriodEnds={livePeriodEnds}
								isReverse={userItems.PhasesMonthTableReverse}
							/>
						</TabPane>

						<TabPane key="PhasesClientTable" tabId={initialViewTabId === "PhasesClientTable" ? "default" : "PhasesClientTable"}>
							<LivePeriodEndsPhasesView view="PhasesClientTable" context={context} livePeriodEnds={livePeriodEnds} />
						</TabPane>

						{userItems.PhasesAppointeesTable &&
							userItems.PhasesAppointeesTable.map(appointees => (
								<TabPane
									key={`PhasesAppointeesTable_${appointees}`}
									tabId={initialViewTabId === `PhasesAppointeesTable_${appointees}` ? "default" : `PhasesAppointeesTable_${appointees}`}
								>
									<LivePeriodEndsPhasesView view={`PhasesAppointeesTable_${appointees}`} context={context} livePeriodEnds={livePeriodEnds} />
								</TabPane>
							))}

						<TabPane key="PhasesManagerTable" tabId={initialViewTabId === "PhasesManagerTable" ? "default" : "PhasesManagerTable"}>
							<LivePeriodEndsPhasesView view="PhasesManagerTable" context={context} livePeriodEnds={livePeriodEnds} />
						</TabPane>
					</UncontrolledTabs.TabContent>
				</UncontrolledTabs>
			</React.Fragment>
		)
	}
}
