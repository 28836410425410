import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import cellEditFactory, { Type } from "../../../../react-bootstrap-table2-editor"
import _ from "lodash"

import { TemplateContext } from "../TemplateContext"

import * as clearview from "../../../../components/@Clearview"

import { TemplateConditions } from "./TemplateConditions"

import { Card, CardHeader, CardBody, CardFooter, Button, ButtonGroup } from "../../../../components"

export class TemplateStages extends React.Component {
	constructor(props) {
		super(props)
		this.props = props
	}

	render() {
		const phaseOptions = [
			{ value: null, label: "" },
			..._.sortBy(this.props.phases, it => it.id).map(it => ({ value: it.id, label: it.sequence.toString() })),
		]
		const phaseDictionary = _.keyBy(this.props.phases, it => it.id)

		const columnDefs = [
			{
				dataField: "phaseId",
				text: "Phase",
				headerAlign: "center",
				align: "center",
				type: "number",
				formatter: cell => phaseDictionary[cell]?.sequence,
				editor: { type: Type.SELECT, options: phaseOptions },
			},
			{
				dataField: "sequence",
				text: "Stage",
				headerAlign: "center",
				align: "center",
				editable: false,
			},
			{
				dataField: "isInHouse",
				text: "In House",
				headerAlign: "center",
				align: "center",
				type: "bool",
				formatter: cell => clearview.Icon.InHouse[cell],
				editor: {
					type: Type.SELECT,
					options: [
						{ value: false, label: "Client" },
						{ value: true, label: "In-House" },
					],
				},
			},
			{
				dataField: "name",
				text: "Description",
				align: "top",
				type: "string",
				editor: { type: Type.TEXT, placeholder: "Stage description..." },
			},
			{
				dataField: "plannedDays",
				text: "Planned Days",
				headerAlign: "center",
				align: "center",
				type: "number",
				editor: { type: "number" },
			},
			{
				dataField: "warningDays",
				text: "Warning Days",
				headerAlign: "center",
				align: "center",
				type: "number",
				editor: { type: "number" },
			},
			{
				dataField: "conditions",
				text: "Conditions",
				align: "center",
				formatter: (cell, row) => <span>{row.conditions.length}</span>,
				editable: false,
			},
		]

		return (
			<TemplateContext.Consumer>
				{({ isEditing, template, onChange }) => {
					const editColumns = [
						{
							dataField: "id",
							text: "Del",
							align: "center",
							formatter: (cell, row) => (
								<Button color="link" className="text-danger" title="Delete stage..." onClick={() => onChange("removeStage", row)}>
									{clearview.Icon.delete}
								</Button>
							),
							editable: false,
						},
						{
							dataField: "rowVersion",
							text: "Move",
							align: "center",
							formatter: (cell, row) => (
								<ButtonGroup hidden={!(template.actions || []).includes("AddStage")}>
									<Button color="link" className="text-info ml-1 mr-2" title="Move Up" onClick={() => onChange("moveUpStage", row)}>
										{clearview.Icon.up}
									</Button>
									<Button color="link" className="text-info mr-1" title="Move Down" onClick={() => onChange("moveDownStage", row)}>
										{clearview.Icon.down}
									</Button>
								</ButtonGroup>
							),
							editable: false,
						},
					]

					const nonEditableRows = isEditing ? [] : template.stages.map(it => it.id)

					return (
						<ToolkitProvider
							keyField="id"
							data={template.stages}
							columns={isEditing && (template.actions || []).includes("RemoveStage") ? columnDefs.concat(editColumns) : columnDefs}
						>
							{props => (
								<React.Fragment>
									<BootstrapTable
										classes="table-responsive-lg csl-stage-table table-striped"
										bordered={true}
										expandRow={expandRow(onChange)}
										responsive
										hover
										cellEdit={cellEditFactory({
											mode: "click",
											blurToSave: true,
											afterSaveCell: (oldValue, newValue, row, column) => onChange("editStage", row),
											nonEditableRows: () => nonEditableRows,
										})}
										{...props.baseProps}
									/>
								</React.Fragment>
							)}
						</ToolkitProvider>
					)
				}}
			</TemplateContext.Consumer>
		)
	}
}

function expandRow(onChange) {
	return {
		renderer: row => (
			<TemplateContext.Consumer>
				{({ isEditing, addCondition }) => (
					<Card className="mb-3" type="border">
						<CardHeader className="h6 d-flex">
							<div className="align-self-center flex-1">{clearview.Icon.condition} Conditions</div>
						</CardHeader>
						<CardBody>
							<TemplateConditions stage={row} />
						</CardBody>
						{isEditing && (
							<CardFooter className="h6 text-right">
								<Button outline color="primary" title="Add new condition..." onClick={() => onChange("addCondition", row)}>
									{clearview.Icon.add}
								</Button>
							</CardFooter>
						)}
					</Card>
				)}
			</TemplateContext.Consumer>
		),
		showExpandColumn: true,
		expandByColumnOnly: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) =>
			isAnyExpands ? <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i> : <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>,
		expandColumnRenderer: ({ expanded }) =>
			expanded ? <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i> : <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>,
	}
}
