import React from "react"

import * as clearview from "../../../../../components/@Clearview"

import { useState } from "react"
import { Button, FormGroup, CustomInput, InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody, ModalFooter } from "../../../../../components"
import { Schedule } from "./Schedule"
import { NominalCode } from "./NominalCode"

export function AddUpdateScheduleCodeTotal({ config, isOpen, onCancel, onSave, value = { total: 0 } }) {
	config = {
		title: "Add",
		buttonLabel: "Update",
		...config,
	}

	const [row, setRow] = useState({ ...value })
	const [showErrors, setShowErrors] = useState(false)
	const onOpened = e => {
		setRow({ ...value, total: value.total / 100 })
		setShowErrors(false)
	}

	const updateRow = (name, value) => setRow(prevState => ({ ...prevState, [name]: value }))

	const errors = () => {
		const myErrors = []
		if (!row.schedule) myErrors.push("Schedule is required.")
		if (!row.code) myErrors.push("Code is required.")
		if (!row.total) myErrors.push("Non-zero Value is required.")
		return myErrors
	}

	const labelWidth = "5rem"
	return (
		<Modal isOpen={isOpen} style={{ maxWidth: 700 }} onOpened={onOpened}>
			<ModalHeader tag="h6">{config.title}</ModalHeader>

			<ModalBody>
				<FormGroup row>
					<InputGroup>
						<InputGroupAddon style={{ width: labelWidth }} addonType="prepend">
							Schedule
						</InputGroupAddon>
						<Schedule value={row.schedule} onChange={val => updateRow("schedule", val)} />
					</InputGroup>
				</FormGroup>

				<FormGroup row>
					<InputGroup style={{ width: "12rem" }}>
						<div className="input-group-prepend input-group-text" style={{ width: labelWidth }}>
							Code
						</div>
						<NominalCode
							value={row.code}
							onChange={val => {
								updateRow("code", val?.code)
								updateRow("nominalCode", val)
							}}
						/>
					</InputGroup>
					<div className="mt-2 ml-2">{row.nominalCode?.name}</div>
				</FormGroup>

				<FormGroup row>
					<InputGroup style={{ width: "12rem" }}>
						<div className="input-group-prepend input-group-text" style={{ width: labelWidth }}>
							Total
						</div>
						<CustomInput
							type="number"
							name="total"
							id="total"
							className="form-control currency"
							value={row.total}
							onChange={e => {
								updateRow("total", e.target.value)
							}}
						/>
					</InputGroup>
				</FormGroup>
			</ModalBody>

			<ModalFooter>
				{clearview.listErrors(errors(), !showErrors)}
				<Button color="link" onClick={onCancel}>
					Cancel
				</Button>
				<Button
					color="primary"
					name="update"
					onClick={e => {
						if (errors().length) {
							setShowErrors(true)
							return false
						}
						row.total = Math.round((row.total || 0) * 100)
						onSave(row)
					}}
				>
					{config.buttonLabel}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
