import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Form, FormGroup, FormFeedback, CustomInput, Input, InputGroup, UncontrolledModal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"

class PeriodEnd_DeleteForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = { comments: "", doNotRenew: true }
		this.props = props

		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleDoNotRenewChange = this.handleDoNotRenewChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleCommentsChange(event) {
		this.setState({
			comments: event.target.value,
		})
	}

	handleDoNotRenewChange(event) {
		this.setState({
			doNotRenew: event.target.checked,
		})
	}

	handleActionButtonClick() {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}
		this.props.doAction({
			periodEnd: this.props.periodEnd,
			comments: this.state.comments,
			doNotRenew: this.state.doNotRenew,
		})
		this.setState({ comments: "" })
		return true
	}

	get isCommentsRequired() {
		return !(this.state.comments && this.state.comments.length)
	}

	get isValid() {
		return !this.isCommentsRequired
	}

	render() {
		const { periodEnd } = this.props

		const { comments, doNotRenew, showValidation } = this.state

		return (
			<UncontrolledModal target={`periodEnd${periodEnd.id}_DeleteForm`}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">Delete</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<Input
									invalid={showValidation && this.isCommentsRequired}
									type="textarea"
									name="comments"
									id="comments"
									placeholder="Comments..."
									value={comments}
									onChange={this.handleCommentsChange}
								/>
								<FormFeedback>Comments are required</FormFeedback>
							</InputGroup>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<CustomInput
									label="Do Not Renew!"
									name="doNotRenew"
									id="doNotRenew"
									key="doNotRenew"
									type="checkbox"
									checked={doNotRenew}
									onChange={this.handleDoNotRenewChange}
								/>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close color="default" className="text-primary" disabled={!this.isValid} onClick={this.handleActionButtonClick}>
							Delete
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAction: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_DELETE,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(PeriodEnd_DeleteForm)
