import React from "react"
import * as ACTIONS from "../actions"
import _ from "lodash"
import { sum } from "lodash"
import moment from "moment"
import * as clearview from "../../components/@Clearview"

const clientsReducer = (state, action) => {
	if (!state)
		state = {
			local: {
				periodEndsMeta: clearview.LoadLocal("periodEndsMeta", {}),
			},
			user: {},
			clients: { isBusy: false, lastUpdated: null, data: [] }, //Actually a nested tree
			properties: { isBusy: false, lastUpdated: null, dict: {} },
			periodEnds: { isBusy: false, lastUpdated: null, dict: {} },
			templates: { isBusy: false, lastUpdated: null, dict: {} },
		}

	const materialiseClient = (it, parent) => {
		if (parent) {
			if (!parent.children) parent.children = []
			if (!parent.children.find(c => c.id === it.id)) parent.children.push(it)
			it.parent = parent
		}

		//Assign role
		it.role = it.role
			? it.role
			: it.type === "Root"
			? "Root"
			: it.type === "Customer"
			? "Customer"
			: it.type === "Property"
			? "Property"
			: parent && parent.type === "Customer"
			? "Client"
			: parent && parent.type === "Client"
			? "Landlord"
			: parent && parent.type === "Managing Agent"
			? "Landlord"
			: it.type
		if (it.role === "Landlord") {
			parent.role = "Managing Agent"
		}

		it.acting = it.parent?.acting
		it.teams?.forEach(team => {
			team.users = team.users.filter(it => !it.isDisabled)
			const me = _.find(team.users, u => u.username === state.user.username)
			if (me)
				it.acting = {
					isInHouse: me.isInHouse,
					role: me.role,
				}
		})

		it.actors = ACTIONS.actorsForBusiness(it)

		//Join properties to period ends
		if (it.type === "Property") {
			state.properties.dict[it.reference] = it
			it.periodEnds = ACTIONS.filter(state.periodEnds.dict, pe => pe.propertyId === it.id)
			it.periodEnds.forEach(pe => (pe.property = it))
		}
	}
	// if (action.type.startsWith("CLIENT:")) console.info(`REDUCER: ${action.type}`)
	// if (action.type.startsWith("TEMPLATES:")) console.info(`REDUCER: ${action.type}`)

	try {
		let it
		let parent
		let clientNode
		let propertyNode
		let periodEndsArray
		let propertiesArray
		let templatesArray

		switch (action.type) {
			case ACTIONS.FAVOURITE_ADD:
			case ACTIONS.FAVOURITE_REMOVE:
			case ACTIONS.FAVOURITE_RESET:
				return ACTIONS.setSubStateFetching(state, "periodEnds", {
					dict: state.periodEnds.dict,
				})

			case ACTIONS.USER_GOT_CURRENT_USER:
			case ACTIONS.FAVOURITE_ADDED:
			case ACTIONS.FAVOURITE_REMOVED:
			case ACTIONS.FAVOURITE_RESETED:
				let updatedPeriodEnds = ACTIONS.dictionaryToArray(state.periodEnds.dict).map(periodEnd => {
					const updatedFavourite = updateFavourite(periodEnd, action.user)
					return {
						...periodEnd,
						isFavourite: updatedFavourite.isFavourite,
						isAlert: updatedFavourite.isAlert,
					}
				})
				state.user = action.user
				return ACTIONS.setSubStateFetched(state, "periodEnds", {
					dict: ACTIONS.arrayToDictionary(updatedPeriodEnds, it => it.reference, state.periodEnds.dict),
				})

			case ACTIONS.TEMPLATES_FETCH:
			case ACTIONS.TEMPLATES_UPDATE:
			case ACTIONS.TEMPLATE_UPDATE:
				return ACTIONS.setSubStateFetching(state, "templates")

			case ACTIONS.TEMPLATES_FETCHED:
			case ACTIONS.TEMPLATES_UPDATED:
				action.templates.forEach(customer =>
					customer.templates.forEach(it => {
						it.family = it.family || it.reference
						it.customer = customer
						it.stages?.forEach(ts => (ts.phase = _.find(customer.phases, p => p.id === ts.phaseId)))
						it.invoiceAfterStage = _.find(it.stages || [], s => s.sequence === it.invoiceAfterStageSequence)
					})
				)
				action.templates.forEach(customer =>
					customer.phases.forEach(it => {
						it.customer = customer
					})
				)
				return ACTIONS.setSubStateFetched(state, "templates", {
					dict: ACTIONS.arrayToDictionary(action.templates, it => it.reference),
				})

			case ACTIONS.TEMPLATE_FETCHED:
			case ACTIONS.TEMPLATE_UPDATED:
				const allTemplates = _.flatMap(
					Object.keys(state.templates.dict).map(key => state.templates.dict[key]),
					it => it.templates
				)
				const template = _.find(allTemplates, it => it.id === action.template.id)
				Object.keys(template).forEach(key => (template[key] = action.template[key] || template[key]))
				return ACTIONS.setSubStateFetched(state, "templates", {
					dict: state.templates.dict,
				})

			case ACTIONS.CLIENTS_FETCH:
				state.properties.isBusy = true
				return ACTIONS.setSubStateFetching(state, "clients", {
					data: [],
				})

			case ACTIONS.CLIENTS_MOVE_CLIENTS:
				return ACTIONS.setSubStateFetching(state, "clients")

			case ACTIONS.CLIENTS_MOVED_CLIENTS:
				clearview.ShowToast("Success", <p>Users, including you, will need to Refresh in order to see the new Client structure.</p>, false)
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.CLIENTS_FETCHED:
				state.properties.dict = {}

				let clients = []
				action.clients.forEach(it => {
					parent = action.clients.find(c => c.id === it.parentId)
					if (!parent) clients.push(it)
					else {
						parent.children = _.sortBy(_.union([it], parent.children), c => c.reference)
						it.parent = parent
					}
				})

				// Clients should only have one root
				const minClientLft = Math.min(...clients.map(it => it.lft))
				clients = clients.filter(it => it.lft === minClientLft)

				//Add parent to every client
				ACTIONS.crawlBranches(clients, materialiseClient)
				state.properties.isBusy = false
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: clients,
				})

			case ACTIONS.CLIENT_UPDATED:
				clientNode = ACTIONS.findInTree(state, "clients", it => it.id === action.client.id)
				if (clientNode) {
					Object.keys(action.client).forEach(key => {
						clientNode[key] = action.client[key]
						clientNode.isBusy = false
						clientNode.lastUpdated = Date.now()
					})
				}
				state.properties.isBusy = false
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.CLIENTS_ADDED:
				for (let it of action.clients) {
					const parent = ACTIONS.findInTree(state, "clients", p => p.id === it.parentId)
					materialiseClient(it, parent)
				}

				state.properties.isBusy = false
				clearview.DismissToast("importBusinesses")
				clearview.ShowToast("Success", <p>{action.clients.length} clients have been created.</p>, false)
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.CLIENT_ADDED:
				parent = ACTIONS.findInTree(state, "clients", it => it.id === action.client.parentId)
				it = action.client
				materialiseClient(it, parent)

				state.properties.isBusy = false
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.CLIENT_REMOVED:
				clientNode = ACTIONS.findInTree(state, "clients", it => it.id === action.client.id)
				if (clientNode) {
					clientNode.parent.children = clientNode.parent.children.filter(it => it.id !== clientNode.id)
				}
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.PROPERTY_ADDED:
				parent = ACTIONS.findInTree(state, "clients", it => it.id === action.property.parentId)
				it = action.property
				materialiseClient(it, parent)

				state.properties.isBusy = false
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.PROPERTIES_ADDED:
				for (let it of action.properties) {
					const parent = ACTIONS.findInTree(state, "clients", p => p.id === it.parentId)
					materialiseClient(it, parent)
				}
				state.properties.isBusy = false
				clearview.DismissToast("importProperties")
				clearview.ShowToast("Success", <p>{action.properties.length} properties have been created.</p>, false)
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.PROPERTY_UPDATED:
				propertyNode = ACTIONS.findInTree(state, "clients", it => it.id === action.property.id)
				if (propertyNode) {
					Object.keys(action.property).forEach(key => {
						propertyNode[key] = action.property[key]
						propertyNode.isBusy = false
						propertyNode.lastUpdated = Date.now()
					})
				}
				state.properties.isBusy = false
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.PROPERTY_REMOVED:
				clientNode = ACTIONS.findInTree(state, "clients", it => it.id === action.property.parentId)
				if (clientNode) {
					clientNode.children = clientNode.children.filter(it => it.id !== action.property.id)
				}
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			case ACTIONS.PERIODENDS_ALL_FETCH:
				return ACTIONS.setSubStateFetching(state, "periodEnds", {
					dict: {},
				})

			case ACTIONS.PERIODENDS_RANGE_FETCHED:
				propertiesArray = ACTIONS.dictionaryToArray(state.properties.dict)
				templatesArray = ACTIONS.dictionaryToArray(state.templates.dict).flatMap(it => it.templates)
				action.periodEnds.forEach(periodEnd => materialisePeriodEndLite(periodEnd, propertiesArray, templatesArray, state.user, state.local))
				return ACTIONS.setSubStateFetched(state, "periodEnds", {
					dict: ACTIONS.arrayToDictionary(
						action.periodEnds.filter(it => it.property),
						it => it.reference,
						state.periodEnds.dict
					),
				})

			case ACTIONS.QUERIES_RANGE_FETCHED:
				periodEndsArray = ACTIONS.dictionaryToArray(state.periodEnds.dict)
				action.queries.forEach(query => materialiseQueryLite(query, periodEndsArray))
				return ACTIONS.setSubStateFetched(state, "periodEnds", state.periodEnds)

			case ACTIONS.PERIODEND_START:
			case ACTIONS.PERIODEND_DELETE:
			case ACTIONS.PERIODEND_UPDATE:
			case ACTIONS.PERIODEND_REFERENCE_UPDATE:
			case ACTIONS.PERIODEND_FIELD_UPDATE:
			case ACTIONS.PERIODEND_DONOTRENEW_UPDATE:
			case ACTIONS.PERIODEND_ISPUBLISHED_UPDATE:
			case ACTIONS.PERIODEND_CONDITION_UPDATE:
			case ACTIONS.PERIODEND_UPDATE_COMMENT:
			case ACTIONS.PERIODEND_DELETE_COMMENT:
			case ACTIONS.PERIODEND_RAISE_QUERY:
			case ACTIONS.PERIODEND_UPDATE_QUERY:
			case ACTIONS.PERIODEND_DELETE_QUERY:
			case ACTIONS.PERIODEND_REPLY_TO_QUERY:
			case ACTIONS.PERIODEND_CLOSE_QUERY:
			case ACTIONS.PERIODEND_REOPEN_QUERY:
			case ACTIONS.PERIODEND_CLOSE_QUERIES:
			case ACTIONS.PERIODEND_UPDATE_REPLY:
			case ACTIONS.PERIODEND_DELETE_REPLY:
				return ACTIONS.setSubStateFetching(state, `periodEnds\\dict\\${action.periodEnd.reference}`)

			case ACTIONS.PERIODEND_FETCH:
				if (action.payload.periodEnd.reference) return ACTIONS.setSubStateFetching(state, `periodEnds\\dict\\${action.payload.periodEnd.reference}`)
				return state

			case ACTIONS.PERIODEND_FETCHED:
			case ACTIONS.PERIODEND_STARTED:
			case ACTIONS.PERIODEND_UPDATED:
			case ACTIONS.PERIODEND_FIELD_UPDATED:
			case ACTIONS.PERIODEND_DONOTRENEW_UPDATED:
			case ACTIONS.PERIODEND_ISPUBLISHED_UPDATED:
			case ACTIONS.PERIODEND_CONDITION_UPDATED:
				materialisePeriodEnd(
					action.periodEnd,
					ACTIONS.dictionaryToArray(state.properties.dict),
					ACTIONS.dictionaryToArray(state.templates.dict).flatMap(it => it.templates),
					state.user,
					state.local
				)
				return ACTIONS.setSubStateFetched(state, `periodEnds\\dict\\${action.periodEnd.reference}`, action.periodEnd)

			case ACTIONS.PERIODEND_REFERENCE_UPDATED:
				materialisePeriodEnd(
					action.periodEnd,
					ACTIONS.dictionaryToArray(state.properties.dict),
					ACTIONS.dictionaryToArray(state.templates.dict).flatMap(it => it.templates),
					state.user,
					state.local
				)
				ACTIONS.setSubStateFetched(state, `periodEnds\\dict\\${action.periodEnd.reference}`, action.periodEnd)
				return ACTIONS.setSubStateFetched(state, `periodEnds\\dict\\${action.oldReference}`, action.periodEnd)

			case ACTIONS.PERIODEND_UNREAD_UPDATE:
				return setPeriodEndUnread(state, action.periodEnd, action.isUnread)

			case ACTIONS.PERIODEND_ALL_READ_UPDATE:
				return setPeriodEndsAllRead(state)

			case ACTIONS.PERIODEND_DELETED:
				materialisePeriodEnd(
					action.periodEnd,
					ACTIONS.dictionaryToArray(state.properties.dict),
					ACTIONS.dictionaryToArray(state.templates.dict).flatMap(it => it.templates),
					state.user,
					state.local
				)
				return ACTIONS.setSubStateFetched(state, `periodEnds\\dict\\${action.periodEnd.reference}`, action.periodEnd)

			case ACTIONS.PERIODEND_CREATED:
				//Add the item to the dictionary
				materialisePeriodEnd(
					action.periodEnd,
					ACTIONS.dictionaryToArray(state.properties.dict),
					ACTIONS.dictionaryToArray(state.templates.dict).flatMap(it => it.templates),
					state.user,
					state.local
				)
				state.periodEnds.isBusy = false
				state.periodEnds.lastUpdated = Date.now()
				return ACTIONS.setSubStateFetched(state, `periodEnds\\dict\\${action.periodEnd.reference}`, action.periodEnd)

			case ACTIONS.PERIODENDS_CREATED:
				propertiesArray = ACTIONS.dictionaryToArray(state.properties.dict)
				templatesArray = ACTIONS.dictionaryToArray(state.templates.dict).flatMap(it => it.templates)
				action.periodEnds.forEach(periodEnd => materialisePeriodEndLite(periodEnd, propertiesArray, templatesArray, state.user, state.local))

				clearview.DismissToast("importPeriodEnds")
				clearview.ShowToast("Success", <p>{action.periodEnds.length} Period Ends have been created.</p>, false)

				return ACTIONS.setSubStateFetched(state, "periodEnds", {
					dict: ACTIONS.arrayToDictionary(
						action.periodEnds.filter(it => it.property),
						it => it.reference,
						state.periodEnds.dict
					),
				})

			case ACTIONS.PERIODEND_REDIRECTED:
				return ACTIONS.setSubStateFetched(state, ACTIONS.PERIODEND_CREATED, {})

			case ACTIONS.TEAMS_MANAGED:
				ACTIONS.crawlBranches(state.clients.data, (it, parent) => {
					if (it.id === action.businessId) {
						// ToDo - resetting the teams seems to mess up the teamGrid
						// it.teams = action.teams || []
					}
				})
				return ACTIONS.setSubStateFetched(state, "clients", {
					data: state.clients.data,
				})

			default:
				return state
		}
	} catch (err) {
		console.error(err)
	}
}

function materialisePeriodEndLite(periodEndLite, propertiesArray, templatesArray, user, local) {
	const property = _.find(propertiesArray, it => it.id === periodEndLite.propertyId)
	if (!property) {
		// console.warn(periodEndLite.propertyId, propertiesArray, "PROPERTY NOT FOUND!")
		return undefined
	}
	periodEndLite.$type = "periodEndLite"
	periodEndLite.property = property
	property.periodEnds = property.periodEnds.filter(it => it.id !== periodEndLite.id).concat([periodEndLite])

	const template = _.find(templatesArray, it => it.id === periodEndLite.templateId)
	if (!template) {
		console.warn(periodEndLite.templateId, templatesArray, "TEMPLATE NOT FOUND!")
		return
	}
	periodEndLite.template = template

	periodEndLite.badges = {
		comments: periodEndLite.stageBadge?.comments,
		attachments: periodEndLite.stageBadge?.attachments,
		closedQueries: sum([
			user.isInHouse ? periodEndLite.inHouseOnlyBadge?.closedQueries : 0,
			periodEndLite.inHousePublicBadge?.closedQueries,
			periodEndLite.clientBadge?.closedQueries,
		]),
		openQueriesWithUs: user.isInHouse
			? sum([periodEndLite.inHouseOnlyBadge?.openQueries, periodEndLite.inHousePublicBadge?.openQueries])
			: periodEndLite.clientBadge?.openQueries,
		openQueriesWithThem: !user.isInHouse ? periodEndLite.inHousePublicBadge?.openQueries : periodEndLite.clientBadge?.openQueries,
		draftQueries: user.isInHouse ? periodEndLite.inHouseOnlyBadge?.draftQueries : periodEndLite.clientBadge?.draftQueries,
	}

	try {
		periodEndLite.isBusy = false
		periodEndLite.stages = mapTemplateStageToStageLite(periodEndLite, template)
		periodEndLite.comments = []
		periodEndLite.attachments = []
		periodEndLite.openQueries = []
		periodEndLite.openConditions = []
		periodEndLite.actions = []
		//TODO:    periodEndLite.openQueries = periodEndLite.currentStage ? periodEndLite.currentStage.comments.filter(it => it.type === "Query") : []
		//TODO:    periodEndLite.openConditions = periodEndLite.currentStage ? periodEndLite.currentStage.conditions.filter(it => !it.isComplete) : []

		const updatedFavourite = updateFavourite(periodEndLite, user)
		periodEndLite.isFavourite = updatedFavourite.isFavourite
		periodEndLite.isAlert = updatedFavourite.isAlert
		periodEndLite.isUnread = isPeriodEndUnread(periodEndLite, user, local)

		periodEndLite.actions = []
	} catch (err) {
		console.error(err)
	}
}

function mapTemplateStageToStageLite(periodEndLite, template) {
	let nextStageStatus = "Closed"

	return template.stages.map(templateStage => {
		const stageLite = {
			periodEnd: periodEndLite,
			templateStage: templateStage,
			templateStageId: templateStage.id,
			conditions: [],
			comments: [],
			attachments: [],
			actions: [],
			status: nextStageStatus,
		}

		if (templateStage.id === periodEndLite.stage?.templateStageId) {
			periodEndLite.currentStage = {
				...stageLite,
				...periodEndLite.stage,
				conditions: templateStage.conditions.map(tc => {
					const condition = _.find(periodEndLite.stage.conditions, it => it.templateConditionId === tc.id) || {}
					return {
						...condition,
						templateCondition: tc,
						actions: [],
					}
				}),
			}

			periodEndLite.currentStage.actions = [...stageLiteActions(periodEndLite.currentStage)]

			nextStageStatus = "NotYetOpen"
			return periodEndLite.currentStage
		} else {
			return stageLite
		}
	})
}

function* stageLiteActions(stageLite) {
	if (["Member", "Approver", "Admin", "System"].includes(stageLite.periodEnd.property.acting?.role)) {
		if (stageLite.status !== "Closed") {
			yield "AddUpdateAttachment"

			//Comments & Queries
			yield "Comment"
			yield "Query"

			if (stageLite.periodEnd.property.acting.isInHouse || stageLite.templateStage.isInHouse === stageLite.periodEnd.property.acting.isInHouse) {
				yield "SetCondition"

				if (isClosable(stageLite)) {
					if (["Approver", "Admin", "System"].includes(stageLite.periodEnd.property.acting.role)) yield "Close"
					else if (stageLite.status === "Open") yield "RequestClose"
				}

				if (stageLite.status === "RequestClosed") {
					yield "CancelRequestClose"
				}

				if (stageLite.status === "Open" || stageLite.status === "RequestClosed") {
					yield "OnHold"
				}

				if (stageLite.Status === "OnHold") {
					yield "OffHold"
				}
			}

			// No longer supported - go to PE view if required if (DbContext.Options.AllowDraftQueries) stage.Actions.Add(StageAction.DraftQuery);
		}
	}
}

function* queryLiteActions(queryLite) {
	if (!queryLite.closedAt) {
		if (queryLite.isDraft) {
			if (queryLite.createdBy != null && queryLite.createdBy.isInHouse === queryLite.stage.periodEnd.property.acting.isInHouse) {
				yield "Update"
				yield "Send"
				if (queryLite.stage.periodEnd.property.acting.role === "Admin") yield "Delete"
			}
		} else {
			yield "ReplyTo"
			if (queryLite.stage.periodEnd.property.acting.isInHouse || queryLite.isInHouse === queryLite.stage.periodEnd.property.acting.isInHouse) {
				yield "Close"
				yield "Update"
				if (queryLite.stage.periodEnd.property.acting.role === "Admin") yield "Delete"
			}
		}

		yield "AddUpdateAttachment"
		queryLite.attachments?.forEach(qa => {
			if (queryLite.stage.periodEnd.property.acting.isInHouse || queryLite.isInHouse === queryLite.stage.periodEnd.property.acting.isInHouse)
				qa.actions = []
			else qa.actions = ["RemoveAttachment"]
		})
	}
}

function isClosable(stageLite) {
	//Any conditions incomplete?
	if (_.find(stageLite.conditions.filter(it => it.isComplete !== true && !it.deletedAt))) return false

	//Any previous stages have open queries
	if (
		_.find(
			stageLite.periodEnd.stages,
			it =>
				it.templateStage.sequence < stageLite.templateStage.sequence &&
				(it.countOfQueriesWithClient || it.countOfQueriesWithInHouse || it.countOfQueriesWithInHouseOnly)
		)
	)
		return false

	// if (PeriodEnd.DbContext.Options.AllowCloseStageIfQueriesRepliedTo)
	//     return Comments.OfType<Query>().All(it =>
	//         it.ClosedAt != null
	//         ||
	//         it.IsInHouse != TemplateStage.IsInHouse
	//     );

	return true
}

function queryIsCurrent(query) {
	if (!["Query", "ClosedQuery", "DraftQuery"].includes(query.type)) return undefined
	return !(query.stage.templateStage.sequence > query.stage.periodEnd.currentStage?.templateStage?.sequence)
}

function queryAgeInDays(query) {
	if (!["Query", "ClosedQuery", "DraftQuery"].includes(query.type)) return undefined
	if (!queryIsCurrent(query)) return null

	let latestActivity = query.replies.reduce((acc, it) => (it.createdAt > acc ? it.createdAt : acc), query.createdAt)
	latestActivity = (query.attachments || []).reduce((acc, it) => (it.createdAt > acc ? it.createdAt : acc), latestActivity)

	return moment(new Date()).diff(latestActivity, "days")
}

function materialiseQueryLite(queryLite, periodEndsArray) {
	const periodEnd = _.find(periodEndsArray, it => it.id === queryLite.periodEndId)
	if (!periodEnd) {
		// console.warn(periodEndLite.propertyId, propertiesArray, "PROPERTY NOT FOUND!")
		return undefined
	}
	const stage = _.find(periodEnd.stages, it => it.templateStageId === queryLite.templateStageId)
	if (!stage) {
		console.warn(queryLite.templateStageId, "TEMPLATESTAGEID NOT FOUND!")
		return undefined
	}

	queryLite.stage = stage
	stage.comments = stage.comments.filter(it => it.id !== queryLite.id).concat([queryLite])

	try {
		queryLite.attachments = undefined
		queryLite.actions = [...queryLiteActions(queryLite)]

		if (_.find(queryLite.replies, it => it.$type === "Closed") != null) {
			periodEnd.openQueries = periodEnd.openQueries.filter(it => it.id !== queryLite.id).concat([queryLite])
		}
	} catch (err) {
		console.error(err)
	}

	queryLite.isCurrent = queryIsCurrent(queryLite)
	queryLite.ageInDays = queryAgeInDays(queryLite)
}

function materialisePeriodEnd(periodEnd, propertiesArray, templatesArray, user, local) {
	const property = _.find(propertiesArray, it => it.id === periodEnd.propertyId)
	if (!property) {
		console.warn(periodEnd.propertyId, propertiesArray, "PROPERTY NOT FOUND!")
		return
	}

	periodEnd.$type = "periodEnd"
	periodEnd.property = property
	property.periodEnds = property.periodEnds.filter(it => it.id !== periodEnd.id).concat([periodEnd])

	const template = _.find(templatesArray, it => it.id === periodEnd.templateId)
	if (!template) {
		console.warn(periodEnd.templateId, templatesArray, "TEMPLATE NOT FOUND!")
		return
	}
	periodEnd.template = template

	try {
		periodEnd.isBusy = false
		periodEnd.currentStage = _.find(periodEnd.stages, it => !["NotYetOpen", "Closed"].includes(it.status))
		periodEnd.openQueries = periodEnd.currentStage ? periodEnd.currentStage.comments.filter(it => it.type === "Query") : []
		periodEnd.openConditions = periodEnd.currentStage ? periodEnd.currentStage.conditions.filter(it => !it.isComplete) : []

		periodEnd.stages.forEach(stage => {
			stage.periodEnd = periodEnd
			const templateStage = _.find(template.stages, it => it.id === stage.templateStageId)
			if (!templateStage) {
				console.warn(periodEnd.templateId, stage.templateStageId, template.stages, "TEMPLATE STAGE NOT FOUND!")
				return
			}
			stage.templateStage = templateStage
			stage.comments.forEach(comment => {
				comment.stage = stage
				comment.isCurrent = queryIsCurrent(comment)
				comment.ageInDays = queryAgeInDays(comment)
			})
			stage.conditions.forEach(condition => {
				const templateCondition = _.find(templateStage.conditions, it => it.id === condition.templateConditionId)
				if (!templateCondition) {
					console.warn(
						periodEnd.templateId,
						stage.templateStageId,
						condition.templateConditionId,
						templateStage.conditions,
						"TEMPLATE CONDITION NOT FOUND!"
					)
					return
				}
				condition.templateCondition = templateCondition
			})

			stage.badges = {
				comments: stage.comments.filter(it => it.type === "Comment")?.length,
				attachments: stage.attachments?.length,
				openQueriesWithUs: stage.comments.filter(it => it.type === "Query" && it.isWithUs === true)?.length,
				openQueriesWithThem: stage.comments.filter(it => it.type === "Query" && it.isWithUs === false)?.length,
				draftQueries: stage.comments.filter(it => it.type === "DraftQuery")?.length,
				closedQueries: stage.comments.filter(it => it.type === "ClosedQuery")?.length,
			}
		})

		const commentsToStage = periodEnd.stages
			.filter(it => (it.templateStage?.sequence || 0) <= (periodEnd.currentStage?.templateStage?.sequence || 999))
			.reduce(
				(current, next) => ({
					openQueriesWithUs: current.openQueriesWithUs + next.comments?.filter(it => it.type === "Query" && it.isWithUs === true)?.length || 0,
					openQueriesWithThem: current.openQueriesWithThem + next.comments?.filter(it => it.type === "Query" && it.isWithUs === false)?.length || 0,
					draftQueries: current.draftQueries + next.comments?.filter(it => it.type === "DraftQuery")?.length || 0,
				}),
				{
					openQueriesWithUs: 0,
					openQueriesWithThem: 0,
					draftQueries: 0,
				}
			)

		periodEnd.badges = {
			comments: periodEnd.currentStage?.comments.filter(it => it.type === "Comment")?.length,
			attachments: periodEnd.currentStage?.attachments?.length,
			closedQueries: periodEnd.currentStage?.comments.filter(it => it.type === "ClosedQuery")?.length,
			...commentsToStage,
		}

		const updatedFavourite = updateFavourite(periodEnd, user)
		periodEnd.isFavourite = updatedFavourite.isFavourite
		periodEnd.isAlert = updatedFavourite.isAlert
		periodEnd.isUnread = isPeriodEndUnread(periodEnd, user, local)
	} catch (err) {
		console.error(err)
	}
}

function isPeriodEndAlert(periodEnd, since, user) {
	return periodEnd.changedAt > since && periodEnd.changedById !== user.id
}

function isPeriodEndUnread(periodEnd, user, local) {
	if (!local.periodEndsMeta) return false
	if (["Closed", "Deleted"].includes(periodEnd.status)) return false
	if (periodEnd.currentStage?.templateStage?.isInHouse !== user.isInHouse) return false

	const lastReadAt = local.periodEndsMeta[periodEnd.id]?.read
	if (!lastReadAt) return true

	if (periodEnd.changedById === user.id) return false

	if (!periodEnd.changedAt) return false
	return new Date(periodEnd.changedAt).valueOf() > lastReadAt
}

function updateFavourite(periodEnd, user) {
	const isFavourite = _.find(user.favourites, f => f.periodEndId === periodEnd.id)
	const isAlert = isFavourite && isPeriodEndAlert(periodEnd, isFavourite.alertsResetAt, user)

	return {
		isFavourite: isFavourite,
		isAlert: isAlert,
	}
}

function setPeriodEndUnread(state, periodEnd, isUnread) {
	const newState = {
		...state,
		updatedAt: new Date().toISOString(),
	}

	newState.local.periodEndsMeta[periodEnd.id] = {
		id: periodEnd.id,
		read: isUnread ? 0 : periodEndReadNow(periodEnd),
	}
	newState.periodEnds.dict[periodEnd.reference].isUnread = isUnread
	localStorage.setItem("periodEndsMeta", JSON.stringify(newState.local.periodEndsMeta))

	return newState
}

function setPeriodEndsAllRead(state) {
	const newState = {
		...state,
		updatedAt: new Date().toISOString(),
	}

	const allPeriodEndsMeta = ACTIONS.dictionaryToArray(newState.periodEnds.dict).map(periodEnd => {
		periodEnd.isUnread = false
		return {
			id: periodEnd.id,
			read: periodEndReadNow(periodEnd),
		}
	})
	newState.local.periodEndsMeta = ACTIONS.arrayToDictionary(allPeriodEndsMeta, it => it.id)
	localStorage.setItem("periodEndsMeta", JSON.stringify(newState.local.periodEndsMeta))

	return newState
}

function periodEndReadNow(periodEnd) {
	return periodEnd.changedAt ? new Date(periodEnd.changedAt).valueOf() : new Date().valueOf()
}

export default clientsReducer
