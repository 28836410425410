import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Auth } from "aws-amplify"

import { Modal, ModalHeader, ModalBody, Button } from "../../components"

import * as ACTIONS from "../../store/actions"

class Toast extends React.Component {
	static propTypes = {
		category: PropTypes.string,
		title: PropTypes.string,
		message: PropTypes.string,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.handleClose = this.handleClose.bind(this)
	}

	handleClose(event) {
		switch (event.target.id) {
			case "Reload":
				// window.location.reload(true)
				window.location.href = "/"
				break

			case "Login":
				Auth.signOut()
					.then(data => (window.location.href = "/"))
					.catch(err => console.error(err))
				break

			default:
				this.props.removeToast()
		}
	}

	render() {
		const { category, title, message } = this.props

		const flavour = {
			SUCCESS: "success",
			ERROR: "danger",
			WARNING: "warning",
			INFO: "info",
			TIMEOUT: "attention",
		}[category]

		const icon = {
			SUCCESS: "fa-check",
			ERROR: "fa-close",
			WARNING: "fa-exclamation",
			INFO: "fa-info",
			TIMEOUT: "fa-hourglass-end",
		}[category]

		return (
			<Modal isOpen={!!category} autoFocus={true} centered={true} className={`modal-${flavour}`}>
				<ModalHeader className="py-3" />
				<ModalBody className="text-center px-5">
					<i className={`fa fa-5x ${icon} fa-fw mb-3 modal-icon`}></i>
					<h6>{title}</h6>
					<p className="modal-text toast-message">{message}</p>
					<h6 hidden={category !== "ERROR"} style={{ marginBottom: "1.5em" }}>
						Unexpected errors can sometimes be resolved by Reloading Clearview.
					</h6>
					<Button hidden={category === "TIMEOUT"} color={flavour} className="mr-2" id="Close" onClick={e => this.handleClose(e)}>
						Ok
					</Button>
					<Button
						hidden={!["ERROR", "WARNING"].includes(category)}
						color={flavour}
						outline
						className="mr-2"
						id="Reload"
						onClick={e => this.handleClose(e)}
					>
						Reload Clearview
					</Button>
					<Button hidden={category !== "TIMEOUT"} color={flavour} className="mr-2" id="Login" onClick={e => this.handleClose(e)}>
						Login
					</Button>
				</ModalBody>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		...state.toastReducer.toast,
	}
}

const mapDispatchToProps = dispatch => ({
	removeToast: () =>
		dispatch({
			type: ACTIONS.REMOVE_TOAST,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(Toast)
