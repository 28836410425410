import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import _ from "lodash"
import * as ACTIONS from "../../../../store/actions"

import { CustomInput, Input, Table } from "../../../../components"

const isActual = it => it.id || it.value

const AttributeEditor = ({ attribute, handleChange }) => {
	if (attribute.values?.length) {
		return (
			<CustomInput type="select" name={attribute.name} id={attribute.typeId} className="ml-auto" defaultValue={attribute.value} onChange={handleChange}>
				{attribute.values.map((it, idx) => (
					<option key={idx}>{it}</option>
				))}
				<option value=""></option>
			</CustomInput>
		)
	}

	return (
		<React.Fragment>
			{
				{
					undefined: (
						<Input
							type="text"
							name={attribute.name}
							id={attribute.typeId}
							placeholder={attribute.narrative}
							defaultValue={attribute.value}
							onChange={handleChange}
						/>
					),
					0: (
						<Input
							type="text"
							name={attribute.name}
							id={attribute.typeId}
							placeholder={attribute.narrative}
							defaultValue={attribute.value}
							onChange={handleChange}
						/>
					),
					2: (
						<Input
							type="number"
							name={attribute.name}
							id={attribute.typeId}
							placeholder={attribute.narrative}
							defaultValue={attribute.value}
							onChange={handleChange}
						/>
					),
					3: (
						<Input
							type="date"
							name={attribute.name}
							id={attribute.typeId}
							placeholder={attribute.narrative}
							defaultValue={attribute.value}
							onChange={handleChange}
						/>
					),
				}[attribute.dataType]
			}
		</React.Fragment>
	)
}

class AttributesTable extends Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		name: PropTypes.string,
		isEditing: PropTypes.bool,
		onChange: PropTypes.func,
		customerTemplates: PropTypes.array.isRequired,
	}

	static defaultProps = {
		isEditing: false,
		onChange: () => {},
	}

	constructor(props) {
		super(props)
		this.props = props

		const { business } = props

		const attributeTypes = _.find(this.props.customerTemplates, it => it.lft <= business.lft && it.rgt >= business.rgt)?.attributeTypes.filter(
			it => it.type === `${business.type}AttributeType`
		)

		this.state = {
			attributes: attributeTypes?.map(attributeType => {
				const attribute = _.find(business.attributes, it => it.typeId === attributeType.id) || {
					typeId: attributeType.id,
					name: attributeType.name,
					value: "",
				}

				attribute.narrative = attributeType.narrative
				attribute.values = attributeType.values
				attribute.dataType = attributeType.dataType
				return attribute
			}),
		}

		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(e) {
		const attributes = this.state.attributes.map(attribute => {
			if (attribute.typeId === parseInt(e.target.id)) {
				attribute.value = e.target.value
			}
			return attribute
		})

		this.setState({
			attributes: attributes,
		})

		this.props.onChange({
			target: {
				name: "attributes",
				value: attributes.filter(isActual),
			},
		})
	}

	render() {
		const { isEditing } = this.props

		let attributes = this.state.attributes

		if (!attributes || !attributes.length) return null

		return (
			<Table size="sm">
				<tbody>
					<tr>
						<th colSpan={2}>Attributes</th>
					</tr>
					{_.sortBy(attributes, it => it.name)
						.filter(attribute => isEditing || isActual(attribute))
						.map(attribute => (
							<tr key={attribute.name}>
								<td className="align-top">{attribute.name}</td>
								<td className="align-top">
									{!isEditing && <span>{attribute.value}</span>}
									{isEditing && <AttributeEditor attribute={attribute} handleChange={this.handleChange} />}
								</td>
							</tr>
						))}
				</tbody>
			</Table>
		)
	}
}

const mapStateToProps = state => {
	const customerTemplates = ACTIONS.getSubState(state, "clientsReducer\\templates")

	return {
		customerTemplates: ACTIONS.dictionaryToArray(customerTemplates.dict),
	}
}

export default connect(mapStateToProps)(AttributesTable)
