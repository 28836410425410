import React from "react"

import _ from "lodash"

import PropTypes from "prop-types"

import { Accordion, Button, Form, FormGroup, InputGroupAddon, CustomInput, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"

import { LedgerItemAvatar } from "./LedgerItemAvatar"
import { SmartMenu } from "../../../components/SmartMenu"

import { validateLedgerItem } from "./Parsers"
import * as clearview from "../../../../components/@Clearview"

const EMPTY_STATE = {
	isOpen: false,
	isChanged: false,
	actions: () => [],
	ledgerItem: {
		vendorName: "",
		ref: "",
		page: "",
		narrative: "",
		date: "",
		total: "",
		accountId: 0,
	},
}

export default class LedgerItemEdit extends React.Component {
	static propTypes = {
		onCancel: PropTypes.func.isRequired,
		onSave: PropTypes.func.isRequired,
		ledgerItem: PropTypes.object,
		accounts: PropTypes.array,
	}

	constructor(props) {
		super(props)
		this.state = EMPTY_STATE

		this.handleChange = this.handleChange.bind(this)
		this.handleChangeCategory = this.handleChangeCategory.bind(this)
	}

	static getDerivedStateFromProps(props, state) {
		if (!props.ledgerItem) return EMPTY_STATE

		if (state.isOpen) return null

		const ledgerItem = validateLedgerItem({ ...EMPTY_STATE.ledgerItem, ..._.cloneDeep(props.ledgerItem) })

		return {
			isOpen: true,
			isChanged: false,
			actions: props.actions || (() => []),
			ledgerItem: ledgerItem,
		}
	}

	handleChangeCategory(e) {
		const typedValue = parseInt(e.target.value)
		const account = this.props.accounts.find(it => it.id === typedValue)

		this.setState(prevState => ({
			isChanged: true,
			ledgerItem: validateLedgerItem({
				...prevState.ledgerItem,
				accountId: typedValue,
				account: account,
			}),
		}))
	}

	handleChange(e) {
		let typedValue = e.target.value
		if (e.type === "blur" && e.target.name === "total") typedValue = clearview.parseCurrency(e.target.value)

		this.setState(prevState => ({
			isChanged: true,
			ledgerItem: validateLedgerItem({
				...prevState.ledgerItem,
				[e.target.name]: typedValue,
			}),
		}))
	}

	render() {
		const { isOpen, isChanged, ledgerItem, actions } = this.state

		return (
			<Modal id="ledgerItem-edit" isOpen={isOpen}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">
						<div className="d-flex-row justify-content-between align-items-center">
							<LedgerItemAvatar ledgerItem={ledgerItem}></LedgerItemAvatar>
							<SmartMenu actions={actions(ledgerItem)} predicate={it => it.id !== "edit"}></SmartMenu>
							<span id="ledgerItem-edit-popover-target">Edit Expenditure Item</span>
							<span className="flex-1">&nbsp;</span>
							{ledgerItem.original && (
								<Accordion>
									<Accordion.Header className="text-info" title="Values have been edited">
										Original Values
									</Accordion.Header>
									<Accordion.Body>
										<b>Vendor</b>: {ledgerItem.original?.vendorName}
										<br />
										<b>Invoice Ref.</b>: {ledgerItem.original?.ref}
										<br />
										<b>Narrative</b>: {ledgerItem.original?.narrative}
										<br />
										<b>Date</b>: {ledgerItem.original?.date}
										<br />
										<b>Amount</b>: {clearview.FormatCurrency(ledgerItem.original?.total)}
										<br />
										<b>Category</b>: {ledgerItem.original?.account?.name}
									</Accordion.Body>
								</Accordion>
							)}
						</div>
					</ModalHeader>

					<ModalBody>
						<h6>
							<b>{ledgerItem.file}</b>
							<br />
							<b>{new Date(ledgerItem.lastModified).toString()}</b>
						</h6>
						<h6 className="d-flex-row justify-content-between">
							<span>
								Row: <b>{ledgerItem.row}</b>
							</span>
						</h6>

						<FormGroup row>
							<InputGroupAddon addonType="prepend">Vendor</InputGroupAddon>
							<CustomInput
								type="text"
								key="vendorName"
								id="vendorName"
								name="vendorName"
								className="form-control flex-1"
								invalid={!ledgerItem.vendorName}
								value={ledgerItem.vendorName}
								onChange={e => this.handleChange(e)}
							></CustomInput>
						</FormGroup>

						<FormGroup row>
							<InputGroupAddon addonType="prepend">Invoice Ref.</InputGroupAddon>
							<CustomInput
								type="text"
								key="ref"
								id="ref"
								name="ref"
								className="form-control flex-1"
								invalid={!ledgerItem.ref}
								value={ledgerItem.ref}
								autoComplete="off"
								onChange={e => this.handleChange(e)}
							></CustomInput>
						</FormGroup>
						<FormGroup row>
							<InputGroupAddon addonType="prepend">Narrative</InputGroupAddon>
							<Input
								type="textarea"
								rows={4}
								key="narrative"
								id="narrative"
								name="narrative"
								className="form-control flex-1"
								value={ledgerItem.narrative}
								onChange={e => this.handleChange(e)}
							></Input>
						</FormGroup>
						<FormGroup row>
							<div className="flex-1 d-flex-row" style={{ width: "50%" }}>
								<InputGroupAddon addonType="prepend">Date</InputGroupAddon>
								<CustomInput
									type="date"
									key="date"
									id="date"
									name="date"
									className="form-control flex-1"
									invalid={!ledgerItem.date}
									value={ledgerItem.date}
									onChange={e => this.handleChange(e)}
								></CustomInput>
							</div>

							<div className="flex-1 d-flex-row" style={{ width: "50%" }}>
								<InputGroupAddon addonType="prepend">Amount</InputGroupAddon>
								<CustomInput
									type="number"
									key="total"
									id="total"
									name="total"
									disabled={["A", "M"].includes(ledgerItem.status)}
									className="form-control currency flex-1"
									invalid={!ledgerItem.total}
									value={clearview.formatCurrencyValue(ledgerItem.total)}
									onChange={e => this.handleChange(e)}
									onBlur={e => this.handleChange(e)}
								></CustomInput>
							</div>
						</FormGroup>

						<FormGroup row>
							<InputGroupAddon addonType="prepend">Category</InputGroupAddon>
							<CustomInput
								type="select"
								key="accountId"
								id="accountId"
								name="accountId"
								className="form-control flex-1"
								invalid={!ledgerItem.accountId}
								value={ledgerItem.accountId}
								onChange={e => this.handleChangeCategory(e)}
							>
								{this.props.accounts.map(it => (
									<option key={it.id} value={it.id}>
										{it.name}
									</option>
								))}
							</CustomInput>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<Button color="link" className="text-primary" onClick={e => this.props.onCancel()}>
							Cancel
						</Button>
						<Button key="save" disabled={!isChanged} color="default" className="text-primary" onClick={e => this.props.onSave(ledgerItem)}>
							Save
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}
