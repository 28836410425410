export const ERROR = "ERROR:An error has occurred!"

export const LOAD = "WP:LOAD"
export const LOADED = "WP:LOADED"

export const SAVE = "WP:SAVE"
export const SAVE_NOMINAL_CODES = "WP:SAVE_NOMINAL_CODES"
export const SAVED = "WP:SAVED"

export const DELETE_ROW = "WP: DELETE_ROW"
export const SAVE_ROW = "WP: SAVE_ROW"
export const SAVE_SCHEDULE_CODE_ROW = "WP: SAVE_SCHEDULE_CODE_ROW"
export const SAVE_FEES = "WP: SAVE_FEES"
export const SAVE_DETAILS = "WP: SAVE_DETAILS"
export const SAVE_LEDGER_ROW = "WP: SAVE_LEDGER_ROW"
export const APPLY_LEDGER_ALIAS = "WP: APPLY_LEDGER_ALIAS"

export const READ_COMMON_WORKSHEET = "WP: READ_COMMON_WORKSHEET"
export const READ_CRS_AND_ACCRUALS_WORKSHEET = "WP:READ_CRS_AND_ACCRUALS_WORKSHEET"
export const READ_DRS_AND_PREPAY_WORKSHEET = "WP:READ_DRS_AND_PREPAY_WORKSHEET"
export const READ_INCOME_REPORT_WORKSHEET = "WP:READ_INCOME_REPORT_WORKSHEET"
export const READ_COMPARATIVES_WORKSHEET = "WP:READ_COMPARATIVES_WORKSHEET"

export const IMPORT_UPDATED_FEE_MATRIX = "WP:IMPORT_UPDATED_FEE_MATRIX"
export const IMPORT_VOUCHING = "WP:IMPORT_VOUCHING"
export const IMPORTED_VOUCHING = "WP:IMPORTED_VOUCHING"

export const SHOW_CHOOSE_TEMPLATE = "WP:SHOW_CHOOSE_TEMPLATE"
export const SELECT_TEMPLATE = "WP:SELECT_TEMPLATE"
export const SELECTED_TEMPLATE = "WP:SELECTED_TEMPLATE"
export const LOAD_REPORT = "WP:LOAD_REPORT"
export const LOADED_REPORT = "WP:LOADED_REPORT"

export const UPDATE_REPORT_TEMPLATE = "WP:UPDATE_REPORT_TEMPLATE"
export const UPDATE_REPORT_PARAMETERS = "WP:UPDATE_REPORT_PARAMETERS"
