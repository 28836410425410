import React from "react"
import PropTypes from "prop-types"

import { Button, Modal, ModalHeader, ModalFooter } from "../../components"

export default class AreYouSureModal extends React.Component {
	static propTypes = {
		isOpen: PropTypes.bool,
		message: PropTypes.node,
		action: PropTypes.func,
		cancel: PropTypes.func,
	}

	render() {
		const { isOpen, message, action, cancel } = this.props

		return (
			<Modal isOpen={isOpen}>
				<ModalHeader tag="div">
					<div className="d-flex">
						<h1 className="text-primary mr-2">
							<i className="fa fa-fw fa-question-circle-o"></i>
						</h1>
						<h6>{message}</h6>
					</div>
				</ModalHeader>
				<ModalFooter>
					<Button
						color="link"
						className="text-primary"
						onClick={() => {
							if (cancel) cancel()
							return true
						}}
					>
						Cancel
					</Button>
					<Button
						color="default"
						className="text-primary"
						onClick={() => {
							if (action) action()
							return true
						}}
					>
						Ok
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}
