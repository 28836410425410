import React from "react"
import { connect } from "react-redux"

import * as clearview from "../../../../../components/@Clearview"

import { SimpleTable } from "../../../../../components"

function FeeAllocation({ fees }) {
	if (!fees.allocations?.length) return <p className="text-info">There are no fees applicable to the current data.</p>

	const columns = {
		schedule: { map: clearview.tidy.string },
		total: {
			heading: <span>Fee £</span>,
			map: clearview.tidy.currency,
			className: "currency text-dark",
		},
	}

	return (
		<div>
			<SimpleTable
				className="fee-allocation-table compact-table mt-2"
				columns={columns}
				data={fees.allocations}
				groupBy={["code", "account"]}
				summaryColumns={["total"]}
			/>
			{clearview.listMessages(fees?.messages)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		fees: state.workingPapersReducer.workingPapers.fees,
	}
}

FeeAllocation = connect(mapStateToProps)(FeeAllocation)

export { FeeAllocation }
