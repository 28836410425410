import React from "react"
import PropTypes from "prop-types"

import CommentsTable from "./CommentsTable"
import { PeriodEndFolders } from "../../PeriodEnds/components/PeriodEndFolders"

import { StageActionsLinks } from "./StageActions"
import Conditions from "./Conditions"

class Stage extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props

		this.state = { showLog: false }
		this.onChangeShowLog = this.onChangeShowLog.bind(this)
	}

	onChangeShowLog() {
		this.setState(prevState => ({ showLog: !prevState.showLog }))
	}

	render() {
		const { periodEnd, stage } = this.props
		const { showLog } = this.state

		return (
			<React.Fragment key={stage.id}>
				<CommentsTable className="mb-0" stage={stage} showLog={showLog} />

				{stage.conditions && stage.conditions.length ? <Conditions periodEnd={periodEnd} stage={stage} /> : null}

				{periodEnd.status === "Open" && periodEnd.currentStage === stage && <PeriodEndFolders periodEnd={periodEnd} />}
				<div className="mt-2 mb-2 text-right">
					<StageActionsLinks key="sal" periodEnd={periodEnd} stage={stage} showLog={showLog} onChangeShowLog={this.onChangeShowLog} />
				</div>
			</React.Fragment>
		)
	}
}

export { Stage }
