import React from "react"
import PropTypes from "prop-types"
import { Typeahead } from "react-bootstrap-typeahead"
import _ from "lodash"
import Dropdown from "react-bootstrap/Dropdown"

import { FormGroup, Input, Button, InputGroup, InputGroupAddon, ExtendedDropdown } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"

class PropertiesFilter extends React.Component {
	static propTypes = {
		properties: PropTypes.array.isRequired,
		onFilterChange: PropTypes.func.isRequired,
		message: PropTypes.string,
		className: PropTypes.string,
	}

	constructor(props) {
		super(props)

		this.props = props
		this.state = {}

		this.handleChange = this.handleChange.bind(this)
		this.handleRadioChange = this.handleRadioChange.bind(this)
		this.filterCount = this.criteriaCount.bind(this)
		this.clearFilters = this.clearFilters.bind(this)
	}

	predicate = property => {
		if (!this.state.client || property.parent.id === this.state.client.id || (property.parent.parent && property.parent.parent.id === this.state.client.id))
			return true

		return false
	}

	handleChange(option, type) {
		this.setState(
			{
				[type]: _.first(option),
			},
			() => this.props.onFilterChange(this.predicate.bind(this))
		)
	}

	handleRadioChange(event, type) {
		this.setState(
			{
				[type]: event.target.value,
			},
			() => this.props.onFilterChange(this.predicate.bind(this))
		)
	}

	criteriaCount() {
		return Object.keys(this.state).reduce((acc, key) => (this.state[key] ? acc + 1 : acc), 0)
	}

	clearFilters(event, type) {
		this.setState(
			{
				client: undefined,
			},
			() => this.props.onFilterChange(this.predicate.bind(this))
		)
	}

	render() {
		const { properties } = this.props

		const clients = _.sortBy(
			_.uniqBy(
				properties
					.map(it => it.parent)
					.concat(properties.map(it => it.parent.parent))
					.filter(it => it),
				it => it.id
			).map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
			it => it._title
		)

		return (
			<InputGroup className={this.props.className} size="sm">
				<InputGroupAddon addonType="prepend">
					<Dropdown className="align-self-center ml-auto" direction="left">
						<Dropdown.Toggle
							color="link"
							title="Properties Filter..."
							outline
							caret
							variant="none"
							className="btn-outline-secondary"
							style={{ paddingTop: 2, paddingBottom: 2 }}
						>
							{this.criteriaCount() === 0 && clearview.Icon.filter}
							{this.criteriaCount() !== 0 && <span className="text-primary">{clearview.Icon.filter}</span>}
						</Dropdown.Toggle>
						<ExtendedDropdown>
							<ExtendedDropdown.Section className="d-flex justify-content-between align-items-center">
								<FormGroup row className="flex-1">
									<InputGroup>
										<InputGroupAddon addonType="prepend">{clearview.Icon.Business.Client}</InputGroupAddon>
										<Typeahead
											id="Client"
											labelKey="_title"
											options={clients}
											placeholder="Filter by client/managing agent/landlord..."
											allowNew={false}
											caseSensitive={false}
											clearButton={true}
											highlightOnlyResult={true}
											value={this.state.client}
											onChange={e => this.handleChange(e, "client")}
											defaultSelected={this.state.client ? [this.state.client] : []}
										/>
									</InputGroup>
								</FormGroup>
							</ExtendedDropdown.Section>
						</ExtendedDropdown>
					</Dropdown>
				</InputGroupAddon>
				<Input disabled hidden={!this.props.message} value={this.props.message} className="bg-white" />
				{this.criteriaCount() !== 0 && (
					<InputGroupAddon addonType="append" title="Clear filters">
						<Button outline onClick={() => this.clearFilters()}>
							<i className="fa fa-fw fa-times"></i>
						</Button>
					</InputGroupAddon>
				)}
			</InputGroup>
		)
	}
}

export { PropertiesFilter }
