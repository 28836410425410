import React, { useState } from "react"
import { connect } from "react-redux"

import * as _ from "lodash"

import * as clearview from "../../../../../components/@Clearview"
import { FormGroup, CustomInput, Input, Col, Alert } from "../../../../../components"

export const DetailsDataTables = [
	"AnnualDeclarationOfManagingAgentsIncomeAndOtherBenefitTable",
	"GeneralReserveFundTable",
	"AnalysisOfReserveFundsGeneralReserveFundTable",
	"AnalysisOfExpenditurePaidFromReserveFundTable",
	"AnalysisOfRepairsAndMaintenanceTable",
]

function ParametersEditor({ isReadOnly, parameters, periodEnd, setParameters }) {
	const climbRecipients = client => {
		if (!client) return []
		if (client.role === "Customer") return []
		return [{ role: client.role, name: client.name }, ...climbRecipients(client.parent)]
	}

	const leaseholdOrFreeholdOptions = [
		{ type: "Leasehold", subject: "lessee", object: "lease" },
		{ type: "Freehold", subject: "freeholder", object: "relevant land registry document" },
	]

	const [formValue, setFormValue] = useState(parameters ?? {})

	const onChangeParameterValue = e => {
		const parameter = formValue[e.target.name]

		switch (e.target.name) {
			// case "leaseholdOrFreehold":
			// 	parameter.value = e.target.value ? JSON.parse(e.target.value) : null
			// 	setFormValue({ ...formValue, [e.target.name]: e.target.value ? JSON.parse(e.target.value) : null })
			// 	break

			default:
				parameter.value = e.target.value
		}

		setFormValue({ ...formValue, [e.target.name]: parameter })
	}

	const onBlurParameterValue = e => {
		//Update report parameters on blur
		setParameters(formValue)
	}

	const onPasteTable = e => {
		const clipText = e.clipboardData.getData("text/plain")

		const keepColumns = []
		let textGrid = clipText.split(/\r\n|\n|\r/g).map(textRow => {
			const cells = textRow.split(/\t/g)
			if (_.every(cells, it => !it)) return null

			for (var cIdx = 0; cIdx < cells.length; cIdx++) {
				cells[cIdx] = clearview.SafeTrim(cells[cIdx])
				if (!!cells[cIdx] && !keepColumns.includes(cIdx)) keepColumns.push(cIdx)
			}
			return cells
		})

		// Remove leading & trailing rows
		const removeLeadingRowsReducer = rows => {
			let hasContent = false
			return rows.reduce((agg, row) => {
				if (row || hasContent) {
					hasContent = true
					agg.push(row)
				}
				return agg
			}, [])
		}
		textGrid = removeLeadingRowsReducer(textGrid)
		textGrid = removeLeadingRowsReducer(textGrid.reverse()).reverse()

		const hasTotalRow = textGrid.length > 1 && textGrid[textGrid.length - 2] == null

		// Without any blank rows & columns
		textGrid = textGrid.filter(it => _.isArray(it)).map(row => row.filter((cell, cIdx) => keepColumns.includes(cIdx)))

		const isHeaderValue = cell => cell && !_.isNumber(clearview.parseCurrency(cell)) && cell !== "-"

		const table = {
			class: "details-table",
			rows: textGrid.map((cells, rowIdx) => {
				const isHeadingRow = rowIdx === 0 || !!cells.find((cell, cellIdx) => cellIdx > 0 && isHeaderValue(cell))
				const isTotalRow = hasTotalRow && rowIdx === textGrid.length - 1

				const row = {
					tr: cells.shift(),
					columns: cells.map(cell => {
						const column = { text: cell }
						if (isHeadingRow) column.class = "column-header"
						else if (isTotalRow) column.class = "column-total"
						return column
					}),
				}
				if (isTotalRow || row.columns.find(it => it.class === "column-header")) row.class = "row-header"
				return row
			}),
		}

		setFormValue({ ...formValue, [e.target.name]: table })
	}

	const onClearTable = e => setFormValue({ ...formValue, [e.target.name]: undefined })

	const colorIfBlank = (value, severity = "danger") => (value ? "info" : severity)
	const colorIfNotInList = (values, value, propertyPredicate, severity = "danger") =>
		clearview.includesObject(values, value, propertyPredicate) ? "info" : severity

	const formData = Object.keys(formValue).map(key => ({
		key: key,
		...formValue[key],
	}))

	return (
		<React.Fragment>
			{formData.map(it => (
				<FormGroup key={it.key} row>
					<Col sm={4}>
						<Alert color={colorIfBlank(it.value)}>
							<h6 className="mb-1 alert-heading">{it.label ?? _.startCase(it.key)}</h6>
							{it.narrative}
						</Alert>
					</Col>
					<Col sm={5}>
						<CustomInput
							style={{ width: "16em" }}
							disabled={isReadOnly}
							type="text"
							name={it.key}
							id={it.key}
							className="form-control"
							invalid={it.value === undefined}
							value={it.value}
							onChange={onChangeParameterValue}
							onBlur={onBlurParameterValue}
						/>
					</Col>
				</FormGroup>
			))}
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		details: state.workingPapersReducer.workingPapers.details,
		periodEnd: state.workingPapersReducer.workingPapers.periodEnd,
	}
}

const mapDispatchToProps = dispatch => ({
	onSaveDetails: details => alert("ToDo"),
	// dispatch({
	// 	type: WP_ACTIONS.SAVE_DETAILS,
	// 	details,
	// }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ParametersEditor)

/*
			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfBlank(value.previousDuration)}>
						<h6 className="mb-1 alert-heading">Previous period duration</h6>
						This is the value of the <b>details.previousDuration</b> token in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<CustomInput
						style={{ width: "16em" }}
						disabled={isReadOnly}
						type="text"
						name="previousDuration"
						id="previousDuration"
						className="form-control"
						invalid={!value.previousDuration}
						value={value.previousDuration}
						onChange={onChangeValue}
						onBlur={onChangeDetails}
					/>
				</Col>
			</FormGroup>

			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfBlank(value.signOffDate)}>
						<h6 className="mb-1 alert-heading">Sign-off date</h6>
						This is the value of the <b>details.signOffDate</b> token in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<CustomInput
						style={{ width: "12em" }}
						disabled={isReadOnly}
						type="date"
						name="signOffDate"
						id="signOffDate"
						className="form-control"
						invalid={!value.signOffDate}
						value={value.signOffDate}
						onChange={onChangeValue}
						onBlur={onChangeDetails}
					/>
				</Col>
			</FormGroup>

			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfBlank(value.typeOfReport)}>
						<h6 className="mb-1 alert-heading">Type of Report</h6>
						This is the value of the <b>details.typeOfReport</b> token in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<CustomInput
						disabled={isReadOnly}
						type="select"
						name="typeOfReport"
						id="typeOfReport"
						className="form-control"
						invalid={!value.typeOfReport}
						value={value.typeOfReport}
						onChange={onChangeValue}
						onBlur={onChangeDetails}
					>
						<option key="-1"></option>
						<option value="Statement of Service Charge Expenditure">Statement of Service Charge Expenditure (Commercial)</option>
						<option value="Service Charge Accounts">Service Charge Accounts (Residential)</option>
					</CustomInput>
				</Col>
			</FormGroup>

			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfBlank(value.recipient)}>
						<h6 className="mb-1 alert-heading">Landlord/Managing Agent</h6>
						This is the value of the <b>details.recipient</b> token in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<CustomInput
						disabled={isReadOnly}
						type="select"
						name="recipient"
						id="recipient"
						className="form-control"
						invalid={!value.recipient}
						value={value.recipient}
						onChange={onChangeValue}
						onBlur={onChangeDetails}
					>
						<option key="-1"></option>
						{climbRecipients(periodEnd.property.parent).map((it, idx) => (
							<option key={idx} value={it.name}>
								{it.name} ({it.role})
							</option>
						))}
					</CustomInput>
				</Col>
			</FormGroup>

			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfNotInList(leaseholdOrFreeholdOptions, value.leaseholdOrFreehold, it => it.type)}>
						<h6 className="mb-1 alert-heading">Leasehold or Freehold</h6>
						This is the value of the <b>details.leaseholdOrFreehold.type,.subject,.object</b> tokens in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<CustomInput
						disabled={isReadOnly}
						type="select"
						name="leaseholdOrFreehold"
						id="leaseholdOrFreehold"
						className="form-control"
						invalid={!clearview.includesObject(leaseholdOrFreeholdOptions, value.leaseholdOrFreehold, it => it.type)}
						value={JSON.stringify(value.leaseholdOrFreehold)}
						onChange={onChangeValue}
						onBlur={onChangeDetails}
					>
						<option key="-1"></option>
						{leaseholdOrFreeholdOptions.map((it, idx) => (
							<option key={idx} value={JSON.stringify(it)}>
								{it.type} ({it.subject}, {it.object})
							</option>
						))}
					</CustomInput>
				</Col>
			</FormGroup>

			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfBlank(value.bankNameAndAddress)}>
						<h6 className="mb-1 alert-heading">Bank Name and Address</h6>
						This is the value of the <b>details.bankNameAndAddress</b> token in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<Input
						disabled={isReadOnly}
						type="textarea"
						rows={8}
						name="bankNameAndAddress"
						id="bankNameAndAddress"
						className="form-control"
						invalid={!value.bankNameAndAddress}
						value={value.bankNameAndAddress}
						onChange={onChangeValue}
						onBlur={onChangeDetails}
					/>
				</Col>
			</FormGroup>

			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfBlank(value.bankAccountName)}>
						<h6 className="mb-1 alert-heading">Bank Account Name</h6>
						This is the value of the <b>details.bankAccountName</b> token in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<CustomInput
						disabled={isReadOnly}
						type="text"
						name="bankAccountName"
						id="bankAccountName"
						className="form-control"
						invalid={!value.bankAccountName}
						value={value.bankAccountName}
						onChange={onChangeValue}
						onBlur={onChangeDetails}
					/>
				</Col>
			</FormGroup>

			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfBlank(value.bankAccountNumber)}>
						<h6 className="mb-1 alert-heading">Bank Account Number</h6>
						This is the value of the <b>details.bankAccountNumber</b> token in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<CustomInput
						style={{ width: "12em" }}
						disabled={isReadOnly}
						type="text"
						name="bankAccountNumber"
						id="bankAccountNumber"
						className="form-control"
						invalid={!value.bankAccountNumber}
						value={value.bankAccountNumber}
						onChange={onChangeValue}
						onBlur={onChangeDetails}
					/>
				</Col>
			</FormGroup>

			<FormGroup row>
				<Col sm={4}>
					<Alert color={colorIfBlank(value.contractorAnnualVerificationFee)}>
						<h6 className="mb-1 alert-heading">Contractor Annual Verification Fee</h6>
						This is the value of the <b>details.contractorAnnualVerificationFee</b> token in the report template.
					</Alert>
				</Col>
				<Col sm={5}>
					<CustomInput
						style={{ width: "16em" }}
						disabled={isReadOnly}
						type="text"
						name="contractorAnnualVerificationFee"
						id="contractorAnnualVerificationFee"
						className="form-control"
						invalid={!value.contractorAnnualVerificationFee}
						value={value.contractorAnnualVerificationFee}
						onChange={onChangeParameterValue}
						onBlur={onBlurParameterValue}
					/>
				</Col>
			</FormGroup>

			{DetailsDataTables.map(tableName => (
				<FormGroup row key={tableName}>
					<Col sm={4}>
						<Alert color={colorIfBlank(value[tableName], "warning")}>
							<h6 className="mb-1 alert-heading">{_.startCase(tableName)}</h6>
							This is the value of the <b>details.{tableName}</b> token in the report template.
						</Alert>
					</Col>
					<Col sm={5}>
						<input
							name={tableName}
							color="primary"
							className="paste-cell full-width"
							readOnly
							onKeyDown={e => {
								if (clearview.OnlyKeys(["Delete", "Ctrl+KeyV"], e) === "Delete") onClearTable(e)
							}}
							onPaste={e => onPasteTable(e)}
							onBlur={onBlurParameterValue}
							placeholder="Paste here from Excel, or Del to delete table"
						/>
						<table key={tableName} className="report-editor-table">
							<tbody>
								{value[tableName]?.rows.map((row, rIdx) => (
									<tr key={rIdx}>
										<td key="-1" className={row.class}>
											{row.tr}
										</td>
										{row.columns.map((column, cIdx) => (
											<td key={cIdx} className={column.class}>
												{column.text}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</Col>
				</FormGroup>


					if (!details.signOffDate)
		details.messages["Sign Off Date is missing!"] = {
			severity: "danger",
		}

	if (!details.typeOfReport)
		details.messages["Type of Report is not selected!"] = {
			severity: "danger",
		}

	if (!details.recipient)
		details.messages["Landlord or Managing Agent is not selected!"] = {
			severity: "danger",
		}

				*/
