import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import _ from "lodash"

import { Accordion, Container, Row, Col } from "../../../components"

import { HeaderMain } from "../../components/HeaderMain"

import { PhaseTable } from "./components/PhaseTable"
import { TemplateTable } from "./components/TemplateTable"
import { EditSaveOrCancel } from "../../components/EditSaveOrCancel"
import { Spinner } from "../../components/Spinner"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"

class TemplatesDashboard extends Component {
	static propTypes = {
		customerTemplates: PropTypes.array.isRequired,
		isBusy: PropTypes.bool.isRequired,
		lastUpdated: PropTypes.number,
	}

	constructor(props) {
		super(props)

		this.newId = 0

		this.state = {
			customerTemplates: _.cloneDeep(props.customerTemplates),
			isEditing: false,
			isChanged: false,
		}

		this.toggleEditing = this.toggleEditing.bind(this)

		this.addTemplate = this.addTemplate.bind(this)
		this.cloneTemplate = this.cloneTemplate.bind(this)
		this.removeTemplate = this.removeTemplate.bind(this)
		this.onChange = this.onChange.bind(this)

		this.addPhase = this.addPhase.bind(this)
		this.removePhase = this.removePhase.bind(this)
		this.onChangePhase = this.onChangePhase.bind(this)

		this.onSave = this.onSave.bind(this)
		this.onCancel = this.onCancel.bind(this)
	}

	toggleEditing() {
		this.setState(prevState => ({
			isEditing: !prevState.isEditing,
		}))
	}

	addTemplate(customer) {
		this.newId--

		const newTemplate = {
			id: this.newId,
			reference: "New...",
			name: "New...",
			sequence: 0,
			family: "",
			durationMonths: 12,
			dueMonths: 6,
			imminentDays: 30,
			warningDays: 30,
			customer: customer,
			stages: [],
		}

		this.onChange({ target: { type: "add" } }, newTemplate)
	}

	cloneTemplate(template) {
		const clone = _.cloneDeepWith(template, (value, key, object, stack) => {
			switch (key) {
				case "id":
					this.newId--
					return this.newId

				case "reference":
					const parts = value.split(".", 2)
					if (parts.length === 1) return `${parts[0]}.1`
					return `${parts[0]}.${parseInt(parts[1]) + 1}`

				case "customer":
					return value

				case "createdAt":
				case "updatedAt":
				case "createdBy":
				case "updatedBy":
				case "next":
				case "countOfPeriodEnds":
					return null

				case "rowVersion":
					return 0

				case "actions":
					return []

				default:
					break
			}

			return undefined
		})

		clone.clonedFromId = template.clonedFromId || template.id
		this.onChange({ target: { type: "add" } }, clone)
	}

	removeTemplate(template) {
		this.onChange({ target: { type: "remove" } }, template)
	}

	onChange(e, template) {
		switch (e.target.type) {
			case "add":
				this.state.customerTemplates = this.state.customerTemplates.map(c => {
					if (c.id !== template.customer.id) return c
					return {
						...c,
						templates: c.templates.concat([template]),
					}
				})
				break

			case "remove":
				this.state.customerTemplates = this.state.customerTemplates.map(c => {
					if (c.id !== template.customer.id) return c
					return {
						...c,
						templates: c.templates.filter(it => it.id !== template.id),
					}
				})
				break

			case "changed":
				break

			case "number":
				template[e.target.name] = parseInt(e.target.value)
				break

			default:
				template[e.target.name] = e.target.value
				break
		}

		this.setState({
			isChanged: true,
		})
	}

	onSave() {
		this.props.doUpdate(this.state.customerTemplates)
		this.setState({
			isEditing: false,
			isChanged: false,
		})
	}

	onCancel() {
		this.setState({
			customerTemplates: _.cloneDeep(this.props.customerTemplates),
			isEditing: false,
			isChanged: false,
		})
	}

	addPhase(customer) {
		this.newId--

		const newPhase = {
			id: this.newId,
			name: "New...",
			customer: customer,
		}

		this.onChangePhase({ target: { type: "add" } }, newPhase)
	}

	removePhase(phase) {
		this.onChangePhase({ target: { type: "remove" } }, phase)
	}

	onChangePhase(e, phase) {
		let oldSequence = 0
		let newSequence = 0

		switch (e.target.type) {
			case "add":
				this.state.customerTemplates = this.state.customerTemplates.map(c => {
					if (c.id !== phase.customer.id) return c
					const sequence = _.max(c.phases.map(it => it.sequence)) || 0
					phase.sequence = sequence + 1
					return {
						...c,
						phases: c.phases.concat([phase]),
					}
				})
				break

			case "remove":
				this.state.customerTemplates = this.state.customerTemplates.map(c => {
					if (c.id !== phase.customer.id) return c
					return {
						...c,
						phases: c.phases.filter(it => it.id !== phase.id),
					}
				})
				break

			case "changed":
				break

			case "moveUpPhase":
			case "moveDownPhase":
				oldSequence = phase.sequence
				newSequence = e.target.type === "moveUpPhase" ? phase.sequence - 1 : phase.sequence + 1
				this.state.customerTemplates = this.state.customerTemplates.map(c => {
					if (c.id !== phase.customer.id) return c
					if (newSequence === 0) return c
					if (newSequence > _.max(c.phases.map(it => it.sequence) || 0)) return c

					return {
						...c,
						phases: _.sortBy(
							c.phases.map(p => {
								if (p.id === phase.id) p.sequence = newSequence
								else if (p.sequence === newSequence) p.sequence = oldSequence
								return p
							}),
							it => it.sequence
						),
					}
				})
				break

			default:
				phase[e.target.name] = e.target.value
				break
		}

		this.setState({
			isChanged: true,
		})
	}

	static getDerivedStateFromProps(props, state) {
		if (state.customerTemplates.length) return null

		return {
			customerTemplates: _.cloneDeep(props.customerTemplates),
			isEditing: false,
			isChanged: false,
		}
	}

	render() {
		const { customerTemplates, isEditing } = this.state

		return (
			<Container className={this.props.isBusy ? "isBusy" : ""}>
				{this.props.isBusy && <Spinner key="spinner"></Spinner>}
				<HeaderMain
					title={<span className="text-primary">{clearview.Icon.template}Templates</span>}
					className="mb-4 mt-0"
					onRefresh={this.state.isEditing ? undefined : () => this.props.doRefresh()}
					actions={
						this.props.user.isInHouse &&
						clearview.User.IsAdmin(this.props.user) && (
							<EditSaveOrCancel
								isEditing={this.state.isEditing}
								isChanged={this.state.isChanged}
								toggleEditing={this.toggleEditing}
								onSave={this.onSave}
								onCancel={this.onCancel}
							/>
						)
					}
				/>
				<Row>
					<Col lg={12}>
						{customerTemplates.map((it, idx) => (
							<Accordion key={idx} className="mb-2" initialOpen={idx === 0}>
								<Accordion.Header className="h4">
									<Accordion.Indicator className="mr-2" />
									{clearview.Icon.Business.Customer} {it.name} ({it.templates.length})
								</Accordion.Header>
								<Accordion.Body>
									<TemplateTable
										customer={it}
										isEditing={isEditing}
										onChange={this.onChange}
										addTemplate={this.addTemplate}
										removeTemplate={this.removeTemplate}
										cloneTemplate={this.cloneTemplate}
									/>
									<PhaseTable
										customer={it}
										isEditing={isEditing}
										onChange={this.onChangePhase}
										addPhase={this.addPhase}
										removePhase={this.removePhase}
									/>
								</Accordion.Body>
							</Accordion>
						))}
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = state => {
	const subState = ACTIONS.getSubState(state, "clientsReducer\\templates")
	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
		...subState,
		customerTemplates: ACTIONS.dictionaryToArray(subState.dict),
	}
}

const mapDispatchToProps = dispatch => ({
	doRefresh: () => {
		dispatch({ type: ACTIONS.TEMPLATES_FETCH, payload: {} })
	},

	doUpdate: customerTemplates => {
		dispatch({ type: ACTIONS.TEMPLATES_UPDATE, payload: { customerTemplates: customerTemplates } })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesDashboard)
