import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import Stage_CloseMultipleQueriesForm from "./Stage_CloseMultipleQueriesForm"
import Stage_NewQueryForm from "./Stage_NewQueryForm"
import Stage_PutOnHoldForm from "./Stage_PutOnHoldForm"
import Stage_TakeOffHoldForm from "./Stage_TakeOffHoldForm"
import Stage_RequestCloseForm from "./Stage_RequestCloseForm"
import Stage_CancelRequestCloseForm from "./Stage_CancelRequestCloseForm"
import Stage_ReopenForm from "./Stage_ReopenForm"
import Stage_CloseForm from "./Stage_CloseForm"
import Stage_AddCommentForm from "./Stage_AddCommentForm"

import * as clearview from "../../../../components/@Clearview"

class StageActionsLinks extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		showLog: PropTypes.bool,
		onChangeShowLog: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.props = props
	}

	render() {
		const { periodEnd, stage, showLog, onChangeShowLog } = this.props
		const actions = stage.actions || []
		const canCloseQueries = stage.comments.filter(it => it.type === "Query" && it.actions.includes("Close"))

		return (
			<React.Fragment>
				<Stage_CloseMultipleQueriesForm periodEnd={periodEnd} stage={stage} openQueries={canCloseQueries} />
				<Stage_NewQueryForm periodEnd={periodEnd} stage={stage} />
				<Stage_PutOnHoldForm periodEnd={periodEnd} stage={stage} />
				<Stage_TakeOffHoldForm periodEnd={periodEnd} stage={stage} />
				<Stage_RequestCloseForm periodEnd={periodEnd} stage={stage} />
				<Stage_CancelRequestCloseForm periodEnd={periodEnd} stage={stage} />
				<Stage_ReopenForm periodEnd={periodEnd} stage={stage} />
				<Stage_CloseForm periodEnd={periodEnd} stage={stage} />
				<Stage_AddCommentForm periodEnd={periodEnd} stage={stage} />
				<div className="d-flex-row justify-content-between align-items-center">
					{onChangeShowLog && !!stage.comments?.length && (
						<button id="showLog" className="text-primary tight" title={showLog ? "Hide Details" : "Show Details"} onClick={onChangeShowLog}>
							{clearview.Icon.details[!showLog]}
						</button>
					)}
					<div className="StageActions flex-1">
						<button
							hidden={!this.props.user.isInHouse || !canCloseQueries.length}
							className="text-primary tight"
							id={`stage${stage.id}_CloseMultipleQueriesForm`}
						>
							{clearview.Icon.StageAction.queries}Close Queries
						</button>
						<button hidden={!actions.includes("RequestClose")} className="default text-primary tight" id={`stage${stage.id}_RequestCloseForm`}>
							{clearview.Icon.StageAction.requestClosed}Request Close
						</button>
						<button
							hidden={!actions.includes("CancelRequestClose")}
							className="default text-primary tight"
							id={`stage${stage.id}_CancelRequestCloseForm`}
						>
							{clearview.Icon.StageAction.reopen}Keep Open
						</button>
						<button hidden={!actions.includes("Close")} className="default text-primary tight" id={`stage${stage.id}_CloseForm`}>
							{clearview.Icon.StageAction.close}
							{clearview.CustomerAlt(
								clearview.PeriodEndMeta(periodEnd).Customer,
								stage.templateStage.isInHouse ? "stage.action.close.label.isInHouse" : "stage.action.close.label.isClient",
								"Close"
							)}
						</button>
						<button hidden={!actions.includes("Reopen")} className="default text-primary tight" id={`stage${stage.id}_ReopenForm`}>
							{clearview.Icon.StageAction.reopen}Re-Open
						</button>
						<button hidden={!actions.includes("OnHold")} className="text-primary tight" id={`stage${stage.id}_PutOnHoldForm`}>
							{clearview.Icon.StageAction.onHold}Put On Hold
						</button>
						<button hidden={!actions.includes("OffHold")} className="text-primary tight" id={`stage${stage.id}_TakeOffHoldForm`}>
							{clearview.Icon.StageAction.offHold}Take Off Hold
						</button>

						<button hidden={!actions.includes("Comment")} className="text-primary tight" id={`stage${stage.id}_AddCommentForm`}>
							{clearview.Icon.StageAction.comment}Comment
						</button>
						<button hidden={!actions.includes("Query")} className="text-primary tight" id={`stage${stage.id}_NewQueryForm`}>
							{clearview.Icon.StageAction.query}Query
						</button>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	const user = state.userReducer.user || clearview.User.UnauthenticatedUser
	return {
		user,
	}
}

StageActionsLinks = connect(mapStateToProps)(StageActionsLinks)

export { StageActionsLinks }
