import { connect } from "react-redux"
import React from "react"
import PropTypes from "prop-types"

import { UncontrolledTooltip } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class PeriodEndUnread extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		doOnReadUnread: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.props = props
		this.onReadUnread = this.onReadUnread.bind(this)

		this.state = {
			isUnread: props.periodEnd.isUnread,
		}
	}

	onReadUnread() {
		this.setState({ isUnread: !this.state.isUnread })
		this.props.doOnReadUnread(this.props.periodEnd)
	}

	render() {
		const { periodEnd } = this.props

		return (
			<button
				className="inline-text"
				onClick={e => {
					clearview.StopPropagation(e)
					this.onReadUnread()
				}}
				id={`isUnread_${periodEnd.id}`}
				title={`Click to mark as ${this.state.isUnread ? "read" : "unread"}`}
			>
				<div style={{ marginLeft: -3 }}>{clearview.Icon.Unread[this.state.isUnread]}</div>
			</button>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doOnReadUnread: periodEnd =>
		dispatch({
			type: ACTIONS.PERIODEND_UNREAD_UPDATE,
			periodEnd: periodEnd,
			isUnread: !periodEnd.isUnread,
		}),
})

PeriodEndUnread = connect(null, mapDispatchToProps)(PeriodEndUnread)

export { PeriodEndUnread }
