import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"

import { Row, Col, Card, CardBody, CardTitle, ButtonGroup } from "../../../../components"

import { CustomExportCSV } from "./CustomExportButton"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class PropertyReports extends Component {
	static propTypes = {
		properties: PropTypes.array.isRequired,
	}

	render = () => (
		<React.Fragment>
			<Row>
				<Col lg={4}>
					<Card className="mb-3">
						<CardBody>
							<CardTitle tag="h6">
								Properties Report<span className="small ml-1 text-muted">#P.01</span>
							</CardTitle>
							<p>All properties</p>

							<ToolkitProvider
								exportCSV={{ fileName: "Properties.csv" }}
								data={this.props.properties}
								keyField="id"
								columns={[
									{
										dataField: "parent.parent.id",
										text: "Client",
										csvFormatter: (cell, row) => (row.parent && row.parent.role === "Client" ? clearview.GetBusinessTitle(row.parent) : ""),
									},
									{
										dataField: "parent.parent.name",
										text: "Managing Agent",
										csvFormatter: (cell, row) =>
											row.parent && row.parent.parent && row.parent.parent.role === "Managing Agent"
												? clearview.GetBusinessTitle(row.parent.parent)
												: "",
									},
									{
										dataField: "parent.name",
										text: "Landlord",
										csvFormatter: (cell, row) =>
											row.parent && row.parent.role === "Landlord" ? clearview.GetBusinessTitle(row.parent) : "",
									},
									{
										dataField: "name",
										text: "Property Name",
									},
									{
										dataField: "reference",
										text: "Ref",
									},
									{
										dataField: "address.fullAddressExcludingPostcode",
										text: "Address",
									},
									{
										dataField: "address.postcode",
										text: "Postcode",
									},
									{
										dataField: "attr0",
										text: "Corporate",
										csvFormatter: (cell, row) => clearview.YesNoAttributeValue(row.parent, "Is Landlord Corporate"),
									},
									{
										dataField: "attr1",
										text: "Use",
										csvFormatter: (cell, row) => clearview.AttributeValue(row, "Property Use"),
									},
									{
										dataField: "attr2",
										text: "Vat Elected",
										csvFormatter: (cell, row) => clearview.YesNoAttributeValue(row, "VAT Elected"),
									},
									{
										dataField: "attr3",
										text: "Tenure",
										csvFormatter: (cell, row) => clearview.AttributeValue(row, "Property Tenure"),
									},
									{
										dataField: "attr4",
										text: "Demand Type",
										csvFormatter: (cell, row) => clearview.AttributeValue(row, "Demand Type"),
									},
								]}
							>
								{props => (
									<React.Fragment>
										<div className="d-flex justify-content-end align-items-center mb-2">
											<div className="d-flex ml-auto">
												<ButtonGroup>
													<CustomExportCSV color="secondary" {...props.csvProps}>
														<i className="fa fa-download mr-2"></i>Download
													</CustomExportCSV>
												</ButtonGroup>
											</div>
										</div>
										<BootstrapTable classes="hidden" {...props.baseProps} />
									</React.Fragment>
								)}
							</ToolkitProvider>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	const subState = ACTIONS.getSubState(state, "clientsReducer\\properties")
	return {
		...subState,
		properties: ACTIONS.dictionaryToArray(subState.dict),
	}
}

PropertyReports = connect(mapStateToProps)(PropertyReports)

export { PropertyReports }
