import * as clearview from "../../../../../components/@Clearview"

const Formatters = {
	linesToCommas: it => it?.toString().replace(/\n|(,\n)/gm, ", "),
	upperCase: it => it?.toString().toUpperCase(),
	shortDate: it => clearview.FormatDate(it, "DD/MM/YY", it),
	longDate: it => clearview.FormatDate(it, "DD MMMM YYYY", it),
	undefined: it => it,
}

export default Formatters
