import React from "react"
import * as clearview from "../../../../components/@Clearview"
import { Badge } from "../../../../components"

import classNames from "classnames"
import classes from "./VouchingDetails.scss"

export const STATUS_COLOR = {
	undefined: "warning",
	null: "warning",
	"?": "danger",
	X: "secondary",
	A: "info",
	M: "success",
	U: "dark",
}

export const STATUS_ICON = {
	undefined: <i className="fa fa-fw fa-square-o"></i>,
	null: <i className="fa fa-fw fa-square-o"></i>,
	"?": <i className="fa fa-fw fa-exclamation-triangle"></i>,
	X: <i className="fa fa-fw fa-close"></i>,
	A: <i className="fa fa-fw fa-check-square-o"></i>,
	M: <i className="fa fa-fw fa-check"></i>,
	U: <i className="fa fa-fw fa-ban"></i>,
}

export const STATUS_TITLE = {
	undefined: "Not matched",
	null: "Not matched",
	"?": "Incomplete",
	X: "Excluded",
	A: "Matched automatically",
	M: "Matched!",
	U: "Unavailable!",
}

export const STATUS_CLASS_NAME = {
	undefined: "vouch-unmatched",
	null: "vouch-unmatched",
	"?": "vouch-incomplete",
	X: "vouch-exclude",
	A: "vouch-auto",
	M: "vouch-match",
	U: "vouch-unavailable",
}

export const SELECTED_CLASS_NAME = {
	true: "vouch-selected",
	false: "",
}

export const DATE_COLOR = {
	undefined: "danger",
	null: "danger",
	1: "secondary-01",
	2: "secondary-02",
	3: "secondary-03",
	4: "secondary-04",
	5: "secondary-05",
	6: "secondary-06",
	7: "secondary-07",
	8: "secondary-08",
	9: "secondary-09",
	10: "dark-07",
	11: "dark-08",
	12: "dark-09",
}

export const formatVendorName = value => {
	if (!value)
		return (
			<Badge className="flex-1 mt-1 h6 mb-1" color="danger">
				SUPPLIER NAME?
			</Badge>
		)
	return <p className="flex-1 mt-0 h5 mb-1 flex-1">{value}</p>
}

export const formatRef = value => {
	if (!value)
		return (
			<Badge className="mt-1 h6 mb-1" color="danger">
				REF?
			</Badge>
		)
	return `${value}`
}

export const formatValue = value => {
	if (!value)
		return (
			<Badge className="mt-1 h6 mb-1" color="danger">
				VALUE?
			</Badge>
		)
	return clearview.FormatCurrency(value)
}

export const fileTypeIcon = item => {
	const type = clearview.AfterLast(item.s3, ".")?.toLowerCase()

	switch (type) {
		case "pdf":
			return clearview.Icon.FileType.Pdf

		case "jpg":
		case "jpeg":
		case "png":
		case "tiff":
			return clearview.Icon.FileType.Image

		default:
			return clearview.Icon.FileType.undefined
	}
}

export const accountColor = accountName => {
	if (!accountName) return "secondary"
	if (accountName === "Journal") return "primary"
	const COLOR_ARRAY = ["cyan", "indigo", "purple", "pink", "yellow", "teal", "orange"]
	return COLOR_ARRAY[accountName.charCodeAt(0) % COLOR_ARRAY.length]
}

export const getDropItemClass = snapshot =>
	classNames(classes["list-group-item"], {
		[classes["list-group-item--dropping"]]: snapshot.isDraggingOver,
	})

export const getDragItemClass = snapshot =>
	classNames(classes["list-group-item"], {
		[classes["list-group-item--dragging"]]: snapshot.isDragging,
	})
