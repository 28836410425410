import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { v4 as uuid } from "uuid"

import _ from "lodash"

import { Button, Table, Form, CustomInput, Input, InputGroup, InputGroupAddon, UncontrolledModal, ModalHeader, ModalBody } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"
import AreYouSure from "../../../components/AreYouSure"

class ManageUsers_Form extends React.Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		const clonedTeams = _.cloneDeep(this.props.business.teams.filter(it => it.type === "Users"))
		this.state = {
			businessId: this.props.business.id,
			teams: clonedTeams,
			teamGrid: clearview.TeamGrid(clonedTeams),
		}

		// this.toggleEditUser = this.toggleEditUser.bind(this)
		// this.toggleDeleteUser = this.toggleDeleteUser.bind(this)
		this.handleRoleChange = this.handleRoleChange.bind(this)
		this.handleContactChange = this.handleContactChange.bind(this)

		this.handleTeamChange = this.handleTeamChange.bind(this)
		this.handleUserChange = this.handleUserChange.bind(this)
		this.handleAddTeam = this.handleAddTeam.bind(this)
		this.handleAddUser = this.handleAddUser.bind(this)

		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
		this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this)
	}

	toggleEditUser(event, user) {
		user._isEditing = !user._isEditing
		this.setState({
			...this.state,
		})
	}

	toggleDisableUser(event, user) {
		user.isDisabled = !user.isDisabled
		this.setState({
			...this.state,
		})
	}

	handleRoleChange(event, userId, teamUsername) {
		const role = event.target.value

		const team = _.find(this.state.teams, it => it.username === teamUsername)
		var teamUser = _.find(team.users, it => it.id === userId)

		if (!teamUser) {
			teamUser = _.clone(_.find(_.find(this.state.teams, it => it.type === "Users").users, it => it.id === userId))
			team.users.push(teamUser)
		}

		teamUser.role = role

		this.setState({
			teamGrid: clearview.TeamGrid(this.state.teams),
		})
	}

	handleContactChange(event, userId, teamUsername) {
		const team = _.find(this.state.teams, it => it.username === teamUsername)
		var teamUser = _.find(team.users, it => it.id === userId)

		if (!teamUser) {
			teamUser = _.clone(_.find(_.find(this.state.teams, it => it.type === "Users").users, it => it.id === userId))
			team.users.push(teamUser)
		}

		teamUser.isContact = event.target.checked

		this.setState({
			teamGrid: clearview.TeamGrid(this.state.teams),
		})
	}
	handleActionButtonClick() {
		this.props.doAction(this.props.business, this.state.teams)
		return true
	}

	handleCancelButtonClick() {
		const clonedTeams = _.cloneDeep(this.props.business.teams)
		this.state = {
			businessId: this.props.business.id,
			teams: clonedTeams,
			teamGrid: clearview.TeamGrid(clonedTeams),
		}
		return true
	}

	handleTeamChange(e) {
		const value = e.target.value

		switch (e.target.name) {
			case "team.name":
				_.find(this.state.teams, it => it.username === e.target.id).name = value
				break
		}

		this.setState({
			businessId: this.props.business.id,
			teams: this.state.teams,
			teamGrid: clearview.TeamGrid(this.state.teams),
		})
	}

	handleUserChange(e) {
		const value = e.target.value || ""
		const user = _.find(_.find(this.state.teams, it => it.type === "Users").users, it => it.id == e.target.id)

		switch (e.target.name) {
			case "user.name":
				user.name = value
				// user.username = (value||"").toLowerCase().replace(" ",".")
				// user.initials = clearview.ToInitials((e.target.value||""),"")
				break

			case "user.username":
				user.username = (value || "").toLowerCase().replace(" ", ".")
				break

			case "user.initials":
				user.initials = value || ""
				break
		}

		this.setState({
			businessId: this.props.business.id,
			teams: this.state.teams,
			teamGrid: clearview.TeamGrid(this.state.teams),
		})
	}

	handleAddTeam(e) {
		this.state.teams.push({ username: uuid(), name: "", users: [] })
		this.setState({
			businessId: this.props.business.id,
			teams: this.state.teams,
			teamGrid: clearview.TeamGrid(this.state.teams),
		})
	}

	handleAddUser(e) {
		let userTeam = _.find(this.state.teams, it => (it.type = "Users"))
		if (!userTeam) {
			userTeam = {
				type: "Users",
				name: "Users",
				username: `${this.props.business.reference}.Users`,
				users: [],
			}
			this.state.teams = [userTeam, ...this.state.teams]
		}

		userTeam.users.unshift({
			id: -clearview.UnixTimestamp(),
			_isEditing: true,
			username: "",
			name: "",
			initials: "",
			role: "Member",
			isInHouse: this.props.business.type === "Customer",
		})
		this.setState({
			businessId: this.props.business.id,
			teams: this.state.teams,
			teamGrid: clearview.TeamGrid(this.state.teams),
		})
	}

	render() {
		const { business, doPasswordReset } = this.props

		if (business.id !== this.state.businessId) {
			this.setState({
				businessId: this.props.business.id,
				teams: this.props.business.teams,
				teamGrid: clearview.TeamGrid(this.props.business.teams),
			})
		}

		const { teams, teamGrid } = this.state

		return (
			<UncontrolledModal style={{ maxWidth: 1200 }} className="overflowScroll" target="ManageUsers_Form">
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="div">
						<div className="d-flex">
							<h1 className="text-primary mr-2">
								<i className="fa fa-fw fa-pencil"></i>
							</h1>
							<h6>
								Manage Users for <b className="nowrap">{business.name}</b>
							</h6>
							<div className="ml-5">
								<UncontrolledModal.Cancel color="link" className="text-primary" onClick={this.handleCancelButtonClick}>
									Cancel
								</UncontrolledModal.Cancel>
								<UncontrolledModal.Close color="default" className="text-primary" onClick={this.handleActionButtonClick}>
									Save
								</UncontrolledModal.Close>
							</div>
						</div>
					</ModalHeader>

					<ModalBody>
						<Table className="mb-0" hover>
							<thead>
								<tr>
									<th colSpan={2} className="align-left bt-0">
										User
										<Button outline color="link" title="Add new user" className="ml-1" onClick={() => this.handleAddUser()}>
											{clearview.Icon.Business.AddUser}
										</Button>
									</th>
									{teams
										.filter(it => ["InHouseTeam", "ClientTeam"].includes(it.type))
										.map(team => (
											<th key={team.id}>
												<Input
													invalid={!team.name.length}
													type="text"
													name="team.name"
													id={team.username}
													placeholder="Team Name ..."
													value={team.name}
													onKeyDown={clearview.ValidNameCharacters}
													onChange={this.handleTeamChange}
												/>
											</th>
										))}
								</tr>
							</thead>
							<tbody>
								{teamGrid.map(row => (
									<tr key={row.user.id} className={row.user.isDisabled ? "greyed-out" : null}>
										<td>
											<Button outline color="link" title="Edit" className="p-0 m-0" onClick={e => this.toggleEditUser(e, row.user)}>
												{clearview.Icon.Editing[row.user._isEditing]}
											</Button>
											{!row.user._isEditing && <span>{row.user.name}</span>}
											<Button
												outline
												color="link"
												title={row.user.isDisabled ? "Enable" : "Disable"}
												className="p-0 ml-1"
												onClick={e => this.toggleDisableUser(e, row.user)}
											>
												{clearview.Icon.delete}
											</Button>
											<a
												className="ml-3"
												key={`ResetPassword_${row.user.id}`}
												id={`ResetPassword_${row.user.id}`}
												onClick={e => {
													e.stopPropagation()
													e.preventDefault()
												}}
											>
												Reset Password
											</a>
											<AreYouSure
												id={`ResetPassword_${row.user.id}`}
												message={
													<span>
														Reset Password for <b className="nowrap">{row.user.username}</b>?
													</span>
												}
												action={() => doPasswordReset(row.user.username)}
											/>
											{row.user._isEditing && (
												<React.Fragment>
													<InputGroup>
														<InputGroupAddon addonType="prepend">Name:</InputGroupAddon>
														<Input
															invalid={!row.user.name || !row.user.name.length}
															type="text"
															name="user.name"
															id={row.user.id}
															placeholder="Name..."
															value={row.user.name}
															onChange={this.handleUserChange}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">Username:</InputGroupAddon>
														<Input
															invalid={!row.user.username || !row.user.username.length}
															type="text"
															name="user.username"
															id={row.user.id}
															placeholder="Username..."
															value={row.user.username}
															onChange={this.handleUserChange}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">Initials:</InputGroupAddon>
														<Input
															invalid={!row.user.initials || !row.user.initials.length || row.user.initials.length > 3}
															type="text"
															name="user.initials"
															id={row.user.id}
															placeholder="Initials..."
															value={row.user.initials}
															onChange={this.handleUserChange}
														/>
													</InputGroup>
												</React.Fragment>
											)}
										</td>
										{teams.map(t => (
											<td key={t.id}>
												<span key={t.id} className="ml-1, mr-0">
													{row.teamRole[t.username]}
												</span>
												{row.isTeamContact[t.username] ? ", Contact" : ""}
												<br />
												<InputGroup className="manageUsersRoles">
													<CustomInput
														className="ml-0 mr-0 mt-2 mb-2"
														type="radio"
														id={`u:${row.userId}|t:${t.username}|r:Reader`}
														name={`u:${row.userId}|t:${t.username}`}
														inline
														value="Reader"
														defaultChecked={row.teamRole[t.username] === "Reader"}
														onChange={e => this.handleRoleChange(e, row.userId, t.username)}
													/>
													<CustomInput
														className="ml-0 mr-0 mt-2 mb-2"
														type="radio"
														id={`u:${row.userId}|t:${t.username}|r:Member`}
														name={`u:${row.userId}|t:${t.username}`}
														inline
														value="Member"
														defaultChecked={row.teamRole[t.username] === "Member"}
														onChange={e => this.handleRoleChange(e, row.userId, t.username)}
													/>
													<CustomInput
														className="ml-0 mr-0 mt-2 mb-2"
														type="radio"
														id={`u:${row.userId}|t:${t.username}|r:Approver`}
														name={`u:${row.userId}|t:${t.username}`}
														inline
														value="Approver"
														defaultChecked={row.teamRole[t.username] === "Approver"}
														onChange={e => this.handleRoleChange(e, row.userId, t.username)}
													/>
													<CustomInput
														className="ml-0 mr-0 mt-2 mb-2"
														type="radio"
														id={`u:${row.userId}|t:${t.username}|r:Admin`}
														name={`u:${row.userId}|t:${t.username}`}
														inline
														value="Admin"
														defaultChecked={row.teamRole[t.username] === "Admin"}
														onChange={e => this.handleRoleChange(e, row.userId, t.username)}
													/>
													{t.type !== "Users" && (
														<CustomInput
															className="ml-0 mr-0 mt-2 mb-2"
															type="checkbox"
															id={`u:${row.userId}|t:${t.username}|r:Contact`}
															name={`u:${row.userId}|t:${t.username}|c`}
															inline
															value="Contact"
															defaultChecked={row.isTeamContact[t.username]}
															onChange={e => this.handleContactChange(e, row.userId, t.username)}
														/>
													)}
												</InputGroup>
											</td>
										))}
									</tr>
								))}
							</tbody>
						</Table>
					</ModalBody>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAction: (business, teams) =>
		dispatch({
			type: ACTIONS.TEAMS_MANAGE,
			business: business,
			teams: teams,
		}),

	doPasswordReset: username =>
		dispatch({
			type: ACTIONS.USER_PASSWORD_RESET,
			username: username,
		}),
})

export default connect(null, mapDispatchToProps)(ManageUsers_Form)
