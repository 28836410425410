import React from "react"

import { Container, Row, Col } from "../../../../components"

import { TimelineDefault } from "./Releases_TimelineDefault"

const colors = {
	info: "info",
	pause: "warning",
	check: "success",
	bug: "danger",
}

export function ItemMap(date) {
	return (it, idx) => (
		<TimelineDefault
			key={idx}
			showPillDate={idx === 0}
			pillDate={date}
			iconCircle={it[0]}
			smallIconColor={colors[it[0]]}
			iconCircleColor={colors[it[0]]}
			title={it[1]}
			content={it[2]}
		/>
	)
}

export function Releases2022() {
	return (
		<React.Fragment>
			<Container>
				<Row>
					<Col lg={8} className="release-timeline">
						{Release_2022_037.map(ItemMap("22 Dec 2022"))}
						{Release_2022_036.map(ItemMap("21 Dec 2022"))}
						{Release_2022_035.map(ItemMap("15 Dec 2022"))}
						{Release_2022_034.map(ItemMap("14 Dec 2022"))}
						{Release_2022_033.map(ItemMap("08 Dec 2022"))}
						{Release_2022_032.map(ItemMap("07 Dec 2022"))}
						{Release_2022_031.map(ItemMap("06 Dec 2022"))}
						{Release_2022_030.map(ItemMap("30 Nov 2022"))}
						{Release_2022_029.map(ItemMap("17 Nov 2022 - Hotfix (2)"))}
						{Release_2022_028.map(ItemMap("17 Nov 2022"))}
						{Release_2022_027.map(ItemMap("09 Nov 2022"))}
						{Release_2022_026.map(ItemMap("03 Nov 2022"))}
						{Release_2022_025.map(ItemMap("25 Oct 2022 - Hotfix"))}
						{Release_2022_024.map(ItemMap("24 Oct 2022"))}
						{Release_2022_023.map(ItemMap("18 Oct 2022"))}
						{Release_2022_022.map(ItemMap("13 Oct 2022"))}
						{Release_2022_021.map(ItemMap("12 Oct 2022 - Hotfix"))}
						{Release_2022_020.map(ItemMap("12 Oct 2022"))}
						{Release_2022_019.map(ItemMap("10 Oct 2022"))}
						{Release_2022_018.map(ItemMap("07 Oct 2022 - Hotfix"))}
						{Release_2022_017.map(ItemMap("07 Oct 2022"))}
						{Release_2022_016.map(ItemMap("06 Oct 2022"))}
						{Release_2022_015.map(ItemMap("23 Sep 2022"))}
						{Release_2022_014.map(ItemMap("21 Sep 2022"))}
						{Release_2022_013.map(ItemMap("20 Sep 2022"))}
						{Release_2022_012.map(ItemMap("19 Sep 2022"))}
						{Release_2022_011.map(ItemMap("08 Sep 2022"))}
						{Release_2022_010.map(ItemMap("07 Sep 2022"))}
						{Release_2022_009a.map(ItemMap("05 Sep 2022 - Hotfix"))}
						{Release_2022_009.map(ItemMap("05 Sep 2022"))}
						{Release_2022_008.map(ItemMap("24 Aug 2022"))}
						{Release_2022_007.map(ItemMap("22 Aug 2022"))}
						{Release_2022_006.map(ItemMap("18 Aug 2022 - Hotfix 2"))}
						{Release_2022_005.map(ItemMap("18 Aug 2022"))}
						{Release_2022_004.map(ItemMap("30 Jun 2022"))}
						{Release_2022_003.map(ItemMap("23 Jun 2022"))}
						{Release_2022_002.map(ItemMap("01 Jun 2022"))}
						{Release_2022_001.map(ItemMap("31 May 2022"))}
						{Release_20220523.map(ItemMap("23 May 2022"))}
						{Release_20220523.map(ItemMap("23 May 2022"))}
						{Release_20220511.map(ItemMap("11 May 2022"))}
						{Release_20220430.map(ItemMap("30 Apr 2022"))}
						{Release_20220401.map(ItemMap("01 Apr 2022"))}
						{Release_20220319.map(ItemMap("19 Mar 2022"))}
						{Release_20220312.map(ItemMap("12 Mar 2022"))}
						{Release_20220312.map(ItemMap("12 Mar 2022"))}
						{Release_20220311.map(ItemMap("11 Mar 2022"))}
						{Release_20220228.map(ItemMap("28 Feb 2022"))}
						{Release_20220227.map(ItemMap("27 Feb 2022"))}
						{Release_20220219.map(ItemMap("19 Feb 2022"))}
						{Release_20220216a.map(ItemMap("16 Feb 2022"))}
						{Release_20220215.map(ItemMap("15 Feb 2022"))}
						{Release_20220206.map(ItemMap("06 Feb 2022"))}
						{Release_20220129.map(ItemMap("29 Jan 2022"))}
						{Release_20220121.map(ItemMap("21 Jan 2022"))}
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	)
}

const Release_2022_037 = [
	[
		"info",
		"Period End Delete sets/un-sets Do Not Renew.",
		"When a Period End is deleted, the user can select whether the scheme should be renewed automatically when due or not.",
	],
	[
		"info",
		"Query editing - In-House only.",
		"Queries and replies to queries are now editable by In-house Admins (and Approvers for Fortus). Closed queries can be re-opened by deleting the Closing Reply.",
	],
	["info", "Query copying.", "Queries can now be copied, e.g. for review to another user."],
	[
		"info",
		"Property Card shows Service Charge Manager.",
		"The Property Card which is in the left hand side of the Period End details page will show the In House Service Charge Manager for the Property, Landlord, Managing Agent or Client.",
	],
]

const Release_2022_036 = [
	[
		"check",
		"Vouching: Links from/to Split invoice items not working.",
		"The link icons to the matched Expense or Invoice item did not work if the Invoice had been split. Now fixed.",
	],
	["info", "Vouching: Expense items show split number.", "Expense item Avatar and Description changed to show page numbers and the Split number."],
	[
		"info",
		"Login error - Logout will un-impersonate.",
		"If a login fails, e.g. because the user has no properties assigned, the logout button will also un-impersonate. This will allow admin users who impersonate a user with no access to login as themselves again.",
	],
]

const Release_2022_035 = [
	[
		"info",
		"Enabled payload compression for large payloads.",
		"fetchClients, fetchTemplates, fetchPeriodEnds and fetchQueries are now using 'application/x-clearview' custom response compression.",
	],
	["info", "Resolved React Key warning for Properties.", "Changed column ids in Properties Table and Property Download. Resolves the React Key warning."],
	["info", "Removed Legacy Policy entity.", "Now that the new files system is bedded in, Policy entities are no longer required."],
	[
		"info",
		"Removed Legacy Attachments Saga.",
		"Now that the new files system is bedded in, the legacy Attachments module has been permanently removed, for performance purposes.",
	],
]

const Release_2022_034 = [
	[
		"info",
		"Working Papers: Updatable Fee matrix.",
		"Admin users can now edit the Fee Matrices for the Client and warnings are shown in the Working Papers when Fee Matrix tables have been changed.",
	],
]

const Release_2022_033 = [
	[
		"info",
		"Working Papers: Import income report does not require first line to contain column headings.",
		"All rows will be imported. Columns are expected in order: Audit, Period From, Period to, Transaction Description, Posted, Trans Date, Nominal, Heading Description, Nett, VAT, Gross.",
	],
]

const Release_2022_032 = [
	[
		"info",
		"Working Papers: Income allocation",
		"On the Income Report tab, Income can be allocated across the schedules. Each allocation appears on the ETB tab. Income summary values are shown, but the income which is allocated may be different.",
	],
	[
		"info",
		"Working Papers: Fee allocation",
		"On the Calc tab, the calculated fee can be allocates across the Schedules. Each allocation appears in the Crs and Accruals tab. Warning are displayed if the total fee allocation does not match the total fee.",
	],
]

const Release_2022_031 = [
	[
		"info",
		"Vouching: Vouching Report Expenditure page standard columns",
		"The columns in the Expenditure tab of the Vouching Report will always contain a minimum set of columns in a specific order. The columns are different per customer.",
	],
	["check", "Files system upgrade to V3", "Files system now used AWS SDK V3 and supports usernames with apostrophes and observes impersonation."],
]

const Release_2022_030 = [
	[
		"check",
		"Add Attachments was crashing on some New Query dialogs",
		"Add Attachments was attempting to save the query as 'draft' but this is disabled for some Customers. Now fixed.",
	],
	[
		"check",
		"Import Properties/Clients duplicate Refs check",
		"Import of Properties and Clients will check and display duplicate Refs which must be removed before the file can be imported.",
	],
	["check", "Import Properties/Clients allows numbers for Refs", "Properties and Clients whose references are pure numbers can now be imported."],
	[
		"info",
		"Overview Page Help Popups amended",
		"The Help popups for the Statistics and Queries sections have been amended to highlight that the data are for Current Stages only.",
	],
	[
		"info",
		"Tables are now striped",
		"To improve the contrast between rows, tables showing the following are now 'striped': Open Stages, Period Ends, Stage comments, Properties, Templates, Queries",
	],
]
const Release_2022_029 = [
	["check", "Vouching: Expense Edit Amount", "Fixed problem changing total amount in Expense edit."],
	["check", "Vouching: Expenditure Edit Amount", "Fixed problem changing expenditure amount."],
	["check", "Period End Edit Fee", "Fixed problem changing period end fee."],
]

const Release_2022_028 = [
	["info", "Vouching: Select All", "'Select All' buttons are available in the Expenditure and Invoices columns."],
	["check", "Query Manager Export fix", "Query Manager Export was failing when any query was in a future stage. Now fixed."],
	[
		"info",
		"Re-Impersonation",
		"To speed thins up, an In-house Admin user can go from impersonating one user to impersonating another user without having to un-impersonate first. You will have to type in the second username to impersonate, if it is not in the purview of the currently impersonated user.",
	],
]

const Release_2022_027 = [
	[
		"info",
		"Vouching: Vouching report column order",
		"'Original' value columns are now moved to the right of the exported worksheet. Original Expenditure Report columns are now shown in the order they are imported immediately after Clearview's own columns.",
	],
	["info", "User Impersonation", "In-House Admin users can Impersonate other In-House or Client users in order to test/confirm the UI experience."],
	[
		"info",
		"Stage Show Details button",
		"On the Period End details there is now a small button left of the Stage Commands that allows the user to show/hide the log records. This will be useful to track when a Stage has been re-opened.",
	],
]
const Release_2022_026 = [
	["check", "Vouching: Some invoices would fail to import if the OCR process found a Label but no Value.", "Now fixed."],
	["info", "Fees now include pence.", "All Period End fees can now include pence."],
]
const Release_2022_025 = [["check", "Client User gets blank screen when viewing closed Period Ends", "Now fixed."]]

const Release_2022_024 = [
	[
		"info",
		"Phases - Service Charge Manager Summary table added to Overview for BW",
		"Tables are now available for any appointee group, currently only Service Charge Manager is shown.",
	],
	[
		"check",
		"Open Queries count in future stages hidden from Client",
		"In the Period End Details view, the number of queries in future stages was visible to Client users even though they were not accessible. Query badges in future stages are now suppressed for Client users.",
	],
]

const Release_2022_023 = [
	[
		"check",
		"Month Summary filters can now be cleared",
		"When selecting any item on the Overview Page Phases Month Summary table, A filter is applied which is not cleared by 'clear filter' operations. Now fixed.",
	],
	[
		"check",
		"Schemes tables show if only one scheme",
		"On the Overview page, the schemes tables were not showing if all the user's Period Ends were for the same scheme, Now fixed.",
	],
	[
		"info",
		"Table rows now 12pt text",
		"The font size for tables was 14pt. Is now 12pt to match the heading. The Stages Comments list now has compact row style.",
	],
	[
		"info",
		"Vouching: Vouched reference does not show page number if Page 1",
		"In the Vouching Report, the Vouched Reference only includes Page number if the Expense is vouched on page 2 or later of the invoice file.",
	],
]

const Release_2022_022 = [
	[
		"info",
		"Vouching: Vouching Report column order changed on Expenditure page",
		"Expenditure page now shows Vouching columns, Original columns, then all remaining columns from the Expenditure spreadsheet.",
	],
	[
		"check",
		"Vouching: All Original Expenditure Report Columns included",
		"Vouching Report Expenditure page now includes ALL columns from the original Expenditure report spreadsheet, not just those with values in the first row.",
	],
	[
		"check",
		"Vouching: Vouching Report date columns.",
		"'Period from' and 'period to' columns from original Expenditure report spreadsheet now formatted as Short Dates in the report.",
	],
]

const Release_2022_021 = [
	["check", "Vouching import unable to differentiate between short Invoice Refs", "Now fixed. Bug fix in clearview-aws-common:letterPairs function."],
]

const Release_2022_020 = [
	[
		"info",
		"Delete Files can also be used to delete empty folders",
		"Files and empty folders can be deleted using the Delete Files menu option. An error will be displayed if the folder is not empty.",
	],
	[
		"check",
		"Files browser can now browse Folders which contain dots in the name",
		"Folders which contain dots in the name cannot be created directly, but may be created by Zip files. They can now be browsed correctly.",
	],
]

const Release_2022_019 = [
	[
		"check",
		"Period End Progress bars show Stages for BW",
		"Although BW now have Phases configured for some templates, the Period End progress bar will always show Stages.",
	],
]

const Release_2022_018 = [["check", "Blank screen when clicking on Customer level in the Client screen.", "Now fixed."]]

const Release_2022_017 = [
	["check", "Manager Summary filter does not clear when clicking on 'Overview'", "Now fixed."],
	[
		"check",
		"Vouching: Expenditure Import handles blank values better",
		"Some Fortus expenditure reports were failing to import due to blank Total values. Now fixed.",
	],
	[
		"info",
		"Period End folders/files are read-only for Closed Period Ends.",
		"All users are prevented from modifying the contents of Folders for Period Ends which are closed. This includes Vouching folders. Admins will have to re-open the PE to modify the Files.",
	],
	[
		"info",
		"Vouching: Queries raised on the Vouching screen default to Subject: Missing Invoices.",
		"The Missing Invoices subject will ensure that attachments added to the Query will be in the Invoices subfolder and will be available for Vouching.",
	],
	["info", "Vouching: Read-only for Closed Period Ends.", "Vouching cannot be modified after the Period End is closed."],
	[
		"info",
		"Vouching: Click on the document Name in the Edit Expenditure / Image page to open the file in another tab.",
		"A PDF file which originated from a poor quality scan will sometimes fail to load in the embedded image view (due to a Cross-Origin Read Blocking response). The file will open OK in a new tab, though.",
	],
	[
		"info",
		"Shared Cancel button disabled until data is changed",
		"The cancel button is disabled until changes have been made on the pages that use the EditSaveOrCancel component.",
	],
]
const Release_2022_016 = [
	[
		"info",
		"Phases - Client Summary and Manager Summary now show columns alphabetically",
		"Previously the Client Summary and Manager Summary Phase tables showed columns in order of those with the most Period Ends. However, when filters are applied this often causes columns to move about and be difficult to find. Columns are now always ordered alphabetically.",
	],
	[
		"info",
		"BW: Phases Tables visible In House or to Client Admins",
		"For Bennett Whitehouse, The Phases section on the Overview page is visible to all In-House users but only Admins for Client users.",
	],
	[
		"info",
		"Vouching: The 'Vouching...' action on Period End Details and the actions at the bottom of the Vouching page are now styled as links rather than buttons.",
		"Links are far less 'shouty', especially when the number of actions increases. Links are a more appropriate style.",
	],
	[
		"info",
		"Vouching: Edit Vendor Aliases",
		"In house admins can edit the Vendor Aliases. For use when an alias is created in error. It is also worth reviewing these once in a while to see if any aliases have been set up which are not specific enough.",
	],
	[
		"info",
		"Customer specific Phases Overview labels",
		"Labels on the Phases Overview table are 'Period Ends' and 'Stages' for BW and 'Year Ends' and 'Tasks' for Fortus.",
	],
	["check", "Vouching: Edit Expense/Tokens copy to clipboard icons not working in Edge.", "Now Fixed. Now works in Chrome and Edge."],
	[
		"check",
		"Vouching: Vouching shows when balanced correctly.",
		"Fixed. The 'scales' icon in the Vouching header will now show the correct balanced icon when the account is balanced.",
	],
	[
		"check",
		"Vouching: Expenditure import did not include pence on expenditure amounts.",
		"Fixed. Now uses same currency parsing rules as the Invoice import.",
	],
	[
		"info",
		"Vouching: Show/Hide Queue Status has 'Please Wait...'.",
		"All Uncontrolled Popovers which load their contents asynchronously can now display placeholder content such as 'Please Wait...'.",
	],
	["info", "Help Popups on Overview Page.", "Help popups have been added to all sections on the Overview Dashboard."],
	[
		"info",
		"Period End Stage table 'Due' column now 'Closed/Due'.",
		"On the Period End details page, the Stage 'Due' date column is now titles 'Closed/Due' to reflect the fact that it shows the stage Closed date for Closed Stages.",
	],
	[
		"info",
		"Stage Summary tables now filter all the Period End data.",
		"On the Overview page (for BW), selecting a Template Family (now labelled 'Schema') now filters the Overview page to that Template Family. In addition, each tab now indicates the number of Live Period Ends in that Template Family.",
	],
	[
		"info",
		"Overview page now includes Phases tables for BW.",
		"The Phases tables Have now been added to the Overview dashboard for Bennett Whitehouse. In addition, an icon now indicates which Tables are In-House only.",
	],
	[
		"info",
		"System users now have Customer Filter.",
		"System users can now apply Period End filter for the Customer using a new Customer Selector on the Overview page.",
	],
	[
		"info",
		"Can create property Attributes with New Property.",
		"The Add Property form now allows Property Attributes to be set when the Property is created.",
	],
	[
		"info",
		"Live Period Ends Stages Overview is now bound to the Period Ends filter.",
		"For BW, on the Overview page, selecting a Template family in the Stages overview sets the Period End filter for the whole overview page. The stages overview does not appear until one of the template families is selected.",
	],
	[
		"info",
		"Template dashboard shows Family.",
		"The Templates dashboard now shows the Template Family, where specified, and the rows are in Template Family order.",
	],
	[
		"check",
		"File upload error if directory.",
		"The file upload feature does not support uploading directories. An error is now displayed if the user attempts top upload a folder and no empty folder is created in the file system.",
	],
	[
		"info",
		"Vouching: Query actions disabled when the PE is Closed or not yet open.",
		"Query Invoice, Query Expenditure and both Query These menu options are disabled if the Period End has no current stage.",
	],
	["check", "Vouching: Page failed to load if PE Closed or Not Yet Started.", "Now fixed."],
	[
		"info",
		"Vouching: Separates invoices if Dates are different.",
		"Where Invoice Refs are undetected on adjacent pages, the import will create a new invoice if the Invoice Date is found on both pages but is different on each. This will help identify separate Invoices from a particular vendor where the invoice number is consistently hard to detect.",
	],
	[
		"info",
		"Vouching: Import rules re-apply on Auto-match.",
		"Clicking the Auto-Match button will also re-apply the latest Invoice Import Rules to all Expenses which are Incomplete or Not-matched, before the Auto-Match.",
	],
	[
		"info",
		"Vouching: Can specify Pages and Ref on Split.",
		"Rather than edit the Narrative, the user can specify the from Page, To Page, Reference and Total of each Split on the Edit Expense form.",
	],
	["info", "Vouching: Ignore Invoice pages with no Summary Fields detected.", "This ensures that all invoices have valid .page and .toPage properties."],
	[
		"info",
		"Close multiple stages.",
		"In the Close Stage dialog, In-House Approvers can close multiple stages if the subsequent stages do not have any incomplete Conditions.",
	],
	[
		"check",
		"Remove duplicate actions from Client Card menu.",
		"Removed duplicate Manage Users & Import Users menu options in the Client Card on the Clients page.",
	],
	[
		"info",
		"Vouching: Vendor import rules can set invoice to Excluded.",
		"The Invoice Import Rules can now specify that matching invoices should default to 'Excluded' as well as setting the Vendor Name.",
	],
	[
		"info",
		"Re-open Stage & Take Off Hold - comments not required.",
		"Comments are now optional on Re-open stage, Take Off-Hold, Close Stage and Request Close Stage.",
	],
	[
		"info",
		"Vouching: All values now in pence.",
		"Using integer pence for the storage and calculation of currency amounts resolved all issues with floating point additions.",
	],
	[
		"info",
		"New Query remembers last person Assigned To.",
		"The New Query and Vouching Raise Query screens will remember who the last query was assigned To and default to that.",
	],
	[
		"info",
		"Vouching: Manual Merge Invoice Import Rules.",
		"Admin users can import/merge Import Rules files supplied from the config of other Customers. Keith will provide new files when available",
	],
	[
		"info",
		"Vouching: Auto-merge Vendor Aliases.",
		"Two or more users can create new vendor aliases at the same time, e.g. for different Period Ends under the same Managing Agent. This change ensures that when each user hits Save, their changes are merged with the previous saved changes so that no alaises are lost by being overwritten by another user.",
	],
]

const Release_2022_015 = [
	[
		"check",
		"Client Attachments.",
		"Access to the files attached to Customer / Clients / Managing Agents / Landlords has been restored on the Client Card. These files are visible to everybody at the Client, but they cannot change or upload files.",
	],
	[
		"info",
		"Vouching Report changes.",
		"Various Vouching Report changes, including:" +
			"\n\t\t• Report renamed from `... Exp Report.xlsx` to `... Vouching Report.xlsx`. " +
			"\n\t\t• Added 'Vouched Ref' column to BW report (invoice filename + page number)." +
			"\n\t\t• Added 'Invoice Amount' column to Expenditure worksheet.",
	],
	[
		"info",
		"Vouching Report: Added 'Exp Date Out Of Range Check' worksheet.",
		"Shows Expenditure items where:" +
			"\n\t\t• Date is missing." +
			"\n\t\t• Date is before the period covered by the Period End." +
			"\n\t\t• Date is after the period covered by the Period End.",
	],
	[
		"info",
		"Vouching Report: Added 'Duplicate Exp Check' worksheet.",
		"Shows Expenditure items where:\n\t\t• Supplier Name and Invoice Number and Total are the same.",
	],
	[
		"info",
		"Vouching Report: Added 'Duplicate Exp Invoice Numbers' worksheet.",
		"Shows Expenditure items where:\n\t\t• Invoice Numbers are the same (may be for different Suppliers or Totals).",
	],
	["info", "Vouching Report: Added 'Duplicate Exp Narrative' worksheet.", "Shows Expenditure items where:\n\t\t• Narrative text is the same."],
	[
		"info",
		"Vouching Report: Added 'Missing or Unexpected Exp' worksheet.",
		"Shows Expenditure items where there are more or fewer items than is suggested by the Narrative. Specifically counting items within Account and Vendor where:" +
			"\n\t\t• 'Fortnight': always included - needs manual checking." +
			"\n\t\t• 'bi-annual', 'twice a year', 'summer', 'winter', '6/six month': must be 2 per year." +
			"\n\t\t• 'annual' or 'year': must be 1 per year." +
			"\n\t\t• 'quarter', 'qtr' or 'qtrly': must be 4 per year." +
			"\n\t\t• 'Jan-Mar','January-March', etc.: must be 4 per year." +
			"\n\t\t• 'Month', 'Jan/January', etc.: must be 12 per year.",
	],
	[
		"info",
		"Vouching Report: Added 'Inv Date Out Of Range Check' worksheet.",
		"Shows Invoices where:" +
			"\n\t\t• Date is missing." +
			"\n\t\t• Date is before the period covered by the Period End." +
			"\n\t\t• Date is after the period covered by the Period End." +
			"\n\t\t• Date is before the Expenditure date.",
	],
]

const Release_2022_014 = [
	["info", "Close multiple queries at any stage.", "Where they exist, multiple queries can be closed even if they are not in the current stage."],
	[
		"info",
		"Vouching: 'Description' changed to 'Narrative'.",
		"'Description' labels and column headings have been changed to 'Narrative' in all vouching screens and reports.",
	],
	[
		"check",
		"Vouching: More reliable currency additions.",
		"Further improvements to combat JavaScript floating point errors when adding numbers containing decimals.",
	],
]

const Release_2022_013 = [
	["check", "Period End white screen for properties with blank postcodes.", "Bug fixed. "],
	[
		"check",
		"Vouching: Reduce false positive Invoice Refs as telephone numbers.",
		"9 and 10 digit reference numbers starting with 0 were incorrectly being identified as telephone numbers.",
	],
	["info", "Vouching: Remove '#' from Invoice References.", "Remove '#' prefixes from Invoice References"],
	["check", "Vouching: Negative invoice totals.", "Correctly import negative invoice totals."],
	[
		"info",
		"Vouching: Merge Invoices.",
		"Invoices that are adjacent in the same files can be merged using multi-select. This is useful in cases where the Invoices import has incorrectly split a utility invoice into several invoices, for example.",
	],
	[
		"info",
		"Vouching: Vendor Aliases.",
		"When a manual match between Expense and Invoice is made and the Vendor name is slightly different, Clearview creates a new alias for the vendor name and prompts the user to retry the Auto-match Wizard. Vendor Name Aliases are stored for re-use at the first Client level under the Customer.",
	],
	[
		"check",
		"Vouching: Currency additions corrected to remove JavaScript floating point errors.",
		"JavaScript can introduce tiny floating point errors when adding numbers containing decimals. All Invoice and Expense totals are now correctly added at 2 decimal places.",
	],
	[
		"info",
		"Vouching: Multi-Match.",
		"The auto-match function now matches Invoices to multiple Expenditure items where the Vendor Name and Reference is the same on multiple expenditure items and the sum total of all the Expenditure items matches the Invoice Total.",
	],
]

const Release_2022_012 = [
	["info", "Close Multiple Queries.", "In house Approvers can now close multiple Queries in a Stage in a single action."],
	[
		"info",
		"Vouching: Number of Invoice Pages displayed.",
		"In the invoice list, the number of pages is displayed when the invoice is more than one page. This should help identify utilities invoices which have been split up in the import.",
	],
	[
		"info",
		"Vouching: Property Names.",
		"The user can add alternative Property Names to improve the changes of finding the name on the invoices. There is a new 'house' icon next to the property name and the user will be prompted to review the property nam and aliases after an import, if there are any invoices where the name cannot be found.",
	],
	["info", "Vouching: Clear all data.", "Expenditure and Invoices can now be cleared down separately."],
	[
		"info",
		"Vouching: Invoice import checks for Property Name in all invoice text (not just recognised Expenses fields).",
		"A second 'text detection' data file is generated for each invoice file. This is processed to check for the Vendor Names on each invoice.",
	],
	["info", "Vouching: confirm on Delete All", "The user is prompted to confirm after clicking on Delete (undo) changes."],
	[
		"info",
		"Missing Invoices Queries",
		"A new Subject 'Missing Invoices' has been added to Queries. Any attachments associated with this subject will be stored in the Invoices subfolder and considered for vouching. Attachments for all other subjects will go into the normal Period End Stages subfolders.",
	],
	["check", "Query Attachments fix", "The new Query forms now momentarily show 'busy' when Add Attachments is pressed."],
	[
		"check",
		"Vouching: Attribute Editing restored",
		"Admins can edit Client and Property custom Attributes (VAT Elected, Is Landlord Corporate, etc) once again.",
	],
	[
		"info",
		"Error 404 page corrected",
		"Lorem Ipsum text removed from the error 404 page. Other redundant static pages (Coming Soon, Confirmation, Danger, Login and Success) have been removed.",
	],
]
const Release_2022_011 = [
	[
		"info",
		"Vouching: Customer wide OCR rules applied",
		"When Invoices are imported, a set of configurable rules is applied to improve the identification of the Vendor. Vendor is often difficult to identify due to the widespread use of logos instead of plain text. VAT number, company registration number, email address and other features can be used to identify the Vendor. Currently, only the System user can configure the rule set.",
	],
	[
		"info",
		"Vouching: Vendor name change can be applied to multiple Invoices",
		"When a vendor name is changed (saved) the user is prompted to confirm whether the same change should be made throughout all the current unmatched Invoices.",
	],
	["info", "Vouching: Dismiss multiple warnings", "Multiple warnings can now be dismissed when one or more Invoices have been selected."],
]

const Release_2022_010 = [
	[
		"info",
		"Vouching: Import will look at all fields for the Property name",
		"During import, all tokens extracted from the invoice will be checked to confirm if the Property name is shown anywhere in the file.",
	],
	[
		"info",
		"Vouching: Improvements to multi-page Invoice import",
		"Improvements have been made to correctly identify when invoices span multiple pages. Specifically, if the vendor name, invoice number and total are unchanged then the page is likely to belong to the previous invoice.",
	],
	["info", "Vouching: sort by document index ", "Invoices can be sorted by their document position in the file, where the file contains multiple Invoices."],
	[
		"info",
		"Vouching: Expense Editor shows document index",
		"The editor shows the document position number in the imported file, where the file contains multiple Invoices.",
	],
	[
		"info",
		"Vouching: Show Hide Queue Status",
		"A new icon in the top right of the vouching page allows the user to check if there is a backlog in processing new Invoice files.",
	],
]

const Release_2022_009a = [["check", "Hotfix: Period End view crashing", "Now fixed. This was due to problem determining the Attachments folder for Queries."]]

const Release_2022_009 = [
	[
		"info",
		"Query Attachment Folders",
		"Attachments to/for Queries with subjects, 'Expenditure', 'Income' and 'Balance Sheet' will be stored under the Invoices subfolder for inclusion in Vouching.",
	],
	["info", "Vouching: Sort by Status", "Expenditure and Invoice items can be sorted by Status."],
	["info", "Vouching: Linked amounts cannot be updated", "Expenditure and Invoice amounts cannot be changed if the item is linked/matched."],
	[
		"info",
		"Vouching: Multiselect",
		"Multi-select is available to both Expenditure and Invoice items and can be used to perform all possible actions on multiple items.",
	],
	[
		"info",
		"Vouching: Report format Currency and Date columns",
		"Vouching report all current columns are formatted with a custom currency formatter and all Dates are Excel Date values.",
	],
	[
		"info",
		"Vouching: Simplified Vouching report",
		"Vouching report now has just Expenditure and Invoices tabs. Note that BW exports will include ALL imported columns on the Expenditure tab.",
	],
	[
		"info",
		"Vouching: Use highest total on Invoice",
		"Sometimes OCR will find more than one `total`, e.g. 'Nett Total' and 'Total Due'. In this case the highest value is imported.",
	],
	[
		"info",
		"Vouching: do not import Net & VAT values",
		"Invoice Net and VAT values are not imported. All Expenditure to Invoice matches will be based on the Total value. However, Net and VAT values may be checked during Invoice import to unsure the correct Total is used.",
	],
	["info", "Vouching: Ignore leading spaces in Invoice Number", "Auto-match will match to imported invoice numbers with and without leading spaces."],
	[
		"info",
		"Vouching: Checks that Property name is present in the invoice",
		"An imported invoice will be marked as 'incomplete' if the property name cannot be found in the imported file.",
	],
	["info", "Vouching: Multiple Page Expenses", "The Expense import procedure now identifies multiple page invoices."],
	[
		"info",
		"Vouching: Expense Tokens Table",
		"In the Expense Edit, there is now a Token tab containing a table of all tokens and values in the detected in the invoice.",
	],
	["info", "Vouching: Can search Expense filenames", "In the Expense column the search will now also search in filenames."],
	["info", "Vouching: Identify amended Ledger Items", "The original Ledger Item details are stored and displayed in the Vouching Report."],
	[
		"info",
		"Vouching: Excluded invoices now in Vouching Report",
		"Vouching Report now contains a tab listing excluded invoices. Additionally, the Vouching Report is disabled if there are unsaved changes.",
	],
]

const Release_2022_008 = [
	[
		"check",
		"Cannot add new Properties",
		"Now fixed. Properties can be added manually in the Clients tree (although they screen needs to be refreshed to see them).",
	],
	["info", "Moving files now added", "Files and subfolders can now be moved around in the new Folder views."],
]

const Release_2022_007 = [
	[
		"check",
		"Unable to download Word Documents in Edge",
		"Word and Excel documents are downloaded in Edge rather than rely on the Office attachments to handle the URL itself.",
	],
	[
		"check",
		"Reports folder now working",
		"The user's Reports folder has now been upgraded to use the new Folder view. This allows downloading, deletion and re-organizing of files.",
	],
	["check", "Edit Property Details menu option", "The menu option to edit the details of a property has been added on the Clients view Property panel."],
	["info", "Fortus Scrollable phase tables on Overview", "The Month Summary and Client Summary have fixed row headers and scrollable data columns."],
]

const Release_2022_006 = [
	["check", "Force download of Word documents", "It was noted that .Docx files could not be opened or downloaded if the user is using Microsoft Edge."],
]

const Release_2022_005 = [
	// ***** REMEMBER TO MOVE THE STAGE ATTACHMENTS ***** //
	[
		"info",
		"New Fortus Overview tables",
		"Fortus users now have a list of Overview tables to select from: Overview, Month Summary, Client Summary and Manager Summary.",
	],
	[
		"info",
		"Period End Fee removed from Client views and exports",
		"The Period End Fee, which could have been blank for Client users, has been removed from Client views and exports.",
	],
	[
		"info",
		"Period End Expected Date hidden from Clients",
		"Fortus clients will no longer see the Period End Expected Date in the Period End list, export or details view.",
	],
	[
		"info",
		"Attachments shown in Files folders",
		"PeriodEnd files are shown in a single folder with subfolders. Stage and Query files live below the PeriodEnd folder.",
	],
	["info", "Clients cannot see future queries", "Clients cannot see Queries and Query files in stages which are not yet open."],
	[
		"info",
		"Import Clients, Properties and Period Ends",
		"Admin users can now import Clients, Properties and Period ends in bulk from Excel spreadsheets. New options exist for each Customer and Managing Agent card on the Clients screen. Note that the Property and Period End import formats are the same, i.e. a file can be uploaded to create Properties and then uploaded again to create the Period Ends.",
	],
	["check", "Template Clone to not copy PE Count", "Cloning a template erroneously copies the PE Count from the original. Now fixed."],
	[
		"info",
		"Folder view on Period End",
		"The old Period End and Stage attachment views have been replaced by new style folder views for Client Files, (In-House) Private Files and Draft/Published accounts. Subfolders for Stages should be named S01, S02, etc in order to hide them from users when that stage is not yet open.",
	],
	["info", "Folder Names removed from Templates", "Template Stages Folder Names have been removed (was not used)."],
	[
		"info",
		"Folder view on Property card",
		"A new style folder view, allowing browsing in a popup and supporting subfolders, has replaced the old Attachments list on the Property tile. Clients now have read-only access to Properties folders.",
	],
	["info", "Files Dashboard and Period End files view removed", "These files views have been removed as they have limited usefulness."],
	[
		"info",
		"Attachment count badges removed",
		"Period End and Stage badges no longer show the attachments counts. Only the number of queries and comments are now shown.",
	],
	[
		"info",
		"Clients page redeveloped",
		"The Clients page has been redeveloped to be compatible with the latest React version. In addition, editing is now done on each details & appointees card (rather than from a global edit button) and changes and additions are displayed in the clients screen without reloading.",
	],
	[
		"info",
		"Sidebar toggle and logout icon moved to sidebar",
		"To save space at the top of every page, the sidebar toggle and logout icon have been moved to the sidebar, The Home icon has been removed",
	],
	["info", "All page content moved up", "The space between the top of each page and the page heading has been reduced to make better use of the space."],
]

const Release_2022_004 = [
	[
		"info",
		"For Fortus Client stages, the close stage label is now 'Submit to Next Stage'",
		"Alternate texts for labels and titles can now be configured on a Customer by Customer basis",
	],
	["info", "Package.json upgrade", "General upgrade of library packages to support latest React and Typescript."],
]

const Release_2022_003 = [
	[
		"info",
		"Templates can be retired",
		"On the template details card, there is a new drop-down menu that allows a template to be retired and no longer used. Optionally, specify the template that automatically renewing Period Ends should use instead of this one.",
	],
	[
		"info",
		"For Fortus Client stages, the close stage label is now 'Submit to Fortus'",
		"Alternate texts for labels and titles can now be configured on a Customer by Customer basis",
	],
	["info", "Page headers are blue", "Page header titles now contain the subject Icon and are the primary colour (currently blue)."],
	["info", "Icon for copying PeriodEnd url changed", "This icon has been changed from the chain icon to the copy icon for consistency with Amazon styles."],
	["info", "Icon for attachments changed", "This icon has been changed from the '2 docs' icon to the folder icon so as not to be confused with 'copy'."],
]

const Release_2022_002 = [
	["check", "Cloning a Template clones Invoice Stage and Policy", "When a template is cloned, its Invoice After Stage and Policy will now also be cloned."],
]

const Release_2022_001 = [
	["check", "Template Card Editing fix", "Fixed the bug which prevented template settings being edited on the Template Details screen."],
	[
		"info",
		"Period End Hyperlinks",
		"Users can now click on a Link icon in the Period End Details title to copy a URL which links directly to the Period End. This is useful for embedding in emails, reports, etc.",
	],
]

const Release_20220523 = [
	[
		"info",
		"My Queries total on Overview page",
		"The Overview page now shows the number of queries the user has raised or is assigned to on the Overview page.",
	],
	[
		"info",
		"Admins can create new User Logins",
		"Admin users are able to create new logins from a spreadsheet. On the Clients page there is a new 'Upload New Users' action for each business which allows Admins to create new logins by dropping in a spreadsheet containing names and email addresses. A sample spreadsheet is provided on the page. The Admin user, and optionally the new user, will automatically be emailed the new username and password.",
	],
	[
		"info",
		"Client Actions available without going into edit mode",
		"Client Actions, e.g. 'Add Client', 'Users & Teams', are now available to Admin users in the Clients tree by clicking on the 'cog' icon on the client details card.",
	],
	[
		"info",
		"Stages with Open Queries will be expanded",
		"When viewing Period End details, in addition to the current Stage any Stages containing open Queries 'with us' will be expanded. When closing a Query in a previous Stage the details view will remain on that stage if there are further open Queries 'with us'.",
	],
	[
		"info",
		"Team Scopes have been removed",
		"The feature that allows users to be allocated to Scopes (lists of properties) has been removed from the UI. It has never been used.",
	],
	[
		"check",
		"Fix error creating first member of a Users team",
		"Admin users were unable to manually create a new User of a client/agent/landlord, unless one already existed.",
	],
]

const Release_20220511 = [
	[
		"info",
		"Period End and Query Detail Reports",
		"“In House Contact Name” and “Client Contact Name” are now included in the Period End Details and Query Details reports.",
	],
]

const Release_20220430 = [
	["info", "Show file version", "Admins can view deleted files and previous version by choosing Actions | Show File Versions in the attachments window."],
]

const Release_20220401 = [
	["check", "Managing agent column blank for some agents", "Managing Agent column in Period Eds view should now show if needed."],
	[
		"check",
		"Error when uploading multiple Period End attachments",
		"No error now, but user will need to refresh the Period End to get the full list of attachments.",
	],
	["info", "Additional reports", "Reports now available for Period End and Query details"],
]

const Release_20220319 = [
	["info", "Template Policies", "Policy details are now available from the Template Card."],
	[
		"check",
		"Missing Template Phase causes Progress Bar to crash",
		"In Phase view, the Period End progress bar will not crash if a template stage does not have a phase.",
	],
	["check", "Error saving new phases", "New phases could not be created once a phase had been deleted. Now fixed."],
]

const Release_20220312 = [
	[
		"info",
		"Stage Due Date column on Period Ends",
		"The Period Ends page now has a column for the current stage due date. The Period End and Properties table now no longer have a left margin to reduce horizontal scrolling.",
	],
	[
		"info",
		"Attachments can now be downloaded by selecting and choosing the download action.",
		"This is intended to be an alternative to double-clicking on a file, which is not available to Safari users on iPads.",
	],
	[
		"info",
		"Login errors improved. (Loading logo is also bigger).",
		"Users who have not been assigned to any properties will get a meaningful error when trying to log in rather than a page that never finishes loading.",
	],
	["info", "3rd party copyright & licenses updated.", "See under Support/Licenses."],
	["info", "Self service password resets.", "Support for self-service password resets via email token is now working."],
]
const Release_20220311 = [["check", "Rag2 Donut fixes for customer", "For customers the Rag2 donut for In House was incorrectly showing Client numbers."]]

const Release_20220228 = [
	[
		"check",
		"Stage Badges Restored",
		"The badges showing the number of comments, attachments, queries, etc. in each stage on the Period End details page were missing. They are now showing, even where the stage only contains attachments.",
	],
]

const Release_20220227 = [
	[
		"check",
		"Period End Badges",
		"The count of Queries, Comments and Attachments is now kept up-to-date and displayed on the Period End and Open Stages lists.",
	],
	["check", "Clear All Filters fix", "The Clear All Filters button causes subsequent filters to be unclearable. Now fixed."],
]
const Release_20220219 = [["info", "Template Fees", "Each template can be set to generate Clearview invoices after a specific stage is closed."]]

const Release_20220216a = [
	["check", "Overview links to Queries not working!", "Now fixed."],
	["check", "Stage summary grouping!", "Stage summary once more groups by template family."],
	[
		"check",
		"Stages having On Hold RAG status after restarting!",
		"Now fixed. Taking a stage off-hold will cause the RAG status of the stage to be recalculated",
	],
]

const Release_20220215 = [
	["check", "Blank screen when searching", "This could happen where there are blank addresses. The search feature will now cope with blank addresses."],
	[
		"info",
		"Rag2 donuts on Overview page",
		"Specific customers can choose to display Rag2 style [Overdue | On Target | On Hold] stage summary donuts on their overview page.",
	],
	[
		"info",
		"Navigate to Overview page",
		"Clicking on the Logo, Home icon or User sidebar avatar will reset the Period End filter to the default filter (i.e. all Open Period Ends).",
	],
	["info", "Negative due dates", "Templates can now have negative due dates, e.g. in order to prepare budgets before a period starts."],
	["info", "Template Stages", "The editable columns in the template stage editor will now be wide enough to see the value while editing."],
	["check", "Clicking on the Overview Stages or Clients table loses filters", "Filters will be preserved when clicking on the phase or stage summary value."],
]

const Release_20220206 = [
	["info", "Admin password resets", "Admin users can now perform password resets from the Clients / Users & Teams view."],
	["check", "Client Screen Unexpectedly Closing", "Now fixed. Modal dialog boxes were unexpectedly closing when Period Ends were update by another user."],
	["info", "Query Export Columns", "Query number, Subject and Title are now included in the query exports."],
]

const Release_20220129 = [
	[
		"info",
		"Overview menu resets filter",
		"Clicking on the Overview menu will reset the Period End filter to the default filter (i.e. all Open Period Ends).",
	],
	[
		"info",
		"Close query without comments",
		"You no longer need to provide any comments when closing a query. A default comment of 'Closed' will be inserted automatically.",
	],
	["info", "Closed Period Ends excluded from 'Unread'", "Closed or Deleted Period Ends do not show as 'Unread'."],
	["check", "Period End Pie Charts", "The Overview page pie Charts have been relabelled from 'Stages' to 'Period Ends'."],
	[
		"info",
		"No more `Are you sure you want to Cancel?`",
		"When clicking on Cancel in a dialog box, the user no longer has to answer an 'Are you sure you want to Cancel?' confirmation. This feature is no longer needed now that 'Open New Query' is more reliable.",
	],
	["info", "Reports (alpha)", "Reports menu is now available. Alpha version so please be patient and feedback suggestions for improvements."],
]

const Release_20220121 = [
	[
		"check",
		"Unread Period Ends",
		"'Unread' status only includes Period Ends currently In-House if you are an In-House user, and With Client if you are a Client user.",
	],
	[
		"check",
		"Overview table links to Open Period Ends",
		"Clicking on values in the stage/phase summary grid will now only include Period Ends which are Open.",
	],
	[
		"check",
		"Overview Page Links",
		"Statistics have been slightly re-organised and amended so that all clickable totals link through to pages with the same totals.",
	],
	["info", "Period End filter", "Filter box now includes 'quick filter' icons for Unread and Open Period Ends."],
	[
		"info",
		"Filter Box to Right",
		"The Filter box has been moved to the right of the Search box on the Open Stages, Period Ends, Properties and Query Management pages to make room for the filter options on smaller screens.",
	],
	["info", "Current & Future filters", "Queries can now be filtered by 'Current' or 'Future' stages."],
	["info", "Query Number for future queries", "The Query Numbers for future queries and now shows as dark grey."],
	["info", "Overview Page Loading", "The overview page remains greyed out until properties have been loaded."],
	[
		"info",
		"Mark all Read",
		"Clicking on the total number of unread Period Ends will now filter for just unread items. The 'mark all read' function is now on the end of the Period Ends filter.",
	],
	["check", "Period Ends unread not updating ", "Now fixed. When first used, all existing PEs are assumed to be unread."],
	["info", "Mark all Read", "Click on the total number of unread Period Ends to mark them all as read."],
	[
		"info",
		"Read/unread",
		"New feature. The number of 'unread' Period Ends are displayed attached to the user's initials. Unread Period Ends are those which have been changed by another user since the user last viewed the Period End details. A teal coloured dot next to the Period End reference indicates that the PE is unread. The user can click on this dot to change the read/unread status. Unread/read can now be selected as a filter. A link to the unread PEs is included in the Statistics section of the overview page.",
	],
	["check", "Attachments on New Queries", "Clicking on 'Add Attachments' will automatically open the Upload Files box."],
]
