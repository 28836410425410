import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import {
	Form,
	FormGroup,
	FormFeedback,
	Input,
	InputGroup,
	InputGroupAddon,
	UncontrolledModal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import CommentsEditor from "./CommentsEditor"

class Stage_AddCommentForm extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		doUpdate: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			title: "",
			comments: "",
		}
		this.props = props

		this.handleTitleChange = this.handleTitleChange.bind(this)
		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleTitleChange(event) {
		this.setState({
			title: event.target.value,
		})
	}

	handleCommentsChange(value) {
		this.setState({
			comments: value,
		})
	}

	handleActionButtonClick(event, action) {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}

		this.props.doUpdate({
			action: action,
			periodEnd: this.props.periodEnd,
			title: this.state.title,
			comments: this.state.comments,
			stage: this.props.stage,
		})
		this.setState({ title: "", comments: "" })
		return true
	}

	get isTitleRequired() {
		return !(this.state.title && this.state.title.length)
	}

	get isValid() {
		return !this.isTitleRequired
	}

	render() {
		return (
			<UncontrolledModal target={`stage${this.props.stage.id}_AddCommentForm`}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">Add a Comment</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Title</InputGroupAddon>
								<Input
									invalid={this.state.showValidation && this.isTitleRequired}
									type="text"
									name="title"
									id="title"
									placeholder="Title..."
									value={this.state.title}
									onChange={this.handleTitleChange}
								/>
								<FormFeedback>A short title is required</FormFeedback>
							</InputGroup>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<CommentsEditor value={this.state.comments} onChange={this.handleCommentsChange} />
								<FormFeedback>Comments are required</FormFeedback>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close
							color="default"
							className="text-primary"
							disabled={!this.isValid}
							onClick={e => this.handleActionButtonClick(e, "Comment")}
						>
							Save
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doUpdate: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_UPDATE,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Stage_AddCommentForm)
