import _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import { stringsMatch } from "clearview-aws-common"

import { scheduleCodeSort, scheduleDateCodeSort } from "./WorkingPapers.Common"
import { materialiseCommonImport } from "./WorkingPapers.Materialise"

export function onReadIncomeReportWorksheet(file, data, worksheetName) {
	const summary = {}
	const categoryLookup = cat => _.keys(summary).find(key => stringsMatch(key, cat)) || cat

	let row = 0
	for (let datum of data) {
		row++
		datum.Gross = clearview.ifSomething(datum.Gross, Math.round(datum.Gross * 100))

		const unixDate = Date.parse(datum["Period From"])
		// Is real date after 2000
		if (unixDate && unixDate > 946684800000) {
			datum.row = row
			const categoryName = categoryLookup(datum["Transaction Description"])
			datum.categoryName = categoryName
			if (!summary[categoryName]) summary[categoryName] = { count: 0, total: 0 }
			summary[categoryName].count = summary[categoryName].count + 1
			summary[categoryName].total = summary[categoryName].total + datum.Gross || 0
		}
	}

	return { file, data, summary }
}

export function onReadCommonWorksheet(name, data, totalColumnName = "Total", importAllColumns = false, sortFunction = scheduleCodeSort, workingPapers) {
	let output = []

	if (data) {
		let row = 0
		for (let datum of data.sort(scheduleCodeSort)) {
			row++

			const otherColumns = importAllColumns ? datum : {}
			const total = Math.round(datum[totalColumnName] * 100)

			if (!!datum.Code && !!datum[totalColumnName] && total) {
				const newRow = {
					row: row,
					...otherColumns,
					date: clearview.DateToString(datum.Date),
					schedule: (datum.Schedule || datum.Sch)?.toString(),
					code: datum.Code?.toString(),
					total: total,
				}
				delete newRow["Date"]
				delete newRow["Schedule"]
				delete newRow["Sch"]
				delete newRow["Code"]
				delete newRow[totalColumnName]
				output.push(newRow)
			}
		}
	}
	output = output.sort(scheduleCodeSort)

	return materialiseCommonImport(workingPapers || this.state.workingPapers, output).sort(sortFunction)
}

export function onReadComparativesWorksheet(name, data, workingPapers) {
	let output = []

	if (data) {
		let row = 0
		for (let datum of data.sort(scheduleCodeSort)) {
			row++

			if (!!datum.Code) {
				const newRow = {
					row,
					schedule: clearview.tidy.string(datum["Schedule"]),
					code: clearview.tidy.string(datum["Code"]),
					account: clearview.tidy.string(datum["Account"]),
					budget: clearview.currencyInput(datum["Budget"], null),
					comparativeBudget: clearview.currencyInput(datum["Prior Period Budget"], null),
					expenditure: clearview.currencyInput(datum["Expenditure"], null),
					income: clearview.currencyInput(datum["Income Report"], null),
					clientReserve: clearview.currencyInput(datum["Reserve Fund Dr/(Cr)"], null),
					clientTrialBalance: clearview.currencyInput(datum["Client Trial Balance Dr/(Cr)"], null),
					perOriginalReportsDr: clearview.currencyInput(datum["Per Original Reports Dr"], null),
					perOriginalReportsCr: clearview.currencyInput(datum["Per Original Reports Cr"], null),
					journalDr: clearview.currencyInput(datum["Journal Dr"], null),
					journalCr: clearview.currencyInput(datum["Journal Cr"], null),
					drAndPrepay: clearview.currencyInput(datum["Res c/f Dr"], null),
					crAndAccrual: clearview.currencyInput(datum["Res c/f Cr"], null),
					perAmendedAgentReportsDrCr: clearview.currencyInput(datum["Per Amended Agent Reports Dr/(Cr)"], null),
					presentationDr: clearview.currencyInput(datum["Presentation Adjustments Dr"], null),
					presentationCr: clearview.currencyInput(datum["Presentation Adjustments Cr"], null),
					perAccountsDrCr: clearview.currencyInput(datum["Per Accounts Dr/(Cr)"], null),
				}
				output.push(newRow)
			}
		}
	}
	output = output.sort(scheduleCodeSort)

	return output
}

export function onReadCRsAndAccrualsWorksheet(file, data, worksheetName, workingPapers) {
	for (let datum of data) {
		datum.reference = datum["BW Ref"]
		datum.comments = datum["Comments"]
		datum.supplier = datum["Supplier"]
		delete datum["BW Ref"]
		delete datum["Comments"]
		delete datum["Supplier"]

		delete datum["__EMPTY"]
		delete datum["__EMPTY_1"]
		delete datum["__EMPTY_2"]
	}

	return onReadCommonWorksheet("crsAndAccruals", data, "Creditor Amount", true, scheduleDateCodeSort, workingPapers)
}

export function onReadDRsAndPrepayWorksheet(file, data, worksheetName, workingPapers) {
	for (let datum of data) {
		datum.reference = datum["BW Ref"]
		datum.comments = datum["Comments"]
		datum.supplier = datum["Supplier"]
		delete datum["BW Ref"]
		delete datum["Comments"]
		delete datum["Supplier"]

		delete datum["__EMPTY"]
		delete datum["__EMPTY_1"]
		delete datum["__EMPTY_2"]
	}
	return onReadCommonWorksheet("drsAndPrepay", data, "Debtor Amount", true, scheduleDateCodeSort, workingPapers)
}
