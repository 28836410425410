import React from "react"

import { Link } from "react-router-dom"
import * as clearview from "../../../../components/@Clearview"

import { Avatar, Row, Col } from "../../../../components"

import { Query } from "./../../Stages/components/Comment"
import { PeriodEndFavourite } from "../../PeriodEnds/components/PeriodEndFavourite"
import { QueryRipenessBadge, QuerySubjectBadge, EventNameBadge } from "../../Stages/components/CommentsTable"

import PropTypes from "prop-types"

export const QueriesTableBody = ({ query }) => (
	<React.Fragment key={`QTB.RF.${query.id}`}>
		<Row key={`${query.id}aa`}>
			<Col>
				<Link to={`/periodend/${query.stage.periodEnd.reference}`} className="text-decoration-none highlight">
					<span>
						{query.stage.periodEnd.reference}
						<sup>
							<PeriodEndFavourite periodEnd={query.stage.periodEnd} />
						</sup>
					</span>
					<span className="ml-2">{QueryRipenessBadge(query)}</span>
					<span className="ml-4">
						{query.subject ? QuerySubjectBadge(query) : EventNameBadge(query)} {(query.title || query.comments || "").substring(0, 80)}
					</span>
				</Link>
			</Col>
		</Row>

		<Row className="mb-2" key={`${query.id}a`}>
			<Col>
				<span>
					{query.stage.periodEnd.template.name} <br />S{query.stage.templateStage.sequence}: {query.stage.templateStage.name}
				</span>
			</Col>

			<Col>
				<div>
					<Link to={`/clients/${query.stage.periodEnd.property.reference}`} className="text-decoration-none">
						{query.stage.periodEnd.property.reference}: {query.stage.periodEnd.property.name}
					</Link>
				</div>
				<span>
					{query.stage.periodEnd.property.address?.fullAddressExcludingPostcode} {query.stage.periodEnd.property.address?.postcode}
				</span>
			</Col>

			<Col>
				<div>
					{query.stage.periodEnd.property.parent && query.stage.periodEnd.property.parent.parent && (
						<Link to={`/clients/${query.stage.periodEnd.property.parent.parent.reference}`} className="text-decoration-none">
							{clearview.GetBusinessTitle(query.stage.periodEnd.property.parent.parent)}
						</Link>
					)}
					{query.stage.periodEnd.property.parent && (
						<Link to={`/clients/${query.stage.periodEnd.property.parent.reference}`} className="text-decoration-none">
							{clearview.GetBusinessTitle(query.stage.periodEnd.property.parent)}
						</Link>
					)}
				</div>
				<span>
					In House Contact:{" "}
					{query.stage.periodEnd.property.actors.inHouseContact && (
						<span className="text-inverse">
							<Avatar.Font user={query.stage.periodEnd.property.actors.inHouseContact} size="sm" className="avatar-with-text mr-1"></Avatar.Font>
							{query.stage.periodEnd.property.actors.inHouseContact.name}
						</span>
					)}
					<br />
					Client Contact:{" "}
					{query.stage.periodEnd.property.actors.clientContact && (
						<span className="text-inverse">
							<Avatar.Font user={query.stage.periodEnd.property.actors.clientContact} size="sm" className="avatar-with-text mr-1"></Avatar.Font>
							{query.stage.periodEnd.property.actors.clientContact.name}
						</span>
					)}
				</span>
			</Col>
		</Row>

		<Row key={`${query.id}b`}>
			<Col>
				<Query stage={query.stage} query={query} />
			</Col>
		</Row>
	</React.Fragment>
)

QueriesTableBody.propTypes = {
	query: PropTypes.object.isRequired,
}
