import React, { useState } from "react"

import * as clearview from "../../../../components/@Clearview"

export function PeriodEndCopyUrl() {
	const [copied, setCopied] = useState(false)

	function onCopyUrl() {
		const el = document.createElement("input")
		el.value = window.location.href
		document.body.appendChild(el)
		el.select()
		document.execCommand("copy")
		document.body.removeChild(el)
		setCopied(true)
	}

	return (
		<a href="#" id="periodEndCopyUrl" onClick={e => onCopyUrl()}>
			{!copied && clearview.Icon.copyLinkToPeriodEnd}
			{copied && clearview.Icon.copiedLink}
		</a>
	)
}
