import React from "react"
import PropTypes from "prop-types"

import { Button } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"
import S3UncontrolledModal from "./S3UncontrolledModal"

export default class S3Popup extends React.Component {
	static propTypes = {
		folder: PropTypes.string.isRequired,
		color: PropTypes.string,
		className: PropTypes.string,
		children: PropTypes.node,
		title: PropTypes.string,
		name: PropTypes.string,
		onOpened: PropTypes.func,
		onChange: PropTypes.func,
		onClosed: PropTypes.func,
	}

	render() {
		return (
			<React.Fragment>
				<Button
					color={this.props.color || "info"}
					size="sm"
					title="Open"
					className={this.props.className}
					id={clearview.MakeSafeFolderId(this.props.folder)}
				>
					{this.props.children || this.props.title || "Files..."}
				</Button>

				<S3UncontrolledModal {...this.props}></S3UncontrolledModal>
			</React.Fragment>
		)
	}
}
