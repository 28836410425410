import { call, put, takeEvery } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

function* folderFetch(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const files = yield call(Api.fetchAttachments, action.payload.entityType, action.payload.id)

		yield put({
			type: ACTIONS.FOLDER_FETCHED,
			files: files,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 025",
			type: ACTIONS.FOLDER_FETCH_FAILED,
			message: e.message,
		})
	}
}

export default function* fileSaga() {
	yield takeEvery(ACTIONS.FOLDER_FETCH, folderFetch)
}
