import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { WP_ACTIONS } from "./store"

import * as clearview from "../../../../components/@Clearview"

import FileUploadButton, { excelSheetReader } from "../../Workflows/components/FileUploadButton"
import { SimpleTable } from "../../../../components"
import { FormGroup, CustomInput, UncontrolledTooltip } from "../../../../components"
import { ETBExportAsTemplate } from "./components/ETBExport"

function ComparativesPage({ isReadOnly, comparatives, onReadWorksheet, onSaveDetails, workingPapers }) {
	const columns = {
		code: { map: clearview.tidy.string },
		account: { map: clearview.tidy.string, className: "nowrap" },

		budget: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Budget
					<br />£
				</span>
			),
			className: "currency",
		},
		comparativeBudget: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Prior Period Budget
					<br />£
				</span>
			),
			className: "currency",
		},
		expenditure: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Expenditure
					<br />£
				</span>
			),
			className: "currency",
		},
		income: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Income Rpt
					<br />£
				</span>
			),
			className: "currency",
		},
		clientReserve: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Res Fund
					<br />
					Dr/(Cr)
				</span>
			),
			className: "currency",
		},
		clientTrialBalance: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Client TB
					<br />
					Dr/(Cr)
				</span>
			),
			className: "currency",
		},
		perOriginalReportsDr: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Per Original Reports
					<br />
					Dr
				</span>
			),
			className: "currency",
		},
		perOriginalReportsCr: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Per Original Reports
					<br />
					Cr
				</span>
			),
			className: "currency",
		},
		journalDr: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Journal
					<br />
					Dr
				</span>
			),
			className: "currency",
		},
		journalCr: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Journal
					<br />
					Cr
				</span>
			),
			className: "currency",
		},
		drAndPrepay: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Res c/f
					<br />
					Dr
				</span>
			),
			className: "currency",
		},
		crAndAccrual: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Res c/f
					<br />
					Cr
				</span>
			),
			className: "currency",
		},
		perAmendedAgentReportsDrCr: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Per Amended Agent Reports
					<br />
					Dr/(Cr)
				</span>
			),
			className: "currency",
		},
		presentationDr: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Pres Adj
					<br />
					Dr
				</span>
			),
			className: "currency",
		},
		presentationCr: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Pres Adj
					<br />
					Cr
				</span>
			),
			className: "currency",
		},
		perAccountsDrCr: {
			map: clearview.tidy.currency,
			heading: (
				<span>
					Per Accounts
					<br />
					Dr/(Cr)
				</span>
			),
			className: "currency",
		},
	}

	const summaryColumns = [
		"budget",
		"comparativeBudget",
		"expenditure",
		"income",
		"clientReserve",
		"clientTrialBalance",
		"perOriginalReportsDr",
		"perOriginalReportsCr",
		"journalDr",
		"journalCr",
		"drAndPrepay",
		"crAndAccrual",
		"perAmendedAgentReportsDrCr",
		"presentationDr",
		"presentationCr",
		"perAccountsDrCr",
	]

	const onChangeDetails = e => onSaveDetails({ [e.target.name]: e.target.value })

	return (
		<React.Fragment>
			<FormGroup row>
				<label id="labelPreviousPeriod" className="mt-2 ml-2 mr-2">
					Previous Period:{" "}
				</label>
				<UncontrolledTooltip placement="top" target="labelPreviousPeriod">
					This is the value of <b>details.previousPeriod</b> in the report template.
				</UncontrolledTooltip>
				<CustomInput
					style={{ width: "16em" }}
					disabled={isReadOnly}
					type="text"
					name="previousPeriod"
					id="previousPeriod"
					className="form-control"
					value={workingPapers.details.previousPeriod}
					onChange={onChangeDetails}
				/>
				<label id="labelPreviousDuration" className="mt-2 ml-4 mr-2">
					Duration:{" "}
				</label>
				<UncontrolledTooltip placement="top" target="labelPreviousDuration">
					This is the value of <b>details.previousDuration</b> in the report template.
				</UncontrolledTooltip>
				<CustomInput
					style={{ width: "8em" }}
					disabled={isReadOnly}
					type="text"
					name="previousDuration"
					id="previousDuration"
					className="form-control"
					value={workingPapers.details.previousDuration}
					onChange={onChangeDetails}
				/>
			</FormGroup>

			<SimpleTable className="etb-table mt-2" columns={columns} data={comparatives} groupBy={["schedule"]} summaryColumns={summaryColumns} />
			{!isReadOnly && (
				<div style={{ display: "inline-flex", verticalAlign: "middle" }}>
					<FileUploadButton className="mt-2" onUploaded={excelSheetReader(onReadWorksheet)} accept=".xls,.xlsx,.csv">
						{clearview.Icon.upload} Import Previous ETB from Excel...
					</FileUploadButton>
					<div className="ml-2 mt-3 text-left">
						<Link
							to="."
							className="text-decoration-none nowrap mr-2 text-primary"
							onClick={e => {
								if (!!workingPapers.etb.length) ETBExportAsTemplate(workingPapers.periodEnd, workingPapers.etb)
								else clearview.ShowToast("warning", <p>There is no data on your ETB page to use as a template.</p>)
								clearview.StopPropagation(e)
								return false
							}}
						>
							{clearview.Icon.FileType.Excel} Export ETB Template file
						</Link>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		comparatives: state.workingPapersReducer.workingPapers.comparatives,
		workingPapers: state.workingPapersReducer.workingPapers,
	}
}

const mapDispatchToProps = dispatch => ({
	onSaveDetails: details =>
		dispatch({
			type: WP_ACTIONS.SAVE_DETAILS,
			details: details,
		}),

	onReadWorksheet: (file, data, worksheetName) =>
		dispatch({
			type: WP_ACTIONS.READ_COMPARATIVES_WORKSHEET,
			file,
			data,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComparativesPage)
