import React from "react"

import * as _ from "lodash"

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer"
import { images } from "./Images"

// import FontRegular from "../../../../../images/fonts/OpenSans_SemiCondensed/OpenSans_SemiCondensed-Regular.ttf"
// import FontBold from "../../../../../images/fonts/OpenSans_SemiCondensed/OpenSans_SemiCondensed-Bold.ttf"
// import FontItalic from "../../../../../images/fonts/OpenSans_SemiCondensed/OpenSans_SemiCondensed-Italic.ttf"
// import FontBoldItalic from "../../../../../images/fonts/OpenSans_SemiCondensed/OpenSans_SemiCondensed-BoldItalic.ttf"

export default function DocumentToPdf(
	document,
	meta = {
		title: "Preview",
		author: "Clearview",
		subject: "Preview",
	}
) {
	// Font.register({ family: "Font", src: FontRegular })
	// Font.register({ family: "Font-Bold", src: FontBold })
	// Font.register({ family: "Font-Italic", src: FontItalic })
	// Font.register({ family: "Font-BoldItalic", src: FontBoldItalic })

	const styles = StyleSheet.create(document.styles)
	const classes = StyleSheet.create(document.classes)

	const pdfSomething = (it, idx) => {
		const type = _.keys(it)[0]
		const value = _.values(it)[0]

		switch (type) {
			case "page":
				return pdfPage(it, idx)
			case "table":
				return pdfTable(it, idx)
			case "tr":
				return pdfTableRow(it, idx)
			case "svg":
				return images[value](it, idx)
			default:
				return (
					<Text key={idx} style={resolveStyles(it)}>
						{value}
					</Text>
				)
		}
	}

	const pdfPage = (it, idx) => (
		<Page key={idx} size={it.size || "A4"} orientation={it.orientation || "portrait"} style={resolveStyles(it)} bookmark={it.bookmark}>
			{it.content.map(pdfSomething)}
		</Page>
	)

	const pdfTable = (it, idx) => (
		<View key={idx} style={resolveStyles(it)} wrap={false}>
			{it.table.rows.map(pdfSomething)}
		</View>
	)

	const pdfTableRow = (it, idx) => (
		<View key={idx} style={resolveStyles(it)}>
			{it.tr.map(pdfSomething)}
		</View>
	)

	const resolveStyles = it => {
		const type = _.keys(it)[0]

		const pdfStyles = [styles[type]]
		if (it.class) pdfStyles.push(...it.class.split(" ").map(className => classes[className]))
		if (it.style) {
			pdfStyles.push(it.style)
		}
		return pdfStyles
	}

	return (
		<Document title={meta.title} author={meta.author} subject={meta.subject} creator="DocumentToPdf">
			{document.pages.map(pdfSomething)}
		</Document>
	)
}
