import { call, put, takeLatest } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

function* manageTeams(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		const teams = yield call(Api.manageTeams, action.business, action.teams)

		yield put({
			type: ACTIONS.TEAMS_MANAGED,
			businessId: action.business.id,
			teams: teams,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 022",
			type: ACTIONS.TEAMS_MANAGE_FAILED,
			message: e.message,
		})
	}
}

function* assignUsersProperties(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		yield call(Api.userAssignUsersProperties, action.user1, action.user2, action.mode)

		yield put({
			type: ACTIONS.USER_ASSIGN_USERS_PROPERTIES_OK,
			message: "Users will need to refresh Clearview to see their new assignments.",
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 032",
			type: ACTIONS.USER_ASSIGN_USERS_PROPERTIES_FAILED,
			message: e.message,
		})
	}
}

export default function* teamSaga() {
	yield takeLatest(ACTIONS.TEAMS_MANAGE, manageTeams)
	yield takeLatest(ACTIONS.USER_ASSIGN_USERS_PROPERTIES, assignUsersProperties)
}
