import { call, put, takeLatest } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

//Worker Functions
function* startPeriodEnd(action) {
	try {
		const periodEnd = yield call(Api.startPeriodEnd, action.periodEnd, action.comments)

		yield put({
			type: ACTIONS.PERIODEND_STARTED,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
			// type: ACTIONS.CUSTOMER_FETCHED,
			// templates: customer.templates,
			// receivedAt: Date.now()
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODEND_START_FAILED,
			message: e.message,
		})
	}
}

function* deletePeriodEnd(action) {
	try {
		const periodEnd = yield call(Api.deletePeriodEnd, action.periodEnd, action.comments, action.doNotRenew)
		//Re-use the old reference so the reducer updates the correct entity
		periodEnd.reference = action.periodEnd.reference

		yield put({
			type: ACTIONS.PERIODEND_DELETED,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
			// type: ACTIONS.CUSTOMER_FETCHED,
			// templates: customer.templates,
			// receivedAt: Date.now()
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODEND_DELETE_FAILED,
			message: e.message,
		})
	}
}

export default function* periodEndStartSaga() {
	yield takeLatest(ACTIONS.PERIODEND_START, startPeriodEnd)
	yield takeLatest(ACTIONS.PERIODEND_DELETE, deletePeriodEnd)
}
