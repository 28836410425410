import React from "react"

import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Form, FormGroup, FormFeedback, InputGroup, UncontrolledModal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import CommentsEditor from "./CommentsEditor"

class Query_ReplyForm extends React.Component {
	static propTypes = {
		target: PropTypes.string.isRequired,
		periodEnd: PropTypes.object.isRequired,
		stage: PropTypes.object.isRequired,
		query: PropTypes.object.isRequired,
		sendReply: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = { comments: "" }

		this.handleCommentsChange = this.handleCommentsChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleCommentsChange(value) {
		this.setState({
			comments: value,
		})
	}

	handleActionButtonClick(event) {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}

		this.props.sendReply({
			periodEnd: this.props.periodEnd,
			queryId: this.props.query.id,
			comments: this.state.comments,
		})
		this.setState({ comments: "" })
		return true
	}

	get isCommentsRequired() {
		return !(this.state.comments && this.state.comments.length)
	}

	get isValid() {
		return !this.isCommentsRequired
	}

	render() {
		return (
			<UncontrolledModal target={this.props.target}>
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">Reply to Query</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<CommentsEditor
									invalid={this.state.showValidation && this.isCommentsRequired}
									value={this.state.comments}
									onChange={this.handleCommentsChange}
								/>
								<FormFeedback>Comments are required</FormFeedback>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close color="default" className="text-primary" disabled={!this.isValid} onClick={this.handleActionButtonClick}>
							Reply
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	sendReply: payload =>
		dispatch({
			type: ACTIONS.PERIODEND_REPLY_TO_QUERY,
			...payload,
		}),
})

export default connect(null, mapDispatchToProps)(Query_ReplyForm)
