import { call, put, takeLatest } from 'redux-saga/effects'
import * as Api from '../../api/CustomerApi'

import * as ACTIONS from './index'


//Worker Functions
function* fetchTemplates(action) {
   try {
    const templates = yield call(Api.fetchTemplates);
    yield put({
      type: ACTIONS.TEMPLATES_FETCHED, 
      templates: templates,
      receivedAt: Date.now()
      }
    );

    if (action.firstLoad) yield put( { type: ACTIONS.CLIENTS_FETCH } )

   } catch (e) {
      yield put({
        type: ACTIONS.TEMPLATES_FETCH_FAILED,
        message: e.message
      });
   }
}


function* updateTemplates(action) {
  try {

   console.info(`SAGA: ${action.type}`)

   const templates = yield call(Api.updateTemplates, action.payload.customerTemplates)

   yield put({
     type: ACTIONS.TEMPLATES_UPDATED,
     templates: templates,
     receivedAt: Date.now()
   })


  } catch (e) {
     yield put({
       type: ACTIONS.TEMPLATES_UPDATE_FAILED,
       message: e.message
     });
  }
}


export default function* templatesSaga() {
  yield takeLatest(ACTIONS.TEMPLATES_FETCH, fetchTemplates);
  yield takeLatest(ACTIONS.TEMPLATES_UPDATE, updateTemplates);
}
