import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import * as Api from "../../../../api/CustomerApi"

import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"

import { Button, Alert, Row, Col, Form, FormGroup, InputGroupAddon, CustomInput } from "../../../../components"

import Preferences from "./Preferences"

class VendorAliases extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		business: PropTypes.object,
	}

	constructor(props) {
		super(props)

		this.state = {
			business: {},
			isBusy: true,
			isChanged: false,
			vendors: [],
		}

		this.loadPreferences = this.loadPreferences.bind(this)

		this.onSave = this.onSave.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.clearAll = this.clearAll.bind(this)
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.business && !this.props.business) {
			this.loadPreferences(nextProps.business)
		}
	}

	mapPersistenceToState = resp => {
		return _.keys(resp)
			.sort(clearview.caseInsensitiveSort)
			.map(vendorName => ({ vendorName, aliases: resp[vendorName] }))
	}

	loadPreferences(business) {
		Api.loadPreferences(business, "vendor-aliases", []).then(resp => {
			this.setState({
				business: business,
				isBusy: false,
				isChanged: false,
				selectedVendor: null,
				selectedIndex: null,
				vendors: this.mapPersistenceToState(resp),
			})
		})
	}

	onSave() {
		this.setState({ isBusy: true })

		for (let vendor of this.state.vendors) {
			vendor.vendorName = vendor.vendorName?.trim()
			vendor.aliases = _.uniq(vendor.aliases.map(it => it?.trim()).filter(it => it && it !== vendor.vendorName))
		}

		const vendors = _.keyBy(
			_.uniqBy(
				this.state.vendors.filter(it => it.vendorName && it.aliases.length),
				it => it.vendorName
			),
			it => it.vendorName
		)

		const vendorAliases = _.mapValues(vendors, it => it.aliases)

		Api.savePreferences(this.state.business, "vendor-aliases", vendorAliases).then(() => this.loadPreferences(this.state.business))
	}

	onCancel() {
		this.loadPreferences(this.state.business)
	}

	clearAll() {
		this.setState(prevState => ({ isBusy: false, isChanged: !!prevState.vendors.length, selectedVendor: null, selectedIndex: null, vendors: [] }))
	}

	render() {
		const { business, isBusy, isChanged, vendors } = this.state

		return (
			<Preferences
				key="preferences"
				business={business}
				title="Vendor Aliases"
				narrative="These aliases help the Vouching Auto-match Process match invoice Vendor Names to Vendor Names in the Expenditure report."
				isBusy={isBusy}
				isChanged={isChanged}
				commands={[
					<Button
						key="addNew"
						disabled={!vendors || !vendors.length}
						className="ml-1 fit-content-h"
						outline={true}
						color="primary"
						title="Add new Vendor Alias"
						onClick={e => this.onAddNewVendor(e)}
					>
						{clearview.Icon.create}
					</Button>,
					<EditSaveOrCancel key="editSaveOrCancel" isEditing={true} isChanged={isChanged} onSave={this.onSave} onCancel={this.onCancel} />,
				]}
			>
				<Form>
					<Row>
						<Col md={6} style={{ height: 600, overflowY: "scroll" }}>
							<ul className="list-group">
								{vendors?.map((vendor, idx) => (
									<li key={`$iv:${idx}`} className="list-group-item hover-highlight pt-0 pb-0" onClick={e => this.selectVendor(e, idx)}>
										{vendor.vendorName} ({vendor.aliases.length})
									</li>
								))}
							</ul>
						</Col>
						{this.state.selectedVendor && (
							<Col md={6} id="vendor-aliases" style={{ height: 600, overflow: "hidden", overflowY: "scroll" }}>
								{this.vendorEditor(this.state.selectedVendor)}
								{this.vendorAliasesEditor(this.state.selectedVendor)}
							</Col>
						)}
					</Row>
				</Form>
			</Preferences>
		)
	}

	selectVendor(e, index) {
		this.setState({ selectedVendor: this.state.vendors[index], selectedIndex: index })
	}

	vendorEditor(vendor) {
		return (
			<FormGroup row className="input-group">
				<InputGroupAddon addonType="prepend">Vendor</InputGroupAddon>
				<CustomInput
					type="text"
					key="vendorName"
					id="vendorName"
					name="vendorName"
					className="form-control"
					invalid={!vendor.vendorName}
					value={vendor.vendorName}
					onChange={e => this.handleVendorChange(e)}
				></CustomInput>
			</FormGroup>
		)
	}

	handleVendorChange(e) {
		this.state.selectedVendor[e.target.name] = e.target.value?.toLowerCase()
		this.setState({ ...this.state, isChanged: true })
	}

	vendorAliasesEditor(vendor) {
		return (
			<table className="table mb-2" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th colSpan={3}>Aliases</th>
					</tr>
				</thead>
				<tbody>{vendor.aliases.map((alias, aIdx) => this.vendorAliasEditor(vendor, alias, aIdx))}</tbody>
				<tfoot>
					<tr>
						<td>
							<Alert color="info" className="mt-2">
								<h6 className="mb-1 alert-heading">Vendor Aliases</h6>
								Vendor aliases are created automatically on the Vouching page when Invoices are dragged onto Expenses. It is not usually
								necessary to edit this data here. However, if a Vendor Alias has been created in error, this is the place to delete it.
							</Alert>
						</td>
					</tr>
				</tfoot>
			</table>
		)
	}

	vendorAliasEditor(vendor, alias, aIdx) {
		return (
			<tr key={`tr_${aIdx}`}>
				<td>
					<CustomInput
						type="text"
						key={`alias_${aIdx}`}
						id={`alias_${aIdx}`}
						name={`alias_${aIdx}`}
						style={{ width: "100%" }}
						className="form-control"
						invalid={!alias}
						value={alias}
						onChange={e => this.handleAliasChange(e, aIdx)}
					/>
				</td>
				<td>
					<span title="Delete this alias" className="hover text-danger" onClick={e => this.onRemoveAlias(e, vendor, aIdx)}>
						{clearview.Icon.delete}
					</span>
				</td>
				{aIdx === vendor.aliases.length - 1 && (
					<td colSpan={aIdx !== vendor.aliases.length - 1 ? 1 : 2}>
						<span title="Add new alias" className="hover text-primary" onClick={e => this.onAddNewAlias(e)}>
							{clearview.Icon.add}
						</span>
					</td>
				)}
			</tr>
		)
	}

	handleAliasChange(e, aIdx) {
		this.state.selectedVendor.aliases[aIdx] = e.target.value?.toLowerCase()
		this.setState({ ...this.state, isChanged: true })
	}

	onAddNewVendor(e) {
		let newVendor = ""

		this.state.selectedIndex =
			this.state.vendors.push({
				vendorName: newVendor,
				aliases: [""],
			}) - 1

		this.state.selectedVendor = this.state.vendors[this.state.selectedIndex]

		this.setState({
			...this.state,
			isChanged: true,
		})
	}

	onAddNewAlias(e) {
		this.state.selectedVendor.aliases.push("")
		this.setState({
			...this.state,
			isChanged: true,
		})
	}

	onRemoveAlias(e, vendor, aIdx) {
		vendor.aliases.splice(aIdx, 1)
		if (!vendor.aliases.length) {
			vendor.aliases = [""]
			clearview.ShowToast("success", <p>Vendors with no aliases will be removed when you save.</p>)
		}

		this.setState({
			...this.state,
			isChanged: true,
		})
	}
}

const mapStateToProps = state => {
	const user = state.userReducer.user || clearview.User.UnauthenticatedUser

	const business = ACTIONS.findInBranches(
		state.clientsReducer.clients.data,
		it => it.id === parseInt(clearview.PathPart(3)),
		it => it.children
	)

	return {
		user,
		business,
	}
}

export default connect(mapStateToProps)(VendorAliases)
