import React from "react"
import { connect } from "react-redux"

import * as clearview from "../../../../components/@Clearview"
import PropTypes from "prop-types"

import { IconWithBadge, Badge } from "../../../../components"

class StageBadges extends React.Component {
	static propTypes = {
		stage: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired,
	}

	render() {
		const { stage, user } = this.props
		if (!stage) return

		const hideFutureBadges =
			!user.isInHouse && stage.periodEnd.currentStage && stage.templateStage.sequence > stage.periodEnd.currentStage.templateStage.sequence

		const stageBadges = {
			comments: stage.badges?.comments,
			openQueriesWithUs: hideFutureBadges ? null : stage.badges?.openQueriesWithUs,
			openQueriesWithThem: hideFutureBadges ? null : stage.badges?.openQueriesWithThem,
			draftQueries: hideFutureBadges ? null : stage.badges?.draftQueries,
			closedQueries: hideFutureBadges ? null : stage.badges?.closedQueries,
		}

		const anyBadges =
			stageBadges.comments || stageBadges.openQueriesWithUs || stageBadges.openQueriesWithThem || stageBadges.draftQueries || stageBadges.closedQueries

		if (anyBadges)
			return (
				<React.Fragment>
					{!!stageBadges.draftQueries && (
						<span title="Draft Queries">
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors["DraftQuery"]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors["DraftQuery"]}>
										{stageBadges.draftQueries}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass["DraftQuery"]} />
							</IconWithBadge>
						</span>
					)}
					{!!stageBadges.openQueriesWithUs && (
						<span title={`Open Queries with ${clearview.Us(this.props.user).name}`}>
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors.Query[true]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors.Query[true]}>
										{stageBadges.openQueriesWithUs}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass.Query[true]} />
							</IconWithBadge>
						</span>
					)}
					{!!stageBadges.openQueriesWithThem && (
						<span title={`Open Queries with ${clearview.Them(this.props.user).name}`}>
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors.Query[false]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors.Query[false]}>
										{stageBadges.openQueriesWithThem}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass.Query[false]} />
							</IconWithBadge>
						</span>
					)}
					{!!stageBadges.closedQueries && (
						<span title="Closed Queries">
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors["ClosedQuery"]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors["ClosedQuery"]}>
										{stageBadges.closedQueries}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass["ClosedQuery"]} />
							</IconWithBadge>
						</span>
					)}
					{!!stageBadges.comments && (
						<span title="Comments">
							<IconWithBadge
								className={`text-${clearview.CommentTypeColors["Comment"]}`}
								badge={
									<Badge pill color={clearview.CommentTypeColors["Comment"]}>
										{stageBadges.comments}
									</Badge>
								}
							>
								<i className={clearview.CommentTypeIconClass["Comment"]} />
							</IconWithBadge>
						</span>
					)}
				</React.Fragment>
			)
		return null
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

StageBadges = connect(mapStateToProps)(StageBadges)
export { StageBadges }
