import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { v4 as uuid } from "uuid"

import _ from "lodash"

import { Table, Form, CustomInput, Input, UncontrolledModal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class ManageScopes_Form extends React.Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		const clonedTeams = _.cloneDeep(this.props.business.teams)
		this.state = {
			businessId: this.props.business.id,
			teams: clonedTeams,
			scopeGrid: clearview.ScopeGrid(clonedTeams, this.props.business),
		}

		this.handleScopeChange = this.handleScopeChange.bind(this)
		this.handleTeamChange = this.handleTeamChange.bind(this)
		this.handleAddTeam = this.handleAddTeam.bind(this)

		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
		this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this)
	}

	handleScopeChange(event, businessId, teamUsername) {
		var clonedTeam = _.find(this.state.teams, it => it.username === teamUsername)
		if (event.target.checked && !clonedTeam.businessIds.includes(businessId)) {
			clonedTeam.businessIds = _.union(clonedTeam.businessIds, [businessId])
		}
		if (!event.target.checked && clonedTeam.businessIds.includes(businessId)) {
			clonedTeam.businessIds = _.difference(clonedTeam.businessIds, [businessId])
		}
		this.setState({
			scopeGrid: clearview.ScopeGrid(this.state.teams, this.props.business),
		})
	}

	handleActionButtonClick() {
		this.props.doAction(this.props.business, this.state.teams)

		return true
	}

	handleCancelButtonClick() {
		const clonedTeams = _.cloneDeep(this.props.business.teams)
		this.setState({
			businessId: this.props.business.id,
			teams: clonedTeams,
			scopeGrid: clearview.ScopeGrid(clonedTeams, this.props.business),
		})
		return true
	}

	handleTeamChange(e) {
		const value = e.target.value

		if (e.target.name === "team.name") _.find(this.state.teams, it => it.username === e.target.id).name = value

		this.setState({
			businessId: this.props.business.id,
			teams: this.state.teams,
			scopeGrid: clearview.ScopeGrid(this.state.teams, this.props.business),
		})
	}

	handleAddTeam(e) {
		this.state.teams.push({ username: uuid(), name: "", users: [] })
		this.setState({
			businessId: this.props.business.id,
			teams: this.state.teams,
			scopeGrid: clearview.ScopeGrid(this.state.teams, this.props.business),
		})
	}

	render() {
		const { business } = this.props

		if (business.id !== this.state.businessId) {
			const clonedTeams = _.cloneDeep(this.props.business.teams)
			this.setState({
				businessId: this.props.business.id,
				teams: clonedTeams,
				scopeGrid: clearview.ScopeGrid(clonedTeams, this.props.business),
			})
		}

		const { teams, scopeGrid } = this.state

		const depth = {
			Customer: <span></span>,
			Client: <span className="ml-0 mr-0 nowrap">&nbsp;&nbsp;</span>,
			"Managing Agent": <span className="ml-0 mr-0 nowrap">&nbsp;&nbsp;</span>,
			Landlord: <span className="ml-0 mr-0 nowrap">&nbsp;&nbsp;&nbsp;&nbsp;</span>,
			Property: <span className="ml-0 mr-0 nowrap">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>,
		}

		return (
			<UncontrolledModal style={{ maxWidth: 1200 }} className="overflowScroll" target="ManageScopes_Form">
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="div">
						<div className="d-flex">
							<h1 className="text-primary mr-2">
								<i className="fa fa-fw fa-pencil"></i>
							</h1>
							<h6>
								Manage Team Scopes for <b className="nowrap">{business.name}</b>
							</h6>
						</div>
					</ModalHeader>

					<ModalBody>
						<Table className="mb-0" hover>
							<thead>
								<tr>
									<th className="align-left bt-0">Agent/Landlord/Property</th>
									{teams
										.filter(it => ["InHouseTeam", "ClientTeam"].includes(it.type))
										.map(team => (
											<th key={`team${team.id}`}>
												<Input
													invalid={!team.name.length}
													type="text"
													name="team.name"
													id={team.username}
													placeholder="Team Name ..."
													value={team.name}
													onKeyDown={clearview.ValidNameCharacters}
													onChange={this.handleTeamChange}
												/>
											</th>
										))}
									{/* <th rowSpan={scopeGrid.length + 2} className="align-center bt-0">
                                    <Button hidden={business.type == "Property"} outline color="link" title="Add new team" onClick={() => this.handleAddTeam()}>{clearview.Icon.Business.AddTeam}</Button>
                                </th> */}
								</tr>
							</thead>
							<tbody>
								<tr key={-1}>
									<td colSpan={teams.filter(it => ["InHouseTeam", "ClientTeam"].includes(it.type)) + 1} style={{ verticalAlign: "middle" }}>
										{depth[this.props.business.role]}
										{clearview.Icon.Business[this.props.business.role]} {clearview.GetBusinessTitle(this.props.business)}
									</td>
								</tr>
								{scopeGrid.map(row => {
									return (
										<tr key={`row${row.id}`}>
											<td style={{ verticalAlign: "middle" }}>
												{depth[row.business.role]}
												{clearview.Icon.Business[row.business.role]} {clearview.GetBusinessTitle(row.business)}
											</td>
											{teams
												.filter(it => ["InHouseTeam", "ClientTeam"].includes(it.type))
												.map(t => (
													<td key={`row${row.id}:t${t.type}`}>
														{row.business.role === "Property" && (
															<CustomInput
																className="ml-0 mr-0 mt-2 mb-2"
																type="checkbox"
																id={`b:${row.businessId}|t:${t.username}`}
																name={`b:${row.businessId}|t:${t.username}`}
																inline
																defaultChecked={row.teamScopes[t.username]}
																onClick={e => this.handleScopeChange(e, row.businessId, t.username)}
															/>
														)}
													</td>
												))}
										</tr>
									)
								})}
							</tbody>
						</Table>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary" onClick={this.handleCancelButtonClick}>
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close color="default" className="text-primary" onClick={this.handleActionButtonClick}>
							Save
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAction: (business, teams) =>
		dispatch({
			type: ACTIONS.TEAMS_MANAGE,
			business: business,
			teams: teams,
		}),
})

export default connect(null, mapDispatchToProps)(ManageScopes_Form)
