//expose all action names
import _ from "lodash"

//Values are in the format "REDUCER:THING"

export const SUCCESS = "SUCCESS:Success!"
export const ERROR = "ERROR:An error has occurred!"
export const WARNING = "WARNING:Warning!"
export const INFO = "INFO:Information"
export const TIMEOUT = "TIMEOUT:Timeout"

export const REMOVE_TOAST = "TOAST:REMOVE_TOAST"
export const SESSION_TIMEOUT = "TIMEOUT:SESSION_TIMEOUT"

export const FILE_UPLOADED = {
	periodEnd: "CLIENT:PERIODEND_FILE_UPLOADED",
	stage: "CLIENT:STAGE_FILE_UPLOADED",
	query: "CLIENT:QUERY_FILE_UPLOADED",
	client: "CLIENT:CLIENT_FILE_UPLOADED",
	property: "CLIENT:PROPERTY_FILE_UPLOADED",
}
export const ATTACHMENT_REQUEST_DELETE = "CLIENT:ATTACHMENT_REQUEST_DELETE"

export const FOLDER_FETCH = "FILES:FOLDER_FETCH"
export const FOLDER_FETCH_CLEAR = "FILES:FOLDER_FETCH_CLEAR"
export const FOLDER_FETCHED = "FILES:FOLDER_FETCHED"
export const FOLDER_FETCH_FAILED = "FILES:FOLDER_FETCH_FAILED"

export const USER_AUTHENTICATED = "USER:USER_AUTHENTICATED"
export const USER_GOT_CURRENT_USER = "USER:USER_GOT_CURRENT_USER"
export const USER_GOT_CURRENT_USER_FAILED = "ERROR:USER_GOT_CURRENT_USER_FAILED"

export const USER_PASSWORD_RESET = "USER:USER_PASSWORD_RESET"
export const USER_PASSWORD_RESET_OK = "SUCCESS"
export const USER_PASSWORD_RESET_FAILED = "ERROR:USER_PASSWORD_RESET_FAILED"

export const USER_ASSIGN_USERS_PROPERTIES = "USER:USER_ASSIGN_USERS_PROPERTIES"
export const USER_ASSIGN_USERS_PROPERTIES_OK = "SUCCESS"
export const USER_ASSIGN_USERS_PROPERTIES_FAILED = "ERROR:USER_ASSIGN_USERS_PROPERTIES_FAILED"

export const REPORTS_ALL_FETCH = "REPORTS:REPORTS_ALL_FETCH"
export const REPORTS_ALL_FETCHED = "REPORTS:REPORTS_ALL_FETCHED"
export const REPORTS_ALL_FETCH_FAILED = "REPORTS:REPORTS_ALL_FETCH_FAILED"

export const REPORTS_ATTACHMENTS_FETCH = "REPORTS:REPORTS_ATTACHMENTS_FETCH"
export const REPORTS_ATTACHMENTS_FETCHED = "REPORTS:REPORTS_ATTACHMENTS_FETCHED"
export const REPORTS_ATTACHMENTS_FETCH_FAILED = "REPORTS:REPORTS_ATTACHMENTS_FETCH_FAILED"

export const REPORTS_REQUEST = "REPORTS:REPORTS_REQUEST"
export const REPORTS_REQUESTED = "REPORTS:REPORTS_REQUESTED"
export const REPORTS_REQUEST_FAILED = "REPORTS:REPORTS_REQUEST_FAILED"

export const FAVOURITE_ADD = "USER:FAVOURITE_ADD"
export const FAVOURITE_ADDED = "USER:FAVOURITE_ADDED"
export const FAVOURITE_ADD_FAILED = "ERROR:FAVOURITE_ADD_FAILED"

export const FAVOURITE_REMOVE = "USER:FAVOURITE_REMOVE"
export const FAVOURITE_REMOVED = "USER:FAVOURITE_REMOVED"
export const FAVOURITE_REMOVE_FAILED = "ERROR:FAVOURITE_REMOVE_FAILED"

export const FAVOURITE_RESET = "USER:FAVOURITE_RESET"
export const FAVOURITE_RESETED = "USER:FAVOURITE_RESETED"
export const FAVOURITE_RESET_FAILED = "ERROR:FAVOURITE_RESET_FAILED"

export const TEMPLATES_FETCH = "TEMPLATES:TEMPLATES_FETCH"
export const TEMPLATES_FETCHED = "TEMPLATES:TEMPLATES_FETCHED"
export const TEMPLATES_FETCH_FAILED = "ERROR:TEMPLATES_FETCH_FAILED"

export const TEMPLATES_UPDATE = "TEMPLATES:TEMPLATES_UPDATE"
export const TEMPLATES_UPDATED = "TEMPLATES:TEMPLATES_UPDATED"
export const TEMPLATES_UPDATE_FAILED = "ERROR:TEMPLATES_UPDATE_FAILED"

export const TEMPLATE_FETCH = "TEMPLATES:TEMPLATE_FETCH"
export const TEMPLATE_FETCHED = "TEMPLATES:TEMPLATE_FETCHED"
export const TEMPLATE_FETCH_FAILED = "ERROR:TEMPLATE_FETCH_FAILED"

export const TEMPLATE_CREATE = "TEMPLATES:TEMPLATE_CREATE"
export const TEMPLATE_CREATED = "TEMPLATES:TEMPLATE_CREATED"
export const TEMPLATE_CREATE_FAILED = "ERROR:TEMPLATE_CREATE_FAILED"
export const TEMPLATE_REDIRECTED = "TEMPLATES:TEMPLATE_REDIRECTED"

export const TEMPLATE_UPDATE = "TEMPLATES:TEMPLATE_UPDATE"
export const TEMPLATE_UPDATED = "TEMPLATES:TEMPLATE_UPDATED"
export const TEMPLATE_UPDATE_FAILED = "ERROR:TEMPLATE_UPDATE_FAILED"

export const TEMPLATE_RETIRE = "TEMPLATES:TEMPLATE_RETIRE"
export const TEMPLATE_RETIRED = "TEMPLATES:TEMPLATE_RETIRED"
export const TEMPLATE_RETIRE_FAILED = "ERROR:TEMPLATE_RETIRE_FAILED"

export const CLIENTS_FETCH = "CLIENT:CLIENTS_FETCH"
export const CLIENTS_FETCHED = "CLIENT:CLIENTS_FETCHED"
export const CLIENTS_FETCH_FAILED = "ERROR:CLIENTS_FETCH_FAILED"

export const CLIENTS_MOVE_CLIENTS = "CLIENT:CLIENTS_MOVE_CLIENTS"
export const CLIENTS_MOVED_CLIENTS = "CLIENT:CLIENTS_MOVED_CLIENTS"
export const CLIENTS_MOVE_CLIENTS_FAILED = "ERROR:CLIENTS_MOVE_CLIENTS_FAILED"

export const CLIENTS_REBUILD = "CLIENT:CLIENTS_REBUILD"
export const CLIENTS_REBUILD_FAILED = "ERROR:CLIENTS_REBUILD_FAILED"

export const PERIODENDS_ALL_FETCH = "CLIENT:PERIODENDS_ALL_FETCH"

export const PERIODENDS_RECALCULATE = "CLIENT:PERIODENDS_RECALCULATE"
export const PERIODENDS_RECALCULATE_FAILED = "ERROR:PERIODENDS_RECALCULATE_FAILED"
export const PERIODENDS_RENEW = "CLIENT:PERIODENDS_RENEW"
export const PERIODENDS_RENEW_FAILED = "ERROR:PERIODENDS_RENEW_FAILED"

export const PERIODENDS_RANGE_FETCH = "CLIENT:PERIODENDS_RANGE_FETCH"
export const PERIODENDS_RANGE_FETCHED = "CLIENT:PERIODENDS_RANGE_FETCHED"
export const PERIODENDS_RANGE_FETCH_RETRYING = "WARNING:PERIODENDS_RANGE_FETCH_RETRYING"
export const PERIODENDS_RANGE_FETCH_RETRY = "CLIENT:PERIODENDS_RANGE_FETCH_RETRY"
export const PERIODENDS_RANGE_FETCH_FAILED = "ERROR:PERIODENDS_RANGE_FETCH_FAILED"

export const QUERIES_RANGE_FETCH = "CLIENT:QUERIES_RANGE_FETCH"
export const QUERIES_RANGE_FETCHED = "CLIENT:QUERIES_RANGE_FETCHED"
export const QUERIES_RANGE_FETCH_RETRYING = "WARNING:QUERIES_RANGE_FETCH_RETRYING"
export const QUERIES_RANGE_FETCH_RETRY = "CLIENT:QUERIES_RANGE_FETCH_RETRY"
export const QUERIES_RANGE_FETCH_FAILED = "ERROR:QUERIES_RANGE_FETCH_FAILED"

export const PERIODEND_FETCH = "CLIENT:PERIODEND_FETCH"
export const PERIODEND_FETCHED = "CLIENT:PERIODEND_FETCHED"
export const PERIODEND_FETCH_FAILED = "CLIENT:PERIODEND_FETCH_FAILED"

export const PERIODEND_UPDATE = "CLIENT:PERIODEND_UPDATE"
export const PERIODEND_UPDATED = "CLIENT:PERIODEND_UPDATED"
export const PERIODEND_UPDATE_FAILED = "ERROR:PERIODENDS_UPDATE_FAILED"

export const PERIODEND_REFERENCE_UPDATE = "CLIENT:PERIODEND_REFERENCE_UPDATE"
export const PERIODEND_REFERENCE_UPDATED = "CLIENT:PERIODEND_REFERENCE_UPDATED"
export const PERIODEND_REFERENCE_UPDATE_FAILED = "ERROR:PERIODEND_REFERENCE_UPDATE_FAILED"

export const PERIODEND_UNREAD_UPDATE = "CLIENT:PERIODEND_UNREAD_UPDATE"
export const PERIODEND_ALL_READ_UPDATE = "CLIENT:PERIODEND_ALL_READ_UPDATE"

export const PERIODEND_FIELD_UPDATE = "CLIENT:PERIODEND_FIELD_UPDATE"
export const PERIODEND_FIELD_UPDATED = "CLIENT:PERIODEND_FIELD_UPDATED"
export const PERIODEND_FIELD_UPDATE_FAILED = "ERROR:PERIODEND_FIELD_UPDATE_FAILED"

export const PERIODEND_DONOTRENEW_UPDATE = "CLIENT:PERIODEND_DONOTRENEW_UPDATE"
export const PERIODEND_DONOTRENEW_UPDATED = "CLIENT:PERIODEND_DONOTRENEW_UPDATED"
export const PERIODEND_DONOTRENEW_UPDATE_FAILED = "ERROR:PERIODEND_DONOTRENEW_UPDATE_FAILED"

export const PERIODEND_ISPUBLISHED_UPDATE = "CLIENT:PERIODEND_ISPUBLISHED_UPDATE"
export const PERIODEND_ISPUBLISHED_UPDATED = "CLIENT:PERIODEND_ISPUBLISHED_UPDATED"
export const PERIODEND_ISPUBLISHED_UPDATE_FAILED = "ERROR:PERIODEND_ISPUBLISHED_UPDATE_FAILED"

export const PERIODEND_CREATE = "CLIENT:PERIODEND_CREATE"
export const PERIODEND_CREATED = "CLIENT:PERIODEND_CREATED"
export const PERIODEND_CREATE_FAILED = "ERROR:PERIODEND_CREATE_FAILED"
export const PERIODEND_REDIRECTED = "CLIENT:PERIODEND_REDIRECTED"

export const PERIODENDS_CREATE = "CLIENT:PERIODENDS_CREATE"
export const PERIODENDS_CREATED = "CLIENT:PERIODENDS_CREATED"
export const PERIODENDS_CREATE_FAILED = "ERROR:PERIODENDS_CREATE_FAILED"

export const PERIODEND_START = "CLIENT:PERIODEND_START"
export const PERIODEND_STARTED = "CLIENT:PERIODEND_STARTED"
export const PERIODEND_START_FAILED = "ERROR:PERIODEND_START_FAILED"

export const PERIODEND_DELETE = "CLIENT:PERIODEND_DELETE"
export const PERIODEND_DELETED = "CLIENT:PERIODEND_DELETED"
export const PERIODEND_DELETE_FAILED = "ERROR:PERIODEND_DELETE_FAILED"

export const PERIODEND_CONDITION_UPDATE = "CLIENT:PERIODEND_CONDITION_UPDATE"
export const PERIODEND_CONDITION_UPDATED = "CLIENT:PERIODEND_CONDITION_UPDATED"
export const PERIODEND_CONDITION_UPDATE_FAILED = "ERROR:PERIODEND_CONDITION_UPDATE_FAILED"

export const PERIODEND_UPDATE_COMMENT = "CLIENT:PERIODEND_UPDATE_COMMENT"
export const PERIODEND_UPDATE_COMMENT_FAILED = "ERROR:PERIODEND_UPDATE_COMMENT_FAILED"

export const PERIODEND_DELETE_COMMENT = "CLIENT:PERIODEND_DELETE_COMMENT"
export const PERIODEND_DELETE_COMMENT_FAILED = "ERROR:PERIODEND_DELETE_COMMENT_FAILED"

export const PERIODEND_RAISE_QUERY = "CLIENT:PERIODEND_RAISE_QUERY"
export const PERIODEND_RAISE_QUERY_FAILED = "ERROR:PERIODEND_RAISE_QUERY_FAILED"

export const PERIODEND_UPDATE_QUERY = "CLIENT:PERIODEND_UPDATE_QUERY"
export const PERIODEND_UPDATE_QUERY_FAILED = "ERROR:PERIODEND_UPDATE_QUERY_FAILED"

export const PERIODEND_DELETE_QUERY = "CLIENT:PERIODEND_DELETE_QUERY"
export const PERIODEND_DELETE_QUERY_FAILED = "ERROR:PERIODEND_DELETE_QUERY_FAILED"

export const PERIODEND_REPLY_TO_QUERY = "CLIENT:PERIODEND_REPLY_TO_QUERY"
export const PERIODEND_REPLY_TO_QUERY_FAILED = "ERROR:PERIODEND_REPLY_TO_QUERY_FAILED"

export const PERIODEND_REOPEN_QUERY = "CLIENT:PERIODEND_REOPEN_QUERY"
export const PERIODEND_REOPEN_QUERY_FAILED = "ERROR:PERIODEND_REOPEN_QUERY_FAILED"

export const PERIODEND_CLOSE_QUERIES = "CLIENT:PERIODEND_CLOSE_QUERIES"
export const PERIODEND_CLOSE_QUERY = "CLIENT:PERIODEND_CLOSE_QUERY"
export const PERIODEND_CLOSE_QUERY_FAILED = "ERROR:PERIODEND_CLOSE_QUERY_FAILED"

export const PERIODEND_UPDATE_REPLY = "CLIENT:PERIODEND_UPDATE_REPLY"
export const PERIODEND_UPDATE_REPLY_FAILED = "CLIENT:PERIODEND_UPDATE_REPLY_FAILED"
export const PERIODEND_DELETE_REPLY = "CLIENT:PERIODEND_DELETE_REPLY"
export const PERIODEND_DELETE_REPLY_FAILED = "CLIENT:PERIODEND_DELETE_REPLY_FAILED"

export const TEAMS_MANAGE = "CLIENT: TEAMS_MANAGE"
export const TEAMS_MANAGED = "CLIENT: TEAMS_MANAGED"
export const TEAMS_MANAGE_FAILED = "ERROR: TEAMS_MANAGE_FAILED"

export const CLIENT_ADD = "CLIENT:CLIENT_ADD"
export const CLIENT_ADDED = "CLIENT:CLIENT_ADDED"
export const CLIENT_ADD_FAILED = "ERROR:CLIENT_ADD_FAILED"

export const CLIENTS_ADD = "CLIENT:CLIENTS_ADD"
export const CLIENTS_ADDED = "CLIENT:CLIENTS_ADDED"
export const CLIENTS_ADD_FAILED = "ERROR:CLIENTS_ADD_FAILED"

export const CLIENT_UPDATE = "CLIENT:CLIENT_UPDATE"
export const CLIENT_UPDATED = "CLIENT:CLIENT_UPDATED"
export const CLIENT_UPDATE_FAILED = "ERROR:CLIENT_UPDATE_FAILED"

export const CLIENT_REMOVE = "CLIENT:CLIENT_REMOVE"
export const CLIENT_REMOVED = "CLIENT:CLIENT_REMOVED"
export const CLIENT_REMOVE_FAILED = "ERROR:CLIENT_REMOVE_FAILED"

export const PROPERTY_ADD = "CLIENT:PROPERTY_ADD"
export const PROPERTY_ADDED = "CLIENT:PROPERTY_ADDED"
export const PROPERTY_ADD_FAILED = "ERROR:PROPERTY_ADD_FAILED"

export const PROPERTIES_ADD = "CLIENT:PROPERTIES_ADD"
export const PROPERTIES_ADDED = "CLIENT:PROPERTIES_ADDED"
export const PROPERTIES_ADD_FAILED = "ERROR:PROPERTIES_ADD_FAILED"

export const PROPERTY_UPDATE = "CLIENT:PROPERTY_UPDATE"
export const PROPERTY_UPDATED = "CLIENT:PROPERTY_UPDATED"
export const PROPERTY_UPDATE_FAILED = "ERROR:PROPERTY_UPDATE_FAILED"

export const PROPERTY_REMOVE = "CLIENT:PROPERTY_REMOVE"
export const PROPERTY_REMOVED = "CLIENT:PROPERTY_REMOVED"
export const PROPERTY_REMOVE_FAILED = "ERROR:PROPERTY_REMOVE_FAILED"

export const WORKFLOW_CREATE_USERS = "WORKFLOW:CREATE_USERS"
export const WORKFLOW_SUBMITTED = "WORKFLOW:SUBMITTED"
export const WORKFLOW_SUBMIT_FAILED = "ERROR:An error has occurred submitting the Create Users workflow!"

export function arrayToDictionary(array, keyGenerator, dict = {}) {
	if (!Array.isArray(array)) throw Error("not an array")

	return array.reduce((acc, it) => {
		acc[keyGenerator(it)] = it
		return acc
	}, dict)
}

export function dictionaryToArray(dict) {
	return dict ? Object.keys(dict).map(k => dict[k]) : []
}

export function setSubState(currentState, path, object) {
	let newState = { ...currentState, modalOpen: true }

	const nodes = path.split("\\")
	nodes.reduce((acc, nodeName, index) => {
		if (index === nodes.length - 1) {
			acc[nodeName] = object
		} else if (acc[nodeName] === null || acc[nodeName] === undefined) {
			acc[nodeName] = {}
		}
		return acc[nodeName]
	}, newState)

	return newState
}

export function setSubStateFetching(currentState, path, object, isBusy = true) {
	if (!object) object = getSubState(currentState, path) || {}
	return setSubState(currentState, path, {
		...object,
		isBusy: isBusy,
	})
}

export function setSubStateFetched(currentState, path, object) {
	let newState = setSubState(currentState, path, {
		...object,
		isBusy: false,
		lastUpdated: Date.now(),
	})
	return newState
}

export function getSubState(state, path) {
	let pathSoFar = null
	return path.split("\\").reduce((acc, nodeName) => {
		pathSoFar = pathSoFar ? `${pathSoFar}\\${nodeName}` : nodeName
		return acc[nodeName]
	}, state)
}

//Finds first match in a tree
export function findInTree(state, path, predicate, childrenSelector = it => it.children) {
	const tree = getSubState(state, path)
	if (!tree || !tree.data || !tree.data.length) return null

	return findInBranches(tree.data, predicate, childrenSelector)
}

export function findInBranches(branches, predicate, childrenSelector) {
	let result = null
	for (let i = 0; result === null && i < branches.length; i++) {
		result = findInBranch(branches[i], predicate, childrenSelector)
	}
	return result
}

export function findInBranch(branch, predicate, childrenSelector) {
	if (predicate(branch)) {
		return branch
	}

	const children = childrenSelector(branch)

	if (children) {
		return findInBranches(children, predicate, childrenSelector)
	}
	return null
}

export function crawlBranches(branches, action, childrenSelector = it => it.children, parent = null) {
	for (let i = 0; i < branches.length; i++) {
		crawlBranch(parent, branches[i], action, childrenSelector)
	}
}

export function crawlBranch(parent, branch, action, childrenSelector = it => it.children) {
	action(branch, parent)

	const children = childrenSelector(branch)
	if (children) {
		crawlBranches(children, action, childrenSelector, branch)
	}
}

export function climbBranch(branch, action, parentSelector = it => it.parent) {
	action(branch)

	const parent = parentSelector(branch)
	if (parent) {
		climbBranch(parent, action, parentSelector)
	}
}

export function findCustomer(business) {
	if (business.type === "Customer") return business

	const parent = business.parent
	if (parent) {
		return findCustomer(parent)
	}
}

export function filter(dict, predicate) {
	return dict
		? Object.keys(dict)
				.filter(k => predicate(dict[k]))
				.map(k => dict[k])
		: []
}

export function actorsForBusiness(biz) {
	const actors = {
		inHouse: [],
		client: [],
		inHouseContact: null,
		clientContact: null,
	}

	climbBranch(biz, business => {
		if (business.type === "Root") return

		business.teams?.forEach(team => {
			switch (team.type) {
				case "InHouseAppointees":
					actors.inHouse.push(
						...team.users
							.filter(user => user.role !== "Reader" || user.isContact)
							.map(user => ({ ...user, description: `${user.name} (In House ${team.name}) for ${business.name}` }))
					)
					break
				case "ClientAppointees":
					actors.client.push(
						...team.users
							.filter(user => user.role !== "Reader" || user.isContact)
							.map(user => ({ ...user, description: `${user.name} (Client ${team.name}) for ${business.name}` }))
					)
					break

				case "Users":
					// You have to be in a team to be visible
					break

				case "InHouseTeam":
					if (team.businessIds.includes(biz.id) && team.users.length) {
						actors.inHouse.push({ ...team, description: `${team.name} @ ${business.name}` })
						actors.inHouse.push(
							...team.users
								.filter(user => user.role !== "Reader")
								.map(user => ({ ...user, description: `${user.name} (In House ${team.name}) @ ${business.name}` }))
						)
					}
					break
				case "ClientTeam":
					if (team.businessIds.includes(biz.id) && team.users.length) {
						actors.client.push({ ...team, description: `${team.name} @ ${business.name}` })
						actors.client.push(
							...team.users
								.filter(user => user.role !== "Reader")
								.map(user => ({ ...user, description: `${user.name} (Client ${team.name}) @ ${business.name}` }))
						)
					}
					break

				default:
					break
			}
		})
	})

	actors.inHouseContact = _.find(actors.inHouse, it => it.isContact)
	actors.clientContact = _.find(actors.client, it => it.isContact)
	actors.inHouse = _.sortBy(
		_.uniqBy(actors.inHouse, it => it.username),
		[it => it.description]
	)
	actors.client = _.sortBy(
		_.uniqBy(actors.client, it => it.username),
		[it => it.description]
	)

	return actors
}
