import React from "react"
import PropTypes from "prop-types"

import * as clearview from "../../components/@Clearview"

const HeaderMain = props => (
	<React.Fragment>
		<div className={`${props.className}`}>
			<div className="d-flex">
				{props.superTitle && <h3 className="mr-3 mb-0 align-self-start flex-1 text-secondary">{props.superTitle}</h3>}
				{props.superActions && <h3>{props.superActions}</h3>}
			</div>
			<div className="d-flex">
				<h1 className="mr-3 mt-0 mb-0 align-self-start flex-1 text-dark">
					{props.title}
					{props.onRefresh && (
						<sup className="ml-2 h2 text-primary hover" title="Click to refresh" onClick={props.onRefresh}>
							{clearview.Icon.refresh}
						</sup>
					)}
				</h1>
				{props.actions && (
					<h1>
						<sup className="ml-2 h2 text-primary hover">{props.actions}</sup>
					</h1>
				)}
			</div>
			{props.subTitle && <h3 className="ml-1 mr-3 mt-2 mb-0 align-self-start text-secondary">{props.subTitle}</h3>}
		</div>
	</React.Fragment>
)
HeaderMain.propTypes = {
	superTitle: PropTypes.node,
	superActions: PropTypes.node,
	title: PropTypes.node,
	subTitle: PropTypes.node,
	className: PropTypes.string,
	actions: PropTypes.node,
	onRefresh: PropTypes.func,
}
HeaderMain.defaultProps = {
	superTitle: "",
	title: "",
	subTitle: "",
	className: "my-4",
}

export { HeaderMain }
