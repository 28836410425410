import React from "react"
import PropTypes from "prop-types"

import { StopPropagation, Icon } from "../../../../components/@Clearview"
import { CustomInput } from "../../../../components"

export default class VouchingItemsSearch extends React.Component {
	static propTypes = {
		propertySearchRegex: PropTypes.object,
		searchValue: PropTypes.string,
		onSearchChanged: PropTypes.func,
	}

	onClearSearch(e) {
		StopPropagation(e)
		this.props.onSearchChanged({ target: { value: "" } })
	}

	render() {
		return (
			<div className="d-flex-row justify-content-between search-select">
				<div className={this.props.propertySearchRegex.test(this.props.searchValue) ? "propertySearch" : "textSearch"}>
					<input
						id="search"
						key="search"
						type="text"
						name="search"
						value={this.props.searchValue}
						onChange={this.props.onSearchChanged}
						placeholder="Search..."
					/>
					<a href="#" id="clearSearch" onClick={e => this.onClearSearch(e)} title="Clear search">
						{Icon.clear}
					</a>
				</div>
			</div>
		)
	}
}
