import React from "react"
import { connect } from "react-redux"

import * as clearview from "../../../../components/@Clearview"
import ReportParamsModal from "./ReportParamsModal"
import PropTypes from "prop-types"

import { ListGroupItem, ListGroup, ExtendedDropdown } from "../../../../components"
import Dropdown from "react-bootstrap/Dropdown"
import * as ACTIONS from "../../../../store/actions"

class ReportsRunner extends React.Component {
	static propTypes = {
		business: PropTypes.object,
		reports: PropTypes.array.isRequired,
		doSubmitReport: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.runReport = this.runReport.bind(this)
	}

	runReport(business, report, params) {
		const reportParam = {}

		Object.keys(params).forEach(key => {
			const prop = params[key]
			if (prop.id) reportParam[key] = prop.id
			else if (prop.value) reportParam[key] = prop.value
			else if (prop !== undefined) reportParam[key] = prop
		})
		this.props.doSubmitReport(business, report, reportParam)
	}

	render() {
		const business = this.props.business
		const reports = this.props.reports

		return (
			<Dropdown direction="down">
				<Dropdown.Toggle caret>Run new report</Dropdown.Toggle>
				<ExtendedDropdown persist>
					<ExtendedDropdown.Section list style={{ minHeight: 370 }}>
						<ListGroup flush outline className="hover">
							{reports.map(report => (
								<ListGroupItem key={report.key} id={`report_${report.key}`} title={`Click to run ${report.name} report.`} action>
									<h6>
										<span className="text-primary">{clearview.Icon.reports}</span> <b>{report.name}</b>
									</h6>
									<ReportParamsModal
										target={`report_${report.key}`}
										title={report.name}
										subTitle={report.description}
										action={params => this.runReport(business, report, params)}
										disabledParams={report.disabledParams}
										defaultValues={report.defaultValues}
									/>
								</ListGroupItem>
							))}
						</ListGroup>
					</ExtendedDropdown.Section>
				</ExtendedDropdown>
			</Dropdown>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doSubmitReport: (business, report, params) => {
		dispatch({ type: ACTIONS.REPORTS_REQUEST, payload: { business: business, report: report, params: params } })
	},
})

export default connect(null, mapDispatchToProps)(ReportsRunner)
