import * as ACTIONS from "../actions"

const userReducer = (state, action) => {
	if (!state) state = {}

	// if (action.type.startsWith("USER:")) console.info(`REDUCER: ${action.type}`)

	switch (action.type) {
		case ACTIONS.USER_GOT_CURRENT_USER:
			return {
				...state,
				isBusy: false,
				user: materialiseUser(action.user),
				lastUpdated: action.receivedAt,
			}

		case ACTIONS.FAVOURITE_ADDED:
		case ACTIONS.FAVOURITE_REMOVED:
		case ACTIONS.FAVOURITE_RESETED:
			return {
				...state,
				isBusy: false,
				user: materialiseUser(action.user),
				lastUpdated: action.receivedAt,
			}

		default:
			return state
	}
}

function materialiseUser(user) {
	const PREFERENCES = {
		Clearview: {
			periodEndProgressBar: {
				ByPhase: false,
			},
			overview: {
				LivePeriodEndsByRagWithUsDonut: true,
				LivePeriodEndsByRagWithThemDonut: true,
				LivePeriodEndsStagesOverview: true,
				LivePeriodEndsPhasesOverview: user =>
					user.isInHouse
						? {
								PhasesOverviewTable: true,
								PhasesMonthTable: true,
								PhasesClientTable: true,
								PhasesAppointeesTable: ["ServiceChargeManager"],
								PhasesManagerTable: false,
						  }
						: {
								PhasesOverviewTable: true,
								PhasesMonthTable: true,
						  },
			},
		},

		$AML: {
			periodEndProgressBar: {
				ByPhase: false,
			},
			overview: {
				LivePeriodEndsByRagWithUsDonut: true,
				LivePeriodEndsByRagWithThemDonut: true,
				LivePeriodEndsStagesOverview: true,
				LivePeriodEndsPhasesOverview: user =>
					user.isInHouse
						? {
								PhasesOverviewTable: true,
								PhasesMonthTable: true,
								PhasesClientTable: true,
								PhasesAppointeesTable: ["ServiceChargeManager"],
								PhasesManagerTable: false,
						  }
						: {
								PhasesOverviewTable: true,
								PhasesMonthTable: true,
						  },
			},
		},
	}

	return {
		...user,
		preferences: PREFERENCES[user.brand.reference] || PREFERENCES.Clearview,
	}
}

export default userReducer
