import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import _ from "lodash"

import { UncontrolledModal } from "../../../../components"

import * as ACTIONS from "../../../../store/actions"
import WorkflowExcelForm from "../components/WorkflowExcelForm"

import sampleFile from "./CreateUsers.xlsx"

class CreateUsers_Form extends React.Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.onUploaded = this.onUploaded.bind(this)
	}

	onUploaded(file, data) {
		const users = (data || [])
			.filter(it => !_.endsWith(it.Email, "@example.com"))
			.map(it => ({
				role: _.capitalize(it.Role),
				name: it.Name.trim().replaceAll("  ", " ").replaceAll("  ", " "),
				email: it.Email.trim(),
				sendToUser: _.upperCase(it["Send Email"]) === "TO USER",
			}))
		this.props.doAction(this.props.business, users)
	}

	render() {
		const { business } = this.props

		return (
			<UncontrolledModal style={{ maxWidth: 600 }} className="overflowScroll" target="CreateUsers_Form">
				<WorkflowExcelForm
					title={
						<span>
							Upload <b>New Users</b> for <b className="nowrap">{business.name}</b>
						</span>
					}
					onUploaded={this.onUploaded}
					handleCancelButtonClick={this.handleCancelButtonClick}
					sampleFile={sampleFile}
				></WorkflowExcelForm>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAction: (business, users) =>
		dispatch({
			type: ACTIONS.WORKFLOW_CREATE_USERS,
			business,
			users,
		}),
})

export default connect(null, mapDispatchToProps)(CreateUsers_Form)
