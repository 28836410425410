import React from "react"
import PropTypes from "prop-types"

import { StrictModeDroppable } from "../../../components/StrictModeDroppable"

import * as clearview from "../../../../components/@Clearview"

import { Media, Badge, Popover, PopoverHeader, PopoverBody } from "../../../../components"

import { SmartMenu } from "../../../components/SmartMenu"

import {
	STATUS_CLASS_NAME,
	SELECTED_CLASS_NAME,
	DATE_COLOR,
	formatVendorName,
	formatRef,
	formatValue,
	accountColor,
	getDropItemClass,
	fileTypeIcon,
} from "./VouchingTheme"

import { VouchingDetailsContext } from "./VouchingDetailsContext"
import { LedgerItemAvatar } from "./LedgerItemAvatar"

function isDropDisabled(context, item) {
	if (["A", "M", "U", "X"].includes(item.status)) return true
	if (!context.expenseBeingDragged) return true

	if (item.isSelected) {
		return (
			!!context.selectedAggregate.items.find(it => ![null, undefined, "?"].includes(it.status)) ||
			context.selectedAggregate.total !== context.expenseBeingDragged.total
		)
	}

	return item.total !== context.expenseBeingDragged.total
}

export const LedgerList = props => (
	<VouchingDetailsContext.Consumer>
		{context => {
			return (
				<div id="ledgerList" className="list-group" style={{ height: 500, overflowY: "scroll" }}>
					{props.items.filter(context.itemFilter).map((item, index) => (
						<StrictModeDroppable key={index} droppableId={item.id} isDropDisabled={isDropDisabled(context, item)}>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									className={`list-group-item ${isDropDisabled(context, item) ? "drop-is-disabled" : ""} ${getDropItemClass(snapshot)} ${
										STATUS_CLASS_NAME[item.status]
									} ${SELECTED_CLASS_NAME[item.isSelected]}`}
								>
									<Media>
										<input
											type="checkbox"
											id={item.id}
											name={item.id}
											style={{ marginLeft: -16, marginRight: 2, marginTop: -8 }}
											checked={item.isSelected}
											onChange={e => props.onItemSelected(item)}
										></input>
										<LedgerItemAvatar ledgerItem={item} />
										<SmartMenu actions={props.itemActionsFn(item)} />
										<Media body className="hover" onClick={e => props.onEditLedgerItem(e, item)}>
											<div className="d-flex-column" title={`${item.file}, row: ${item.row}`}>
												<div className="flex-1 d-flex-row justify-content-between">
													{formatVendorName(item.vendorName)}
													<Badge className="mt-1 h6 mb-1" color={DATE_COLOR[item.date]}>
														{item.date || "DATE?"}
													</Badge>
												</div>
												<div className="mt-0 mb-0 flex-1 d-flex-row justify-content-between">
													<p className="h6 flex-1 text-muted">{item.narrative}</p>
													<p className="h6">{formatRef(item.ref)}</p>
												</div>
												<div className="mt-0 mb-0 flex-1 d-flex-row justify-content-between">
													<Badge className="h6" color={accountColor(item.account?.name)}>
														{item.account?.name || "unknown"}
													</Badge>
													<p className="h6">
														{item.expense && (
															<a
																href="#"
																onClick={e => {
																	clearview.StopPropagation(e)
																	props.onShowExpense(item)
																}}
																title="Show Invoice"
															>
																{fileTypeIcon(item.expense)}
															</a>
														)}{" "}
														{formatValue(item.total)}
													</p>
												</div>
											</div>
										</Media>
									</Media>
									<div style={{ display: "none" }}>{provided.placeholder}</div>
								</div>
							)}
						</StrictModeDroppable>
					))}
					<Popover placement="left" isOpen={!!context.selectedAggregate?.items.length} target="ledgerList" className="selected-aggregate">
						<PopoverHeader>
							<span>Multi Select [{context.selectedAggregate?.items.length}]</span>
							<input
								type="checkbox"
								id="deselectAllLedgerItems"
								defaultChecked={true}
								title="Click to deselect all items"
								onChange={e => props.onDeselectAll()}
							/>
						</PopoverHeader>
						<PopoverBody>
							<div>{context.selectedAggregate?.vendorName}</div>
							<div>{context.selectedAggregate?.narrative}</div>
							<div style={{ textAlign: "right" }}>{clearview.formatCurrency(context.selectedAggregate?.total)}</div>
						</PopoverBody>
					</Popover>
				</div>
			)
		}}
	</VouchingDetailsContext.Consumer>
)

LedgerList.propTypes = {
	items: PropTypes.array,
	listId: PropTypes.string,
	title: PropTypes.string,
	itemActionsFn: PropTypes.func.isRequired,
	onItemSelected: PropTypes.func.isRequired,
	onDeselectAll: PropTypes.func.isRequired,
	onShowExpense: PropTypes.func.isRequired,
	onEditLedgerItem: PropTypes.func.isRequired,
}
