import _ from "lodash"

export class Search {
	static SafeRegex(rawRegex, flags = "gi") {
		const regexSpec = _.escapeRegExp(rawRegex)
		return new RegExp(regexSpec, flags)
	}

	static Entity(it, regex) {
		return it && ((it.createdBy && it.createdBy.name.match(regex)) || (it.updatedBy && it.updatedBy.name.match(regex)))
	}

	static Address(it, regex) {
		return it && (it.fullAddressExcludingPostcode?.match(regex) || it.postcode?.match(regex))
	}

	static Business(it, regex) {
		return (
			it &&
			(this.Entity(it, regex) ||
				it.reference.match(regex) ||
				it.name.match(regex) ||
				this?.Address(it.address, regex) ||
				_.find(it.actors.inHouse, a => a.name.match(regex)) ||
				_.find(it.actors.client, a => a.name.match(regex)) ||
				this.Business(it.parent, regex))
		)
	}

	static Comment(it, regex) {
		return (
			it &&
			(this.Entity(it, regex) ||
				(it.comments && it.comments.match(regex)) ||
				(it.assignedTo && it.assignedTo.name.match(regex)) ||
				(it.reference && it.reference.match(regex)) ||
				(it.replies && _.find(it.replies, r => this.Comment(r, regex))))
		)
	}

	static Comments(comments, regex) {
		return comments && _.find(comments, it => this.Comment(it, regex))
	}

	static Stage(it, regex) {
		return (
			it &&
			(this.Entity(it, regex) ||
				(it.templateStage && it.templateStage.name.match(regex)) ||
				(it.templateStage && it.templateStage.conditions && _.find(it.templateStage.conditions, c => c.name.match(regex))))
		)
	}

	static PeriodEnd(it, regex) {
		return (
			it &&
			(this.Entity(it, regex) ||
				it.reference.match(regex) ||
				(it.template && it.template.reference.match(regex)) ||
				(it.template && it.template.name.match(regex)))
		)
	}
}
