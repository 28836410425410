import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone-uploader"

import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import * as Api from "../../../../api/CustomerApi"

import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"

import { Button, Alert, Row, Col, Form, FormGroup, InputGroupAddon, CustomInput } from "../../../../components"

import Preferences from "./Preferences"
import { STATUS_ICON } from "../../PeriodEnds/VouchingDetails/VouchingTheme"

const SPECIAL_VENDOR = {
	NAMES: { null: "*blank*" },
	VALUES: { "*blank*": null },
}

class TextractAnalysisRules extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		business: PropTypes.object,
	}

	constructor(props) {
		super(props)

		this.state = {
			business: {},
			isBusy: true,
			isChanged: false,
			vendors: [],
		}

		this.loadPreferences = this.loadPreferences.bind(this)

		this.onSave = this.onSave.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.clearAll = this.clearAll.bind(this)

		this.import = this.import.bind(this)

		this.onPasteNewRule = this.onPasteNewRule.bind(this)
		this.onPasteNewField = this.onPasteNewField.bind(this)
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.business && !this.props.business) {
			this.loadPreferences(nextProps.business)
		}
	}

	mapPersistenceToState = resp => {
		const groupByVendorStuff = _.groupBy(resp, it =>
			JSON.stringify({ vendorName: SPECIAL_VENDOR.NAMES[it.is.vendorName] || it.is.vendorName, status: it.is.status || "" })
		)
		return _.keys(groupByVendorStuff)
			.sort(clearview.caseInsensitiveSort)
			.map(key => ({ ...JSON.parse(key), rules: groupByVendorStuff[key] }))
	}

	loadPreferences(business) {
		Api.loadPreferences(business, "textract-analysis-rules", []).then(resp => {
			this.setState({
				business: business,
				isBusy: false,
				isChanged: false,
				selectedVendor: null,
				selectedIndex: null,
				vendors: this.mapPersistenceToState(resp),
			})
		})
	}

	onSave() {
		this.setState({ isBusy: true })
		const rules = this.state.vendors.flatMap(vendor => vendor.rules.map(rule => ({ vendor, rule })))

		const payload = rules.map(complex => ({
			fields: complex.rule.fields.map(field => ({ [field.Label ? "Label" : "Type"]: field.Label || field.Type, Value: field.Value })),
			is: {
				vendorName:
					SPECIAL_VENDOR.VALUES[complex.vendor.vendorName] !== undefined
						? SPECIAL_VENDOR.VALUES[complex.vendor.vendorName]
						: complex.vendor.vendorName,
				status: complex.vendor.status === "" ? undefined : complex.vendor.status,
			},
		}))

		Api.savePreferences(this.state.business, "textract-analysis-rules", payload).then(() => this.loadPreferences(this.state.business))
	}

	onCancel() {
		this.loadPreferences(this.state.business)
	}

	clearAll() {
		this.setState(prevState => ({ isBusy: false, isChanged: !!prevState.vendors.length, selectedVendor: null, selectedIndex: null, vendors: [] }))
	}

	import(data) {
		const importingVendors = this.mapPersistenceToState(data)
		const beforeLength = this.state.vendors.length
		const newVendors = _.unionWith(this.state.vendors, importingVendors, (one, two) => one.vendorName === two.vendorName).sort(
			clearview.orderBy(it => it.vendorName.toLowerCase())
		)
		const changeCount = newVendors.length - beforeLength

		this.setState({ vendors: newVendors, isChanged: true })
		clearview.ShowToast(changeCount ? "success" : "warning", <p>{changeCount} rules imported.</p>, null, null)
	}

	handleChangeStatus = ({ meta, file, remove }, status) => {
		if (status === "preparing") {
			this.setState({ file: file })
		}

		if (status === "done") {
			const reader = new FileReader()

			reader.onload = evt => {
				try {
					const bstr = evt.target.result
					this.import(JSON.parse(bstr))
				} catch (ex) {
					clearview.ShowToast("error", <p>{ex.message}</p>, null, null)
				}
			}

			reader.readAsBinaryString(file)
			remove()
		}
	}

	render() {
		const { business, isBusy, isChanged, vendors } = this.state

		return (
			<Preferences
				key="preferences"
				business={business}
				title="Invoice Import Rules"
				narrative="These rules help the Vouching Invoice Import Process correctly identify the Vendor Name."
				isBusy={isBusy}
				isChanged={isChanged}
				commands={[
					<Dropzone
						key="dropzone"
						onChangeStatus={this.handleChangeStatus}
						classNames={{
							dropzone: "dzu-dropzone dropzone-tight d-flex",
							dropzoneActive: "dzu-dropzoneActive dropzone-active",
							inputLabel: "btn btn-outline-primary fit-content-h",
							label: "m-0",
						}}
						accept=".json"
						multiple={false}
						maxFiles={1}
						submitButtonDisabled={true}
						inputContent={<span title="Import new rules from .json file">{clearview.Icon.upload}</span>}
						inputWithFilesContent={null}
						submitButtonContent={null}
					/>,
					<Button
						key="addNew"
						disabled={!vendors || !vendors.length}
						className="ml-1 fit-content-h"
						outline={true}
						color="primary"
						title="Add new Vendor Rule"
						onClick={e => this.onAddNewVendor(e)}
					>
						{clearview.Icon.create}
					</Button>,
					<EditSaveOrCancel key="editSaveOrCancel" isEditing={true} isChanged={isChanged} onSave={this.onSave} onCancel={this.onCancel} />,
				]}
			>
				<Form key="textractAnalysisForm">
					<Row>
						<Col key="vendor-col" md={4} style={{ height: 600, overflowY: "scroll" }}>
							<ul className="list-group">
								{vendors?.map((vendor, idx) => (
									<li key={`$iv:${idx}`} className="list-group-item hover-highlight pt-0 pb-0" onClick={e => this.selectVendor(e, idx)}>
										{vendor.vendorName} ({vendor.rules.length}) {STATUS_ICON[vendor.status]}
									</li>
								))}
							</ul>
						</Col>
						{this.state.selectedVendor && (
							<Col key="selected-vendor-col" md={8} id="textract-analysis-rules" style={{ height: 600, overflow: "hidden", overflowY: "scroll" }}>
								{this.vendorEditor(this.state.selectedVendor)}
								{this.statusEditor(this.state.selectedVendor)}
								{this.vendorRulesEditor(this.state.selectedVendor)}
								<Alert color="info" className="mt-2">
									<h6 className="mb-1 alert-heading">Fields and Rules</h6>
									You cannot "edit" fields and rules directly. However, you can add them by copying a summary field from the Edit Expense -
									Tokens tab in Vouching and pasting it in here.
								</Alert>
							</Col>
						)}
					</Row>
				</Form>
			</Preferences>
		)
	}

	selectVendor(e, index) {
		this.setState({ selectedVendor: this.state.vendors[index], selectedIndex: index })
	}

	vendorEditor(vendor) {
		return (
			<FormGroup row className="input-group">
				<InputGroupAddon addonType="prepend">Vendor</InputGroupAddon>
				<CustomInput
					type="text"
					key="vendorName"
					id="vendorName"
					name="vendorName"
					className="form-control"
					invalid={!vendor.vendorName}
					value={vendor.vendorName}
					onChange={e => this.handleVendorChange(e)}
				></CustomInput>
			</FormGroup>
		)
	}

	statusEditor(vendor) {
		return (
			<FormGroup row className="input-group">
				<InputGroupAddon addonType="prepend" title="Set invoices for this vendor to be set to Excluded when imported.">
					Exclude?
				</InputGroupAddon>
				<CustomInput
					title="Set invoices for this vendor to be set to Excluded when imported."
					type="checkbox"
					key="status"
					id="status"
					name="status"
					className="form-control"
					checked={vendor.status === "X"}
					onChange={e => this.handleStatusChange(e)}
				></CustomInput>
			</FormGroup>
		)
	}

	handleVendorChange(e) {
		this.state.selectedVendor[e.target.name] = e.target.value
		this.setState({ ...this.state, isChanged: true })
	}

	handleStatusChange(e) {
		this.state.selectedVendor[e.target.name] = e.target.checked ? "X" : ""
		this.setState({ ...this.state, isChanged: true })
	}

	vendorRulesEditor(vendor) {
		return (
			<table className="table mb-2" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th>Rule</th>
						<th>Type/Label</th>
						<th>Value</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				{vendor.rules.map((rule, rIdx) => this.vendorRuleEditor(vendor, rule, rIdx))}
				<tfoot>
					<tr>
						<td colSpan={4}>
							<input
								color="primary"
								className="paste-cell"
								readOnly
								onKeyDown={clearview.OnlyPaste}
								onPaste={e => this.onPasteNewRule(e, vendor)}
								placeholder="Paste here - as new Rule"
							/>
						</td>
					</tr>
				</tfoot>
			</table>
		)
	}

	vendorRuleEditor(vendor, rule, rIdx) {
		return (
			<tbody key={`vendorRuleEditor-${rIdx}`}>
				{rule.fields.map((field, fIdx) => (
					<tr key={`r:${rIdx}-f:${fIdx}`}>
						{fIdx === 0 && <td rowSpan={rule.fields.length}>{rIdx + 1} </td>}
						<td>
							{fIdx === 0 ? "" : <b>and </b>} {this.getTypeOrLabel(field)}
						</td>
						<td>{field.Value}</td>
						<td>
							<span title="Remove Field from rule" className="hover text-danger" onClick={e => this.onRemoveField(e, vendor, rule, rIdx, fIdx)}>
								{clearview.Icon.clear}
							</span>
						</td>
					</tr>
				))}
				<tr>
					<td></td>
					<td colSpan={2}>
						<input
							style={{ width: "100%" }}
							color="primary"
							className="paste-cell"
							readOnly
							onKeyDown={clearview.OnlyPaste}
							onPaste={e => this.onPasteNewField(e, rule)}
							placeholder="Paste here - as new Field"
						/>
					</td>
				</tr>
			</tbody>
		)
	}

	getTypeOrLabel(field) {
		if (clearview.TEXTRACT_KNOWN_FIELD_TYPES.includes(field.Type)) return field.Type
		return field.Label
	}

	parseFieldsFromClipboard(e) {
		const newFields = []

		const text = e.clipboardData.getData("text/plain")
		try {
			const newField = JSON.parse(text)
			if (newField.value && (clearview.TEXTRACT_KNOWN_FIELD_TYPES.includes(newField.type) || newField.label)) {
				newFields.push({ Type: newField.type, Label: newField.label, Value: newField.value })
				console.warn("Inserted newField from JSON") //Debug
			} else console.warn("Cannot parse newField from JSON")
		} catch (ex) {
			try {
				const lines = text?.split("\r") || []
				for (let line of lines) {
					const cols = line.split("\t").map(it => it.trim())
					if (cols[2] && (clearview.TEXTRACT_KNOWN_FIELD_TYPES.includes(cols[0]) || cols[1])) {
						newFields.push({ Type: cols[0], Label: cols[1], Value: cols[2] })
						console.warn("Inserted columns from text")
					}
				}
				console.warn("End of insert columns from text")
			} catch (ex1) {
				console.error(ex1)
				clearview.ShowToast(
					"error",
					<p>
						Unable to paste <i>{text}</i>
						<b>{JSON.stringify(ex1)}</b>
					</p>,
					5000
				)
			}
		}

		console.warn("parseFieldsFromClipboard: newFields", newFields)
		return newFields
	}

	onAddNewVendor(e) {
		let newVendor = "<new>"

		this.state.selectedIndex =
			this.state.vendors.push({
				vendorName: newVendor,
				rules: [],
			}) - 1

		this.state.selectedVendor = this.state.vendors[this.state.selectedIndex]

		this.setState({
			...this.state,
			isChanged: true,
		})
	}

	onPasteNewRule(e, vendor) {
		const newFields = this.parseFieldsFromClipboard(e) || { Type: "OTHER", Label: "", Value: "" }
		if (!newFields.length) return

		vendor.rules.push({
			fields: newFields,
		})

		this.setState({
			...this.state,
			isChanged: true,
		})
	}

	onPasteNewField(e, rule) {
		const newFields = this.parseFieldsFromClipboard(e)
		if (!newFields.length) return

		for (let newField of newFields) {
			rule.fields.push(newField)
		}

		this.setState({
			...this.state,
			isChanged: true,
		})
	}

	onRemoveField(e, vendor, rule, rIdx, fIdx) {
		rule.fields.splice(fIdx, 1)
		if (!rule.fields.length) {
			vendor.rules.splice(rIdx, 1)
		}

		this.setState({
			...this.state,
			isChanged: true,
		})
	}
}

const mapStateToProps = state => {
	const user = state.userReducer.user || clearview.User.UnauthenticatedUser

	const business = ACTIONS.findInBranches(
		state.clientsReducer.clients.data,
		it => it.id === parseInt(clearview.PathPart(3)),
		it => it.children
	)

	return {
		user,
		business,
	}
}

export default connect(mapStateToProps)(TextractAnalysisRules)
