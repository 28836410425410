import React from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone-uploader"
import _ from "lodash"

import * as XLSX from "sheetjs-style"

import * as clearview from "../../../../components/@Clearview"

export default class FileUploadButton extends React.Component {
	static propTypes = {
		onUploaded: PropTypes.func.isRequired,
		accept: PropTypes.string,
		className: PropTypes.string,
	}

	constructor(props) {
		super(props)

		this.state = { file: null }
	}

	handleFileChangeStatus = ({ meta, file, remove }, status) => {
		switch (status) {
			case "preparing":
				this.setState({ file: file })
				break

			case "done":
				const reader = new FileReader()
				reader.onload = evt => this.props.onUploaded(file, evt)
				reader.readAsBinaryString(file)
				remove()
				break

			case "removed":
				this.setState({ file: null })
				break

			default:
				break
		}
	}

	render() {
		const { className, accept, children, ...otherProps } = this.props

		return (
			<Dropzone
				disabled={!!this.state.file}
				onChangeStatus={this.handleFileChangeStatus}
				classNames={{
					dropzone: "dzu-dropzone dropzone-tight " + className || "",
					dropzoneActive: "dzu-dropzoneActive dropzone-active",
					inputLabel: "btn btn-outline-primary fit-content-h",
					label: "m-0",
				}}
				accept={accept}
				multiple={false}
				maxFiles={1}
				submitButtonDisabled={true}
				inputContent={children}
				inputWithFilesContent={null}
				submitButtonContent={null}
				{...otherProps}
			></Dropzone>
		)
	}
}

// header = 1	Generate an array of arrays
// header = "A"	Row object keys are literal column labels
// header = array of strings	Use specified strings as keys in row objects
// header = (default)	Read and disambiguate first row as keys
export function excelSheetReader(onSheetRead, sheetNames = [], importAll = false, header = undefined) {
	return (file, evt) => {
		try {
			const bstr = evt.target.result
			const wb = XLSX.read(bstr, { type: "binary", cellDates: true, dateNF: "dd/mm/yyyy" })

			// Import the specified sheets ("" will indicate the first sheet), or the first sheet
			const sheetsToImport = !sheetNames?.length ? [wb.SheetNames[0]] : sheetNames.map(it => (it ? it : wb.SheetNames[0]))
			for (let worksheetName of _.uniq(sheetsToImport)) {
				const ws = wb.Sheets[worksheetName]
				if (ws) {
					const data = XLSX.utils.sheet_to_json(ws, {
						blankrows: false,
						skipHidden: true,
						rawNumbers: true,
						header: header,
					})

					onSheetRead(file, data, worksheetName)
					if (!importAll) break
				}
			}
		} catch (ex) {
			clearview.ShowToast("error", <p>{ex.message}</p>, null, null)
		}
	}
}
