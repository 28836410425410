import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Dropdown from "react-bootstrap/Dropdown"

export const ExtendedDropdown = ({ className, ...otherProps }) => {
	const classes = classNames(className, "extended-dropdown")
	return <Dropdown.Menu className={classes} {...otherProps} />
}
ExtendedDropdown.propTypes = {
	className: PropTypes.string,
}
