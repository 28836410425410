import React from "react"

import * as _ from "lodash"
import * as clearview from "../../../../../components/@Clearview"

import { useReducer } from "react"
import { CustomInput, Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "../../../../../components"
import { SimpleTable } from "../../../../../components"

export function UpdateFeeAllocation({ config, isOpen, onCancel, onSave, value = { s: [] } }) {
	config = {
		title: "",
		buttonLabel: "Update",
		...config,
	}

	const [feesState, dispatch] = useReducer(
		(prevState, action) => {
			let newState = _.cloneDeep(prevState)

			switch (action.type) {
				case "LOAD":
					newState = _.cloneDeep(action.value)
					newState.allocations = newState.allocations || []
					_.each(newState.allocations, it => {
						it.total = clearview.isNothingOrEmpty(it.total) ? "" : parseFloat(it.total)
					})
					newState.isChanged = false
					newState.isSaveClicked = false
					return newState

				case "UPDATE":
					newState[action.key] = action.value
					newState.isChanged = true
					return newState

				case "UPDATE-ROW":
					newState.allocations.find(it => it.schedule === action.schedule).total = Math.round(action.total * 100)
					newState.isChanged = true
					return newState

				case "ALLOCATE-EVENLY":
					const perSchedule = Math.round(prevState.calculated.total / prevState.allocations.length)
					let remaining = prevState.calculated.total
					_.eachRight(newState.allocations, (allocation, idx) => {
						if (idx === 0) allocation.total = remaining
						else {
							allocation.total = perSchedule
							remaining = remaining - perSchedule
						}
					})
					newState.isChanged = true
					return newState

				default:
					break
			}
		},
		{ isChanged: false, isSaveClicked: false, allocations: [] }
	)

	const onOpened = () => dispatch({ type: "LOAD", value })

	const onSaving = () => {
		if (!feesState.isChanged) return
		else if (!!errors().length) dispatch({ type: "UPDATE", key: "isSaveClicked", value: true })
		else {
			_.each(feesState.items, it => {
				it.total = Math.round(it.total * 100) || undefined
			})
			onSave(feesState)
		}
	}

	const errors = () => {
		const myErrors = []
		const allocatedCounts = _.countBy(feesState.allocations, it => !clearview.isNothingOrEmpty(it.total))
		if (allocatedCounts.false === 1) myErrors.push(`One schedule does not have a fee or zero.`)
		if (allocatedCounts.false > 1) myErrors.push(`${allocatedCounts.false} schedules do not have a fee or zero.`)

		return myErrors
	}

	const columns = {
		schedule: {
			mapSummary: clearview.tidy.string,
		},
		total: {
			heading: "Fee £",
			map: (col, row, idx) => (
				<CustomInput
					key={`dr_${idx}`}
					type="number"
					name="total"
					id="total"
					className="form-control currency"
					defaultValue={clearview.isNothingOrEmpty(col) ? "" : parseFloat(col / 100)}
					onBlur={e => dispatch({ type: "UPDATE-ROW", schedule: row.schedule, total: clearview.roundCurrencyInput(e.target.value) })}
				/>
			),
			mapSummary: clearview.tidy.currency,
			className: "currency",
		},
	}

	return (
		<Modal isOpen={isOpen} onOpened={onOpened} style={{ minWidth: 525 }} className="update-fee-allocation">
			<ModalHeader tag="h6">
				<div className="full-width d-flex-row justify-content-between align-items-center">
					<div className="flex-1">
						{config.title} {clearview.FormatCurrency(feesState.calculated?.total)}
					</div>
					<div>
						<Button size="sm" title="Allocate evenly" color="primary" className="ml-0 mr-2" onClick={e => dispatch({ type: "ALLOCATE-EVENLY" })}>
							{clearview.Icon.wizard}
						</Button>
					</div>
				</div>
			</ModalHeader>

			<ModalBody>
				<FormGroup row>
					<SimpleTable columns={columns} data={feesState.allocations} summaryColumns={["total"]} />
				</FormGroup>
			</ModalBody>

			<ModalFooter>
				{clearview.listErrors(errors(), !feesState.isChanged || !feesState.isSaveClicked)}
				<Button color="link" onClick={onCancel}>
					Cancel
				</Button>
				<Button color="primary" name="update" onClick={onSaving}>
					{config.buttonLabel}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
