import React from 'react'
import ReactQuill from 'react-quill';

import PropTypes from 'prop-types'


export default class CommentsEditor extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    }

    modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}]
        ],
    }

    formats = [
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent'
    ]

    render() {

        return (<ReactQuill
            value={ this.props.value }
            onChange={ this.props.onChange }
            modules={ this.modules }
            formats={ this.formats }
            style={{
                minHeight: '250px',
                maxHeight: '250px',
                flex: 1
            }}
        />)
    }

}
