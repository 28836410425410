import _ from "lodash"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"

import { Container, Row, Badge, Table, Col } from "../../../components"
import { Spinner } from "../../components/Spinner"

import { HeaderMain } from "../../components/HeaderMain"
import { HelpPopup } from "../../components/HelpPopup"

import { Consumer } from "../../../layout/context"
import { PreFilterLink } from "../../components/PreFilterLink"

import { PeriodEndFilter } from "../components/PeriodEndFilter"

import {
	LivePeriodEndsByRagWithUsDonut,
	LivePeriodEndsByRagWithThemDonut,
	LivePeriodEndsStagesOverview,
	LivePeriodEndsByRag2WithUsDonut,
	LivePeriodEndsByRag2WithThemDonut,
	LivePeriodEndsPhasesOverview,
} from "./widgets"
import { CustomerSelector } from "./widgets/CustomerSelector"

class OverviewDashboardComponent extends Component {
	static propTypes = {
		periodEnds: PropTypes.array.isRequired,
		properties: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
		isBusy: PropTypes.bool.isRequired,
		lastUpdated: PropTypes.number,
	}

	render() {
		const { user } = this.props

		return (
			<Consumer>
				{context => {
					const livePeriodEnds = context.filtered(this.props.periodEnds.filter(it => ["NotYetOpen", "Open"].includes(it.status))) || []
					const properties = this.props.properties || []

					const periodEndsByInHouse = {
						true: [],
						false: [],
						..._.groupBy(
							livePeriodEnds.filter(it => it.currentStage),
							it => it.currentStage.templateStage.isInHouse
						),
					}
					const periodEndsByRag = {
						BehindSchedule: [],
						Overdue: [],
						OnHold: [],
						..._.groupBy(livePeriodEnds, it => it.rag),
					}
					const queries = livePeriodEnds.flatMap(it => (it.currentStage ? it.currentStage.comments : null)).filter(it => it && it.type === "Query")
					const queriesBy = {
						true: [],
						false: [],
						..._.groupBy(queries, it => it.isWithUs),
					}
					const myQueriesByMe = {
						true: [],
						false: [],
						..._.groupBy(queries, it => ([it.createdBy.name, it.assignedTo.name].includes(user.name) ? it.isWithUs : undefined)),
					}

					const activePeriodEnds = livePeriodEnds.filter(it => it.currentStage && !["OnHold", "NotDue"].includes(it.rag))

					const priorityCounts = _.countBy(this.props.periodEnds, it => (it.isAlert ? "alerts" : it.isFavourite ? "favourites" : null))
					priorityCounts.alerts = priorityCounts.alerts || 0
					priorityCounts.favourites = (priorityCounts.favourites || 0) + priorityCounts.alerts
					const alertsColor = priorityCounts.alerts ? clearview.PeriodEndRagColorValues.Overdue : clearview.PastelColorValues.Greyish

					const statistics = [
						{
							name: "Priority / Alerts",
							tooltip: "Your Alert Period Ends",
							clear: true,
							preFilter: "/periodEnds?isAlert=priority",
							color: clearview.PeriodEndRagColorValues.Warning,
							value: priorityCounts.favourites,
							tooltip2: "Your Alert Period Ends changed recently",
							color2: alertsColor,
							clear2: true,
							preFilter2: "/periodEnds?isAlert=alert",
							value2: priorityCounts.alerts,
						},
						{
							name: "Unread Period Ends",
							tooltip: "Period Ends changed since you last read them",
							preFilter: "/periodEnds?isUnread=unread",
							color: clearview.PastelColorValues.Infoish,
							value: this.props.periodEnds.filter(it => it.isUnread).length,
						},
						{
							name: "Active Properties / Registered",
							tooltip: "Properties with Open Period Ends not On Hold",
							color: clearview.PastelColorValues.Greenish,
							value: Object.keys(_.groupBy(activePeriodEnds, it => it.property.id)).length,
							tooltip2: "All Properties",
							color2: clearview.PastelColorValues.Greyish,
							preFilter2: "/properties",
							value2: properties.length,
						},
						{
							name: "Period Ends On Hold : Active",
							tooltip: "Period Ends On Hold",
							color: clearview.PastelColorValues.Blueish,
							preFilter: "/periodends?periodEndRag=OnHold",
							value: periodEndsByRag["OnHold"].length,
							separator: " : ",
							tooltip2: "Due Period Ends not On Hold",
							color2: clearview.PastelColorValues.Greenish,
							// preFilter2: "/periodends?periodEndStatus=Open&RAG is not OnHold and Not Not Due",
							value2: activePeriodEnds.length,
						},
						{
							name: "Stages In House",
							tooltip: "Open Stages In House",
							color: clearview.PastelColorValues.Beige,
							preFilter: "/stages?inHouse=InHouse",
							value: periodEndsByInHouse[true].length,
						},
						{
							name: "Stages with Client",
							tooltip: "Open Stages With Client",
							color: clearview.PastelColorValues.Beige,
							preFilter: "/stages?inHouse=WithClient",
							value: periodEndsByInHouse[false].length,
						},
						{
							name: "Period Ends Behind Schedule",
							tooltip: "Period Ends Behind Schedule",
							color: clearview.PastelColorValues.Purplish,
							preFilter: "/periodends?periodEndRag=BehindSchedule",
							value: periodEndsByRag["BehindSchedule"].length,
						},
						{
							name: "Period Ends Already Late",
							tooltip: "Period Ends already Overdue",
							color: clearview.PastelColorValues.Redish,
							preFilter: "/periodends?periodEndRag=Overdue",
							value: periodEndsByRag["Overdue"].length,
						},
					]

					const queryStatistics = [
						{
							name: `Queries Awaiting ${clearview.Client(user).name} Response`,
							tooltip: `Queries in the Current Stage awaiting ${clearview.Client(user).name} response`,
							color: user.isInHouse ? clearview.PeriodEndRagColorValues.Warning : clearview.PeriodEndRagColorValues.Overdue,
							preFilter: `/queries?type=Query&currentOrFuture=current&with=${user.isInHouse ? "Them" : "Us"}`,
							value: queriesBy[!user.isInHouse].length,
						},
						{
							name: `Queries Awaiting ${clearview.Customer(user).name} Response`,
							tooltip: `Queries in the Current Stage awaiting ${clearview.Customer(user).name} response`,
							color: user.isInHouse ? clearview.PeriodEndRagColorValues.Overdue : clearview.PeriodEndRagColorValues.Warning,
							preFilter: `/queries?type=Query&currentOrFuture=current&with=${user.isInHouse ? "Us" : "Them"}`,
							value: queriesBy[user.isInHouse].length,
						},
						{
							name: `My Queries and Queries to me`,
							tooltip: `Queries by/to me in the Current Stage awaiting ${clearview.Client(user).name} response`,
							color: clearview.PeriodEndRagColorValues.Warning,
							preFilter: `/queries?type=Query&currentOrFuture=current&withWhom=Them`,
							value: myQueriesByMe[false].length,
							tooltip2: `Queries by or to me in the Current Stage awaiting ${clearview.Customer(user).name} response`,
							color2: clearview.PeriodEndRagColorValues.Overdue,
							preFilter2: `/queries?type=Query&currentOrFuture=current&withWhom=Me`,
							value2: myQueriesByMe[true].length,
						},
					]

					const openStagesWithUs = _.groupBy(
						(livePeriodEnds || []).filter(it => it.currentStage && it.currentStage.templateStage.isInHouse === user.isInHouse),
						it => it.currentStage.status
					)
					const openStagesWithUsByStatus = Object.keys(clearview.StageStatusNames)
						.filter(key => key !== "Closed")
						.reverse()
						.map(key => ({
							name: clearview.StageStatusNames[key],
							value: (openStagesWithUs[key] || []).length,
							color: clearview.StageStatusColorValuesPastel[key],
							preFilter: `/periodEnds?inHouse=${user.isInHouse ? "InHouse" : "WithClient"}&stageStatus=${key}`,
						}))

					const openStagesWithThem = _.groupBy(
						(livePeriodEnds || []).filter(it => it.currentStage && it.currentStage.templateStage.isInHouse !== user.isInHouse),
						it => it.currentStage.status
					)
					const openStagesWithThemByStatus = Object.keys(clearview.StageStatusNames)
						.filter(key => key !== "Closed")
						.reverse()
						.map(key => ({
							name: clearview.StageStatusNames[key],
							value: (openStagesWithThem[key] || []).length,
							color: clearview.StageStatusColorValuesPastel[key],
							preFilter: `/periodEnds?inHouse=${!user.isInHouse ? "InHouse" : "WithClient"}&stageStatus=${key}`,
						}))

					const statsMapper = (it, idx) => (
						<tr key={it.name}>
							<td className="text-inverse bt-0">
								{it.preFilter ? (
									<PreFilterLink
										key={`l-${idx}`}
										context={context}
										periodEnds={this.props.periodEnds}
										clear={!!it.clear}
										to={it.preFilter}
										className="text-decoration-none"
									>
										{it.name}
									</PreFilterLink>
								) : (
									it.name
								)}
							</td>
							<td className="text-right bt-0 nowrap">
								{it.preFilter ? (
									<PreFilterLink
										key={`v0-${idx}`}
										context={context}
										periodEnds={this.props.periodEnds}
										clear={!!it.clear}
										to={it.preFilter}
										className="text-decoration-none"
										title={it.tooltip}
									>
										<Badge style={{ backgroundColor: it.color }} pill title={it.tooltip}>
											{it.value}
										</Badge>
									</PreFilterLink>
								) : (
									<Badge key={`b0-${idx}`} style={{ backgroundColor: it.color }} pill title={it.tooltip}>
										{it.value}
									</Badge>
								)}
								{it.color2 && <span>{it.separator || "/"}</span>}
								{it.color2 &&
									(it.preFilter2 ? (
										<PreFilterLink
											key={`v1-${idx}`}
											context={context}
											periodEnds={this.props.periodEnds}
											clear={!!it.clear2}
											to={it.preFilter2}
											className="text-decoration-none"
										>
											<Badge key={`stat2_${idx}`} style={{ backgroundColor: it.color2 }} pill title={it.tooltip2}>
												{it.value2}
											</Badge>
										</PreFilterLink>
									) : (
										<Badge key={`b1-${idx}`} style={{ backgroundColor: it.color2 }} pill title={it.tooltip2}>
											{it.value2}
										</Badge>
									))}
							</td>
						</tr>
					)

					return (
						<Container className={this.props.isBusy || !properties.length ? "isBusy" : ""}>
							{this.props.isBusy && <Spinner key="spinner"></Spinner>}

							<Row className="mb-5">
								<Col lg={6}>
									<HeaderMain
										title={<span className="text-primary">{clearview.Icon.overview}Overview</span>}
										className="mb-2 mt-0"
										onRefresh={() => this.props.doRefresh()}
									/>
								</Col>
								<Col lg={6}>
									<CustomerSelector context={context} />
									<PeriodEndFilter
										key={clearview.GenerateKey()}
										className="mr-2"
										periodEnds={this.props.periodEnds}
										key={clearview.GenerateKey()}
									/>
								</Col>
							</Row>

							<Row className="mb-5">
								{user.preferences.overview.LivePeriodEndsByRagWithUsDonut && (
									<Col lg={6} md={8}>
										<LivePeriodEndsByRagWithUsDonut user={user} livePeriodEnds={livePeriodEnds} />
									</Col>
								)}

								{user.preferences.overview.LivePeriodEndsByRagWithThemDonut && (
									<Col lg={6} md={8} className="mb-4 mb-lg-0">
										<LivePeriodEndsByRagWithThemDonut user={user} livePeriodEnds={livePeriodEnds} />
									</Col>
								)}

								{user.preferences.overview.LivePeriodEndsByRag2WithUsDonut && (
									<Col lg={6} md={8}>
										<LivePeriodEndsByRag2WithUsDonut user={user} livePeriodEnds={livePeriodEnds} />
									</Col>
								)}

								{user.preferences.overview.LivePeriodEndsByRag2WithThemDonut && (
									<Col lg={6} md={8} className="mb-4 mb-lg-0">
										<LivePeriodEndsByRag2WithThemDonut user={user} livePeriodEnds={livePeriodEnds} />
									</Col>
								)}
							</Row>

							{user.preferences.overview.LivePeriodEndsStagesOverview && (
								<Row className="mb-5">
									<Col lg={12} md={12} className="mb-4 mb-lg-0">
										<LivePeriodEndsStagesOverview
											key="LivePeriodEndsStagesOverview"
											context={context}
											periodEnds={this.props.periodEnds}
											livePeriodEnds={livePeriodEnds}
										/>
									</Col>
								</Row>
							)}

							{user.preferences.overview.LivePeriodEndsPhasesOverview(user) && (
								<Row className="mb-5">
									<Col lg={12} md={12} className="mb-4 mb-lg-0">
										<LivePeriodEndsPhasesOverview key="LivePeriodEndsPhasesOverview" context={context} livePeriodEnds={livePeriodEnds} />
									</Col>
								</Row>
							)}

							<Row className="mb-0">
								<Col lg={6} md={8}>
									<div className="hr-text hr-text-left my-2">
										<span>
											Status of Stages With {clearview.Us(user).name}
											<HelpPopup id="od_hp_swu" title={`Status of Stages With ${clearview.Us(user).name}`}>
												<p>
													This table shows the <b>activity Status of the current Stage</b> of Open Period Ends where the current Stage
													is owned by {clearview.Us(user).name}.
												</p>
											</HelpPopup>
										</span>
									</div>
									<Table size="sm" className="no-borders">
										<tbody>{openStagesWithUsByStatus.map(statsMapper)}</tbody>
									</Table>

									<div className="hr-text hr-text-left my-2">
										<span>
											Status of Stages With {clearview.Them(user).name}
											<HelpPopup id="od_hp_swt" title={`Status of Stages With ${clearview.Them(user).name}`}>
												<p>
													This table shows the <b>activity Status of the current Stage</b> of Open Period Ends where the current Stage
													is owned by {clearview.Them(user).name}.
												</p>
											</HelpPopup>
										</span>
									</div>
									<Table size="sm" className="no-borders">
										<tbody>{openStagesWithThemByStatus.map(statsMapper)}</tbody>
									</Table>
								</Col>

								<Col lg={6} md={8}>
									<div className="hr-text hr-text-left my-2">
										<span>
											Statistics
											<HelpPopup id="od_hp_stats" title="Statistics">
												<p>
													This table shows various statistics for the <b>current Stage of Open Period Ends</b>. Hover over the numbers
													for further information.
												</p>
											</HelpPopup>
										</span>
									</div>
									<Table size="sm" className="no-borders">
										<tbody>{statistics.map(statsMapper)}</tbody>
									</Table>
								</Col>
							</Row>

							<Row className="mb-5">
								<Col lg={6} md={8}></Col>

								<Col lg={6} md={8}>
									<div className="hr-text hr-text-left my-2">
										<span>
											Queries{" "}
											<HelpPopup id="od_hp_queries" title="Queries">
												<p>
													This table shows various statistics for Open Queries in the <b>current Stage of Open Period Ends</b>. Hover
													over the numbers for further information.
												</p>
											</HelpPopup>
										</span>
									</div>
									<Table size="sm" className="no-borders">
										<tbody>{queryStatistics.map(statsMapper)}</tbody>
									</Table>
								</Col>
							</Row>
						</Container>
					)
				}}
			</Consumer>
		)
	}
}

function OverviewDashboard({ periodEnds, properties, user, isBusy, lastUpdated, preFilterContext, doRefresh, markAllRead }) {
	preFilterContext.checkIfPreFilterLinked()
	return (
		<OverviewDashboardComponent
			periodEnds={periodEnds}
			properties={properties}
			user={user}
			isBusy={isBusy}
			lastUpdated={lastUpdated}
			doRefresh={doRefresh}
			markAllRead={markAllRead}
		/>
	)
}

const mapStateToProps = state => {
	const periodEndsSubState = ACTIONS.getSubState(state, "clientsReducer\\periodEnds")
	const propertiesSubState = ACTIONS.getSubState(state, "clientsReducer\\properties")

	return {
		...periodEndsSubState,
		periodEnds: ACTIONS.dictionaryToArray(periodEndsSubState.dict),
		properties: ACTIONS.dictionaryToArray(propertiesSubState.dict),
		user: state.userReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	doRefresh: () => {
		dispatch({ type: ACTIONS.USER_AUTHENTICATED, payload: {} })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(OverviewDashboard)
