import React from "react"
import PropTypes from "prop-types"

const TimelineDefault = props => (
	<React.Fragment>
		<div className="timeline timeline-datetime">
			{props.showPillDate && (
				<React.Fragment>
					<div className="timeline-date">
						<span className="badge badge-pill badge-secondary">{props.pillDate}</span>
					</div>
				</React.Fragment>
			)}
			<div className="timeline-item pr-3">
				<div className="timeline-icon">
					<i className={` fa fa-circle-o text-${props.smallIconColor}`}></i>
				</div>
				<div className="timeline-item-inner pb-0">
					<div className="timeline-item-head pb-0">
						<div className="pull-left mr-2">
							<span className="fa-stack fa-lg">
								<i className={` fa fa-circle fa-stack-2x text-${props.iconCircleColor}`}></i>
								<i className={` fa fa-stack-1x text-white fa-${props.iconCircle}`}></i>
							</span>
						</div>
						<div className="user-detail">
							{props.title && <h6 className="mb-0">{props.title}</h6>}
							{props.subTitle && <div>{props.subTitle}</div>}
						</div>
					</div>
					{props.content && (
						<div className="timeline-item-content">
							<div>{props.content}</div>
						</div>
					)}
				</div>
			</div>
		</div>
	</React.Fragment>
)

TimelineDefault.propTypes = {
	showPillDate: PropTypes.bool,
	pillDate: PropTypes.string,
	smallIconColor: PropTypes.string,
	iconCircleColor: PropTypes.string,
	iconCircle: PropTypes.string,
	badgeTitle: PropTypes.string,
}

TimelineDefault.defaultProps = {
	showPillDate: false,
	pillDate: "Waiting",
	smallIconColor: "secondary",
	iconCircleColor: "secondary",
	iconCircle: "question",
}

export { TimelineDefault }
