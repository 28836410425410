import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import _ from "lodash"

import {
	Form,
	FormGroup,
	FormText,
	InputGroup,
	InputGroupAddon,
	UncontrolledModal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	CustomInput,
} from "../../../../components"

import DatePicker, { setDefaultLocale } from "react-datepicker"

import * as clearview from "../../../../components/@Clearview"

import * as ACTIONS from "../../../../store/actions"

setDefaultLocale("en")

class Property_NewPeriodEndForm extends React.Component {
	static propTypes = {
		property: PropTypes.object.isRequired,
		customerTemplates: PropTypes.array.isRequired,
		// dispatch: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {}
		this.props = props

		this.handleTemplateChange = this.handleTemplateChange.bind(this)
		this.handleEndDateChange = this.handleEndDateChange.bind(this)
		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
	}

	handleTemplateChange(e) {
		const latestPeriodEnd = _.maxBy(
			this.props.property.periodEnds.filter(it => it.template.id === parseInt(e.target.value || 0)),
			it => it.endDate
		)
		const nextEndDate = latestPeriodEnd
			? new Date(moment(latestPeriodEnd.endDate).add(parseInt(latestPeriodEnd.template.durationMonths), "month").toISOString(false))
			: null

		this.setState({
			templateId: e.target.value,
			endDate: nextEndDate || this.state.endDate,
		})
	}

	handleEndDateChange(value) {
		this.setState({
			endDate: value,
		})
	}

	handleActionButtonClick() {
		if (!this.isValid) {
			this.setState({ showValidation: true })
			return
		}
		this.props.dispatch({
			type: ACTIONS.PERIODEND_CREATE,
			propertyId: this.props.property.id,
			templateId: parseInt(this.state.templateId),
			endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
		})
		this.setState({})
		return true
	}

	get isTemplateRequired() {
		return !this.state.templateId
	}
	get isEndDateRequired() {
		return !this.state.endDate
	}

	get isValid() {
		return !this.isTemplateRequired && !this.isEndDateRequired
	}

	render() {
		const { customerTemplates, property } = this.props
		const thisCustomer = _.first(customerTemplates.filter(it => it.lft <= property.lft && it.rgt >= property.rgt))
		const templates = thisCustomer ? _.sortBy(thisCustomer.templates, it => it.reference).filter(it => !it.isRetired) : []

		return (
			<UncontrolledModal target="NewPeriodEndForm">
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="h6">New Period End for {clearview.GetBusinessTitle(property)}</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">Template</InputGroupAddon>
								<CustomInput type="select" name="templateId" id="templateId" onChange={this.handleTemplateChange}>
									<option defaultValue="">Choose a Template...</option>
									{templates.map((it, idx) => (
										<option key={idx} value={it.id}>
											{it.reference}: {it.name}
										</option>
									))}
								</CustomInput>
							</InputGroup>
							<FormText hidden={!this.state.showValidation || !this.isTemplateRequired} className="text-danger">
								Template is required
							</FormText>
						</FormGroup>

						<FormGroup row>
							<InputGroup>
								<InputGroupAddon addonType="prepend">End Date</InputGroupAddon>
								<DatePicker inline selected={this.state.endDate} onChange={this.handleEndDateChange} />
								<FormText hidden={!this.state.showValidation || !this.isEndDateRequired} className="text-danger">
									End Date is required
								</FormText>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close color="default" className="text-primary" disabled={!this.isValid} onClick={this.handleActionButtonClick}>
							Create
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapStateToProps = state => {
	return {
		customerTemplates: ACTIONS.dictionaryToArray(ACTIONS.getSubState(state, "clientsReducer\\templates").dict),
	}
}

export default connect(mapStateToProps)(Property_NewPeriodEndForm)
