import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import _ from "lodash"

import * as ACTIONS from "../../../../store/actions"

import moment from "moment"

import * as clearview from "../../../../components/@Clearview"

// import NumberFormat from 'react-number-format';

import { Link } from "react-router-dom"
import { Accordion, Table, Input, CustomInput } from "../../../../components"

import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"
import { PeriodEndActions } from "./PeriodEndActions"
import { PeriodEndQueriesExport } from "./PeriodEndQueriesExport"

class PeriodEndCard extends React.Component {
	static propTypes = {
		periodEnd: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.initState = this.initState.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onSaveFee = this.onSaveFee.bind(this)
		this.onSaveExpenditureFinal = this.onSaveExpenditureFinal.bind(this)
		this.onSaveExpenditureBudget = this.onSaveExpenditureBudget.bind(this)
		this.onSaveDoNotRenew = this.onSaveDoNotRenew.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.state = this.initState()
	}

	initState() {
		return {
			periodEnd: { ...this.props.periodEnd },
			isEditing: [],
			isChanged: [],
		}
	}

	onChange(e) {
		switch (e.target.type) {
			case "number":
				if (["fee", "expenditureFinal", "expenditureBudget"].includes(e.target.name)) {
					this.state.periodEnd[e.target.name] = e.target.value
					//clearview.isNothingOrEmpty(e.target.value) ? null : clearview.parseCurrency(e.target.value)
				} else {
					this.state.periodEnd[e.target.name] = isNaN(e.target.value) ? null : parseInt(e.target.value)
				}
				break

			default:
				this.state.periodEnd[e.target.name] = e.target.value
				break
		}

		this.setState(prevState => ({
			isEditing: _.union(prevState.isEditing, [e.target.name]),
			isChanged: _.union(prevState.isChanged, [e.target.name]),
		}))
	}

	onSaveFee() {
		this.state.periodEnd.fee = clearview.isNothingOrEmpty(this.state.periodEnd.fee) ? null : clearview.parseCurrency(this.state.periodEnd.fee)
		this.props.doFieldUpdate(this.state.periodEnd, "fee")
		this.setState(prevState => ({
			isEditing: _.difference(prevState.isEditing, ["fee"]),
			isChanged: _.difference(prevState.isChanged, ["fee"]),
		}))
	}

	onSaveExpenditureFinal() {
		this.state.periodEnd.expenditureFinal = clearview.isNothingOrEmpty(this.state.periodEnd.expenditureFinal)
			? null
			: clearview.parseCurrency(this.state.periodEnd.expenditureFinal)
		this.props.doFieldUpdate(this.state.periodEnd, "expenditureFinal")
		this.setState(prevState => ({
			isEditing: _.difference(prevState.isEditing, ["expenditureFinal"]),
			isChanged: _.difference(prevState.isChanged, ["expenditureFinal"]),
		}))
	}

	onSaveExpenditureBudget() {
		this.state.periodEnd.expenditureBudget = clearview.isNothingOrEmpty(this.state.periodEnd.expenditureBudget)
			? null
			: clearview.parseCurrency(this.state.periodEnd.expenditureBudget)
		this.props.doFieldUpdate(this.state.periodEnd, "expenditureBudget")
		this.setState(prevState => ({
			isEditing: _.difference(prevState.isEditing, ["expenditureBudget"]),
			isChanged: _.difference(prevState.isChanged, ["expenditureBudget"]),
		}))
	}

	onSaveDoNotRenew(e) {
		this.state.periodEnd.doNotRenew = e.target.checked
		this.props.doDoNotRenewUpdate(this.state.periodEnd)
		this.setState(prevState => ({
			isEditing: _.difference(prevState.isEditing, ["doNotRenew"]),
			isChanged: _.difference(prevState.isChanged, ["doNotRenew"]),
		}))
	}

	onCancel() {
		this.setState(this.initState())
	}

	componentDidUpdate(prevProps) {
		if (prevProps.periodEnd.reference !== this.props.periodEnd.reference) {
			this.setState(this.initState())
		}
	}

	render() {
		const { periodEnd } = this.props
		const fee = this.state.periodEnd.fee === null || isNaN(this.state.periodEnd.fee) ? "" : this.state.periodEnd.fee
		const expenditureFinal =
			this.state.periodEnd.expenditureFinal === null || isNaN(this.state.periodEnd.expenditureFinal) ? "" : this.state.periodEnd.expenditureFinal
		const expenditureBudget =
			this.state.periodEnd.expenditureBudget === null || isNaN(this.state.periodEnd.expenditureBudget) ? "" : this.state.periodEnd.expenditureBudget
		const actions = _.intersection(periodEnd.actions, ["SetFee", "SetExpenditureFinal", "SetExpenditureBudget", "SetDoNotRenew", "Vouching"])

		return (
			<Accordion className="mb-2" initialOpen={true}>
				<Accordion.Header className="d-flex-row h6 justify-content-between">
					<span className="align-self-center">{clearview.Icon.periodEnd} Period End </span>
					<PeriodEndActions periodEnd={periodEnd} />
				</Accordion.Header>
				<Accordion.Body>
					<Table key="ref" size="sm">
						<tbody>
							<tr>
								<td className="align-middle">Ref.</td>
								<td className="text-right">
									<Link to={`/periodend/${periodEnd.reference}`} className="text-decoration-none" title={`Period End #${periodEnd.id}`}>
										{periodEnd.reference}
									</Link>
								</td>
							</tr>

							{actions.includes("Vouching") && (
								<tr>
									<td colSpan={2}>
										<Link
											to={`/vouching/${periodEnd.reference}`}
											className="text-primary"
											title={`Vouching for Period End #${periodEnd.reference}`}
										>
											{clearview.Icon.vouching} Vouching...
										</Link>
										{this.props.user.isInHouse && (
											<React.Fragment>
												<br />
												<Link
													to={`/working-papers/${periodEnd.reference}`}
													className="text-primary"
													title={`Working Papers for Period End #${periodEnd.reference}`}
												>
													{clearview.Icon.workingPapers} Working Papers...
												</Link>
											</React.Fragment>
										)}
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					<Table key="data" size="sm">
						<tbody>
							<tr>
								<td className="align-middle">Status</td>
								<td className="text-right">{clearview.PeriodEndStatusBadge(periodEnd, clearview.PeriodEndStatusNames[periodEnd.status])}</td>
							</tr>
							{periodEnd.currentStage && (
								<tr>
									<td className="align-middle" colSpan={2}>
										Stage {periodEnd.currentStage.templateStage.sequence}:<br />
										{periodEnd.currentStage.templateStage.name}
									</td>
								</tr>
							)}
							<tr>
								<td className="align-middle">RAG</td>
								<td className="text-right">{clearview.PeriodEndRagBadge(periodEnd, clearview.PeriodEndRagNames[periodEnd.rag])}</td>
							</tr>
							<tr>
								<td className="align-middle nowrap">Period End</td>
								<td className="text-right">{moment(periodEnd.endDate).format("DD/MM/YYYY")}</td>
							</tr>
							<tr>
								<td className="align-middle nowrap">Start Work</td>
								<td className="text-right">{moment(periodEnd.startOn).format("DD/MM/YYYY")}</td>
							</tr>
							<tr>
								<td className="align-middle nowrap">Warning At</td>
								<td className="text-right">{moment(periodEnd.warningDate).format("DD/MM/YYYY")}</td>
							</tr>
							<tr>
								<td className="align-middle">Due</td>
								<td className="text-right">{moment(periodEnd.dueDate).format("DD/MM/YYYY")}</td>
							</tr>
							{periodEnd.projectedEndDate && this.props.user.isInHouse && (
								<tr>
									<td className="align-middle">Expected</td>
									<td className="text-right">
										{clearview.PeriodEndRagBadge(periodEnd, moment(periodEnd.projectedEndDate).format("DD/MM/YYYY"))}
									</td>
								</tr>
							)}
							{periodEnd.closedAt && (
								<tr>
									<td className="align-middle">Closed</td>
									<td className="text-right">{clearview.PeriodEndRagBadge(periodEnd, moment(periodEnd.closedAt).format("DD/MM/YYYY"))}</td>
								</tr>
							)}
							{this.props.user.isInHouse && (
								<React.Fragment>
									<tr>
										<td className="align-middle">Fee (£)</td>
										<td className="text-right">
											<CustomInput
												disabled={!actions.includes("SetFee")}
												type="number"
												key="fee"
												id="fee"
												name="fee"
												className="form-control currency"
												value={clearview.formatCurrencyValue(fee)}
												onChange={this.onChange}
											></CustomInput>

											{this.props.user.isInHouse && (
												<EditSaveOrCancel
													isEditing={this.state.isEditing.includes("fee")}
													isChanged={this.state.isChanged.includes("fee")}
													onSave={this.onSaveFee}
													onCancel={this.onCancel}
												/>
											)}
										</td>
									</tr>
									<tr>
										<th colSpan={2} className="align-left">
											Expenditure (£):
										</th>
									</tr>
									<tr>
										<td className="align-middle" style={{ borderTopStyle: "none" }}>
											Final Total
										</td>
										<td className="text-right" style={{ borderTopStyle: "none" }}>
											<CustomInput
												disabled={!actions.includes("SetExpenditureFinal")}
												type="number"
												key="expenditureFinal"
												id="expenditureFinal"
												name="expenditureFinal"
												className="form-control currency"
												value={clearview.formatCurrencyValue(expenditureFinal)}
												onChange={this.onChange}
											></CustomInput>

											{this.props.user.isInHouse && (
												<EditSaveOrCancel
													isEditing={this.state.isEditing.includes("expenditureFinal")}
													isChanged={this.state.isChanged.includes("expenditureFinal")}
													onSave={this.onSaveExpenditureFinal}
													onCancel={this.onCancel}
												/>
											)}
										</td>
									</tr>
									<tr>
										<td className="align-middle" style={{ borderTopStyle: "none" }}>
											Budget Total
										</td>
										<td className="text-right" style={{ borderTopStyle: "none" }}>
											<CustomInput
												disabled={!actions.includes("SetExpenditureBudget")}
												type="number"
												key="expenditureBudget"
												id="expenditureBudget"
												name="expenditureBudget"
												className="form-control currency"
												value={clearview.formatCurrencyValue(expenditureBudget)}
												onChange={this.onChange}
											></CustomInput>

											{this.props.user.isInHouse && (
												<EditSaveOrCancel
													isEditing={this.state.isEditing.includes("expenditureBudget")}
													isChanged={this.state.isChanged.includes("expenditureBudget")}
													onSave={this.onSaveExpenditureBudget}
													onCancel={this.onCancel}
												/>
											)}
										</td>
									</tr>
								</React.Fragment>
							)}
							{this.props.user.isInHouse && (
								<tr>
									<td className="align-middle">Do Not Renew</td>
									<td className="text-right">
										<Input
											name="doNotRenew"
											type="checkbox"
											checked={periodEnd.doNotRenew}
											onChange={this.onSaveDoNotRenew}
											disabled={!actions.includes("SetDoNotRenew")}
										/>
									</td>
								</tr>
							)}
							<tr>
								<td className="align-middle">Queries</td>
								<td className="text-right">
									<PeriodEndQueriesExport periodEnd={periodEnd} buttonStyle={{ padding: "0px 4px 0px 4px" }} />
								</td>
							</tr>
						</tbody>
					</Table>
				</Accordion.Body>
			</Accordion>
		)
	}
}

const mapStateToProps = state => ({
	user: state.userReducer.user,
})

const mapDispatchToProps = dispatch => ({
	doFieldUpdate: (periodEnd, fieldName) =>
		dispatch({
			type: ACTIONS.PERIODEND_FIELD_UPDATE,
			fieldName: fieldName,
			periodEnd: periodEnd,
		}),

	doDoNotRenewUpdate: periodEnd =>
		dispatch({
			type: ACTIONS.PERIODEND_DONOTRENEW_UPDATE,
			periodEnd: periodEnd,
		}),
})

PeriodEndCard = connect(mapStateToProps, mapDispatchToProps)(PeriodEndCard)

export { PeriodEndCard }
