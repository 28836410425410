import React from "react"
import { Link } from "react-router-dom"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"
import paginationFactory from "../../../../react-bootstrap-table2-paginator"

import * as clearview from "../../../../components/@Clearview"
import { sortCaret } from "../../../../components/@Clearview"

import PropTypes from "prop-types"

import { ButtonGroup, Row, Col } from "../../../../components"
import { CustomExportCSV } from "./CustomExportButton"
import { CustomSearch } from "./CustomSearch"
import { PropertiesFilter } from "./PropertiesFilter"

import { PropertyCard } from "./PropertyCard"

class PropertiesTable extends React.Component {
	static propTypes = {
		properties: PropTypes.array.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			predicate: it => true,
		}

		this.onFilterChange = this.onFilterChange.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.searchPredicate = this.searchPredicate.bind(this)
	}

	onFilterChange(predicate) {
		this.setState({
			predicate: predicate,
		})
	}

	onSearch(value) {
		this.setState({
			search: value,
		})
	}

	searchPredicate = property => {
		if (!this.state.search) return true

		const regex = clearview.Search.SafeRegex(this.state.search)
		return clearview.Search.Business(property, regex)
	}
	render() {
		const properties = this.props.properties.filter(p => this.state.predicate(p)).filter(this.searchPredicate)

		const filterMessage = `${properties.length} of ${this.props.properties.length}`

		const columnDefs = createColumnDefinitions()

		return (
			<ToolkitProvider keyField="reference" data={properties} columns={columnDefs} search exportCSV>
				{props => (
					<React.Fragment>
						<div className="d-flex justify-content-end align-items-center mb-2">
							<div className="d-flex ml-auto">
								<CustomSearch onSearch={this.onSearch} />
								<PropertiesFilter
									className="ml-2"
									properties={this.props.properties}
									onFilterChange={this.onFilterChange}
									message={filterMessage}
								/>
								<ButtonGroup className="ml-2">
									<CustomExportCSV {...props.csvProps}>Export</CustomExportCSV>
								</ButtonGroup>
							</div>
						</div>
						<BootstrapTable
							pagination={paginationFactory({
								sizePerPage: 20,
								sizePerPageList: [
									{ text: "10 row per page", value: 10 },
									{ text: "20 row per page", value: 20 },
									{ text: "50 row per page", value: 50 },
									{ text: "100 row per page", value: 100 },
								],
							})}
							classes="table-responsive-lg table-striped"
							bordered={false}
							expandRow={expandRow}
							responsive
							hover
							{...props.baseProps}
						/>
					</React.Fragment>
				)}
			</ToolkitProvider>
		)
	}
}

function createColumnDefinitions() {
	return [
		{
			dataField: "parent.parent.id",
			text: "Client",
			sort: true,
			sortCaret,
			formatter: (cell, row) =>
				row.parent && row.parent.role === "Client" ? (
					<Link to={`/clients/${row.parent.reference}`} className="text-decoration-none">
						{clearview.GetBusinessTitle(row.parent)}
					</Link>
				) : null,
		},
		{
			dataField: "parent.parent.name",
			text: "Managing Agent",
			sort: true,
			sortCaret,
			formatter: (cell, row) =>
				row.parent && row.parent.parent && row.parent.parent.role === "Managing Agent" ? (
					<Link to={`/clients/${row.parent.parent.reference}`} className="text-decoration-none">
						{clearview.GetBusinessTitle(row.parent.parent)}
					</Link>
				) : null,
		},
		{
			dataField: "parent.name",
			text: "Landlord",
			sort: true,
			sortCaret,
			formatter: (cell, row) =>
				row.parent && row.parent.role === "Landlord" ? (
					<Link to={`/clients/${row.parent.reference}`} className="text-decoration-none">
						{clearview.GetBusinessTitle(row.parent)}
					</Link>
				) : null,
		},
		{
			dataField: "name",
			text: "Property Name",
			sort: true,
			sortCaret,
		},
		{
			dataField: "reference",
			text: "Ref",
			sort: true,
			sortCaret,
			headerAlign: "center",
			align: "center",
			classes: "nowrap",
		},
		{
			dataField: "address.fullAddressExcludingPostcode",
			text: "Address",
			sort: true,
			sortCaret,
			formatter: (cell, row) => clearview.AddressBlock(cell),
		},
		{
			dataField: "address.postcode",
			text: "Postcode",
			sort: true,
			headerAlign: "center",
			align: "center",
			sortCaret,
		},
		{
			dataField: "attr0",
			text: "Corporate",
			sort: true,
			sortCaret,
			headerAlign: "center",
			align: "center",
			formatter: (cell, row) => clearview.YesNoAttributeValue(row.parent, "Is Landlord Corporate"),
			csvFormatter: (cell, row) => clearview.YesNoAttributeValue(row.parent, "Is Landlord Corporate"),
		},
		{
			dataField: "attr1",
			text: "Use",
			sort: true,
			sortCaret,
			headerAlign: "center",
			align: "center",
			formatter: (cell, row) => clearview.AttributeValue(row, "Property Use"),
			csvFormatter: (cell, row) => clearview.AttributeValue(row, "Property Use"),
		},
		{
			dataField: "attr2",
			text: "Vat Elected",
			sort: true,
			sortCaret,
			headerAlign: "center",
			align: "center",
			formatter: (cell, row) => clearview.YesNoAttributeValue(row, "VAT Elected"),
			csvFormatter: (cell, row) => clearview.YesNoAttributeValue(row, "VAT Elected"),
		},
		{
			dataField: "attr3",
			text: "Tenure",
			sort: true,
			sortCaret,
			headerAlign: "center",
			align: "center",
			formatter: (cell, row) => clearview.AttributeValue(row, "Property Tenure"),
			csvFormatter: (cell, row) => clearview.AttributeValue(row, "Property Tenure"),
		},
		{
			dataField: "attr4",
			text: "Demand Type",
			sort: true,
			sortCaret,
			headerAlign: "center",
			align: "center",
			formatter: (cell, row) => clearview.AttributeValue(row, "Demand Type"),
			csvFormatter: (cell, row) => clearview.AttributeValue(row, "Demand Type"),
		},
	]
}

const expandRow = {
	renderer: row => (
		<Row key={`P_${row.id}`}>
			<Col md={6}></Col>
			<Col md={6}>
				<PropertyCard property={row} />
			</Col>
		</Row>
	),
	showExpandColumn: true,
	expandHeaderColumnRenderer: ({ isAnyExpands }) =>
		isAnyExpands ? <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i> : <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>,
	expandColumnRenderer: ({ expanded }) =>
		expanded ? <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i> : <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>,
}

export { PropertiesTable }
