import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Badge } from "reactstrap"
import { UncontrolledTooltip } from "./../../../components"

import { Consumer } from "../../../layout/context"
import { PreFilterLink } from "../PreFilterLink"
import * as ACTIONS from "../../../store/actions"

let PeriodEndsUnreadBadge = props => {
	const { ...badgeProps } = props
	const unreadCount = props.periodEndsArray?.filter(it => it.isUnread).length

	return (
		<Consumer>
			{context => (
				<Badge {...badgeProps} pill color="info" key="avatar-unread-badge" id="avatar-unread-badge">
					<PreFilterLink
						style={{ color: "white" }}
						key="avatar-unread-prefilter"
						context={context}
						periodEnds={props.periodEndsArray}
						to="/periodEnds?isUnread=unread"
						clear={true}
						className="text-decoration-none"
					>
						{unreadCount}
					</PreFilterLink>
					<UncontrolledTooltip placement="left-end" target="avatar-unread-badge">
						<span>
							You have {unreadCount} unread Period End<span hidden={unreadCount === 1}>s</span>
						</span>
					</UncontrolledTooltip>
				</Badge>
			)}
		</Consumer>
	)
}
PeriodEndsUnreadBadge.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	periodEndsArray: PropTypes.array,
	markAllRead: PropTypes.func,
}
PeriodEndsUnreadBadge.addOnId = "avatar--badge"

const mapStateToProps = state => {
	const { clientsReducer } = state
	return {
		periodEndsArray: ACTIONS.dictionaryToArray(clientsReducer.periodEnds.dict),
	}
}

const mapDispatchToProps = dispatch => ({
	markAllRead: () => {
		dispatch({ type: ACTIONS.PERIODEND_ALL_READ_UPDATE })
	},
})

PeriodEndsUnreadBadge = connect(mapStateToProps, mapDispatchToProps)(PeriodEndsUnreadBadge)
export { PeriodEndsUnreadBadge }
