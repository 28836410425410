import React from "react"

import * as _ from "lodash"
import * as clearview from "../../../../../components/@Clearview"

import { useReducer } from "react"
import { Input, CustomInput, Button, FormGroup, InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody, ModalFooter } from "../../../../../components"
import { SimpleTable } from "../../../../../components"
import { Schedule } from "./Schedule"
import { NominalCode } from "./NominalCode"

export function AddUpdateJournalEntry({ config, isOpen, onCancel, onSave, value = { description: "", items: [] } }) {
	config = {
		title: "",
		buttonLabel: "Update",
		...config,
	}

	const balanceItems = (items, affectsColumn) => {
		const countOfDr = items.filter(it => !!it.dr).length
		const sumOfDr = _.sumBy(items, it => Math.round((it.dr || 0) * 100))
		const countOfCr = items.filter(it => !!it.cr).length
		const sumOfCr = _.sumBy(items, it => Math.round((it.cr || 0) * 100))

		switch (affectsColumn) {
			case "dr":
				//Make sure all DRs add up to this value
				if (countOfDr === 1) {
					_.find(items, it => !!it.dr).dr = sumOfCr / 100
				} else if (countOfDr === 0) {
					items.push({ dr: clearview.roundCurrencyInput((sumOfCr - sumOfDr) / 100) })
				}
				break
			case "cr":
				//Make sure all CRs add up to this value
				if (countOfCr === 1) {
					_.find(items, it => !!it.cr).cr = sumOfDr / 100
				} else if (countOfCr === 0) {
					items.push({ cr: clearview.roundCurrencyInput((sumOfDr - sumOfCr) / 100) })
				}
				break
			default:
				break
		}
	}

	const [entry, dispatch] = useReducer(
		(prevState, action) => {
			let newState = _.cloneDeep(prevState)

			switch (action.type) {
				case "LOAD":
					newState = _.cloneDeep(action.value)
					newState.description = newState.description || ""
					newState.isPresentationOnly = newState.isPresentationOnly || false
					newState.items = newState.items || []
					_.each(newState.items, it => {
						it.dr = parseFloat(it.dr / 100) || ""
						it.cr = parseFloat(it.cr / 100) || ""
					})
					newState.isChanged = false
					newState.isSaveClicked = false
					return newState

				case "UPDATE":
					newState[action.key] = action.value
					newState.isChanged = true
					return newState

				case "ADD-ROW":
					const insertAt = newState.items.length ? newState.items.length - 1 : 0
					newState.items.splice(insertAt, 0, { code: "", nominalCode: { name: "" }, schedule: "", cr: "", dr: "", ...action.defaultValue })
					newState.isChanged = true
					return newState

				case "UPDATE-ROW":
					newState.items.splice(action.idx, 1, action.item)
					if (action.item.dr || action.item.cr) balanceItems(newState.items, !!action.item.dr ? "cr" : "dr")
					newState.isChanged = true
					return newState

				case "DELETE-ROW":
					const deletedRow = newState.items.splice(action.idx, 1)
					balanceItems(newState.items, !!deletedRow.dr ? "dr" : "cr")
					newState.isChanged = true
					return newState

				default:
					break
			}
		},
		{ isChanged: false, isSaveClicked: false, description: "", isPresentationOnly: false, items: [] }
	)

	const onOpened = () => dispatch({ type: "LOAD", value })

	const onSaving = () => {
		if (!entry.isChanged) return
		else if (!!errors().length) dispatch({ type: "UPDATE", key: "isSaveClicked", value: true })
		else {
			_.each(entry.items, it => {
				it.dr = Math.round(it.dr * 100) || undefined
				it.cr = Math.round(it.cr * 100) || undefined
			})
			onSave(entry)
		}
	}

	const errors = () => {
		const myErrors = []
		if (!entry.description) myErrors.push("Description is required.")
		if (!entry.items.length) myErrors.push("Items are required.")
		if (!_.every(entry.items, it => it.code && it.schedule && (!!it.cr || !!it.dr)))
			myErrors.push("Every item must have code, schedule and a Dr or Cr value.")
		const counts = _.countBy(entry.items, it => (!!it.dr ? "dr" : !!it.cr ? "cr" : "neither"))
		if (counts.dr === 0) myErrors.push("You must have a Dr item")
		if (counts.cr === 0) myErrors.push("You must have a Cr item")
		if (counts.dr !== 1 && counts.cr !== 1) myErrors.push("You must not have multiple Dr and multiple Cr items.")

		const total = _.sum(entry.items.map(it => Math.round((it.dr || 0) * 100) - Math.round((it.cr || 0) * 100)))
		if (total !== 0) myErrors.push(`Drs and Crs must balance: ${clearview.formatCurrency(total, false, true)}`)

		const scheduleCodeArray = _.values(_.groupBy(entry.items, it => JSON.stringify({ schedule: it.schedule, code: it.code })))
		if (_.find(scheduleCodeArray, it => it.length > 1)) {
			myErrors.push("You must not have the same Code and Schedule more than once.")
		}

		return myErrors
	}

	const columns = {
		code: {
			map: (col, row, idx) => (
				<NominalCode
					key={`c_${idx}`}
					value={col}
					onChange={val => dispatch({ type: "UPDATE-ROW", idx, item: { ...row, code: val?.code, nominalCode: val } })}
				/>
			),
			mapSummary: clearview.tidy.string,
		},
		schedule: {
			map: (col, row, idx, type) => (
				<Schedule key={`s_${idx}`} value={col} onChange={val => dispatch({ type: "UPDATE-ROW", idx, item: { ...row, schedule: val } })} />
			),
			mapSummary: clearview.tidy.string,
		},
		detailsAndWorkings: {
			heading: <span className="nowrap">Details & Workings</span>,
			map: (col, row) => clearview.tidy.string(row.nominalCode?.name),
		},
		dr: {
			heading: "Dr £",
			map: (col, row, idx) => (
				<CustomInput
					key={`dr_${idx}`}
					type="number"
					name="total"
					id="dr"
					className="form-control currency"
					defaultValue={col}
					onBlur={e => dispatch({ type: "UPDATE-ROW", idx, item: { ...row, dr: clearview.roundCurrencyInput(e.target.value), cr: "" } })}
				/>
			),
			mapSummary: clearview.tidy.currency,
			className: "currency",
		},
		cr: {
			heading: "Cr £",
			map: (col, row, idx) => (
				<CustomInput
					key={`cr_${idx}`}
					type="number"
					name="total"
					id="cr"
					className="form-control currency"
					defaultValue={col}
					onBlur={e => dispatch({ type: "UPDATE-ROW", idx, item: { ...row, dr: "", cr: clearview.roundCurrencyInput(e.target.value) } })}
				/>
			),
			mapSummary: clearview.tidy.currency,
			className: "currency",
		},
	}

	return (
		<Modal isOpen={isOpen} style={{ minWidth: 800, maxWidth: 1000 }} onOpened={onOpened} className="add-update-journal-entry">
			<ModalHeader tag="h6">{config.title}</ModalHeader>

			<ModalBody>
				<FormGroup row>
					<InputGroup>
						<InputGroupAddon addonType="prepend" className="vertical-align-top">
							Description
						</InputGroupAddon>
						<Input
							type="textarea"
							name="description"
							id="description"
							className="form-control"
							defaultValue={value.description}
							onBlur={e => dispatch({ type: "UPDATE", key: "description", value: e.target.value })}
						/>
					</InputGroup>
				</FormGroup>

				<FormGroup row>
					<InputGroup>
						<CustomInput
							label="Presentation only"
							type="checkbox"
							name="isPresentationOnly"
							id="isPresentationOnly"
							className="ml-2"
							defaultChecked={value.isPresentationOnly}
							onBlur={e => dispatch({ type: "UPDATE", key: "isPresentationOnly", value: e.target.checked })}
						/>
					</InputGroup>
				</FormGroup>

				<FormGroup row>
					<SimpleTable
						columns={columns}
						data={entry.items}
						onAdd={item => dispatch({ type: "ADD-ROW", defaultValue: item })}
						onDelete={(item, idx) => dispatch({ type: "DELETE-ROW", idx })}
					/>
				</FormGroup>
			</ModalBody>

			<ModalFooter>
				{clearview.listErrors(errors(), !entry.isChanged || !entry.isSaveClicked)}
				<Button color="link" onClick={onCancel}>
					Cancel
				</Button>
				<Button color="primary" name="update" onClick={onSaving}>
					{config.buttonLabel}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
