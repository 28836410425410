import React, { useState } from "react"
import { connect } from "react-redux"
import { WP_ACTIONS } from "./store"

import * as _ from "lodash"
import * as clearview from "../../../../components/@Clearview"

import { Table, Row, Col, Button } from "../../../../components"
import { FeeSummary } from "./components"
import { FeeAllocation, UpdateFeeAllocation, FeeMatrixActions } from "./components"

function FeeMatrixPage({ isReadOnly, FEE_MATRIX, fees, client, periodEnd, onSave }) {
	const [editor, setEditor] = useState(false)

	const onEdit = isReadOnly ? false : fees => setEditor({ title: "Allocate Fees", value: fees })

	const onCancel = e => setEditor(false)

	return (
		<React.Fragment>
			<Row>
				<Col lg={8}>
					<h5 className="mt-4">Fees</h5>
					<FeeSummary />
				</Col>
				<Col lg={4}>
					<div className="d-flex justify-content-between">
						<h5 className="flex-1 mt-4">Fee Allocation</h5>
						{!isReadOnly && (
							<Button
								key="edit"
								className="ml-1 mt-3 fit-content-h"
								outline={!!fees.messages.length}
								color="primary"
								title="Allocate fee to schedules"
								onClick={e => onEdit(fees)}
							>
								Allocate Fee ...
							</Button>
						)}
					</div>
					<FeeAllocation />
				</Col>
			</Row>
			<div className="mt-4 d-flex justify-content-between">
				<h5>{client.name} Fee Matrix</h5>
				<FeeMatrixActions key="actions" periodEnd={periodEnd} />
			</div>
			<FeeMatrixTable feeMatrix={FEE_MATRIX} selectedFee={fees?.calculated} />
			{fees?.allocations && (
				<UpdateFeeAllocation
					name="editor"
					config={{ title: editor?.title }}
					isOpen={!!editor}
					onCancel={onCancel}
					onSave={item => {
						onSave(item)
						onCancel()
					}}
					value={editor?.value}
				/>
			)}
		</React.Fragment>
	)
}

function FeeMatrixTable({ feeMatrix, selectedFee }) {
	const matrix = feeMatrix.matrix
	const keys = _.keys(matrix)
	const valueCount = _.max(keys.map(key => matrix[key].length))

	let upToExpenditure = -100

	return (
		<Table className="fee-matrix-table">
			<thead>
				<tr>
					<th rowSpan={2} colSpan={2} className="text-align-center">
						Expenditure
					</th>
					<th colSpan={valueCount} className="text-align-center">
						Accountancy Fee for Periods Ending on/after {feeMatrix["~meta"].scope}
					</th>
				</tr>
				<tr>
					{[...Array(valueCount)].map((_, idx) => (
						<th key={`sch_${idx}`} className={idx + 1 === selectedFee?.schedules ? "selected text-align-center" : "text-align-center"}>
							{idx + 1}
						</th>
					))}
				</tr>
			</thead>
			<tbody className="compact-rows">
				{keys.map((band, bIdx) => {
					const fees = matrix[band]
					const fromExpenditure = upToExpenditure + 100
					upToExpenditure = parseInt(band)

					return (
						<tr key={`fees_${bIdx}`} className={band === selectedFee?.band ? "selected" : ""}>
							<th className="currency">{clearview.formatCurrency(fromExpenditure)}</th>
							<th className="currency">{clearview.formatCurrency(upToExpenditure)}</th>
							{fees.map((fee, fIdx) => (
								<td key={`fee_${fIdx}`} className={fIdx + 1 === selectedFee?.schedules ? "selected currency" : "currency"}>
									{clearview.FormatCurrency(fee)}
								</td>
							))}
						</tr>
					)
				})}
			</tbody>
		</Table>
	)
}

const mapStateToProps = state => {
	return {
		FEE_MATRIX: state.workingPapersReducer.workingPapers.config.FEE_MATRIX,
		fees: state.workingPapersReducer.workingPapers.fees,
		client: state.workingPapersReducer.workingPapers.client,
		periodEnd: state.workingPapersReducer.workingPapers.periodEnd,
	}
}

const mapDispatchToProps = dispatch => ({
	onSave: fees =>
		dispatch({
			type: WP_ACTIONS.SAVE_FEES,
			page: "fees",
			fees,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeeMatrixPage)
