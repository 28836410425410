import React from "react"

import { useReducer } from "react"

import { connect } from "react-redux"

import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import * as Api from "../../../../api/CustomerApi"

import DEFAULT_FEE_MATRIX from "../../PeriodEnds/WorkingPapers/Config/DEFAULT_FEE_MATRIX.json"

import { EditSaveOrCancel } from "../../../components/EditSaveOrCancel"

import { Button, InputGroup, InputGroupAddon, CustomInput, SimpleTable } from "../../../../components"

import Preferences from "../Preferences/Preferences"

const FLAVOUR = "working-papers-fee-matrix"

function FeeMatrixEditorPage({ user, business }) {
	const [state, dispatch] = useReducer(
		(prevState, action) => {
			let newState = _.cloneDeep(prevState)

			switch (action.type) {
				case "LOAD":
					newState.user = action.user
					newState.business = action.business
					return newState

				case "LIST-MATRICES":
					newState.feeMatrices = action.feeMatrices.sort(clearview.orderBy(it => it.scope))
					const latestFeeMatrix = newState.feeMatrices.reduce((prev, next) => {
						const upToDate = new Date(next.scope)
						upToDate.setDate(upToDate.getDate() - 1)
						prev.range = `${prev.scope} to ${clearview.FormatDate(upToDate, "YYYY-MM-DD")}`
						return next
					})
					if (latestFeeMatrix) latestFeeMatrix.range = `${latestFeeMatrix.scope} onward`
					newState.feeMatrices = newState.feeMatrices.reverse()
					newState.isBusy = false
					newState.isShowErrors = false
					newState.isChanged = false
					return newState

				case "SET-MATRIX":
					newState.feeMatrix = {
						originalScope: action.scope,
						scope: action.scope,
						matrix: action.matrix,
					}

					let prevUpTo = -100
					newState.feeMatrix.editable = _.keys(action.matrix).map((upTo, idx) => {
						const row = {
							id: idx,
							from: prevUpTo + 100,
							upTo: parseInt(upTo),
							_0: action.matrix[upTo][0],
							_1: action.matrix[upTo][1],
							_2: action.matrix[upTo][2],
							_3: action.matrix[upTo][3],
							_4: action.matrix[upTo][4],
							_5: action.matrix[upTo][5],
							_6: action.matrix[upTo][6],
							_7: action.matrix[upTo][7],
							_8: action.matrix[upTo][8],
							_9: action.matrix[upTo][9],
						}
						prevUpTo = row.upTo
						return row
					})
					newState.isChanged = false
					newState.isShowErrors = false

					if (action.scope) newState.isBusy = false
					return newState

				case "CLEAR-MATRIX":
					newState.feeMatrix = false
					newState.isChanged = false
					newState.isShowErrors = false
					return newState

				case "SET-IS-CHANGED":
					newState.isChanged = action.isChanged
					return newState

				case "SET-SHOW-ERRORS":
					newState.isShowErrors = action.isShowErrors
					return newState

				case "UPDATE-SCOPE":
					if (newState.feeMatrix.scope !== action.scope) {
						newState.feeMatrix.scope = action.scope
						newState.isChanged = true
					}
					return newState

				case "UPDATE-FEE":
					const row = newState.feeMatrix.editable.find(it => it.id === action.id)
					const newFee = Math.round(action.value * 100)
					if (row[action.column] !== newFee) {
						row[action.column] = newFee
						newState.isChanged = true
					}
					return newState

				default:
					console.error(action, "ACTION is unrecognised")
					return newState
			}
		},
		{ isBusy: true, isChanged: false, isShowErrors: false, user: {}, business: {}, feeMatrices: [], feeMatrix: false }
	)

	const listScopes = () =>
		Api.listPreferences(business, FLAVOUR).then(resp => {
			dispatch({ type: "LIST-MATRICES", feeMatrices: resp })
		})

	if (user && business && (!_.isEqual(user, state.user) || !_.isEqual(business, state.business))) {
		dispatch({ type: "LOAD", user, business })
		listScopes()
	}

	const onCreate = async () => {
		const latestScope = state.feeMatrices[0]?.scope
		if (latestScope) {
			const res = await Api.loadPreferences(business, FLAVOUR, DEFAULT_FEE_MATRIX, latestScope)
			dispatch({ type: "SET-MATRIX", scope: "", matrix: res.matrix })
		} else {
			dispatch({ type: "SET-MATRIX", scope: "", matrix: DEFAULT_FEE_MATRIX.matrix })
		}
	}

	const onSelect = async scope => {
		if (!scope) {
			dispatch({ type: "CLEAR-MATRIX" })
		} else {
			const res = await Api.loadPreferences(business, FLAVOUR, DEFAULT_FEE_MATRIX, scope)
			dispatch({ type: "SET-MATRIX", scope, ...res })
		}
	}

	const onCancel = () => {
		onSelect(state.feeMatrix.originalScope)
	}

	const errors = () => {
		const myErrors = []
		if (!state.feeMatrix.scope) myErrors.push("Applies To Date is required.")
		if (state.feeMatrix.scope !== state.feeMatrix.originalScope && state.feeMatrices.find(it => it.scope === state.feeMatrix.scope))
			myErrors.push(`A fee matrix starting at ${state.feeMatrix.scope} already exists.`)
		return myErrors
	}

	const onDelete = () => {
		const doWhat = prompt(`Are you sure you wish the delete ${state.feeMatrix.originalScope}?. Type in 'delete' to confirm.`)
		if (!doWhat) return
		switch (doWhat.toLowerCase()) {
			case "delete":
				Api.deletePreferences(business, FLAVOUR, state.feeMatrix.originalScope)
					.then(res => listScopes())
					.then(() => onSelect(false))

				break

			default:
				return
		}
	}

	const onSave = async () => {
		if (errors().length) {
			dispatch({ type: "SET-SHOW-ERRORS", isShowErrors: true })
			return false
		}

		if (state.feeMatrix.scope !== state.feeMatrix.originalScope && !!state.feeMatrix.originalScope) {
			const doWhat = prompt(
				"You have changed the Applies To Date. Type in 'delete' if you wish to delete the old matrix, or 'keep' if you wish to keep it."
			)
			if (!doWhat) return
			switch (doWhat.toLowerCase()) {
				case "delete":
					await Api.deletePreferences(business, FLAVOUR, state.feeMatrix.originalScope)
					break

				case "keep":
					// Continue
					break

				default:
					return
			}
		}

		const newFeeMatrix = { matrix: {} }
		_.each(state.feeMatrix.editable, row => {
			newFeeMatrix.matrix[row.upTo] = [row._0, row._1, row._2, row._3, row._4, row._5, row._6, row._7, row._8, row._9]
		})

		const saveScope = state.feeMatrix.scope
		Api.savePreferences(business, FLAVOUR, newFeeMatrix, saveScope)
			.then(res => listScopes())
			.then(() => onSelect(saveScope))
	}

	const feeColumnFn = idx => ({
		className: "text-align-center pl-0 pr-0",
		heading: <span>{idx + 1}</span>,
		map: (col, row) => (
			<CustomInput
				style={{ width: "5em" }}
				key={`fee_${idx}`}
				type="number"
				name="fee"
				id="fee"
				className="form-control currency"
				defaultValue={clearview.isNothingOrEmpty(col) ? "" : parseFloat(col / 100)}
				onBlur={e => dispatch({ type: "UPDATE-FEE", id: row.id, column: `_${idx}`, value: clearview.roundCurrencyInput(e.target.value) })}
			/>
		),
	})
	const columns = {
		from: {
			className: "currency",
			heading: <span className="nowrap">From £</span>,
			map: clearview.tidy.currency,
		},
		upTo: {
			className: "currency",
			heading: <span className="nowrap">Up to £</span>,
			map: clearview.tidy.currency,
		},
		_0: feeColumnFn(0),
		_1: feeColumnFn(1),
		_2: feeColumnFn(2),
		_3: feeColumnFn(3),
		_4: feeColumnFn(4),
		_5: feeColumnFn(5),
		_6: feeColumnFn(6),
		_7: feeColumnFn(7),
		_8: feeColumnFn(8),
		_9: feeColumnFn(9),
	}

	return (
		<Preferences
			key="preferences"
			business={state.business}
			title="Fee Matrix Manager"
			narrative="Fee matrix manager for Working Papers Fee calculations."
			isBusy={state.isBusy}
			isChanged={state.isChanged}
			commands={[
				<InputGroup key="select" hidden={!state.feeMatrices.length}>
					<InputGroupAddon addonType="prepend">Select Fee Matrix:</InputGroupAddon>
					<CustomInput
						disabled={state.isChanged}
						className="ml-auto outline-primary"
						type="select"
						id="select"
						name="select"
						onChange={e => onSelect(e.target.value)}
					>
						<option key={-1} value={""}>
							Please select ...
						</option>
						{state.feeMatrices.map((it, idx) => (
							<option key={idx} value={it.scope}>
								{it.range}
							</option>
						))}
					</CustomInput>
				</InputGroup>,

				<Button
					disabled={state.isChanged}
					key="addNew"
					className="ml-1 fit-content-h"
					outline={true}
					color="primary"
					title="Add new matrix"
					onClick={onCreate}
				>
					{clearview.Icon.create}
				</Button>,
			]}
		>
			{!!state.feeMatrix && (
				<React.Fragment>
					<div className="full-width d-flex justify-content-between mb-2">
						<div>
							<InputGroup key="scope">
								<InputGroupAddon addonType="prepend">
									<span>Applies to Period Ends on/after:</span>
								</InputGroupAddon>
								<CustomInput
									className="ml-2 outline-primary"
									type="date"
									id="scope"
									name="scope"
									value={state.feeMatrix.scope}
									onChange={e => dispatch({ type: "UPDATE-SCOPE", scope: e.target.value })}
								></CustomInput>
							</InputGroup>
						</div>
						{clearview.listErrors(errors(), !state.isShowErrors)}
						<EditSaveOrCancel
							key="editSaveOrCancel"
							isEditing={true}
							isChanged={state.isChanged}
							onSave={onSave}
							onCancel={onCancel}
							onDelete={!!state.feeMatrix?.originalScope ? onDelete : false}
						/>
					</div>
					<SimpleTable className="fee-matrix-editable-table full-width" columns={columns} data={state.feeMatrix.editable} />
				</React.Fragment>
			)}
		</Preferences>
	)
}

const mapStateToProps = state => {
	const user = state.userReducer.user || clearview.User.UnauthenticatedUser

	const business = ACTIONS.findInBranches(
		state.clientsReducer.clients.data,
		it => it.id === parseInt(clearview.PathPart(2)),
		it => it.children
	)

	return {
		user,
		business,
	}
}

export default connect(mapStateToProps)(FeeMatrixEditorPage)
