import React, { useState } from "react"
import * as clearview from "../../components/@Clearview"

import { FormGroup, Button, Col, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter } from "../../components"

export const NOT_BLANK_CONSTRAINT = x => (x?.trim() ? undefined : "Value cannot be blank.")

export function InputPrompt({ config, isOpen, onCancel, onSave, defaultValue = "" }) {
	config = {
		width: 600,
		title: "Enter a Value",
		message: "Value",
		placeHolder: "",
		buttonLabel: "Save",
		errors: [NOT_BLANK_CONSTRAINT],
		...config,
	}

	const [value, setValue] = useState(defaultValue)
	const [isChanged, setChanged] = useState(false)

	if (!isChanged && config.isChanged) setChanged(true)

	const [errors, setErrors] = useState([])
	const validate = val => {
		setErrors(config.errors.map(it => it(val)).filter(it => !!it))
	}

	const onOpened = e => {
		setValue(defaultValue)
		setChanged(false)
	}

	return (
		<Modal isOpen={isOpen} autoFocus={true} centered={true} style={{ minWidth: config.width }} onOpened={onOpened}>
			<ModalHeader tag="div">
				<div className="d-flex">
					<h1 className="text-primary mr-2">
						<i className="fa fa-fw fa-question-circle-o"></i> {config.title}
					</h1>
				</div>
			</ModalHeader>

			<ModalBody>
				<h6>{config.message}</h6>

				<FormGroup row>
					<Col sm={12}>
						<CustomInput
							type="text"
							name="value"
							id="value"
							className="form-control"
							placeholder={config.placeholder}
							value={value}
							onChange={e => {
								setValue(e.target.value)
								validate(e.target.value)
								setChanged(true)
							}}
						/>
					</Col>
				</FormGroup>
			</ModalBody>

			<ModalFooter>
				{clearview.listErrors(errors, !errors.length)}
				<Button color="link" onClick={onCancel}>
					Cancel
				</Button>
				<Button
					disabled={!isChanged || !!errors.length}
					color="primary"
					name="update"
					onClick={e => {
						onSave(value)
					}}
				>
					{config.buttonLabel}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
