import * as XLSX from "sheetjs-style"
import * as _ from "lodash"

import {
	Config,
	MakeSafeFileName,
	AfterLast,
	S3EntityPath,
	PeriodEndDateRange,
	formatCurrencyValue,
	getAllKeys,
	prioritisedSort,
} from "../../../../components/@Clearview"
import { xlsx } from "clearview-aws-common"

import { STATUS_TITLE } from "./VouchingTheme"

export default function VouchingReport(periodEnd, vouching) {
	const workbook = XLSX.utils.book_new()

	XLSX.utils.book_append_sheet(workbook, expenditureWorksheet(vouching, periodEnd), "Expenditure")
	XLSX.utils.book_append_sheet(workbook, invoicesWorksheet(vouching), "Invoices")

	const expenditureChecks = [
		{
			name: "Exp Date Out Of Range Check",
			rows: (ledger, expenses) => {
				const periodEndDateRange = PeriodEndDateRange(periodEnd)
				return ledger
					.map(it => ({
						...it,
						Comment: !it.date
							? "Missing Date"
							: it.date < periodEndDateRange.from
							? "Date is before Period"
							: it.date >= periodEndDateRange.upTo
							? "Date is after Period"
							: null,
					}))
					.filter(it => it.Comment)
					.map(combineMaps(it => _.pick(it, ["Comment"]), ledgerData_SimpleMap))
			},
			columnWidths: [20, ...ledgerData_SimpleMap_ColumnWidths],
		},
		{
			name: "Duplicate Exp Check",
			rows: (ledger, expenses) =>
				reportGroupChecks(
					ledger,
					it => `${it.vendorName}|${it.ref}|${it.total}`,
					(group, it, idx) => (group.length > 1 ? `Duplicate Supplier & Invoice & Total (${idx + 1} of ${group.length})` : ""),
					ledgerData_SimpleMap
				),
			columnWidths: [8, 40, ...ledgerData_SimpleMap_ColumnWidths],
		},
		{
			name: "Duplicate Exp Invoice Numbers",
			rows: (ledger, expenses) =>
				reportGroupChecks(
					ledger,
					it => `${it.ref}`,
					(group, it, idx) => (!group[0].ref ? "" : group.length > 1 ? `Duplicate Invoice No. (${idx + 1} of ${group.length})` : ""),
					ledgerData_SimpleMap
				),
			columnWidths: [8, 35, ...ledgerData_SimpleMap_ColumnWidths],
		},
		{
			name: "Duplicate Exp Narrative",
			rows: (ledger, expenses) =>
				reportGroupChecks(
					ledger,
					it => `${it.narrative}`,
					(group, it, idx) => (!group[0].narrative ? "" : group.length > 1 ? `Duplicate Narrative (${idx + 1} of ${group.length})` : ""),
					ledgerData_SimpleMap
				),
			columnWidths: [8, 35, ...ledgerData_SimpleMap_ColumnWidths],
		},
		{
			name: "Missing or Unexpected Exp",
			rows: (ledger, expenses) => {
				const categories = [
					{
						name: "Fortnightly",
						regex: /\bFortnight(ly)?\b/im,
						expect: NaN, //These are likely to be seasonal so just flag for manual checking
					},
					{
						name: "Bi-annually",
						regex: /\bbi[\b\D]?annual|\btwice[\b\D](a[\b\D]|per[\b\D])?year|\bsummer[\b\D]|\bwinter|\b(6|six)\b.+month(ly)?/im,
						expect: parseInt(periodEnd.template.durationMonths / 6),
					},
					{
						name: "Annually",
						regex: /\bannual|\byear[^s][\b\D]?/im,
						expect: parseInt(periodEnd.template.durationMonths / 12),
					},
					{ name: "Quarterly", regex: /\bquarter(ly)?[^s]|\bqtr(ly)?[^s]/im, expect: parseInt(periodEnd.template.durationMonths / 3) },
					{
						name: "Quarterly",
						regex: /(\b|\d)Jan(uary)?.+Mar(ch)?(\b|\d)|(\b|\d)Feb(ruary)?(\b|\d).+Apr(il)?(\b|\d)|(\b|\d)Mar(ch)?(\b|\d).+May(\b|\d)|(\b|\d)Apr(il)?.+Jun(e)?(\b|\d)|(\b|\d)May(\b|\d).+Jul(y)?(\b|\d)|(\b|\d)Jun(e)?.+Aug(ust)?(\b|\d)|(\b|\d)Jul(y)?.+Sep(\b|\d)|(\b|\d)Jul(y)?.+Sept(ember)?(\b|\d)|(\b|\d)Aug(ust)?.+Oct(ober)?(\b|\d)|(\b|\d)Sep?.+Nov(ember)?(\b|\d)|(\b|\d)Sept(ember)?.+Nov(ember)?(\b|\d)|(\b|\d)Oct(ober)?.+Dec(ember)?(\b|\d)(\b|\d)Nov(ember)?.+Jan(uary)?(\b|\d)|(\b|\d)Dec(ember)?.+Feb(ruary)?(\b|\d)/im,
						expect: parseInt(periodEnd.template.durationMonths / 3),
					},
					{
						name: "Monthly",
						regex: /\bmonth[^s]/im,
						expect: periodEnd.template.durationMonths,
					},
					{
						name: "Monthly",
						regex: /(\b|\d)Jan(uary)?(\b|\d)|(\b|\d)Feb(ruary)?(\b|\d)|(\b|\d)Mar(ch)?(\b|\d)|(\b|\d)Apr(il)?(\b|\d)|(\b|\d)May(\b|\d)|(\b|\d)Jun(e)?(\b|\d)|(\b|\d)Jul(y)?(\b|\d)|(\b|\d)Aug(ust)?(\b|\d)|(\b|\d)Sep(\b|\d)|(\b|\d)Sept(ember)?(\b|\d)|(\b|\d)Oct(ober)?(\b|\d)|(\b|\d)Nov(ember)?(\b|\d)|(\b|\d)Dec(ember)?(\b|\d)/im,
						expect: periodEnd.template.durationMonths,
						debug: true,
					},
				]
				const categorisedLedger = ledger
					.map(it => {
						return {
							...it,
							category: categories.reduce((result, next) => (!result && next.regex.test(it.narrative) ? next : result), null),
						}
					})
					.filter(it => !!it.category)

				return reportGroupChecks(
					categorisedLedger,
					it => `${it.category?.name}|${it.account?.name}|${it.vendorName}`,
					(group, it, idx) =>
						group.length !== group[0].category.expect
							? `${group[0].category.name}: ${group[0].category.expect ? `expected ${group[0].category.expect}` : "manual check -"} got ${
									group.length
							  }`
							: "",
					ledgerData_SimpleMap
				)
			},
			columnWidths: [8, 35, ...ledgerData_SimpleMap_ColumnWidths],
		},
		{
			name: "Inv Date Out Of Range Check",
			rows: (ledger, expenses) => {
				const periodEndDateRange = PeriodEndDateRange(periodEnd)
				return expenses
					.map(expense => ({
						...expense,
						Comment: !expense.date
							? "Missing Date"
							: expense.date < periodEndDateRange.from
							? "Date is before Period"
							: expense.date >= periodEndDateRange.upTo
							? "Date is After Period"
							: ledger.find(ledgerLine => ledgerLine.expenseId === expense.id && expense.date < ledgerLine.date)
							? "Date is before Expenditure date"
							: "",
					}))
					.filter(it => it.Comment)
					.map(combineMaps(it => ({ Comment: it.Comment }), expensesData_Map))
			},
			columnWidths: [8, 40, ...expensesData_ColumnWidths],
		},
	]
	for (let check of expenditureChecks) appendCheckWorksheet(workbook, check, vouching)

	XLSX.writeFile(workbook, MakeSafeFileName(`${periodEnd.reference} - ${periodEnd.property.name} - Vouching Report.xlsx`))
}

function appendCheckWorksheet(workbook, check, vouching) {
	const data = check.rows(vouching.ledger, vouching.expenses)
	const worksheet = XLSX.utils.json_to_sheet(data, { cellDates: true })
	const columnFormats = deduceColumnsSpec(data)
	const rowFormats = deduceGroupRowsSpec(data)
	xlsx.formatSheet(worksheet, check.columnWidths || ledgerData_SimpleMap_ColumnWidths, [36], { ...columnFormats, ...rowFormats })

	XLSX.utils.book_append_sheet(workbook, worksheet, check.name)
}

const INTERNAL_ONLY_LEDGER_KEYS = [
	"id",
	"file",
	"lastModified",
	"row",
	"total",
	"accountId",
	"status",
	"account",
	"expense",
	"isSelected",
	"ref",
	"expenseId",
	"vendorName",
	"date",
	"narrative",
]

function expenditureWorksheet(vouching, periodEnd) {
	const userDataKeys = Config(periodEnd).vouchingReportUserColumns || []
	const originalDataKeys = _.union(
		userDataKeys,
		_.difference(getAllKeys(vouching.ledger), [
			...INTERNAL_ONLY_LEDGER_KEYS,
			_.keys(ledgerData_SimpleMap({ account: { name: "" } })),
			_.keys(ledgerData_OriginalValuesMap({})),
		])
	).sort(prioritisedSort(userDataKeys))

	const ledgerDataWithOriginalValuesMap = it => ({
		...ledgerData_SimpleMap(it),
		...ledgerData_MapAllKeys(originalDataKeys)(it),
		...ledgerData_OriginalValuesMap(it),
	})
	const ledgerDataWithOriginalValuesColumnWidths = [
		...ledgerData_SimpleMap_ColumnWidths,
		...originalDataKeys.map(() => 12),
		...ledgerData_OriginalValues_ColumnWidths,
	]

	const allLedgerItemsData = vouching.ledger.map(ledgerDataWithOriginalValuesMap)
	const allLedgerItems = XLSX.utils.json_to_sheet(allLedgerItemsData, { cellDates: true })
	const allLedgerItemsFormats = deduceColumnsSpec(allLedgerItemsData)
	xlsx.formatSheet(allLedgerItems, ledgerDataWithOriginalValuesColumnWidths, [36], allLedgerItemsFormats)

	return allLedgerItems
}

function invoicesWorksheet(vouching) {
	const allExpensesData = vouching.expenses.map(expensesData_Map)
	const allExpenses = XLSX.utils.json_to_sheet(allExpensesData, { cellDates: true })
	const allExpensesFormats = deduceColumnsSpec(allExpensesData)
	xlsx.formatSheet(allExpenses, expensesData_ColumnWidths, [36], allExpensesFormats)

	return allExpenses
}

const ledgerData_OriginalValuesMap = it => ({
	"Original Account": it.original?.account?.name,
	"Original Date": { v: it.original?.date ? new Date(it.original?.date) : undefined, t: "d" },
	"Original Narrative": it.original?.narrative,
	"Original Supplier": it.original?.vendorName,
	"Original Supplier Invoice": it.original?.ref,
	"Original Gross": formatCurrencyValue(it.original?.total),
})
const ledgerData_OriginalValues_ColumnWidths = [30, 10, 40, 35, 20, 10]

const ledgerData_SimpleMap = it => ({
	Status: STATUS_TITLE[it.status],
	Account: it.account?.name,
	Date: { v: it.date ? new Date(it.date) : undefined, t: "d" },
	Narrative: it.narrative,
	Supplier: it.vendorName,
	"Supplier Invoice": it.ref,
	Gross: formatCurrencyValue(it.total),
	"Invoice Amount": formatCurrencyValue(it.expense?.total),
	"Vouched Ref": expensePageRef(it.expense),
})
const ledgerData_SimpleMap_ColumnWidths = [20, ...[30, 10, 40, 35, 20, 10, 12, 50]]

function combineMaps(map1, map2, map3 = it => ({})) {
	return it => ({
		...map1(it),
		...map2(it),
		...map3(it),
	})
}

function ledgerData_MapAllKeys(keys) {
	return it => {
		const res = {}
		for (let key of keys) {
			res[key] = key.match(/\bdate\b|\bperiod\b|\bfrom\b|\bto\b/i) ? { v: it[key] ? new Date(it[key]) : undefined, t: "d" } : it[key]
		}
		return res
	}
}

const expensesData_Map = it => ({
	Status: STATUS_TITLE[it.status],
	Date: { v: it.date ? new Date(it.date) : undefined, t: "d" },
	Narrative: it.narrative,
	Supplier: it.vendorName,
	"Supplier Invoice": it.ref,
	Total: formatCurrencyValue(it.total),
	Split: it.Split,
	File: S3EntityPath(it.s3),
	Page: it.page,
	"To Page": it.toPage,
})
const expensesData_ColumnWidths = [20, 10, 40, 35, 20, 10, 10, 50, 10, 10]

function deduceColumnsSpec(data) {
	const columnNames = _.keys(data[0])

	const res = {
		[`A1:AZ1`]: xlsx.HEADER_CELL_FORMAT,
	}

	let i = 0
	for (let columnName of columnNames) {
		if (columnName.match(/^(net|vat|gross|total|original\stotal|original\sgross|Invoice Amount)$/i)) {
			res[`${xlsx.AllColumns[i]}2:${xlsx.AllColumns[i]}${data.length + 1}`] = xlsx.CURRENCY_CELL_FORMAT
		}

		if (columnName.match(/^(group)$/i)) {
			res[`${xlsx.AllColumns[i]}2:${xlsx.AllColumns[i]}${data.length + 1}`] = {
				alignment: {
					vertical: "center",
					horizontal: "center",
					wrapText: true,
				},
			}
		}

		i++
	}
	return res
}

function deduceGroupRowsSpec(data) {
	const lastColumn = String.fromCharCode(64 + _.keys(data[0]).length)
	const res = {}

	_.each(data, (row, idx) => {
		res[[`A${idx + 2}:${lastColumn}${idx + 2}`]] = {
			font: {
				color: {
					rgb: row.Group % 2 === 0 ? "FF00008B" : "FF005500",
				},
			},
		}
	})

	return res
}

export function expensePageRef(expense) {
	if (!expense) return ""
	if (expense.page === undefined) return expense

	const pageRef = expense.page === 1 ? "" : ` p${expense.page}`
	return `${AfterLast(expense?.s3, "/")}${pageRef}`
}

function reportGroupChecks(collection, groupBy, commentFunction, itemMap) {
	const groups = _.groupBy(collection, groupBy)
	const groupCount = { groupIdx: -1, commentGroupIdx: 0 }
	return _.keys(groups)
		.flatMap((key, groupIdx) =>
			groups[key].map((it, idx) => {
				let comment = commentFunction(groups[key], it, idx)
				if (comment && groupIdx > groupCount.groupIdx) {
					groupCount.commentGroupIdx = groupCount.commentGroupIdx + 1
				}
				groupCount.groupIdx = groupIdx
				return { Group: groupCount.commentGroupIdx, Comment: comment, ...it }
			})
		)
		.filter(it => it.Comment)
		.map(combineMaps(it => _.pick(it, ["Group", "Comment"]), itemMap))
}
