import React from "react"
import { connect } from "react-redux"

import { Row, Col, Card, CardHeader } from "../../../../components"
import { Link } from "react-router-dom"

import * as clearview from "../../../../components/@Clearview"

function Workstreams({ user }) {
	if (!user || !user.isInHouse || !clearview.User.IsAdmin(user)) return
	return (
		<Row>
			<Col lg={4}>
				<ClearviewWorkstreamInfo />
			</Col>

			<Col lg={4}>
				<InfrastructureWorkstreamInfo />
			</Col>

			<Col lg={4}>
				<RoadMapWorkstreamInfo />
			</Col>
		</Row>
	)
}

const mapStateToProps = state => {
	const { userReducer } = state
	return {
		user: userReducer.user || clearview.User.UnauthenticatedUser,
	}
}

export default connect(mapStateToProps)(Workstreams)

function ClearviewWorkstreamInfo() {
	return (
		<Link to="/workstream/clearview" className="hover">
			<Card className="mb-3">
				<CardHeader tag="h6">{clearview.Icon.workStream} Clearview Workstream</CardHeader>
			</Card>
		</Link>
	)
}

function InfrastructureWorkstreamInfo() {
	return (
		<Link to="/workstream/infrastructure" className="hover">
			<Card className="mb-3">
				<CardHeader tag="h6">{clearview.Icon.workStream} Infrastructure Workstream</CardHeader>
			</Card>
		</Link>
	)
}

function RoadMapWorkstreamInfo() {
	return (
		<Link to="/workstream/roadmap" className="hover">
			<Card className="mb-3">
				<CardHeader tag="h6">{clearview.Icon.workStream} Road Map / Phase 2</CardHeader>
			</Card>
		</Link>
	)
}
