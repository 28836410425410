import React from "react"
import { connect } from "react-redux"

import * as clearview from "../../../../../components/@Clearview"

import { Table } from "../../../../../components"

function FeeSummary({ fees, client }) {
	if (!fees) return <p className="text-info">There are no fees applicable to the current data.</p>

	return (
		<Table className="mt-4 compact-table compact-rows">
			<tbody>
				<tr>
					<th rowSpan={fees.schedules.length + 1} style={{ verticalAlign: "top" }}>
						Schedules ({fees.schedules.length})
					</th>
					<td></td>
				</tr>
				{fees.schedules.map((it, idx) => (
					<tr key={`fee_${idx}`}>
						<td>{it}</td>
					</tr>
				))}
				<tr>
					<th>Expenditure</th>
					<td className="currency">{clearview.FormatCurrency(fees.expenditure)}</td>
				</tr>
				<tr>
					<th>{client.name} Fee</th>
					<td className="currency">{clearview.FormatCurrency(fees.calculated?.total)}</td>
				</tr>
			</tbody>
		</Table>
	)
}

const mapStateToProps = state => {
	return {
		fees: state.workingPapersReducer.workingPapers.fees,
		client: state.workingPapersReducer.workingPapers.client,
	}
}

FeeSummary = connect(mapStateToProps)(FeeSummary)

export { FeeSummary }
