import React from "react"
import { toast } from "react-toastify"

import _ from "lodash"
import moment from "moment"

import { v4 as uuid } from "uuid"

import * as ACTIONS from "../../store/actions"
import { Button, Badge, Media, UncontrolledModal } from ".."

import config from "../../config"
import { PeriodEndMeta } from "./PeriodEnd"

export const TEXTRACT_KNOWN_FIELD_TYPES = [
	"VENDOR_NAME",
	"TOTAL",
	"RECEIVER_ADDRESS",
	"INVOICE_RECEIPT_DATE",
	"INVOICE_RECEIPT_ID",
	"PAYMENT_TERMS",
	"SUBTOTAL",
	"DUE_DATE",
	"TAX",
	"TAX_PAYER_ID",
	"ITEM_NAME",
	"PRICE",
	"QUANTITY",
]

export function ToInitials(name, delimiter = ",") {
	if (!name) return name

	return name
		.replace(/[^a-zA-Z- ]/g, "")
		.match(/\b\w/g)
		.join(delimiter)
}

export function ValidNameCharacters(e) {
	var badEx = /[$&+,:;=?[\]\\@#|{}'<>^*%!/]/
	if (badEx.test(e.key)) {
		e.preventDefault()
	}
}

export function ValidRefCharacters(e) {
	var badEx = /[\s$&+,:;=?[\]\\@#|{}'<>^*%!/]/
	if (badEx.test(e.key)) {
		e.preventDefault()
	}
}

export function BytesToString(bytes) {
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

	if (bytes === 0) {
		return "n/a"
	}

	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

	if (i === 0) {
		return bytes + " " + sizes[i]
	}

	return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i]
}

export function UnixTimestamp(when) {
	return Math.floor(((when || Date.now()) - new Date(1970, 1, 1)) / 1000)
}

//https://attacomsian.com/blog/javascript-convert-query-string-to-object
export function QueryObject(querystring) {
	const params = new URLSearchParams(querystring)

	const obj = {}

	for (const key of params.keys()) {
		if (params.getAll(key).length > 1) {
			obj[key] = params.getAll(key)
		} else {
			obj[key] = params.get(key)
		}
	}

	return obj
}

export function QueryString(...args) {
	const obj = new URLSearchParams()
	while (args.length) {
		obj.append(args.shift(), args.shift())
	}

	return `?${obj.toString()}`
}

export function ActionButtons(...args) {
	const buttons = args.map((it, idx) => (
		<Button
			key={`a_${idx}`}
			disabled={it.disabled}
			// style={{ height: "fit-content" }}
			outline={!it.isDefault}
			color={it.color || "primary"}
			title={it.title}
			onClick={e => {
				StopPropagation(e)
				it.onClick(e)
			}}
		>
			{it.label}
		</Button>
	))

	return <div className="clearview-action-buttons">{buttons}</div>
}

export function Checkbox(value, labels = ["true", "false", "undefined"], options = { readonly: false, disabled: false }) {
	return (
		<input
			type="checkbox"
			checked={value}
			readOnly={options.readonly}
			disabled={options.disabled}
			title={labels[value === true ? 0 : value === false ? 1 : 2]}
		/>
	)
}

const businessTitleMaps = {
	Property: it => `${it.reference}: ${it.name}`,
	Client: it => `${it.reference.startsWith("@") ? it.name : it.reference + ": " + it.name}`,
	Customer: it => it.name,
	Root: it => it.name,
	default: it => `${it.reference}: ${it.name}`,
}

export function GetBusinessTitle(business) {
	if (!business) return
	return (businessTitleMaps[business.type] || businessTitleMaps.default)(business)
}

export function GetEntityType(entity) {
	const entityTypeMap = {
		Root: "client",
		Customer: "client",
		Client: "client",
		"Managing Agent": "client",
		Landlord: "client",
		Property: "property",
		DraftQuery: "query",
		Query: "query",
		ClosedQuery: "query",
		S3: "file",
	}

	if (entity.type) return entityTypeMap[entity.type]

	if (entity.template) return "periodEnd"
	if (entity.templateStage) return "stage"

	console.warn("No EntityType for", entity)
}

export function Client(user) {
	return user.isInHouse ? Them(user) : Us(user)
}
export function Customer(user) {
	return user.isInHouse ? Us(user) : Them(user)
}

export function CustomerFor(business) {
	let customer = business
	while (customer.type !== "Customer") {
		customer = customer.parent
	}
	return customer
}

export function TopClientFor(property) {
	let parent = property.parent
	while (!["Client", "Managing Agent"].includes(parent.role)) {
		parent = parent.parent
	}
	return parent
}

export function ClientFor(business) {
	let client = business
	while (client.parent.type !== "Customer") {
		client = client.parent
	}
	return client
}

export function CustomerAlt(customerOrRef, id, defaultValue) {
	const reference = customerOrRef.reference || customerOrRef
	const alts = config[reference].alts
	return alts?.[id] || defaultValue
}

export function Config(periodEndOrCustomerOrRef) {
	let reference = periodEndOrCustomerOrRef.reference || periodEndOrCustomerOrRef
	if (periodEndOrCustomerOrRef.property) reference = PeriodEndMeta(periodEndOrCustomerOrRef)?.Customer?.reference
	return config[reference]
}

export function Us(user) {
	return _.find(user.teams, it => it.type === "Users").business
}
export function Them(user) {
	if (user.isInHouse) return { name: "Client" }
	return user.brand
}

export function ShortName(entity, length) {
	if (!entity) return entity
	if (entity.name.length <= length) return entity.name
	if (entity.reference) {
		var readableReference = entity.reference.match(/[a-zA-Z0-9\s]+/g)
		if (readableReference.length <= length) return readableReference
	}
	return _.startCase(entity.name).replace("_", "").substring(0, length)
}

export function GenerateKey() {
	return uuid()
}

const actorDescriptionMaps = {
	Root: it => `${it.name} (System User)`,
	Customer: it => `${it.business}`,
	Client: it => `${it.business}`,
	Team: it => `${it.business} / ${it.name}`,
	User: it => (it.business === it.team.name ? `${it.name} @ ${it.business}` : `${it.name} @ ${it.business} / ${it.team.name}`),
	default: it => `${it.business}`,
}

export function GetActorDescription(actor) {
	return (actorDescriptionMaps[actor.type] || actorDescriptionMaps.default)(actor)
}

export function TeamGrid(teams) {
	var usersTeam = _.find(teams, it => it.type === "Users") || { users: [] }

	return usersTeam.users.map(it => ({
		userId: it.id,
		user: it,
		isTeamContact: teams.reduce((agg, t) => {
			agg[t.username] = (_.find(t.users, u => u.id === it.id) || { isContact: false }).isContact
			return agg
		}, {}),

		teamRole: teams.reduce((agg, t) => {
			agg[t.username] = (_.find(t.users, u => u.id === it.id) || { role: "" }).role
			return agg
		}, {}),
	}))
}

export function ScopeGrid(teams, business) {
	var businesses = []
	ACTIONS.crawlBranches(business.children, it => businesses.push(it))

	return businesses.map(it => ({
		businessId: it.id,
		business: it,
		teamScopes: (teams || [])
			.filter(it => ["InHouseTeam", "ClientTeam"].includes(it.type))
			.reduce((agg, t) => {
				agg[t.username] = _.find(t.businessIds, b => b === it.id) || false
				return agg
			}, {}),
	}))
}

export const StopPropagation = (e, e1) => {
	const ev = e1 && e1.originalEvent ? e1.originalEvent : e

	ev.stopPropagation()
	ev.preventDefault()
	return false
}

// Apply to onKeyDown to allow only paste functionality
export const OnlyPaste = e => OnlyKeys(["Ctrl+KeyV"], e)

export const OnlyKeys = (keys, e) => {
	const keyCode = (e.ctrlKey ? "Ctrl+" : "") + e.code
	if (!keys.includes(keyCode)) {
		StopPropagation(e)
		return false
	}
	return keyCode
}

export const DeleteProperties = (obj, predicate) => {
	Object.keys(obj).forEach(key => {
		if (predicate(obj[key])) {
			delete obj[key]
		}
	})
}

export function LoadLocal(key, defaultValue) {
	const value = JSON.parse(localStorage.getItem(key))
	if (value) return value

	localStorage.setItem(key, JSON.stringify(defaultValue))
	return defaultValue
}

export function SaveLocal(key, value) {
	localStorage.setItem(key, JSON.stringify(value))
}

export function FormatDate(stringValue, format = "DD/MM/YYYY", defaultIfBadDate = false) {
	const myMoment = moment(stringValue)
	if (!myMoment._isValid && !!defaultIfBadDate) return defaultIfBadDate
	return myMoment.format(format)
}

export function FormatDateAndTime(stringValue, format = "DD-MMM-YYYY HH:mm", defaultIfBadDate = false) {
	const myMoment = moment(stringValue).local()
	if (!myMoment._isValid && !!defaultIfBadDate) return defaultIfBadDate
	if (myMoment.isDST()) myMoment.add(1, "hour")
	return myMoment.format(format)
}

export function DateBadge(stringValue, options) {
	var formattedDate = FormatDate(stringValue)
	return (
		<Badge title={formattedDate} className={options?.className}>
			{formattedDate}
		</Badge>
	)
}

export function AfterFirst(stringValue, delimiter) {
	stringValue = `${stringValue}`
	const delimiterPos = stringValue.indexOf(delimiter)
	return delimiterPos < 0 ? undefined : stringValue.substring(delimiterPos + delimiter.length)
}

export function AfterLast(stringValue, delimiter) {
	stringValue = `${delimiter}${stringValue}`
	return stringValue.substring(stringValue.lastIndexOf(delimiter) + delimiter.length)
}

export function BeforeFirst(stringValue, delimiter) {
	if (!stringValue) return stringValue
	const pos = stringValue.indexOf(delimiter)
	return pos >= 0 ? stringValue.substring(0, pos) : null
}

export function BeforeLast(stringValue, delimiter) {
	if (!stringValue) return stringValue
	const pos = stringValue.lastIndexOf(delimiter)
	return pos >= 0 ? stringValue.substring(0, pos) : null
}

export function TemplateDurationName(template) {
	switch (template.durationMonths) {
		case 12:
			return "year"
		case 3:
			return "quarter"
		default:
			return `${template.durationMonths} months`
	}
}

export function S3EntityPath(s3) {
	if (!s3) return s3
	const parts = s3.substring(5).split("/")
	return parts.slice(3).join("/")
}

export const CURRENCY_OPTIONS = {
	significantDigits: 2,
	thousandsSeparator: ",",
	decimalSeparator: ".",
	symbol: "£",
}

export const parseCurrency = value => {
	const regex = /[^-?[0-9]*]*(-)?([0-9]*)\.?([0-9])?([0-9])?([0-9])?/ //Read to 3dp in order to round correctly
	const match = value?.replace(",", "").match(regex)
	if (!match) return

	const neg = match[1] === "-" ? -1 : +1

	if (isNaN(parseInt(match[2]))) return match[1] || ""
	if (isNaN(parseInt(match[3]))) return neg * parseInt(match[2]) * 100
	if (isNaN(parseInt(match[4]))) return neg * (parseInt(match[2]) * 100 + parseInt(match[3]) * 10)
	if (isNaN(parseInt(match[5]))) return neg * (parseInt(match[2]) * 100 + parseInt(match[3]) * 10 + parseInt(match[4]))
	return neg * (parseInt(match[2]) * 100 + parseInt(match[3]) * 10 + parseInt(match[4]) + (parseInt(match[5]) >= 5 ? 1 : 0))
}

export const roundCurrencyInput = (value, defaultValue = false) => {
	if (isNothing(value) && defaultValue !== false) return defaultValue
	if (isNothing(value)) return value
	return Math.round(value * 100) / 100
}

export const currencyOutput = (value, defaultValue = false) => {
	const isBad = !_.isNumber(value) || _.isNaN(value)
	if (isBad && defaultValue !== false) return defaultValue
	if (isBad) return value
	return parseFloat(_.round((value / 100).toFixed(CURRENCY_OPTIONS.significantDigits), 2))
}

export const currencyInput = (value, defaultValue = false) => {
	const isBad = !_.isNumber(value) || _.isNaN(value)
	if (isBad && defaultValue !== false) return defaultValue
	if (isBad) return value
	return Math.round(value * 100)
}

export const formatCurrencyValue = (value, defaultValue = false) => {
	const isBad = !_.isNumber(value) || _.isNaN(value)
	if (isBad && defaultValue !== false) return defaultValue
	if (isBad) return value
	return parseFloat(_.round((value / 100).toFixed(CURRENCY_OPTIONS.significantDigits), 2)).toFixed(CURRENCY_OPTIONS.significantDigits)
}

export const formatCurrency = (value, currencySymbol = undefined, negativeBrackets = false) => {
	if (!_.isNumber(value)) return undefined

	value = (value / 100).toFixed(CURRENCY_OPTIONS.significantDigits)
	const [currency, decimal] = value.split(".")

	const prefix = currencySymbol === undefined ? CURRENCY_OPTIONS.symbol : !currencySymbol ? "" : currencySymbol

	if (negativeBrackets && value < 0)
		return `(${prefix} ${currency.substring(1).replace(/\B(?=(\d{3})+(?!\d))/g, CURRENCY_OPTIONS.thousandsSeparator)}${
			CURRENCY_OPTIONS.decimalSeparator
		}${decimal} )`
	else return `${prefix} ${currency.replace(/\B(?=(\d{3})+(?!\d))/g, CURRENCY_OPTIONS.thousandsSeparator)}${CURRENCY_OPTIONS.decimalSeparator}${decimal}`
}

export const FormatCurrency = (value, className = null, currencySymbol = undefined, negativeBrackets = false) => {
	const myClassName = className || (value < 0 ? "currency negative" : value === 0 ? "currency zero" : "currency positive")
	if (!_.isNumber(value)) return <span className={myClassName}>-</span>

	return <span className={myClassName}>{formatCurrency(value, currencySymbol, negativeBrackets)}</span>
}

export const MakeSafeFolderId = value => {
	if (!value) return value
	return value.replace(/\W/g, "-")
}

export const MakeSafeFileName = (value, subst = " ") => {
	if (!value) return value
	return value.replace(/[\\\/"<>|]/g, subst).replace(/[:.\*\?]/g, ".") /* eslint-disable-line */
}

export const AsFolders = value => {
	if (!value) return value
	return value.split("/").filter(it => it?.length)
}

export const DateToString = value => {
	if (!value) return value

	if (_.isDate(value)) {
		return value.toLocaleDateString("af")
	} else if (_.isNumber(value)) {
		const date = new Date(1900, 0, 0)
		date.setDate(date.getDate() + parseInt(value))
		return date.toLocaleDateString("af")
	} else {
		const parts = value.match(/(\d{1,4})/g)
		if (!parts) return null

		const newDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]))
		return newDate.toLocaleDateString("af")
	}
}

export const dateTimeToString = value => (
	<span title={value.toString()}>
		{value.toLocaleDateString("af")} {value.toLocaleTimeString("af").substring(0, 5)}
	</span>
)

const timeToString = value => <span title={value.toString()}>{value.toLocaleTimeString("af").substring(0, 5)}</span>

export const DateTimeToString = value => {
	if (!value) return value

	if (_.isDate(value)) {
		return dateTimeToString(value)
	} else if (_.isNumber(value)) {
		const date = new Date(1900, 0, 0)
		date.setDate(date.getDate() + parseInt(value))
		return dateTimeToString(date)
	} else {
		const parts = value.match(/(\d{1,4})/g)
		if (!parts) return null

		const newDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]))
		return dateTimeToString(newDate)
	}
}

export const TimeToString = value => {
	if (!value) return value

	if (_.isDate(value)) {
		return timeToString(value)
	} else if (_.isNumber(value)) {
		const date = new Date(1900, 0, 0)
		date.setDate(date.getDate() + parseInt(value))
		return timeToString(date)
	} else {
		const parts = value.match(/(\d{1,4})/g)
		if (!parts) return null

		const newDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]))
		return timeToString(newDate)
	}
}

export const ToNumber = value => {
	if ([null, undefined].includes(value)) return value

	const number = _.toNumber(value)
	return !isNaN(number) ? number : null
}

export const SafeTrim = value => {
	if (!value) return value
	return `${value}`.trim()
}

export const Truncate = (value, maxLength = 80) => {
	if (!value) return value
	value = `${value}`.trim()
	if (value.length < maxLength) return value
	return value.substring(0, maxLength - 3) + "..."
}

export const TruncateHtml = (value, maxLength = 80) => {
	if (!value) return value
	value = `${value}`.replace(/(<([^>]+)>)/gi, "").trim()
	return Truncate(value, maxLength)
}

export const PathPart = (elementNumber, defaultValue) => {
	const parts = PathParts()
	if (!parts) return parts
	if (parts.length <= elementNumber) return defaultValue
	return parts[elementNumber]
}

export const PathParts = _ => {
	return window.location.pathname.split("/").map(it => decodeURIComponent(it))
}

export const DismissToast = toastId => {
	toast.dismiss(toastId)
}

export const ShowToast = (
	typeOptionalTitle,
	content,
	autoClose = undefined,
	toastId = undefined,
	dismissToastId = undefined,
	showCloseButton = true,
	onClose = undefined
) => {
	toastId = toastId || uuid()
	let [type, title] = typeOptionalTitle?.split(":", 2)
	type = type.toLowerCase()
	if ([null, undefined].includes(autoClose)) autoClose = type === "error" ? 15000 : 3000

	const options = {
		type,
		autoClose,
		toastId,
		icon: false,
		closeButton: showCloseButton && <i className="fa fa-fw fa-1x fa-close text-secondary"></i>,
		onClose: onClose,
	}

	const className = {
		info: "text-primary",
		success: "text-success",
		warning: "text-warning",
		error: "text-danger",
		default: "text-dark",
		undefined: "text-dark",
	}[type]

	const icon = {
		info: <i className="fa fa-fw fa-2x fa-info-circle text-primary"></i>,
		success: <i className="fa fa-fw fa-2x fa-check-circle text-success"></i>,
		warning: <i className="fa fa-fw fa-2x fa-exclamation-triangle text-warning"></i>,
		error: <i className="fa fa-fw fa-2x fa-exclamation-circle text-danger"></i>,
		default: <i className="fa fa-fw fa-2x fa-exclamation text-dark"></i>,
		undefined: <i className="fa fa-fw fa-2x fa-exclamation text-dark"></i>,
	}[type]

	const heading =
		title ||
		{
			info: "Information",
			success: "Success",
			warning: "Warning!",
			error: "Error!",
			default: undefined,
			undefined: undefined,
		}[type]

	const body = ({ closeToast }) => (
		<Media>
			<Media middle left className="mr-3">
				{icon}
			</Media>
			<Media body>
				{heading && (
					<Media heading tag="h6" className={className}>
						{heading}
					</Media>
				)}
				{content}
			</Media>
		</Media>
	)

	if (dismissToastId) toast.dismiss(dismissToastId)

	switch (type) {
		case "info":
			toast.info(body, options)
			break
		case "success":
			toast.success(body, options)
			break
		case "warning":
			toast.warn(body, options)
			break
		case "error":
			toast.error(body, options)
			break
		default:
			toast.info(body, options)
	}

	return toastId
}

export const sortCaret = order => {
	if (order) return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
	else return <i className="fa fa-fw fa-sort text-muted"></i>
}

export const multiSortCaret = multiSort => {
	if (multiSort?.order)
		return [<i key={GenerateKey()} className={`fa fa-fw text-muted fa-sort-${multiSort.order}`}></i>, <sup key={GenerateKey()}>{multiSort.priority}</sup>]
	else return <i key={GenerateKey()} className="fa fa-fw fa-sort text-muted"></i>
}

export const QuerySubjects = ["Query", "Expenditure", "Income", "Balance Sheet", "Missing Information", "Missing Invoices"]
export const QueryAttachmentsFolders = {
	null: "",
	undefined: "",
	"": "",
	Query: "",
	Expenditure: "",
	Income: "",
	"Balance Sheet": "",
	"Missing Information": "",
	"Missing Invoices": "",
}

export const queryFolder = (stage, comment) =>
	`/periodEnd/${stage.periodEnd.id}${QueryAttachmentsFolders[comment?.subject]}/S${stage.templateStage.sequence
		.toString()
		.padStart(2, "0")}/Q${comment?.number.toString().padStart(2, "0")}`

export const editPreferencesUrl = (user, client, flavourName) => {
	const actions = client.actions || []

	switch (flavourName) {
		case "textract-analysis-rules":
			if (actions.includes("SetProperties") && client.type === "Customer" && user.isInHouse) return `/preferences/${flavourName}/${client.id}`
			break

		case "vendor-aliases":
			if (actions.includes("SetProperties") && client.parent?.type === "Customer" && user.isInHouse) return `/preferences/${flavourName}/${client.id}`
			break

		case "working-papers-fee-matrix":
			if (actions.includes("SetProperties") && client.parent?.type === "Customer" && user.isInHouse) return `/preferences/${flavourName}/${client.id}`
			break

		default:
			break
	}
}

export const caseInsensitiveSort = (a, b) => (a?.toString().toUpperCase() > b?.toString().toUpperCase() ? 1 : -1)

export function prioritisedSort(priorities) {
	const priorityLookup = _.reduce(
		priorities,
		(prev, next, idx) => {
			prev[next] = idx + 1
			return prev
		},
		{}
	)

	return (a, b) => {
		if (!priorityLookup[a] && !priorityLookup[b]) return caseInsensitiveSort(a, b)
		if (!!priorityLookup[a] && !priorityLookup[b]) return -1
		if (!priorityLookup[a] && !!priorityLookup[b]) return 1

		if (priorityLookup[a] < priorityLookup[b]) return -1
		return 1
	}
}

export const periodEndSort = (a, b) => (a.endDate == b.endDate ? (a.reference > b.reference ? 1 : -1) : a.endDate > b.endDate ? 1 : -1)
export const periodEndSortDesc = (a, b) => (a.endDate == b.endDate ? (a.reference > b.reference ? 1 : -1) : a.endDate > b.endDate ? -1 : 1)

export const orderBy = fn => (a, b) => fn(a) > fn(b) ? 1 : -1

export const PeriodEndDateRange = periodEnd => {
	const fromDate = new Date(periodEnd.endDate)
	fromDate.setMonth(fromDate.getMonth() - periodEnd.template.durationMonths)
	fromDate.setDate(fromDate.getDate() + 1)

	let upToDate = new Date(periodEnd.endDate)
	upToDate.setDate(upToDate.getDate() + 1)

	return { from: DateToString(fromDate), upTo: DateToString(upToDate) }
}

// return a promise
export function copyToClipboard(textToCopy) {
	// navigator clipboard api needs a secure context (https)
	if (navigator.clipboard && window.isSecureContext) {
		// navigator clipboard api method'
		return navigator.clipboard.writeText(textToCopy)
	} else {
		// text area method
		let textArea = document.createElement("textarea")
		textArea.value = textToCopy
		// make the textarea out of viewport
		textArea.style.position = "fixed"
		textArea.style.left = "-999999px"
		textArea.style.top = "-999999px"
		document.body.appendChild(textArea)
		textArea.focus()
		textArea.select()
		return new Promise((res, rej) => {
			// here the magic happens
			document.execCommand("copy") ? res() : rej()
			textArea.remove()
		})
	}
}

export function getAllKeys(array) {
	let keys = []
	for (let datum of array || []) {
		keys = _.uniq([...keys, ..._.keys(datum)])
	}
	return keys
}

export function isNothing(value) {
	return [undefined, null, NaN].includes(value)
}

export function isNothingOrEmpty(value) {
	return [undefined, null, NaN, ""].includes(value)
}

export function ifSomething(value, thenValue) {
	return isNothing(value) ? value : thenValue
}

export function ifNothing(value, elseValue) {
	return isNothing(value) ? elseValue : value
}

const tidyBooleanOptions = { true: <i className="fa fa-fw fa-check-square-o text-primary"></i>, false: <i className="fa fa-fw fa-square-o text-secondary"></i> }
const tidyWith = {
	stringOr: (it, defaultIfEmpty = "-") => it?.toString() || defaultIfEmpty,
	dateOr: (it, defaultIfEmpty = "-") =>
		_.isDate(it) ? (
			<span className="nowrap">{it.toLocaleDateString("af").substring(0, 10)}</span>
		) : Date.parse(it) ? (
			<span className="nowrap">{new Date(it).toLocaleDateString("af").substring(0, 10)}</span>
		) : (
			it || defaultIfEmpty
		),
	numberOr: (it, defaultIfEmpty = "-") => (isNothing(it) ? defaultIfEmpty : it.toString()),
	currencyOr: (it, defaultIfEmpty = "-") => (isNothing(it) ? defaultIfEmpty : FormatCurrency(it, null, false, true)),
	booleanOr: (it, defaultIfEmpty = "-") => (isNothing(it) ? defaultIfEmpty : tidyBooleanOptions[it]),
}

export const tidy = {
	...tidyWith,
	markup: it => (_.isString(it) ? tidyWith.stringOr(it, "") : it),
	string: it => tidyWith.stringOr(it, ""),
	date: it => tidyWith.dateOr(it, ""),
	number: it => tidyWith.numberOr(it, ""),
	currency: it => tidyWith.currencyOr(it, ""),
	boolean: it => tidyWith.booleanOr(it, tidyBooleanOptions[false]),
}

export const addIdx = (objectOrArray, idxPropertyName = "idx", extraPropsMap = null) => {
	if (objectOrArray) {
		if (_.isArray(objectOrArray))
			objectOrArray.forEach((it, idx) => {
				it[idxPropertyName] = idx
				if (extraPropsMap) {
					const extraProps = extraPropsMap(it)
					_.keys(extraProps).forEach(extraPropName => (it[extraPropName] = extraProps[extraPropName]))
				}
			})
		else
			_.keys(objectOrArray).forEach((key, idx) => {
				objectOrArray[key][idxPropertyName] = idx
				if (extraPropsMap) {
					const extraProps = extraPropsMap(objectOrArray[key])
					_.keys(extraProps).forEach(extraPropName => (objectOrArray[key][extraPropName] = extraProps[extraPropName]))
				}
			})
	}

	return objectOrArray
}

export const sumAny = (...args) => (_.some(args, it => !isNothing(it)) ? _.sum(args.filter(it => !isNothing(it))) : NaN)

export const SummaryBy = (arr, groupByPredicate, rowTotal = it => it.total, summaryMapper = it => it) => {
	if (!arr || !arr.length) return arr

	const grouped = _.groupBy(arr, it => JSON.stringify(groupByPredicate(it)))
	const summary = _.keys(grouped).map(key => {
		const keyValue = JSON.parse(key)
		const summaryValue = grouped[key].reduce((prev, next) => ({ count: prev.count + 1, total: prev.total + rowTotal(next) }), { count: 0, total: 0 })
		return {
			...keyValue,
			...summaryMapper(summaryValue),
		}
	})

	return summary
}

export const listErrors = (errors, isHidden = false) => (
	<ul className="list-errors" hidden={isHidden}>
		{errors.map((it, idx) => (
			<li key={idx}>{it}</li>
		))}
	</ul>
)

export const listProps = (entity = {}, excludeProps = []) => {
	const propsObject = _.difference(_.keys(entity), excludeProps).reduce(
		(prev, nextKey) => {
			prev[nextKey] = entity[nextKey]
			return prev
		},

		{}
	)

	const json = JSON.stringify(propsObject, null, 2).slice(2, -2)

	return <pre>{json}</pre>
}

export const CloseModal = (
	<UncontrolledModal.Cancel title="Close" color="link" className="text-primary close-modal-icon">
		<i className="fa fa-fw fa-close" />
	</UncontrolledModal.Cancel>
)

export function sumValues(...args) {
	return args.reduce((total, value) => {
		if (_.isNumber(value) && !_.isNaN(value)) return (total || 0) + value
		return total
	}, undefined)
}

export function includesObject(values, value, propertyPredicate) {
	return !!(value && values.find(it => propertyPredicate(it) === propertyPredicate(value)))
}

export function arraysContainSameElements(arr1, arr2) {
	if (!_.isArray(arr1) || !_.isArray(arr2)) return false
	if (arr1.length !== arr2.length) return false
	return _.intersection(arr1, arr2).length === arr1.length
}

export function firstKey(it) {
	if (!it) return it
	return _.keys(it)[0]
}

export function firstValue(it) {
	if (!it) return it
	return _.values(it)[0]
}

export function doesUpdate(it, keyValues) {
	return _.keys(keyValues).reduce((didUpdate, key) => {
		if (it[key] !== keyValues[key]) {
			it[key] = keyValues[key]
			return true
		}
		return didUpdate
	}, false)
}
