import React from "react"
import { Link } from "react-router-dom"

import { Sidebar } from "../"

import { SidebarMiddleNav } from "./SidebarMiddleNav"

import { SidebarTopA } from "../../routes/components/Sidebar/SidebarTopA"
import { SidebarBottomA } from "../../routes/components/Sidebar/SidebarBottomA"
import { LogoThemed } from "./LogoThemed/LogoThemed"

import { Consumer } from "../context"

const onHomeClick = context => {
	context.setPreFilter([], { periodEndStatus: "Open" }, true)
}

export const DefaultSidebar = () => (
	<Consumer>
		{context => {
			return (
				<Sidebar>
					{/* <Sidebar.Close>
						<SidebarTrigger tag={"a"} href="#">
							<i className="fa fa-times-circle fa-fw"></i>
						</SidebarTrigger>
					</Sidebar.Close> */}

					<Sidebar.HideSlim>
						<Sidebar.Section>
							<Link to="/" className="sidebar__brand" onClick={() => onHomeClick(context)}>
								<LogoThemed checkBackground />
							</Link>
						</Sidebar.Section>
					</Sidebar.HideSlim>

					<Sidebar.MobileFluid>
						<SidebarTopA />

						<Sidebar.Section fluid cover>
							<SidebarMiddleNav />
						</Sidebar.Section>

						<SidebarBottomA />
					</Sidebar.MobileFluid>
				</Sidebar>
			)
		}}
	</Consumer>
)
