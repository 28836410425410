import { combineReducers } from "redux"

import clientsReducer from "./clientsReducer"
import userReducer from "./userReducer"
import toastReducer from "./toastReducer"
import reportsReducer from "./reportsReducer"
import filesReducer from "./filesReducer"
import workflowReducer from "./workflowsReducer"
import { workingPapersReducer } from "../../routes/Dashboards/PeriodEnds/WorkingPapers/store"

const rootReducer = combineReducers({
	clientsReducer,
	userReducer,
	toastReducer,
	reportsReducer,
	filesReducer,
	workflowReducer,
	workingPapersReducer,
})

export default rootReducer
