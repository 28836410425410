import React from "react"
import { connect } from "react-redux"
import _ from "lodash"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"
import PropTypes from "prop-types"
import { Typeahead } from "react-bootstrap-typeahead"
// import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle"
import DateRangePicker from "@wojtekmaj/react-daterange-picker"

import { Label, UncontrolledModal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, InputGroup, InputGroupAddon } from "../../../../components"

import { LookupConstants } from "../../../Dashboards/components/PeriodEndFilter"

const NoFilter = {
	client: undefined,
	property: undefined,
	templateFamily: undefined,
	template: undefined,
	periodEndStatus: undefined,
	periodEndRag: undefined,
	stageStatus: undefined,
	stageRag: undefined,
	endDateRange: undefined,
	dueDateRange: undefined,
}

class ReportParamsModal extends React.Component {
	static propTypes = {
		target: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		action: PropTypes.func.isRequired,
		properties: PropTypes.array.isRequired,
		templateCustomers: PropTypes.array.isRequired,
		disabledParams: PropTypes.object,
		defaultValues: PropTypes.object,
	}

	constructor(props) {
		super(props)

		this.state = {
			filter: {
				...NoFilter,
				...props.defaultValues,
			},
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleDateRangeChange = this.handleDateRangeChange.bind(this)
	}

	handleChange(option, type) {
		this.setState({
			filter: {
				...this.state.filter,
				[type]: _.first(option),
			},
		})
	}

	handleDateRangeChange(value, type) {
		this.setState({
			filter: {
				...this.state.filter,
				[type]: value,
			},
		})
	}

	render() {
		const { target, title, subTitle, action } = this.props
		const disabledParams = this.props.disabledParams || {}

		const properties = _.sortBy(
			this.props.properties.map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
			it => it._title
		)

		const clients = _.sortBy(
			_.uniqBy(
				this.props.properties
					.map(it => it.parent)
					.concat(this.props.properties.map(it => it.parent.parent))
					.concat(this.props.properties.map(it => it.parent.parent.parent))
					.filter(it => it),
				it => it.id
			).map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
			it => it._title
		)

		const multiCustomer = !!(this.props.templateCustomers.length > 1)

		const templateFamilies = _.sortBy(
			_.uniq(
				this.props.templateCustomers
					.flatMap(it => it.templates)
					.map(it => it.family)
					.filter(it => it)
			).map(it => ({ value: it, _title: `${it}` })),
			it => it._title
		)
		const templates = _.sortBy(
			this.props.templateCustomers
				.flatMap(it => it.templates)
				.map(it => ({ ...it, _title: `${multiCustomer ? it.customer.reference + ": " : ""}${it.reference}: ${it.name}` })),
			it => it._title
		)

		return (
			<UncontrolledModal target={target}>
				<Form>
					<ModalHeader tag="div">
						<div className="d-flex">
							<h1 className="text-primary mr-2">
								<i className="fa fa-fw fa-table"></i>
							</h1>
							<h6 className="d-flex" style={{ flexDirection: "column" }}>
								<b>{title}</b>
								{subTitle}
							</h6>
						</div>
					</ModalHeader>

					<ModalBody>
						<FormGroup row>
							<InputGroup disabled={disabledParams.client}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.Business.Client}</InputGroupAddon>
								<Typeahead
									id="Client"
									labelKey="_title"
									options={clients}
									placeholder="Filter by client/managing agent/landlord..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									disabled={disabledParams.client}
									value={this.state.filter.client}
									onChange={e => this.handleChange(e, "client")}
									defaultSelected={this.state.filter.client ? [this.state.filter.client] : []}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.property}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.Business.Property}</InputGroupAddon>
								<Typeahead
									id="Property"
									labelKey="_title"
									options={properties}
									placeholder="Filter by property..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									disabled={disabledParams.property}
									value={this.state.filter.property}
									onChange={e => this.handleChange(e, "property")}
									defaultSelected={this.state.filter.property ? [this.state.filter.property] : []}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.templateFamily}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.templateFamily}</InputGroupAddon>
								<Typeahead
									id="TemplateFamily"
									labelKey="_title"
									options={templateFamilies}
									placeholder="Filter by template family..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									value={this.state.filter.templateFamily}
									disabled={disabledParams.templateFamily}
									onChange={e => this.handleChange(e, "templateFamily")}
									defaultSelected={this.state.filter.templateFamily ? [this.state.filter.templateFamily] : []}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.template}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.template}</InputGroupAddon>
								<Typeahead
									id="Template"
									labelKey="_title"
									options={templates}
									placeholder="Filter by template..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									disabled={disabledParams.template}
									value={this.state.filter.template}
									onChange={e => this.handleChange(e, "template")}
									defaultSelected={this.state.filter.template ? [this.state.filter.template] : []}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.periodEndStatus}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndStatus}</InputGroupAddon>
								<Typeahead
									id="PeriodEndStatus"
									labelKey="_title"
									options={LookupConstants.periodEndStatuses}
									placeholder="Filter by period end status..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									disabled={disabledParams.periodEndStatus}
									value={this.state.filter.periodEndStatus}
									onChange={e => this.handleChange(e, "periodEndStatus")}
									defaultSelected={this.state.filter.periodEndStatus ? [this.state.filter.periodEndStatus] : []}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.periodEndRag}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.periodEndRag}</InputGroupAddon>
								<Typeahead
									id="PeriodEndRag"
									labelKey="_title"
									options={LookupConstants.periodEndRags}
									placeholder="Filter by period end RAG..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									disabled={disabledParams.periodEndRag}
									value={this.state.filter.periodEndRag}
									onChange={e => this.handleChange(e, "periodEndRag")}
									defaultSelected={this.state.filter.periodEndRag ? [this.state.filter.periodEndRag] : []}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.stageStatus}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.stageStatus}</InputGroupAddon>
								<Typeahead
									id="StageStatus"
									labelKey="_title"
									options={LookupConstants.stageStatuses}
									placeholder="Filter by stage status..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									disabled={disabledParams.stageStatus}
									value={this.state.filter.stageStatus}
									onChange={e => this.handleChange(e, "stageStatus")}
									defaultSelected={this.state.filter.stageStatus ? [this.state.filter.stageStatus] : []}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.stageRag}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.stageRag}</InputGroupAddon>
								<Typeahead
									id="stageRag"
									labelKey="_title"
									options={LookupConstants.stageRags}
									placeholder="Filter by stage RAG..."
									allowNew={false}
									caseSensitive={false}
									clearButton={true}
									highlightOnlyResult={true}
									disabled={disabledParams.stageRag}
									value={this.state.filter.stageRag}
									onChange={e => this.handleChange(e, "stageRag")}
									defaultSelected={this.state.filter.stageRag ? [this.state.filter.stageRag] : []}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.endDate}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.end}</InputGroupAddon>
								<Label style={{ marginTop: "0.5rem", width: "3rem", paddingLeft: "0.5rem" }}>Ends </Label>
								<DateRangePicker
									disabled={disabledParams.endDate}
									value={this.state.filter.endDate}
									onChange={v => this.handleDateRangeChange(v, "endDate")}
									rangeDivider="to "
									showLeadingZeros={false}
								/>
							</InputGroup>
							<InputGroup disabled={disabledParams.dueDate}>
								<InputGroupAddon addonType="prepend">{clearview.Icon.due}</InputGroupAddon>
								<Label style={{ marginTop: "0.5rem", width: "3rem", paddingLeft: "0.5rem" }}>Due </Label>
								<DateRangePicker
									disabled={disabledParams.dueDate}
									value={this.state.filter.dueDate}
									onChange={v => this.handleDateRangeChange(v, "dueDate")}
									rangeDivider="to "
									showLeadingZeros={false}
								/>
							</InputGroup>
						</FormGroup>
					</ModalBody>

					<ModalFooter>
						<UncontrolledModal.Cancel color="link" className="text-primary">
							Cancel
						</UncontrolledModal.Cancel>
						<UncontrolledModal.Close
							color="default"
							className="text-primary"
							onClick={() => {
								clearview.DeleteProperties(this.state.filter, it => it === undefined)
								action(this.state.filter)
								return true
							}}
						>
							Run Report
						</UncontrolledModal.Close>
					</ModalFooter>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapStateToProps = state => {
	const propertiesSubState = ACTIONS.getSubState(state, "clientsReducer\\properties")
	const templatesSubState = ACTIONS.getSubState(state, "clientsReducer\\templates")
	return {
		...propertiesSubState,
		properties: ACTIONS.dictionaryToArray(propertiesSubState.dict),
		templateCustomers: ACTIONS.dictionaryToArray(templatesSubState.dict),
	}
}

ReportParamsModal = connect(mapStateToProps)(ReportParamsModal)

export default ReportParamsModal
