import React from "react"

import { Media, Avatar } from "../../../../components"

import { STATUS_TITLE, STATUS_COLOR, STATUS_ICON } from "./VouchingTheme"

export const ExpenseAvatar = props => {
	const page = props.expense.index > 1 ? props.expense.index : false
	const split = parseInt(props.expense.id) !== props.expense.id ? props.expense.id?.toString().split(".")[1] : false

	let title = STATUS_TITLE[props.expense.status]
	if (page) title = title + ` To page ${page}`
	if (split) title = title + ` split ${split}`

	return (
		<Media left middle className={["A", "M"].includes(props.expense.status) ? "align-self-center hover-alias" : "align-self-center"}>
			{" "}
			<Avatar.Font size="md" className="d-block avatar-with-text mr-1" bgColor={STATUS_COLOR[props.expense.status]} title={title}>
				{STATUS_ICON[props.expense.status]}
				<span className="sup-sub">
					{!!page && <sup>{page}</sup>}
					{!!split && <sub>{split}</sub>}
				</span>
			</Avatar.Font>
		</Media>
	)
}
