import React, { useState, useEffect } from "react"
import _ from "lodash"
import * as clearview from "./index"

export function ListSelector({ value, className, valueLabel, optionsLabel, options, optionValue, optionLabel, onChange, rows = 10 }) {
	const [selectedItems, setSelectedItems] = useState(false)
	const [availableItems, setAvailableItems] = useState(_.cloneDeep(options))

	useEffect(() => {
		if (selectedItems !== false) {
			const updatedValue = selectedItems.map(it => optionValue(it))
			if (!clearview.arraysContainSameElements(value, updatedValue)) {
				onChange(updatedValue)
			}
		}
	}, [selectedItems])

	const add = option => {
		setSelectedItems(previousState => {
			return _.uniq([...previousState, option].sort((a, b) => clearview.caseInsensitiveSort(optionLabel(a), optionLabel(b))))
		})
		setAvailableItems(previousState => {
			return _.difference(previousState, [option])
		})
	}

	const remove = option => {
		setSelectedItems(previousState => {
			return _.difference(previousState, [option])
		})
		setAvailableItems(previousState => {
			return _.uniq([...previousState, option].sort((a, b) => clearview.caseInsensitiveSort(optionLabel(a), optionLabel(b))))
		})
	}

	if (selectedItems === false && value) {
		const allOptions = _.cloneDeep(options)
		setSelectedItems(allOptions.filter(it => value.includes(optionValue(it))).sort((a, b) => clearview.caseInsensitiveSort(optionLabel(a), optionLabel(b))))
		setAvailableItems(
			allOptions.filter(it => !value.includes(optionValue(it))).sort((a, b) => clearview.caseInsensitiveSort(optionLabel(a), optionLabel(b)))
		)
	}

	return (
		<div className={`list-editor d-flex justify-content-between ${className}`}>
			<table className="list-editor-list">
				{valueLabel && (
					<thead>
						<tr>
							<th colSpan={2}>{valueLabel}</th>
						</tr>
					</thead>
				)}
				<tbody style={{ height: `${rows}rem` }}>
					{selectedItems &&
						selectedItems.map((option, idx) => (
							<tr key={idx} className="hover" title="Click to remove from list" onClick={e => remove(option)}>
								<td className="full-width">{optionLabel(option)}</td>
								<td>
									<i className="fa fa-fw fa-arrow-circle-right text-danger" />
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<div style={{ minWidth: "10%" }}></div>
			<table className="list-editor-options">
				{optionsLabel && (
					<thead>
						<tr>
							<th colSpan={2}>{optionsLabel}</th>
						</tr>
					</thead>
				)}
				<tbody style={{ height: `${rows}rem` }}>
					{availableItems &&
						availableItems?.map((option, idx) => (
							<tr key={idx} className="hover" title="Click to add to list" onClick={e => add(option)}>
								<td>
									<i className="fa fa-fw fa-arrow-circle-left text-success" />
								</td>
								<td className="full-width">{optionLabel(option)}</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	)
}
