import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { UncontrolledModal } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import WorkflowExcelForm from "../components/WorkflowExcelForm"

import { REF_DOES_NOT_EXIST, BUSINESS_ROW_MAPPER, DUPLICATE_REFERENCE_CHECK } from "../ImportBusinesses/ImportBusinesses_Form"
import sampleFile from "../ImportPeriodEnds/PropertiesAndPeriodEnds.xlsx"

class ImportProperties_Form extends React.Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.onUploaded = this.onUploaded.bind(this)
	}

	onUploaded(file, data) {
		const properties = (data || []).filter(REF_DOES_NOT_EXIST(this.props.business)).map(BUSINESS_ROW_MAPPER(this.props.business))
		if (!DUPLICATE_REFERENCE_CHECK(properties)) return

		this.props.doAction(this.props.business, properties)
		clearview.ShowToast(
			"Info",
			<div>
				<p>Creating {properties.length} Properties.</p>
				<p>Please wait...</p>
			</div>,
			false,
			"importProperties"
		)
	}

	render() {
		const { business } = this.props

		return (
			<UncontrolledModal style={{ maxWidth: 600 }} className="overflowScroll" target="ImportProperties_Form">
				<WorkflowExcelForm
					title={
						<span>
							Upload <b>New Properties</b> under <b className="nowrap">{business.name}</b>
						</span>
					}
					onUploaded={this.onUploaded}
					handleCancelButtonClick={this.handleCancelButtonClick}
					sampleFile={sampleFile}
				></WorkflowExcelForm>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAction: (parent, properties) =>
		dispatch({
			type: ACTIONS.PROPERTIES_ADD,
			parent: parent,
			properties: properties,
		}),
})

export default connect(null, mapDispatchToProps)(ImportProperties_Form)
