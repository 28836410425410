import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import _ from "lodash"

import { Form, UncontrolledModal, ModalHeader, ModalBody } from "../../../../components"

import { Media, Row, Col, Avatar } from "../../../../components"
import { DragDropContext, Draggable } from "react-beautiful-dnd"
import { StrictModeDroppable } from "../../../components/StrictModeDroppable"

import * as ACTIONS from "../../../../store/actions"
import * as clearview from "../../../../components/@Clearview"

class UserAssignments_Form extends React.Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		doAssign: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.setState = this.setState.bind(this)

		this.onDragEnd = this.onDragEnd.bind(this)

		this.handleActionButtonClick = this.handleActionButtonClick.bind(this)
		this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this)

		this.state = this.initialState()
	}

	initialState() {
		const clonedTeams = _.cloneDeep(this.props.business.teams.filter(it => it.type === "Users"))
		return {
			businessId: this.props.business.id,
			users: clonedTeams.find(it => it.type === "Users")?.users,
			user1: undefined,
			user2: undefined,
		}
	}

	handleActionButtonClick(action, confirmationMessage) {
		if (window.confirm(confirmationMessage)) {
			this.props.doAssign(this.state.user1, this.state.user2, action)
			return true
		}
		return false
	}

	handleCancelButtonClick() {
		this.setState(this.initialState())
		return true
	}

	onDragEnd(result, announce) {
		const { draggableId, destination } = result

		const draggableUser = this.state.users.find(it => it.username === draggableId)
		if (!draggableUser) return

		if (["user1", "user2"].includes(destination.droppableId)) {
			const users = _.difference(this.state.users, [draggableUser])
			if (this.state[destination.droppableId]) users.push(this.state[destination.droppableId])
			this.setState({ users: users, [destination.droppableId]: draggableUser })
		}
	}

	render() {
		const { business } = this.props

		if (business.id !== this.state.businessId) {
			this.setState(this.initialState())
		}

		const { users, user1, user2 } = this.state
		const isActionable = user1 && user2 && user1.id !== user2.id

		return (
			<UncontrolledModal style={{ maxWidth: 1200 }} className="overflowScroll" target="UserAssignments_Form">
				<Form onSubmit={this.handleSubmit}>
					<ModalHeader tag="div">
						<div className="d-flex">
							<h1 className="text-primary mr-2">{clearview.Icon.Business.Assignments}</h1>
							<h6>
								User Assignments for <b className="nowrap">{business.name}</b>
							</h6>
							<div className="ml-5">
								<UncontrolledModal.Cancel color="link" className="text-primary" onClick={this.handleCancelButtonClick}>
									Cancel
								</UncontrolledModal.Cancel>
							</div>
						</div>
					</ModalHeader>

					<ModalBody>
						<DragDropContext onDragEnd={this.onDragEnd}>
							<Row>
								<Col width={6}>
									<StrictModeDroppable droppableId="allUsers" direction="horizontal">
										{(droppable, snapshot) => (
											<div className="d-flex-row" ref={droppable.innerRef} {...droppable.droppableProps}>
												{users
													.sort((a, b) => clearview.caseInsensitiveSort(a.initials, b.initials))
													.map((user, index) => (
														<Draggable
															key={`actor${user.username}`}
															draggableId={user.username}
															index={index}
															isDragDisabled={false}
														>
															{draggable => (
																<div
																	className="mr-1"
																	ref={draggable.innerRef}
																	{...draggable.draggableProps}
																	{...draggable.dragHandleProps}
																>
																	<Avatar.Font
																		user={user}
																		size="md"
																		className="avatar-with-text"
																		title={`${user.name} [${user.role}]`}
																	></Avatar.Font>
																</div>
															)}
														</Draggable>
													))}{" "}
											</div>
										)}
									</StrictModeDroppable>
									<hr />
								</Col>
							</Row>
							<Row className="mt-4">
								<Col width={5}>
									<StrictModeDroppable droppableId="user1">
										{(droppable, snapshot) => (
											<div ref={droppable.innerRef} {...droppable.droppableProps}>
												{user1 === undefined && <h3 className="text-info">Drag a user here</h3>}
												{user1 && <UserDetails user={user1} />}
											</div>
										)}
									</StrictModeDroppable>
								</Col>
								<Col width={2} className="d-flex-column justify-content-between align-items-start">
									<UncontrolledModal.Close
										disabled={!isActionable}
										color="link"
										className="d-flex align-items-center"
										onClick={e =>
											this.handleActionButtonClick(
												"Assign",
												`Are you sure you wish to COPY all of ${user1?.name}'s Properties to ${user2?.name}?`
											)
										}
									>
										<Avatar.Font bgColor="info" size="md" className="avatar-with-text mr-1">
											<i className="fa fa-fw fa-arrow-right"></i>
										</Avatar.Font>
										<span>
											Copy all of {user1?.name}'s Properties to {user2?.name}
										</span>
									</UncontrolledModal.Close>
									<UncontrolledModal.Close
										disabled={!isActionable}
										color="link"
										className="d-flex align-items-center"
										onClick={e =>
											this.handleActionButtonClick(
												"Unassign",
												`Are you sure you wish to REMOVE all of ${user1?.name}'s Properties from ${user2?.name}`
											)
										}
									>
										<Avatar.Font bgColor="danger" size="md" className="avatar-with-text mr-1">
											<i className="fa fa-fw fa-arrow-left"></i>
										</Avatar.Font>
										<span>
											Remove all of {user1?.name}'s Properties from {user2?.name}
										</span>
									</UncontrolledModal.Close>
								</Col>
								<Col width={5}>
									<StrictModeDroppable droppableId="user2">
										{(droppable, snapshot) => (
											<div ref={droppable.innerRef} {...droppable.droppableProps}>
												{user2 === undefined && <h3 className="text-info">Drag another user here</h3>}
												{user2 && <UserDetails user={user2} />}
											</div>
										)}
									</StrictModeDroppable>
								</Col>
							</Row>
						</DragDropContext>
					</ModalBody>
				</Form>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAssign: (user1, user2, mode) =>
		dispatch({
			type: ACTIONS.USER_ASSIGN_USERS_PROPERTIES,
			user1,
			user2,
			mode,
		}),
})

export default connect(null, mapDispatchToProps)(UserAssignments_Form)

function UserDetails({ user }) {
	return (
		user && (
			<Media>
				<Avatar.Font user={user} size="lg" className="avatar-with-text mr-2"></Avatar.Font>
				<Media body>
					<h5>{user.name}</h5>
					<p>
						{user.role}
						<br />
						{user.username}
					</p>
				</Media>
			</Media>
		)
	)
}
