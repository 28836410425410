import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import _ from "lodash"

import { UncontrolledModal } from "../../../../components"

import * as clearview from "../../../../components/@Clearview"
import * as ACTIONS from "../../../../store/actions"
import WorkflowExcelForm from "../components/WorkflowExcelForm"

import sampleFile from "./Businesses.xlsx"

export function REF_DOES_NOT_EXIST(parent) {
	return it =>
		it["Ref"] &&
		it["Ref"] !== "SAMPLE" &&
		!ACTIONS.findInBranch(
			parent,
			p => p.reference === it["Ref"]?.toString(),
			p => p.children
		)
}

export function BUSINESS_ROW_MAPPER(parent) {
	return it => ({
		parent:
			(it["Landlord Ref"]
				? ACTIONS.findInBranch(
						parent,
						p => p.reference === it["Landlord Ref"]?.toString(),
						p => p.children
				  )
				: parent) || parent,
		reference: it["Ref"]?.toString(),
		name: it["Name"]?.toString(),
		address: {
			name: it["Address Line 1"]?.toString(),
			fullAddressExcludingPostcode: `${it["Name"] || ""}\n${it["Address Line 1"] || ""}\n${it["Address Line 2"] || ""}\n${it["Address Line 3"] || ""}\n${
				it["City"] || ""
			}\n${it["County"] || ""}`.replace(/\n{2,}/, "\n"),
			postcode: it["Postcode"]?.toString(),
		},
	})
}

export function DUPLICATE_REFERENCE_CHECK(collection) {
	// Check for duplicates
	const referenceGroup = _.groupBy(collection, it => it.reference)
	const duplicates = _.keys(referenceGroup).filter(key => referenceGroup[key].length !== 1)
	if (duplicates.length) {
		clearview.ShowToast(
			"Error",
			<div>
				<p>Duplicate Refs in the file are preventing import:</p>
				<p>{duplicates.join(", ")}</p>
			</div>
		)
		return false
	}
	return true
}

class ImportBusinesses_Form extends React.Component {
	static propTypes = {
		business: PropTypes.object.isRequired,
		doAction: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.props = props

		this.onUploaded = this.onUploaded.bind(this)
	}

	onUploaded(file, data) {
		const businesses = (data || []).filter(REF_DOES_NOT_EXIST).map(BUSINESS_ROW_MAPPER(this.props.business))
		if (!DUPLICATE_REFERENCE_CHECK(businesses)) return

		this.props.doAction(this.props.business, businesses)
		clearview.ShowToast(
			"Info",
			<div>
				<p>Creating {businesses.length} Clients.</p>
				<p>Please wait...</p>
			</div>,
			false,
			"importBusinesses"
		)
	}

	render() {
		const { business } = this.props

		return (
			<UncontrolledModal style={{ maxWidth: 600 }} className="overflowScroll" target="ImportBusinesses_Form">
				<WorkflowExcelForm
					title={
						<span>
							Upload <b>New Clients</b> under <b className="nowrap">{business.name}</b>
						</span>
					}
					onUploaded={this.onUploaded}
					handleCancelButtonClick={this.handleCancelButtonClick}
					sampleFile={sampleFile}
				></WorkflowExcelForm>
			</UncontrolledModal>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	doAction: (parent, clients) =>
		dispatch({
			type: ACTIONS.CLIENTS_ADD,
			parent: parent,
			clients: clients,
		}),
})

export default connect(null, mapDispatchToProps)(ImportBusinesses_Form)
