import { v4 as uuid } from "uuid"
import { put, takeLatest } from "redux-saga/effects"
import * as Api from "../../../../../api/CustomerApi"
import * as clearview from "../../../../../components/@Clearview"
import * as WP_ACTIONS from "./workingPapersActions"

import DEFAULT_FEE_MATRIX from "../Config/DEFAULT_FEE_MATRIX.json"
import DEFAULT_NOMINAL_CODES from "../Config/DEFAULT_NOMINAL_CODES.json"

import { materialiseVouching } from "../../VouchingDetails/VouchingDetails"
import { materialiseWorkingPapers, dematerialiseWorkingPapers, dematerialiseReport } from "../WorkingPapers.Materialise"

function* load(action) {
	try {
		const client = clearview.TopClientFor(action.periodEnd.property)

		const responses = yield Promise.all([
			Api.loadPeriodEndDataFile(action.periodEnd, "vouching/working-papers", { id: action.periodEnd.id }),
			Api.loadInScopePreferences(client, "working-papers-fee-matrix", DEFAULT_FEE_MATRIX, action.periodEnd.endDate.substring(0, 10)),
			Api.loadPreferences(client, "working-papers-nominal-codes", DEFAULT_NOMINAL_CODES),
			Api.listPeriodEndDataFiles(action.periodEnd, "vouching/working-papers-report"),
		])

		const workingPapers = materialiseWorkingPapers(client, action.periodEnd, responses[0], responses[1], responses[2]).workingPapers

		yield put({
			type: WP_ACTIONS.LOADED,
			workingPapers,
			reports: responses[3],
		})
	} catch (e) {
		yield put({
			type: WP_ACTIONS.ERROR,
			message: e.message,
		})
	}
}

function* save(action) {
	try {
		if (!!action.isChanged?.workingPapers)
			yield Api.savePeriodEndDataFile(action.periodEnd, "vouching/working-papers", dematerialiseWorkingPapers(action.workingPapers))

		if (action.saveReportAs) {
			const reportKey = Api.periodEndDataFilePath(action.periodEnd, "vouching/working-papers-report", action.saveReportAs)
			yield Api.saveJsonFile(reportKey, dematerialiseReport(action.report))
		} else if (!!action.isChanged?.report) {
			yield Api.saveJsonFile(action.report.fileInfo.key, dematerialiseReport(action.report))
		}

		yield put({
			type: WP_ACTIONS.LOAD,
			periodEnd: action.periodEnd,
		})
	} catch (e) {
		yield put({
			type: WP_ACTIONS.ERROR,
			message: e.message,
		})
	}
}

function* saveNominalCodes(action) {
	try {
		const client = clearview.TopClientFor(action.workingPapers.periodEnd.property)
		yield Api.savePreferences(client, "working-papers-nominal-codes", action.workingPapers.config.NOMINAL_CODES.dematerialise())
		yield put({
			type: WP_ACTIONS.SAVED,
		})
	} catch (e) {
		yield put({
			type: WP_ACTIONS.ERROR,
			message: e.message,
		})
	}
}

function* importVouching(action) {
	try {
		const rawVouching = yield Api.loadPeriodEndDataFile(action.periodEnd, "vouching/vouching", { id: action.periodEnd.id })

		yield put({
			type: WP_ACTIONS.IMPORTED_VOUCHING,
			vouching: materialiseVouching(rawVouching),
		})
	} catch (e) {
		yield put({
			type: WP_ACTIONS.ERROR,
			message: e.message,
		})
	}
}

function* selectTemplate(action) {
	try {
		const template = yield Api.loadJsonFile(action.fileInfo.key, {})

		yield put({
			type: WP_ACTIONS.SELECTED_TEMPLATE,
			newReport: {
				id: uuid(),
				fileInfo: { ...action.fileInfo, key: undefined, name: action.fileInfo.scope + ".json" },
				template: template,
				parameters: template.parameters ?? {},
			},
		})
	} catch (e) {
		yield put({
			type: WP_ACTIONS.ERROR,
			message: e.message,
		})
	}
}

function* loadReport(action) {
	try {
		const report = yield Api.loadJsonFile(action.fileInfo.key)
		report.fileInfo = action.fileInfo

		yield put({
			type: WP_ACTIONS.LOADED_REPORT,
			report,
		})
	} catch (e) {
		yield put({
			type: WP_ACTIONS.ERROR,
			message: e.message,
		})
	}
}

export default function* workingPapersSaga() {
	yield takeLatest(WP_ACTIONS.LOAD, load)
	yield takeLatest(WP_ACTIONS.SAVE, save)
	yield takeLatest(WP_ACTIONS.SAVE_NOMINAL_CODES, saveNominalCodes)
	yield takeLatest(WP_ACTIONS.IMPORT_VOUCHING, importVouching)
	yield takeLatest(WP_ACTIONS.SELECT_TEMPLATE, selectTemplate)
	yield takeLatest(WP_ACTIONS.LOAD_REPORT, loadReport)
}
