import React from "react"
import { PastelColorValues } from "./Icon"

import { Badge } from ".."

export const StageStatusOrder = {
	NotYetOpen: 0,
	Open: 1,
	OnHold: 2,
	RequestClosed: 3,
	Closed: 4,
}
export const StageStatusColors = {
	NotYetOpen: "info",
	Open: "success",
	OnHold: "warning",
	RequestClosed: "danger",
	Closed: "secondary",
}
export const StageStatusColorValues = {
	NotYetOpen: "#33AE9A",
	Open: "#1BB934",
	OnHold: "#F27212",
	RequestClosed: "#ED1C24",
	Closed: "#ADB7C3",
}
export const StageStatusColorValuesPastel = {
	NotYetOpen: PastelColorValues.Greyish,
	Open: PastelColorValues.Greenish,
	OnHold: PastelColorValues.Blueish,
	RequestClosed: PastelColorValues.Redish,
	Closed: PastelColorValues.Greyish,
}

export const StageStatusNames = {
	NotYetOpen: "Not Yet Open",
	Open: "Open",
	OnHold: "On Hold",
	RequestClosed: "Request Closed",
	Closed: "Closed",
}
export const StageRagOrder = {
	NotDue: 0,
	OnHold: 1,
	OnTime: 2,
	Warning: 3,
	Overdue: 4,
}

export const StageRagOk = {
	NotDue: true,
	OnHold: true,
	OnTime: true,
	Warning: true,
	Overdue: false,
}

export const StageRagColors = {
	NotDue: "secondary",
	OnHold: "info",
	OnTime: "success",
	Warning: "warning",
	Overdue: "danger",
}

export const StageRagColorValues = {
	NotDue: "#ADB7C3",
	OnHold: "#33AE9A",
	OnTime: "#1BB934",
	Warning: "#F27212",
	Overdue: "#ED1C24",
}

export const StageRagNames = {
	NotDue: "Not Due",
	OnHold: "On Hold",
	OnTime: "On Time",
	Warning: "Warning",
	Overdue: "Overdue",
}

export const PeriodEndToRag2Mapper = periodEnd => {
	const ragMap = {
		OnHold: "OnHold",
		OnTime: "OnTime",
		Warning: "OnTime",
		Overdue: "Overdue",
	}
	return ragMap[periodEnd.currentStage.rag]
}

export const Rag2Names = {
	OnHold: "On Hold",
	OnTime: "On Target",
	Overdue: "Overdue",
}

export const Rag2ColorValues = {
	OnHold: "#2C77FF",
	OnTime: "#1BB934",
	Overdue: "#F27212",
}

export const CommentTypeColors = {
	Log: "secondary",
	Comment: "primary",
	Query: {
		// bool: is with user?
		undefined: "dark",
		false: "warning",
		true: "danger",
	},
	DraftQuery: "secondary",
	ClosedQuery: "success",
	Reply: "info",
	Closer: "success",
}

export const CommentTypeIcon = {
	Log: <i className="fa fa-fw fa-info-circle secondary"></i>,
	Comment: <i className="fa fa-fw fa-comment-o primary"></i>,
	Query: {
		// bool: is with user?
		undefined: <i className="fa fa-fw fa-comments-o dark"></i>,
		false: <i className="fa fa-fw fa-comments-o warning"></i>,
		true: <i className="fa fa-fw fa-comments danger"></i>,
	},
	DraftQuery: <i className="fa fa-fw fa-comments-o secondary"></i>,
	ClosedQuery: <i className="fa fa-fw fa-comments success"></i>,
	Reply: <i className="fa fa-fw fa-comment-o warning"></i>,
	Closer: <i className="fa fa-fw fa-comment-o success"></i>,
}

export const CommentTypeIconClass = {
	Log: "fa fa-fw fa-info-circle",
	Comment: "fa fa-fw fa-comment-o",
	Query: {
		// bool: is with user?
		undefined: "fa fa-fw fa-comments-o",
		true: "fa fa-fw fa-comments",
		false: "fa fa-fw fa-comments-o",
	},
	DraftQuery: "fa fa-fw fa-comments-o",
	ClosedQuery: "fa fa-fw fa-comments",
	Reply: "fa fa-fw fa-comment-o",
	Closer: "fa fa-fw fa-comment-o",
}

export const CommentTypeName = {
	Log: "Log",
	Comment: "Comment",
	Query: "Query",
	DraftQuery: "Draft",
	ClosedQuery: "Closed",
}

export const EntityColors = {
	Attachment: "info",
}

export function StageStatusBadge(stage, content, options = { className: "badge-pill" }) {
	return (
		<Badge color={StageStatusColors[stage?.status]} title={StageStatusNames[stage?.status]} className={options.className}>
			{content || StageStatusNames[stage?.status]}
		</Badge>
	)
}
export function StageRagBadge(stage, content, options = { className: "badge-pill" }) {
	if (!stage) return
	return (
		<Badge color={StageRagColors[stage?.rag]} title={StageRagNames[stage?.rag]} disabled={stage?.status === "Closed"} className={options.className}>
			{content || StageRagNames[stage?.rag]}
		</Badge>
	)
}
