import _ from "lodash"
import * as WP_ACTIONS from "./workingPapersActions"

import {
	materialiseFeeMatrix,
	materialiseExpenditureReport,
	updateAllSchedules,
	updateFees,
	updateETB,
	materialiseDetails,
	materialiseReport,
} from "../WorkingPapers.Materialise"
import {
	onReadCommonWorksheet,
	onReadCRsAndAccrualsWorksheet,
	onReadDRsAndPrepayWorksheet,
	onReadIncomeReportWorksheet,
	onReadComparativesWorksheet,
} from "../WorkingPapers.Methods"

const workingPapersReducer = (state, action) => {
	if (!state) {
		state = {
			ui: {
				isBusy: false,
				isChanged: false,
				isImporting: false,
				isLoading: false,
				isShowChooseTemplate: false,
			},
			workingPapers: {},
			reports: [],
			report: {},
		}
	}

	switch (action.type) {
		case WP_ACTIONS.LOAD:
			state.ui.isChanged = false
			state.ui.isBusy = true
			state.ui.isLoading = true
			return _.cloneDeep(state)

		case WP_ACTIONS.LOADED:
			state.workingPapers = action.workingPapers
			state.reports = action.reports
			state.ui.isBusy = false
			state.ui.isLoading = false
			state.ui.isChanged = false
			return _.cloneDeep(state)

		case WP_ACTIONS.SAVE_ROW:
			if (!!action.row.id) {
				const updateRow = state.workingPapers[action.page].find(it => it.id === action.row.id)
				_.assign(updateRow, action.row)
			} else {
				action.row.id = (_.max(state.workingPapers[action.page].map(it => it.id)) || 0) + 1
				const insertAfterIndex = _.findLastIndex(state.workingPapers[action.page], it => it.schedule === action.row.schedule)
				state.workingPapers[action.page].splice(insertAfterIndex + 1, 0, action.row)
			}
			return changedState(state)

		case WP_ACTIONS.SAVE_SCHEDULE_CODE_ROW:
			const updateScheduleCodeRow = state.workingPapers[action.page].find(it => it.schedule === action.row.schedule && it.code === action.row.code)
			if (!updateScheduleCodeRow) {
				const insertAfterIndex = _.findLastIndex(state.workingPapers[action.page], it => it.schedule === action.row.schedule)
				state.workingPapers[action.page].splice(insertAfterIndex + 1, 0, action.row)
			}
			_.assign(updateScheduleCodeRow, action.row)
			return changedState(state)

		case WP_ACTIONS.SAVE_DETAILS:
			state.workingPapers.details = materialiseDetails({ ...state.workingPapers.details, ...action.details })
			return changedState(state)

		case WP_ACTIONS.SAVE_FEES:
			state.workingPapers.fees.allocations = action.fees.allocations.filter(it => !!it.total)
			return changedState(state)

		case WP_ACTIONS.SAVE_LEDGER_ROW:
			updateLedgerRow(
				state.workingPapers.expenditureReport.ledger.find(it => it.id === action.row.id),
				action.row
			)
			return changedState(state)

		case WP_ACTIONS.SAVE_NOMINAL_CODES:
			state.ui.isBusy = true
			state.workingPapers.config.NOMINAL_CODES.setAlias(action.row.code, action.row.account.name)
			updateLedgerRows(state.workingPapers.expenditureReport.ledger, action.row)
			return changedState(state)

		case WP_ACTIONS.APPLY_LEDGER_ALIAS:
			updateLedgerRows(state.workingPapers.expenditureReport.ledger, action.row)
			return changedState(state)

		case WP_ACTIONS.DELETE_ROW:
			state.workingPapers[action.page] = state.workingPapers[action.page].filter(it => it.id !== action.row.id)
			return changedState(state)

		case WP_ACTIONS.READ_COMMON_WORKSHEET:
			state.workingPapers[action.page] = onReadCommonWorksheet(action.file, action.data, undefined, undefined, undefined, state.workingPapers)
			return changedState(state)

		case WP_ACTIONS.READ_CRS_AND_ACCRUALS_WORKSHEET:
			state.workingPapers["crsAndAccruals"] = onReadCRsAndAccrualsWorksheet(action.file, action.data, undefined, state.workingPapers)
			return changedState(state)

		case WP_ACTIONS.READ_DRS_AND_PREPAY_WORKSHEET:
			state.workingPapers["drsAndPrepay"] = onReadDRsAndPrepayWorksheet(action.file, action.data, undefined, state.workingPapers)
			return changedState(state)

		case WP_ACTIONS.READ_INCOME_REPORT_WORKSHEET:
			state.workingPapers["incomeReport"] = onReadIncomeReportWorksheet(action.file, action.data, undefined)
			return changedState(state)

		case WP_ACTIONS.READ_COMPARATIVES_WORKSHEET:
			state.workingPapers["comparatives"] = onReadComparativesWorksheet(action.file, action.data, state.workingPapers)
			return changedState(state)

		case WP_ACTIONS.IMPORT_UPDATED_FEE_MATRIX:
			state.workingPapers.config.FEE_MATRIX = materialiseFeeMatrix(action.rawFeeMatrix)
			updateFees(state.workingPapers)
			delete state.workingPapers.fees.messages["The Fee Matrix for this Client has changed since you started using it!"]
			return changedState(state)

		case WP_ACTIONS.IMPORTED_VOUCHING:
			state.workingPapers.expenditureReport = materialiseExpenditureReport(state.workingPapers, action.vouching.ledger)
			return changedState(state)

		case WP_ACTIONS.SAVE:
			state.ui.isBusy = true
			return _.cloneDeep(state)

		case WP_ACTIONS.SAVED:
			state.ui.isBusy = false
			return _.cloneDeep(state)

		case WP_ACTIONS.SHOW_CHOOSE_TEMPLATE:
			state.ui.isBusy = false
			state.ui.isShowChooseTemplate = action.showHide
			return _.cloneDeep(state)

		case WP_ACTIONS.SELECTED_TEMPLATE:
			state.ui.isShowChooseTemplate = false
			state.report = materialiseReport(state.workingPapers, action.newReport)
			return _.cloneDeep(state)

		case WP_ACTIONS.LOADED_REPORT:
			state.report = materialiseReport(state.workingPapers, action.report)
			return _.cloneDeep(state)

		case WP_ACTIONS.UPDATE_REPORT_TEMPLATE:
			state.report.template = action.template
			return changedState(state, false, true)

		case WP_ACTIONS.UPDATE_REPORT_PARAMETERS:
			state.report.parameters = action.parameters
			return changedState(state, false, true)

		default:
			return state
	}
}

function changedState(state, workingPapers = true, report = false) {
	state.ui.isBusy = false
	if (!state.ui.isChanged) state.ui.isChanged = {}

	if (workingPapers) {
		state.ui.isChanged.workingPapers = true
		updateETB(state.workingPapers)
		updateAllSchedules(state.workingPapers)
	}
	if (report) {
		state.ui.isChanged.report = true
	}
	return _.cloneDeep(state)
}

function updateLedgerRows(ledger, row) {
	_.each(
		ledger.filter(it => it.account?.name === row.account.name),
		it => updateLedgerRow(it, row)
	)
}

function updateLedgerRow(updateRow, { code, schedule }) {
	updateRow.code = code
	updateRow.schedule = schedule
}

export default workingPapersReducer
