import React from "react"

import PropTypes from "prop-types"

import { UncontrolledPopover, PopoverHeader, PopoverBody } from "../../../../components"

import * as Api from "../../../../api/CustomerApi"
import * as clearview from "../../../../components/@Clearview"

export class QueueManager extends React.Component {
	static propTypes = {
		target: PropTypes.string.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {}

		this.loadQueueManagerAsync = this.loadQueueManagerAsync.bind(this)
	}

	loadQueueManagerAsync() {
		return Api.getQueueManager().then(resp => this.setState({ ...resp }))
	}

	render() {
		return (
			<UncontrolledPopover
				key="queueManagerUncontrolledPopover"
				placement="left"
				target={this.props.target}
				beforeOpenAsync={this.loadQueueManagerAsync}
				beforeOpenContent={<p style={{ width: 260 }}>Please Wait...</p>}
			>
				<PopoverHeader className="d-flex-row justify-content-between">
					<div>Queue Status</div>
					<div>@{clearview.TimeToString(new Date(this.state.timestamp))}</div>
				</PopoverHeader>
				<PopoverBody>
					<table className="table text-light">
						<tbody>
							<tr key="stat1">
								<th className="text-light">Items waiting</th>
								<td>{this.state.sqs?.ApproximateNumberOfMessages}</td>
							</tr>
							<tr key="stat2">
								<th className="text-light">In progress</th>
								<td>{this.state.sqs?.ApproximateNumberOfMessagesNotVisible}</td>
							</tr>
							<tr key="stat3">
								<th className="text-light">Next batch starts</th>
								<td>{clearview.DateTimeToString(new Date(this.state.eventBridge?.Next))}</td>
							</tr>
						</tbody>
					</table>
				</PopoverBody>
			</UncontrolledPopover>
		)
	}
}
