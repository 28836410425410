import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"

import { Container, Row, Col } from "../../../components"

import { Provider } from "../../../layout/context"

import { HeaderMain } from "../../components/HeaderMain"
import { Spinner } from "../../components/Spinner"

import { PropertiesTable } from "./components/PropertiesTable"

class PropertiesDashboard extends Component {
	static propTypes = {
		properties: PropTypes.array.isRequired,
		isBusy: PropTypes.bool.isRequired,
		lastUpdated: PropTypes.number,
		title: PropTypes.string,
	}

	componentDidMount() {
		//Do not refresh every time: this.props.doRefresh()
	}

	render() {
		const { properties } = this.props

		return (
			<Provider
				value={{
					isEditing: false,
				}}
			>
				<Container className={this.props.isBusy ? "isBusy wide" : "wide"}>
					{this.props.isBusy && <Spinner key="spinner"></Spinner>}
					<HeaderMain
						title={<span className="text-primary">{clearview.Icon.property} Properties</span>}
						className="mb-4 mt-0"
						onRefresh={() => this.props.doRefresh()}
					/>
					<Row>
						<Col lg={12}>
							<PropertiesTable properties={properties} />
						</Col>
					</Row>
				</Container>
			</Provider>
		)
	}
}

const mapStateToProps = state => {
	const subState = ACTIONS.getSubState(state, "clientsReducer\\properties")
	return {
		...subState,
		properties: ACTIONS.dictionaryToArray(subState.dict),
	}
}

const mapDispatchToProps = dispatch => ({
	doRefresh: () => {
		dispatch({ type: ACTIONS.CLIENTS_FETCH, payload: {} })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesDashboard)
