import React from "react"

import { Container, Row, Col } from "../../../../components"

import { ItemMap } from "./Releases"

export const Releases2021 = () => (
	<React.Fragment>
		<Container>
			<Row>
				<Col lg={8} className="release-timeline">
					{Release_20211219.map(ItemMap("19 Dec 2021"))}
					{Release_20211212.map(ItemMap("12 Dec 2021"))}
					{Release_20211206.map(ItemMap("06 Dec 2021"))}
					{Release_20211124.map(ItemMap("24 Nov 2021"))}
					{Release_20211119.map(ItemMap("19 Nov 2021"))}
					{Release_20211114.map(ItemMap("14 Nov 2021"))}
					{Release_20211108.map(ItemMap("08 Nov 2021"))}
					{Release_20211107.map(ItemMap("07 Nov 2021"))}
					{Release_20211024.map(ItemMap("24 Oct 2021"))}
					{Release_20211019.map(ItemMap("19 Oct 2021"))}
					{Release_20211017.map(ItemMap("17 Oct 2021"))}
					{Release_20211014.map(ItemMap("14 Oct 2021"))}
					{Release_20211006.map(ItemMap("06 Oct 2021"))}
					{Release_20211003.map(ItemMap("03 Oct 2021"))}
					{Release_20210920.map(ItemMap("20 Sep 2021"))}
					{Release_20210816.map(ItemMap("16 Aug 2021"))}
					{Release_20210727.map(ItemMap("27 Jul 2021"))}
					{Release_20210720.map(ItemMap("20 Jul 2021"))}
					{Release_20210712.map(ItemMap("12 Jul 2021"))}
					{Release_20210711.map(ItemMap("11 Jul 2021"))}
					{Release_20210627.map(ItemMap("27 Jun 2021"))}
					{Release_20210621.map(ItemMap("21 Jun 2021"))}
					{Release_20210604.map(ItemMap("04 Jun 2021"))}
					{Release_20210531.map(ItemMap("31 May 2021"))}
					{Release_20210509.map(ItemMap("09 May 2021"))}
					{Release_20210504.map(ItemMap("04 May 2021"))}
					{Release_20210425.map(ItemMap("25 Apr 2021"))}
					{Release_20210420.map(ItemMap("20 Apr 2021"))}
					{Release_20210412.map(ItemMap("12 Apr 2021"))}
					{Release_20210408.map(ItemMap("08 Apr 2021"))}
					{Release_20210407.map(ItemMap("07 Apr 2021"))}
					{Release_20210406.map(ItemMap("06 Apr 2021"))}
					{Release_20210405.map(ItemMap("05 Apr 2021"))}
					{Release_20210316.map(ItemMap("16 Mar 2021"))}
					{Release_20210228.map(ItemMap("28 Feb 2021"))}
					{Release_20210222.map(ItemMap("22 Feb 2021"))}
					{Release_20210221.map(ItemMap("21 Feb 2021"))}
					{Release_20210207.map(ItemMap("07 Feb 2021"))}
					{Release_20210124.map(ItemMap("24 Jan 2021"))}
					{Release_20210119.map(ItemMap("19 Jan 2021"))}
					{Release_20210117.map(ItemMap("17 Jan 2021"))}
					{Release_20210112.map(ItemMap("12 Jan 2021"))}
					{Release_20210111.map(ItemMap("11 Jan 2021"))}
					{Release_20210110.map(ItemMap("10 Jan 2021"))}
					{Release_20210106.map(ItemMap("06 Jan 2021"))}
					{Release_20210103.map(ItemMap("03 Jan 2021"))}
				</Col>
			</Row>
		</Container>
	</React.Fragment>
)

const Release_20211219 = [
	[
		"check",
		"Alerts updating",
		"Now fixed. Alerts will be generated when a PE or Stage changes status or rag, when an attachment is added/updated, when a comment is made, or when a query is sent or updated.",
	],
]

const Release_20211212 = [
	["check", "Overview Phase view not showing for Phase 7", "The overview was incorrectly showing counts for phase 0-6 rather than 1-7. Now fixed."],
	[
		"info",
		"PE progress bars show Phases",
		"Where a customer has defined Phases for their templates, the Period End progress bars will show Phases rather than Stages.",
	],
	[
		"check",
		"Draft queries correctly assigned",
		"When a draft query is finally sent, it was remaining 'with the author'. It will not be assigned to be 'with the assignee'.",
	],
	["check", "Client tree loading improvement", "Fix to: The client tree data loads faster by loading flat data and building the tree in the web app."],
	["info", "Restrict queries to User's range", "Ensure the Period End loading is limited to their Client range (they will not be returned anyway)."],
]

const Release_20211206 = [
	["info", '"Director" category', '"Director" has been added to the client Appointees types.'],
	["info", "Client appointees shown on PE page", "Users can now see the Property/Agent/Client appointees on the Period End Property card."],
	["info", "Client tree loading improvement", "The client tree data loads faster by loading flat data and building the tree in the web app."],
	["info", "Condition loading improvement", "Condition creates/updated date now loaded until Period End details are viewed."],
]

const Release_20211124 = [
	["check", "Fix to Next Template info being lost", "Cloning or deleting a template caused all templates to lose their Next template setting. Now fixed."],
]

const Release_20211119 = [
	["info", "In-house vs Client icons colour coded.", "The In-House icon is now blue and the Client icon is now green."],
	["info", "Updated Clearview & BW logos.", "Logos have been updated for the BW and Guest branded views."],
	["info", "Query numbers now shown on Query Management", "Each query now shows its query number, ripeness, subject and title (where specified)."],
	["check", "Queries can be dealt with in any stage", "An open query can be replied to or closed even if it is not in the current stage."],
	["check", "New Queries correctly with In-House or Client", "Fixed a bug whereby client created queries were not showing up as assigned In House."],
]

const Release_20211114 = [
	["info", "Query Icons simplified.", 'Query number icons are now coloured according to "with us" or "with them". Hourglass added to show query age.'],
	["info", "Default actions highlighted in Blue.", "labels for the default Stage actions and query reply actions are now highlighted in blue."],
	["info", "Hearts replaced with client type icons", 'In House and With Client heart icons replaced with "Institution" and "house" icons.'],
	["info", 'Donut charts now show stages with "Us" and with "Them"', "Stages by RAG are now shown in the donut charts."],
	[
		"info",
		"Closed Period Ends are now excluded from the Stages table on the Overview Page",
		"This ensures that the data in the donut charts matches that in the table.",
	],
	["info", "Colour coding on Overview Page", "Statistics which do not relate to the Stage RAG colours are now shown in pastel colors to reduce confusion."],
	["info", "Query statistics on Overview Page", "Query Statistics now have their own section."],
	["check", "Error occurs saving new Templates", 'Intermittent error believed to be resolved using "mutex".'],
]

const Release_20211108 = [
	["check", "Fixed errors with manually created Period Ends", "Manually created period ends can now be created and progressed without stage errors."],
	["check", "Confirmation message on New Period End", "A message will now be displayed when a new Period End is created."],
	[
		"check",
		"Open Stages Due Date and Closed At",
		'Stage Due Date or Closed At dates are now correctly displayed on the Open Stages table (rather than "invalid date").',
	],
]

const Release_20211107 = [
	[
		"info",
		"Fast Loading!",
		'Period Ends and Queries are now fast loading. Only "lite" versions of Period Ends and queries are loaded at first. This is enough to populate the Overview, Period Ends and Query Management pages and perform Stage level actions. The Period End details are loaded when the user clicks through to the Period End details page.',
	],
	["info", "Template Families", "Templates can now be grouped in Families. Family names and sequence can be edited."],
	["check", "Stage overview links", "Stage Overview links now correctly filters Period Ends by Template Family."],
]

const Release_20211024 = [
	["check", "Loads PeriodEndLite", "Fast loading of PeriodEnd summary info, but Open Stages page does not show Actions."],
	["check", "Loads QueryLite", "Fast loading of Query info, but Query Manager page does not show Actions or Attachments."],
]

const Release_20211019 = [
	["check", "ChunkDelay varies by customer.", "Chunk size 25, delay 1000."],
	["check", "Will retry up to 50 times. Warns on 3rd retry", "Period End loading performance optimisation (DB timeouts at 10s)."],
]

const Release_20211017 = [
	["check", "Retry on 504 timeout (chunkDelay: 1000, size: 50)", "Period End loading performance optimisation (504 response now has CORS headers)."],
]

const Release_20211014 = [["check", "Delay & chunk size varies by customer", "Period End loading performance optimisation."]]

const Release_20211006 = [
	["check", "Added a delay of 1000ms between Period End chunks of 25 to relieve pressure on server", "Not all Period Ends were loading for large customers."],
]

const Release_20211003 = [["info", "Stages overview format II better headings", "Improved column headings on the alternative stages overview table."]]

const Release_20210920 = [
	["info", "Reports now renamed to Downloads", "The reports menu option is now labelled as Downloads to allow for new Reports function (coming soon)."],
	[
		"check",
		"Changed addresses now update in Client view",
		"Client, Landlord and Property details would not refresh after being edited and saved in the Clients view. Now fixed.",
	],
	[
		"info",
		"Admins can now edit business attributes",
		'In the clients view, an Admin user can edit "Is Landlord Corporate", "Property Use", "VAT Elected", "Property Tenure", "Demand Type". These values are now included in the Properties export file',
	],
	["check", "Under certain circumstances, users could close stages even if not all conditions are ticked", "Now fixed."],
	[
		"check",
		"Blank screen when searching with special characters",
		"Special characters, e.g. brackets and apostrophes, can now be used in Period End, Open Stages, Properties and Query Management pages as well as the Clients tree",
	],
	["info", "Deleted users now removed from view", "Once a user is deleted they will no longer appear in the user management panels and appointees lists."],
]

const Release_20210816 = [
	["info", "Conditions after Queries", "In the Period End details and open stages views, any stage Conditions are now listed AFTER any Comments or Queries."],
	[
		"info",
		"Stage Actions on Open Stages",
		"On the Open Stages page, the actions for each stage are displayed in the same format as on the Period End page (i.e. not in and not in a dropdown list).",
	],
	[
		"info",
		"Query Freshness",
		"On the Period End Details page, the age of open queries is indicated by the background colour of the query number: green if 2 days or less; amber if 3 to 7 days old; red if 8 days or older.",
	],
	["info", "Default Filter", "The default Period End filter is set to show Open Period ends."],
	["info", "Stages Overview", "Templates can be combined and shown in a specific order on the Over page Stage Overview table."],
	["info", "Query Assigned To avatar", "On the Period End page, a new icon shows which user each query isd assigned to."],
	["info", "Private Query indicator", "On the Period End page, a new icon will highlight any Queries which are only visible to In House users."],
	[
		"info",
		"User logins can be Disabled",
		"On the Manage Users and Teams page, individual users can be disabled. These users will not be able to log in and their avatars will appear greyed out when shown in the UI.",
	],
]

const Release_20210727 = [["check", "Cannot add Attachments to new Queries", "Now fixed."]]

const Release_20210720 = [
	[
		"check",
		"Reply button not working for Queries",
		"There was an area around the “Reply” button that you can click on and the popup will close but you haven’t really clicked on Reply. All modal dialog boxes have been reworked to eliminate this dead area.",
	],
]

const Release_20210712 = [
	[
		"check",
		"Upload files with complex filenames",
		"It should now be possible to upload any valid file, even if its name contains non-alphanumeric characters.",
	],
	[
		"check",
		"Templates and Clients load before Period Ends",
		"On slow networks, it is possible for Period Ends to start loading before Templates have loaded. This cna cause problems. Period Ends now load after Templates and Clients have completed loading.",
	],
	[
		"check",
		"Query Updates lock Period End",
		"On slow networks, it can take a moment for queries to be saved to the server. The Period End will now be greyed out and an eggtimer will display during the following operations: PERIODEND_RAISE_QUERY, PERIODEND_UPDATE_QUERY, PERIODEND_DELETE_QUERY,	PERIODEND_REPLY_TO_QUERY, PERIODEND_CLOSE_QUERY",
	],
	[
		"info",
		"Suppress Websocket Errors",
		"Some networks may not allow Websocket connections. Popup error messages are now suppressed for Websocket connection failures. Affected users cannot receive updates to their Period End automatically and will need to refresh their views manually.",
	],
	["check", "Fixed color errors for Query Avatars", "Color settings for Query Assignee avatar icons were failing silently. Now not failing at all."],
]

const Release_20210711 = [
	["info", "Phases (optional)", "Templates can now be grouped into phases."],
	["info", "Phases/stages overview table", "Moved phase/stages table to below donuts. Phase table now shows phases rather than stages."],
	["info", "No more us/them", "Replaced Us and Them with Customer Name & Client."],
	["info", "Query view", "Moved attachments to bottom. Have one white area per query."],
	["info", "Files view", "Attachments now appear as files (further refinements to follow)."],
	["check", "Reopen closed PE", "Reopening now also changes the Period End Closed status to Open."],
	["check", "Client Page Search", "On the Clearview client page when I enter a “(“ in the search bar it crashes the system. Now fixed."],
	["check", "Do not renew checkbox", "Checkbox now displays the correct state"],
]

const Release_20210627 = [
	["info", "Stop Auto-renew", "Period Ends can now be flagged as Do Not Renew."],
	["info", "Folder Names", "Template Stages can now have Folder Names to indicate what kind of files they are intended to contain."],
]

const Release_20210621 = [
	["check", "Query Overwrite Bug", "Bugfix to prevent new queries overwriting previous new query."],
	["info", "Overview Page Template Summary", "The overview page now shows the status counts in all currently active templates."],
	["info", "Overview Page With Us / With Them", "The overview page now shows total Request Closed stages, etc. with us and with them."],
]

const Release_20210604 = [
	[
		"info",
		"Confirm Cancel",
		"Modal dialog boxes; e.g. for Queries, Comments, etc; appeared to be being unintentionally closed without saving changes. Now all dialogs can only be closed by clicking on Save or Cancel. When Cancel is clicked, the user must confirm they are Ok to Cancel.",
	],
	[
		"info",
		"Appointees Bug Fix",
		"Frequent errors were reported when trying to save Accounts, Property Managers & Surveyors. This would occur due to a bug when adding two users to different teams in one save operation.",
	],
]
const Release_20210531 = [
	["info", "Comment and Query Titles", "Comments and Queries now require a short plain text title."],
	["info", "Rich Text Editor", "Comments, Queries and Replies can now contain extensive details formatted into paragraphs, lists, bold, italic, etc."],
	[
		"info",
		"Query Subjects",
		'New Queries must be assigned a "Subject". The default is "Query", the other options are currently "Client Invoices", "Lease Documents" and "Cost Allocation".',
	],
	[
		"info",
		"Query Color Coding",
		"Queries are red if they are awaiting action by the user's team, amber if they have been replied to and are awaiting action by the other side.",
	],
	["info", "Query Numbering", "Queries are now automatically allocated Q numbers; from Q1 onward within each Period End."],
	[
		"info",
		"Compact Comments List",
		"On the Period End Pages, the Comments and Queries on each Stage are now shown in a simple list. Click on the item to see the details and respond to Queries.",
	],
	["info", "Reformatted Comments & Queries", "Comments, Queries and Replies have been slightly reformatted to improve readability."],
	[
		"info",
		"Query Management Filters",
		'The Query Management page now defaults to Queries currently with the current user\'s team. You can now filter on whether the Query is "With Us" or "With Them".',
	],
	[
		"info",
		"Query Behaviour",
		'By request, each Customer can 1) disable the ability to have "draft" Queries, 2) hide Client Queries in future Stages, 3) allow a Stage to be Closed if all Queries are "replied to" and review them in the next Stage.',
	],
]

const Release_20210509 = [
	["info", "Remote Refresh.", "When another user changes a Period End within your overview, it will automatically be updated in your app."],
]

const Release_20210504 = [["info", "Broadcast alerts.", "Alerts can now be sent to all online users by system admins (wia WebSockets)."]]

const Release_20210425 = [
	[
		"check",
		"Loading error if Admin user is also in appointee team.",
		'Resolved. This was caused by a "double-dip" load of period ends at the admin AND property levels.',
	],
	[
		"check",
		"Error loading Period Ends. Not all Period Ends load.",
		"Period Ends are now loaded in chunks of up to 100 properties at a time. Admin view should now also load all Period Ends.",
	],
	["check", "Appointee teams now excluded from User & Teams screen.", "Only the Users and cross-property teams are shown."],
]

const Release_20210420 = [
	["check", 'BW users "Fail to Fetch" error.', "Max Period End payload size exceeded! Split requests > 100 properties into 2 requests."],
]

const Release_20210412 = [
	[
		"check",
		"Period End filters are hard to remove from Overview Page Statistics links.",
		"It is now possible to clear the Period End filter on pages linked to from the Overview Statistics links.",
	],
]

const Release_20210408 = [
	[
		"info",
		"Priority Alerts.",
		"Alerts will now also be raised when a Period End or stage RAG status changes, e.g. it becomes overdue. This will be visible in the Stage Comments - Log section.",
	],
]

const Release_20210407 = [
	[
		"info",
		"Priority Alerts.",
		'An alert icon (red bell) will appear next to a "Priority" Period End when any of the following have been added/changed by another user: attachments, stages, comments or queries. Click on the bell to reset the alert. Note that to remain fully up-to-date you will need to regularly refresh your Period Ends using the refresh icon next to the page title.',
	],
]

const Release_20210406 = [["check", 'Rename "Favourite" as "Priority".', "PEs are now given Priority rather than added to Favourites."]]

const Release_20210405 = [
	[
		"info",
		"Teams.",
		"Admin users can now add users to the Accounts/Service Charge Manager/Property Manager/Surveyor/Other teams in the Clients view. This can be done at the Customer, Client, Landlord or Property level. These users are restricted to the prt of the client tree they are assigned to (unless they are Admin users). Further details are to follow.",
	],
	["check", "In-House and Client Contact now working.", "All property contacts are now correctly displayed and are taken from the teams assigned above."],
	[
		"info",
		"Favourite Period Ends.",
		'Users can now "Favourite" a Period End by clicking on the star icon next to the Period End reference number. Users can filter to their list of favourites using the Period End filter or clicking on "Favourites" in the Overview Page Statistics screen.',
	],
]

const Release_20210316 = [
	["info", "Client editing dropdown menu drops to the right and falls off smaller screens.", "All dropdown menus now drop directly down."],
	["check", "Period Ends are not auto-renewing.", "Now fixed. Period Ends will be auto-renewed overnight."],
	["check", "Closed Period Ends are appearing in the overview stats.", "Overview stats now only include Live Period Ends."],
	["info", "Wide left margin on most pages causes unnecessary scrolling on smaller screens.", "Left margin is now much narrower."],
	[
		"check",
		"Stage Duration is not rounding correctly.",
		"Stage Duration now ignores the stage start Time and so is calculated correctly from midnight. A stage will need to be completed the same day to be of zero length.",
	],
]

const Release_20210228 = [
	["info", "New Queries on Future Stages", "When raising a new query, it is now possible to assign it to any open stage."],
	["info", "Edit Queries, Change Stage", "Draft queries can be re-assigned to any open stage."],
	["info", "New Query Attachments", "It is possible to assign attachments when raising a new query."],
	["info", "Query Manager Pagination", "There is now pagination on the Query Manager page."],
	["info", "Comments on Future Stages", "It is now possible to add a comment to any open stage."],
	["info", "Attachments on Future Stages", "It is now possible to add/delete attachments to any open stage."],
]

const Release_20210222 = [
	["info", "Need to manually recalculate Period Ends statuses", "Sysadmins can now manually recalculate all Period Ends from the User Info menu."],
]

const Release_20210221 = [
	[
		"info",
		"Display splash screen until user fully authenticated.",
		'There is now a "Please Wait. Clearview Loading..." screen while the app loads followed by a "Please Wait. Logging In..." splash screen while the user is authenticated.',
	],
	["check", "Unable to close Stages where there are which have been deleted from the template.", "Fixed."],
	["check", "Error 022 when creating a new Team.", "Fixed."],
]

const Release_20210207 = [
	[
		"info",
		"New User & Teams Management.",
		"Users and Teams are now managed and behave in a simplified way. In the Clients view, an Admin user can go into edit mode and manage users permissions on a single page for the Customer/Client. In addition there is a new Teams management page in which Teams can be limited to specific properties, in which case members of that team can only see those properties.",
	],
]

const Release_20210124 = [
	["info", "Deleting Period Ends.", "In-House admins can now delete period ends in the Period End details card."],
	["check", "Period End details card not updating when Period End starts or changes Status.", "Now fixed."],
]

const Release_20210119 = [["info", "Branding for Forsite.", "Now done."]]

const Release_20210117 = [
	["info", "Period Ends - client view to exclude workflows with no client stages, eg our trans in and trans out workflows.", "Now done."],
	["info", 'Overview - to show "Queries awaiting Client Response" and "Queries awaiting {BrandName} response".', "Now done."],
	["info", "PeriodEnds - no need to hide Fee from Client", "Fee now visible in page and on exports."],
	["info", "Reports	- hide reports page from clients", "Now done."],
	["info", 'Menu - Copyright to be changed to just "©2020 all rights reserved"', "Now done."],
	["check", "Clients - prevent invalid characters in Ref field (?,/+, etc.)", "Now fixed."],
	["check", "Attachments - error 005 uploading attachments to properties", "Now fixed."],
	["check", "Stages - Error re-opening closed stage", "Now fixed."],
	[
		"info",
		"Clients - Client admins should only be able to edit addresses and manage their own teams. Clients cannot edit, delete or create business entities or add/remove/rename property attachments.",
		"Now done.",
	],
	[
		"info",
		"Conditions - need to be able to Add/Remove and reorder the stage conditions in the templates and have the changes reflected in any Open Stages in existing Period Ends",
		"Now done. Users will need to refresh their Periods Ends to see the updated conditions!",
	],
	["info", "Attachments - Confirm whether Clients can delete In-House attachments.", "Clients cannot delete In-House attachments."],
	[
		"info",
		"Attachments	- Confirm Period End level attachments are locked when the Period End is closed.",
		"Yes, attachments are locked when the PE is closed.",
	],
	[
		"check",
		"Attachments - Attachment links are opening the wrong attachments sometimes.",
		"Now fixed. This was caused occasionally by multiple file uploads.",
	],
]

const Release_20210112 = [
	[
		"check",
		"Over Page: Period Ends payload was too big to load.",
		"This has been resolved by loading the period ends in blocks (for each managing agent in turn).",
	],
	["check", "Files could not be attached to Properties", "Now fixed."],
]

const Release_20210111 = [
	["check", "Cannot upload attachments", "Appears to be working now. Possibly related to out-of-date CloudFront cache."],
	["check", "Cannot update stage names", "Appears to be working now. Possibly related to out-of-date CloudFront cache."],
	["info", "Hide Support page from Clients", "Done."],
	["info", "Hide Templates page from Client", "Done."],
]

const Release_20210110 = [
	[
		"check",
		"When creating templates, we changed the Next field to the template we want to use for the next year end and saved, but when we returned to the screen it was showing None.",
		"Now fixed",
	],
	["info", "Put Customer name in the Query statistics label on the Overview Page", "Now done"],
	["check", "Filters do not display the selected filter values", "Now fixed"],
	["check", "Fee is not included in the PE exports", "Now fixed"],
]

const Release_20210106 = [
	["bug", "Initial loading of the Overview page is slow.", "I am working on compressing the data to speed up the initial load."],
	["info", "Pagination for PeriodEnds, Open Stages and Properties", "Done. This should significantly improve page rendering times."],
	["check", "Filtering by Managing Agent does not select any items.", "Fixed"],
	["check", "Remove time component of dates in all reports", "Done"],
	["check", "On the Period End and Open Stages reports, the second Due Date is the Stage Due Date", "Column label changed to reflect this."],
	[
		"check",
		"Period End Projected End Date for closed PEs should not contain a time component, nor should Stage Due Date and Stage Warning Date",
		"Fixed. Time component will no longer be populated in the database.",
	],
]

const Release_20210103 = [
	["info", "Reports", "A Reports page is now available from the left hand menu. This allows all data to be exported to Excel"],
	["info", "Export the client list to Excel", "Now available on the new Reports/Properties tab"],
	["info", "Export all the queries to Excel within a Period End", "Now available on the Period End overview card"],
	[
		"info",
		"Overview statistics are too general",
		"The Overview page now has a Period End filter to select subsets of the data. Note that changing this filter affects the Open Stages and Period Ends page, and vice-versa",
	],
	[
		"check",
		"Prevent existing PEs being messed up by template edits",
		"Template stages cannot be added, removed or have their order changed, if they have been used in any period ends. Close the Template if you need to make these changes for future period Ends.",
	],
	["info", "Report of assigned users to Period Ends", "Now available in Reports/Period Ends tab"],
	["info", "Does a draft query prevent the stage from being closed?", "Yes it does"],
	[
		"info",
		"3rd party software licences",
		"To comply with 3rd party licence requirements, details of all 3rd party components and their licence terms are included in the Support/Licences tab.",
	],
]
