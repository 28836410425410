import React, { useReducer, useState } from "react"
import PropTypes from "prop-types"

import _ from "lodash"
import * as Api from "../../../../api/CustomerApi"
import * as clearview from "../../../../components/@Clearview"

import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../../../components"
import AreYouSureModal from "../../../components/AreYouSureModal"

function S3ChooseFromBranch({ isOpen, onCancel, onSelect, periodEnd, title, flavour, defaultFileInfo }) {
	const [folders, dispatchFolders] = useFoldersReducer(periodEnd, flavour, action => dispatchFolders(action))

	if (isOpen && folders === false) {
		dispatchFolders({ type: "LOAD_TREE" })
	}

	return (
		<React.Fragment>
			<Modal isOpen={isOpen} className="clearview-find-in-tree">
				<ModalHeader tag="div">
					<div className="d-flex">
						<h1 className="text-primary mr-2">
							<i className="fa fa-fw fa-file-text-o"></i>
						</h1>
						<h1 className="text-primary mr-2">{title}</h1>
					</div>
				</ModalHeader>
				<ModalBody>
					<ul>
						{!!_.isArray(folders) &&
							folders.map(folder => (
								<li key={folder[0].owner.type}>
									<span className="list-heading">{folder[0].owner.name || folder[0].owner.reference}:</span>
									<FolderContents folder={folder} onSelect={onSelect} despatcher={action => dispatchFolders(action)} />
								</li>
							))}
					</ul>
				</ModalBody>
				<ModalFooter className={!!defaultFileInfo && "d-flex justify-content-between"}>
					{defaultFileInfo && (
						<a href="#" onClick={() => onSelect(defaultFileInfo)}>
							{defaultFileInfo.scope}
						</a>
					)}
					<a href="#" onClick={() => onCancel()}>
						Cancel
					</a>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	)
}

S3ChooseFromBranch.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	periodEnd: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	flavour: PropTypes.string.isRequired,
}

export default S3ChooseFromBranch

export const useFoldersReducer = (periodEnd, flavour, dispatch) =>
	useReducer((prevState, action) => {
		let newState = _.cloneDeep(prevState)

		switch (action.type) {
			case "LOAD_TREE":
				Api.listPreferencesInTree(periodEnd, flavour).then(value => {
					dispatch({ type: "LOADED_TREE", value })
				})
				return "Loading"

			case "LOADED_TREE":
				newState = _.values(_.groupBy(action.value.sort(clearview.orderBy(it => it.depth)), it => it.owner.type))
				return newState

			case "DELETE":
				Api.deleteFilesOrFolders([action.key]).then(value => {
					dispatch({ type: "LOAD_TREE" })
				})
				return "Deleting"

			default:
				return newState
		}
	}, false)

export function FolderContents({ folder, onSelect, despatcher, className, itemClass = file => "" }) {
	const [deleteFile, setDeleteFile] = useState(false)

	return (
		<React.Fragment>
			<ul className={className}>
				{folder.sort(clearview.orderBy(it => it.scope)).map(file => (
					<li key={file.key} title={clearview.FormatDateAndTime(file.lastModifiedDate)}>
						<a href="#" className={itemClass(file)} onClick={e => onSelect(file)}>
							{file.scope}
						</a>
						<a
							href="#"
							title="Delete template file..."
							className="danger ml-2"
							onClick={e => {
								setDeleteFile(file)
								clearview.StopPropagation(e)
							}}
						>
							{clearview.Icon.clear}
						</a>
					</li>
				))}
			</ul>
			<AreYouSureModal
				id="deleteFile_AreYouSure"
				isOpen={!!deleteFile}
				message={
					<span>
						Are you sure you wish to delete the file <b className="nowrap">{deleteFile?.scope}</b> from{" "}
						<b className="nowrap">{deleteFile?.owner?.name || deleteFile?.owner?.reference}</b>?
					</span>
				}
				action={() => {
					despatcher({ type: "DELETE", key: deleteFile?.key })
					setDeleteFile(false)
				}}
				cancel={() => setDeleteFile(false)}
			/>
		</React.Fragment>
	)
}
