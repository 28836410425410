import { call, put, takeLatest, takeEvery } from "redux-saga/effects"
import * as Api from "../../api/CustomerApi"

import * as ACTIONS from "./index"

//Worker Functions
function* fetchPeriodEnd(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const periodEnd = yield call(Api.fetchPeriodEnd, action.payload.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_FETCHED,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 006",
			type: ACTIONS.PERIODEND_FETCH_FAILED,
			message: e.message,
		})
	}
}

function* updatePeriodEndReference(action) {
	try {
		const periodEnd = yield call(Api.updatePeriodEndReference, action.periodEnd)

		yield put({
			type: ACTIONS.PERIODEND_REFERENCE_UPDATED,
			oldReference: action.periodEnd.reference,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
		})

		yield put({
			type: ACTIONS.WARNING,
			title: `${action.periodEnd.reference} has been changed to ${periodEnd.reference}`,
			message: "You should Reload Clearview now!\nThis will ensure you edit the correct Period End going forward.",
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODEND_REFERENCE_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* updatePeriodEnd(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const periodEnd = yield call(
			Api.updatePeriodEnd,
			action.action,
			action.periodEnd,
			action.subject,
			action.title,
			action.comments,
			action.stage,
			action.otherParameters
		) //Stage is optional

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 007",
			type: ACTIONS.PERIODEND_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* updatePeriodEndField(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const periodEnd = yield call(Api.updatePeriodEndField, action.periodEnd, action.fieldName)

		yield put({
			type: ACTIONS.PERIODEND_FIELD_UPDATED,
			fieldName: action.fieldName,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODEND_FIELD_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* updatePeriodEndDoNotRenew(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const periodEnd = yield call(Api.updatePeriodEndDoNotRenew, action.periodEnd)

		yield put({
			type: ACTIONS.PERIODEND_DONOTRENEW_UPDATED,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODEND_DONOTRENEW_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* updatePeriodEndIsPublished(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const periodEnd = yield call(Api.updatePeriodEndIsPublished, action.periodEnd)

		yield put({
			type: ACTIONS.PERIODEND_ISPUBLISHED_UPDATED,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			type: ACTIONS.PERIODEND_ISPUBLISHED_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* updatePeriodEndCondition(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		const periodEnd = yield call(Api.updatePeriodEndCondition, action.periodEnd, action.conditionId, action.isChecked)

		yield put({
			type: ACTIONS.PERIODEND_CONDITION_UPDATED,
			periodEnd: periodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 008",
			type: ACTIONS.PERIODEND_CONDITION_UPDATE_FAILED,
			message: e.message,
		})
	}
}

function* updateComment(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		yield call(Api.updateComment, action.id, action.periodEnd, action.stage, action.title, action.comments)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 033",
			type: ACTIONS.PERIODEND_UPDATE_COMMENT_FAILED,
			message: e.message,
		})
	}
}

function* deleteComment(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		yield call(Api.deleteComment, action.id, action.periodEnd, action.stage)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 034",
			type: ACTIONS.PERIODEND_DELETE_COMMENT_FAILED,
			message: e.message,
		})
	}
}

function* raiseQuery(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		yield call(Api.raiseQuery, action.periodEnd, action.stage, action.subject, action.title, action.comments, action.assignTo, action.isDraft)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 009",
			type: ACTIONS.PERIODEND_RAISE_QUERY_FAILED,
			message: e.message,
		})
	}
}

function* updateQuery(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		yield call(Api.updateQuery, action.id, action.periodEnd, action.stage, action.subject, action.title, action.comments, action.assignTo, action.isDraft)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 010",
			type: ACTIONS.PERIODEND_UPDATE_QUERY_FAILED,
			message: e.message,
		})
	}
}

function* deleteQuery(action) {
	try {
		console.info(`SAGA: ${action.type}`)
		yield call(Api.deleteQuery, action.id, action.periodEnd, action.stage)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 023",
			type: ACTIONS.PERIODEND_DELETE_QUERY_FAILED,
			message: e.message,
		})
	}
}

function* replyToQuery(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		yield call(Api.replyToQuery, action.queryId, action.comments)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 011",
			type: ACTIONS.PERIODEND_REPLY_TO_QUERY_FAILED,
			message: e.message,
		})
	}
}

function* reOpenQuery(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		yield call(Api.reOpenQuery, action.queryId, action.comments)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 032",
			type: ACTIONS.PERIODEND_REOPEN_QUERY_FAILED,
			message: e.message,
		})
	}
}

function* closeQuery(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		yield call(Api.closeQuery, action.queryId, action.comments)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 012",
			type: ACTIONS.PERIODEND_CLOSE_QUERY_FAILED,
			message: e.message,
		})
	}
}

function* closeQueries(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		for (let queryId of action.queryIds) {
			yield call(Api.closeQuery, queryId, action.comments)
		}

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 029",
			type: ACTIONS.PERIODEND_CLOSE_QUERY_FAILED,
			message: e.message,
		})
	}
}

function* updateReply(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		yield call(Api.updateReply, action.queryId, action.replyId, action.comments)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 030",
			type: ACTIONS.PERIODEND_UPDATE_REPLY_FAILED,
			message: e.message,
		})
	}
}

function* deleteReply(action) {
	try {
		console.info(`SAGA: ${action.type}`)

		yield call(Api.deleteReply, action.queryId, action.replyId)

		const updatedPeriodEnd = yield call(Api.fetchPeriodEnd, action.periodEnd.id)

		yield put({
			type: ACTIONS.PERIODEND_UPDATED,
			periodEnd: updatedPeriodEnd,
			receivedAt: Date.now(),
		})
	} catch (e) {
		yield put({
			id: "ERROR CODE 031",
			type: ACTIONS.PERIODEND_DELETE_REPLY_FAILED,
			message: e.message,
		})
	}
}

export default function* periodEndUpdateSaga() {
	yield takeEvery(ACTIONS.PERIODEND_FETCH, fetchPeriodEnd)
	yield takeEvery(ACTIONS.PERIODEND_UPDATE, updatePeriodEnd)
	yield takeEvery(ACTIONS.PERIODEND_REFERENCE_UPDATE, updatePeriodEndReference)
	yield takeLatest(ACTIONS.PERIODEND_FIELD_UPDATE, updatePeriodEndField)
	yield takeLatest(ACTIONS.PERIODEND_DONOTRENEW_UPDATE, updatePeriodEndDoNotRenew)
	yield takeLatest(ACTIONS.PERIODEND_ISPUBLISHED_UPDATE, updatePeriodEndIsPublished)
	yield takeLatest(ACTIONS.PERIODEND_CONDITION_UPDATE, updatePeriodEndCondition)
	yield takeLatest(ACTIONS.PERIODEND_UPDATE_COMMENT, updateComment)
	yield takeLatest(ACTIONS.PERIODEND_DELETE_COMMENT, deleteComment)
	yield takeLatest(ACTIONS.PERIODEND_RAISE_QUERY, raiseQuery)
	yield takeLatest(ACTIONS.PERIODEND_UPDATE_QUERY, updateQuery)
	yield takeLatest(ACTIONS.PERIODEND_DELETE_QUERY, deleteQuery)
	yield takeLatest(ACTIONS.PERIODEND_REPLY_TO_QUERY, replyToQuery)
	yield takeLatest(ACTIONS.PERIODEND_REOPEN_QUERY, reOpenQuery)
	yield takeLatest(ACTIONS.PERIODEND_CLOSE_QUERY, closeQuery)
	yield takeLatest(ACTIONS.PERIODEND_CLOSE_QUERIES, closeQueries)

	yield takeLatest(ACTIONS.PERIODEND_UPDATE_REPLY, updateReply)
	yield takeLatest(ACTIONS.PERIODEND_DELETE_REPLY, deleteReply)
}

//Call in components using:
/*
  const { dispatch } = this.props
  dispatch({type: CLIENTS_FETCH, payload: {}})
*/
