import React from "react"
import BootstrapTable from "../../../../react-bootstrap-table2"
import ToolkitProvider from "../../../../react-bootstrap-table2-toolkit"

import _ from "lodash"

import * as clearview from "../../../../components/@Clearview"
import PropTypes from "prop-types"

import { PhasesPeriodEndRagBadge } from "./PhasesPeriodEndRagBadge"

export class LivePeriodEndsPhasesOverviewTable extends React.Component {
	static propTypes = {
		context: PropTypes.object.isRequired,
		periodEnds: PropTypes.array.isRequired,
	}

	render() {
		const { context, periodEnds } = this.props
		const customer = context.state.filter.customer

		if (!customer) return

		const phaseInHouse = _.flatMap(customer.templates, it => it.stages.map(s => ({ phaseId: s.phaseId, isInHouse: s.isInHouse })))
		const phaseInHouseLookup = _.groupBy(phaseInHouse, it => it.phaseId)

		const ownerMap = {
			true: customer.name,
			false: "Client",
			undefined: "Shared",
		}

		const byPhaseId = _.groupBy(periodEnds, it => it.currentStage.templateStage.phaseId)

		const rowData = _.sortBy(customer.phases, it => it.sequence).map(phase => {
			const isInHouse = _.first(phaseInHouseLookup[phase.id])?.isInHouse
			const currentPhase = byPhaseId[phase.id]

			const byPeriodEndRagOk = _.groupBy(currentPhase, periodEnd => clearview.PeriodEndRagOk[periodEnd.rag])

			const byPeriodEndRagOkStageRagOk = {
				true: _.groupBy(byPeriodEndRagOk[true] || [], periodEnd => clearview.StageRagOk[periodEnd.currentStage.rag]),
				false: _.groupBy(byPeriodEndRagOk[false] || [], periodEnd => clearview.StageRagOk[periodEnd.currentStage.rag]),
			}

			return {
				phase: phase,
				seq: phase.sequence,
				owner: ownerMap[isInHouse],
				name: phase.name,
				link: `/periodEnds?phaseName=${phase.name}&periodEndStatus=Open`,

				OkOk: (byPeriodEndRagOkStageRagOk[true][true] || []).length,
				OkBad: (byPeriodEndRagOkStageRagOk[true][false] || []).length,
				BadOk: (byPeriodEndRagOkStageRagOk[false][true] || []).length,
				BadBad: (byPeriodEndRagOkStageRagOk[false][false] || []).length,

				Total: currentPhase?.length,
			}
		})

		const rowClasses = (row, idx) => {
			return row.owner === "Client" ? `table-info` : `table-primary`
		}

		return (
			<ToolkitProvider
				keyField="seq"
				data={rowData}
				caption="some caption"
				columns={[
					{
						dataField: "seq",
						text: "#",
						headerAlign: "center",
						align: "center",
					},
					{
						dataField: "owner",
						text: "Owner",
						headerAlign: "center",
						align: "center",
					},
					{
						dataField: "name",
						text: "Phase",
						headerAlign: "center",
						align: "left",
					},
					{
						dataField: "OkOk",
						text: `${clearview.CustomerAlt(
							customer,
							"LivePeriodEndsPhasesOverviewTable.periodEnds.label",
							"Period Ends"
						)} within Target Deadline\n${clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.stages.label", "Stages")} on Target`,
						headerFormatter: hdr => (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									flexWrap: "wrap",
									padding: 0,
								}}
							>
								<div
									style={{
										width: "100%",
										borderBottom: "1px solid #E9ECEF",
									}}
									title="Period End Due Date is in the future"
								>
									{clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.periodEnds.label", "Period Ends")} within Target
									Deadline
								</div>
								<div
									style={{
										width: "50%",
										borderRight: "1px solid #E9ECEF",
									}}
									title="Stage is not yet Overdue"
								>
									{clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.stages.label", "Stages")} on Target
								</div>
								<div
									style={{
										width: "50%",
										borderLeft: "1px solid #E9ECEF",
									}}
									title="Stage is Overdue"
								>
									{clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.stages.label", "Stages")} Overdue
								</div>
							</div>
						),
						headerAttrs: { colSpan: 2 },
						style: { width: "10%" },
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0
								? PhasesPeriodEndRagBadge("OnTime", cell, context, periodEnds, `${row.link}&periodEndRagOk=true&stageRagOk=true`)
								: undefined,
					},
					{
						dataField: "OkBad", //Current Period ends
						text: `${clearview.CustomerAlt(
							customer,
							"LivePeriodEndsPhasesOverviewTable.periodEnds.label",
							"Period Ends"
						)} within Target deadline\n${clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.stages.label", "Stages")} Overdue`,
						headerAttrs: { hidden: "hidden" },
						style: { width: "10%" },
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0
								? PhasesPeriodEndRagBadge("Warning", cell, context, periodEnds, `${row.link}&periodEndRagOk=true&stageRagOk=false`)
								: undefined,
					},
					{
						dataField: "BadOk", //Current year ends
						text: `Overdue ${clearview.CustomerAlt(
							customer,
							"LivePeriodEndsPhasesOverviewTable.periodEnds.label",
							"Period Ends"
						)}\n${clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.stages.label", "Stages")} on Target`,
						headerFormatter: hdr => (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									flexWrap: "wrap",
									padding: 0,
								}}
							>
								<div
									style={{
										width: "100%",
										borderBottom: "1px solid #E9ECEF",
									}}
									title="Period End Due Date has passed"
								>
									Overdue {clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.periodEnds.label", "Period Ends")}
								</div>
								<div
									style={{
										width: "50%",
										borderRight: "1px solid #E9ECEF",
									}}
									title="Stage is not yet Overdue"
								>
									{clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.stages.label", "Stages")} on Target
								</div>
								<div
									style={{
										width: "50%",
										borderLeft: "1px solid #E9ECEF",
									}}
									title="Stage is Overdue"
								>
									{clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.stages.label", "Stages")} Overdue
								</div>
							</div>
						),
						headerAttrs: { colSpan: 2 },
						style: { width: "10%" },
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0
								? PhasesPeriodEndRagBadge("BehindSchedule", cell, context, periodEnds, `${row.link}&periodEndRagOk=false&stageRagOk=true`)
								: undefined,
					},
					{
						dataField: "BadBad", //Current year ends
						text: `Overdue ${clearview.CustomerAlt(
							customer,
							"LivePeriodEndsPhasesOverviewTable.periodEnds.label",
							"Period Ends"
						)}\n${clearview.CustomerAlt(customer, "LivePeriodEndsPhasesOverviewTable.stages.label", "Stages")} Overdue`,
						style: { width: "10%" },
						headerAttrs: { hidden: "hidden" },
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) =>
							cell > 0
								? PhasesPeriodEndRagBadge("Overdue", cell, context, periodEnds, `${row.link}&periodEndRagOk=false&stageRagOk=false`)
								: undefined,
					},

					{
						dataField: "Total", //Current year ends
						text: "Total",
						headerAlign: "center",
						align: "center",
						formatter: (cell, row) => (cell > 0 ? PhasesPeriodEndRagBadge("Total", cell, context, periodEnds, row.link) : undefined),
					},
				]}
			>
				{props => (
					<React.Fragment>
						<BootstrapTable
							keyField="seq"
							classes="table-responsive-lg csl-stage-table text-inverse"
							bordered={true}
							rowClasses={rowClasses}
							responsive
							hover
							{...props.baseProps}
						/>
					</React.Fragment>
				)}
			</ToolkitProvider>
		)
	}
}
